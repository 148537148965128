import React, {Component} from 'react';
import classNames from 'classnames';
import {Button, FontIcon} from 'react-md';
import {connect} from 'react-redux';

import LoadingBar from '../presentation/LoadingBar';
import {createPortal} from 'react-dom';
import {buildRestPath, UPLOAD_BN_ENDPOINT} from '../../common/clientServer/navigation';
import ConfirmationDialog from '../presentation/ConfirmationDialog';
import {StoreWithSharedState} from '../../common/reducers/sharedStateReducer';
import {BnWrapperState, getBnWrapperStateFromStore} from '../../common/reducers/bnWrapperStateReducer';
import {joinAnd} from '../../server/util/stringUtil';

interface UploadNetworkComponentStoreProps {
    bnWrapperState: BnWrapperState;
}

interface UploadNetworkComponentOwnProps {
    problemStepId: number;
    buttonBarDiv: HTMLDivElement | null;
    uploadDisabled?: boolean;
}

type UploadNetworkComponentProps = UploadNetworkComponentStoreProps & UploadNetworkComponentOwnProps;

interface UploadNetworkComponentState {
    isSettingUpload?: boolean;
    isUploading?: boolean;
    uploadTarget?: File;
    uploadError?: string;
}

class UploadNetworkComponent extends Component<UploadNetworkComponentProps, UploadNetworkComponentState> {

    constructor(props) {
        super(props);
        this.state = {};
    }

    renderUploadButton() {
        const noBnWrapper = (this.props.bnWrapperState.engines.length === 0);
        return (this.props.uploadDisabled || !this.props.buttonBarDiv) ? null : createPortal((
            <Button flat={true} className={classNames('mdActionButton', 'btnRevert', 'revert', {disabled: this.state.isUploading})}
                    disabled={this.state.isUploading || noBnWrapper} onClick={() => {
                this.setState({isSettingUpload: true});
            }} title={noBnWrapper ? 'No BN engines are currently available to process uploaded files.' : undefined}>
                Load network from file
            </Button>
        ), this.props.buttonBarDiv);
    }

    renderUploadDialog() {
        const availableEngines = joinAnd(this.props.bnWrapperState.engines
            .map((engine) => (`${engine.name} files (${engine.fileSuffix.join(', ')})`)),
            ', ', ' or ');
        const fileTypes = this.props.bnWrapperState.engines
            .map((engine) => (engine.fileSuffix.join(','))).join(',');
        return (
            <ConfirmationDialog
                visible={this.state.isSettingUpload || false}
                dialogTitle='Load network from a file'
                onHideAction={async () => {
                    const body = this.state.uploadTarget;
                    if (body) {
                        const url = buildRestPath(UPLOAD_BN_ENDPOINT, {problemStepId: this.props.problemStepId, fileName: body.name});
                        this.setState({isUploading: true, isSettingUpload: false, uploadTarget: undefined, uploadError: undefined});
                        const result = await fetch(url, {
                            method: 'put',
                            headers: {
                                'Content-Type': 'application/octet-stream'
                            },
                            credentials: 'include',
                            body
                        });
                        if (!result.ok) {
                            this.setState({isSettingUpload: true, uploadError: `File ${body.name} could not be loaded.`});
                        }
                    }
                    this.setState({isUploading: false});
                }}
                onCancelAction={() => {this.setState({isSettingUpload: false, uploadTarget: undefined, uploadError: undefined})}}
                actionButtonDisabled={!this.state.uploadTarget}
                dialogContent={(
                    <div>
                        {!this.state.uploadError ? null : (<p className='errorStyle'>{this.state.uploadError}</p>)}
                        <p>You can load {availableEngines} into BARD.</p>
                        <p>
                            <FontIcon>warning</FontIcon>
                            Loading a network will replace your current draft variables, structure, parameters
                            and scenarios.  You may want to cancel and download your current network to a file
                            before proceeding.
                        </p>
                        <input type='file' name='file' accept={fileTypes} onChange={(evt) => {
                            if (evt.target.files && evt.target.files.length > 0) {
                                this.setState({uploadTarget: evt.target.files[0]});
                            }
                        }}/>
                    </div>
                )}
                width={350}
                actionButtonContent='Load'/>
        );
    }

    render() {
        return (
            <>
                {
                    this.state.isUploading ? (
                        <div className="loadingBarContentContainer"><LoadingBar/></div>
                    ) : this.props.children
                }
                {this.renderUploadButton()}
                {this.renderUploadDialog()}
            </>
        );
    }
}

function mapStoreToProps(store: StoreWithSharedState): UploadNetworkComponentStoreProps {
    return {
        bnWrapperState: getBnWrapperStateFromStore(store)
    };
}

export default connect(mapStoreToProps)(UploadNetworkComponent);