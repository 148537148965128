import React, {Component} from 'react';
import PropTypes from 'prop-types';
import SingleInput from './SingleInput';
import Select from 'react-select';
import classNames from 'classnames';
import {clamp} from 'lodash';
import Dotdotdot from 'react-dotdotdot';

import {EPSILON} from '../../common/util/constants';
import {normaliseNumberArray, roundFractionToEpsilon, roundPercentToEpsilon} from '../util/maths';
import {SingleVariableState, VariableType} from '../../common/reducers/keyVariablesReducer';
import {ValidityType} from '../container/BayesNetParametersContainer';
import {BayesNetParametersState, ProbabilityDistribution} from '../../common/reducers/bayesNetParametersReducer';
import commonSelectStyles from '../util/commonSelectStyles';

interface ProbabilityTableComponentProps {
    mode: string;
    variable: SingleVariableState;
    variableId: string;
    onChange: (variableId: string, permutation: number, newProbabilities: ProbabilityDistribution) => any;
    onCommitChange: (variableId: string, permutation: number, newProbabilities: ProbabilityDistribution) => void;
    probabilities: ProbabilityDistribution;
    probabilitiesKeys: string[];
    validity: ValidityType;
    readOnly?: boolean;
    sourceVariableIds?: string[];
    permutation: number;
    bnParameters?: BayesNetParametersState;
    numberMode?: boolean;
}

class ProbabilityTableComponent extends Component<ProbabilityTableComponentProps> {

    static propTypes = {
        mode: PropTypes.string.isRequired,
        variable: PropTypes.object.isRequired,
        variableId: PropTypes.string.isRequired,
        onChange: PropTypes.func.isRequired,
        onCommitChange: PropTypes.func.isRequired,
        probabilities: PropTypes.arrayOf(PropTypes.number).isRequired,
        probabilitiesKeys: PropTypes.arrayOf(PropTypes.string).isRequired,
        validity: PropTypes.object.isRequired,
        readOnly: PropTypes.bool,
        sourceVariableIds: PropTypes.arrayOf(PropTypes.string),
        permutation: PropTypes.number,
        bnParameters: PropTypes.object,
        numberMode: PropTypes.bool
    };

    static defaultProps = {
        mode: 'QLIST',
        readOnly: false,
        numberMode: true
    };

    constructor(props) {
        super(props);
        this.onNormalise = this.onNormalise.bind(this);
    }

    /**
     * The verbal cues returned by this function define ranges of probabilities corresponding to the given label.
     * They must be in order from lowest probability range (starting at 0) to highest probability range (ending at 1).
     * Aside from the first range, the ranges are open at the bottom and closed at the top, i.e. a probability falls in
     * a given label's interval if lowerBound < probability <= upperBound.  The first range is closed at both ends;
     * 0 <= probability <= upperBound.
     *
     * @returns A list of objects defining labelled ranges of probabilities, sorted in order from lowest to highest.
     */
    ciaWordsOfEstimativeProbability() {
        return [
            {label: 'No Chance', upperBound: 0},
            {label: 'Almost No Chance', upperBound: 0.05},
            {label: 'Very Unlikely', upperBound: 0.2},
            {label: 'Unlikely', upperBound: 0.45},
            {label: 'Roughly Even Chance', upperBound: 0.55},
            {label: 'Likely', upperBound: 0.8},
            {label: 'Very Likely', upperBound: 0.95},
            {label: 'Almost Certainly', upperBound: 1 - 1.1 * EPSILON},
            {label: 'Certain', upperBound: 1}
        ];
    }

    onChange(targetIndex: number, value?: number) {
        const probabilities = this.updateProbabilities(targetIndex, value);
        this.props.onChange(this.props.variableId, this.props.permutation, probabilities);
    }

    onCommitChange(targetIndex: number, value?: number) {
        let probabilities = this.updateProbabilities(targetIndex, value);
        this.props.onCommitChange(this.props.variableId, this.props.permutation, probabilities);
    }

    sumVerbalProbabilities(verbalCueIndexes) {
        let min = 0, max = 0;
        const verbalCue = this.ciaWordsOfEstimativeProbability();
        verbalCueIndexes.forEach((cueIndex) => {
            if (cueIndex !== null) {
                if (cueIndex > 0) {
                    min += verbalCue[cueIndex - 1].upperBound + EPSILON;
                }
                max += verbalCue[cueIndex].upperBound;
            }
        });
        return {min, max};
    }

    updateProbabilities(targetIndex: number, value?: number): ProbabilityDistribution {
        let probabilities = this.props.probabilitiesKeys.map((_key, index) => (
            (this.props.probabilities[index] === 0) ? 0 : (this.props.probabilities[index] || null)
        ));
        if (value === undefined) {
            probabilities[targetIndex] = null;
        } else if (this.props.numberMode) {
            probabilities[targetIndex] = clamp(Number(value), 0, 100) / 100;
        } else {
            let verbalCueIndexes = this.props.probabilitiesKeys.map((_key, index) => (this.findMatchingVerbalCueIndex(index)));
            verbalCueIndexes[targetIndex] = value;
            let {min, max} = this.sumVerbalProbabilities(verbalCueIndexes);
            const verbalCue = this.ciaWordsOfEstimativeProbability();
            if (min >= max) {
                probabilities[targetIndex] = verbalCue[value].upperBound;
            } else {
                // Attempt to get the probabilities to sum to 1 by making them all take a value at the same fraction
                // across the probability range of their nominated label.
                let fraction = clamp((1 - min) / (max - min), 0, 1);
                let roundingError = 0;
                probabilities = verbalCueIndexes.map((cueIndex) => {
                    if (cueIndex !== null) {
                        let lowerBound = (cueIndex === 0) ? 0 : verbalCue[cueIndex - 1].upperBound + EPSILON;
                        let upperBound = verbalCue[cueIndex].upperBound;
                        let probability = roundingError + lowerBound + fraction * (upperBound - lowerBound);
                        let rounded = roundFractionToEpsilon(probability);
                        rounded = clamp(rounded, lowerBound, upperBound);
                        roundingError = probability - rounded;
                        return rounded;
                    } else {
                        return null;
                    }
                });
            }
        }
        return probabilities;
    }

    onFocus(target, targetIndex) {
        if (target.value === '') {
            let allOthers = true;
            let remaining = this.props.probabilitiesKeys.reduce((total, _key, index) => {
                if (index !== targetIndex) {
                    if (this.props.probabilities[index] === null) {
                        allOthers = false;
                    } else {
                        return total - Number(this.props.probabilities[index]);
                    }
                }
                return total;
            }, 1);
            if (allOthers && remaining > -EPSILON) {
                remaining = roundPercentToEpsilon(remaining * 100);
                target.value = remaining;
                target.select();
                this.onChange(targetIndex, remaining);
            }
        }
    }

    onNormalise() {
        let normalisedProbabilities = normaliseNumberArray(this.props.probabilities);
        this.props.onCommitChange(this.props.variableId, this.props.permutation, normalisedProbabilities);
    }

    derivePermutation(variableId: string, permutation: number): [string, string, number] {
        if (!this.props.bnParameters) {
            throw new Error('Cannot call derivePermutation without bnParameters');
        }
        let variable = this.props.bnParameters.nodes.variable[variableId];
        let attributeKeys = variable.attributes.order;
        let arity = attributeKeys.length;
        let index = permutation % arity;
        return [variable.data.name, variable.attributes.state[attributeKeys[index]].stateName, Math.floor(permutation / arity)];
    }

    interleaveAndRows(tableRows) {
        let result: JSX.Element[] = [];
        tableRows.forEach((row, index) => {
            if (index > 0) {
                result.push(<tr key={'and' + index} className="andRow">
                    <td colSpan={3}>and</td>
                </tr>)
            }
            result.push(row);
        });
        return result;
    }

    renderScenario() {
        if (this.props.sourceVariableIds) {
            let label, value, permutation = this.props.permutation;
            return (
                <div className="scenario-Questions">
                    <span className="strongLabel-px">If:</span>
                    <table>
                        <tbody>
                        {
                            // AgenaRisk expects the permutations to change in "odometer order", so we need to reverse
                            // the sourceVariableIds twice to get the last source variable changing the fastest.
                            this.interleaveAndRows(this.props.sourceVariableIds.slice().reverse().map((variableId) => {
                                [label, value, permutation] = this.derivePermutation(variableId, permutation);
                                return (
                                    <tr key={variableId}>
                                        <td>{label}</td>
                                        <td>=</td>
                                        <td className="probabilityTitle">
                                            {value}                                            
                                        </td>
                                    </tr>
                                );
                            }).reverse())
                        }
                        </tbody>
                    </table>
                </div>
            )
        } else {
            return null;
        }
    }

    renderPercentageInput(index) {
        let value = (index >= this.props.probabilities.length || this.props.probabilities[index] === null) ? '' :
            (roundPercentToEpsilon(this.props.probabilities[index]! * 100)).toString();
        return (
            <SingleInput
                inputType="number" content={value} readOnly={this.props.readOnly} disabled={this.props.readOnly}
                onChange={(value: string) => {
                    this.onChange(index, value.trim() ? Number(value) : undefined);
                }}
                min={0} max={100} step={200 * EPSILON}
                onFocus={(evt) => {
                    this.onFocus(evt.target, index)
                }}
                onBlur={(evt) => {
                    this.onCommitChange(index, evt.target.value.trim() ? Number(evt.target.value) : undefined)
                }}
            />
        );
    }

    findMatchingVerbalCueIndex(index: number): number | null {
        let value = this.props.probabilities[index];
        if (value !== null) {
            const verbalCue = this.ciaWordsOfEstimativeProbability();
            for (let index = 0; index < verbalCue.length; ++index) {
                if (value <= verbalCue[index].upperBound) {
                    return index;
                }
            }
        }
        return null;
    }

    renderVerbalInput(index) {
        const verbalCue = this.ciaWordsOfEstimativeProbability();
        let options = verbalCue.map((cue, index) => ({label: cue.label, value: index}));
        let verbalCueIndex = this.findMatchingVerbalCueIndex(index);
        return (
            <Select value={verbalCueIndex === null ? null : options[verbalCueIndex]} options={options}
                    isDisabled={this.props.readOnly} menuShouldScrollIntoView={true}
                    styles={commonSelectStyles}
                    onChange={(selected) => {
                        this.onCommitChange(index, selected ? selected.value : undefined)
                    }}/>
        );
    }

    renderProbabilityInput(index) {
        if (this.props.variable.data.variableType === VariableType.UTILITY) {
            const value = this.props.probabilities.length > 0 ? this.props.probabilities[0] : null;
            return (
                <SingleInput inputType='number' content={value === null ? '' : value.toString()} onChange={(value: string) => {
                    const utility = value.trim() ? Number(value) : null;
                    this.props.onCommitChange(this.props.variableId, this.props.permutation, [utility]);
                }}/>
            );
        } else if (this.props.numberMode) {
            return (
                <div className="toolTipContainer"><span>{this.renderPercentageInput(index)} %</span><span
                    className="toolTip toolTipProbability">Chance of this state (%)</span></div>);
        } else {
            return this.renderVerbalInput(index);
        }
    }

    renderProbabilityBar(index) {
        const probability = this.props.probabilities[index];
        if (probability === null || probability === undefined) {
            return (<div className="probabilityBarBack"/>);
        } else {
            let className = 'probabilityBarBack';
            if (this.props.validity.valid) {
                className += ' valid';
            } else if (this.props.validity.moreThanOne || !this.props.validity.incomplete) {
                className += ' invalid';
            }
            return (
                <div className={className}>
                    <div className="probabilityBarLevel"
                         style={{width: (100 * probability) + '%'}}/>
                </div>
            );
        }
    }

    normaliseWarning(CPT) {
        return (
            <div className="normaliseWarning" style={(CPT ? {width: '100%'} : undefined)}>
                <div className="errorStyle" style={(CPT ? {width: '55%', textAlign: 'right'} : undefined)}>
                    Numbers don't add up to 100%.<br/>Modify inputs or click Normalise.
                </div>
                <div className="toolTipContainer">
                    <button className="normaliseButton" onClick={this.onNormalise}>Normalise</button>
                    <span className="toolTip normaliseToolTip">Adjust to total to 100%</span>
                </div>
            </div>
        );
    }

    renderNormaliseWarning(CPT = false) {
        if (!this.props.validity.valid && (this.props.validity.moreThanOne || !this.props.validity.incomplete)) {
            if (CPT) {
                return (<tr>
                    <td colSpan={1000}>{this.normaliseWarning(CPT)}</td>
                </tr>)
            } else {
                return (this.normaliseWarning(CPT))
            }
        } else {
            return null;
        }
    }

    renderUtilityInput() {
        return (
            <div className='conditionCaseUtility'>
                <p>What is the utility value of {this.props.variable.data.name}?</p>
                {this.renderProbabilityInput(0)}
            </div>
        )
    }

    renderInputTable() {
        return (
            <div className="conditionCaseHowLikely">
                <span className="strongLabel-px">How likely is it that:</span>
                <div className={classNames({
                    conditionCaseInputs: true,
                    verbalInputs: !this.props.numberMode
                })}>
                    <div className="keyVariableName">{this.props.variable.data.name}</div>
                    =
                    <table className='probabilityBarGraph'>
                        <tbody>
                        {
                            this.props.probabilitiesKeys.map((attributeId, index) => (
                                <tr key={attributeId}>
                                    <td className="probabilityTitle toolTipContainer">
                                        <Dotdotdot clamp={1}>
                                                {this.props.variable.attributes.state[attributeId].stateName}
                                        </Dotdotdot>
                                        <span className="toolTip toolTipProbabilityTitle">
                                            {this.props.variable.attributes.state[attributeId].stateName}
                                        </span>
                                    </td>
                                    <td className="probabilityInput">{this.renderProbabilityInput(index)}</td>
                                    <td className="probabilityBar">{this.renderProbabilityBar(index)}</td>
                                </tr>
                            ))
                        }
                        <tr>
                            <td colSpan={2}/>
                            <td className="scale"><span className="leftScale"/>100%<span className="rightScale"/></td>
                        </tr>
                        </tbody>
                    </table>
                </div>
                {this.renderNormaliseWarning()}
            </div>
        );
    }

    renderCPTElement() {
        const probabilitiesKeys = this.props.variable.data.variableType === VariableType.UTILITY ? [''] : this.props.probabilitiesKeys;
        if (this.props.sourceVariableIds) {
            let label, value, permutation = this.props.permutation;
            return (
                <tbody>
                    <tr>
                        {this.props.sourceVariableIds.slice().reverse().map((variableId) => {
                            [label, value, permutation] = this.derivePermutation(variableId, permutation);
                            return (<td key={variableId + '_' + permutation + '_' + label}
                                        className="probabilityTitle-CPT">{value}</td>);
                        }).reverse()}

                        {probabilitiesKeys.map((_attributeId, index) => (
                            <td key={index} className={classNames({
                                'conditionCaseInputs-CPT': true,
                                'verbalInputs': !this.props.numberMode
                            })}>
                                {this.renderProbabilityInput(index)}
                            </td>
                        ))}
                    </tr>
                    {this.renderNormaliseWarning(true)}
                </tbody>
            );
        }
        else {
            return (
                <tbody>
                    <tr>
                        {probabilitiesKeys.map((_attributeId, index) => (
                            <td key={index} className={classNames({
                                'conditionCaseInputs-CPT': true,
                                'verbalInputs': !this.props.numberMode
                            })}>
                                {this.renderProbabilityInput(index)}
                            </td>
                        ))}
                    </tr>
                    {this.renderNormaliseWarning(true)}
                </tbody>
            );
        }
    }

    render() {
        if (this.props.mode === 'QLIST') {
            return (
                <li>
                    <div className="conditionCase">
                        {this.renderScenario()}
                        {
                            this.props.variable.data.variableType === VariableType.UTILITY
                                ? this.renderUtilityInput()
                                : this.renderInputTable()
                        }
                    </div>
                </li>
            )
        } else {
            return (
                this.renderCPTElement()
            )
        }
    }
}

export default ProbabilityTableComponent;