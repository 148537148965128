export const ROOT_PATH = '/';

export const SELECT_PATH = '/select/:problemStepId?';
export const FOYER_PATH = '/select';

export interface ProblemPathRouteParameters {
    problemStepId: string;
    tabName?: string;
    subtabName?: string;
}
export const PROBLEM_PATH = '/problem/:problemStepId/:tabName?/:subtabName?';

export interface ProblemSaveLoadPathRouteParameters {
    problemStepId: string;
}
export const PROBLEM_SAVE_LOAD_PATH = '/loadSave/:problemStepId';

export const PROBLEM_DESCRIPTION_PATH = '/description/:problemStepId';

export const ADMIN_PATH_BASE = '/admin';
export const ADMIN_PATH = ADMIN_PATH_BASE + '/:tabIndex?';

export const SERVICE_UNAVAIL_PATH = '/503';

export const VERSION_PATH = '/version';

export const OPEN_PROBLEM_ENDPOINT = '/api/problems/:problemStepId/open';
export const DOWNLOAD_REPORT_ENDPOINT = '/api/problems/:problemStepId/report';
export const DOWNLOAD_BN_ENDPOINT = '/api/problems/:problemStepId/bayesNetFile/:format?/:userId?/:status?';
export const DOWNLOAD_TEAM_REPORTS_ENDPOINT = '/api/admin/teamReports/:problemStepId';
export const UPLOAD_BN_ENDPOINT = '/api/problems/:problemStepId/bayesNetFile/:fileName';
export const TAGGED_SAVED_DATA_PATH = '/api/problems/:problemStepId/user/:userId/tag/:tag?';
export const UPLOAD_CASE_FILE_PATH = '/api/problems/:problemStepId/user/:userId/caseFile';
export const UPLOAD_PROBLEM_IMAGE_PATH = '/api/problems/:problemStepId/imageUploadUrl';
export const GET_UPLOADED_FILE_PATH = '/api/getUpload/'; // This path matches * afterwards.

export const GET_PROBLEM_ACTIVITY_ENDPOINT = '/api/problems/:problemStepId/recentActivities';
export const GET_USER_ACTIVITY_ENDPOINT = '/api/users/:userId/recentActivities';

export const CREATE_DISCUSSION_TOPIC = '/api/topic';
export const CREATE_PROBLEM_STEP = '/api/problemStep';
export const CREATE_PROBLEM = '/api/problem';

export const PROJECT_PATH = '/api/project';

export const UPLOAD_IMAGE_PATH = '/api/images/imageUploadUrl';

export function buildRestPath(path: string, parameters = {}) {
    let result = '', lastEnd = 0, match;
    const parameterRegExp = new RegExp(':([a-zA-Z]+)(\\?)?(\\/|$)', 'g');
    while ((match = parameterRegExp.exec(path)) !== null) {
        const start = parameterRegExp.lastIndex - match[0].length;
        result += path.substring(lastEnd, start);
        const value = parameters[match[1]];
        if (value === undefined) {
            const optional = match[2] === '?';
            if (optional) {
                return result; // Stop at the first missing optional parameter.
            } else {
                throw new Error('Parameters missing mandatory value for ' + match[1]);
            }
        }
        result += value + match[3];
        lastEnd = parameterRegExp.lastIndex;
    }
    return result + path.substring(lastEnd);
}
