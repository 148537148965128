import {Component} from 'react';
import {connect} from 'react-redux';

import {offlineAction} from '../reducers/loginReducer';
import {getListenerIdFromStore} from '../reducers/listenerIdReducer';
import {addToastMessageAction} from '../reducers/snackbarToastReducer';
import {actionProcessedByServerAction, getPendingActionsFromStore} from '../reducers/pendingActionReducer';
import * as constants from '../../common/util/constants';
import {storeInitAction} from '../../common/reducers/sharedStateReducer';

/**
 * Registers the client with the server to listen for events on the configured URL.  When this component is mounted,
 * it starts receiving events and dispatches them as redux actions; when it is unmounted, it stops processing events
 * and the event stream is closed.
 */
class EventSourceReduxDispatcher extends Component {

    eventSourceURL = '/api/actionStream';

    eventSource;

    componentDidMount() {
        this.eventSource = new EventSource(this.eventSourceURL, {withCredentials: true});
        this.eventSource.onmessage = this.onMessage.bind(this);
        this.eventSource.onerror = this.onError.bind(this);
    }

    componentWillUnmount() {
        this.eventSource.close();
        this.eventSource = null;
    }

    onMessage(message) {
        if (message.data) {
            const action = JSON.parse(message.data);
            if (action.type === constants.BARD_SESSION_INVALIDATED) {
                // This client's session has been invalidated - load the logout page directly from the server, which
                // will send back a redirect to the login path. Navigating to a new page will also reload the Redux
                // store, so we don't need to dispatch the Logout action.
                window.location.assign('/api/logout?listenerId=' + this.props.listenerId);
            } else if (!action.fromServer || action.fromServer.originListenerId !== this.props.listenerId) {
                // An action from someone other than me - dispatch it.
                this.props.dispatch(action);
            } else if (action.fromServer && action.fromServer.error) {
                console.error('Server rejected action: ', action, action.fromServer.error);
                const oldState = this.props.pendingActions.states[action.actionId];
                if (oldState) {
                    this.props.dispatch(addToastMessageAction('There was a server error - some of your changes were lost.'));
                    // Revert to the state prior to the rejected action, then replay all subsequent pending actions.
                    this.props.dispatch(storeInitAction(oldState));
                    let afterFailedAction = false;
                    this.props.pendingActions.actions.forEach((pendingAction) => {
                        if (afterFailedAction) {
                            this.props.dispatch({...pendingAction, isClientOnly: true});
                        } else if (pendingAction.actionId === action.actionId) {
                            afterFailedAction = true;
                        }
                    });
                }
                // Mark the failed action as processed by the server - no need to remember it any longer.
                this.props.dispatch(actionProcessedByServerAction(action));
            } else {
                // Mark the successful action as processed by the server - no need to remember it any longer.
                this.props.dispatch(actionProcessedByServerAction(action));
            }
        }
    }

    onError(err) {
        console.error(err);
        this.props.dispatch(offlineAction());
    }

    render() {
        return null;
    }
}

function mapStoreToProps(store) {
    return {
        listenerId: getListenerIdFromStore(store),
        pendingActions: getPendingActionsFromStore(store)
    }
}

export default connect(mapStoreToProps)(EventSourceReduxDispatcher);
