import React from 'react';
import {arrayMove} from 'react-sortable-hoc';
import classNames from 'classnames';

import ReorderableAddableContainer from '../container/ReorderableAddableContainer';
import ReorderableDeleteableElement from '../container/ReorderableDeleteableElement';

interface MultiAssignComponentProps {
    allIds: (number | string)[];
    selectedIds: {[key: string]: boolean | undefined};
    renderItem: (id: number | string) => React.ReactElement;
    onSelectionChanged: (id: number | string, selected: boolean) => void;
}

interface MultiAssignComponentState {
    present: {[key: string]: boolean};
    order: (number | string)[];
}

export default class MultiAssignComponent extends React.Component<MultiAssignComponentProps, MultiAssignComponentState> {

    static MULTI_ASSIGN_BOUNDARY = '__multi_assign_boundary';

    static getDerivedStateFromProps(props: MultiAssignComponentProps, state: MultiAssignComponentState) {
        const present = props.allIds.reduce((all, id) => {
            all[id] = true;
            return all;
        }, {});
        const order = (state.order.length === 0)
            ? [
                MultiAssignComponent.MULTI_ASSIGN_BOUNDARY,
                ...props.allIds.filter((id) => (props.selectedIds[id])),
                MultiAssignComponent.MULTI_ASSIGN_BOUNDARY,
                ...props.allIds.filter((id) => (!props.selectedIds[id]))
            ]
            : state.order
                .filter((id) => (id === MultiAssignComponent.MULTI_ASSIGN_BOUNDARY || present[id]))
                .concat(props.allIds.filter((id) => (!state.present[id])));
        return {order, present};
    }

    constructor(props: MultiAssignComponentProps) {
        super(props);
        this.state = {
            order: [],
            present: {}
        };
        this.onSortEnd = this.onSortEnd.bind(this);
    }

    private onSortEnd({oldIndex, newIndex}) {
        const order = arrayMove(this.state.order, oldIndex, newIndex);
        this.setState({order});
        let selected = false;
        order.forEach((id) => {
            if (id === MultiAssignComponent.MULTI_ASSIGN_BOUNDARY) {
                selected = !selected;
            } else if ((this.props.selectedIds[id] === true) !== selected) {
                this.props.onSelectionChanged(id, selected);
            }
        });
    }

    public render() {
        return (
            <ReorderableAddableContainer disableAdd={true} onSortEnd={this.onSortEnd}>
                {
                    this.state.order
                        .map((id, index) => {
                            return (
                                <ReorderableDeleteableElement
                                    index={index} disableDelete={true} className={classNames('dragHandleAtTop', 'userDraggable', {
                                        boundaryGroup: id === MultiAssignComponent.MULTI_ASSIGN_BOUNDARY,
                                        assignedGroup: this.props.selectedIds[id] && id !== MultiAssignComponent.MULTI_ASSIGN_BOUNDARY,
                                        notAssignedGroup: !this.props.selectedIds[id] && id !== MultiAssignComponent.MULTI_ASSIGN_BOUNDARY
                                    })}
                                    key={id === MultiAssignComponent.MULTI_ASSIGN_BOUNDARY ? 'boundary' + index : id}
                                >
                                    {
                                        id === MultiAssignComponent.MULTI_ASSIGN_BOUNDARY
                                            ? <div className='multiAssignBoundary'><hr/></div>
                                            : this.props.renderItem(id)
                                    }
                                </ReorderableDeleteableElement>
                            );
                        })
                }
            </ReorderableAddableContainer>
        );
    }
    
}