import React from "react";

import SVGText from "./SVGText";

const Label = ({transform, link, onDoubleClick, onClick, width, height, textStyle, horizontalPadding, visibility}) => (
    <g
        transform={transform}
        onDoubleClick={onDoubleClick}
        onMouseDown={e => {
            e.preventDefault();
            e.stopPropagation();
        }}
        onClick={onClick}
        style={
            visibility === "hidden" ? { } : { pointerEvents: "bounding-box" }
        }
        cursor="pointer"
    >
        <SVGText
            style={{
                ...textStyle
            }}
            x={-width / 2}
            y={-height / 2}
            width={width}
            height={height}
            horizontalPadding={horizontalPadding}
            horizontalAlign="center"
            verticalAlign="middle"
        >
            {link.label}
        </SVGText>
    </g>
);

export default Label;
//<rect/>
