import * as constants from './constants';
import {
    FEATURE_CANDIDATE_SOLUTION_TYPE, FEATURE_EXPLORE_PROBLEM_TYPE, STEP_MODE_DELPHI_EXCHANGE, STEP_MODE_OPEN
} from './featureFlags';
import {SingleVariableStateTypeData} from '../reducers/keyVariablesReducer';

const userRoles = {
    [constants.ROLE_ROBOT]: { id: constants.ROLE_ROBOT, role: constants.ROLE_ROBOT_NAME },
    [constants.ROLE_FACILITATOR]: { id: constants.ROLE_FACILITATOR, role: constants.ROLE_FACILITATOR_NAME },
    [constants.ROLE_ANALYST]: { id: constants.ROLE_ANALYST, role: constants.ROLE_ANALYST_NAME },
    [constants.ROLE_OBSERVER]: { id: constants.ROLE_OBSERVER, role: constants.ROLE_OBSERVER_NAME },
    [constants.ROLE_SOLO_ANALYST]: { id: constants.ROLE_SOLO_ANALYST, role: constants.ROLE_SOLO_ANALYST_NAME },
    [constants.ROLE_UNALLOCATED]: { id: constants.ROLE_UNALLOCATED, role: constants.ROLE_UNALLOCATED_NAME },
    [constants.ROLE_ROBOT_NAME]: { id: constants.ROLE_ROBOT, role: constants.ROLE_ROBOT_NAME },
    [constants.ROLE_FACILITATOR_NAME]: { id: constants.ROLE_FACILITATOR, role: constants.ROLE_FACILITATOR_NAME },
    [constants.ROLE_ANALYST_NAME]: { id: constants.ROLE_ANALYST, role: constants.ROLE_ANALYST_NAME },
    [constants.ROLE_OBSERVER_NAME]: { id: constants.ROLE_OBSERVER, role: constants.ROLE_OBSERVER_NAME },
    [constants.ROLE_SOLO_ANALYST_NAME]: { id: constants.ROLE_SOLO_ANALYST, role: constants.ROLE_SOLO_ANALYST_NAME },
    [constants.ROLE_UNALLOCATED_NAME]: { id: constants.ROLE_UNALLOCATED, role: constants.ROLE_UNALLOCATED_NAME },
};

export interface SubtabData {
    title: string;
    description: string;
    round?: number[];
    tags?: string[];
    facilitatorTags?: string[];
    visibleRole?: number | number[];
    enabledRole?: number | number[];
    problemType?: number | number[];
}

const commonSubtabs: SubtabData[] = [
    {
        title: constants.SUBTAB_MY_DRAFT,
        round: [constants.ROUND_PRIVATE, constants.ROUND_EXCHANGE, constants.ROUND_DELPHI_EXCHANGE, constants.ROUND_OPEN],
        visibleRole: [constants.ROLE_ANALYST, constants.ROLE_SOLO_ANALYST],
        description: 'My proposed contribution'
    },
    {
        title: constants.SUBTAB_PUBLISHED,
        round: [constants.ROUND_EXCHANGE, constants.ROUND_DELPHI_EXCHANGE, constants.ROUND_OPEN + constants.ROUND_PUBLISHED],
        tags: [constants.TAG_DRAFT_PUBLISHED],
        visibleRole: [constants.ROLE_ANALYST, constants.ROLE_FACILITATOR, constants.ROLE_OBSERVER],
        description: 'Other Analysts\' contribution'
    },
    {
        title: constants.SUBTAB_GROUP_DRAFT,
        visibleRole: constants.ROLE_FACILITATOR,
        description: 'Group\'s proposed contribution'
    },
    {
        title: constants.SUBTAB_GROUP,
        round: [constants.ROUND_EXCHANGE, constants.ROUND_DELPHI_EXCHANGE, constants.ROUND_OPEN + constants.ROUND_PUBLISHED],
        tags: [constants.TAG_GROUP_DRAFT_PUBLISHED],
        visibleRole: [constants.ROLE_ANALYST, constants.ROLE_FACILITATOR, constants.ROLE_OBSERVER],
        description: 'Group consensus for this step'
    },
    {
        title: constants.SUBTAB_DISCUSSION,
        round: [constants.ROUND_EXCHANGE, constants.ROUND_DELPHI_EXCHANGE, constants.ROUND_OPEN + constants.ROUND_PUBLISHED],
        tags: [constants.TAG_DISCUSSION],
        visibleRole: [constants.ROLE_ANALYST, constants.ROLE_FACILITATOR, constants.ROLE_OBSERVER],
        description: 'Discussion about the current step'
    }
];

function mergeArrays(array1, array2) {
    return array1.map((base, index) => ({...base, ...array2[index]}));
}

const commonSubtabsDelphiExchange = mergeArrays(commonSubtabs, [
    {},
    {
        enabledRole: [constants.ROLE_ANALYST, constants.ROLE_FACILITATOR],  // Published subtab is disabled for Observer.
        tags: null,                                                         // No notifications for the Published subtab
        facilitatorTags: [constants.TAG_DRAFT_PUBLISHED]                    // ... except for the Facilitator.
    }
]);

const strathclydeSubtabs: SubtabData[] = mergeArrays(commonSubtabs, [
    {},
    {},
    {enabledRole: []},  // Disable Group Draft subtab for everyone
    {enabledRole: []},  // Disable Group subtab for everyone
    {enabledRole: []}   // Disable Discussion subtab for everyone
]);

export interface StepDescription {
    id: number;
    description: string;
    nextStep: number | number[] | null;
    nextStepSyncVariable?: string;
    name: string;
    startingStep?: boolean;
    launchStep?: boolean;
    tabName?: string;
    tabClassName?: string;
    subtabClassName?: string;
    parentStepId?: number;
    syncVariables?: string[];
    stepRounds?: constants.ROUND[];
    notificationRounds?: number;
    tags?: string[];
    facilitatorTags?: string[];
    subTabs?: SubtabData[];
    copyPublished?: string;
    visibleRole?: number | number[];
    futureEnabled?: number[];
    ratingSyncVariable?: string;
}

export interface StepDescriptionMap {
    [key: number]: StepDescription;
}

const stepDescriptions: StepDescriptionMap = {
    [constants.STEP_NOT_LAUNCHED]: {
        id: constants.STEP_NOT_LAUNCHED,
        description: "NOT LAUNCHED",
        name: 'NOT STARTED',
        nextStep: constants.STEP_EXPLORE_PROBLEM
    },
    [constants.STEP_EXPLORE_PROBLEM]: {
        id: constants.STEP_EXPLORE_PROBLEM,
        startingStep: true,
        launchStep: true,
        tabName: "1. EXPLORE PROBLEM",
        name: "EXPLORE PROBLEM",
        description: "Key problem elements",
        nextStep: [constants.STEP_BAYESIAN_SOLUTION, constants.STEP_NBR_CANDIDATE_SOLUTION],
        nextStepSyncVariable: constants.SYNC_METHOD_SELECTION,
        syncVariables: [constants.SYNC_KEY_QUESTIONS, constants.SYNC_KEY_FACTS, constants.SYNC_METHOD_SELECTION, constants.SYNC_COMMENTS_SUGGESTIONS],
        stepRounds: [constants.ROUND_PRIVATE, constants.ROUND_EXCHANGE],
        notificationRounds: constants.ROUND_EXCHANGE,
        tags: [constants.TAG_DRAFT_PUBLISHED, constants.TAG_GROUP_DRAFT_PUBLISHED, constants.TAG_DISCUSSION],
        subTabs: commonSubtabs,
        copyPublished: constants.COPY_PUBLISHED_APPEND
    },
    [constants.STEP_BAYESIAN_SOLUTION]: {
        id: constants.STEP_BAYESIAN_SOLUTION,
        name: "BUILD BAYESIAN NETWORK",
        description: "Build a Bayesian Network to model the intelligence problem ",
        nextStep: [constants.STEP_BR_CANDIDATE_SOLUTION, constants.STEP_COMBINED_REPORT],
        stepRounds: [constants.ROUND_OPEN],
    },
    [constants.STEP_VARIABLES]: {
        id: constants.STEP_VARIABLES,
        startingStep: true,
        tabName: "2. VARIABLES",
        name: "VARIABLES",
        tabClassName: 'bayesianSolnStart',
        description: "Variables, types and states",
        nextStep: constants.STEP_STRUCTURE,
        syncVariables: [constants.SYNC_KEY_VARIABLES],
        parentStepId: constants.STEP_BAYESIAN_SOLUTION,
        notificationRounds: constants.ROUND_OPEN + constants.ROUND_PUBLISHED,
        tags: [constants.TAG_DRAFT_PUBLISHED, constants.TAG_GROUP_DRAFT_PUBLISHED, constants.TAG_DISCUSSION],
        subTabs: commonSubtabs,
        copyPublished: constants.COPY_PUBLISHED_REPLACE
    },
    [constants.STEP_STRUCTURE]: {
        id: constants.STEP_STRUCTURE,
        tabName: "3. STRUCTURE",
        name: "STRUCTURE",
        tabClassName: 'bayesianSolnMid',
        description: "Relationships between variables",
        nextStep: constants.STEP_PARAMETERS,
        syncVariables: [constants.SYNC_BN_GRAPH],
        parentStepId: constants.STEP_BAYESIAN_SOLUTION,
        notificationRounds: constants.ROUND_OPEN + constants.ROUND_PUBLISHED,
        tags: [constants.TAG_DRAFT_PUBLISHED, constants.TAG_GROUP_DRAFT_PUBLISHED, constants.TAG_DISCUSSION],
        subTabs: commonSubtabs,
        copyPublished: constants.COPY_PUBLISHED_REPLACE
    },
    [constants.STEP_PARAMETERS]: {
        id: constants.STEP_PARAMETERS,
        tabName: "4. PARAMETERS",
        name: "PARAMETERS",
        tabClassName: 'bayesianSolnMid',
        description: "Conditional probabilities",
        nextStep: constants.STEP_EXPLORE_MODEL,
        syncVariables: [constants.SYNC_BN_PARAMETERS],
        parentStepId: constants.STEP_BAYESIAN_SOLUTION,
        notificationRounds: constants.ROUND_OPEN + constants.ROUND_PUBLISHED,
        tags: [constants.TAG_DRAFT_PUBLISHED, constants.TAG_GROUP_DRAFT_PUBLISHED, constants.TAG_DISCUSSION],
        subTabs: commonSubtabs,
        copyPublished: constants.COPY_PUBLISHED_REPLACE
    },
    [constants.STEP_EXPLORE_MODEL]: {
        id: constants.STEP_EXPLORE_MODEL,
        tabName: "5. EXPLORE NETWORK",
        name: "EXPLORE NETWORK",
        tabClassName: 'bayesianSolnEnd',
        description: "Explore problem scenarios",
        nextStep: null,
        syncVariables: [constants.SYNC_EXPLORE_MODEL],
        parentStepId: constants.STEP_BAYESIAN_SOLUTION,
        notificationRounds: constants.ROUND_OPEN + constants.ROUND_PUBLISHED,
        tags: [constants.TAG_DRAFT_PUBLISHED, constants.TAG_GROUP_DRAFT_PUBLISHED, constants.TAG_DISCUSSION],
        subTabs: commonSubtabs,
        copyPublished: constants.COPY_PUBLISHED_REPLACE
    },
    [constants.STEP_BR_CANDIDATE_SOLUTION]: {
        id: constants.STEP_BR_CANDIDATE_SOLUTION,
        tabName: "6. CANDIDATE SOLUTION",
        name: "CANDIDATE SOLUTION",
        description: "Answer the problem questions",
        nextStep: constants.STEP_BR_REPORT,
        syncVariables: [constants.SYNC_CANDIDATE_SOLUTION],
        stepRounds: [constants.ROUND_PRIVATE, constants.ROUND_EXCHANGE],
        notificationRounds: constants.ROUND_EXCHANGE,
        tags: [constants.TAG_DRAFT_PUBLISHED, constants.TAG_GROUP_DRAFT_PUBLISHED, constants.TAG_DISCUSSION],
        subTabs: commonSubtabs,
        copyPublished: constants.COPY_PUBLISHED_APPEND,
        futureEnabled: [constants.STEP_BAYESIAN_SOLUTION]
    },
    [constants.STEP_BR_REPORT]: {
        id: constants.STEP_BR_REPORT,
        tabName: "7. REPORT",
        name: "REPORT",
        description: "Final intelligence report",
        nextStep: null,
        syncVariables: [constants.SYNC_REPORT],
        stepRounds: [constants.ROUND_REPORT_EXCHANGE],
        notificationRounds: constants.ROUND_REPORT_EXCHANGE,
        tags: [constants.TAG_GROUP_DRAFT_PUBLISHED, constants.TAG_DISCUSSION],
        subTabs: [
            {title: constants.SUBTAB_GROUP_DRAFT, visibleRole: constants.ROLE_FACILITATOR, description: 'Group\'s proposed contribution'},
            {title: constants.SUBTAB_GROUP, description: 'Group consensus for this step'},
            {title: constants.SUBTAB_DISCUSSION, tags: [constants.TAG_DISCUSSION], description: 'Discussion about the current step'}
        ]
    },
    [constants.STEP_NBR_CANDIDATE_SOLUTION]: {
        id: constants.STEP_NBR_CANDIDATE_SOLUTION,
        tabName: "6. CANDIDATE SOLUTION",
        name: "CANDIDATE SOLUTION (Non-Bayesian)",
        description: "Answer the problem questions",
        nextStep: constants.STEP_NBR_REPORT,
        syncVariables: [constants.SYNC_CANDIDATE_SOLUTION],
        stepRounds: [constants.ROUND_PRIVATE, constants.ROUND_EXCHANGE],
        notificationRounds: constants.ROUND_EXCHANGE,
        tags: [constants.TAG_DRAFT_PUBLISHED, constants.TAG_GROUP_DRAFT_PUBLISHED, constants.TAG_DISCUSSION],
        subTabs: commonSubtabs,
        copyPublished: constants.COPY_PUBLISHED_APPEND
    },
    [constants.STEP_NBR_REPORT]: {
        id: constants.STEP_NBR_REPORT,
        tabName: "7. REPORT",
        name: "REPORT",
        description: "Final intelligence report",
        nextStep: null,
        syncVariables: [constants.SYNC_REPORT],
        stepRounds: [constants.ROUND_REPORT_EXCHANGE],
        notificationRounds: constants.ROUND_REPORT_EXCHANGE,
        tags: [constants.TAG_GROUP_DRAFT_PUBLISHED, constants.TAG_DISCUSSION],
        subTabs: [
            {title: constants.SUBTAB_GROUP_DRAFT, visibleRole: constants.ROLE_FACILITATOR, description: 'Group\'s proposed contribution'},
            {title: constants.SUBTAB_GROUP, description: 'Group consensus for this step'},
            {title: constants.SUBTAB_DISCUSSION, tags: [constants.TAG_DISCUSSION], description: 'Discussion about the current step'}
        ]
    },
    [constants.STEP_COMBINED_REPORT]: {
        id: constants.STEP_COMBINED_REPORT,
        tabName: '6. REPORT',
        name: 'REPORT',
        description: 'Answer the problem questions and create final intelligence report',
        nextStep: null,
        syncVariables: [constants.SYNC_REPORT],
        stepRounds: [constants.ROUND_OPEN],
        notificationRounds: constants.ROUND_OPEN + constants.ROUND_PUBLISHED,
        tags: [constants.TAG_DRAFT_PUBLISHED, constants.TAG_GROUP_DRAFT_PUBLISHED, constants.TAG_DISCUSSION],
        subTabs: commonSubtabs,
        futureEnabled: [constants.STEP_BAYESIAN_SOLUTION],
        ratingSyncVariable: constants.SYNC_REPORT,
        copyPublished: constants.COPY_PUBLISHED_APPEND
    },
    [constants.STEP_STRATHCLYDE_EXPLORE_PROBLEM]: {
        id: constants.STEP_STRATHCLYDE_EXPLORE_PROBLEM,
        startingStep: true,
        tabName: "1. EXPLORE PROBLEM",
        name: "EXPLORE PROBLEM",
        description: "Key problem elements",
        nextStep: [constants.STEP_SINGLE_VARIABLE],
        syncVariables: [constants.SYNC_KEY_QUESTIONS, constants.SYNC_KEY_FACTS, constants.SYNC_METHOD_SELECTION, constants.SYNC_COMMENTS_SUGGESTIONS],
        stepRounds: [constants.ROUND_EXCHANGE],
        notificationRounds: constants.ROUND_EXCHANGE,
        tags: [constants.TAG_DRAFT_PUBLISHED, constants.TAG_GROUP_DRAFT_PUBLISHED, constants.TAG_DISCUSSION],
        subTabs: strathclydeSubtabs,
        copyPublished: constants.COPY_PUBLISHED_APPEND
    },
    [constants.STEP_SINGLE_VARIABLE]: {
        id: constants.STEP_SINGLE_VARIABLE,
        tabName: '2. VARIABLES',
        name: 'VARIABLES',
        description: "Variables, types and states",
        nextStep: constants.STEP_SINGLE_STRUCTURE,
        syncVariables: [constants.SYNC_KEY_VARIABLES],
        stepRounds: [constants.ROUND_EXCHANGE],
        notificationRounds: constants.ROUND_EXCHANGE,
        tags: [constants.TAG_DRAFT_PUBLISHED, constants.TAG_GROUP_DRAFT_PUBLISHED, constants.TAG_DISCUSSION],
        subTabs: strathclydeSubtabs,
        copyPublished: constants.COPY_PUBLISHED_REPLACE
    },
    [constants.STEP_SINGLE_STRUCTURE]: {
        id: constants.STEP_SINGLE_STRUCTURE,
        tabName: '3. STRUCTURE',
        name: 'STRUCTURE',
        description: "Relationships between variables",
        nextStep: null,
        syncVariables: [constants.SYNC_BN_GRAPH],
        stepRounds: [constants.ROUND_PRIVATE, constants.ROUND_READ_ONLY, constants.ROUND_PRIVATE, constants.ROUND_READ_ONLY, constants.ROUND_EXCHANGE],
        notificationRounds: constants.ROUND_EXCHANGE,
        tags: [constants.TAG_DRAFT_PUBLISHED, constants.TAG_GROUP_DRAFT_PUBLISHED, constants.TAG_DISCUSSION],
        subTabs: strathclydeSubtabs,
        copyPublished: constants.COPY_PUBLISHED_REPLACE,
        ratingSyncVariable: constants.SYNC_BN_GRAPH
    },
    [constants.STEP_FACILITATOR_ADMIN]: {
        id: constants.STEP_FACILITATOR_ADMIN,
        name: "ADMIN",
        tabClassName: 'additionalTab roleColour roleBackgroundColourOnSelected',
        subtabClassName: 'roleBackgroundColour',
        visibleRole: constants.ROLE_FACILITATOR,
        description: "Problem administration and current state",
        syncVariables: [],
        stepRounds: [],
        nextStep: null,
        tags: [constants.TAG_OPT_OUT_REQUEST],
        subTabs: [
            {
                title: constants.SUBTAB_INFORMATION_FACILITATOR,
                description: 'Information and Admin section'
            },
            {
                title: constants.SUBTAB_GROUP_MANAGEMENT,
                tags: [constants.TAG_OPT_OUT_REQUEST],
                problemType: constants.PROBLEM_T_AND_E,
                description: 'Group Management section'
            }
        ]
    },
    [constants.STEP_PROBLEM_STATUS]: {
        id: constants.STEP_PROBLEM_STATUS,
        name: "STATUS",
        tabClassName: 'additionalTab',
        visibleRole: [constants.ROLE_ANALYST, constants.ROLE_OBSERVER],
        description: "Problem current state",
        syncVariables: [],
        stepRounds: [],
        nextStep: null,
        tags: [],
        subTabs: [
            {title: constants.SUBTAB_INFORMATION_ANALYST, description: 'Information section'}
        ]
    }
};

const stepDescriptionsDelphi = {
    [constants.STEP_EXPLORE_PROBLEM]: {
        stepRounds: [constants.ROUND_PRIVATE, constants.ROUND_DELPHI_EXCHANGE],
        notificationRounds: constants.ROUND_DELPHI_EXCHANGE,
        tags: [constants.TAG_GROUP_DRAFT_PUBLISHED, constants.TAG_DISCUSSION],
        facilitatorTags: [constants.TAG_DRAFT_PUBLISHED, constants.TAG_GROUP_DRAFT_PUBLISHED, constants.TAG_DISCUSSION],
        subTabs: commonSubtabsDelphiExchange
    },
    [constants.STEP_BR_CANDIDATE_SOLUTION]: {
        stepRounds: [constants.ROUND_PRIVATE, constants.ROUND_DELPHI_EXCHANGE],
        notificationRounds: constants.ROUND_DELPHI_EXCHANGE,
        tags: [constants.TAG_GROUP_DRAFT_PUBLISHED, constants.TAG_DISCUSSION],
        facilitatorTags: [constants.TAG_DRAFT_PUBLISHED, constants.TAG_GROUP_DRAFT_PUBLISHED, constants.TAG_DISCUSSION],
        subTabs: commonSubtabsDelphiExchange
    },
    [constants.STEP_NBR_CANDIDATE_SOLUTION]: {
        stepRounds: [constants.ROUND_PRIVATE, constants.ROUND_DELPHI_EXCHANGE],
        notificationRounds: constants.ROUND_DELPHI_EXCHANGE,
        tags: [constants.TAG_GROUP_DRAFT_PUBLISHED, constants.TAG_DISCUSSION],
        facilitatorTags: [constants.TAG_DRAFT_PUBLISHED, constants.TAG_GROUP_DRAFT_PUBLISHED, constants.TAG_DISCUSSION],
        subTabs: commonSubtabsDelphiExchange
    }
};

const openRoundChanges = {
    stepRounds: [constants.ROUND_OPEN],
    notificationRounds: constants.ROUND_OPEN + constants.ROUND_PUBLISHED
};

const stepDescriptionsOpen = {
    [constants.STEP_EXPLORE_PROBLEM]: openRoundChanges,
    [constants.STEP_BR_CANDIDATE_SOLUTION]: openRoundChanges,
    [constants.STEP_NBR_CANDIDATE_SOLUTION]: openRoundChanges
};

const stepDescriptionFeatureFlagsByStepId = {
    [constants.STEP_EXPLORE_PROBLEM]: FEATURE_EXPLORE_PROBLEM_TYPE,
    [constants.STEP_BR_CANDIDATE_SOLUTION]: FEATURE_CANDIDATE_SOLUTION_TYPE,
    [constants.STEP_NBR_CANDIDATE_SOLUTION]: FEATURE_CANDIDATE_SOLUTION_TYPE
};

const additionalConditionalSteps = [constants.STEP_FACILITATOR_ADMIN, constants.STEP_PROBLEM_STATUS];

const variableStateTypeData: SingleVariableStateTypeData[] = [
    {
        type: "Boolean",
        label: "True/False",
        minStates: 2,
        maxStates: 2,
        fixedValues: ["True", "False"],
        reorderable: true
    },
    {
        type: "Binary",
        minStates: 2,
        maxStates: 2,
        fixedValues: null,
        reorderable: true
    },
    {
        type: "Ordered",
        minStates: 2,
        maxStates: 10,
        fixedValues: null,
        reorderable: true,
        migrationDefault: true
    },
    {
        type: "Unordered",
        minStates: 2,
        maxStates: 10,
        fixedValues: null,
        reorderable: false
    }
];

const problemStates = {
    [constants.STATE_NOT_READY]: {
        id: constants.STATE_NOT_READY,
        stateName: "Not Ready",
        description: "Problem is not ready for launch"
    },
    [constants.STATE_READY]: {
        id: constants.STATE_READY,
        stateName: "Ready",
        description: "Problem is ready for launch"
    },
    [constants.STATE_ACTIVE]: {
        id: constants.STATE_ACTIVE,
        stateName: "Active",
        description: "Problem has been launched and is active"
    },
    [constants.STATE_SUSPENDED]: {
        id: constants.STATE_SUSPENDED,
        stateName: "Suspended",
        description: "Problem is suspended"
    },
    [constants.STATE_CLOSED]: {
        id: constants.STATE_CLOSED,
        stateName: "Closed",
        description: "Problem is closed"
    }
};

const rounds = {
    [constants.ROUND_PRIVATE]: {
        id: constants.ROUND_PRIVATE,
        name: "Private",
        description: "Private Round"
    },
    [constants.ROUND_EXCHANGE]: {
        id: constants.ROUND_EXCHANGE,
        name: "Exchange",
        description: "Exchange Round"
    },
    [constants.ROUND_DELPHI_EXCHANGE]: {
        id: constants.ROUND_DELPHI_EXCHANGE,
        name: "Exchange",
        description: "Exchange Round"
    },
    [constants.ROUND_OPEN]: {
        id: constants.ROUND_OPEN,
        name: "Open",
        description: "Open Round"
    },
    [constants.ROUND_REPORT_EXCHANGE]: {
        id: constants.ROUND_REPORT_EXCHANGE,
        name: "Open",
        description: "Open Round"
    },
    [constants.ROUND_READ_ONLY]: {
        id: constants.ROUND_READ_ONLY,
        name: "Read-Only",
        description: "Read-Only"
    }
};

function mergeStepDescriptionForStepId(stepDescriptions, stepId, alternative) {
    return {
        ...stepDescriptions,
        [stepId]: {
            ...stepDescriptions[stepId],
            ...alternative[stepId]
        }
    };
}

export default class ReferenceData {
    static instance: ReferenceData;
    static getInstance(): ReferenceData {
        return ReferenceData.instance || (ReferenceData.instance = new ReferenceData());
    }

    private stepDescriptionsMerged;

    public updateFeatureFlags(flags) {
        this.stepDescriptionsMerged = stepDescriptions;
        Object.keys(stepDescriptionFeatureFlagsByStepId).forEach((stepId) => {
            const featureName = stepDescriptionFeatureFlagsByStepId[stepId];
            if (flags[featureName]) {
                switch (flags[featureName].value) {
                    case STEP_MODE_DELPHI_EXCHANGE:
                        this.stepDescriptionsMerged = mergeStepDescriptionForStepId(this.stepDescriptionsMerged, stepId, stepDescriptionsDelphi);
                        break;
                    case STEP_MODE_OPEN:
                        this.stepDescriptionsMerged = mergeStepDescriptionForStepId(this.stepDescriptionsMerged, stepId, stepDescriptionsOpen);
                        break;
                    default:
                }
            }
        });
    }

    public getAllUserRoles() {
        return userRoles;
    }
    
    public getAllStepDescriptions(): StepDescriptionMap {
        return this.stepDescriptionsMerged || stepDescriptions;
    }
    
    public getStepDescription(stepId: constants.STEP): StepDescription {
        return this.getAllStepDescriptions()[stepId];
    }

    public getStepDescriptionName(stepId: constants.STEP): string {
        const stepDescription = this.getAllStepDescriptions()[stepId];
        return (stepDescription && stepDescription.name) ? stepDescription.name : '';
    }

    public getAllRounds() {
        return rounds;
    }

    public getRound(roundId: constants.ROUND) {
        return rounds[roundId];
    }
    
    public getAllProblemStates() {
        return problemStates;
    }
    
    public getVariableStateTypeData() {
        return variableStateTypeData;
    }

    public getAdditionalConditionalSteps() {
        return additionalConditionalSteps;
    }

    public getFeatureFlagValueForStepId(stepId, flags) {
        const featureName = stepDescriptionFeatureFlagsByStepId[stepId];
        return featureName && flags[featureName].value;
    }

    public getOpenRoundChanges() {
        return openRoundChanges;
    }

}
