import {ACTIVITY_USER_AUTHENTICATION, ACTIVITY_USER_OPEN_PROBLEM, ACTIVITY_USER_VIEW, ACTIVITY_USER_LOGOUT, ACTIVITY_USER_LOGIN} from '../../common/util/constants';
import * as constants from '../../common/util/constants';
export const endpointURL = '/api/user/activity';

export const handleRouteChange = (initialPath) => {
    if(initialPath !== '/'){
        trackActivityByPath(initialPath);
    }
    
    return (location) => {
        const path = (!!location && !!location.pathname)
            ? location.pathname
            : '';
        trackActivityByPath(path);
    }
};

export const trackActivityByPath = (path) => {
    const activity = pathToProblemActivity(path);

    if (!activity) {
        return;
    }
    return sendActivity(activity);
};

export const trackLoginActivity = () => {
    sendActivity({type: ACTIVITY_USER_AUTHENTICATION, subType: ACTIVITY_USER_LOGIN}); 
};
export const trackLogoutActivity = () => sendActivity({type: ACTIVITY_USER_AUTHENTICATION, subType: ACTIVITY_USER_LOGOUT});

export const sendActivity = (activity) => {
    try {
        const headers = new Headers({'Content-Type': 'application/json'});

        return fetch(endpointURL, {
            method: 'put',
            headers,
            credentials: 'include',
            body: JSON.stringify(activity)
        });
    } catch (err) {}
};

export const sendUserOpenProblem = (problemStepId) => {
    return sendActivity({
        problemStepId,
        type: ACTIVITY_USER_VIEW,
        subType: ACTIVITY_USER_OPEN_PROBLEM
    });
};

const getStepName = (urlStepLocation, urlSubStep) => {

    if(!urlSubStep){
        return null;
    }

    const stepNameParts = urlStepLocation.split('-');
    if(stepNameParts.length < 2){
        return stepNameParts[0];
    }
    let stepName = stepNameParts[1];

    if(stepNameParts[2]){
        stepName = stepName+'_'+stepNameParts[2]+'_'+urlSubStep;
    }else if(urlSubStep){
        stepName = stepName+'_'+urlSubStep;
    }
    
    return stepName;
};

/**
 * Based on the path to extract all relevant information like problem ID, step ID, etc.
 *
 * @param {*} path
 */
export const pathToProblemActivity = (path) => {
    path = path || '';
    const parts = path
        .split('/')
        .filter(v => !!v);

        if(parts.length === 0){
            return null;
        }

    // path starts with 'select'
    if (parts.length < 2 && parts[0] === constants.ACTIVITY_FOYER){
        return {type: ACTIVITY_USER_VIEW, subType: constants.ACTIVITY_USER_VIEW_FOYER};
    }
    
    //record opening of the problem description in a new tab
    if(parts[0] === constants.ACTIVITY_DESCRIPTION){
        return {type: ACTIVITY_USER_VIEW, subType: constants.ACTIVITY_PROBLEM_DESCRIPTION};
    }
    
    //On opening a problem from Foyer, incomplete path is obtained. Skip logging the location in that case. Open problem activity has already been logged
    if(parts.length === 2 && parts[0] === constants.ACTIVITY_PROBLEM_ROUTE_NAME){
        return null;
    }

    const problemSelected = parts[1];

    let activitySubType= constants.ACTIVITY_USER_VIEW;
    let activityLocation = parts[2] ? parts[2].split("-")[0] : null;

    if(activityLocation)
    { 
        if(!isNaN(activityLocation)){
            activitySubType = getStepName(parts[2], parts[3]);
        }else{
            activitySubType = 'user_view_'+activityLocation;
        }
        
    }

    if(activitySubType){
        return {problemSelected, type: ACTIVITY_USER_VIEW, subType: activitySubType};
    }else return null;
};