import React, {Component} from 'react';
import PropTypes from 'prop-types'
import moment from 'moment';

class Timer extends Component {

    static propTypes = {
        className: PropTypes.string,
        deadline: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        suffix: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = {
            deadline: moment(props.deadline)
        };
    }

    timerText() {
        const seconds = this.state.deadline.diff(moment(), 'seconds');
        if (seconds < 0) {
            return '00:00';
        } else if (seconds < 60) {
            return '00:' + `0${seconds}`.slice(-2);
        } else {
            return this.state.deadline.fromNow(this.props.suffix);
        }
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({deadline: moment(props.deadline)});
    }

    componentDidMount() {
        this.setState({timerText: this.timerText()});
        let timerHandler = setInterval(() => {
            this.setState({timerText: this.timerText()});
        }, 1000);

        this.setState({
            ...this.state,
            timerHandler: timerHandler
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.timerHandler);
    }

    render() {
        return (
            <span className={this.props.className ? this.props.className : 'timerDisplay'}>{this.state.timerText}</span>
        );
    }
}

export default Timer;
