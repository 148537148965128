import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Button, DialogContainer, FontIcon} from 'react-md';

import * as constants from '../../common/util/constants';

import {getUserNotesFromStore, setUserNotesAction} from '../../common/reducers/userNotesReducer';
import RichTextField from './RichTextField';
import {getLoggedInUserIdFromStore} from '../reducers/loginReducer';
import {getProblemDBFromStore} from '../../common/reducers/allProblemsReducer';
import {buildRestPath, UPLOAD_PROBLEM_IMAGE_PATH} from '../../common/clientServer/navigation';
import {getProblemStepFromStore} from '../../common/reducers/allTeamsReducerGetters';
import {getUserForProblemFromStore} from '../../common/reducers/allUsersForTeamReducerGetters';

class UserNotes extends Component {

    static propTypes = {
        problemStepId: PropTypes.number.isRequired
    };
    
    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            text: props.text
        };
        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onChange = this.onChange.bind(this);
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({
            text: props.text
        })
    }

    openModal() {
        this.setState({
            modalIsOpen: true
        });
    }

    afterOpenModal() { }

    closeModal() {
        this.setState({
            modalIsOpen: false
        });
        this.props.dispatch(setUserNotesAction(this.state.text, this.props.problemStepId, this.props.userId));
    }

    onChange(text) {
        this.setState({
            text
        });
    }

    render() {
        let tipmsg = <div className='pageTip'>{this.props.userRole === constants.ROLE_SOLO_ANALYST ? "These notes are for your own use and don't become part of the submitted report." : "These notes are for your own use and are not shared with the Facilitator or other Analysts. Use 'Discussion' if you wish to share your notes with others."}</div>;
        if (typeof(this.props.userId) === 'undefined') {
            return null;
        } else {
            return (
                <div className='headerNotes'>
                    <div className="toolTipContainer" onClick={this.props.disabled ? null : this.openModal}>
                        <FontIcon className={"md-22 md-link notesIcon" + (this.props.disabled ? " disabled" : "")}>note</FontIcon>
                        <div className="headerNotesLabel">MY NOTES</div>
                        {this.props.disabled ? 
                             <span className="toolTip toolTipNotes">Notes are unavailable for 
                                {this.props.problem.isTraining ? ' Training ' : 
                                (this.props.problemStep.state === constants.STATE_CLOSED ? ' Closed ' : '')} Problems</span>
                            :
                            <span className="toolTip toolTipNotes">Your own private notes for this problem</span>
                        }
                    </div>

                    <DialogContainer
                        id="notesContainer"
                        visible={this.state.modalIsOpen}
                        width='720px'
                        height='485px'
                        title={'Notes'}
                        onHide={this.closeModal}
                        onShow={this.afterOpenModal}
                        actions={[<Button className='primaryActionButton' raised primary onClick={this.closeModal}>CLOSE</Button>]}
                        autosizeContent={false}
                        stackedActions={false}
                    >
                        {tipmsg}
                        <RichTextField
                            className='notesText'
                            text={this.state.text}
                            onChange={this.onChange}
                            config={{
                                uploadUrl: buildRestPath(UPLOAD_PROBLEM_IMAGE_PATH, {problemStepId: this.props.problemStepId}),
                                toolbarCanCollapse: true,
                                readOnly: false
                            }}
                        />
                    </DialogContainer>
                </div>
            );
        }
    }
}

function mapStoreToProps(store, myProps) {
    const userId = getLoggedInUserIdFromStore(store);
    const problemStep = getProblemStepFromStore(store, myProps.problemStepId);
    const problem = getProblemDBFromStore(store, problemStep.problemId);
    const disabled = (problem.isTraining || problemStep.state === constants.STATE_CLOSED);
    const text = disabled ? '' : getUserNotesFromStore(store, userId, myProps.problemStepId);
    const userFromStore = userId && myProps.problemStepId && getUserForProblemFromStore(store, myProps.problemStepId, userId);
    const userRole = (userId && userFromStore) ? userFromStore.db.role : null;
    return {
        userId,
        problem,
        problemStep,
        disabled,
        text,
        userRole
    }
}

export default connect(mapStoreToProps)(UserNotes);
