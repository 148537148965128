import * as constants from '../../common/util/constants';
import {HelpTopic} from './helpContentData';

const imgReportAPublished01 = require('../images/HELP_TIPS_COMBINED_REPORTA_PUBLISHED_01.png');
const imgReportFPublished01 = require('../images/HELP_TIPS_COMBINED_REPORTF_PUBLISHED_01.png');

// TODO File path needs to be updated for Monash experiements
const helpContentUrlPrefix = `https://bard-problem-resources.s3.amazonaws.com/help-content/CREATE`;

const helpTips: { [key: string]: HelpTopic } = {
    [constants.HELP_EXPLORE_PROBLEM_READ]: {
        id: constants.HELP_EXPLORE_PROBLEM_READ,
        title: 'Read Problem: ',
        helpObj: [
            {
                markdown: `
You might like to print out the Problem description to mark up possible hypotheses and evidence, or you can copy and paste the Problem text into MY NOTES, and mark it up there.

Bayesian networks are generally most useful if the problem requires you to weigh conflicting evidence and work out the cause of an event.
                `
            },
            {
                bardRoles: [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER],
                markdown: `
Consider a non-Bayesian solution if the problem can be solved by logic or a mathematical formula.
                `,
                links: [{
                    linkName: 'Guidelines: BN or Non-BN',
                    linkText: `${helpContentUrlPrefix}/bard_guidelines_bn_vs_non-bn.pdf`
                }]
            }
        ]
    },
    [constants.HELP_EXPLORE_PROBLEM_KEY_QUESTIONS]: {
        id: constants.HELP_EXPLORE_PROBLEM_KEY_QUESTIONS,
        title: 'Review Key Questions: ',
        helpObj: [
            {
                bardRoles: [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER],
                markdown: `
The Group’s final report must contain direct answers to all the questions asked.
                `
            },
            {
                bardRoles: [constants.ROLE_SOLO_ANALYST],
                bardModes: [constants.LAUNCH_MODE_SOLO_BARD],
                markdown: `
The final report must contain direct answers to all the questions asked.
                `
            },
            {
                markdown: `
Use the Comments text field to record your thoughts about the question. You might comment, for instance, that the question is similar to another problem you’ve seen.

Remember: Parts of these questions might become items in your Hypotheses list or target variables in your Bayesian network.
                `
            }
        ]
    },
    [constants.HELP_EXPLORE_PROBLEM_HYPOTHESES]: {
        id: constants.HELP_EXPLORE_PROBLEM_HYPOTHESES,
        title: 'Identify Hypotheses: ',
        helpObj: [
            {
                bardRoles: [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER],
                markdown: `
Identifying hypotheses and evidence first will help your group to focus on the most important information to consider when developing your final Report.
                `,
                links: [{
                    linkName: 'Step1: Explore Problem',
                    linkText: `${helpContentUrlPrefix}/bard_step1_explore_problem.pdf`
                }]
            },
            {
                bardRoles: [constants.ROLE_SOLO_ANALYST],
                bardModes: [constants.LAUNCH_MODE_SOLO_BARD],
                markdown: `
Identifying hypotheses and evidence first will help you to focus on the most important information to consider when developing your final Report.
                `
            },
            {
                markdown: `
Your **hypotheses** are often possible answers to the questions listed in the Problem. A given question may have several competing hypotheses which could answer it. By determining the likelihood of each hypothesis given the evidence, you can determine which answer best fits the evidence.

Aim for up to four hypotheses in total.
                `
            }
        ]
    },
    [constants.HELP_EXPLORE_PROBLEM_EVIDENCE]: {
        id: constants.HELP_EXPLORE_PROBLEM_EVIDENCE,
        title: 'Identify Evidence: ',
        helpObj: [
            {
                bardRoles: [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER],
                markdown: `
Identifying hypotheses and evidence first helps your group to focus on the important information given in Problem that should be considered when developing your final report.

If you build a Bayesian network, the evidence is often represented as possible state values of your variables.
                `,
                links: [{
                    linkName: 'Step1: Explore Problem',
                    linkText: `${helpContentUrlPrefix}/bard_step1_explore_problem.pdf`
                }]
            },
            {
                bardRoles: [constants.ROLE_SOLO_ANALYST],
                bardModes: [constants.LAUNCH_MODE_SOLO_BARD],
                markdown: `
Identifying hypotheses and evidence first helps you to focus on the important information given in Problem that should be considered when developing your final report.

When you build a Bayesian network, the evidence is often represented as possible state values of your variables.
                `
            },
            {
                markdown: `
Try to keep your evidence in the form of short bullet points or phrases rather than long, discursive sentences or paragraphs.

It is important that you only identify evidence from within the problem. Do not add things you think are missing or are not already present in the problem.
                `
            }
        ]
    },
    [constants.HELP_EXPLORE_PROBLEM_SELECT_APPROACH]: {
        id: constants.HELP_EXPLORE_PROBLEM_SELECT_APPROACH,
        title: 'Suggest Approach: ',
        helpObj: [
            {
                bardRoles: [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST],
                markdown: `
Remember, if the problem contains a causal story, uncertainty, and conflicting evidence then a Bayesian Network may be best for exploring and solving the problem. If the problem can be solved without a BN, e.g. by logic, or a mathematical formula, consider skipping the Bayesian modelling part.
                `,
                links: [{
                    linkName: 'Guidelines: BN vs Non-BN',
                    linkText: `${helpContentUrlPrefix}/bard_guidelines_bn_vs_non-bn.pdf`
                }]
            }
        ]
    },
    [constants.HELP_EXPLORE_PROBLEM_APPROACH]: {
        id: constants.HELP_EXPLORE_PROBLEM_APPROACH,
        title: 'Suggest Approach: ',
        helpObj: [
            {
                bardRoles: [constants.ROLE_FACILITATOR],
                markdown: `
Remember, if the problem contains a causal story, uncertainty, and conflicting evidence then a Bayesian Network may be best for exploring and solving the problem. If the problem can be solved without a BN, e.g. by logic, or a mathematical formula, consider skipping the Bayesian modelling part.
                `,
                links: [{
                    linkName: 'Guidelines: BN vs Non-BN [PDF]',
                    linkText: `${helpContentUrlPrefix}/bard_guidelines_bn_vs_non-bn.pdf`
                }]
            }
        ]
    },
    [constants.HELP_EXPLORE_PROBLEM_APPROACH_RATIONALE]: {
        id: constants.HELP_EXPLORE_PROBLEM_APPROACH_RATIONALE,
        title: 'Rationale for the suggested Approach: ',
        helpObj: [
            {
                bardRoles: [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST],
                markdown: `
Provide your rationale as input to the group. Also, if there are any other tools or resources that you think would be useful in approaching this problem, enter them here.
                `
            }
        ]
    },
    [constants.HELP_VARIABLES_TARGET_VARIABLES]: {
        id: constants.HELP_VARIABLES_TARGET_VARIABLES,
        title: 'Target Variables: ',
        helpObj: [
            {
                markdown: `
Target Variables represent a hypothesis, a supposition or a Key Question about the problem that must be answered in the final report.

Target Variables are often either true/false or binary (e.g. yes/no).

The most suitable type for each variable depends on the variable’s name and description.
                `
            },
            {
                bardRoles: [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER],
                links: [{linkName: 'Step 2: Variables', linkText: `${helpContentUrlPrefix}/bard_more_on_variables.pdf`}]
            },
        ]
    },
    [constants.HELP_VARIABLES_OTHER_VARIABLES]: {
        id: constants.HELP_VARIABLES_OTHER_VARIABLES,
        title: 'Other Variables: ',
        helpObj: [
            {
                bardRoles: [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER],
                bardModes: [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND],
                markdown: `
Consider adding a variable for each piece of information in the problem description which has been identified by the group as an evidence or hypothesis item during the Explore Problem step.
                `,
                links: [{
                    linkName: 'More On: VARIABLES [PDF]',
                    linkText: `${helpContentUrlPrefix}/bard_more_on_variables.pdf`
                }]
            },
            {
                bardRoles: [constants.ROLE_SOLO_ANALYST],
                bardModes: [constants.LAUNCH_MODE_SOLO_BARD],
                markdown: `
Consider adding a variable for each piece of information in the problem description which you identified as an evidence or hypothesis item during the Explore Problem step.
                `
            },
            {
                bardRoles: [constants.ROLE_ANALYST],
                bardModes: [constants.LAUNCH_MODE_STRATHCLYDE_EXPERIMENT],
                markdown: `
A Variable for each piece of information in the problem description identified as evidence or hypothesis item.
                `
            }
        ]
    },
    [constants.HELP_VARIABLES_DECISION_VARIABLES]: {
        id: constants.HELP_VARIABLES_DECISION_VARIABLES,
        title: 'Decision Variables: ',
        helpObj: [
            {
                markdown: `
Each decision variable represents a choice that needs to be made, and its states are the options to choose between. 
                `
            }
        ]
    },
    [constants.HELP_VARIABLES_UTILITY_VARIABLES]: {
        id: constants.HELP_VARIABLES_UTILITY_VARIABLES,
        title: 'Utility Nodes: ',
        helpObj: [
            {
                markdown: `
Utility Nodes are used to represent the costs or benefits of decisions and variable states.
                `
            }
        ]
    },
    [constants.HELP_STRUCTURE]: {
        id: constants.HELP_STRUCTURE,
        title: 'Structure: ',
        helpObj: [
            {
                markdown: `
Here, you connect your variables to show the relationships between them. 

Arrows should go from cause to effect (e.g. from disease to symptom in a medical example), even if the reasoning is diagnostic (e.g. from symptom to disease).
                `
            },
            {
                bardRoles: [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],
                bardModes: [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND, constants.LAUNCH_MODE_SOLO_BARD],
                markdown: `
Try to avoid having more than 2 or 3 arrows coming into a variable, as this results in a big table of parameters to fill in. Consider introducing one or more intermediate variables.
                `
            },
            {
                bardRoles: [constants.ROLE_ANALYST],
                bardModes: [constants.LAUNCH_MODE_STRATHCLYDE_EXPERIMENT],
                markdown: `
Try to avoid having more than 2 or 3 arrows coming into a variable, as this results in a big table of parameters to fill in.
                `
            },
            {
                markdown: `
You can change the layout of your network by selecting a variable and dragging it to a new position. BARD automatically re-routes arrows to minimise crossings.

BARD enforces a top-to-bottom and left-to-right structure for Bayesian Networks, as this makes them easier to read and understand.
                `
            },
            {
                bardRoles: [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER],
                bardModes: [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND],
                links: [{
                    linkName: 'More On: STRUCTURE [PDF]',
                    linkText: `${helpContentUrlPrefix}/bard_more_on_structure.pdf`
                }]
            }
        ]
    },
    [constants.HELP_PARAMETERS]: {
        id: constants.HELP_PARAMETERS,
        title: 'Parameters: ',
        helpObj: [
            {
                markdown: `
Here you add probabilities, given as percentages, to capture the uncertainties in the relationships between variables.

The percentage inputs for each question (question mode) or row in table (table mode) must add up to 100% (BARD can do this for you).

As an alternative to using numbers, you can also add parameters as “verbal probabilities” (derived from Intelligence Community Guidelines).

| No Chance | 0% |
| --- | --- |
| Almost No Chance | ${200 * constants.EPSILON}% &ndash; 5% |
| Very Unlikely | ${5 + 200 * constants.EPSILON}% &ndash; 20% |
| Unlikely | ${20 + 200 * constants.EPSILON}% &ndash; 45% |
| Roughly Even Chance | ${45 + 200 * constants.EPSILON}% &ndash; 55% |
| Likely | ${55 + 200 * constants.EPSILON}% &ndash; 80% |
| Very Likely | ${80 + 200 * constants.EPSILON}% &ndash; 95% |
| Almost Certainly | ${95 + 200 * constants.EPSILON}% &ndash; ${100 - 200 * constants.EPSILON}% |
| Certain | 100% | 

When entering verbal probabilities, don’t use ‘No Chance’ or ‘Certain’ unless a result is logically or physically impossible or certain, respectively.
                `
            },
            {
                bardRoles: [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER],
                links: [{
                    linkName: 'More On: PARAMETERS [PDF]',
                    linkText: `${helpContentUrlPrefix}/bard_more_on_parameters.pdf`
                }]
            }
        ]
    },
    [constants.HELP_EXPLORE_NETWORK]: {
        id: constants.HELP_EXPLORE_NETWORK,
        title: 'Explore Network: ',
        helpObj: [
            {
                bardRoles: [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],
                markdown: `
In this step, your job is to use different scenarios to test the effect different values for your Other Variables have on your Target Variables. These scenarios form the basis of your investigation of potential solutions to the problem at hand.

BARD always provides you with a Base Scenario, which includes the prior probabilities for your identified variables.

When adding new scenarios, make your scenario title and descriptions short but clear.

Each scenario consists of evidence variables. You should set the state for each variable in the scenario.

For each scenario, you should also add a list of all the variables whose updated probabilities you want to observe for that scenario. Usually there is at least one target variable in this list. There is no limit to how many variables you observe.

The Summary Explanation (bottom of middle panel) draws on BARD’s Natural Language Generator to provide a brief written explanation of the chosen scenario. The DETAILED INFORMATION link provides additional information that will often be useful in Step 6 REPORT.
                `,
                links: [
                    {
                        linkName: 'More On: Reasoning [PDF]',
                        linkText: `${helpContentUrlPrefix}/bard_more_on_reasoning.pdf`
                    },
                    {
                        linkName: 'More On: Explanation [PDF]',
                        linkText: `${helpContentUrlPrefix}/bard_more_on_explanation.pdf`
                    }
                ]
            }
        ]
    },
    [constants.HELP_TIPS_REPORT]: {
        id: constants.HELP_TIPS_REPORT,
        title: 'Report: ',
        helpObj: [
            {
                bardRoles: [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER],
                markdown: `
The Report is the Group’s solution to the Problem and must be submitted before the scheduled Problem End time. Only the Facilitator edits the Report. Analysts should provide their feedback and suggestions via the Discussion tab. Observors can make suggestions to the Faciltiator via a chat MESSAGE only.
                `,
                links: [{
                    linkName: 'Report Guidelines',
                    linkText: `${helpContentUrlPrefix}/bard_step7_report.pdf`
                }, {linkName: 'PERFECT reminder', linkText: `${helpContentUrlPrefix}/bard_perfect_ref.pdf`}]
            }
        ]
    },
    [constants.HELP_TIPS_COMBINED_REPORTA]: {
        id: constants.HELP_TIPS_COMBINED_REPORTA,
        title: 'Report (My Draft) ',
        helpObj: [
            {
                bardRoles: [constants.ROLE_ANALYST, constants.ROLE_OBSERVER],
                markdown: `
The Report is the Group’s solution to the Problem and must be submitted before the scheduled Problem end time.
 
* If the Group has a Facilitator, the Facilitator will edit the final Report and publish it in the Group tab.
* If the Group does not have a Facilitator, the Published Analyst Report that has the highest rating will be automatically submitted.

Use ‘My Draft’ to create your suggested solution. When you are ready, click ‘PUBLISH MY DRAFT’ to share your draft Report with the rest of the Group.

You can return to My Draft to revise your Report after reviewing:

* Other Analysts’ Reports in the Published tab
* The Group Report produced by the Facilitator (if any) in the Group tab
* Any discussion amongst the Group in the Discussion tab
        
### Training materials
See the following topics in the BARD Training:

* How to Use BARD for Analysts
* Introduction to PERFECT
                `,
                links: [
                    {
                        linkName: 'Step 6: REPORT - BN',
                        linkText: `${helpContentUrlPrefix}/bard_step6_report-bn.pdf`
                    },
                    {
                        linkName: 'Step 6: REPORT - NBN',
                        linkText: `${helpContentUrlPrefix}/bard_step6_report-nbn.pdf`
                    },
                    {
                        linkName: 'How to use BARD: Analyst [PDF]',
                        linkText: `${helpContentUrlPrefix}/bard_how_to_use_bard_analyst.pdf`
                    },
                    {
                        linkName: 'PERFECT reminder',
                        linkText: `${helpContentUrlPrefix}/bard_perfect_ref.pdf`
                    }
                ]
            },
            {
                bardRoles: [constants.ROLE_SOLO_ANALYST],
                bardModes: [constants.LAUNCH_MODE_SOLO_BARD],
                markdown: `
The Report is the your solution to the Problem and must be submitted before the scheduled Problem end time.

When you are ready, click ‘Save’ to save your Report.

You can edit and revise your Report after saving.

When you are ready, click 'Submit Report' to submit the Report.

### Training materials
See the following topics in the BARD Training:

* Introduction to PERFECT
                `,
                links: [
                    {
                        linkName: 'Step 6: REPORT - BN',
                        linkText: `${helpContentUrlPrefix}/bard_step6_report-bn.pdf`
                    },
                    {
                        linkName: 'PERFECT reminder',
                        linkText: `${helpContentUrlPrefix}/bard_perfect_ref.pdf`
                    }
                ]
            }
        ]
    },
    [constants.HELP_TIPS_COMBINED_REPORTF]: {
        id: constants.HELP_TIPS_COMBINED_REPORTF,
        title: 'Report (Group Draft) ',
        helpObj: [
            {
                bardRoles: [constants.ROLE_FACILITATOR],
                markdown: `
The Report is the Group’s solution to the Problem and must be submitted before the scheduled Problem end time.

Use ‘Group Draft’ to create your suggested solution based on the published Analyst Reports in the Published tab. Pay attention to the average rating of each Analyst’s report, as well as any discussion in the Discussion tab.

When you are ready, click ‘PUBLISH GROUP DRAFT’ to share your draft Report with the rest of the Group. Encourage the Analysts to review and provide feedback.

You can return to the Group Draft tab to revise the Report after reviewing:

* Analysts’ revised Reports in the Published tab;
* Analysts ratings of each other’s Reports;
* Any discussion amongst the Group in the Discussion tab

When the Group is happy with the final Report, you can submit it from the Group tab.

If you don’t Submit a Report
* If you do not submit a Report before the scheduled Problem end time, then your last Published Group Draft will be automatically submitted.
* If you do not publish a Group Draft, or If the Group does not have a Facilitator at the scheduled Problem end time, the Published Analyst Report that has the highest rating will be automatically submitted.

### Training materials
See the following topics in the BARD Training:
* Facilitator Training: How to Use BARD as a Facilitator
* Facilitator Training: Detailed Facilitator Training - Guidance Document
* Introduction to PERFECT
                `,
                links: [
                    {
                        linkName: 'Step 6: REPORT - BN',
                        linkText: `${helpContentUrlPrefix}/bard_step6_report-bn.pdf`
                    },
                    {
                        linkName: 'Step 6: REPORT - NBN',
                        linkText: `${helpContentUrlPrefix}/bard_step6_report-nbn.pdf`
                    },
                    {
                        linkName: 'PERFECT reminder',
                        linkText: `${helpContentUrlPrefix}/bard_perfect_ref.pdf`
                    }
                ]
            }
        ]
    },
    [constants.HELP_TIPS_COMBINED_REPORTA_SELECT]: {
        id: constants.HELP_TIPS_COMBINED_REPORTA_SELECT,
        title: 'Select a Report template: ',
        helpObj: [
            {
                bardRoles: [constants.ROLE_ANALYST, constants.ROLE_OBSERVER],
                markdown: `
Two Report templates are provided:
                
* Report based on a Bayesian network. Use this if you have used a Bayesian network to solve the Problem.
* Report not based on Bayesian networks. Use this if you did not rely on a Bayesian network.

Each Report template contains the same sections, but the guidance provided differs between the two templates.

Changing the Report template: You can change between Report templates at any time. Your responses will not be lost.

### Training materials
See the following topics in the BARD Training:
* How to Use BARD for Analysts`,
                links: [
                    {
                        linkName: 'Step 6: REPORT',
                        linkText: `${helpContentUrlPrefix}/bard_step7_report.pdf`
                    }
                ]
            }
        ]
    },
    [constants.HELP_TIPS_COMBINED_REPORTF_SELECT]: {
        id: constants.HELP_TIPS_COMBINED_REPORTF_SELECT,
        title: 'Select a Report template: ',
        helpObj: [
            {
                bardRoles: [constants.ROLE_FACILITATOR],
                markdown: `
Two Report templates are provided:
                
* Report based on a Bayesian network. Use this if the Group’s solution is based on a Bayesian network.
* Report not based on Bayesian networks. Use this the Group’s solution does not rely on a Bayesian network.

Changing the Report template: You can change between Report templates at any time. Your responses will not be lost.

### Training materials
See the following topics in the BARD Training:
* Facilitator Training: How to Use BARD as a Facilitator
* Facilitator Training: Detailed Facilitator Training - Guidance Document
* Introduction to PERFECT
                `,
                links: [{linkName: 'Step 6: REPORT', linkText: `${helpContentUrlPrefix}/bard_step6_report.pdf`}]
            }
        ]

    },
    [constants.HELP_TIPS_COMBINED_REPORTA_PUBLISHED]: {
        id: constants.HELP_TIPS_COMBINED_REPORTA_PUBLISHED,
        title: 'Analyst Solution',
        helpObj: [
            {
                bardRoles: [constants.ROLE_ANALYST, constants.ROLE_OBSERVER],
                markdown: `
The Published tab shows each Analyst’s most recent proposed Problem solution (Report). 

The Facilitator reviews each Analyst’s Report, along with the ratings and discussion, to derive a Group solution (published in the Group tab).
        
Analysts can revise and re-publish their Report at any time up to the scheduled Problem end date. 

### Viewing each Analyst’s report
Use the drop-down list below the tabs at the top of the page to view each Analyst’s Report.
        
You cannot select Analysts who have not published a Report yet.
        
You can incorporate aspects of other Analysts’ Reports into your own Draft, or start a discussion in the Discussion tab.

### Rating Analysts’ reports
Please rate each Analyst’s published Report. 
The ratings are used to guide the Facilitator in the creation of the final Group Report.
        
Drag the rating scale next to the drop-down list to rate each Analyst’s Report on a scale from 1 (poor) to 7 (excellent).

### Rating updated Reports
Each time an Analyst publishes a new version of their Report, you should examine the new Report and consider changing your rating.

Newly published versions are marked with a red dot.

### Report ratings
When you have provided a rating for a report, you will see two numbers against the Report in the drop-down list.

![combined-report-published-img-01](${imgReportAPublished01})

* The average rating across all Analysts (so far) is labelled ‘Avg’. The current highest rating is highlighted in teal. The other average ratings are shown in blue.
* Your rating is labelled ‘Me’, and is shown in purple. 

### Groups without a Facilitator
If the group does not have a Facilitator at the scheduled Problem end time, then the highest rated published Report from any Analyst will be automatically submitted at the scheduled Problem end time.

### Training materials
See the following topics in the __BARD Training__:
* How to Use BARD for Analysts (Skip to ‘Report’)
                `,
                links: [{linkName: 'Step 6: REPORT', linkText: `${helpContentUrlPrefix}/bard_step6_report.pdf`}]
            }
        ]

    },
    [constants.HELP_TIPS_COMBINED_REPORTF_PUBLISHED]: {
        id: constants.HELP_TIPS_COMBINED_REPORTF_PUBLISHED,
        title: 'Analyst solution',
        helpObj: [
            {
                bardRoles: [constants.ROLE_FACILITATOR],
                markdown: ` 
The Published tab shows each Analyst’s most recent proposed Problem solution (Report).

The Facilitator should review each Analyst’s Report, along with the ratings and discussion, to derive a Group solution (published in the Group tab).

Analysts can revise and re-publish their Report at any time up to the scheduled Problem end date. 

### Viewing each Analyst’s report
Use the drop-down list below the tabs at the top of the page to view each Analyst’s Report.

You cannot select Analysts who have not published a Report yet.

### Incorporating an Analyst’s Solution into the Group Draft

Use the ‘COPY TO GROUP DRAFT’ link to copy the currently selected Analyst’s solution into the Group Draft. The solution will be prepended to any existing contents of the Group Draft.

### Report ratings
Once a Report has been rated by at least one Analyst, the drop-down list shows the average rating and number of Analysts who have provided a rating for that Report. 

![combined-report-published-img-01](${imgReportFPublished01})

Note that Facilitators cannot rate Reports. You can engage in discussion using the Discussion tab, however.

### Ratings for updated Reports
Note that when an Analyst publishes a new version of their report, other Analysts’ ratings of the previous version are retained.

### Groups without a Facilitator
If the group does not have a Facilitator at the scheduled Problem end time, the Published Analyst Report that has the highest rating will be automatically submitted.

### Training materials
See the following topics in the __BARD Training__:
* Facilitator Training: How to Use BARD as a Facilitator (Skip to ‘Report’)
* Facilitator Training: Detailed Facilitator Training - Guidance Document (Skip to ‘Report’)
* Introduction to PERFECT
                `,
                links: [{linkName: 'Step 6: REPORT', linkText: `${helpContentUrlPrefix}/bard_step6_report.pdf`}]
            }
        ]

    },
    [constants.HELP_TIPS_COMBINED_REPORTA_GROUP]: {
        id: constants.HELP_TIPS_COMBINED_REPORTA_GROUP,
        title: 'Group solution',
        helpObj: [
            {
                bardRoles: [constants.ROLE_ANALYST, constants.ROLE_OBSERVER],
                markdown: `
The Group Report is the Group’s solution to the Problem as interpreted by the Facilitator.

The Facilitator must submit the Group’s solution before the scheduled Problem end time. 

The Report can be revised and re-submitted by the Facilitator any time up to the scheduled Problem end time. If no report has been submitted at the Problem end time, then the last Group Report published by the Facilitator will be automatically submitted.

### Groups without a Facilitator
If the group does not have a Facilitator at the scheduled Problem end time, the Published Analyst Report that has the highest rating will be automatically submitted.

### Submitted report
Use the ‘DOWNLOAD SUBMITTED REPORT’ link to view the most recently submitted Report. Note that the currently published Group Report (in the Group tab) may be newer than the most recently submitted version.

### Training materials
See the following topics in the __BARD Training__:
* How to Use BARD for Analysts (Skip to ‘Report’)
                `,
                links: [{linkName: 'Step 6: REPORT', linkText: `${helpContentUrlPrefix}/bard_step6_report.pdf`}]
            }
        ]

    },
    [constants.HELP_TIPS_COMBINED_REPORTF_GROUP]: {
        id: constants.HELP_TIPS_COMBINED_REPORTF_GROUP,
        title: 'Group solution',
        helpObj: [
            {
                bardRoles: [constants.ROLE_FACILITATOR],
                markdown: `
The Group Report is the Group’s solution to the Problem as interpreted by the Facilitator.

The Facilitator must submit the Group’s solution before the scheduled Problem end time. 

The Report can be revised and re-submitted by the Facilitator any time up to the scheduled Problem end time. If no report has been submitted at the Problem end time, then the last Group Report published by the Facilitator will be automatically submitted.

### Groups without a Facilitator
If the group does not have a Facilitator at the scheduled Problem end time, then the highest rated published Report from any Analyst will be automatically submitted at the scheduled Problem end time.

### Submitting the report
Use the SUBMIT REPORT button at the bottom-right of the page to submit the current Group solution.

### Submitted report
Use the ‘DOWNLOAD SUBMITTED REPORT’ link to view the most recently submitted Report. Note that the currently published Group Report (in the Group tab) may be newer than the most recently submitted version.

### Training materials
See the following topics in the __BARD Training__:
* Facilitator Training: How to Use BARD as a Facilitator (Skip to ‘Report’)
* Facilitator Training: Detailed Facilitator Training - Guidance Document (Skip to ‘Report’)
* Introduction to PERFECT
`,
                links: [{linkName: 'Step 6: REPORT', linkText: `${helpContentUrlPrefix}/bard_step6_report.pdf`}]
            }
        ]

    }
};

export default class HelpTipsData {

    static instance: HelpTipsData;

    static getInstance() {
        return HelpTipsData.instance || (HelpTipsData.instance = new HelpTipsData());
    }

    getGenericTipContent(id) {
        return helpTips[id];
    }

}
