export default class Vector {
    /**
     * A basic 2D Vector utility class.
     *
     * constructor(obj: {x: number, y: number})
     * constructor(x: number, y: number)
     */
    constructor(x, y) {
        if (
            typeof y === "undefined" &&
            typeof x === "object" &&
            typeof x.x === "number" &&
            typeof x.y === "number"
        ) {
            // Copy numbers from object
            this.x = x.x;
            this.y = x.y;
        } else if (typeof x === "number" && typeof y === "number") {
            this.x = x;
            this.y = y;
        } else {
            throw new Error("Unable to instantiate Vector class");
        }
    }
    distSq(other) {
        const diff = other.subtract(this);
        return diff.dot(diff);
    }
    mag() {
        return Math.sqrt(this.dot(this));
    }
    scalarMultiply(k) {
        return new Vector(this.x * k, this.y * k);
    }
    norm() {
        return this.scalarMultiply(1 / this.mag());
    }
    negate() {
        return this.scalarMultiply(-1);
    }
    add(other) {
        return new Vector(this.x + other.x, this.y + other.y);
    }
    subtract(other) {
        return this.add(other.negate());
    }
    dot(other) {
        return this.x * other.x + this.y * other.y;
    }
    project(other) {
        return other.scalarMultiply(this.dot(other) / other.dot(other));
    }
    perp(other) {
        return this.subtract(this.project(other));
    }
    rotate90DegreesCounterClockwise() {
        return new Vector(-this.y, this.x);
    }
    rotate90DegreesClockwise() {
        return new Vector(this.y, -this.x);
    }
}
