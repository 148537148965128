import {Reducer} from 'redux';

import ensureFieldPath from '../ensureFieldPath';

export type LoginPathReducerType = string;

const loginPathReducer: Reducer<LoginPathReducerType> = (state = process.env.REACT_APP_LOGIN_PATH || '/') => {
    return state;
};

export default loginPathReducer;

export function getLoginPathFromStore(store): LoginPathReducerType {
    return store.sharedState.loginPath;
}

export function setLoginPathInStore(store, loginPath) {
    ensureFieldPath(store, 'sharedState');
    store.sharedState.loginPath = loginPath;
}