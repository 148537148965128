import React, {Component} from 'react';
import {detect} from 'detect-browser';

import Login from '../container/Login';
import {FOYER_PATH} from '../../common/clientServer/navigation';

import bardLogoImg from '../images/BARD_RGB_MED_LOGO_FOYER_clear.png';
import '../scss/app.scss';
import '../scss/browserLogos.scss';

class HomePage extends Component {

    renderSupportedBrowserDiv() {
        return (
            <div className='homeBodyLoginStyle'>
                <Login defaultLoginSuccessURL={FOYER_PATH}/>
            </div>
        );
    }

    renderIncompatibleBrowserDiv() {
        return (
            <div className='incompatibleBrowserDiv'>
                <h1>YOUR BROWSER IS NOT SUPPORTED</h1>
                <p>BARD supports the following major browsers.  The minimum recommended screen resolution is 1366 &times; 768 pixels.</p>
                <ul>
                    <li>
                        <a href='https://www.google.com/chrome/' target='_blank' rel='noopener noreferrer'>
                            <div className='browserLogo browserlogo-chrome'/> Chrome (latest version)
                        </a> (recommended)
                    </li>
                    <li>
                        <a href='https://www.mozilla.org/en-US/firefox/new/' target='_blank' rel='noopener noreferrer'>
                            <div className='browserLogo browserlogo-firefox'/> Firefox (latest version)
                        </a>
                    </li>
                    <li>
                        <a href='https://www.apple.com/safari/' target='_blank' rel='noopener noreferrer'>
                            <div className='browserLogo browserlogo-safari'/> Safari (latest version)
                        </a>
                    </li>
                    <li>
                        <a href='https://support.microsoft.com/en-gb/help/17621/internet-explorer-downloads' target='_blank' rel='noopener noreferrer'>
                            <div className='browserLogo browserlogo-internet-explorer'/> Internet Explorer 11
                        </a> (but <b>not Edge</b>)
                    </li>
                </ul>
            </div>
        );
    }

    // Couldn't get stubbing of detectBrowser.detect() working in test.  For now, wrap the call in a method we can stub.
    getBrowserDetails() {
        return detect();
    }

    renderLogin() {
        const browser = this.getBrowserDetails();
        switch (browser && browser.name) {
            case 'ie':
                return (browser.version.indexOf('11.') === 0) ? this.renderSupportedBrowserDiv() : this.renderIncompatibleBrowserDiv(browser);
            case 'chrome':
            case 'firefox':
            case 'safari':
                return this.renderSupportedBrowserDiv();
            default:
                return this.renderIncompatibleBrowserDiv();
        }
    }

    render() {
        return (
            <div className='homeBody'>
                <div className='homeBodyTextStyle' style={{textAlign: "left"}}>
                        
                    <h1>Welcome to BARD</h1>
                    
                    <h3>The online problem analysis and elicitation application.</h3>
    
                    <div className="homeBardText">
                        <p>BARD &mdash; Bayesian Argumentation via Delphi &mdash; uses causal Bayesian networks as underlying structured representations for argument analysis and automated Delphi methods to help groups of analysts develop, improve and present their analyses. This five-year project involves researching and designing new means of interacting with Bayesian networks, including new means of assessing their potential in causal explanations.
                        </p>
                        <div className="bardfoyerLogoImgDiv">
                            <img src={bardLogoImg} alt="BARD LOGO" className="bardfoyerLogoImg"/>
                        </div>
                    </div>
                </div>
                {this.renderLogin()}
            </div>
        );
    }
}

export default HomePage;
