import {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import {getDefaultProblemStepIdFromStore, setDefaultProblemStepIdAction} from '../reducers/defaultProblemStepIdReducer';

/**
 * This component simply checks to see if the URL contains a "problem" query parameter, and if so checks if it is
 * different from whatever is set in the store as the defaultProblemLabel.  If they differ, it dispatches an action to
 * update the store's defaultProblemStepId to match the query parameter's value.
 *
 * The "problem" parameter is used by T&E to pass through a user's selection in their portal of a particular problem.
 */
class DefaultProblemLabelComponent extends Component {

    static PROBLEM_QUERY_PARAM_RE = /[?&]problem=([^&]*)/;

    componentDidMount() {
        this.checkDefaultProblemLabel();
    }

    componentDidUpdate() {
        this.checkDefaultProblemLabel();
    }

    checkDefaultProblemLabel() {
        if (this.props.location && this.props.location.search) {
            const match = this.props.location.search.match(DefaultProblemLabelComponent.PROBLEM_QUERY_PARAM_RE);
            if (match && match[1] !== this.props.defaultProblemLabel) {
                this.props.dispatch(setDefaultProblemStepIdAction(match[1]));
            }
        }
    }

    render() {
        return null;
    }
}

function mapStoreToProps(store) {
    return {
        defaultProblemLabel: getDefaultProblemStepIdFromStore(store)
    };
}

export default withRouter(connect(mapStoreToProps)(DefaultProblemLabelComponent));