import {AnyAction, combineReducers, Reducer} from 'redux';
import moment from 'moment';

import ensureFieldPath from '../ensureFieldPath';
import allUsersForTeamReducer, {AllUsersForTeamReducerType, UserForTeamType} from './allUsersForTeamReducer';
import discussionsReducer, {DiscussionsState} from './discussionsReducer';
import groupManagementReducer, {GroupManagementReducerType} from './groupManagementReducer';
import notificationsReducer, {NotificationsReducerType} from './notificationsReducer';
import * as constants from '../util/constants';
import {LAUNCH_MODE_NONE, RESET_PROBLEM_STEP} from '../util/constants';
import {getProblemDBFromStore, SingleProblemType} from './allProblemsReducer';
import {ObjectMapState, timestampedActionCreator} from '../util/genericReducers';
import ReferenceData from '../util/referenceData';
import ratingsReducer, {RatingsReducerType} from './ratingsReducer';
import {SharedStateReducerType} from './sharedStateReducer';
import {getTeamFromStore} from './allTeamsReducerGetters';

// ======== Constants

export const ADD_PROBLEM_STEP = 'add_problem_step';
const SET_PROBLEM_STEP_FULLY_LOADED = 'set-problem-step-fully-loaded';
export const STEP_ID_CHANGE = 'step_id_change';
export const LAUNCH_PROBLEM = 'launch_problem';
export const SET_PROBLEM_STATE = 'set_problem_state';
export const LAUNCH_NEXT_ROUND = 'launch_next_round';
export const CLOSE_PROBLEM = 'close_problem';
export const REOPEN_PROBLEM = 'reopen_problem';
export const SET_ARCHIVE_PROBLEM = 'set_archive_problem';
export const DELETE_PROBLEM_STEP = 'delete_problem_step';
export const EXTEND_CURRENT_ROUND = 'extend_current_round';
const UPDATE_PROBLEM_STEP = 'update-problem-step';
export const OPEN_PROBLEM_CHANGED = 'OPEN_PROBLEM_CHANGED';

export interface ProblemStepAction extends AnyAction {
    problemStepId: number;
}

export interface ExtendCurrentRoundAction extends ProblemStepAction {
    activityDuration: number;
    stepId: number;
}

export interface ChangeProblemStepIdAction extends AnyAction {
    problemStepId: number,
    fromId: number | string
}

export interface SingleProblemStepType {
    id: number;
    problemId: number;
    problem?: SingleProblemType;
    stepId: constants.STEP | null;
    stepLaunched?: Date;
    roundSequenceNumber?: number;
    roundLaunched?: Date;
    activityDuration?: number;
    route?: number[][];
    launched?: Date;
    actualCompletion?: Date;
    state: number;
    teamId?: number;
    problemLabel?: string;
    reportSubmittedAt?: Date;
    systemReportSubmittedAt?: Date;
    submittedReportEmpty?: boolean;
    reportLocation?: string;
    notifiedWillBeClosed?: boolean;
    notifiedDueToBeClosed?: boolean;
    notifiedExceedToBeClosed?: boolean;
    launchMode: number;
    users?: UserForTeamType[];
}

export const initialSingleProblemStepType: SingleProblemStepType = {
    id: 0,
    problemId: 0,
    stepId: null,
    launchMode: LAUNCH_MODE_NONE,
    state: 0,
    notifiedWillBeClosed: false,
    notifiedDueToBeClosed: false,
    notifiedExceedToBeClosed: false
};

export const singleProblemStepReducer: Reducer<SingleProblemStepType> = (state = initialSingleProblemStepType, action: ProblemStepAction | AnyAction) => {
    switch (action.type) {
        case ADD_PROBLEM_STEP:
            return {
                state: constants.STATE_READY,
                launched: null,
                stepId: null,
                stepLaunched: null,
                roundSequenceNumber: null,
                roundLaunched: null,
                activityDuration: null,
                route: [],
                problemLabel: null,
                teamId: null,
                ...action.problemStep,
                id: action.problemStepId
            };
        case STEP_ID_CHANGE:
            return {...state, id: action.problemStepId};
        case LAUNCH_PROBLEM:
            return {
                ...state,
                launched: action.launchTimestamp,
                state: constants.STATE_ACTIVE,
                stepId: action.launchStepId,
                stepLaunched: action.launchTimestamp,
                roundSequenceNumber: 0,
                roundLaunched: action.launchTimestamp,
                activityDuration: action.activityDuration,
                route: action.route,
                launchMode: action.launchMode
            };
        case SET_PROBLEM_STATE:
            return {
                ...state,
                state: action.state
            };
        case LAUNCH_NEXT_ROUND:
            return {
                ...state,
                stepId: action.launchStepId,
                stepLaunched: (action.launchRoundSequenceNumber) ? state.stepLaunched : action.launchTimestamp,
                roundSequenceNumber: action.launchRoundSequenceNumber,
                roundLaunched: action.launchTimestamp,
                activityDuration: action.activityDuration,
                route: [...(state.route || []), [action.launchStepId, action.launchRoundId]]
            };
        case EXTEND_CURRENT_ROUND:
            return {
                ...state, activityDuration: state.activityDuration + action.activityDuration
            };
        case CLOSE_PROBLEM:
            return {
                ...state,
                actualCompletion: action.closeTimestamp,
                state: constants.STATE_CLOSED
            };
        case REOPEN_PROBLEM:
            return {
                ...state,
                actualCompletion: undefined,
                state: constants.STATE_ACTIVE
            };
        case SET_ARCHIVE_PROBLEM:
            return {
                ...state,
                state: (action.archived) ? constants.STATE_ARCHIVED : constants.STATE_CLOSED
            };
        case RESET_PROBLEM_STEP:
            return {
                ...state,
                state: constants.STATE_READY,
                launched: null,
                stepId: 1,
                stepLaunched: null,
                roundSequenceNumber: null,
                roundLaunched: null,
                activityDuration: null,
                reportSubmittedAt: null,
                systemReportSubmittedAt: null,
                route: []
            };
        case UPDATE_PROBLEM_STEP:
            return {
                ...state,
                ...action.problemStep
            };
        default:
            return state;
    }
};

export const isFullyLoadedReducer: Reducer<boolean> = (state = false, action: AnyAction) => {
    switch (action.type) {
        case ADD_PROBLEM_STEP:
        case SET_PROBLEM_STEP_FULLY_LOADED:
            return true;
        default:
            return state;
    }
};

export type CombinedTeamReducerType = {
    problemStep: SingleProblemStepType,
    user: AllUsersForTeamReducerType,
    discussion: DiscussionsState,
    groupManagement: GroupManagementReducerType,
    notifications: NotificationsReducerType,
    ratings: RatingsReducerType,
    isFullyLoaded: boolean
};

const combinedTeamReducer = combineReducers<CombinedTeamReducerType>({
    problemStep: singleProblemStepReducer,
    user: allUsersForTeamReducer,
    discussion: discussionsReducer,
    groupManagement: groupManagementReducer,
    notifications: notificationsReducer,
    ratings: ratingsReducer,
    isFullyLoaded: isFullyLoadedReducer,
});

export type AllTeamsReducerType = ObjectMapState<CombinedTeamReducerType>;

const allTeamsReducer : Reducer<AllTeamsReducerType> = (state = {}, action: ProblemStepAction | AnyAction) => {
    let result;
    switch (action.type) {
        case STEP_ID_CHANGE:
            result = {...state, [action.problemStepId]: combinedTeamReducer(state[action.fromId], action)};
            delete(result[action.fromId]);
            return result;
        case DELETE_PROBLEM_STEP:
            result = {...state};
            delete(result[action.problemStepId]);
            return result;
        case OPEN_PROBLEM_CHANGED:
            return {...action.store.teams};
        default:
            if (action.problemStepId) {
                const currProblemState = state[action.problemStepId];
                const nextProblemState = combinedTeamReducer(currProblemState, action);
                if (currProblemState !== nextProblemState) {
                    return { ...state, [action.problemStepId]: nextProblemState };
                }
            }
    }
    return state;
};

export default allTeamsReducer;

// ======== DBSync functions
// (used by DBSync and for unit testing)

export function getDBSyncActionTypes() {
    return [ADD_PROBLEM_STEP, LAUNCH_PROBLEM, SET_PROBLEM_STATE, LAUNCH_NEXT_ROUND, CLOSE_PROBLEM, REOPEN_PROBLEM,
        SET_ARCHIVE_PROBLEM, RESET_PROBLEM_STEP, DELETE_PROBLEM_STEP, EXTEND_CURRENT_ROUND, UPDATE_PROBLEM_STEP];
}

export function setProblemStepForTeamInStore(store, problemStepId: number, problemStep: SingleProblemStepType) {
    ensureFieldPath(store, 'sharedState', 'teams', problemStepId);
    store.sharedState.teams[problemStepId] = {...store.sharedState.teams[problemStepId], problemStep};
}

// ======== messaging functions

export function getActivityActionTypes() {
    return [LAUNCH_NEXT_ROUND, CLOSE_PROBLEM, REOPEN_PROBLEM, LAUNCH_PROBLEM, EXTEND_CURRENT_ROUND];
}

// ======== Action generator functions

export function addProblemStepAction(problemStepId: number, problemStep: SingleProblemStepType) {
    return {type: ADD_PROBLEM_STEP, problemStepId, problemStep};
}

export function setProblemStepFullyLoaded(problemStepId: number) {
    return {type: SET_PROBLEM_STEP_FULLY_LOADED, problemStepId};
}

export function changeProblemStepIdAction(problemStepId: number, fromId: number | string) : ChangeProblemStepIdAction {
    return {type: STEP_ID_CHANGE, problemStepId, fromId};
}

export function launchProblemAction(problemStepId: number, launchMode: number, launchTimestamp: string,
                                    activityDuration: number, launchStepId: number | null = null,
                                    launchRoundSequenceNumber: number | null = null, route?: number[][]) {
    return {
        type: LAUNCH_PROBLEM, problemStepId, launchTimestamp, launchStepId, launchRoundSequenceNumber, activityDuration,
        route, launchMode
    };
}

export function launchFacilitatorAdvancedProblemAction(problemStepId: number, activityDuration: number) {
    const allStepDescriptions = ReferenceData.getInstance().getAllStepDescriptions();
    const launchStepId = Object.keys(allStepDescriptions).reduce(
        (result, stepId) => (allStepDescriptions[stepId].launchStep ? Number(stepId) : result),
        constants.STEP_EXPLORE_PROBLEM);
    const launchRoundSequenceNumber = 0;
    const launchRoundId = allStepDescriptions[launchStepId].stepRounds![launchRoundSequenceNumber];
    return launchProblemAction(problemStepId, constants.LAUNCH_MODE_FACILITATOR_ADVANCES, moment().format(),
        activityDuration, launchStepId, launchRoundSequenceNumber, [[launchStepId, launchRoundId]]);
}

export function usingProblemLaunchRealTimeJuryProblemAction(problem: SingleProblemType, problemStepId: number, launchMode = constants.LAUNCH_MODE_REAL_TIME_JURY) {
    // back-calculate the launch time to be an exact number of hours from the completion time.
    const completionTime = problem.expectedCompletion ? moment(problem.expectedCompletion) : moment();
    const activityDuration = Math.ceil(completionTime.diff(moment(), 'hours', true));
    const launchTimestamp = completionTime.subtract(activityDuration, 'hours');
    return launchProblemAction(problemStepId, launchMode, launchTimestamp.format(), activityDuration,
        null, null, [[constants.STEP_COMBINED_REPORT, constants.ROUND_OPEN]]);

}

export function launchRealTimeJuryProblemAction(problemStepId: number, launchMode = constants.LAUNCH_MODE_REAL_TIME_JURY) {
    // Redux thunk action
    return (dispatch, getState) => {
        const state = getState();
        const team = getTeamFromStore(state, problemStepId);
        const problem = getProblemDBFromStore(state, team.problemStep.problemId);
        dispatch(usingProblemLaunchRealTimeJuryProblemAction(problem, problemStepId, launchMode));
    }
}

export function launchSoloBardProblemAction(problemStepId: number) {
    return launchRealTimeJuryProblemAction(problemStepId, constants.LAUNCH_MODE_SOLO_BARD);
}

export function launchStrathclydeProblemAction(problemStepId: number) {
    return launchProblemAction(problemStepId, constants.LAUNCH_MODE_STRATHCLYDE_EXPERIMENT, moment().format(), 0,
        constants.STEP_STRATHCLYDE_EXPLORE_PROBLEM, 0,
        [[constants.STEP_STRATHCLYDE_EXPLORE_PROBLEM, constants.ROUND_EXCHANGE]]);
}

export function setProblemStateAction(problemStepId: number, state: number) : ProblemStepAction {
    return { type: SET_PROBLEM_STATE, problemStepId, state }
}

export function launchRoundAction(problemStepId : number, launchTimestamp: string, launchStepId: number, launchRoundSequenceNumber: number, launchRoundId: number, activityDuration: number) : ProblemStepAction {
    return { type: LAUNCH_NEXT_ROUND, problemStepId, launchTimestamp, launchStepId, launchRoundSequenceNumber, launchRoundId, activityDuration };
}

export function extendCurrentRoundAction(problemStepId: number, stepId, activityDuration: number): ExtendCurrentRoundAction {
    return timestampedActionCreator({ type: EXTEND_CURRENT_ROUND, problemStepId, activityDuration, stepId });
}

export function closeProblemAction(problemStepId: number, closeTimestamp: string) : ProblemStepAction {
    return {type: CLOSE_PROBLEM, problemStepId, closeTimestamp}
}

export function reopenProblemAction(problemStepId: number) : ProblemStepAction {
    return {type: REOPEN_PROBLEM, problemStepId}
}

export function setArchiveProblemAction(problemStepId: number, archived: boolean) : ProblemStepAction {
    return {type: SET_ARCHIVE_PROBLEM, problemStepId, archived}
}

export function resetProblemStepStateAction(problemStepId: number) {
    return {type: constants.RESET_PROBLEM_STEP, problemStepId};
}

export function deleteProblemStepAction(problemStepId: number) {
    return {type: DELETE_PROBLEM_STEP, problemStepId};
}

export function updateProblemStepAction(problemStep) {
    return {type: UPDATE_PROBLEM_STEP, problemStepId: problemStep.id, problemStep};
}

export function openProblemChangedAction(store: SharedStateReducerType, fromServer = {}) {
    return {type: OPEN_PROBLEM_CHANGED, store, fromServer};
}
