import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import {Button} from 'react-md';
import {GoogleLogin} from "@react-oauth/google";

import SingleInput from '../presentation/SingleInput';
import {getIsLoggedInFromStore, getLoginErrorFromStore, loginAction} from '../reducers/loginReducer'
import {ROOT_PATH, SERVICE_UNAVAIL_PATH} from '../../common/clientServer/navigation';
import {trackLoginActivity, handleRouteChange, pathToProblemActivity} from '../util/userActivity';
import * as constants from '../../common/util/constants';


const REALM_REGEX = /Form realm="([^"]*)"/;

/**
 * Container to render login fields, and handle the act of the user attempting to log in.
 */
class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            password: '',
            errorMessage: props.error || null,
            useLocalLogin: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.useLocalLogin = this.useLocalLogin.bind(this);
        this.redirectTo503 = this.redirectTo503.bind(this);
    }

    componentDidMount() {
        // Attempt to get user details without logging in, in case session is still valid.
        if (!this.props.isLoggedIn) {
            this.attemptLogin({
                method: 'post',
                credentials: 'include'
            }, false);
        } else {
            this.redirect();
        }
    }

    useLocalLogin(realmLoginPath) {
        return (realmLoginPath === ROOT_PATH);
    }

    handleSubmit(evt) {
        evt.preventDefault();
        if (!this.state.username) {
            return this.setState({'errorMessage': 'Username is required'});
        } else if (!this.state.password) {
            return this.setState({'errorMessage': 'Password is required'});
        } else {
            this.setState({'errorMessage': null});
        }
        return this.attemptLogin({
            method: 'post',
            credentials: 'include',
            body: JSON.stringify({username: this.state.username, password: this.state.password})
        });
    }

    render() {
        if (this.props.isLoggedIn) {
            return null;
        } else if (!this.state.useLocalLogin) {
            return (this.state.errorMessage) ? (
                <div className='loginTitle error errorStyle'>
                    {this.state.errorMessage}
                    <div className='homebuttonDiv'>
                        <Button raised className='buttonStyle loginButtonColour' onClick={() => {
                            this.setState({errorMessage: null});
                            this.attemptLogin({
                                method: 'post',
                                credentials: 'include'
                            }, false);
                        }}>Retry</Button>
                    </div>
                </div>
            ) : (
                <div className='loginTitleSmall'>
                    Please wait...
                </div>
            );
        } else {
            const errorDiv = (this.state.errorMessage) ? (
                <div className='error errorStyle'>{this.state.errorMessage}</div> ) : null;
            return (
                <div>
                    <div className='loginTitle'>
                        Login
                    </div>
                    <form onSubmit={this.handleSubmit}>
                        {errorDiv}
                        <div className='inputSet'>
                            <SingleInput className='homeLoginFieldsDiv' size={35} inputType='text' name='username'
                                         title='Username ' content={this.state.username} placeholder='Username'
                                         autoComplete='username'
                                         onChange={
                                             (value) => {
                                                 this.setState({username: value});
                                             }
                                         } focus/>
                            <SingleInput className='homeLoginFieldsDiv' size={35} inputType='password' name='password'
                                         title='Password ' content={this.state.password} placeholder='Password'
                                         autoComplete='current-password'
                                         onChange={
                                             (value) => {
                                                 this.setState({password: value});
                                             }
                                         }/>
                        </div>
                        <div className='homebuttonDiv'>
                            <Button raised type='submit' className='buttonStyle loginButtonColour'>Login</Button>
                        </div>
                        <GoogleLogin
                            onSuccess={(response) => {
                                this.attemptLogin({
                                    method: 'post',
                                    credentials: 'include',
                                    headers: {Authorization: response.credential}
                                });
                            }}
                            onError={(response) => {
                                this.setState({errorMessage: response.description || response.error})
                            }}
                        />
                    </form>
                </div>
            );
        }
    }

    redirect() {
        const {deeplink} = (this.props.location && this.props.location.state) || {deeplink: {pathname: this.props.defaultLoginSuccessURL}};
        this.props.history.push(deeplink);
    }

    redirectTo503() {
        this.props.history.push(SERVICE_UNAVAIL_PATH);
    }

    reportLoginFailure(response) {
        if (response.status >= 400 && response.status < 500) {
            this.setState({'errorMessage': 'Username or Password incorrect.'});
        } else {
            this.setState({'errorMessage': 'There was a problem contacting the server!'});
        }
    }

    getRealmFrom401ResponseHeader(response) {
        if (response.status === 401 && response.headers) {
            const realm = response.headers.get('WWW-Authenticate') || '';
            const match = realm.match(REALM_REGEX);
            return match && match[1];
        }
    }

    attemptLogin(fetchParameters, interactiveAttempt = true) {
        const {headers, ...parameters} = fetchParameters;
        return fetch('api/login', {
            ...parameters,
            headers: {
                'content-type': 'application/json',
                'client-app-version': constants.release_id,
                ...headers
            }
        }).then((response) => {
            if (response.ok) {
                // Login succeeded.
                const activity = this.props.location.state? pathToProblemActivity(this.props.location.state.deeplink.pathname) : null;
                return response.json().then((user) => {
                    this.props.dispatch(loginAction(user));
                    this.redirect();
                    (activity) ? handleRouteChange(this.props.location.pathname) : trackLoginActivity();
                });
            } else {
                if (response.status === 503) {
                    this.redirectTo503();
                }

                if (response.status === 417) {
                    window.location.reload();
                }

                let realmContent = this.getRealmFrom401ResponseHeader(response);
                if (realmContent) {
                    this.setState({useLocalLogin: this.useLocalLogin(realmContent)}, () => {
                        if (!this.state.useLocalLogin) {
                            // If they're not authenticated and we're not using local login, redirect.
                            window.location = realmContent;
                        } else if (interactiveAttempt) {
                            this.reportLoginFailure(response);
                        }
                    });
                } else if (interactiveAttempt || !this.state.useLocalLogin) {
                    this.reportLoginFailure(response);
                }
            }
        }).catch((err) => {
            console.error('Error on login:', err);
            if (interactiveAttempt) {
                this.setState({'errorMessage': 'A problem occurred.'});
            }
        });
    }
}

function mapStoreToProps(store) {
    return {
        isLoggedIn: getIsLoggedInFromStore(store),
        error: getLoginErrorFromStore(store)
    };
}

export default withRouter(connect(mapStoreToProps)(Login));