import * as constants from './constants';
import ReferenceData from './referenceData';
import {SingleProblemStepType} from '../reducers/allTeamsReducer';
import {STEP} from './constants';

function getStepTense(stepId: number | null, problemStep, stepDescriptions = ReferenceData.getInstance().getAllStepDescriptions()) {
    if (!problemStep.launched || stepId === null) {
        // Problem hasn't been launched or getting round for non-existent step - all steps are in the future
        return constants.STEP_TENSE.FUTURE;
    } else if (problemStep.state === constants.STATE_CLOSED) {
        // Problem has been closed - all steps are in the past
        return constants.STEP_TENSE.PAST;
    } else if (problemStep.launchMode === constants.LAUNCH_MODE_SOLO_BARD || problemStep.launchMode === constants.LAUNCH_MODE_REAL_TIME_JURY) {
        // Solo BARD or RTJ problem - all steps are effectively in the present.
        return constants.STEP_TENSE.PRESENT;
    }
    let effectiveStepId = (stepDescriptions[stepId].parentStepId) ? stepDescriptions[stepId].parentStepId : stepId;

    if (stepId !== problemStep.stepId && effectiveStepId !== problemStep.stepId) {
        // Determine if effectiveStepId is behind or ahead of problemStep.stepId
        let search = problemStep.stepId;
        while (search !== null && search !== effectiveStepId) {
            let nextStep = stepDescriptions[search].nextStep;
            if (Array.isArray(nextStep)) {
                search = nextStep.reduce((result, stepId) => {
                    return (problemStep.route || []).reduce((result, route) => {
                        if (route[0] === stepId) {
                            return stepId;
                        } else {
                            return result;
                        }
                    }, null) || result;
                }, nextStep[0]);
            } else {
                search = nextStep;
            }
        }
        if (search === null) {
            return constants.STEP_TENSE.PAST;
        } else {
            return constants.STEP_TENSE.FUTURE;
        }
    } else {
        return constants.STEP_TENSE.PRESENT;
    }
}

/**
 * Returns the round for the give stepId, given the current state of a problem.
 *
 * @param stepId The stepId of the step whose round we want (can be past, current or future).
 * @param problemStep The ProblemStep object for this problem, i.e. getProblemStepFromStore(store, problemStepId)
 * @param stepDescriptions (optional) The stepDescriptions definitions from reference data to use.  If omitted, will get
 * the value directly from referenceData
 * @param tense (optional) The "tense" of the given stepId (past, present or future) compared to the current step in
 * problemStep.  If omitted, the value is calculated.
 * @returns {number} The current round of the given step.
 */
export function getCurrentRound(stepId: number | null, problemStep: SingleProblemStepType, stepDescriptions = ReferenceData.getInstance().getAllStepDescriptions(), tense = getStepTense(stepId, problemStep, stepDescriptions)) {
    if (!problemStep.launched || !stepId) {
        // Problem hasn't been launched or getting round for non-existent step - not in a round
        return constants.ROUND_NONE;
    }
    const effectiveStepId = (!stepDescriptions[stepId].parentStepId) ? stepId : stepDescriptions[stepId].parentStepId!;
    const stepRounds = stepDescriptions[effectiveStepId].stepRounds;
    if (!stepRounds) {
        return constants.ROUND_NONE;
    }
    switch (tense) {
        case constants.STEP_TENSE.PAST:
            // stepId is in the past - that step's "current round" is the last one in the list.
            return stepRounds[stepRounds.length - 1];
        case constants.STEP_TENSE.FUTURE:
            // stepId is in the future - that step's "current round" is the first one in the list.
            return stepRounds[0];
        default:
            let index = Math.min(problemStep.roundSequenceNumber || 0, stepRounds.length - 1);
            return stepRounds[index];
    }
}

/**
 * Determine if a given round or array of rounds matches the current state of a problem for the given user
 *
 * @param round Either a single number or an array of numbers, from constants.ROUND_*, to match
 * @param user The UserProblem object for this user, i.e. getUserForProblemFromStore(store, problemStepId, userId)
 * @param problemStep The ProblemStep object for this problem, i.e. getProblemStepFromStore(store, problemStepId)
 * @param stepId The stepId of the currently viewed step.
 * @param stepDescriptions (optional) The stepDescriptions definitions from reference data to use.  If omitted, will get
 * the value directly from referenceData
 * @param tense (optional) The "tense" of the given stepId (past, present or future) compared to the current step in
 * problemStep.  If omitted, the value is calculated.
 * @returns {boolean} Whether the provided round or round constants match
 */
export function doesRoundMatch(round, user, problemStep, stepId, stepDescriptions = ReferenceData.getInstance().getAllStepDescriptions(), tense = getStepTense(stepId, problemStep, stepDescriptions)) {

    let currentRound = getCurrentRound(stepId, problemStep, stepDescriptions, tense);

    let publishedPseudoStep;
    if (tense === constants.STEP_TENSE.PAST) {
        publishedPseudoStep = constants.ROUND_PUBLISHED;
    } else {
        switch (user.db.role) {
            case constants.ROLE_FACILITATOR:
            case constants.ROLE_OBSERVER:
                publishedPseudoStep = constants.ROUND_PUBLISHED;
                break;

            case constants.ROLE_ANALYST:
                publishedPseudoStep = (user.db.publishedStepMask !== undefined && ((user.db.publishedStepMask & (1 << stepId)) !== 0)) ?
                    constants.ROUND_PUBLISHED : constants.ROUND_UNPUBLISHED;
                break;

            default:
                publishedPseudoStep = constants.ROUND_UNPUBLISHED;
        }
    }

    if (Array.isArray(round)) {
        for (let index = 0; index < round.length; ++index) {
            if (round[index] === currentRound || round[index] === currentRound + publishedPseudoStep) {
                return true;
            }
        }
        return false;
    } else {
        return round === currentRound || round === currentRound + publishedPseudoStep;
    }
}

/**
 * Return the stepId and roundSequenceNumber of the next round for the given problemStep
 *
 * @param {ProblemStepPojo} problemStep The problemStep to advance to the next step
 * @return {{nextStepId: number | number[] | null, nextRoundSequenceNumber: number | null}} The stepId and roundSequenceNumber of the next round or step in the sequence.
 */
export function getNextStepAndRound(problemStep: SingleProblemStepType): {nextStepId: (number | number[] | null), nextRoundSequenceNumber: number | null} {
    if (!problemStep.launched) {
        // ProblemStep that hasn't been launched - return the stepId flagged as the launchStep.
        const allStepDescriptions = ReferenceData.getInstance().getAllStepDescriptions();
        const nextStepId = Object.keys(allStepDescriptions).reduce((result: number | null, stepId) => (
            result || (allStepDescriptions[stepId].launchStep ? Number(stepId) : null)
        ), null);
        return {nextStepId, nextRoundSequenceNumber: 0};
    } else if (problemStep.stepId !== null) {
        // ProblemStep that has a single stepId shared by the whole team.
        const stepDescription = ReferenceData.getInstance().getStepDescription(problemStep.stepId as STEP);
        if (stepDescription.stepRounds && problemStep.roundSequenceNumber !== undefined && problemStep.roundSequenceNumber < stepDescription.stepRounds.length - 1) {
            return {nextStepId: problemStep.stepId, nextRoundSequenceNumber: problemStep.roundSequenceNumber + 1};
        } else if (stepDescription.nextStep !== null) {
            return {nextStepId: stepDescription.nextStep, nextRoundSequenceNumber: 0};
        }
    }
    return {nextStepId: null, nextRoundSequenceNumber: null};
}

/**
 * Get the current roundId of the given stepId and roundSequenceNumber
 *
 * @return {number | null} The roundId for the given stepId and roundSequenceNumber, or null if stepId and roundSequenceNumber don't correspond to a valid round.
 */
export function getRoundId(stepId: null | number, roundSequenceNumber: undefined | number): constants.ROUND | null {
    if (stepId !== null && !isNaN(stepId) && roundSequenceNumber !== undefined && !isNaN(roundSequenceNumber)) {
        const stepDescription = ReferenceData.getInstance().getStepDescription(stepId as STEP);
        if (stepDescription.stepRounds && roundSequenceNumber < stepDescription.stepRounds.length) {
            return stepDescription.stepRounds[roundSequenceNumber];
        }
    }
    return null;
}