import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {ExpansionPanel, DialogContainer} from 'react-md';
import moment from 'moment';
import classnames from 'classnames';
import { find, indexOf} from 'lodash';
import {v4} from 'uuid';

import PlainTextField from './PlainTextField';
import RichTextField from './RichTextField';
import {discardDraftCommentAction, discussHideTopicAction, discussUpdateOrCreateCommentAction, discussPostCommentAction, discussHideCommentAction} from '../../common/reducers/discussionsReducer';
import {createInitChatAndSendAction, createSendChatMessageAction} from '../../common/reducers/chatsReducer';
import NotificationContainer from '../container/NotificationContainer';
import * as constants from '../../common/util/constants';
import {DISPLAY_REMOVED_USER} from '../../common/util/constants';
import BardSnackbar from './BardSnackbar';
import {MSG_TEXT_EXCEEDS_MAXLENGTH, MAX_TEXT_LENGTH} from '../util/richtextUtil';
import ConfirmationDialog from './ConfirmationDialog';
import {buildRestPath, UPLOAD_PROBLEM_IMAGE_PATH} from "../../common/clientServer/navigation";

class Discussion extends Component {

    static propTypes = {
        topicId: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
        userId: PropTypes.number.isRequired,
        userProblem: PropTypes.object.isRequired,
        problemStepId: PropTypes.oneOfType([ PropTypes.number, PropTypes.string ]).isRequired,
        topic: PropTypes.object.isRequired,
        discussionComments: PropTypes.arrayOf(PropTypes.object),
        dispatch: PropTypes.func.isRequired,
        usersForProblem: PropTypes.object.isRequired,
        defaultExpanded: PropTypes.bool,
        userRole: PropTypes.number,
        chatsForProblemAndUser: PropTypes.object,
        readOnly: PropTypes.bool,
        visible: PropTypes.bool
    };

    static defaultProps = {
        visible: true,
        discussionComments: []
    };

    constructor(props) {
        super(props);
        this.state = {
            ...this.getCommentIdAndTextFromProps(props),
            flushChanges: {},
            commentEmpty: true,
            modalIsOpen: false,
            confirmCancelReply: false,
            deleteDetails: {
                deleteElement: '',
                deleteReason: '',
                comment: null,
                commentId: null,
                createdBy: null
            },
            toastMessages: []
        };

        this.handleTextChange = this.handleTextChange.bind(this);
        this.addFlushChanges = this.addFlushChanges.bind(this);

        this.updateOrCreateComment = this.updateOrCreateComment.bind(this);
        this.onPostComment = this.onPostComment.bind(this);

        this.onDeleteTopic = this.onDeleteTopic.bind(this);
        this.onDeleteComment = this.onDeleteComment.bind(this);

        this.onDoDelete = this.onDoDelete.bind(this);
        this.onCloseModal = this.onCloseModal.bind(this);

        this.onDeleteReasonChange = this.onDeleteReasonChange.bind(this);
    }

    getCommentIdAndTextFromProps(props) {
        const draft = props.discussionComments.find(comment => (comment.draft === true && comment.createdBy === props.userId));
        const myNewTopic = (props.topic.createdBy === props.userId && props.discussionComments.length === 0);
        return {
            commentId: draft ? draft.commentId : myNewTopic ? v4() : null,
            commentText: draft ? draft.comment : ''
        };
    }
    
    handleTextChange(text) {
        let discussionContent = text;
        if(discussionContent && discussionContent.length > MAX_TEXT_LENGTH){
            this.setState({ toastMessages: [MSG_TEXT_EXCEEDS_MAXLENGTH] }, () =>{
                discussionContent = discussionContent.substring(0, MAX_TEXT_LENGTH);
            });
        }
        this.setState({commentText: discussionContent});
        this.updateOrCreateComment();
    }

    addFlushChanges(key, handler) {
        this.setState((oldState) => ({flushChanges: {...oldState.flushChanges, [key]: handler}}));
    }

    getCreatorDisplayName(comment) {
        if (this.props.usersForProblem[comment.creator.userId]) {
            return comment.creator.displayName + (comment.creator.userId === this.props.userId ? ' (Me)' : '');
        } else {
            return DISPLAY_REMOVED_USER;
        }
    }

    sendChatMessage(senderId, recipientId, text, problemStepId) {
        if ((senderId !== recipientId) && (text !== '')) {
            let chat = this.props.chatsForProblemAndUser ? find(this.props.chatsForProblemAndUser, (chat) => {return indexOf(chat.participants, recipientId) >= 0;}) : null;
            if (chat) {
                this.props.dispatch(createSendChatMessageAction(senderId, recipientId, text, chat.id, problemStepId));
            } else {
                this.props.dispatch(createInitChatAndSendAction(senderId, recipientId, text, problemStepId));
            }
        }
    }

    updateOrCreateComment() {
        // Need to wait for potential previous setStates from handleTextChange to be processed - use the updater feature of React setState()
        this.setState((prevState) => {
            if (prevState.commentText && prevState.commentId) {
                this.props.dispatch(discussUpdateOrCreateCommentAction(prevState.commentId, prevState.commentText, this.props.topicId, this.props.userId, this.props.userProblem.db, this.props.problemStepId, this.props.stepId));
            }
            return {};
        });
    }

    onPostComment() {
        Object.keys(this.state.flushChanges).forEach((key) => {
            this.state.flushChanges[key]();
        });
        // Need to wait for potential previous setStates from handleTextChange to be processed - use the updater feature of React setState()
        this.setState((prevState) => {
            if (prevState.commentText && prevState.commentId) {
                this.props.dispatch(discussPostCommentAction(prevState.commentId, prevState.commentText, this.props.topicId, this.props.userId, this.props.userProblem.db, this.props.problemStepId, this.props.stepId));
                return {commentId: null, commentText: '', commentEmpty: true};
            }
        });
    }

    onDeleteTopic() {
        this.setState({deleteDetails: {...this.state.deleteDetails, deleteElement: 'topic', deleteReason: '', comment: null, commentId: null, createdBy: null}, modalIsOpen: true});
    }

    onDeleteComment(comment, commentId, createdBy) {
        this.setState({deleteDetails: {...this.state.deleteDetails, deleteElement: 'comment', deleteReason: '', comment: comment, commentId: commentId, createdBy: createdBy}, modalIsOpen: true});
    }

    onDeleteReasonChange(reason) {
        this.setState({
            deleteDetails: {...this.state.deleteDetails, deleteReason: reason}
        });
    }

    onDoDelete() {
        this.setState({ modalIsOpen: false });

        switch(this.state.deleteDetails['deleteElement']) {
            case 'topic':
                this.dismissNotification();
                this.props.dispatch(discussHideTopicAction(this.props.problemStepId, this.props.stepId, this.props.topic.createdBy, this.props.topic.topicTitle, this.state.deleteDetails['deleteReason'], this.props.userId, this.props.userProblem.db, this.props.topicId));
                this.sendChatMessage(this.props.userId, this.props.topic.createdBy, 'The Facilitator removed a discussion topic created by you because "' + this.state.deleteDetails['deleteReason'] + '"', this.props.problemStepId);
                break;
            case 'comment':
                this.props.dispatch(discussHideCommentAction(this.props.problemStepId, this.props.stepId, this.state.deleteDetails['createdBy'], this.state.deleteDetails['commentId'], this.props.topicId, this.state.deleteDetails['deleteReason'], this.props.userId, this.props.userProblem.db));
                this.sendChatMessage(this.props.userId, this.state.deleteDetails['createdBy'], 'The Facilitator removed a discussion comment created by you because "' + this.state.deleteDetails['deleteReason'] + '"', this.props.problemStepId);
                break;
            default:
        }
    }

    onCloseModal() {
        this.setState({ modalIsOpen: false });
    }

    afterOpenModal() { }

    onDismiss= () => {
        this.setState({ toastMessages: [] });
    };    

    UNSAFE_componentWillReceiveProps(props) {
        this.setState(this.getCommentIdAndTextFromProps(props));
    }

    componentWillUnmount() {
        Object.keys(this.state.flushChanges).forEach((key) => {
            this.state.flushChanges[key]();
        });
        this.updateOrCreateComment();
    }

    renderPostEditor() {
        const uploadUrl = buildRestPath(UPLOAD_PROBLEM_IMAGE_PATH, {problemStepId: this.props.problemStepId});
        return (
            <div className='discussionInputDiv'>
                                    <div className='discussionSender roleColour'>
                                        {this.props.usersForProblem[this.props.userId].db.displayName} (Me):
                                    </div>
                <div className='discussionEditorDiv'>
                    <RichTextField
                        text={this.state.commentText}
                        forceUpdate={this.state.commentText === ''}
                        onChange={this.handleTextChange}
                        addFlushChanges={this.addFlushChanges}
                        focus
                        onTextStart={(empty) => {
                            this.setState({commentEmpty: empty});
                        }}
                        readOnly={this.props.userRole === constants.ROLE_OBSERVER || isNaN(this.props.topicId)}
                        config={{
                            height: '100',
                            toolbarCanCollapse: true,
                            uploadUrl
                        }}
                    />
                </div>
                <div className="buttonBar">
                    <input type="button" value="Cancel" className="md-btn--hover md-btn--text md-pointer--hover mdActionButton buttonStyle linkButtonStyle regularButtonStyle"
                           onClick={() => {
                               this.setState({confirmCancelReply: true})
                           }}
                           disabled={isNaN(this.props.topicId)}
                    />
                    <input type="button" value="Post"
                           className='md-btn--raised md-btn--text md-pointer--hover mdActionButton buttonStyle regularButtonStyle roleBackgroundColour'
                           onClick={this.onPostComment}
                           disabled={!this.state.commentText || isNaN(this.props.topicId)}
                    />
                </div>
                <BardSnackbar
                    id='discussionSnackbar'
                    toasts={this.state.toastMessages}
                    autoHide={true}
                    positionCenter={true}
                    size={BardSnackbar.SIZE_LARGE}
                    onDismiss={this.onDismiss}
                />
            </div>
        );
    }

    renderReplyButton() {
        return (
            <div className="buttonBar">
                <input type="button" value="Reply"
                       className='md-btn--raised md-btn--text md-pointer--hover regularButtonStyle roleBackgroundColour'
                       onClick={() => {
                           this.props.dispatch(discussUpdateOrCreateCommentAction(v4(), '', this.props.topicId, this.props.userId, this.props.userProblem.db, this.props.problemStepId, this.props.stepId));
                       }}
                       disabled={this.props.userRole === constants.ROLE_OBSERVER || isNaN(this.props.topicId)}
                />
            </div>
        );
    }

    render() {
        const isDraftTopic = this.props.discussionComments.reduce((allDraft, comment) => (allDraft && comment.draft), true);
        const hidden = !this.props.visible || (isDraftTopic && this.props.topic.createdBy !== this.props.userId);
        return hidden ? null : (
            <div className="discussionContainerDiv">
                <NotificationContainer
                            stepId={this.props.stepId}
                            tags={[ 'discussion' ]}
                            setDismiss={(dismiss) => {
                                this.dismissNotification = dismiss
                            }}
                            keyValue={this.props.topicId}
                        >
                            <span className="notificationComment" />
                        </NotificationContainer>
                <ExpansionPanel
                    columnWidths={this.props.columnWidths}
                    focused={this.props.focused}
                    animateContent={false}
                    className="discussionHeaderDiv"
                    label={this.props.topic.topicTitle}
                    secondaryLabel={isDraftTopic ? '(hidden from other users until you post)' : ''}
                    footer={null}
                    defaultExpanded={this.props.defaultExpanded}
                    onExpandToggle={(expanded) => {
                        if (expanded) {
                            this.dismissNotification();
                        }
                    }}
                >
                    <div className='topicDiv'>
                        {
                            this.props.discussionComments.map((comment, index) => {
                                if (!comment.draft && comment.visible) {
                                    return (
                                        <div className="topicContainerDiv" key={index}>
                                            <div className='discussionDisplayDiv'>
                                                <div className='discussionDisplayHdrDiv'>
                                                    <span className={classnames('timestamp', 'postTimestamp', 'roleBackgroundColour', 'override', {
                                                        observer: comment.creator.role === constants.ROLE_OBSERVER,
                                                        analyst: comment.creator.role === constants.ROLE_ANALYST,
                                                        facilitator: comment.creator.role === constants.ROLE_FACILITATOR,
                                                        other: comment.createdBy !== this.props.userId
                                                    })}>
                                                        {moment(comment.created).format('llll')}
                                                    </span>
                                                    <span className="discussionSender">{this.getCreatorDisplayName(comment)}:</span>
                                                </div>
                                                <div className='discussionComment' dangerouslySetInnerHTML={{__html: comment.comment}} />
                                            </div>
                                            {
                                                this.props.userRole === constants.ROLE_FACILITATOR ?
                                                    <div className="postDeleteButton actionColor material-icons toolTipContainer"
                                                            onClick={() => {
                                                                this.onDeleteComment(comment.comment, comment.commentId, comment.createdBy)
                                                            }}>
                                                        delete
                                                        <span className="toolTip">Delete this post</span>
                                                    </div>
                                                : null
                                            }
                                        </div>
                                    )
                                } else {
                                    return null;
                                }
                            })
                        }
                        { (this.props.readOnly || this.props.userRole === constants.ROLE_OBSERVER) ? null : (
                            this.state.commentId ? this.renderPostEditor() : this.renderReplyButton()
                        )}
                        
                    </div>
                </ExpansionPanel>

                {
                    this.props.userRole === constants.ROLE_FACILITATOR ?
                        <div className="topicDeleteButton actionColor material-icons toolTipContainer" onClick={() => {this.onDeleteTopic()}}>
                            delete
                            <span className="toolTip">Delete whole topic</span>
                        </div>
                    : null
                }
                    
                <DialogContainer
                    id="discussionDeleteReason"
                    visible={this.state.modalIsOpen}
                    width='500px'
                    height='180px'
                    title={"Enter a reason for deleting the " + this.state.deleteDetails['deleteElement']}
                    onHide={this.onCloseModal}
                    onShow={this.afterOpenModal}
                    actions={[<button className='buttonStyle linkButtonStyle discussionDeleteReasonCancelButton' onClick={this.onCloseModal}>CANCEL</button>, <button className='raisedButtonStyle discussionDeleteReasonDeleteButton roleBackgroundColour' onClick={this.onDoDelete} disabled={this.state.deleteDetails['deleteReason'] === ''}>DELETE</button>]}
                    autosizeContent={false}
                    stackedActions={false}
                >
                    <PlainTextField
                        className='reasonText'
                        text={this.state.deleteDetails['deleteReason']}
                        onChange={this.onDeleteReasonChange}
                        rows={2}
                        readOnly={false}
                    />
                </DialogContainer>

                <ConfirmationDialog
                    visible={this.state.confirmCancelReply}
                    dialogTitle='Discard draft?'
                    onCancelAction={() => {this.setState({confirmCancelReply: false})}}
                    onHideAction={() => {
                        this.setState({confirmCancelReply: false, commentId: null, commentText: ''});
                        this.props.dispatch(discardDraftCommentAction(this.props.problemStepId, this.props.stepId, this.props.userId, this.props.topicId, this.state.commentId));
                    }}
                    dialogContent={<p>Are you sure you want to discard your draft?</p>}
                    actionButtonContent="Yes, discard"
                    cancelIconClasses="cancelClasses"
                />

                <div className="toolTip">Expand/Collapse</div>
                
            </div>
        );
    }

}
    
export default Discussion;
