import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import RichTextField from "../presentation/RichTextField";
import {
    getCandidateSolutionFromStore,
    updateCandidateSolutionAction
} from "../../common/reducers/candidateSolutionReducer";
import {getLoggedInUserIdFromStore} from '../reducers/loginReducer';
import DataStatusEnum from '../../common/DataStatusEnum'
import {
    getCandidateSolutionTemplateFromStore
} from '../../common/reducers/candidateSolutionTemplateReducer';
import BardSnackbar from '../presentation/BardSnackbar';
import {MSG_TEXT_EXCEEDS_MAXLENGTH, MAX_TEXT_LENGTH} from '../util/richtextUtil';
import * as constants from '../../common/util/constants';
import {buildRestPath, UPLOAD_PROBLEM_IMAGE_PATH} from "../../common/clientServer/navigation";

class CandidateSolutionContainer extends Component {

    static propTypes = {
        readOnly: PropTypes.bool,
        status: PropTypes.number,
        userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        problemStepId: PropTypes.number.isRequired,
        addFlushChanges: PropTypes.func
    };

    static defaultProps = {
        readOnly: false,
        status: DataStatusEnum.MY_DRAFT
    };

    constructor(props) {
        super(props);
        this.state = {
            toastMessages: []
        };
        this.textChange = this.textChange.bind(this);
    }
    
    textChange(text){
        if(text.length <= MAX_TEXT_LENGTH){
            this.setState({
                    text: text,
                    toastMessages: []
            });
            this.props.dispatch(updateCandidateSolutionAction(this.props.problemStepId, this.props.userId, this.props.status, text));
        }else{
            const trimmedText = text.substring(0, MAX_TEXT_LENGTH);
            this.setState({toastMessages: [MSG_TEXT_EXCEEDS_MAXLENGTH], text : trimmedText});
            this.props.dispatch(updateCandidateSolutionAction(this.props.problemStepId, this.props.userId, this.props.status, trimmedText));
        }
    }

    onDismiss= () => {
        this.setState({
            toastMessages: []
    });
    };    

    render(){
        const isToastMessages = (this.state.toastMessages && this.state.toastMessages.length !== 0);
        const editorText = this.state.text ? this.state.text : (this.props.candidateSolution.text ? this.props.candidateSolution.text : (this.props.candidateSolutionTemplate ? this.props.candidateSolutionTemplate.template : ''));
        const uploadUrl = buildRestPath(UPLOAD_PROBLEM_IMAGE_PATH, {problemStepId: this.props.problemStepId});
        return (
            <div>
                <div id="candidateSolutionContainerId" className="candidatesolutionDiv">
                <div className="minorTitle instructionDivStyle">
                    {this.props.status === DataStatusEnum.PUBLISHED ? 'Published candidate solution' :
                        (this.props.status === DataStatusEnum.GROUP ? 'Group candidate solution' : (this.props.status === DataStatusEnum.GROUP_DRAFT ? 'Write the group candidate solution' : 'Write your candidate solution'))}
                </div>
                
                    <RichTextField
                        forceUpdate={isToastMessages}
                        text={editorText}
                        onRevert={this.props.candidateSolution.onRevert}
                        addFlushChanges={this.props.addFlushChanges}
                        onChange={this.textChange}
                        readOnly={this.props.readOnly}
                        config={{uploadUrl, height:'400'}}
                    /> 
                </div>
                <BardSnackbar
                    lightTheme
                    autoHide={true}
                    id='candidateSolutionSnackbar'
                    toasts={this.state.toastMessages}
                    positionCenter={true}
                    size={BardSnackbar.SIZE_LARGE}
                    onDismiss={this.onDismiss}
                />
            </div>
        );
    }
}

function mapStoreToProps(store, myProps) {
    const problemStepId = myProps.problemStepId;
    const userId = myProps.userId || getLoggedInUserIdFromStore(store);
    const status = (myProps.status === undefined) ? DataStatusEnum.MY_DRAFT : myProps.status;
    const method = (myProps.stepId === constants.STEP_BR_CANDIDATE_SOLUTION) ? constants.METHOD_BN : constants.METHOD_NBN;

    return {
        userId,
        status,
        candidateSolution: getCandidateSolutionFromStore(store, problemStepId, userId, status),
        candidateSolutionTemplate: getCandidateSolutionTemplateFromStore(store, method)
    };
}

export default connect(mapStoreToProps)(CandidateSolutionContainer);
