import {logoutAction} from '../reducers/loginReducer';
import {LOGOUT_REASON_SESSION_INVALID, LOGOUT_REASON_SERVICE_UNAVAILABLE} from '../../common/util/constants';
/**
 * Generic error handler for response coming out of the fetch promise.
 * @param response The http response from fetch
 */
export function handleErrors(response) {
    if (!response.ok) {
        throw Error(response.status);
    }

    return response;
}

export function commonApiExceptionHandled(errorCode, dispatchFunc) {
    switch(errorCode) {
        case 401:
            dispatchFunc(logoutAction(LOGOUT_REASON_SESSION_INVALID));
            break;
        case 503:
            dispatchFunc(logoutAction(LOGOUT_REASON_SERVICE_UNAVAILABLE));
            break;
        default:
            return false;
    }
    return true;
}