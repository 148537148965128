import {Action, Reducer} from 'redux';

import {objectMapReducer, ObjectMapState, singleFieldReducer} from '../util/genericReducers';
import {StoreWithSharedState} from './sharedStateReducer';
import ensureFieldPath from '../ensureFieldPath';

// ======== Constants

export enum RatingsReducerActionTypes {
    UPDATE_RATING = 'update-rating'
}

// ======== Action Generators

export interface UpdateRatingActionType extends Action {
    type: RatingsReducerActionTypes.UPDATE_RATING;
    problemStepId: number;
    syncVariable: string;
    forUserId: number;
    userId: number;
    rating: number;
}

export function updateRatingAction(problemStepId: number, syncVariable: string, forUserId: number, userId: number, rating: number): UpdateRatingActionType {
    return {type: RatingsReducerActionTypes.UPDATE_RATING, problemStepId, syncVariable, forUserId, userId, rating};
}

// ======== Reducers

export type SingleRatingType = number;

const singleRatingReducer: Reducer<SingleRatingType> = (state = 0, action) => {
    switch (action.type) {
        case RatingsReducerActionTypes.UPDATE_RATING:
            return action.rating;
        default:
            return state;
    }
};

const ratingsByOneUserReducer = singleFieldReducer('ratings', {ratings: {}}, objectMapReducer<SingleRatingType>('userId', singleRatingReducer));

export type RatingForUserType = {
    ratings: ObjectMapState<SingleRatingType>
};

const ratingsForUserReducer = singleFieldReducer('forUser', {forUser: {}}, objectMapReducer<RatingForUserType>('forUserId', ratingsByOneUserReducer));

export type RatingForSyncVariableType = {
    forUser: ObjectMapState<RatingForUserType>;
};

const ratingsForStepReducer = singleFieldReducer('syncVariable', {syncVariable: {}}, objectMapReducer<RatingForSyncVariableType>('syncVariable', ratingsForUserReducer));

export type RatingsReducerType = {
    syncVariable: ObjectMapState<RatingForSyncVariableType>;
};

const ratingsReducer: Reducer<RatingsReducerType> = (state = {syncVariable: {}}, action) => {
    switch (action.type) {
        case RatingsReducerActionTypes.UPDATE_RATING:
            return ratingsForStepReducer(state, action);
        default:
            return state;
    }
};

export default ratingsReducer;

// ======== DBSync functions
// (used by DBSync and for unit testing)

export function getActivityActionTypes() {
    return [RatingsReducerActionTypes.UPDATE_RATING];
}

export function setRatingsInStore(store: StoreWithSharedState, problemStepId: number, syncVariable: string, forUserId: number, ratings: {[userId: number]: number}) {
    ensureFieldPath(store, 'sharedState', 'teams', problemStepId, 'ratings', 'syncVariable', syncVariable, 'forUser', forUserId, 'ratings');
    store.sharedState.teams[problemStepId].ratings.syncVariable[syncVariable].forUser[forUserId].ratings = ratings;
}

export function getRatingsForProblemStepFromStore(store: StoreWithSharedState, problemStepId: number): RatingsReducerType {
    return store.sharedState.teams[problemStepId]
        && store.sharedState.teams[problemStepId].ratings;
}

export function getRatingsForProblemStepAndSyncVariableFromStore(store: StoreWithSharedState, problemStepId: number, syncVariable: string): RatingForSyncVariableType {
    const ratingsForProblemStep = getRatingsForProblemStepFromStore(store, problemStepId);
    return ratingsForProblemStep
        && ratingsForProblemStep.syncVariable
        && ratingsForProblemStep.syncVariable[syncVariable];
}
