import {timestampedReducer, TimestampedState} from '../util/genericReducers';
import DataStatusEnum from '../DataStatusEnum';
import ensureFieldPath from '../ensureFieldPath';
import * as constants from '../util/constants';
import { Reducer } from 'redux';
import { StoreWithSharedState } from './sharedStateReducer';

// ======== Constants
const UPDATE_CANDIDATE_SOLUTION = 'update_candidate_solution';

export interface SingleCandidateSolutionState extends TimestampedState {
    text: string;
    onRevert?: boolean;
}

// ======== Reducers
const singleCandidateSolutionReducer: Reducer<SingleCandidateSolutionState> = (state = {text: '', onRevert: false}, action) => {
    switch (action.type) {
        case UPDATE_CANDIDATE_SOLUTION:
            if (state['text'] === action.text) {
                return state;
            }
            return { ...state, text: action.text, onRevert: false };
        case constants.COPY_PUBLISHED_ACTION:
            return action.fromUser.candidateSolution ? {...action.fromUser.candidateSolution} : state;
        case constants.APPEND_PUBLISHED_ACTION:
            if (action.fromUser.candidateSolution) {
                return {
                    ...state,
                    text: action.fromUser.candidateSolution.text + '\n\n' + (state.text || '')
                }
            } else {
                return state;
            }
        default:
            return state;
    }
};

export default timestampedReducer(singleCandidateSolutionReducer);

// ======== DBSync functions
// (used by DBSync and for unit testing)
export function getDBSyncActionTypes() {
    return [ UPDATE_CANDIDATE_SOLUTION ];
}

export function setCandidateSolutionInStore(store: StoreWithSharedState, problemStepId, userId, status, object) {
    ensureFieldPath(store, 'sharedState', 'teams', problemStepId, 'user', userId, 'status', [status]);
    store.sharedState.teams[problemStepId].user[userId].status[status].candidateSolution = object;
}

// ======== Action Generators
export function updateCandidateSolutionAction(problemStepId, userId, status, text) {
    return {type: UPDATE_CANDIDATE_SOLUTION, problemStepId, userId, status, text};
}

// ======== Getter functions
export function getCandidateSolutionFromStore(store: StoreWithSharedState, problemStepId, userId, status: DataStatusEnum): SingleCandidateSolutionState {
    return store.sharedState.teams[problemStepId].user[userId].status[status].candidateSolution;
}