import {combineReducers, Reducer} from 'redux';
import {v4} from 'uuid';

import {objectMapReducer, orderedListReducer, timestampedReducer, ObjectMapState, TimestampedState} from '../util/genericReducers';
import DataStatusEnum from '../DataStatusEnum';
import ensureFieldPath from '../ensureFieldPath';
import keyVariablesStatesReducer, {getDBSyncActionTypes as getStateDBSyncActionTypes, KeyVariablesAttributeState} from './keyVariablesStatesReducer';
import * as constants from '../util/constants';
import { StoreWithSharedState } from './sharedStateReducer';

// ======== Constants

const ADD_VARIABLE = 'add_variable';
const UPDATE_VARIABLE = 'update_variable';
const DELETE_VARIABLE = 'delete_variable';
const REORDER_VARIABLE = 'reorder_variable';
const SET_ALL_VARIABLES = 'set-all-variables';

export interface SingleVariableStateTypeData {
    type: string;
    label?: string;
    minStates: number;
    maxStates: number;
    fixedValues: string[] | null,
    reorderable: boolean;
    migrationDefault?: boolean;
}

export const DEFAULT_TYPE_DATA: SingleVariableStateTypeData = {
    type: '',
    minStates: 0,
    maxStates: 0,
    fixedValues: null,
    reorderable: true
};

export enum VariableType {
    TARGET = '1_target', OTHER = '2_other', DECISION = '3_decision', UTILITY = '4_utility'
}

export interface SingleVariableDataState extends SingleVariableStateTypeData {
    name: string;
    description: string;
    rationale: string;
    isTargetVariable?: boolean;
    variableType: VariableType;
}

export interface SingleVariableState {
    data: SingleVariableDataState;
    attributes: KeyVariablesAttributeState;
}

export interface KeyVariablesState extends TimestampedState {
    variable: ObjectMapState<SingleVariableState>;
    order: string[];
    onRevert?: boolean;
}

export const initialKeyVariablesState: KeyVariablesState = {variable: {}, order: []};

// ======== Reducers

export const initialSingleVariableData: SingleVariableDataState = {
    name: '',
    description: '',
    rationale: '',
    variableType: VariableType.TARGET,
    ...DEFAULT_TYPE_DATA
};

const singleVariableDataReducer: Reducer<SingleVariableDataState> = (state = initialSingleVariableData, action) => {
    // Backwards compatibility - update to variableType if isTargetVariable still being used
    const updatedState = state.isTargetVariable === undefined ? state : {
        ...state, updateVariableState: undefined, variableType: getKeyVariableType(state)
    };
    switch (action.type) {
        case ADD_VARIABLE:
            return {...updatedState, ...action.variable.data};
        case UPDATE_VARIABLE:
            return {...updatedState, ...action.value};
        case constants.UPDATE_VARIABLE_TYPE:
            const typeData = action.typeData || DEFAULT_TYPE_DATA;
            return {
                ...updatedState,
                type: typeData.type,
                minStates: typeData.minStates,
                maxStates: typeData.maxStates,
                fixedValues: typeData.fixedValues,
                reorderable: typeData.reorderable
            };
        default:
            // Return original state for all other actions, even if the state contains the old isTargetVariable field
            return state;
    }
};

const replacableKeyVariablesStatesReducer: Reducer<KeyVariablesAttributeState> = (state, action) => {
    const result = keyVariablesStatesReducer(state, action);
    if (action.type === ADD_VARIABLE) {
        return {...result, ...action.variable.attributes};
    } else {
        return result;
    }
};

export const singleVariableReducer = combineReducers<SingleVariableState>({
    data: singleVariableDataReducer,
    attributes: replacableKeyVariablesStatesReducer
});

const allVariablesReducer = objectMapReducer('variableId', singleVariableReducer, {deleteActionType: DELETE_VARIABLE});

const keyVariablesOrderReducer = orderedListReducer('variableId', ADD_VARIABLE, DELETE_VARIABLE, REORDER_VARIABLE);

const keyVariablesReducer = combineReducers<KeyVariablesState>({
    variable: allVariablesReducer,
    order: keyVariablesOrderReducer,
    timestamp: (state: string = '') => (state),
    onRevert: (state: boolean = false) => (state)
});

const keyVariablesTimestampedReducer = timestampedReducer(keyVariablesReducer);

const keyVariablesCopyableReducer: Reducer<KeyVariablesState> = (state, action) => {
    switch (action.type) {
        case constants.COPY_PUBLISHED_ACTION:
        case constants.APPEND_PUBLISHED_ACTION:
            return action.fromUser.keyVariables ? {...action.fromUser.keyVariables} : state;
        case SET_ALL_VARIABLES:
            return action.keyVariables;
        default:
            const nextState = keyVariablesTimestampedReducer(state, action);
            if (nextState !== state) {
                return {...nextState, onRevert: false};
            } else {
                return state;
            }
    }
};

export default keyVariablesCopyableReducer;

// ======== DBSync functions
// (used by DBSync and for unit testing)

export function getDBSyncActionTypes() {
    return [ADD_VARIABLE, UPDATE_VARIABLE, DELETE_VARIABLE, REORDER_VARIABLE, SET_ALL_VARIABLES, ...getStateDBSyncActionTypes()];
}

export function setKeyVariablesInStore(store: StoreWithSharedState, problemStepId: number, userId: number, status: DataStatusEnum, object: KeyVariablesState) {
    ensureFieldPath(store, 'sharedState', 'teams', problemStepId, 'user', userId, 'status', [status]);
    store.sharedState.teams[problemStepId].user[userId].status[status].keyVariables = object;
}

// ======== Action generator functions

export function addKeyVariableAction(problemStepId: number, userId: number, status: DataStatusEnum, variable = {}) {
    return {type: ADD_VARIABLE, problemStepId, userId, status, variableId: v4(), variable};
}

export interface UpdateKeyVariableAction {
    type: string;
    problemStepId: number;
    userId: number;
    status: DataStatusEnum;
    variableId: string;
    value: Partial<SingleVariableDataState>;
}

export function updateKeyVariableAction(problemStepId: number, userId: number, status: DataStatusEnum, variableId: string, value: Partial<SingleVariableDataState>): UpdateKeyVariableAction {
    return {type: UPDATE_VARIABLE, problemStepId, userId, status, variableId, value};
}

export function updateKeyVariableTypeAction(problemStepId: number, userId: number, status: DataStatusEnum, variableId, typeData) {
    return {type: constants.UPDATE_VARIABLE_TYPE, problemStepId, userId, status, variableId, typeData};
}

export function deleteKeyVariableAction(problemStepId: number, userId: number, status: DataStatusEnum, variableId, index) {
    return {type: DELETE_VARIABLE, problemStepId, userId, status, variableId, index};
}

export function reorderKeyVariableAction(problemStepId: number, userId: number, status: DataStatusEnum, oldIndex: number, newIndex: number) {
    return {type: REORDER_VARIABLE, problemStepId, userId, status, oldIndex, newIndex};
}

export function setAllKeyVariablesAction(problemStepId: number, userId: number, status: DataStatusEnum, keyVariables: KeyVariablesState) {
    return {type: SET_ALL_VARIABLES, problemStepId, userId, status, keyVariables};
}

// ======== Getter functions

export function getKeyVariablesFromStore(store: StoreWithSharedState, problemStepId, userId, status: DataStatusEnum): KeyVariablesState {
    return store.sharedState.teams[problemStepId].user[userId].status[status].keyVariables;
}

export function getKeyVariableType(variable: SingleVariableDataState) {
    return variable.isTargetVariable === undefined ? variable.variableType : variable.isTargetVariable ? VariableType.TARGET : VariableType.OTHER;
}