import React, {Component} from 'react';
import Select from 'react-select';
import {connect, DispatchProp} from 'react-redux';
import {findIndex} from 'lodash';
import memoizeOne from 'memoize-one';

import '../scss/keyVariables.scss';

import DataStatusEnum from '../../common/DataStatusEnum';
import {getLoggedInUserIdFromStore} from '../reducers/loginReducer';
import {SingleUserForProblemReducerType} from '../../common/reducers/allUsersForTeamReducer';
import {SingleProblemStepType} from '../../common/reducers/allTeamsReducer';
import ReorderableDeleteableElement from '../container/ReorderableDeleteableElement';
import PlainTextField from './PlainTextField';
import ReorderableAddableContainer from '../container/ReorderableAddableContainer';
import KeyVariablesStatesComponent from './KeyVariablesStatesComponent';
import {
    addKeyVariableAction,
    deleteKeyVariableAction,
    getKeyVariablesFromStore,
    getKeyVariableType,
    KeyVariablesState,
    reorderKeyVariableAction,
    SingleVariableStateTypeData,
    updateKeyVariableAction,
    updateKeyVariableTypeAction,
    VariableType
} from '../../common/reducers/keyVariablesReducer';
import ReferenceData from '../../common/util/referenceData';
import * as constants from '../../common/util/constants';
import {ROLE_FACILITATOR, ROLE_OBSERVER} from '../../common/util/constants';
import {addToastMessageAction} from '../reducers/snackbarToastReducer';
import HelpTips from './HelpTips';
import * as validation from '../../common/util/validation';
import {ValidationValue} from '../../common/util/validation';
import {validateKeyVariable} from '../../common/util/specificValidations';
import {safeOrderObject} from '../../common/util/safeOrder';
import commonSelectStyles from '../util/commonSelectStyles';
import DownloadNetworkComponent from './DownloadNetworkComponent';
import UploadNetworkComponent from '../container/UploadNetworkComponent';
import {FEATURE_DECISION_NETWORKS, getFlagValue} from '../../common/util/featureFlags';
import {getFeatureFlagsFromStore} from '../../common/reducers/featureFlagsReducer';
import {getProblemStepFromStore} from '../../common/reducers/allTeamsReducerGetters';
import {getUserForProblemFromStore} from '../../common/reducers/allUsersForTeamReducerGetters';

interface VariableTypeDetails {
    number: number;
    helpId: string;
    label: string;
    hasStates: boolean;
    stateLabel?: string;
    newVariableTypeData?: Partial<SingleVariableStateTypeData>;
}

const defaultProps = {
    readOnly: false,
    status: DataStatusEnum.MY_DRAFT
};

type KeyVariablesComponentDefaultProps = Readonly<typeof defaultProps>;

interface KeyVariablesComponentOwnProps {
    problemStepId: number;
    userId?: number;
    tabTense?: number;
    stepReadOnly?: boolean;
    buttonBarDiv: HTMLDivElement | null;
}

interface KeyVariablesComponentStoreProps {
    userId: number;
    loggedInUserId: number;
    problemStep: SingleProblemStepType;
    keyVariables: KeyVariablesState;
    loggedInUserForProblem: SingleUserForProblemReducerType;
    isDecisionNetworksEnabled: boolean;
}

interface KeyVariablesComponentState {
    validation: ValidationValue;
}

type KeyVariablesComponentProps = KeyVariablesComponentDefaultProps & KeyVariablesComponentOwnProps & KeyVariablesComponentStoreProps & DispatchProp;

class KeyVariablesComponent extends Component<KeyVariablesComponentProps, KeyVariablesComponentState> {

    static defaultProps = defaultProps;

    constructor(props) {
        super(props);
        this.updateValidation = this.updateValidation.bind(this);
        this.isDecisionNetworkEnabled = memoizeOne(this.isDecisionNetworkEnabled.bind(this));
        this.state = {
            validation: {}
        };
    }

    updateValidation(newValidations) {
        this.setState((prevState) => ({validation: validation.mergeValidations(prevState.validation, newValidations)}));
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (!newProps.readOnly && newProps.keyVariables.onRevert) {
            // Variable state has been reverted - recalculate all validations.
            this.updateValidation(validation.revalidate(newProps.keyVariables.order));
        }
    }

    findVariableStateTypeIndex(type): number {
        if (!type) {
            return -1;
        }
        const variableTypeData = ReferenceData.getInstance().getVariableStateTypeData();
        const index = findIndex(variableTypeData, (typeData) => (typeData.type === type));
        if (index >= 0) {
            return index;
        }
        return findIndex(variableTypeData, (typeData) => (typeData.migrationDefault));
    }

    getDetailsForVariableType(variableType: VariableType): VariableTypeDetails | undefined {
        switch (variableType) {
            case VariableType.TARGET:
                return {
                    number: 1,
                    helpId: constants.HELP_VARIABLES_TARGET_VARIABLES,
                    label: 'Target Variable',
                    hasStates: true
                };
            case VariableType.OTHER:
                return {
                    number: 2,
                    helpId: constants.HELP_VARIABLES_OTHER_VARIABLES,
                    label: 'Other Variable',
                    hasStates: true
                };
            case VariableType.DECISION:
                return this.isDecisionNetworkEnabled(this.props) ? {
                    number: 3,
                    helpId: constants.HELP_VARIABLES_DECISION_VARIABLES,
                    label: 'Decision Variable',
                    hasStates: true,
                    stateLabel: 'Decision'
                } : undefined;
            case VariableType.UTILITY:
                return this.isDecisionNetworkEnabled(this.props) ? {
                    number: 4,
                    helpId: constants.HELP_VARIABLES_UTILITY_VARIABLES,
                    label: 'Utility Node',
                    hasStates: false
                } : undefined;
        }
    }

    private isDecisionNetworkEnabled(props: KeyVariablesComponentProps): boolean {
        // Also enabled DNs if there are any Decision Variables or Utility Nodes currently in the network
        return props.isDecisionNetworksEnabled || safeOrderObject(props.keyVariables, 'variable').reduce((dn: boolean, variableId: string) => (
            dn || getKeyVariableType(props.keyVariables.variable[variableId].data) === VariableType.DECISION
                || getKeyVariableType(props.keyVariables.variable[variableId].data) === VariableType.UTILITY
        ), false);
    }


    renderSingleVariable(variableId: string, index: number, variableCount: number, typeDetails: VariableTypeDetails,
                         allValues: {[id: string]: string}) {
        const variable = this.props.keyVariables.variable[variableId];
        const variableType = getKeyVariableType(variable.data);
        const variableStateTypeIndex = this.findVariableStateTypeIndex(variable.data.type);
        const variableStateTypeOptions = ReferenceData.getInstance().getVariableStateTypeData().map((typeData, index) => (
            {label: typeData.label || typeData.type, value: index}
        ));
        const role = this.props.loggedInUserForProblem ? this.props.loggedInUserForProblem.db.role : ROLE_OBSERVER;
        const copyDisabled = (role === ROLE_OBSERVER || Number(this.props.userId) === Number(this.props.loggedInUserId)
            || this.props.stepReadOnly);
        const copyTargetStatus = (role === ROLE_FACILITATOR ? DataStatusEnum.GROUP_DRAFT : DataStatusEnum.MY_DRAFT);
        const copyTargetTabName = (copyTargetStatus === DataStatusEnum.GROUP_DRAFT ? 'Group Draft' : 'My Draft');
        return (
            <ReorderableDeleteableElement
                status={this.props.status}
                index={index}
                key={variableId}
                disableReorder={this.props.readOnly}
                title={typeDetails.label + ' ' + variableCount}
                additionalOptions={[
                    {
                        primaryText: 'Delete this variable',
                        disabled: this.props.readOnly,
                        onClick: () => {
                            this.props.dispatch(deleteKeyVariableAction(this.props.problemStepId, this.props.userId, this.props.status, variableId, index));
                            // Discard any validation failures for this variable, but wait for state to settle first
                            this.setState({}, () => {
                                this.updateValidation(validation.deleteValidationsForNames([...variable.attributes.order, variableId], this.props.keyVariables.order));
                            });
                        }
                    },
                    {
                        primaryText: `Change this variable to 'Target'`,
                        disabled: this.props.readOnly || variableType === VariableType.TARGET,
                        onClick: () => {
                            this.props.dispatch(updateKeyVariableAction(this.props.problemStepId, this.props.loggedInUserId, this.props.status, variableId, {variableType: VariableType.TARGET}));
                            this.props.dispatch(addToastMessageAction(`Variable "${variable.data.name}" changed to type 'Target'.`));
                        }
                    },
                    {
                        primaryText: `Change this variable to 'Other'`,
                        disabled: this.props.readOnly || variableType === VariableType.OTHER,
                        onClick: () => {
                            this.props.dispatch(updateKeyVariableAction(this.props.problemStepId, this.props.loggedInUserId, this.props.status, variableId, {variableType: VariableType.OTHER}));
                            this.props.dispatch(addToastMessageAction(`Variable "${variable.data.name}" changed to type 'Other'.`));
                        }
                    },
                    {
                        primaryText: `Change this variable to 'Decision'`,
                        disabled: this.props.readOnly || variableType === VariableType.DECISION,
                        onClick: () => {
                            this.props.dispatch(updateKeyVariableAction(this.props.problemStepId, this.props.loggedInUserId, this.props.status, variableId, {variableType: VariableType.DECISION}));
                            this.props.dispatch(addToastMessageAction(`Variable "${variable.data.name}" changed to type 'Decision'.`));
                        }
                    },
                    {
                        primaryText: `Change this variable to 'Utility'`,
                        disabled: this.props.readOnly || variableType === VariableType.UTILITY,
                        onClick: () => {
                            this.props.dispatch(updateKeyVariableAction(this.props.problemStepId, this.props.loggedInUserId, this.props.status, variableId, {variableType: VariableType.UTILITY}));
                            this.props.dispatch(addToastMessageAction(`Variable "${variable.data.name}" changed to type 'Utility'.`));
                        }
                    },
                    {
                        primaryText: 'Copy this variable to ' + copyTargetTabName,
                        disabled: copyDisabled,
                        onClick: () => {
                            this.props.dispatch(addKeyVariableAction(this.props.problemStepId, this.props.loggedInUserId, copyTargetStatus, variable));
                            this.props.dispatch(addToastMessageAction(`Variable "${variable.data.name}" copied to ${copyTargetTabName}.`));
                        }
                    }
                ]}
            >
                <div className="targetVariablesStyle">
                    <div className="targetVariableDetail">
                        <PlainTextField
                            className='mostlyFullWidthInput'
                            rows={2}
                            cols={3}
                            text={variable.data.name}
                            readOnly={this.props.readOnly}
                            onChange={(value) => {
                                this.props.dispatch(updateKeyVariableAction(this.props.problemStepId, this.props.userId, this.props.status, variableId, {
                                    name: value
                                }));
                            }}
                            placeholder='Variable name'
                            validationName={variableId}
                            validation={this.state.validation}
                            onValidate={(name, value, oldValue) => {
                                this.updateValidation(validateKeyVariable(name, value, oldValue, allValues));
                            }}
                        />
                    </div>
                    <div className="targetVariableDetailRow">
                        <div className="targetVariableDetail">
                            <div className="miniTitle">Variable Description</div>
                            <PlainTextField
                                className='mostlyFullWidthInput'
                                rows={3}
                                cols={5}
                                text={variable.data.description}
                                placeholder="Variable Description"
                                onChange={(value) => {
                                    this.props.dispatch(updateKeyVariableAction(this.props.problemStepId, this.props.userId, this.props.status, variableId, {
                                        description: value
                                    }));
                                }}
                                readOnly={this.props.readOnly}
                            />
                        </div>
                        <div className="targetVariableDetail">
                            <div className="miniTitle">Variable Rationale</div>
                            <PlainTextField
                                className='mostlyFullWidthInput'
                                rows={3}
                                cols={5}
                                text={variable.data.rationale}
                                placeholder="Variable Rationale"
                                onChange={(value) => {
                                    this.props.dispatch(updateKeyVariableAction(this.props.problemStepId, this.props.userId, this.props.status, variableId, {
                                        rationale: value
                                    }));
                                }}
                                readOnly={this.props.readOnly}
                            />
                        </div>
                    </div>
                    {
                        !typeDetails.hasStates ? null : (
                            <>
                                <div className="targetVariableDetailRow">
                                    <div className="targetVariableType">
                                        <div className="miniTitle">Type</div>
                                        <Select options={variableStateTypeOptions}
                                                className='selectType'
                                                value={variableStateTypeIndex < 0 ? undefined : variableStateTypeOptions[variableStateTypeIndex]}
                                                styles={commonSelectStyles}
                                                onChange={(selected) => {
                                                    if (selected) {
                                                        const value = selected ? ReferenceData.getInstance().getVariableStateTypeData()[selected.value] : null;
                                                        this.props.dispatch(updateKeyVariableTypeAction(
                                                            this.props.problemStepId,
                                                            this.props.userId,
                                                            this.props.status,
                                                            variableId,
                                                            value
                                                        ));
                                                    }
                                                }}
                                                isSearchable={true}
                                                isClearable={true}
                                                isDisabled={this.props.readOnly}
                                        />
                                    </div>
                                    <div className="targetVariableStateDiv">
                                        <KeyVariablesStatesComponent
                                            problemStepId={this.props.problemStepId}
                                            userId={this.props.userId}
                                            status={this.props.status}
                                            readOnly={this.props.readOnly}
                                            variableId={variableId}
                                            dispatch={this.props.dispatch}
                                            attributes={variable.attributes}
                                            minStates={variable.data.minStates}
                                            maxStates={variable.data.maxStates}
                                            reorderable={variable.data.reorderable || variable.data.reorderable === undefined}
                                            fixedValues={variable.data.fixedValues !== null}
                                            validation={this.state.validation}
                                            updateValidation={this.updateValidation}
                                            stateLabel={typeDetails.stateLabel}
                                        />
                                    </div>
                                </div>
                            </>
                        )
                    }
                </div>
            </ReorderableDeleteableElement>
        );
    }

    renderVariables(variableType: VariableType, allValues: {[id: string]: string}) {
        let variableCount = 1;
        const typeDetails = this.getDetailsForVariableType(variableType);
        return !typeDetails ? null : (
            <div key={variableType}>
                <div className='minorTitle instructionNumberDivStyle roleBackgroundColour'>
                    <span className="instructionNumberListStyle">{typeDetails.number}</span>
                    <span className="toolTipContainer instructionDivStyle">Identify {typeDetails.label}s</span>
                </div>
                <HelpTips helpId={typeDetails.helpId} problemStepId={this.props.problemStepId}/>
                <ReorderableAddableContainer
                    disableAdd={this.props.readOnly}
                    addLabel={typeDetails.label.toUpperCase()}
                    onAdd={() => {
                        this.props.dispatch(addKeyVariableAction(this.props.problemStepId, this.props.userId, this.props.status, {data: {variableType, ...typeDetails.newVariableTypeData}}));
                    }}
                    onSortEnd={({oldIndex, newIndex}) => {
                        this.props.dispatch(reorderKeyVariableAction(this.props.problemStepId, this.props.userId, this.props.status, oldIndex, newIndex));
                    }}
                >
                    {
                        safeOrderObject(this.props.keyVariables, 'variable').map((variableId, index) => {
                            return (getKeyVariableType(this.props.keyVariables.variable[variableId].data) !== variableType) ? null :
                                this.renderSingleVariable(variableId, index, variableCount++, typeDetails, allValues)
                        })
                    }
                </ReorderableAddableContainer>
            </div>
        );
    }

    render() {
        const allValues = safeOrderObject(this.props.keyVariables, 'variable').reduce((all, variableId) => {
            all[variableId] = this.props.keyVariables.variable[variableId].data.name;
            return all;
        }, {});
        return (
            <UploadNetworkComponent problemStepId={this.props.problemStepId} buttonBarDiv={this.props.buttonBarDiv} uploadDisabled={this.props.readOnly}>
                <DownloadNetworkComponent problemStepId={this.props.problemStepId} userId={this.props.userId}
                                          status={this.props.status} right={true} buttonBarDiv={this.props.buttonBarDiv}
                                          buttonClassName='lightTheme'
                >
                    <div className="variablesDiv">
                        {
                            Object.keys(VariableType).map((type) => (this.renderVariables(VariableType[type], allValues)))
                        }
                    </div>
                </DownloadNetworkComponent>
            </UploadNetworkComponent>
        );
    }

}

function mapStoreToProps(store, myProps) {
    const problemStepId = myProps.problemStepId;
    const loggedInUserId = getLoggedInUserIdFromStore(store);
    const userId = myProps.userId || loggedInUserId;
    const status = (myProps.status === undefined) ? KeyVariablesComponent.defaultProps.status : myProps.status;
    return {
        userId,
        loggedInUserId,
        problemStep: getProblemStepFromStore(store, problemStepId),
        keyVariables: getKeyVariablesFromStore(store, problemStepId, userId, status),
        loggedInUserForProblem: getUserForProblemFromStore(store, problemStepId, getLoggedInUserIdFromStore(store)),
        isDecisionNetworksEnabled: getFlagValue(getFeatureFlagsFromStore(store)[FEATURE_DECISION_NETWORKS], store)
    };
}

export default connect<KeyVariablesComponentStoreProps, DispatchProp,
    KeyVariablesComponentOwnProps & Partial<KeyVariablesComponentDefaultProps>>(mapStoreToProps)(KeyVariablesComponent);