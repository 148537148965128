import React, {Component} from 'react';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';
import classNames from 'classnames';

import {getFormattedMessageTimeStamp} from '../util/timeUtils';
import UserNotes from './UserNotes';
import UserMessages from './UserMessages';
import HelpContent from './HelpContent';
import UserDetailsComponent from './UserDetailsComponent';
import VisibleWhenLoggedIn from '../container/VisibleWhenLoggedIn';
import ProblemSelectedContainer from '../container/ProblemSelectedContainer';
import MainMenu from '../container/MainMenu';
import HeaderCurrentProblem from './HeaderCurrentProblem';
import HeaderCurrentStep from './HeaderCurrentStep';
import HeaderCurrentTimer from './HeaderCurrentTimer';
import {getUserFromStore} from '../reducers/loginReducer';
import PendingActionIndicator from './PendingActionIndicator';
import {getServerStateFromStore} from '../../common/reducers/serverStateReducer';
import {getAllProblemDBsFromStore} from '../../common/reducers/allProblemsReducer';
import bardHeaderImg from '../images/BARD_RGB_MINI_LOGO.png';
import {SELECT_PATH, buildRestPath} from '../../common/clientServer/navigation';
import * as constants from '../../common/util/constants';
import {getProblemStepFromStore} from "../../common/reducers/allTeamsReducerGetters";
import {getUserForProblemFromStore} from "../../common/reducers/allUsersForTeamReducerGetters";

class PageHeader extends Component {

    constructor(props) {
        super(props);

        this.state = {
            loginPath: null
        };
    }

    
    isThisFoyerOrAdmin(location){
        const parts = location
        .split('/')
        .filter(v => !!v);

        return (parts.length === 0 || parts[0] === 'select' || parts[0] === 'admin');
    }

    renderUserName(user) {
        if (!user) {
            return '';
        }
        return (user.givenName && user.familyName)
            ? user.givenName + ' ' + user.familyName
            : user.givenName || user.familyName || user.loginName;
    }

    render() {
        const isFoyerOrAdmin = this.isThisFoyerOrAdmin(this.props.location.pathname);
        const problemStepId = this.props.match && Number(this.props.match.params.problemStepId);

        const isSoloBardUser = (this.props.userRole === constants.ROLE_SOLO_ANALYST);

        const probId = this.props.problemStep && this.props.problemStep.problemId;

        const isProblemTraining = this.props.problems && probId && this.props.problems[probId].isTraining;

        const isProblemClosed = this.props.problemStep && this.props.problemStep.state === constants.STATE_CLOSED && this.props.problemStep.roundSequenceNumber === null && !isProblemTraining;

        const pushHistory = buildRestPath(SELECT_PATH, {problemStepId});
        return (
            <div>
            <div className={classNames('homeHeaderStyle darkBackground', {
                atFoyer: isFoyerOrAdmin,
                notAtFoyer: !isFoyerOrAdmin
            })}>              
                <div className="exitImgDiv toolTipContainer">                    
                    <img onClick={() => {
                        this.props.history.push(pushHistory);
                    }} className="bardLogo" src={bardHeaderImg} alt="BARD"/>
                    {
                        isFoyerOrAdmin
                        ? <span className="toolTip toolTipBard">Bayesian ARgumentation via Delphi</span>
                        : <span className="toolTip toolTipBard">Back to Foyer</span>
                    }
                </div>                
                
                <VisibleWhenLoggedIn className='loggedInHeader' showWhenNotLoggedIn={(
                    <div className='welcomeBanner'>
                        Welcome
                    </div>
                )}>
                    <div className='homeUserDetailsComponentStyle'>

                        {!isFoyerOrAdmin ? 
                            <ProblemSelectedContainer problemStepId={problemStepId} whenSelected={true}>
                                <div className="homeHeaderUserLeftPane">
                                    <HeaderCurrentProblem problemStepId={problemStepId}/>
                                    <HeaderCurrentStep problemStepId={problemStepId}/>
                                    <HeaderCurrentTimer problemStepId={problemStepId}/>
                                    {
                                        isSoloBardUser ? null : (
                                            <UserDetailsComponent problemStepId={problemStepId}/>
                                        )
                                    }
                                </div>

                                <div className="homeHeaderUserRightPane">
                                    <PendingActionIndicator/>
                                    {this.props.userRole && this.props.problemStep && !isProblemClosed ?
                                        <HelpContent step={this.props.problemStep} user={this.props.userFromStore.db} /> 
                                        : null
                                    }
                                    {
                                        isSoloBardUser ? null : (
                                            <UserMessages problemStepId={problemStepId}/>
                                        )
                                    }
                                    <UserNotes problemStepId={problemStepId}/>
                                </div>
                            </ProblemSelectedContainer>
                            :
                           
                            <ProblemSelectedContainer whenSelected={false}>
                                <div className='welcomeBanner'>Welcome {this.renderUserName(this.props.user)}</div>
                            </ProblemSelectedContainer>
                           
                        }
                    </div>
                </VisibleWhenLoggedIn>
                <MainMenu/>
            </div>
            { this.props.serverState && this.props.serverState.shutdownScheduled ? (<div className='alertBanner'>
                <div className='alertBannerMessage'>
                    <span>BARD has been scheduled to be unavailable at {getFormattedMessageTimeStamp(this.props.serverState.scheduledShutdownTimeStamp)}</span>
                </div>
            </div>) : (null)}
            </div>
        )
    }
}


function mapStoreToProps(store, myProps) {
    const problemStepId = myProps.match && Number(myProps.match.params.problemStepId);
    const user = getUserFromStore(store);
    const userId = user && user.id;
    const userFromStore = userId && problemStepId && getUserForProblemFromStore(store, problemStepId, userId);
    const userRole = (userId && userFromStore) ? userFromStore.db.role : null;
    
    const problemStep = problemStepId && userFromStore && getProblemStepFromStore(store, problemStepId);
    return {
        user,
        userRole,
        userFromStore,
        problemStep,
        serverState: getServerStateFromStore(store),
        problems: getAllProblemDBsFromStore(store)
    };
}

export default withRouter(connect(mapStoreToProps)(PageHeader));