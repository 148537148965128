import React, {ReactNode} from 'react';
import {createPortal} from 'react-dom';
import {MenuButtonColumn} from 'react-md';
import classNames from 'classnames';
import {connect, DispatchProp} from 'react-redux';

import '../scss/downloadNetworkComponent.scss';

import {buildRestPath, DOWNLOAD_BN_ENDPOINT} from '../../common/clientServer/navigation';
import DataStatusEnum from '../../common/DataStatusEnum';
import IconLabelButton from './IconLabelButton';
import {StoreWithSharedState} from '../../common/reducers/sharedStateReducer';
import {BNEngine, BnWrapperState, getBnWrapperStateFromStore} from '../../common/reducers/bnWrapperStateReducer';
import {addToastMessageAction} from '../reducers/snackbarToastReducer';
import {validateAllKeyVariables} from '../../common/util/specificValidations';
import {getAnyProblemTextFromValidation} from '../../common/util/validation';
import {getKeyVariablesFromStore, KeyVariablesState} from '../../common/reducers/keyVariablesReducer';

interface DownloadNetworkComponentStoreProps {
    bnWrapperState: BnWrapperState;
    keyVariables?: KeyVariablesState;
}

interface DownloadNetworkComponentOwnProps {
    problemStepId: number;
    userId?: number;
    status?: DataStatusEnum;
    right?: boolean;
    buttonBarDiv?: HTMLDivElement | null;
    disabled?: boolean;
    label?: string;
    className?: string;
    buttonClassName?: string;
    noSanityCheck?: boolean;
    children?: ReactNode;
}

type DownloadNetworkComponentProps = DownloadNetworkComponentStoreProps & DownloadNetworkComponentOwnProps & DispatchProp;

class DownloadNetworkComponent extends React.Component<DownloadNetworkComponentProps> {

    static problemEnginesPlaceholder: BNEngine[] = [{name: 'Problems', netClass: '', fileSuffix: []}];

    getNetworkProblems(): string {
        let problem = '';
        if (this.props.keyVariables) {
            const validations = validateAllKeyVariables(this.props.keyVariables);
            problem = getAnyProblemTextFromValidation(validations) || (
                this.props.keyVariables.order.length === 0 ? 'Network is completely empty' : ''
            );
        }
        return problem;
    }

    renderDownloadNetwork() {
        const problems = this.getNetworkProblems();
        const bnEngines = problems ? DownloadNetworkComponent.problemEnginesPlaceholder : this.props.bnWrapperState.engines;
        if (bnEngines.length === 0) {
            return null;
        } else {
            const menuItems = bnEngines.map((engine) => (
                <IconLabelButton
                    key={'networkLink' + engine.name}
                    id={'networkLink' + engine.name}
                    className={classNames('small', this.props.buttonClassName)}
                    href={problems ? undefined : buildRestPath(DOWNLOAD_BN_ENDPOINT, {
                        problemStepId: this.props.problemStepId, userId: this.props.userId,
                        status: this.props.status, format: engine.name
                    })}
                    download={!problems}
                    onClick={!problems ? undefined : () => {
                        this.props.dispatch(addToastMessageAction('Cannot download network: ' + problems));
                    }}
                    iconChildren={'save_alt'}
                    disabled={this.props.disabled}
                    label={(bnEngines.length === 1)
                        ? (this.props.label || 'Download this network')
                        : (engine.name + ' format')}
                />
            ));
            return (
                <div className={classNames(this.props.className, {downloadNetworkLinkDiv: this.props.buttonBarDiv !== undefined})}>
                    {
                        (menuItems.length === 1) ? (
                            menuItems[0]
                        ) : (
                            <MenuButtonColumn
                                id='downloadNetworkButton'
                                flat={true}
                                disabled={this.props.disabled}
                                menuItems={menuItems}
                            >
                                <IconLabelButton
                                    id='networkLink'
                                    className={classNames('small', this.props.buttonClassName)}
                                    iconChildren={'save_alt'}
                                    disabled={this.props.disabled}
                                    label={this.props.label || 'Download this network'}
                                />
                            </MenuButtonColumn>
                        )
                    }
                </div>
            );

        }
    }

    render() {
        return (
            <>
                {this.props.children}
                {
                    this.props.buttonBarDiv === null ? null
                        : this.props.buttonBarDiv === undefined ? this.renderDownloadNetwork()
                        : createPortal(this.renderDownloadNetwork(), this.props.buttonBarDiv)
                }
            </>
        );
    }
}

function mapStoreToProps(store: StoreWithSharedState, {problemStepId, userId, status, noSanityCheck}: DownloadNetworkComponentOwnProps): DownloadNetworkComponentStoreProps {
    return {
        bnWrapperState: getBnWrapperStateFromStore(store),
        keyVariables: noSanityCheck ? undefined : getKeyVariablesFromStore(store, problemStepId, userId,
            status === undefined ? DataStatusEnum.PUBLISHED : status)
    };
}

export default connect(mapStoreToProps)(DownloadNetworkComponent);