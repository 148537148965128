import React, {Component} from 'react';
import {connect} from 'react-redux';
import {RouteComponentProps, withRouter} from 'react-router';
import classNames from 'classnames';
import moment from 'moment';
import {Button, FontIcon} from 'react-md';

import {
    buildRestPath,
    PROBLEM_PATH,
    ProblemSaveLoadPathRouteParameters,
    TAGGED_SAVED_DATA_PATH
} from '../../common/clientServer/navigation';
import {StoreWithSharedState} from '../../common/reducers/sharedStateReducer';
import {SingleUserForProblemReducerType} from '../../common/reducers/allUsersForTeamReducer';
import {getLoggedInUserIdFromStore} from '../reducers/loginReducer';
import * as constants from '../../common/util/constants';
import {SavedTag} from '../../server/service/savedTaggedDataService';
import LoadingBar from './LoadingBar';
import SingleInput from './SingleInput';
import ConfirmationDialog from './ConfirmationDialog';
import {getUserForProblemFromStore} from '../../common/reducers/allUsersForTeamReducerGetters';

import '../scss/problemSaveLoadComponent.scss';

type ProblemSaveLoadComponentOwnProps = RouteComponentProps<ProblemSaveLoadPathRouteParameters>;

interface ProblemSaveLoadComponentStoreProps {
    userId: number;
    userForProblem: SingleUserForProblemReducerType;
}

type ProblemSaveLoadComponentProps = ProblemSaveLoadComponentOwnProps & ProblemSaveLoadComponentStoreProps;

enum SortColumn {
    NAME = 'Save Name',
    SAVED_AT = 'Saved At'
}

interface ProblemSaveLoadComponentState {
    loading: boolean;
    error: boolean;
    saving: boolean;
    sortColumn: SortColumn;
    sortDescending: boolean;
    saves: SavedTag[];
    saveTag?: string;
    confirmDelete?: string;
    confirmLoad?: string;
    confirmSave?: string;
}

class ProblemSaveLoadComponent extends Component<ProblemSaveLoadComponentProps, ProblemSaveLoadComponentState> {

    constructor(props: ProblemSaveLoadComponentProps) {
        super(props);
        this.sortSaves = this.sortSaves.bind(this);
        this.state = {
            loading: true,
            error: false,
            saving: false,
            sortColumn: SortColumn.SAVED_AT,
            sortDescending: true,
            saves: []
        };
    }

    componentDidMount() {
        return this.loadSavedDrafts();
    }

    async loadSavedDrafts() {
        this.setState({loading: true});
        const url = buildRestPath(TAGGED_SAVED_DATA_PATH, {problemStepId: this.props.match.params.problemStepId, userId: this.props.userId});
        console.log(url);
        const result = await fetch(url, {credentials: 'include'});
        if (result.ok) {
            this.setState({saves: await result.json(), loading: false});
        } else {
            this.setState({error: true});
        }
    }

    private isTagUsed() {
        return this.state.saves.reduce((used, save) => (used || this.state.saveTag === save.tag), false);
    }

    private async saveTag(tag: string) {
        const url = buildRestPath(TAGGED_SAVED_DATA_PATH, {problemStepId: this.props.match.params.problemStepId, userId: this.props.userId, tag});
        this.setState({saving: false, saveTag: undefined, confirmSave: undefined, loading: true});
        await fetch(url, {credentials: 'include', method: 'put'});
        await this.loadSavedDrafts();
    }

    private renderSortableColumnHeader(column: SortColumn) {
        if (column !== this.state.sortColumn) {
            return <span onClick={() => {this.setState({sortColumn: column, sortDescending: column === SortColumn.SAVED_AT})}}>{column.toString()}</span>;
        } else {
            const arrow = this.state.sortDescending ? <FontIcon>sort</FontIcon> : <FontIcon className='flipped'>sort</FontIcon>;
            return <span className='sortColumn' onClick={() => {this.setState({sortDescending: !this.state.sortDescending})}}>{column.toString()} {arrow}</span>;
        }
    }

    private sortSaves(s1: SavedTag, s2: SavedTag): number {
        const direction = this.state.sortDescending ? 1 : -1;
        switch (this.state.sortColumn) {
            case SortColumn.NAME:
                return s1.tag < s2.tag ? direction : s1.tag === s2.tag ? 0 : -direction;
            case SortColumn.SAVED_AT:
                const date1 = moment(s1.savedAt).toDate().getTime();
                const date2 = moment(s2.savedAt).toDate().getTime();
                return date1 < date2 ? direction : date1 === date2 ? 0 : -direction;
        }
    }

    render() {
        if (!this.props.userForProblem) {
            return null;
        }
        const role = this.props.userForProblem.db.role;
        return (
            <div className={classNames('problemSaveLoad', {
                analyst: role === constants.ROLE_ANALYST,
                facilitator: role === constants.ROLE_FACILITATOR,
                soloAnalyst: role === constants.ROLE_SOLO_ANALYST,
            })}>
                <div className='previousSaves'>
                    {
                        this.state.error ? (
                            <p>There was an error contacting the server.  Please try again later.</p>
                        ) : this.state.loading ? (
                            <LoadingBar id='loadingSavedTags'/>
                        ) : this.state.saves.length === 0 && !this.state.saving ? (
                            <p>Here you may save a copy of your current draft work to the server.  All work across all
                                tabs is saved.  You can also load saved drafts, replacing your current draft work with
                                previously saved work.</p>
                        ) : (
                            <table>
                                <tr>
                                    <th>{this.renderSortableColumnHeader(SortColumn.NAME)}</th>
                                    <th>{this.renderSortableColumnHeader(SortColumn.SAVED_AT)}</th>
                                    <th>Actions</th>
                                </tr>
                                {
                                    !this.state.saving ? null : (
                                        <tr>
                                            <td>
                                                <SingleInput inputType='text' placeholder='Enter save name' content={this.state.saveTag}
                                                             onChange={(saveTag: string) => {this.setState({saveTag})}}/>
                                            </td>
                                            <td>&mdash;</td>
                                            <td>
                                                <Button flat={true} className='mdActionButton roleBackgroundColour'
                                                        disabled={!this.state.saveTag}
                                                        onClick={async () => {
                                                            if (this.isTagUsed()) {
                                                                this.setState({confirmSave: this.state.saveTag});
                                                            } else {
                                                                await this.saveTag(this.state.saveTag!);
                                                            }
                                                        }}>
                                                    {this.isTagUsed() ? 'Overwrite' : 'Save'}
                                                </Button>
                                                <Button flat={true} className='mdActionButton roleBackgroundColour' onClick={() => {
                                                    this.setState({saving: false, saveTag: undefined})
                                                }}>Cancel</Button>
                                            </td>
                                        </tr>
                                    )
                                }
                                {
                                    this.state.saves
                                        .sort(this.sortSaves)
                                        .map((save) => (
                                            <tr>
                                                <td>{save.tag}</td>
                                                <td>{moment(save.savedAt).format('LLLL')}</td>
                                                <td>
                                                    <Button flat={true} className='mdActionButton roleBackgroundColour' onClick={async () => {
                                                        this.setState({confirmLoad: save.tag})
                                                    }}>Load</Button>
                                                    <Button flat={true} className='mdActionButton roleBackgroundColour' onClick={async () => {
                                                        this.setState({confirmDelete: save.tag})
                                                    }}>Delete</Button>
                                                </td>
                                            </tr>
                                        ))
                                }
                            </table>
                        )
                    }
                </div>
                <div className='controls'>
                    <Button flat={true} className='mdActionButton roleBackgroundColour'
                            disabled={this.state.saving || this.state.loading}
                            onClick={() => {this.setState({saving: true})}}
                    >
                        Save current draft
                    </Button>
                    <Button flat={true} className='mdActionButton roleBackgroundColour' onClick={() => {
                        this.props.history.push(buildRestPath(PROBLEM_PATH, {problemStepId: this.props.match.params.problemStepId}));
                    }}>Done</Button>
                </div>
                <ConfirmationDialog
                    visible={this.state.confirmDelete !== undefined}
                    dialogTitle='Confirm delete'
                    onHideAction={async () => {
                        const url = buildRestPath(TAGGED_SAVED_DATA_PATH, {problemStepId: this.props.match.params.problemStepId, userId: this.props.userId, tag: this.state.confirmDelete});
                        this.setState({loading: true, confirmDelete: undefined});
                        await fetch(url, {credentials: 'include', method: 'delete'});
                        await this.loadSavedDrafts();
                    }}
                    onCancelAction={() => {this.setState({confirmDelete: undefined})}}
                    dialogContent={
                        <div>
                            Are you sure you want to delete saved draft "{this.state.confirmDelete}"?
                            <p><strong>This action cannot be undone.</strong></p>
                        </div>
                    }
                />
                <ConfirmationDialog
                    visible={this.state.confirmLoad !== undefined}
                    dialogTitle='Confirm load'
                    onHideAction={async () => {
                        const url = buildRestPath(TAGGED_SAVED_DATA_PATH, {problemStepId: this.props.match.params.problemStepId, userId: this.props.userId, tag: this.state.confirmLoad});
                        this.setState({loading: true, confirmLoad: undefined});
                        await fetch(url, {credentials: 'include', method: 'post'});
                        this.props.history.push(buildRestPath(PROBLEM_PATH, {problemStepId: this.props.match.params.problemStepId}));
                    }}
                    onCancelAction={() => {this.setState({confirmLoad: undefined})}}
                    dialogContent={
                        <div>
                            <p>Are you sure you want to load saved draft "{this.state.confirmLoad}"?</p>
                            <p><strong>Your current draft work in all steps will be overwritten!</strong></p>
                            <p>If you do not wish to lose your current draft work, you can hit Cancel and save it first,
                                before proceeding with a load.</p>
                        </div>
                    }
                />
                <ConfirmationDialog
                    visible={this.state.confirmSave !== undefined}
                    dialogTitle='Confirm overwrite previous save'
                    onHideAction={async () => {
                        await this.saveTag(this.state.confirmSave!);
                    }}
                    onCancelAction={() => {this.setState({confirmSave: undefined})}}
                    dialogContent={
                        <div>
                            <p>There is already a saved draft with the name of "{this.state.confirmSave}"</p>
                            <p>Are you sure you wish to overwrite this saved data with your current draft work?</p>
                        </div>
                    }
                />
            </div>
        );
    }
}

function mapStoreToProps(store: StoreWithSharedState, ownProps: ProblemSaveLoadComponentProps): ProblemSaveLoadComponentStoreProps {
    const problemStepId = ownProps.match.params.problemStepId;
    const userId = getLoggedInUserIdFromStore(store);
    return {
        userId,
        userForProblem: getUserForProblemFromStore(store, problemStepId, userId)
    };
}

export default withRouter(connect(mapStoreToProps)(ProblemSaveLoadComponent));