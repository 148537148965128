import * as constants from '../../common/util/constants';
import DataStatusEnum from '../../common/DataStatusEnum';
//File path needs to be updated for Monash experiements
const helpContentUrlPrefix = `https://bard-problem-resources.s3.amazonaws.com/help-content/CREATE`;

const helpTipsOnScreenLeft = {
    [constants.STEP_EXPLORE_PROBLEM]: {
        tipContent: [
            {
                tipName: "EXPLORE PROBLEM",
                tipObj:[ 
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_PRIVATE_ROUND], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT], 
                        text: ["First, familiarize yourself with the problem. Read the information presented carefully, and identify the key pieces of information provided that will help you answer the questions posed in the problem statement.","Create your list of hypotheses and evidence then click the ‘PUBLISH MY DRAFT’ button to share your work with the rest of the team."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_DIRECT_EXCHANGE], 
                        bardRoles : [constants.ROLE_FACILITATOR],             
                        statuses : [DataStatusEnum.GROUP_DRAFT], 
                        text: ["Collate the responses from the analysts’ published work.","You can either copy-and-paste single elements or use the “Copy to Group Draft” on the Published tab."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_SOLO_BARD],
                        bardRoles : [constants.ROLE_SOLO_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT], 
                        text: ["First, familiarize yourself with the problem.","Read the information presented carefully, and identify the key pieces of information provided that will help you answer the questions posed in the problem statement.","Create your list of hypotheses and evidence then click the ‘SAVE’ button to save your work."],
                        link: []
                    }
                ]
            },
            {
            tipName: "tip", 
            tipObj:[ 
                {
                    bardModes : [constants.MODE_DIRECT_EXCHANGE], 
                    bardRoles : [constants.ROLE_ANALYST],             
                    statuses : [DataStatusEnum.MY_DRAFT], 
                    text: ["The first step in BARD is to familiarise yourself with the problem. When you are ready, press 'PUBLISH MY DRAFT'  to share your work with the group."],
                    link: []
                },
                {
                    bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_PRIVATE_ROUND], 
                    bardRoles : [constants.ROLE_FACILITATOR],             
                    statuses : [DataStatusEnum.GROUP_DRAFT], 
                    text: ["If multiple analysts have included the same or very similar hypotheses/evidence, you should choose one (or combine them), keeping any unique rationales.","Indicate in brackets, e.g. (4), how many analysts had the same Hypothesis/Evidence."],
                    link: []
                },
                {
                    bardModes : [constants.MODE_DELPHI_EXCHANGE, constants.MODE_DIRECT_EXCHANGE, constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_PRIVATE_ROUND], 
                    bardRoles : [constants.ROLE_ANALYST],             
                    statuses : [DataStatusEnum.GROUP], 
                    text: ["This is the combined Group response (collated by the facilitator) for the Explore Problem step and will be the basis for the next steps. Please read through it and familiarize yourself with any new information."],
                    link: []
                },
                {
                    bardModes : [constants.MODE_DELPHI_EXCHANGE, constants.MODE_DIRECT_EXCHANGE, constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_PRIVATE_ROUND], 
                    bardRoles : [constants.ROLE_FACILITATOR],             
                    statuses : [DataStatusEnum.GROUP], 
                    text: ["This is the combined Group response (collated by you) for the Explore Problem step and will be the basis for the next steps.","You can update the combined group work based on analysts' published work, at any time, as appropriate."],
                    link: []
                },
                {
                    bardModes : [constants.MODE_DIRECT_EXCHANGE, constants.LAUNCH_MODE_REAL_TIME_JURY], 
                    bardRoles : [constants.ROLE_ANALYST],             
                    statuses : [DataStatusEnum.PUBLISHED], 
                    text: ["Here are all the analysts' published Key Questions, Hypotheses and Evidence items. Refer to the Group tab for the combined list published by the facilitator."],
                    link: []
                },
                {
                    bardModes : [constants.MODE_DIRECT_EXCHANGE, constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_PRIVATE_ROUND], 
                    bardRoles : [constants.ROLE_FACILITATOR],             
                    statuses : [DataStatusEnum.PUBLISHED], 
                    text: ["Here are all the analysts' published Key Questions, Hypotheses and Evidence items."],
                    link: []
                },
                {
                    bardModes : [constants.MODE_PRIVATE_ROUND], 
                    bardRoles : [constants.ROLE_ANALYST],             
                    statuses : [DataStatusEnum.PUBLISHED], 
                    text: ["Here are the Key Questions, Hypotheses and Evidence items you published and shared with the team. Refer to the Group tab for the combined list published by the facilitator."],
                    link: []
                }
            ]
        },
        {
            tipName:"DISCUSSION",
            tipObj:[
                {
                    bardModes: [constants.MODE_DELPHI_EXCHANGE],
                    bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST], 
                    statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                    text : ["In Step EXPLORE PROBLEM, Analyst cannot initiate or contribute to Discussion Topics."],
                    link: []
                },
                {
                    bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY], 
                    bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST],             
                    statuses : [constants.HELP_SUBSTEP_DISCUSSION], 
                    text: ["Use this area to discuss the Explore Problem step with other analysts."],
                    link: []
                },
                {
                    bardModes : [constants.MODE_DIRECT_EXCHANGE],
                    bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST],
                    statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                    text : ["Analysts must Publish their first attempt at this Step before they can see the Discussions."],
                    link:[]
                }
            ]
        },
        {
            tipName: "REMEMBER", 
            tipObj:[ 
                {
                    bardModes : [constants.MODE_DIRECT_EXCHANGE, constants.MODE_PRIVATE_ROUND], 
                    bardRoles : [constants.ROLE_FACILITATOR],             
                    statuses : [DataStatusEnum.GROUP_DRAFT], 
                    text: ["Listen to your team members","Revise as needed","Search thoroughly for possibilities and evidence before reaching a conclusion","Fair judgment of favour", "Confidence that is assigned appropriately based on the quantity and the quality of thinking done and available information"],
                    link: []
                },
                {
                    bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_DIRECT_EXCHANGE], 
                    bardRoles : [constants.ROLE_FACILITATOR],             
                    statuses : [DataStatusEnum.PUBLISHED, DataStatusEnum.GROUP], 
                    text: ["Search thoroughly for possibilities and evidence before reaching a conclusion.","Take care to give appropriate consideration to the evidence items that the analysts have identified; don't discount evidence that argues against the favoured hypothesis, and don't give too much weight to evidence in favour.","View the work of analysts and revise the group work as needed."],
                    link: []
                },
                {
                    bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_DIRECT_EXCHANGE], 
                    bardRoles : [constants.ROLE_ANALYST],             
                    statuses : [DataStatusEnum.PUBLISHED], 
                    text: ["Search thoroughly for possibilities and evidence before reaching a conclusion.","Take care to give appropriate consideration to the evidence items you have identified; don't discount evidence that argues against your favoured hypothesis, and don't give too much weight to evidence in favour.","View the work of teammates and revise your work as needed."],
                    link: []
                },
                {
                    bardModes: [constants.LAUNCH_MODE_REAL_TIME_JURY],
                    bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST], 
                    statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                    text : ["Make the discussion topics clear and specific","Responses should be relevant, courteous, targeted (addressed to @Member…) and constructive","You can include screenshots of diagrams or sketches"],
                    link: []
                },
                {
                  bardModes: [constants.MODE_DELPHI_EXCHANGE],
                  bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST], 
                  statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                  text : ["In Step VARIABLES, Analyst cannot initiate or contribute to Discussion Topics."],
                  link: []
                }
            ]
        },
        {
            tipName: "AVOID", 
            tipObj:[ 
                {
                    bardModes : [constants.MODE_DIRECT_EXCHANGE, constants.MODE_PRIVATE_ROUND], 
                    bardRoles : [constants.ROLE_FACILITATOR],             
                    statuses : [DataStatusEnum.GROUP_DRAFT, DataStatusEnum.GROUP], 
                    text: ["Failing to look for other possibilities","Looking only for evidence to support your favored conclusion","Finding evidence against your favored conclusion but (partially or fully) ignoring it","When drawing conclusions, giving too much weight to evidence for your favoured conclusion, or too little weight to evidence against it or for something else"],
                    link: []
                },
                {
                    bardModes : [constants.MODE_DIRECT_EXCHANGE], 
                    bardRoles : [constants.ROLE_FACILITATOR],             
                    statuses : [DataStatusEnum.PUBLISHED], 
                    text: ["Failing to look for other possibilities","Looking only for evidence to support your favored conclusion","Finding evidence against your favored conclusion but (partially or fully) ignoring it","When drawing conclusions, giving too much weight to evidence for your favoured conclusion, or too little weight to evidence against it or for something else"],
                    link: []
                }
            ]
        }
    ]
    },
    [constants.STEP_VARIABLES]: {
        tipContent: [{
                tipName: "VARIABLES", 
                tipObj:[ 
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND, constants.LAUNCH_MODE_SOLO_BARD],
                        bardRoles : [constants.ROLE_ANALYST, constants.ROLE_FACILITATOR, constants.ROLE_SOLO_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT, DataStatusEnum.GROUP_DRAFT], 
                        text: ["Use variables to represent the information required to solve the problem.","Review the Problem Description, Key Questions, Hypotheses and Evidence items for possible variables."],
                        link: []
                    }
                ]
            },
            {
                tipName: "tip",
                tipObj:[
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY,constants.MODE_OPEN_ROUND], 
                        bardRoles : [constants.ROLE_ANALYST, constants.ROLE_FACILITATOR],             
                        statuses : [DataStatusEnum.GROUP], 
                        text: ["This is the combined Group response (collated by the facilitator) to the Variables step and will be the basis for the next steps."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY,constants.MODE_OPEN_ROUND], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.PUBLISHED], 
                        text: ["Here are all the analysts' published Variables. Refer to the Group tab for the combined list published by the facilitator."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY,constants.MODE_OPEN_ROUND], 
                        bardRoles : [constants.ROLE_FACILITATOR],             
                        statuses : [DataStatusEnum.PUBLISHED], 
                        text: ["Here are all the analysts' published Variables."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY,constants.MODE_OPEN_ROUND], 
                        bardRoles : [constants.ROLE_FACILITATOR],             
                        statuses : [DataStatusEnum.GROUP], 
                        text: ["You can update the combined group work based on analysts' published work, at any time, as appropriate."],
                        link: []
                    }
                ]
            },
            {
                tipName: "REMEMBER : States should be ", 
                tipObj:[ 
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY,constants.MODE_OPEN_ROUND, constants.LAUNCH_MODE_SOLO_BARD],
                        bardRoles : [constants.ROLE_ANALYST,constants.ROLE_FACILITATOR, constants.ROLE_SOLO_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT, DataStatusEnum.GROUP_DRAFT], 
                        text: ["Exhaustive - cover all possible outcomes or attributes","Mutually exclusive - no overlapping of states, only one applies at any given time","Representative - capture the problem as simply as possible","Keep Variable and State names short"],
                        link: []
                    }
                ]
            },
            {
                tipName: "DISCUSSION",
                tipObj:[
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY,constants.MODE_OPEN_ROUND],
                        bardRoles : [constants.ROLE_ANALYST, constants.ROLE_FACILITATOR],
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                        text : ["Use this area to discuss the Variables step with other analysts."],
                        link:[]
                    }
                ]
            },
            {
                tipName: "REMEMBER",
                tipObj: [
                    {
                        bardModes: [constants.LAUNCH_MODE_REAL_TIME_JURY,constants.MODE_OPEN_ROUND],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST], 
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                        text : ["Make the Discussion Topics clear and specific","Responses should be relevant, courteous, targeted (addressed to @Member…) and constructive","You can include screenshots of diagrams or sketches"],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND], 
                        bardRoles : [constants.ROLE_FACILITATOR],             
                        statuses : [DataStatusEnum.PUBLISHED, DataStatusEnum.GROUP], 
                        text: ["View the work of analysts and revise the group work as needed."],
                        link: []
                    }
                ]
            }
        ]
    },
    [constants.STEP_STRUCTURE]: {
        tipContent: [
            {
                tipName: "DISCUSSION",
                tipObj:[
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY,constants.MODE_OPEN_ROUND],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST],
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                        text : ["Use this area to discuss the Structure step with other Analysts."],
                        link:[]
                    }
                ]
            },
            {
                tipName: "REMEMBER",
                tipObj: [
                    {
                        bardModes: [constants.LAUNCH_MODE_REAL_TIME_JURY],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST], 
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                        text : ["Make the discussion topics clear and specific","Responses should be relevant, courteous, targeted (addressed to @Member…) and constructive","You can include screenshots of diagrams or sketches"],
                        link: []
                    }
                ]
            }
        ]
    },
    [constants.STEP_PARAMETERS]: {
        tipContent: [
            {
              tipName: "DISCUSSION",
              tipObj:[
                {
                    bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY,constants.MODE_OPEN_ROUND],
                    bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST],
                    statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                    text : ["Use this area to discuss the Parameters step with other analysts."],
                    link:[]
                }
              ]
            },
            {
                tipName: "REMEMBER",
                tipObj: [
                    {
                        bardModes: [constants.LAUNCH_MODE_REAL_TIME_JURY,constants.MODE_OPEN_ROUND],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST], 
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                        text : ["Make the Discussion Topics clear and specific","Responses should be relevant, courteous, targeted (addressed to @Member…) and constructive","You can include screenshots of diagrams or sketches"],
                        link: []
                    }
                ]
            }
        ]
    },
    [constants.STEP_EXPLORE_MODEL]: {
        tipContent: [
            {
              tipName: "DISCUSSION",
              tipObj:[
                {
                    bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY,constants.MODE_OPEN_ROUND],
                    bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST],
                    statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                    text : ["Use this area to discuss the Explore Model step with other Analysts."],
                    link:[]
                }
              ]
            },
            {
                tipName: "REMEMBER",
                tipObj: [
                    {
                        bardModes: [constants.LAUNCH_MODE_REAL_TIME_JURY,constants.MODE_OPEN_ROUND],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST], 
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                        text : ["Make the Discussion Topics clear and specific","Responses should be relevant, courteous, targeted (addressed to @Member…) and constructive","You can include screenshots of diagrams or sketches"],
                        link: []
                    }
                ]
            }
        ]
    },
    [constants.STEP_BR_CANDIDATE_SOLUTION]: {
        tipContent: [
            {
                tipName:"tip",
                tipObj:[
                {
                    bardModes: [constants.MODE_DELPHI_EXCHANGE],
                    bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST], 
                    statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                    text : ["Discussions in Step CANDIDATE SOLUTION will become available to Analysts after the Private Round has closed, and the Facilitator has launched the Exchange Round."],
                    link: []
                },
                {
                    bardModes : [constants.MODE_DELPHI_EXCHANGE, constants.MODE_DIRECT_EXCHANGE], 
                    bardRoles : [constants.ROLE_ANALYST],             
                    statuses : [DataStatusEnum.GROUP], 
                    text: ["This is the combined Group response (collated by the facilitator) to the Step EXPLORE PROBLEM and will be the basis for the next steps. Please read through it and familiarize yourself with any new information."],
                    link: []
                }
                ]
            },
            {
                tipName: "DISCUSSION",
                tipObj:[
                  {
                      bardModes : [constants.MODE_DIRECT_EXCHANGE],
                      bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST],
                      statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                      text : ["Use this area to discuss the Candidate Solution step with other Analysts."],
                      link:[]
                  },
                  {
                    bardModes: [constants.MODE_DELPHI_EXCHANGE],
                    bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST], 
                    statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                    text : [" Discussions in Candidate Solution step will become available to Analysts after the Private Round has closed, and the Facilitator has launched the Exchange Round."],
                    link: []
                  }
                ]
            },
            {
                tipName: "REMEMBER",
                tipObj: [
                    {
                        bardModes: [],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST], 
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                        text : ["Make the Discussion Topics clear and specific","Responses should be relevant, courteous, targeted (addressed to @Member…) and constructive","You can include screenshots of diagrams or sketches"],
                        link: []
                    }
                ]
            }
        ]
    },
    [constants.STEP_NBR_CANDIDATE_SOLUTION]: {
        tipContent: [
            {
                tipName:"tip",
                tipObj:[
                {
                    bardModes: [constants.MODE_DELPHI_EXCHANGE],
                    bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST], 
                    statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                    text : ["Discussions in Step CANDIDATE SOLUTION will become available to Analysts after the Private Round has closed, and the Facilitator has launched the Exchange Round."],
                    link: []
                },
                {
                    bardModes : [constants.MODE_DELPHI_EXCHANGE, constants.MODE_DIRECT_EXCHANGE], 
                    bardRoles : [constants.ROLE_ANALYST],             
                    statuses : [DataStatusEnum.GROUP], 
                    text: ["This is the combined Group response (collated by the facilitator) to the Step EXPLORE PROBLEM and will be the basis for the next steps. Please read through it and familiarize yourself with any new information."],
                    link: []
                }
                ]
            },
            {
                tipName: "DISCUSSION",
                tipObj:[
                  {
                      bardModes : [constants.MODE_DELPHI_EXCHANGE],
                      bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST],
                      statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                      text : [" Use this area to discuss the Candidate Solution step with other Analysts."],
                      link:[]
                  },
                  {
                    bardModes: [constants.MODE_DIRECT_EXCHANGE],
                    bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST], 
                    statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                    text : [" Discussions in Candidate Solution step will become available to Analysts after the Private Round has closed, and the Facilitator has launched the Exchange Round."],
                    link: []
                  }
                ]
            },
            {
                tipName: "REMEMBER",
                tipObj: [
                    {
                        bardModes: [],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST], 
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                        text : ["Make the Discussion Topics clear and specific","Responses should be relevant, courteous, targeted (addressed to @Member…) and constructive","You can include screenshots of diagrams or sketches"],
                        link: []
                    }
                ]
            }
        ]
    },
    [constants.STEP_BR_REPORT]: {
        tipContent: [{
                tipName: "tip", 
                tipObj:[ 
                    {
                        bardModes : [constants.MODE_DELPHI_EXCHANGE, constants.MODE_DIRECT_EXCHANGE], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.GROUP],
                        text: ["This is the combined Group response (collated by the facilitator) to the Step REPORT and will be the basis for the next steps. Please read through it and familiarize yourself with any new information."],
                        link: []
                    }
                ]
            },
            {
                tipName: "REPORT",
                tipObj:[
                    {
                        bardModes : [constants.MODE_DIRECT_EXCHANGE],
                        bardRoles : [constants.ROLE_ANALYST],
                        statuses : [DataStatusEnum.GROUP],
                        text : ["The Report is the Group’s solution to the problem and must be submitted before the scheduled problem end time. Only the facilitator can edit the Report. Analysts should provide their feedback and suggestions via Discussion"],
                        link:[]
                    },
                    {
                        bardModes : [constants.MODE_DIRECT_EXCHANGE],
                        bardRoles : [constants.ROLE_OBSERVER],
                        statuses : [DataStatusEnum.GROUP],
                        text : ["The Report is the Group’s solution to the Problem and must be submitted before the scheduled Problem End time. Only the Facilitator edits the Report. Analysts provide their feedback and suggestions via the Discussion tab. Observers can provide feedback to the facilitator via a chat MESSAGE."],
                        link:[]
                    }
                ]
            },
            {
                tipName: "DISCUSSION",
                tipObj:[
                    {
                        bardModes : [constants.MODE_DIRECT_EXCHANGE],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST],
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                        text : ["Use this area to discuss the Report step with other Analysts."],
                        link:[]
                    },
                    {
                        bardModes: [constants.MODE_DELPHI_EXCHANGE],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST], 
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                        text : [" Discussions in Report step will become available to Analysts after the Private Round has closed, and the Facilitator has launched the Exchange Round."],
                        link: []
                    }
                ]
            },
            {
                tipName: "REMEMBER",
                tipObj: [
                    {
                        bardModes: [],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST], 
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                        text : ["Make the Discussion Topics clear and specific","Responses should be relevant, courteous, targeted (addressed to @Member…) and constructive","You can include screenshots of diagrams or sketches"],
                        link: []
                    }
                ]
            }
        ]
    },
    [constants.STEP_NBR_REPORT]: {
        tipContent: [{
                tipName: "tip", 
                tipObj:[ 
                    {
                        bardModes : [constants.MODE_DELPHI_EXCHANGE, constants.MODE_DIRECT_EXCHANGE], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.GROUP], 
                        text: ["This is the combined Group response (collated by the facilitator) to the Step EXPLORE PROBLEM and will be the basis for the next steps. Please read through it and familiarize yourself with any new information."],
                        link: []
                    }
                ]
            },
            {
                tipName: "REPORT",
                tipObj:[
                    {
                        bardModes : [constants.MODE_DIRECT_EXCHANGE],
                        bardRoles : [constants.ROLE_ANALYST],
                        statuses : [DataStatusEnum.GROUP],
                        text : ["The Report is the Group’s solution to the Problem and must be submitted before the scheduled Problem End time. Only the Facilitator edits the Report. Analysts should provide their feedback and suggestions via the Discussion tab."],
                        link:[]
                    },
                    {
                        bardModes : [constants.MODE_DIRECT_EXCHANGE],
                        bardRoles : [constants.ROLE_OBSERVER],
                        statuses : [DataStatusEnum.GROUP],
                        text : ["The Report is the Group’s solution to the Problem and must be submitted before the scheduled Problem End time. Only the Facilitator edits the Report. Analysts provide their feedback and suggestions via the Discussion tab. Observers can provide feedback to the Facilitator via a chat MESSAGE."],
                        link:[]
                    }
                ]
            },
            {
                tipName: "DISCUSSION",
                tipObj:[
                    {
                        bardModes : [constants.MODE_DIRECT_EXCHANGE],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST],
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                        text : ["Use this area to discuss the Report step with other Analysts."],
                        link:[]
                    },
                    {
                        bardModes: [constants.MODE_DELPHI_EXCHANGE],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST], 
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                        text : [" Discussions in Step Report will become available to Analysts after the Private Round has closed, and the Facilitator has launched the Exchange Round."],
                        link: []
                    }
                ]
            },
            {
                tipName: "REMEMBER",
                tipObj: [
                    {
                        bardModes: [],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST], 
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                        text : ["Make the Discussion Topics clear and specific","Responses should be relevant, courteous, targeted (addressed to @Member…) and constructive","You can include screenshots of diagrams or sketches"],
                        link: []
                    }
                ]
            }
        ]
    },
    [constants.STEP_COMBINED_REPORT]: {
        tipContent: [
            {
                tipName: "GROUP REPORT", 
                tipObj:[
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.GROUP], 
                        text: ["The Group’s solution to the Problem, created by the Facilitator. It must be submitted before the scheduled Problem end time."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY], 
                        bardRoles : [constants.ROLE_FACILITATOR],             
                        statuses : [DataStatusEnum.GROUP], 
                        text: ["The Group’s solution to the Problem, created by the Facilitator. It must be submitted before the scheduled Problem end time."],
                        link: []
                    }
                ]
            },
            {
                tipName: "tip",
                tipObj:[
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.GROUP], 
                        text: ["The Group Report is the Facilitator’s proposed Problem solution, based on the Analysts’ published Reports, Report ratings and any general discussion."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY], 
                        bardRoles : [constants.ROLE_FACILITATOR],             
                        statuses : [DataStatusEnum.GROUP], 
                        text: ["Encourage the Analysts to review and provide feedback via the Discussion tab.","Make sure that you have taken into account any Analyst comments before you submit the final Report."],
                        link: []
                    }
                ]
            },
            {
                tipName: "PUBLISHED REPORTS",
                tipObj:[
                   
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.PUBLISHED], 
                        text: ["Each Analyst’s most recently published Report.","Please rate each Analyst’s Report. If the group does not have a Facilitator, the Report with the highest average rating will be automatically submitted at the scheduled Problem end time."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY], 
                        bardRoles : [constants.ROLE_FACILITATOR],             
                        statuses : [DataStatusEnum.PUBLISHED],  
                        text: ["Each Analyst’s most recently published Report."],
                        link: []
                    }
                ]
            },
            {
                tipName: "REPORT",
                tipObj:[
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY], 
                        bardRoles : [constants.ROLE_FACILITATOR],             
                        statuses : [DataStatusEnum.GROUP_DRAFT], 
                        text: ["The Report is the Group’s solution to the Problem and must be submitted before the scheduled Problem end time. Only you as the Facilitator edits the Group Report. "],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT], 
                        text: ["Your suggested solution to the Problem.","The Facilitator may use all or part of your Report for the final Report submitted by the group. If your group does not have a Facilitator, then the Report with the highest average rating will be submitted in its entirety."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY],
                        bardRoles : [constants.ROLE_ANALYST],
                        statuses : [DataStatusEnum.GROUP],
                        text : ["This is the combined Group response (collated by the facilitator) to the Step REPORT and will be the basis for the next steps. Please read through it and familiarize yourself with any new information. ","The Report is the Group’s solution to the Problem and must be submitted before the scheduled Problem End time. Facilitator edits and submits the Report. In the absence of Facilitator, the highest rated Analyst report will be automatically submitted."],
                        link:[]
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_SOLO_BARD],
                        bardRoles : [constants.ROLE_SOLO_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT], 
                        text: ["Your suggested solution to the Problem.","Try to be as complete as you can. Your final answer should mention all the Hypotheses raised by the Problem and the evidence for and against each Hypothesis.","You may wish to copy text from the ‘Summary Explanation’ and ‘Detailed Explanation’ in Step 5 EXPLORE NETWORK in your report, or simply use it for your own reference while writing your responses."],
                        link: []
                    }
                ]
            },
            {
                tipName: "DISCUSSION",
                tipObj:[
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY],
                        bardRoles : [constants.ROLE_ANALYST, constants.ROLE_FACILITATOR],
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                        text : ["Use this area to discuss the Report step with other analysts."],
                        link:[]
                    }
                ]
            },
            {
                tipName: "REMEMBER",
                tipObj: [
                    {
                        bardModes: [],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST], 
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                        text : ["Make the Discussion Topics clear and specific","Responses should be relevant, courteous, targeted (addressed to @Member…) and constructive","You can include screenshots of diagrams or sketches"],
                        link: []
                    }
                ]
            }
        ]
    },
    [constants.STEP_STRATHCLYDE_EXPLORE_PROBLEM]: {
        tipContent: [{
                tipName: "EXPLORE PROBLEM", 
                tipObj:[ 
                    {
                        bardModes : [constants.LAUNCH_MODE_STRATHCLYDE_EXPERIMENT], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT], 
                        text: ["First, familiarize yourself with the problem. Read the information presented carefully, and identify the key pieces of information provided."],
                        link: []
                    }
                ]
            },
            {
                tipName: "REMEMBER",
                tipObj: [
                    {
                        bardModes : [constants.LAUNCH_MODE_STRATHCLYDE_EXPERIMENT], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT], 
                        text: ["You do not need to identify hypotheses or evidence.","In this experiment, after reading the problem statement, just move to the VARIABLES tab to see the variables already provided for you."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_STRATHCLYDE_EXPERIMENT], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.PUBLISHED], 
                        text: ["You do not need to review other analysts' hypotheses or evidence. In this experiment, other analysts did not identify any hypotheses or evidence, and were provided with exactly the same variables as you."],
                        link: []
                    }
                ]
            }
        ]
    },
    [constants.STEP_SINGLE_VARIABLE]: {
        tipContent: [
            {
                tipName: "REMEMBER", 
                tipObj:[ 
                    {
                        bardModes : [constants.LAUNCH_MODE_STRATHCLYDE_EXPERIMENT], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT], 
                        text: ["You cannot change any of these variables.","In this experiment, you and your teammates have been provided with all the variables you need, and also some that you should not use.","After reading the list of variables, just move to the STRUCTURE tab and put arrows between the variables you want to use."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_STRATHCLYDE_EXPERIMENT], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.PUBLISHED], 
                        text: ["All these variables are the same as yours. In this experiment, you and your teammates have been provided with exactly the same variables, and cannot change any of them."],
                        link: []
                    }
                ]
            }
        ]
    }
    
};

const helpTipsOnScreenRight = {
    [constants.HELP_STEP_PROBLEM_DESC]: {
        tipContent: [{
                tipName: "tip", 
                tipObj:[ 
                    {
                        bardModes : [], 
                        bardRoles : [],             
                        statuses : [], 
                        text: ["Remember that you should only use the information provided in this description to solve this problem. Personal knowledge or information from internet searches won’t help solve this problem.", "Make sure you read through all components of the problem descriptions. The key questions, evidence, and resources are all important sources of information."],
                        link: []
                    }
                ]
            }, 
            {
                tipName: "KEY QUESTIONS", 
                tipObj:[ 
                    {
                        bardModes : [], 
                        bardRoles : [],             
                        statuses : [], 
                        text: ["All key questions must be answered in the Group’s final submitted intelligence report for this problem."],
                        link: []
                    }
                ]
            }, 
            {
                tipName: "EVIDENCE", 
                tipObj:[ 
                    {
                        bardModes : [], 
                        bardRoles : [],             
                        statuses : [], 
                        text: ["Evidence is specific information provided for the problem. Some evidence is true and you can take as given. Other evidence may be uncertain. Part of the group reasoning task is to work out which evidence is relevant and which is not."],
                        link: []
                    }
                ]
            }, 
            {
                tipName: "RESOURCES", 
                tipObj:[ 
                    {
                        bardModes : [], 
                        bardRoles : [],             
                        statuses : [], 
                        text: ["These additional resources provide background information which can help to understand and solve the problem."],
                        link: []
                    }
                ]
            }
        ]
    },
    [constants.STEP_EXPLORE_PROBLEM]: {
        tipContent: [
            {
                tipName: "tip",
                tipObj:[ 
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_PRIVATE_ROUND, constants.MODE_DIRECT_EXCHANGE, constants.MODE_DELPHI_EXCHANGE], 
                        bardRoles : [constants.ROLE_FACILITATOR],             
                        statuses : [DataStatusEnum.GROUP_DRAFT], 
                        text: ["Open either the Published tab or the Group Draft tab in another browser window when collating analysts' work using"],
                        icons: [{icon: 'open_in_new'}]
                    },
                    {
                        bardModes: [],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST], 
                        statuses : [DataStatusEnum.MY_DRAFT], 
                        text : ["Click on the VIEW PROBLEM link to open the Problem description in a different browser tab."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_DIRECT_EXCHANGE, constants.MODE_DELPHI_EXCHANGE], 
                        bardRoles : [constants.ROLE_OBSERVER],             
                        statuses : [DataStatusEnum.GROUP], 
                        text: ["Provide your feedback and suggestions via a chat MESSAGE to the Facilitator."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_DIRECT_EXCHANGE, constants.MODE_DELPHI_EXCHANGE], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.GROUP], 
                        text: ["Provide your feedback and suggestions via the Discussion tab."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_DIRECT_EXCHANGE,constants.MODE_PRIVATE_ROUND,constants.MODE_DELPHI_EXCHANGE], 
                        bardRoles : [constants.ROLE_FACILITATOR],             
                        statuses : [DataStatusEnum.PUBLISHED], 
                        text: ["Click on the “Copy to Group Draft” icon above to copy this analyst’s published responses into the Group Draft. This will insert the whole set above any already there."],
                        link: []
                    },
                    {
                        bardModes : [], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.PUBLISHED], 
                        text: ["Provide your feedback and suggestions in the Discussion tab."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_PRIVATE_ROUND, constants.MODE_DIRECT_EXCHANGE, constants.MODE_DELPHI_EXCHANGE], 
                        bardRoles : [constants.ROLE_FACILITATOR],             
                        statuses : [DataStatusEnum.GROUP, constants.HELP_SUBSTEP_DISCUSSION], 
                        text: ["Start a new discussion topic if you want to draw the team’s attention to an emerging topic.","Once you’ve published the Group Draft, create a discussion topic to discuss the group solution."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_DIRECT_EXCHANGE], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION], 
                        text: ["Don’t understand something about the BARD screen for this step?","For more information, refer to help content below."],
                        link: [{linkName: 'Guidelines: Explore Network', linkText: `${helpContentUrlPrefix}/bard_step1_explore_problem.pdf`}]
                    }
                ],
                
            },
            {
                tipName: "KEY QUESTIONS",
                tipObj: [
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_PRIVATE_ROUND, constants.MODE_DIRECT_EXCHANGE, constants.MODE_DELPHI_EXCHANGE], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT], 
                        text: ["You can record your initial thoughts on the question in short bullet points for sharing with the group."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_PRIVATE_ROUND, constants.MODE_DIRECT_EXCHANGE, constants.MODE_DELPHI_EXCHANGE], 
                        bardRoles : [constants.ROLE_FACILITATOR],             
                        statuses : [DataStatusEnum.GROUP_DRAFT], 
                        text: ["Click on the “Copy to Group Draft” icon in Published tab, to copy an Analyst’s published work into the Group Draft.","This inserts each analyst’s comments above any already there, separated by a horizontal line."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_SOLO_BARD],
                        bardRoles : [constants.ROLE_SOLO_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT], 
                        text: ["You can record your initial thoughts on the question in short bullet points."],
                        link: []
                    }
                ]
            },
            {
                tipName: "HYPOTHESES and EVIDENCE items",
                tipObj: [
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_PRIVATE_ROUND, constants.MODE_DIRECT_EXCHANGE, constants.MODE_DELPHI_EXCHANGE, constants.LAUNCH_MODE_SOLO_BARD],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_SOLO_ANALYST],             
                        statuses : [DataStatusEnum.GROUP_DRAFT, DataStatusEnum.MY_DRAFT], 
                        text: ["What are the elements of the problem which will need to be considered in the solution?","List the items from most to least important, provide your reasoning under Rationale.","Only identify key items from within the problem; do not add items you think are missing"],
                        link: []
                    }
                ]
            }
        ]
    },
    [constants.STEP_VARIABLES]: {
        tipContent: [
                {
                    tipName: "TARGET VARIABLES",
                    tipObj:[ 
                        {
                            bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND, constants.LAUNCH_MODE_SOLO_BARD],
                            bardRoles : [constants.ROLE_ANALYST, constants.ROLE_FACILITATOR, constants.ROLE_SOLO_ANALYST],             
                            statuses : [DataStatusEnum.MY_DRAFT,DataStatusEnum.GROUP_DRAFT], 
                            text: ["Target variables represent the information to be determined, typically the Key Questions from the problem."],
                            link: []
                        }
                    ]
                },
                {
                    tipName: "OTHER VARIABLES",
                    tipObj:[ 
                        {
                            bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND, constants.LAUNCH_MODE_SOLO_BARD],
                            bardRoles : [constants.ROLE_ANALYST, constants.ROLE_FACILITATOR, constants.ROLE_SOLO_ANALYST],             
                            statuses : [DataStatusEnum.MY_DRAFT,DataStatusEnum.GROUP_DRAFT], 
                            text: ["Other variables represent evidence and observation as well as interim conclusions leading to the target variables."],
                            link: []
                        }
                    ]
                },
                {
                    tipName: "tip", 
                    tipObj:[ 
                        {
                            bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND, constants.LAUNCH_MODE_SOLO_BARD],
                            bardRoles : [constants.ROLE_ANALYST, constants.ROLE_FACILITATOR, constants.ROLE_SOLO_ANALYST],             
                            statuses : [DataStatusEnum.MY_DRAFT,DataStatusEnum.GROUP_DRAFT], 
                            text: ["You can leave variables without specifying Type or State right up until your reach the Parameters step."],
                            link: []
                        },
                        {
                            bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND], 
                            bardRoles : [constants.ROLE_FACILITATOR],             
                            statuses : [constants.HELP_SUBSTEP_DISCUSSION, DataStatusEnum.GROUP], 
                            text: ["Start a new discussion topic if you want to draw the team’s attention to an emerging topic.","Once you’ve published the Group Draft, create a discussion topic to discuss the group solution."],
                            link: []
                        },
                        {
                            bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND], 
                            bardRoles : [constants.ROLE_ANALYST],             
                            statuses : [constants.HELP_SUBSTEP_DISCUSSION], 
                            text: ["Don’t understand something about the BARD screen for this step?","For more information, refer to help content below."],
                            link: [{linkName: 'Guidelines: Variables', linkText: `${helpContentUrlPrefix}/bard_more_on_variables.pdf`}]
                        },
                        {
                            bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND], 
                            bardRoles : [constants.ROLE_ANALYST],             
                            statuses : [DataStatusEnum.PUBLISHED, DataStatusEnum.GROUP], 
                            text: ["Provide your feedback and suggestions via the Discussion tab."],
                            link: []
                        },
                        {
                            bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND], 
                            bardRoles : [constants.ROLE_FACILITATOR],             
                            statuses : [DataStatusEnum.PUBLISHED], 
                            text: ["Click on the “Copy to Group Draft” icon above to copy this analyst’s published responses into the Group Draft. This will insert the whole set above any already there."],
                            link: []
                        }
                    ]
                }
            ]
    },
    [constants.STEP_STRUCTURE]: {
        tipContent: [
            {
                tipName: "STRUCTURE",
                tipObj:[ 
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND, constants.LAUNCH_MODE_SOLO_BARD],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT, DataStatusEnum.GROUP_DRAFT], 
                        text: ["Here you connect your variables to show the relationships between them."]
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND, constants.LAUNCH_MODE_SOLO_BARD],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT, DataStatusEnum.GROUP_DRAFT], 
                        text: ["Target Variables are colored"],
                        icons: [{icon: "brightness_1", cssClass: "targetVarIcon"}]
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND, constants.LAUNCH_MODE_SOLO_BARD],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT, DataStatusEnum.GROUP_DRAFT],
                        text: ["Other Variables are colored"],
                        icons: [{icon: "brightness_1", cssClass: "otherVarIcon"}]
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND, constants.LAUNCH_MODE_SOLO_BARD],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],
                        statuses : [DataStatusEnum.MY_DRAFT, DataStatusEnum.GROUP_DRAFT],
                        text: ["If you are building a Decision Network, there may be additional nodes:"],
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND, constants.LAUNCH_MODE_SOLO_BARD],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],
                        statuses : [DataStatusEnum.MY_DRAFT, DataStatusEnum.GROUP_DRAFT],
                        text: ["Decision Variables are colored"],
                        icons: [{icon: "brightness_1", cssClass: "decisionVarIcon"}]
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND, constants.LAUNCH_MODE_SOLO_BARD],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],
                        statuses : [DataStatusEnum.MY_DRAFT, DataStatusEnum.GROUP_DRAFT],
                        text: ["Utility Nodes are colored"],
                        icons: [{icon: "brightness_1", cssClass: "utilityVarIcon"}]
                    }
                ]
            },
            {
                tipName: "tip", 
                tipObj:[
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND, constants.LAUNCH_MODE_SOLO_BARD],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT, DataStatusEnum.GROUP_DRAFT], 
                        text: ["Draw arrows between the variables (starting from your target variables) going from cause to effect."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND, constants.LAUNCH_MODE_SOLO_BARD],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT, DataStatusEnum.GROUP_DRAFT], 
                        text: ["To delete an arrow, select it  and then click on the"],
                        icons: [{icon: "cancel", cssClass: "md-red"}]
                    }
                ]},
                {
                    tipName: "tip", 
                    tipObj:[
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND, constants.LAUNCH_MODE_SOLO_BARD],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT, DataStatusEnum.GROUP_DRAFT], 
                        text: ["You can zoom in and out using the scroll button on your mouse.","Try to avoid having more than 2 or 3 arrows coming into a variable, as this results in a big table of parameters to fill in. Consider introducing one or more intermediate variables."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND], 
                        bardRoles : [constants.ROLE_FACILITATOR],             
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION,  DataStatusEnum.GROUP], 
                        text: ["Start a new discussion topic if you want to draw the team’s attention to an emerging topic.","Once you’ve published the Group Draft, create a discussion topic to discuss the group solution."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION], 
                        text: ["Don’t understand something about the BARD screen for this step?","For more information, refer to help content below."],
                        link: [{linkName: 'Guidelines: Structure', linkText: `${helpContentUrlPrefix}/bard_more_on_structure.pdf`}]
                    },
                        {
                            bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND], 
                            bardRoles : [constants.ROLE_ANALYST],             
                            statuses : [DataStatusEnum.PUBLISHED, DataStatusEnum.GROUP], 
                            text: ["Provide your feedback and suggestions via the Discussion tab."],
                            link: []
                        },
                        {
                            bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND], 
                            bardRoles : [constants.ROLE_FACILITATOR],             
                            statuses : [DataStatusEnum.PUBLISHED], 
                            text: ["Click on the “Copy to Group Draft” icon above to copy this Analyst’s published responses into the Group Draft. This will insert the whole set above any already there."],
                            link: []
                        }
                ]
            },
            {
                tipName: "REMEMBER",
                tipObj:[ 
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND, constants.LAUNCH_MODE_SOLO_BARD],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],             
                        statuses : [DataStatusEnum.GROUP_DRAFT, DataStatusEnum.MY_DRAFT], 
                        text: ["Only add arrows where there is a direct cause or influence"],
                        link: []
                    }
                ]
            }
        ]
    },
    [constants.STEP_PARAMETERS]: {
        tipContent:[
            {
                tipName: "tip", 
                tipObj:[ 
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND], 
                        bardRoles : [constants.ROLE_FACILITATOR],             
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION], 
                        text: ["Start a new discussion topic if you want to draw the team’s attention to an emerging topic.","Once you’ve published the Group Draft, create a discussion topic to discuss the group solution."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION], 
                        text: ["Don’t understand something about the BARD screen for this step?","For more information, refer to help content below."],
                        link: [{linkName: 'Guidelines: Parameters', linkText: `${helpContentUrlPrefix}/bard_more_on_parameters.pdf`}]
                    }
                ]
            }
        ]
    },
    [constants.STEP_EXPLORE_MODEL]: {
        tipContent:[
            {
                tipName: "tip", 
                tipObj:[ 
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND], 
                        bardRoles : [constants.ROLE_FACILITATOR],             
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION], 
                        text: ["Start a new discussion topic if you want to draw the team’s attention to an emerging topic.","Once you’ve published the Group Draft, create a discussion topic to discuss the group solution."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION], 
                        text: ["Don’t understand something about the BARD screen for this step?","For more information, refer to help content below."],
                        link: [{ linkName: 'More On: Reasoning [PDF]', linkText: `${helpContentUrlPrefix}/bard_more_on_reasoning.pdf` },
                        { linkName: 'More On: Explanation [PDF]', linkText: `${helpContentUrlPrefix}/bard_more_on_explanation.pdf` }]
                    }
                ]
            }
        ]
    },
    [constants.STEP_BR_CANDIDATE_SOLUTION]: {
        tipContent: [
            {
                tipName: "REPORT",
                tipObj:[ 
                    {
                        bardModes : [constants.MODE_DIRECT_EXCHANGE, constants.MODE_PRIVATE_ROUND], 
                        bardRoles : [constants.ROLE_FACILITATOR],             
                        statuses : [DataStatusEnum.GROUP_DRAFT], 
                        text: ["1. Please enter your proposed answer to the question in the space provided. A template with several sections is included for guidance with your writing; if there are multiple questions, you may duplicate the necessary sections of the template for each additional question. Do not duplicate the “Problem” section since there is only one problem. If for a given problem or question some section does not apply to your solution, then delete it.","2. Fill out all the sections that do help you write your solution.Try to be as complete as you can; your final answer should mention all the hypotheses raised by the problem and all pieces of evidence for and against each hypothesis."],
                        link: []
                    },
                    {
                        bardModes : [constants.MODE_PRIVATE_ROUND, constants.MODE_DELPHI_EXCHANGE], 
                        bardRoles : [constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT], 
                        text: [""],
                        link: []
                    }
                ]
            },
            {
                tipName: "tip", 
                tipObj:[ 
                    {
                        bardModes : [], 
                        bardRoles : [constants.ROLE_FACILITATOR],             
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION], 
                        text: ["Start a new Discussion topic if you see there is a lack of consensus between the Analysts, regarding some part of this step.","If Discussions get off track from the original Topic, consider starting a new Topic.","Once you’ve Published the Group Draft, use a Discussion Topic to start Analysts discussing the Group solution."],
                        link: []
                    },
                    {
                        bardModes : [constants.MODE_DIRECT_EXCHANGE, constants.MODE_PRIVATE_ROUND], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION], 
                        text: ["Don’t understand something about the BARD screen for this step?","For more information, refer to help content below."],
                        link: [{ linkName: 'More On: Reasoning [PDF]', linkText: `${helpContentUrlPrefix}/bard_step6_candidate_solution-bn.pdf` }]
                    },
                    {
                        bardModes : [constants.MODE_DIRECT_EXCHANGE, constants.MODE_PRIVATE_ROUND], 
                        bardRoles : [constants.ROLE_FACILITATOR],             
                        statuses : [DataStatusEnum.GROUP_DRAFT], 
                        text: ["You may wish to use the NLG tool’s text in one or more of the sections, or simply for your own reference while writing your answers below. Further tips are provided on screen to help you write a fully drafted, comprehensive solution. Links are also provided to your training, for reference. Parenthetical guidance about length are suggestions only."],
                        link: []
                    }
                ]
            }
        ]
    },
    [constants.STEP_NBR_CANDIDATE_SOLUTION]: {
        tipContent: [
            {
                tipName: "REPORT",
                tipObj:[ 
                    {
                        bardModes : [constants.MODE_DELPHI_EXCHANGE], 
                        bardRoles : [constants.ROLE_FACILITATOR],             
                        statuses : [DataStatusEnum.GROUP_DRAFT], 
                        text: [""],
                        link: []
                    },
                    {
                        bardModes : [constants.MODE_DELPHI_EXCHANGE], 
                        bardRoles : [constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT], 
                        text: [""],
                        link: []
                    }
                ]
            },
            {
                tipName: "tip", 
                tipObj:[ 
                    {
                        bardModes : [constants.MODE_DELPHI_EXCHANGE], 
                        bardRoles : [constants.ROLE_FACILITATOR],             
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION],
                        text: ["Start a new Discussion topic if you see there is a lack of consensus between the Analysts, regarding some part of this step.","If Discussions get off track from the original Topic, consider starting a new Topic.","Once you’ve Published the Group Draft, use a Discussion Topic to start Analysts discussing the Group solution."],
                        link: []
                    },
                    {
                        bardModes : [constants.MODE_DIRECT_EXCHANGE, constants.MODE_PRIVATE_ROUND], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION], 
                        text: ["Don’t understand something about the BARD screen for this step?","For more information, refer to help content below."],
                        link: [{ linkName: 'Step 6: CANDIDATE SOLUTION - NBN [PDF]', linkText: `${helpContentUrlPrefix}/bard_step6_candidate_solution-non-bn.pdf` }]
                    }
                ]
            }
        ]
    },
    [constants.STEP_BR_REPORT]: {
        tipContent: [
            {
                tipName: "tip", 
                tipObj:[ 
                    {
                        bardModes : [constants.MODE_DELPHI_EXCHANGE], 
                        bardRoles : [constants.ROLE_FACILITATOR],             
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION], 
                        text: ["Start a new Discussion topic if you see there is a lack of consensus between the Analysts, regarding some part of this step.","If Discussions get off track from the original Topic, consider starting a new Topic.","Once you’ve Published the Group Draft, use a Discussion Topic to start Analysts discussing the Group solution."],
                        link: []
                    },
                    {
                        bardModes : [constants.MODE_DIRECT_EXCHANGE, constants.MODE_PRIVATE_ROUND], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION], 
                        text: ["Don’t understand something about the BARD screen for this step?","For more information, refer to help content below."],
                        link: [{ linkName: 'Step 7: REPORT [PDF]', linkText: `${helpContentUrlPrefix}/bard_step7_report.pdf` }]
                    }
                ]
            }
        ]
    },
    [constants.STEP_NBR_REPORT]: {
        tipContent: [
            {
                tipName: "tip", 
                tipObj:[ 
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_DELPHI_EXCHANGE], 
                        bardRoles : [constants.ROLE_FACILITATOR],             
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION], 
                        text: ["Start a new Discussion topic if you see there is a lack of consensus between the Analysts, regarding some part of this step.","If Discussions get off track from the original Topic, consider starting a new Topic.","Once you’ve Published the Group Draft, use a Discussion Topic to start Analysts discussing the Group solution."],
                        link: []
                    },
                    {
                        bardModes : [constants.MODE_DIRECT_EXCHANGE, constants.MODE_PRIVATE_ROUND], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION], 
                        text: ["Don’t understand something about the BARD screen for this step?","For more information, refer to help content below."],
                        link: [{ linkName: 'Step 7: REPORT [PDF]', linkText: `${helpContentUrlPrefix}/bard_step7_report.pdf` }]
                    }
                ]
            }
        ]
    },
    [constants.STEP_COMBINED_REPORT]: {
        tipContent: [
            {
                tipName: "tip", 
                tipObj:[ 
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT], 
                        text: ["If you used a Bayesian Network to solve the problem, select ‘Report based on a Bayesian Network’.","Try to be as complete as you can. Your final answer should mention all the Hypotheses raised by the Problem and the evidence for and against each Hypothesis","You may wish to copy text from the ‘Summary Explanation’ and ‘Detailed Explanation’ in Step 5 EXPLORE NETWORK in your report, or simply use it for your own reference while writing your responses."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY], 
                        bardRoles : [constants.ROLE_FACILITATOR],             
                        statuses : [DataStatusEnum.GROUP_DRAFT], 
                        text: ["If the group solution is based on a Bayesian Network, select ‘Report based on a Bayesian Network’.","Try to be as complete as you can. Your final answer should mention all the Hypotheses raised by the Problem and the evidence for and against each Hypothesis.","Click PUBLISH to share the Group Draft with the Analysts.","When you are ready, Submit the Group Report from the Group tab.","You may wish to copy text from the ‘Summary Explanation’ and ‘Detailed Explanation’ in Step 5 EXPLORE NETWORK in your report, or simply use it for your own reference while writing your responses."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY], 
                        bardRoles : [constants.ROLE_FACILITATOR],             
                        statuses : [DataStatusEnum.PUBLISHED], 
                        text: ["Click ‘ADD TO GROUP DRAFT’ to insert this Analyst’s responses into the Group Draft. Each of the Analyst’s responses will be inserted before any existing contents of the Group Draft."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.PUBLISHED], 
                        text: ["Provide your feedback and suggestions in the Discussion tab."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.PUBLISHED], 
                        text: ["Review other published Reports for possible improvements to your own Report. Provide your feedback and suggestions in the Discussion tab. "],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY], 
                        bardRoles : [constants.ROLE_FACILITATOR],             
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION, DataStatusEnum.GROUP], 
                        text: ["Start a new discussion topic if you want to draw the team’s attention to an emerging topic.","Once you’ve published the Group Draft, create a discussion topic to discuss the group solution."],
                        link: []
                    },
                    {
                        bardModes : [constants.MODE_DIRECT_EXCHANGE], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [constants.HELP_SUBSTEP_DISCUSSION], 
                        text: ["Don’t understand something about the BARD screen for this step?","For more information, refer to help content below."],
                        link: [{ linkName: 'Step 6: REPORT [PDF]', linkText: `${helpContentUrlPrefix}/bard_step6_report.pdf` }]
                    }
                ]
            }
        ]
    }
    ,
    [constants.STEP_STRATHCLYDE_EXPLORE_PROBLEM]: {
        tipContent: [
            {
                tipName: "tip", 
                tipObj:[ 
                    {
                        bardModes: [],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST], 
                        statuses : [DataStatusEnum.MY_DRAFT], 
                        text : ["Click on the VIEW PROBLEM link to open the Problem description in a different browser tab."],
                        link: []
                    }
                ]
            }
            
    ]
    },
    [constants.STEP_SINGLE_VARIABLE]: {
        tipContent: [
            {
                tipName: "TARGET VARIABLES",
                tipObj:[ 
                    {
                        bardModes : [constants.LAUNCH_MODE_STRATHCLYDE_EXPERIMENT],
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT], 
                        text: ["Target variables represent the information to be determined, typically the Key Questions from the problem."],
                        link: []
                    }
                ]
            },
            {
                tipName: "OTHER VARIABLES",
                tipObj:[ 
                    {
                        bardModes : [constants.LAUNCH_MODE_STRATHCLYDE_EXPERIMENT],
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT], 
                        text: ["Other variables represent evidence and observation as well as interim conclusions leading to the target variables."],
                        link: []
                    }
                ]
            }
        ]
    },
    [constants.STEP_SINGLE_STRUCTURE]: {
        tipContent: [
            {
                tipName: "STRUCTURE",
                tipObj:[ 
                    {
                        bardModes : [constants.LAUNCH_MODE_STRATHCLYDE_EXPERIMENT],
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT], 
                        text: ["Here you connect your variables to show the relationships between them."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_STRATHCLYDE_EXPERIMENT],
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT], 
                        text: ["Target Variables are colored"],
                        icons: [{icon: "brightness_1", cssClass: "targetVarIcon"}]
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_STRATHCLYDE_EXPERIMENT],
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT], 
                        text: ["Other Variables are colored"],
                        icons: [{icon: "brightness_1", cssClass: "otherVarIcon"}]
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND, constants.LAUNCH_MODE_SOLO_BARD],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],
                        statuses : [DataStatusEnum.MY_DRAFT, DataStatusEnum.GROUP_DRAFT],
                        text: ["If you are building a Decision Network, there may be additional nodes:"],
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND, constants.LAUNCH_MODE_SOLO_BARD],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],
                        statuses : [DataStatusEnum.MY_DRAFT, DataStatusEnum.GROUP_DRAFT],
                        text: ["Decision Variables are colored"],
                        icons: [{icon: "brightness_1", cssClass: "decisionVarIcon"}]
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.MODE_OPEN_ROUND, constants.LAUNCH_MODE_SOLO_BARD],
                        bardRoles : [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],
                        statuses : [DataStatusEnum.MY_DRAFT, DataStatusEnum.GROUP_DRAFT],
                        text: ["Utility Nodes are colored"],
                        icons: [{icon: "brightness_1", cssClass: "utilityVarIcon"}]
                    }
                ]
            },
            {
                tipName: "tip", 
                tipObj:[
                    {
                        bardModes : [constants.LAUNCH_MODE_STRATHCLYDE_EXPERIMENT],
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT], 
                        text: ["Draw arrows between the variables (starting from your target variables) going from cause to effect."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_STRATHCLYDE_EXPERIMENT],
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT], 
                        text: ["To delete an arrow, select it  and then click on the"],
                        icons: [{icon: "cancel", cssClass: "md-red"}]
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_STRATHCLYDE_EXPERIMENT],
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT], 
                        text: ["You can zoom in and out using the scroll button on your mouse.","Try to avoid having more than 2 or 3 arrows coming into a variable, as this results in a big table of parameters to fill in."],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_STRATHCLYDE_EXPERIMENT],
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.PUBLISHED], 
                        text: ["Use the drop down to select and view other analysts' published structures.","Use the slider beside the drop down to rate them."],
                        link: []
                    }
                ]
            },
            {
                tipName: "REMEMBER", 
                tipObj:[ 
                    {
                        bardModes : [constants.LAUNCH_MODE_STRATHCLYDE_EXPERIMENT], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.MY_DRAFT], 
                        text: ["Only add arrows where there is a direct cause or influence.","Only use the variables you need, and just move the others aside.","You must publish something before your time is up!"],
                        link: []
                    },
                    {
                        bardModes : [constants.LAUNCH_MODE_STRATHCLYDE_EXPERIMENT], 
                        bardRoles : [constants.ROLE_ANALYST],             
                        statuses : [DataStatusEnum.PUBLISHED], 
                        text: ["Rate all the other analysts' BNs, and try to improve your own.","You can check the problem statement if this helps.","Only use the variables and causal arrows you need.","You must republish any changes before your time is up!"],
                        link: []
                    }
                ]
            }
        ]
    }
};

export default class onScreenTipsData {
    
    static getInstance() {
        return onScreenTipsData.instance || (onScreenTipsData.instance = new onScreenTipsData());
    }

    getOnscreenTips(step, side){
        if(side === constants.TIP_SCREEN_LEFT){
            return helpTipsOnScreenLeft[step];
        }else if(side  === constants.TIP_SCREEN_RIGHT){
            return helpTipsOnScreenRight[step];
        }
    }

}