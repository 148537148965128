const SET_DEFAULT_PROBLEM_STEP = 'set-default-problem-step';

export default function defaultProblemStepIdReducer(state = null, action) {
    switch (action.type) {
        case SET_DEFAULT_PROBLEM_STEP:
            return action.problemStepId;
        default:
            return state;
    }
}

export function setDefaultProblemStepIdAction(problemStepId = null) {
    return {type: SET_DEFAULT_PROBLEM_STEP, problemStepId, isClientOnly: true};
}

export function getDefaultProblemStepIdFromStore(store) {
    return store.clientState.defaultProblemStepId;
}