const UPDATE_CRON_LIST = 'updateCronList';
const UPDATE_CRON = 'updateCron';

function allCronsReducer(state = {}, action) {
    switch (action.type) {
        case UPDATE_CRON_LIST:
            return action.crons.reduce((accum, cron) => {
                accum[cron.id] = cron;

                return accum;
            }, {});
        case UPDATE_CRON:
            return { ...state, [action.cron.id]: {...state[action.cron.id], ...action.cron} };
        default:
            return state;
    }
}

export function updateCronsListAction(crons) {
    return { type: UPDATE_CRON_LIST, crons };
}

export default allCronsReducer;

export function updateCronAction(cron) {
    return { type: UPDATE_CRON, cron };
}

export function getAllCronsFromStore(store) {
    return store.clientState.allCrons;
}
