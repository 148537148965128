import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import moment from 'moment';
import {FontIcon} from 'react-md';

import * as constants from '../../common/util/constants';
import { getProblemDBFromStore } from '../../common/reducers/allProblemsReducer';
import {getTeamFromStore} from "../../common/reducers/allTeamsReducerGetters";

class ProblemCalendar extends Component {
    static propTypes = {
        side: PropTypes.string.isRequired,
        problemStepId: PropTypes.number.isRequired
    };

    render() {
        let result = null;
        const problem = this.props.problem;
        if (!problem.isTraining
            && (this.props.team.problemStep.state === constants.STATE_READY
                || this.props.team.problemStep.state === constants.STATE_ACTIVE)) {
            result =
            <div className="calendarDisplayDiv">
                <div className="calendarDisplayIconDiv">
                    <FontIcon className="md-light md-30">date_range</FontIcon>
                    </div>
                <div className="inlineBlockDisplay">
                    {this.props.side === 'left' ? 
                        <div className="miniTitle problemDateHeaderStyle">
                            {(this.props.team.problemStep.state === constants.STATE_CLOSED || this.props.team.problemStep.state === constants.STATE_ACTIVE) ? 'END' : 'START'}
                        </div>
                        : null
                    }
                    <div className="textFont problemDateFontStyle">
                    {this.props.side === 'left' ? 
                        (this.props.team.problemStep.state === constants.STATE_CLOSED || this.props.team.problemStep.state === constants.STATE_ACTIVE ? 
                            <div>{moment(problem.expectedCompletion).format("DD MMM YYYY hh:mm A")}</div>
                            :
                            <div>{moment(problem.expectedStart).format("DD MMM YYYY hh:mm A")}</div>
                        )
                        :
                        <div>
                            <div>{moment(problem.expectedStart).format("DD MMM YYYY hh:mm A")} - </div>
                            <div>{moment(problem.expectedCompletion).format("DD MMM YYYY hh:mm A")} </div>
                        </div>
                    }
                    </div>
                </div>
            </div>;
        }

        return result;
    }
}

function mapStoreToProps(store, myProps) {
    const problemStepId = myProps.problemStepId;
    const team = getTeamFromStore(store, problemStepId);
    const problem = getProblemDBFromStore(store, team.problemStep.problemId);

    return {
        team,
        problem
    };
}

export default connect(mapStoreToProps)(ProblemCalendar);