import React, {Component} from 'react';
import {AnyAction, Dispatch} from 'redux';
import * as PropTypes from 'prop-types';
import {clamp, indexOf} from 'lodash';
import Select from 'react-select';
import {ExpansionList, ExpansionPanel} from 'react-md';
import classNames from 'classnames';

import '../scss/exploreModel.scss';
import '../scss/react-md.scss';

import {
    addScenarioAction,
    addScenarioEvidenceNodeAction,
    BASE_SCENARIO,
    changeScenarioEvidenceNodeAction,
    deleteScenarioAction,
    deleteScenarioEvidenceNodeAction,
    ExploreModelState,
    reorderScenarioEvidenceNodeAction,
    SingleScenarioState,
    updateScenarioAction,
    updateScenarioEvidenceNodeAction
} from '../../common/reducers/exploreModelReducer';
import ReorderableAddableContainer from './ReorderableAddableContainer';
import ReorderableDeleteableElement from './ReorderableDeleteableElement';
import PlainTextField from '../presentation/PlainTextField';
import SingleInput from '../presentation/SingleInput';
import {EPSILON} from '../../common/util/constants';
import {roundPercentToEpsilon} from '../util/maths';
import {safeOrderObject} from '../../common/util/safeOrder';
import DataStatusEnum from '../../common/DataStatusEnum';
import {getKeyVariableType, VariableType} from '../../common/reducers/keyVariablesReducer';
import IconLabelButton from '../presentation/IconLabelButton';
import commonSelectStyles from '../util/commonSelectStyles';

interface ExploreModelScenariosPanelProps {
    readOnly: boolean;
    problemStepId: number;
    disablePersonalScenarios?: boolean;
    userId: number;
    loggedInUserId: number;
    status: DataStatusEnum;
    dispatch: Dispatch<AnyAction>;
    exploreModel: ExploreModelState;
    onScenarioChange: (scenarioId: string) => void;
    currentScenarioId: string;
}

interface ExploreModelScenariosPanelState {
    expanded: {[scenarioId: string]: boolean};
    editingScenario?: string;
}

class ExploreModelScenariosPanel extends Component<ExploreModelScenariosPanelProps, ExploreModelScenariosPanelState> {

    static propTypes = {
        readOnly: PropTypes.bool.isRequired,
        problemStepId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        disablePersonalScenarios: PropTypes.bool,
        userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        loggedInUserId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        status: PropTypes.number.isRequired,
        dispatch: PropTypes.func.isRequired,
        exploreModel: PropTypes.object.isRequired,
        onScenarioChange: PropTypes.func.isRequired,
        currentScenarioId: PropTypes.string.isRequired
    };

    static defaultProps = {
        disablePersonalScenarios: false
    };

    static BASE_SCENARIO_DATA = {
        prefix: '',
        title: 'Base Scenario',
        description: 'The base scenario contains no evidence, and shows the prior probabilities of the network.',
        evidence: {
            variable: {},
            order: []
        }
    };

    static getDerivedStateFromProps(props: ExploreModelScenariosPanelProps, state: ExploreModelScenariosPanelState) {
        return (state.expanded[props.currentScenarioId] !== undefined) ? null : {
            expanded: {...state.expanded, [props.currentScenarioId]: true}
        };
    }

    constructor(props: ExploreModelScenariosPanelProps) {
        super(props);
        this.state = {
            expanded: {
                [BASE_SCENARIO]: true
            }
        }
    }

    onEvidenceClicked(scenarioId, variableId, selectedIndex) {
        const evidence = this.props.exploreModel.scenario[scenarioId].data.evidence.variable[variableId];
        const clearedSelection = (selectedIndex === evidence.selectedIndex);
        this.props.dispatch(updateScenarioEvidenceNodeAction(this.props.problemStepId, this.props.userId, this.props.status, scenarioId, variableId, {
            selectedIndex: clearedSelection ? null : selectedIndex,
            probability: clearedSelection ? evidence.probability : 1
        }));
    }

    onUncertaintyToggled(scenarioId, variableId, value) {
        this.props.dispatch(updateScenarioEvidenceNodeAction(this.props.problemStepId, this.props.userId, this.props.status, scenarioId, variableId, {
            uncertainty: value
        }));
    }

    availableVariableIds(scenarioId, keepVariableId = null) {
        const usedIds = this.props.exploreModel.scenario[scenarioId].data.evidence.order;
        return safeOrderObject(this.props.exploreModel.bayesNet.nodes, 'variable').filter((variableId) => (
            (variableId === keepVariableId || indexOf(usedIds, variableId) < 0)
            && this.props.exploreModel.bayesNet.nodes.variable[variableId].data.variableType !== VariableType.UTILITY
        ));
    }

    availableSortedVariableIds(scenarioId, keepVariableId = null) {
        const availableVariableIds = this.availableVariableIds(scenarioId, keepVariableId);
        const variable = this.props.exploreModel.bayesNet.nodes.variable;
        return availableVariableIds.sort((variableId1, variableId2) => {
            const variable1Type = getKeyVariableType(variable[variableId1].data);
            const variable2Type = getKeyVariableType(variable[variableId2].data);
            return (variable1Type < variable2Type) ? -1 : (variable1Type === variable2Type) ? 0 : 1;
        });
    }

    availableOptions(scenarioId, keepVariableId) {
        const availableVariableIds = this.availableVariableIds(scenarioId, keepVariableId);
        const variable = this.props.exploreModel.bayesNet.nodes.variable;
        return availableVariableIds.sort((variableId1, variableId2) => {
            const variable1Type = getKeyVariableType(variable[variableId1].data);
            const variable2Type = getKeyVariableType(variable[variableId2].data);
            return (variable1Type < variable2Type) ? -1 : (variable1Type === variable2Type) ? 0 : 1;
        }).map((variableId) => (
            {label: variable[variableId].data.name, value: variableId}
        ));
    }

    renderValueSelected(scenario: SingleScenarioState, variableId: string, index: number) {
        const evidence = scenario.data.evidence.variable[variableId];
        return (
            <div className="scenarioCertainValue">
                {evidence.selectedIndex === index ? <span className="material-icons">done</span> : null}
            </div>
        );
    }

    renderUncertainValue(scenario: SingleScenarioState, variableId: string, index: number, readOnly: boolean, onChange: (value: string) => void) {
        const singleScenario = scenario.data.evidence.variable[variableId];
        if (!singleScenario.uncertainty || index !== singleScenario.selectedIndex) {
            return null;
        } else {
            const probability = singleScenario.probability;
            const value = (probability === undefined) ? '' : roundPercentToEpsilon(probability * 100).toString();
            return (
                <div className="scenarioUncertainValue">
                    <SingleInput inputType='number' readOnly={readOnly} onChange={onChange} content={value}
                                 step={200 * EPSILON} onClick={(evt) => {evt.stopPropagation()}}/>
                    &nbsp;%
                </div>
            );
        }
    }

    renderEvidenceInput(scenarioId, variableId, variableIndex, readOnly) {
        const scenario = this.props.exploreModel.scenario[scenarioId];
        const variable = this.props.exploreModel.bayesNet.nodes.variable[variableId];
        const options = this.availableOptions(scenarioId, variableId);
        return (
            <ReorderableDeleteableElement
                index={variableIndex}
                key={variableId}
                readOnly={readOnly}
                onDelete={() => {
                    this.props.dispatch(deleteScenarioEvidenceNodeAction(this.props.problemStepId, this.props.userId, this.props.status, scenarioId, variableId, variableIndex));
                }}
            >
                <Select 
                        options={options}
                        className='selectType'
                        styles={commonSelectStyles}
                        value={options.find(({value}) => (value === variableId))}
                        onChange={(selected) => {
                            if (selected) {
                                this.props.dispatch(changeScenarioEvidenceNodeAction(this.props.problemStepId, this.props.userId, this.props.status, scenarioId, selected.value, variableId));
                            }
                        }}
                        isDisabled={readOnly}
                        isClearable={false}
                        isSearchable={true}
                />
                {
                    <div>
                        {
                            safeOrderObject(variable.attributes, 'state').map((stateId, index) => (
                                <div key={stateId} className="scenarioEvidenceValue" onClick={() => {
                                    if (!readOnly) {
                                        this.onEvidenceClicked(scenarioId, variableId, index);
                                    }
                                }}>
                                    {this.renderValueSelected(scenario, variableId, index)}
                                    <div className="stateNameDiv">
                                        {variable.attributes.state[stateId].stateName}
                                    </div>
                                    {this.renderUncertainValue(scenario, variableId, index, readOnly, (value: string) => {
                                        this.props.dispatch(updateScenarioEvidenceNodeAction(this.props.problemStepId, this.props.userId, this.props.status, scenarioId, variableId, {
                                            probability: clamp(Number(value) / 100, 0, 1)
                                        }));
                                    })}
                                </div>
                            ))
                        }
                        <div className="uncertaintyCheckbox">
                            <SingleInput
                                title="Uncertainty"
                                inputType="checkbox"
                                disabled={readOnly}
                                onChange={(value) => {
                                    this.onUncertaintyToggled(scenarioId, variableId, value);
                                }}
                                content={scenario.data.evidence.variable[variableId].uncertainty}
                            />
                        </div>
                    </div>
                }
            </ReorderableDeleteableElement>
        );
    }

    private renderNameAndDescription(scenarioData, readOnly, scenarioId) {
        if (this.state.editingScenario === scenarioId) {
            return (
                <>
                    <div className="scenarioNameLabel">Scenario name</div>
                    <PlainTextField
                        rows={1}
                        text={scenarioData.title}
                        className="scenarioName"
                        onChange={(value) => {
                            this.props.dispatch(updateScenarioAction(this.props.problemStepId, this.props.userId, this.props.status, scenarioId, {title: value}));
                        }}
                        readOnly={readOnly}
                    />
                    <div className="scenarioDescLabel toolTipContainer">
                        Description
                        <span className="toolTip">What is this Scenario investigating?</span>
                    </div>
                    <PlainTextField
                        rows={3}
                        className="scenarioDescription"
                        text={scenarioData.description || ''}
                        onChange={(value) => {
                            this.props.dispatch(updateScenarioAction(this.props.problemStepId, this.props.userId, this.props.status, scenarioId, {description: value}));
                        }}
                        readOnly={readOnly}
                    />
                    <div className='scenarioNameSaveButton toolTipContainer floatLeft'>
                        <IconLabelButton label='Done' onClick={() => {
                            this.setState({editingScenario: undefined});
                        }}/>
                        <span className="toolTip">Finish editing the scenario name and description.</span>
                    </div>
                </>
            )
        } else if (scenarioData.description && scenarioData.description.trim()) {
            return (
                <div>{scenarioData.description}</div>
            );
        } else {
            return null;
        }
    }

    renderScenario(scenarioId, readOnly) {
        let scenarioData = (scenarioId === BASE_SCENARIO) ?
            ExploreModelScenariosPanel.BASE_SCENARIO_DATA : this.props.exploreModel.scenario[scenarioId].data;
        const isSelected = scenarioId === this.props.currentScenarioId;
        return (
            <ExpansionPanel
                key={scenarioId}
                className='scenarioExpansionPanel'
                headerClassName={classNames('scenarioPanelHeader', {isSelected})}
                label={
                    <div>
                        <input type='radio' checked={isSelected}
                               onChange={() => {this.props.onScenarioChange(scenarioId)}}
                               onClick={(event) => {event.stopPropagation()}}
                        />
                        {scenarioData.title}
                    </div>
                }
                secondaryLabel={
                    isSelected && !readOnly && this.state.editingScenario !== scenarioId ?
                        <span className='material-icons' onClick={(evt) => {
                            this.setState({editingScenario: scenarioId});
                            evt.stopPropagation();
                        }}>create</span> : null
                }
                contentClassName={classNames({isSelected})}
                footer={null}
                expanded={this.state.expanded[scenarioId]}
                onExpandToggle={() => {
                    this.setState({expanded: {...this.state.expanded, [scenarioId]: !this.state.expanded[scenarioId]}});
                }}
            >
                <div onClick={() => {
                    this.props.onScenarioChange(scenarioId);
                }}>
                    {
                        readOnly ? null :
                            <div className="scenarioDeleteButton deleteButton actionColor material-icons" onClick={() => {
                                this.props.dispatch(deleteScenarioAction(this.props.problemStepId, this.props.userId, this.props.status, scenarioId));
                            }}>
                                delete
                                <span className="toolTip toolTipDelScenario">Delete Scenario</span>
                            </div>
                    }
                    {
                        this.renderNameAndDescription(scenarioData, readOnly, scenarioId)
                    }
                    <ReorderableAddableContainer
                        addLabel="EVIDENCE"
                        disableAdd={scenarioData.evidence.order.length >= this.props.exploreModel.bayesNet.nodes.order.length}
                        readOnly={readOnly}
                        onAdd={() => {
                            this.props.dispatch(addScenarioEvidenceNodeAction(
                                this.props.problemStepId,
                                this.props.userId,
                                this.props.status,
                                scenarioId,
                                this.availableSortedVariableIds(scenarioId)[0]
                            ))
                        }}
                        onSortEnd={({oldIndex, newIndex}) => {
                            this.props.dispatch(reorderScenarioEvidenceNodeAction(this.props.problemStepId, this.props.userId, this.props.status, scenarioId, oldIndex, newIndex));
                        }}
                    >
                        {
                            safeOrderObject(scenarioData.evidence, 'variable').map((variableId, index) => (
                                this.renderEvidenceInput(scenarioId, variableId, index, readOnly)
                            ))
                        }
                    </ReorderableAddableContainer>
                </div>
            </ExpansionPanel>
        );
    }

    render() {
        const propUserScenarioIds = Object.keys(this.props.exploreModel.scenario)
            .filter((scenarioId) => (scenarioId === BASE_SCENARIO || !this.props.exploreModel.scenario[scenarioId].data.ownerUserId));
        const loggedInUserScenarioIds = Object.keys(this.props.exploreModel.scenario)
            .filter((scenarioId) => (Number(this.props.exploreModel.scenario[scenarioId].data.ownerUserId) === Number(this.props.loggedInUserId)));
        return (
            <div className="scenarioPanel">
                <div className="title">
                    Scenarios
                </div>
                <ReorderableAddableContainer
                    readOnly={this.props.readOnly}
                    onAdd={() => {
                        const action = addScenarioAction(this.props.problemStepId, this.props.userId, this.props.status, '');
                        this.props.dispatch(action);
                        this.setState({editingScenario: action.scenarioId});
                    }}
                    addLabel="NEW SCENARIO"
                >
                    <ExpansionList animateContent={false}>
                        {propUserScenarioIds.map((scenarioId) => (this.renderScenario(scenarioId, this.props.readOnly || scenarioId === BASE_SCENARIO)))}
                    </ExpansionList>
                </ReorderableAddableContainer>
                {
                    (!this.props.readOnly || Number(this.props.userId) === Number(this.props.loggedInUserId) || this.props.disablePersonalScenarios) ? null : (
                        <div className="myScenariosWrap">
                            <div className="title myScenarios overrideAll roleBackgroundColour">
                                My Scenarios
                            </div>
                            <ReorderableAddableContainer
                                onAdd={() => {
                                    const action = addScenarioAction(this.props.problemStepId, this.props.userId, this.props.status,
                                        '', this.props.loggedInUserId
                                    );
                                    this.props.dispatch(action);
                                    // Since read-only mode stores current scenario in state, can't rely on the Redux action to change it.
                                    this.props.onScenarioChange(action.scenarioId);
                                }}
                                addLabel="NEW MY SCENARIO"
                            >
                                {
                                    loggedInUserScenarioIds.length === 0 ? (
                                        <div className="pageTip">
                                            Create your own Scenarios to explore this Network. These Scenarios are only visible to you.
                                        </div>
                                    ) : (
                                        <ExpansionList animateContent={false}>
                                            {loggedInUserScenarioIds.map((scenarioId) => (this.renderScenario(scenarioId, false)))}
                                        </ExpansionList>
                                    )
                                }
                            </ReorderableAddableContainer>
                        </div>
                    )
                }
                
            </div>
        );
    }

}

export default ExploreModelScenariosPanel;