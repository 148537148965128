import React from "react";

const Menu = ({
    alwaysPerformForceDirectedLayout,
    performForceDirectedLayout,
    onPerformForceDirectedLayout,
    onZoomToFit
}) => (
    <div
        style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            margin: "1em"
        }}
    >
        {!alwaysPerformForceDirectedLayout && (
            <button
                disabled={performForceDirectedLayout}
                onClick={onPerformForceDirectedLayout}
            >
                {performForceDirectedLayout
                    ? "Performing automatic layout..."
                    : "Perform automatic layout"}
            </button>
        )}
        &nbsp; <button onClick={onZoomToFit}>Zoom to fit</button>
    </div>
);

export default Menu;
