import React, {Component} from 'react';
import * as PropTypes from 'prop-types';

class StatusIconUser extends Component {

    static propTypes = {
        className: PropTypes.string.isRequired
    };

    render() {
        return (
            <svg version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink" x="0px" y="0px"
                	 viewBox="0 0 50 50" xmlSpace="preserve" className={this.props.className}>
                <path d="M25,1.7c6.6,0,12,5.4,12,12s-5.4,12-12,12s-12-5.4-12-12S18.4,1.7,25,1.7"/>
                <path d="M25,27.3c12.3,0,22.3,5.7,22.3,12.7v6.4H2.7v-6.4C2.7,33,12.7,27.3,25,27.3z"/>
            </svg>
        );
    }
}

export default StatusIconUser;