import {Component} from 'react';
import * as PropTypes from 'prop-types';
import {connect} from 'react-redux';

import BardFeatureFlagsProvider from './BardFeatureFlagsProvider';
import {getFlagValue} from '../../common/util/featureFlags';

/**
 * Emulate the Flag component from react-flag, but make the redux store available to the flagValue method.
 * See https://www.npmjs.com/package/react-flag
 *
 * Differences:
 * => Instead of taking the name parameter, this component takes a flagName field which assumes the flag is formatted as
 * per the interfaces in featureFlags.ts.
 * => This component's version of "computed properties" (the flagValue method) take the specific flag and the store as
 * parameters.
 */
class ConnectedFlag extends Component {

    static propTypes = {
        flagName: PropTypes.string.isRequired,
        equalTo: PropTypes.string,
        render: PropTypes.func,
        fallbackRender: PropTypes.func,
        component: PropTypes.element,
        fallbackComponent: PropTypes.element
    };

    static contextTypes = {
        [BardFeatureFlagsProvider.CONTEXT_NAME]: PropTypes.object
    };

    render() {
        let active = false;
        if (this.context[BardFeatureFlagsProvider.CONTEXT_NAME]) {
            const flag = this.context[BardFeatureFlagsProvider.CONTEXT_NAME][this.props.flagName];
            if (!flag) {
                throw new Error(`Feature flag name ${this.props.flagName} does not appear in flags?`);
            }
            active = getFlagValue(flag, this.props.store);
            if (this.props.equalTo) {
                active = (active === this.props.equalTo);
            }
        }
        if (active) {
            return this.props.component ||
                (this.props.render ? this.props.render() : this.props.children);
        } else {
            return this.props.fallbackComponent ||
                (this.props.fallbackRender ? this.props.fallbackRender() : null);
        }
    }

}

function mapStoreToProps(store) {
    return {
        store
    }
}

export default connect(mapStoreToProps)(ConnectedFlag);