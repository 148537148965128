export const release_id = '@@bard-release-id';

export const FACILITATOR_DELINQUENT_DAYS = 2;
export const ANALYST_DELINQUENT_DAYS = 3;
export const OBSERVER_DELINQUENT_DAYS = 5;

// These colours should match the corresponding values in _definitions.scss
// Unfortunately, we can't import them directly from scss unless we eject from create-react-app
export const colourDarkBlueGrey = '#2f373c';    // $colour-headerDarkBlueGrey
export const colourBlueGrey = '#607d8b';        // $colour-darkGrey
export const colourLightGrey = '#f2f2f2';       // $colour-lightGrey
export const colourLightBlueGrey = '#cfd8dc';   // $colour-darkMidGrey
export const colourPaleBlueGrey = '#eceff1';    // $colour-background-midGrey
export const colourGreen = '#7cb342';           // $colour-proceed-green
export const colourAdmin = '#0000ff';           // $colour-admin
export const colourFacilitator = '#ff9100';     // $colour-facilitator
export const colourAnalyst = '#7e57c2';         // $colour-analyst
export const colourObserver = '#ec407a';        // $colour-observer
export const colourActionBlue = '#42a5f5';      // $colour-action-blue
export const colourLightActionBlue = '#76b6ed'; // $colour-light-action-blue
export const colourTextGrey = '#263238';        // $colour-textGrey
export const colourProceedGreen = '#719f3c';    // $colour-proceed-green

// BN visualiser colours
export const colourTargetVariable = colourBlueGrey;
export const colourOtherVariable = colourLightBlueGrey;
export const colourDecisionVariable = colourLightActionBlue;
export const colourUtilityVariable = colourProceedGreen;

// Many of these constants match data in the database - changing here or the DB requires a corresponding change in the other place.

// Controls precision; the sum of probabilities must be 1.0 +/- epsilon.
// IMPORTANT: the value must be 5*10^-n for some integer n for rounding/steps to work correctly.
export const EPSILON = 0.00005;

// User
export const USER_STATUS_ACTIVE = 0;
export const USER_STATUS_DISABLED = 1;

// Problem state
export const STATE_ARCHIVED = -1;
export const STATE_NOT_READY = 1;
export const STATE_READY = 2;
export const STATE_ACTIVE = 3;
export const STATE_SUSPENDED = 4;
export const STATE_CLOSED = 5;
export const STATE_TRAINING_PROBLEM = STATE_CLOSED + 1;

// Problem method
export const METHOD_NONE = 0;
export const METHOD_BN = 1;
export const METHOD_NBN = 2;

// Predefined userIds
export const USER_ANY = -1;

// Special projects
export const PROJECT_SYSADMIN = 1;
export const PROJECT_SHARED = 2;

// Roles
export const ROLE_FACILITATOR_NAME = 'facilitator';
export const ROLE_ANALYST_NAME = 'analyst';
export const ROLE_OBSERVER_NAME = 'observer';
export const ROLE_SOLO_ANALYST_NAME = 'soloAnalyst';
export const ROLE_ROBOT_NAME = 'robot';
export const ROLE_UNALLOCATED_NAME = 'unallocated';
export const ROLE_FACILITATOR = 2;
export const ROLE_ANALYST = 3;
export const ROLE_OBSERVER = 4;
export const ROLE_SOLO_ANALYST = 5;
export const ROLE_ROBOT = 9;
export const ROLE_UNALLOCATED = 10;
export type UserRole = 1 | 2 | 3 | 4 | 5 | 9 | 10;

// Group Management requests/actions
export const GRP_MGMT_NOP = 0;
export const GRP_MGMT_OPT_OUT_REQUESTED = 1;
export const GRP_MGMT_OPT_OUT_REQUEST_WITHDRAWN = 2;
export const GRP_MGMT_OPT_OUT_REQUEST_GRANTED = 3;
export const GRP_MGMT_USER_DEMOTED = 4;
export const GRP_MGMT_USER_AUTO_DEMOTED = 5;
export const GRP_MGMT_USER_PROMOTED = 6;

// ProblemStep Launch Modes
export const LAUNCH_MODE_NONE = 0;
export const LAUNCH_MODE_FACILITATOR_ADVANCES = 1;
export const LAUNCH_MODE_REAL_TIME_JURY = 2;
export const LAUNCH_MODE_SOLO_BARD = 3;
export const LAUNCH_MODE_STRATHCLYDE_EXPERIMENT = 4;

// Round types
export const ROUND_NONE = 0;
export const ROUND_PRIVATE = 1;
export const ROUND_EXCHANGE = 2;
export const ROUND_DELPHI_EXCHANGE = 3;
export const ROUND_REPORT_EXCHANGE = 4;
export const ROUND_OPEN = 5;
export const ROUND_READ_ONLY = 6;
export type ROUND = 0 | 1 | 2 | 3 | 4 | 5 | 6;

export const ROUND_UNPUBLISHED = 100;
export const ROUND_PUBLISHED = 200;

// Problem types
export const PROBLEM_INTERNAL_BARD = 1;
export const PROBLEM_T_AND_E = 2;
export const PROBLEM_TRAINING = 3;

// sync variables
// NOTE: the syncVariable values need to match the corresponding fields in allStatusesForUserAndTeamReducer.ts
export const SYNC_KEY_FACTS = 'keyFacts';
export const SYNC_KEY_QUESTIONS = 'keyQuestions';
export const SYNC_KEY_VARIABLES = 'keyVariables';
export const SYNC_METHOD_SELECTION = 'methodSelection';
export const SYNC_COMMENTS_SUGGESTIONS = 'suggestions';
export const SYNC_BN_GRAPH = 'bayesNetGraph';
export const SYNC_BN_PARAMETERS = 'bayesNetParameters';
export const SYNC_EXPLORE_MODEL = 'exploreModel';
export const SYNC_CANDIDATE_SOLUTION = 'candidateSolution';
export const SYNC_REPORT = 'report';

export type BNSyncVariableType = 'keyVariables' | 'bayesNetGraph' | 'bayesNetParameters' | 'exploreModel';

export const BN_SYNC_VARIABLES_LIST: BNSyncVariableType[] = [SYNC_KEY_VARIABLES, SYNC_BN_GRAPH, SYNC_BN_PARAMETERS, SYNC_EXPLORE_MODEL];

// Subtabs
export const SUBTAB_PROBLEM_DESCRIPTION = 'Problem description';
export const SUBTAB_INFORMATION_FACILITATOR = 'Information & Admin';
export const SUBTAB_GROUP_MANAGEMENT = 'Group Management';
export const SUBTAB_INFORMATION_ANALYST = 'Information';
export const SUBTAB_INFORMATION_OBSERVER = 'Information';
export const SUBTAB_MY_DRAFT = 'My Draft';
export const SUBTAB_PUBLISHED = 'Published';
export const SUBTAB_GROUP_DRAFT = 'Group Draft';
export const SUBTAB_GROUP = 'Group';
export const SUBTAB_DISCUSSION = 'Discussion';

// Possible values for copyPublished flag
export const COPY_PUBLISHED_NONE = 'copy_none';
export const COPY_PUBLISHED_APPEND = 'copy_append';
export const COPY_PUBLISHED_REPLACE = 'copy_replace';

// User removed from problem
export const DISPLAY_REMOVED_USER = '[Removed User]';

// Custom headers and cookies
export const HEADER_LISTENER_ID = 'x-listener-id';
export const BARD_SESSION_COOKIE = 'connect.sid';

export const GOOGLE_CLIENT_ID = '403565344488-uq4me3ru723724ul8eq72lm56p61m3fr.apps.googleusercontent.com';

// Redux actions or values which originate on the server, but are otherwise client-side
export const SET_LISTENER_ID = 'set_listener_id';
export const BARD_SESSION_INVALIDATED = 'bard-session-invalidated';

// Redux actions that are shared across multiple reducers go here, to avoid circular import dependencies.
export const STATUS_CHANGE = 'status_change';
export const PROBLEM_SELECTED = 'problem_selected';
export const UPDATE_USER_DB_FOR_PROBLEM = 'update_user_db_for_problem';
export const UPDATE_USER_ROLES_FOR_PROBLEM = 'update_user_roles_for_problem';
export const UPDATE_VARIABLE_TYPE = 'update_variable_type';
export const RESET_PROBLEM_STEP = 'reset_problem_step';
export const COPY_PUBLISHED_ACTION = 'copy_published_action';
export const APPEND_PUBLISHED_ACTION = 'append_published_action';
export const REVERT_STATUS_CHANGE = 'revert_status_change';
export const SET_NOTES = 'set_notes';

// Moment parse/format format string which preserves milliseconds
export const TIMESTAMP_FORMAT = 'YYYY-MM-DDTHH:mm:ss.SSSZ';

// Step Descriptions
export const STEP_NOT_LAUNCHED = 1;
export const STEP_EXPLORE_PROBLEM = 2;
export const STEP_BAYESIAN_SOLUTION = 3;
export const STEP_VARIABLES = 4;
export const STEP_STRUCTURE = 5;
export const STEP_PARAMETERS = 6;
export const STEP_EXPLORE_MODEL = 7;
export const STEP_BR_CANDIDATE_SOLUTION = 8;
export const STEP_BR_REPORT = 9;
export const STEP_NBR_CANDIDATE_SOLUTION = 10;
export const STEP_NBR_REPORT = 11;
export const STEP_FACILITATOR_ADMIN = 12;
export const STEP_PROBLEM_STATUS = 13;
export const STEP_COMBINED_REPORT = 14;
export const STEP_STRATHCLYDE_EXPLORE_PROBLEM = 15;
export const STEP_SINGLE_VARIABLE = 16;
export const STEP_SINGLE_STRUCTURE = 17;
export type STEP = 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12 | 13 | 14 | 15 | 16 | 17;

// Tags
export const TAG_DRAFT_PUBLISHED = 'draftPublished';
export const TAG_DISCUSSION = 'discussion';
export const TAG_GROUP_DRAFT_PUBLISHED = 'groupDraftPublished';
export const TAG_CHAT = 'chat';
export const TAG_OPT_OUT_REQUEST = 'optOutRequest';
export type Tag = 'draftPublished' | 'discussion' | 'groupDraftPublished' | 'chat' | 'optOutRequest';

// Step tense
export const STEP_TENSE = {
    PAST: 1,
    PRESENT: 2,
    FUTURE: 3,
    FUTURE_EDITABLE: 4
};

//Header Help related constants
//BARD Modes
export const MODE_DELPHI_EXCHANGE = 'bard_mode_delphi_exchange';
export const MODE_DIRECT_EXCHANGE = 'bard_mode_direct_exchange';
export const MODE_OPEN_ROUND = 'bard_mode_open_round';
export const MODE_PRIVATE_ROUND = 'bard_mode_private_round';

//HELP types
export const TIP_SCREEN_LEFT = 'tip_left';
export const TIP_SCREEN_RIGHT = 'tip_right';
export const HELP_TIPS_ONSCREEN = 'tis_onscreen';
export const HELP_TIPS_POPUP = 'tis_popup';


//Main BARD Help related constants
export const HELP_NEW = 'help_whats_new';
export const HELP_INTRO = 'help_introduction';
export const HELP_EXPLORE_PROBLEM = 'help_exploreProblem';
export const HELP_BUILD_NETWORK = 'help_buildNetwork';
export const HELP_BN_VARIABLES = 'help_bnVariables';
export const HELP_BN_STRUCTURE = 'help_bnStructure';
export const HELP_BN_PARAMETERS = 'help_bnParameters';
export const HELP_BN_EXPLORE_NETWORK = 'help_bnExploreNetwork';
export const HELP_CANDIDATE_SOLUTION = 'help_candidateSolution';
export const HELP_CS_REPORT = 'help_CS_report';
export const HELP_REPORT = 'help_report';
export const HELP_EXAMPLES = 'help_examples';
export const HELP_WORKING_IN_TEAMS = 'help_working_in_teams';
export const HELP_GOOD_REASONING = 'help_goodReasoning';
export const HELP_FACILITATOR = 'help_facilitator';
export const HELP_COMMUNICATION = 'help_communication';
export const HELP_TROUBLESHOOTING = 'help_troubleshooting';
export const HELP_TRAINING = 'help_training';


//Help Tips
export const HELP_STEP_PROBLEM_DESC = 'help_problem_description';
export const HELP_EXPLORE_PROBLEM_READ = 'help_explore_problem';
export const HELP_EXPLORE_PROBLEM_KEY_QUESTIONS = 'help_key_questions';
export const HELP_EXPLORE_PROBLEM_HYPOTHESES = 'help_hypotheses';
export const HELP_EXPLORE_PROBLEM_EVIDENCE = 'help_evidence';
export const HELP_EXPLORE_PROBLEM_SELECT_APPROACH = 'help_select_approach';
export const HELP_EXPLORE_PROBLEM_APPROACH_RATIONALE = 'help_approach_rationale';
export const HELP_EXPLORE_PROBLEM_APPROACH = 'help_explore_problem_approach';
export const HELP_VARIABLES_TARGET_VARIABLES = 'help_target_variables';
export const HELP_VARIABLES_OTHER_VARIABLES = 'help_other_variables';
export const HELP_VARIABLES_DECISION_VARIABLES = 'help_decision_variables';
export const HELP_VARIABLES_UTILITY_VARIABLES = 'help_utility_variables';
export const HELP_STRUCTURE = 'help_structure';
export const HELP_PARAMETERS = 'help_parameters';
export const HELP_EXPLORE_NETWORK = 'help_explore_network';
export const HELP_TIPS_REPORT = 'help_tips_report';
export const HELP_TIPS_REPORTA = 'help_tips_analyst_report';
export const HELP_TIPS_REPORTF = 'help_tips_facilitator_report';
export const HELP_TIPS_REPORTA_SELECT = 'help_tips_analyst_report_select';
export const HELP_TIPS_REPORTF_SELECT = 'help_tips_facilitator_report_select';
export const HELP_TIPS_COMBINED_REPORTF = 'help_tips_combined_reportf';
export const HELP_TIPS_COMBINED_REPORTA = 'help_tips_combined_reporta';
export const HELP_TIPS_COMBINED_REPORTF_SELECT = 'help_tips_combine_reportf_select';
export const HELP_TIPS_COMBINED_REPORTA_SELECT = 'help_tips_combine_reporta_select';
export const HELP_TIPS_COMBINED_REPORTA_PUBLISHED = 'help_tips_combine_reporta_published';
export const HELP_TIPS_COMBINED_REPORTF_PUBLISHED = 'help_tips_combine_reportf_published';
export const HELP_TIPS_COMBINED_REPORTA_GROUP = 'help_tips_combine_reporta_group';
export const HELP_TIPS_COMBINED_REPORTF_GROUP = 'help_tips_combine_reportf_group';
export const HELP_SUBSTEP_DISCUSSION = 'help_substep_discussion';


//Page constants to provide context props to components
export const PAGE_FOYER = 'bard_foyer';
export const PAGE_HEADER = 'bard_header';
export const PAGE_STATUS = 'bard_problem_status';

//Logout reasons.
export const LOGOUT_REASON_SESSION_INVALID = 'Your session is no longer valid.  Please log in again.';
export const LOGOUT_REASON_SERVICE_UNAVAILABLE = 'BARD is not available at the moment. Please come back later.';

// User activity types
export const ACTIVITY_PROBLEM_ROUTE_NAME = 'problem';
export const ACTIVITY_FOYER = 'select';
export const ACTIVITY_DESCRIPTION = 'description';
export const ACTIVITY_PROBLEM_DESCRIPTION = 'problem_description';
export const ACTIVITY_USER_LOGIN = 'user_login';
export const ACTIVITY_USER_LOGOUT = 'user_logout';
export const ACTIVITY_USER_VIEW = 'user_view_activity';
export const ACTIVITY_USER_VIEW_FOYER = 'user_view_foyer';
export const ACTIVITY_USER_OPEN_PROBLEM = 'user_open_problem';
export const ACTIVITY_USER_MODIFY = 'user_modify_activity';
export const ACTIVITY_USER_AUTHENTICATION ='user_authentication';


// Explore Problem labels based on readOnly flag 
export const appContextDict = {
    exploreProblem: {
        APPROACH_SELECTION_LABEL: 'Select',
        APPROACH_SELECTION_LABEL_READ_ONLY: 'Selected',
        KEY_QUESTION_MY_COMMENT_LABEL: 'My Comments',
        KEY_QUESTION_COMMENT_LABEL: 'Comments'
    }
};

// Facilitator Indicators related constants

// Note that the below group IDs only apply to course ID 23, but since we're only going with CC users we don't need to
// make further changes to switch between different group IDs based on course.
export const YES_GROUP = 40;
export const NO_GROUP = 41;
export const MAYBE_GROUP = 42;

export const YES = 1;
export const MAYBE = 0;
export const NO = -1;

//min & max for report rating 
export const REPORT_RATING_MINIMUM = 1;
export const REPORT_RATING_MAXIMUM = 7;

// common
export const OPEN_PROBLEM_ACTION = 'OPEN_PROBLEM';

export const millisecondsPerDay = 1000 * 60 * 60 * 24;

export const fileUploadPath = 'userUploads';
export const maxUploadFileSize = 2 * 1024 * 1024;
export const fileStoragePath = 'userUploadedFiles';
