import React from "react";

const EditLabelButton = ({ onclick }) => (
    <svg
        onMouseDown={e => {
            e.preventDefault();
            e.stopPropagation();
        }}
        onClick={onclick}
        x="26"
        style = {{overflow: "visible"}}
    >
        <circle
            className="editLabelButton"
            r={12}
            fill="green"
            style={{ filter: "url(#shadow)" }}
            cursor="pointer"
        />
        <text
            fill="white"
            textAnchor="middle"
            alignmentBaseline="central"
            style={{ pointerEvents: "none" }}
        >
            🖉
        </text>
        <title>Edit Label</title>
    </svg>
);

export default EditLabelButton;