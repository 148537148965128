import React, {Component} from 'react';
import {indexOf} from 'lodash';
import Select from 'react-select';
import {Checkbox, FontIcon} from 'react-md';
import ReactMarkdown from 'react-markdown';

import {
    addMonitorVariableAction,
    BASE_SCENARIO,
    changeMonitorVariableAction,
    deleteMonitorVariableAction,
    ExploreModelState,
    reorderMonitorVariableAction,
    selectScenarioAction,
    setMonitorVariableFocusStateAction
} from '../../common/reducers/exploreModelReducer';
import ReorderableAddableContainer from './ReorderableAddableContainer';
import ReorderableDeleteableElement from './ReorderableDeleteableElement';
import {roundPercentToEpsilon} from '../util/maths';
import {safeOrderObject} from '../../common/util/safeOrder';
import {getKeyVariableType, SingleVariableState, VariableType} from '../../common/reducers/keyVariablesReducer';
import DispatchProp from '../../@types/dispatchProp';

import '../scss/exploreModel.scss';
import '../scss/react-md.scss';
import commonSelectStyles from '../util/commonSelectStyles';

interface ExploreModelMonitorVariablesPanelProps extends DispatchProp {
    readOnly: boolean;
    problemStepId: number;
    userId: number;
    status: number;
    exploreModel: ExploreModelState;
    currentScenarioId: string;
}

class ExploreModelMonitorVariablesPanel extends Component<ExploreModelMonitorVariablesPanelProps> {

    private selectScenarioTimeout: number | undefined;

    availableVariableIds(keepVariableId = null) {
        const usedIds = this.props.exploreModel.scenario[this.props.currentScenarioId].monitorVariables;
        return safeOrderObject(this.props.exploreModel.bayesNet.nodes, 'variable').filter((variableId) => (
            (variableId === keepVariableId || indexOf(usedIds, variableId) < 0)
            && this.props.exploreModel.bayesNet.nodes.variable[variableId].data.variableType !== VariableType.UTILITY
        ));
    }

    getDefaultFocusStateIdForMonitorVariable(variableId) {
        // Default focus state for a variable is True or Yes, or if no such state exists, use the 1st state.
        const attributes = this.props.exploreModel.bayesNet.nodes.variable[variableId].attributes;
        const focusStateId = safeOrderObject(attributes, 'state').reduce<string | null>((result, stateId) => {
            const stateName = attributes.state[stateId].stateName.toLowerCase();
            return (stateName === 'true' || stateName === 'yes') ? stateId : result;
        }, null);
        return focusStateId || attributes.order[0];
    }

    availableOptions(keepVariableId) {
        const availableVariableIds = this.availableVariableIds(keepVariableId);
        const variable = this.props.exploreModel.bayesNet.nodes.variable;
        return availableVariableIds.sort((variableId1, variableId2) => {
            const variable1Type = getKeyVariableType(variable[variableId1].data);
            const variable2Type = getKeyVariableType(variable[variableId2].data);
            return (variable1Type < variable2Type) ? -1 : (variable1Type === variable2Type) ? 0 : 1;
        }).map((variableId) => (
            {label: variable[variableId].data.name, value: variableId}
        ));
    }

    renderFocusStateRadioButtonAndLabel(scenarioId, variableId, variableIndex, stateId, readOnly) {
        const focusState = ((this.props.exploreModel.scenario[scenarioId].monitorVariableFocusStates
            && this.props.exploreModel.scenario[scenarioId].monitorVariableFocusStates[variableIndex])
            || this.getDefaultFocusStateIdForMonitorVariable(variableId));
        return (
            <div className='toolTipContainer'>
                <Checkbox
                    id={`${scenarioId}_${variableId}_${stateId}`}
                    name={`${scenarioId}_${variableId}_${stateId}`}
                    label={this.props.exploreModel.bayesNet.nodes.variable[variableId].attributes.state[stateId].stateName}
                    checked={focusState === stateId}
                    checkedIcon={<FontIcon>radio_button_checked</FontIcon>}
                    uncheckedIcon={<FontIcon>radio_button_unchecked</FontIcon>}
                    value={stateId}
                    disabled={readOnly}
                    onChange={() => {
                        this.props.dispatch(setMonitorVariableFocusStateAction(
                            this.props.problemStepId,
                            this.props.userId,
                            this.props.status,
                            scenarioId,
                            variableIndex,
                            stateId
                        ));
                    }}
                />
                <div className='toolTip toolTipBelief'>The selected state will be described in the Explanation.</div>
            </div>
        );
    }

    renderBeliefAsPercentage(belief) {
        return roundPercentToEpsilon(belief * 100) + '%';
    }

    renderBeliefAsBar(belief) {
        return (
            <div className="beliefBar" style={{width: this.renderBeliefAsPercentage(belief)}}/>
        );
    }

    renderUtility(utility) {
        return 'Expected Utility: ' + roundPercentToEpsilon(utility);
    }

    private renderDecisionVariableMessage(variableId: string, variable: SingleVariableState): React.ReactElement | null {
        safeOrderObject(this.props.exploreModel.bayesNet.nodes, 'variable').forEach((decisionId) => {
            console.log(decisionId, JSON.stringify(this.props.exploreModel.scenario[this.props.currentScenarioId].data.evidence.variable[decisionId]));
        });

        const currentDecisionVariableId = safeOrderObject(this.props.exploreModel.bayesNet.nodes, 'variable')
            .reduce<string | undefined>((result, decisionId) => (
                (!result
                    && getKeyVariableType(this.props.exploreModel.bayesNet.nodes.variable[decisionId].data) === VariableType.DECISION
                    && (!this.props.exploreModel.scenario[this.props.currentScenarioId].data.evidence.variable[decisionId]
                        || this.props.exploreModel.scenario[this.props.currentScenarioId].data.evidence.variable[decisionId].selectedIndex === null
                    )
                ) ? decisionId : result
            ), undefined);
        if (!currentDecisionVariableId || currentDecisionVariableId === variableId) {
            return null;
        }
        const currentDecisionName = this.props.exploreModel.bayesNet.nodes.variable[currentDecisionVariableId].data.name;
        return (
            <div>
                The expected utilities for "{variable.data.name}" cannot be calculated until a decision is made about
                "{currentDecisionName}".  To do this:
                <ul>
                    <li>View the expected utilities of "{currentDecisionName}".</li>
                    <li>Add "{currentDecisionName}" as evidence to this scenario and select its state.</li>
                </ul>
                Alternatively, move "{variable.data.name}" above "{currentDecisionName}" in the VARIABLES step to make
                it the next decision instead.
            </div>
        );
    }

    renderBeliefTable(variableId, variableIndex, scenarioId, readOnly) {
        const options = this.availableOptions(variableId);
        const beliefs = this.props.exploreModel.scenario[scenarioId].beliefs;
        const variable = this.props.exploreModel.bayesNet.nodes.variable[variableId];
        return (
            <ReorderableDeleteableElement
                index={variableIndex}
                key={variableId}
                readOnly={readOnly}
                onDelete={() => {
                    this.props.dispatch(deleteMonitorVariableAction(
                        this.props.problemStepId,
                        this.props.userId,
                        this.props.status,
                        scenarioId,
                        variableIndex
                    ));
                }}
            >
                <Select options={options}
                        value={options.find(({value}) => (value === variableId))}
                        styles={commonSelectStyles}
                        onChange={(selected) => {
                            if (selected) {
                                this.props.dispatch(changeMonitorVariableAction(
                                    this.props.problemStepId,
                                    this.props.userId,
                                    this.props.status,
                                    scenarioId,
                                    variableIndex,
                                    selected.value,
                                    this.getDefaultFocusStateIdForMonitorVariable(selected.value)
                                ));
                            }
                        }}
                        isDisabled={readOnly}
                        isClearable={false}
                        isSearchable={true}
                />
                {
                    (!beliefs || !beliefs[variableId] || beliefs[variableId].length < variable.attributes.order.length) ? (
                        getKeyVariableType(variable.data) === VariableType.DECISION ? (
                            <div key={`${variableId}-decision`}>
                                {this.renderDecisionVariableMessage(variableId, variable)}
                            </div>
                        ) : null
                    ) : (
                        <table className="beliefTable">
                            <tbody>
                            {
                                safeOrderObject(this.props.exploreModel.bayesNet.nodes.variable[variableId].attributes, 'state')
                                    .map((stateId, stateIndex) => (
                                            <tr key={`${variableId}-${stateId}`}>
                                                {
                                                    getKeyVariableType(variable.data) === VariableType.DECISION ? (
                                                        <>
                                                            <th className='utilityNode'>{this.props.exploreModel.bayesNet.nodes.variable[variableId].attributes.state[stateId].stateName}</th>
                                                            <td colSpan={2}>{this.renderUtility(beliefs[variableId][stateIndex])}</td>
                                                        </>
                                                    ) : (
                                                        <>
                                                            <th>{this.renderFocusStateRadioButtonAndLabel(scenarioId, variableId, variableIndex, stateId, readOnly)}</th>
                                                            <td className="beliefPercentageCol">{this.renderBeliefAsPercentage(beliefs[variableId][stateIndex])}</td>
                                                            <td className="beliefBarBack">{this.renderBeliefAsBar(beliefs[variableId][stateIndex])}</td>
                                                        </>
                                                    )
                                                }
                                            </tr>
                                        )
                                    )
                            }
                            </tbody>
                        </table>
                    )
                }
            </ReorderableDeleteableElement>
        );
    }

    render() {
        const scenarioId = this.props.currentScenarioId;
        const scenario = this.props.exploreModel.scenario[scenarioId];
        if (!scenario || !scenario.beliefs) {
            if (!this.selectScenarioTimeout) {
                this.selectScenarioTimeout = window.setTimeout(() => {
                    this.props.dispatch(selectScenarioAction(this.props.problemStepId, this.props.userId, this.props.status, scenarioId || BASE_SCENARIO));
                    this.selectScenarioTimeout = undefined;
                }, 100);
            }
            return (
                <div className="beliefsPanel">
                    <span className="calculating">Calculating...</span>
                </div>
            );
        }
        const isBaseScenario = (scenarioId === BASE_SCENARIO);
        const readOnly = (this.props.readOnly && (isBaseScenario || isNaN(+scenario.data.ownerUserId!) || Number(this.props.userId) === Number(scenario.data.ownerUserId)));
        const TIP_PRIOR_PROB = 'Add variables to see how the scenario impacts their probabilities.';
        
        return (
            <div className="beliefsPanel">
                <div className="title toolTipContainer">
                    {(isBaseScenario) ? 'Prior Probabilities' : 'Updated Probabilities'}
                    <div className='toolTip probabilitiesToolTip'>Probabilities calculated from Scenario</div>
                </div>

                {
                    readOnly ? null : (
                        <div className="pageTip">{TIP_PRIOR_PROB}</div>
                    )
                }
                <div className="subtitle">
                    {(isBaseScenario) ? null : 'For ' + scenario.data.prefix + scenario.data.title}
                </div>
                {
                    (scenario.beliefs.error) ? (
                        <div className="errorStyle">
                            {
                                typeof(scenario.beliefs.error) === 'string' ? (
                                    <span>
                                        <ReactMarkdown source={scenario.beliefs.error} />
                                    </span>
                                ) : (
                                    <span>An error occurred attempting to calculate probabilities.</span>
                                )
                            }
                        </div>
                    ) : (
                        <ReorderableAddableContainer
                            addLabel="VARIABLE"
                            disableAdd={scenario.monitorVariables.length >= this.props.exploreModel.bayesNet.nodes.order.length}
                            readOnly={readOnly}
                            onAdd={() => {
                                const availableVariableId = this.availableVariableIds()[0];
                                this.props.dispatch(addMonitorVariableAction(
                                    this.props.problemStepId,
                                    this.props.userId,
                                    this.props.status,
                                    scenarioId,
                                    availableVariableId,
                                    this.getDefaultFocusStateIdForMonitorVariable(availableVariableId)
                                ));
                            }}
                            onSortEnd={({oldIndex, newIndex}) => {
                                this.props.dispatch(reorderMonitorVariableAction(this.props.problemStepId, this.props.userId, this.props.status, scenarioId, oldIndex, newIndex));
                            }}
                        >
                            {
                                scenario.monitorVariables.map((variableId, index) => (
                                    this.renderBeliefTable(variableId, index, scenarioId, readOnly)
                                ))
                            }
                        </ReorderableAddableContainer>
                    )
                }
            </div>
        );
    }
}

export default ExploreModelMonitorVariablesPanel;