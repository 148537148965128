import {Reducer} from 'redux';

import ensureFieldPath from '../ensureFieldPath';
import {CandidateSolutionTemplatePojo} from '../../server/orm/dao/candidateSolutionTemplateDAO';

// ======== Reducers

export type CandidateSolutionTemplateReducerType = {[key: number]: CandidateSolutionTemplatePojo}

const candidateSolutionTemplateReducer: Reducer<CandidateSolutionTemplateReducerType> = (state = {}) => {
    return state;
};


export default candidateSolutionTemplateReducer;

// ======== DBSync functions

export function setCandidateSolutionTemplateInStore(store, template: CandidateSolutionTemplatePojo) {
    ensureFieldPath(store, 'sharedState', 'candidateSolutionTemplate', template.id);
    store.sharedState.candidateSolutionTemplate[template.id] = template;
}


// ======== Getter functions
export function getCandidateSolutionTemplateFromStore(store, approachId) {
    return store.sharedState.candidateSolutionTemplate[approachId];
}

export function getCandidateSolutionTemplatesFromStore(store): CandidateSolutionTemplateReducerType {
    return store.sharedState.candidateSolutionTemplate;
}