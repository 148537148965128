import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {trackLogoutActivity} from '../util/userActivity';
import {getListenerIdFromStore} from '../reducers/listenerIdReducer';

class Logout extends Component {

    static propTypes = {
        onLogout: PropTypes.func,
        text: PropTypes.string,
        className: PropTypes.string
    };

    static defaultProps = {
        text: null
    };

    constructor(props) {
        super(props);
        this.handleLogout = this.handleLogout.bind(this);
    }

    handleLogout() {
        if(this.props.history) {
            this.props.history.index=0;
        }
        if (typeof(this.props.onLogout) !== 'undefined') {
            this.props.onLogout();
        }
        return trackLogoutActivity()
            .then(() => {
                // Load the logout page directly from the server, which will send back a redirect to the login path.
                // Navigating to a new page will also reload the Redux store, so we don't need to dispatch Logout action.
                window.location.assign('/api/logout?listenerId=' + this.props.listenerId);
            });
    }

    render() {
        
        return (
            <span className={this.props.className ? this.props.className + ' logout' : 'logout'} onClick={this.handleLogout}>{this.props.text ? this.props.text : 'Logout'}</span>
        );
    }
}

function mapStoreToProps(store) {
    return {
        listenerId: getListenerIdFromStore(store)
    };
}

export default connect(mapStoreToProps)(Logout);