const UPDATE_EMAIL_TEMPLATE = 'updateEmailTemplate';
const UPDATE_EMAIL_TEMPLATE_LIST = 'updateEmailTemplateList';

function allEmailTemplatesReducer(state = {}, action) {
    switch (action.type) {
        case UPDATE_EMAIL_TEMPLATE_LIST:
            return action.emails.reduce((accum, email) => {
                accum[email.id] = email;

                return accum;
            }, {});
        case UPDATE_EMAIL_TEMPLATE:
            return { ...state, [action.email.id]: action.email };
        default:
            return state;
    }
}

export default allEmailTemplatesReducer;

export function updateEmailTemplatesListAction(emails) {
    return { type: UPDATE_EMAIL_TEMPLATE_LIST, emails };
}

export function updateEmailTemplateAction(email) {
    return { type: UPDATE_EMAIL_TEMPLATE, email };
}

export function getAllEmailTemplatesFromStore(store) {
    return store.clientState.allEmailTemplates;
}
