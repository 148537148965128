import React, {Component} from 'react';
import {throttle} from 'lodash';
import PropTypes from 'prop-types'
import isRequiredIf from 'react-proptype-conditional-require';
import classNames from 'classnames';

import '../scss/plainTextField.scss';

import {getProblemTextFromValidation, shouldRecalculateValidation} from '../../common/util/validation';

class PlainTextField extends Component {

    static propTypes = {
        text: PropTypes.string.isRequired,
        rows: PropTypes.number,
        cols: PropTypes.number,
        placeholder: PropTypes.string,
        characterLimit: PropTypes.number,
        onChange: PropTypes.func,
        changeOnBlur: PropTypes.bool,
        readOnly: PropTypes.bool,
        className: PropTypes.string,
        autoResize: PropTypes.bool,
        validationName: isRequiredIf(PropTypes.string, (props) => (props.onValidate !== undefined && !props.readOnly)),
        validation: isRequiredIf(PropTypes.object, (props) => (props.onValidate !== undefined && !props.readOnly)),
        onValidate: PropTypes.func
    };

    static defaultProps = {
        readOnly: false,
        changeOnBlur: false,
        autoResize: false,
        characterLimit: 1000
    };

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.onSetRef = this.onSetRef.bind(this);
        this.dispatchChange = throttle(this.dispatchChange.bind(this), 5000);
        this.flushDispatchChange = this.flushDispatchChange.bind(this);
        this.state = {text: props.text};
    }

    UNSAFE_componentWillMount() {
        if (!this.props.readOnly) {
            this.onValidate(this.state.text);
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        // Note that because we throttle dispatches to the store, the store could be up to 5 seconds out of date with
        // this.state (actually could be more than 5 seconds if the changeOnBlur prop is set true.)  So, prefer to keep
        // state if we can, and leave it alone unless props.text has actually changed.
        if (newProps.text !== this.props.text) {
            this.setState({text: newProps.text});
        }
        if (!newProps.readOnly && shouldRecalculateValidation(newProps.validation, newProps.validationName)) {
            this.onValidate(newProps.text);
        }
    }

    componentWillUnmount() {
        this.flushDispatchChange();
    }

    onSetRef(textarea) {
        if (textarea && this.props.autoResize) {
            this.textarea = textarea;
            textarea.style.height = (textarea.scrollHeight + 4) + 'px';
        }
    }

    onChange(value) {
        const oldValue = this.state.text;
        const newValue = value.length > this.props.characterLimit ? value.slice(0, this.props.characterLimit) : value;
        this.setState({text: newValue}, () => {
            this.onValidate(newValue, oldValue);
        });
        if (!this.props.changeOnBlur) {
            this.dispatchChange(newValue);
        }
        if (this.props.autoResize && this.textarea) {
            this.textarea.style.height = '1em'; // in case text is deleted and height shrinks
            this.textarea.style.height = (this.textarea.scrollHeight + 4) + 'px';
        }
    }

    flushDispatchChange() {
        this.dispatchChange.flush();
    }

    dispatchChange(value) {
        this.props.onChange(value);
    }

    onValidate(value, oldValue) {
        if (this.props.onValidate) {
            this.props.onValidate(this.props.validationName, value, oldValue);
        }
    }

    render() {
        const firstProblem = getProblemTextFromValidation(this.props.validationName, this.props.validation);
        return (
            <textarea
                ref={this.onSetRef}
                className={classNames('plainTextField', this.props.className, {
                    error: firstProblem
                })}
                title={firstProblem}
                readOnly={this.props.readOnly}
                rows={this.props.rows || (this.props.autoResize ? 1 : 3)}
                cols={this.props.cols || 40}
                value={this.state.text}
                placeholder={this.props.placeholder}
                onChange={!this.props.readOnly ? (e) => {
                    this.onChange(e.target.value);
                } : null}
                onBlur={!this.props.readOnly ? () => {
                    if (this.props.changeOnBlur) {
                        this.dispatchChange(this.state.text);
                    }
                    this.flushDispatchChange();
                } : null}
            />);
    }
}


export default PlainTextField
