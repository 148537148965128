import {AnyAction, Reducer} from 'redux';
import moment from 'moment';

import ensureFieldPath from '../ensureFieldPath';
import * as constants from '../util/constants';
import {objectMapReducer, ObjectMapState} from '../util/genericReducers';
import { StoreWithSharedState } from './sharedStateReducer';

export const GRP_MGMT_AUTO_REJECT_OPT_OUT_REQUEST = 'grp_mgmt_auto_reject_opt_out_request';
export const GRP_MGMT_OPT_OUT_REQUEST = 'grp_mgmt_opt-out-request';
export const GRP_MGMT_OPT_OUT_WITHDRAW_REQUEST = 'grp_mgmt_opt-out-withdraw';
export const GRP_MGMT_OPT_OUT_GRANT_REQUEST = 'grp_mgmt_opt-out-grant';
export const GRP_MGMT_DEMOTE_USER = 'grp_mgmt_demote_user';
export const GRP_MGMT_AUTO_DEMOTE_USER = 'grp_mgmt_auto_demote_user';
export const GRP_MGMT_PROMOTE_USER = 'grp_mgmt_promote_user';

export function getDBSyncActionTypes() {
    return [GRP_MGMT_OPT_OUT_REQUEST, GRP_MGMT_OPT_OUT_WITHDRAW_REQUEST, GRP_MGMT_OPT_OUT_GRANT_REQUEST, GRP_MGMT_DEMOTE_USER, GRP_MGMT_AUTO_DEMOTE_USER, GRP_MGMT_PROMOTE_USER, GRP_MGMT_AUTO_REJECT_OPT_OUT_REQUEST];
}

export function getActivityActionTypes() {
    return [GRP_MGMT_OPT_OUT_REQUEST, GRP_MGMT_OPT_OUT_WITHDRAW_REQUEST, GRP_MGMT_OPT_OUT_GRANT_REQUEST, GRP_MGMT_DEMOTE_USER, GRP_MGMT_AUTO_DEMOTE_USER, GRP_MGMT_PROMOTE_USER, GRP_MGMT_AUTO_REJECT_OPT_OUT_REQUEST];
}

export interface GroupManagementAction extends AnyAction {
    problemStepId: number,
    userId: number,
    lastAction: number,
    requestDate?: Date,
    actionDate?: Date,
    fromRole?: number,
    toRole?: number
}

export type SingleUserGroupManagementReducerType = {
    lastAction: number;
    requestDate: Date;
    actionDate: Date;
    fromRole?: number;
    toRole?: number;
};

const singleUserGroupManagementReducer: Reducer<SingleUserGroupManagementReducerType> = (state = {
    lastAction: 0,
    requestDate: new Date(),
    actionDate: new Date(),
    fromRole: constants.ROLE_UNALLOCATED,
    toRole: constants.ROLE_UNALLOCATED
}, action) => {
    switch (action.type) {
        case GRP_MGMT_OPT_OUT_REQUEST:
            return {...state, lastAction: action.lastAction, requestDate: action.requestDate, actionDate: action.actionDate, fromRole: action.fromRole};
        case GRP_MGMT_DEMOTE_USER:
        case GRP_MGMT_AUTO_DEMOTE_USER:
        case GRP_MGMT_PROMOTE_USER:
            return {...state, lastAction: action.lastAction, requestDate: action.requestDate, actionDate: action.actionDate, fromRole: action.fromRole, toRole: action.toRole};
        case GRP_MGMT_OPT_OUT_WITHDRAW_REQUEST:
            return {...state, lastAction: action.lastAction, actionDate: action.actionDate};
        case GRP_MGMT_OPT_OUT_GRANT_REQUEST:
            return {...state, lastAction: action.lastAction, actionDate: action.actionDate, toRole: action.toRole};
        case GRP_MGMT_AUTO_REJECT_OPT_OUT_REQUEST:
            return {...state, lastAction: action.lastAction, actionDate: action.actionDate};
        default:
            return state;
    }
};

export type GroupManagementReducerType = ObjectMapState<SingleUserGroupManagementReducerType>;

const groupManagementReducer: Reducer<GroupManagementReducerType> = objectMapReducer('userId', singleUserGroupManagementReducer);

export default groupManagementReducer;

export function rejectOptOutRequestAction(problemStepId, userId, userRole): GroupManagementAction {
    let now = moment().toDate();
    return {type: GRP_MGMT_AUTO_REJECT_OPT_OUT_REQUEST, problemStepId, userId, lastAction: constants.GRP_MGMT_NOP, requestDate: now, actionDate: now, fromRole: userRole};
}

export function requestOptOutAction(problemStepId, userId, userRole): GroupManagementAction {
    let now = moment().toDate();
    return {type: GRP_MGMT_OPT_OUT_REQUEST, problemStepId, userId, lastAction: constants.GRP_MGMT_OPT_OUT_REQUESTED, requestDate: now, actionDate: now, fromRole: userRole};
}

export function withdrawOptOutRequestAction(problemStepId, userId, userRole): GroupManagementAction {
    return {type: GRP_MGMT_OPT_OUT_WITHDRAW_REQUEST, problemStepId, userId, lastAction: constants.GRP_MGMT_OPT_OUT_REQUEST_WITHDRAWN, actionDate: moment().toDate(), fromRole: userRole};
}

export function grantOptOutRequestAction(problemStepId, userId, fromRole, toRole=constants.ROLE_OBSERVER): GroupManagementAction {
    return {type: GRP_MGMT_OPT_OUT_GRANT_REQUEST, problemStepId, userId, lastAction: constants.GRP_MGMT_OPT_OUT_REQUEST_GRANTED, actionDate: moment().toDate(), fromRole, toRole};
}

export function demoteUserAction(problemStepId, userId, fromRole, toRole=constants.ROLE_OBSERVER): GroupManagementAction {
    let now = moment().toDate();
    return {type: GRP_MGMT_DEMOTE_USER, problemStepId, userId, lastAction: constants.GRP_MGMT_USER_DEMOTED, requestDate: now, actionDate: now, fromRole, toRole: toRole};
}

export function autoDemoteUserAction(problemStepId, userId, fromRole, toRole=constants.ROLE_OBSERVER): GroupManagementAction {
    let now = moment().toDate();
    return {type: GRP_MGMT_AUTO_DEMOTE_USER, problemStepId, userId, lastAction: constants.GRP_MGMT_USER_AUTO_DEMOTED, requestDate: now, actionDate: now, fromRole, toRole: toRole};
}

export function promoteUserAction(problemStepId, userId, fromRole, toRole): GroupManagementAction {
    let now = moment().toDate();
    return {type: GRP_MGMT_PROMOTE_USER, problemStepId, userId, lastAction: constants.GRP_MGMT_USER_PROMOTED, requestDate: now, actionDate: now, fromRole, toRole};
}

export function getGroupManagementRecordFromStore(store: StoreWithSharedState, problemStepId, userId) {
    return store.sharedState.teams[problemStepId].groupManagement[userId];
}

export function setGroupManagementRecordInStore(store: StoreWithSharedState, problemStepId, userId, groupManagementPojo: SingleUserGroupManagementReducerType) {
    ensureFieldPath(store, 'sharedState', 'teams', problemStepId, 'groupManagement', userId);
    store.sharedState.teams[problemStepId].groupManagement[userId] = {
        ...store.sharedState.teams[problemStepId].groupManagement[userId],
        lastAction: groupManagementPojo.lastAction,
        requestDate: groupManagementPojo.requestDate,
        actionDate: groupManagementPojo.actionDate,
        fromRole: groupManagementPojo.fromRole,
        toRole: groupManagementPojo.toRole
    };
}
