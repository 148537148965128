import {Reducer} from 'redux';

import ensureFieldPath from '../ensureFieldPath';

export type BardTrainingUrlReducerType = string;

const bardTrainingUrlReducer: Reducer<BardTrainingUrlReducerType> = (
    state = (process.env.REACT_APP_LOGIN_PATH === '/' ? process.env.BARD_MOODLE_TRAINING_URL_LOCAL : process.env.BARD_MOODLE_TRAINING_URL_TNE) || ''
) => {
    return state;
};

export default bardTrainingUrlReducer;

export function getBardTrainingUrlFromStore(store) {
    return store.sharedState.bardTrainingUrl;
}

export function setBardTrainingURLInStore(store, bardTrainingUrl) {
    ensureFieldPath(store, 'sharedState');
    store.sharedState.bardTrainingUrl = bardTrainingUrl;
}