import {StoreWithSharedState} from './sharedStateReducer';
import {AllTeamsReducerType, CombinedTeamReducerType, SingleProblemStepType} from './allTeamsReducer';

// ======== extracted getter functions for allTeamsReducer, to break circular import

export function getTeamFromStore(store: StoreWithSharedState, problemStepId: number | string): CombinedTeamReducerType {
    return store.sharedState.teams[problemStepId];
}

export function getProblemStepFromStore(store: StoreWithSharedState, problemStepId: number | string): SingleProblemStepType {
    return store.sharedState.teams[problemStepId].problemStep;
}

export function doesTeamExistInStore(store: StoreWithSharedState, problemStepId: number | string) {
    return !!store.sharedState.teams[problemStepId];
}

export function getAllTeamsFromStore(store: StoreWithSharedState): AllTeamsReducerType {
    return store.sharedState.teams;
}