import React, {Component} from 'react';
import InfiniteScroll from 'react-infinite-scroller';
import moment from 'moment';
import {ExpansionList, ExpansionPanel} from 'react-md';
import {connect} from 'react-redux';

import {UserFeedbackType} from '../../common/clientServer/userFeedbackType';
import DispatchProp from '../../@types/dispatchProp';
import {commonApiExceptionHandled} from '../util/ajaxUtils';
import LoadingBar from './LoadingBar';
import {StoreWithSharedState} from '../../common/reducers/sharedStateReducer';
import {BardUser} from '../../common/clientServer/bardUser';
import {getAllUsersFromStore} from '../reducers/allUsersReducer';

import '../scss/adminUserFeedbackPanel.scss';

interface AdminUserFeedbackPanelProps extends DispatchProp {
    allUsers: {[id: string]: BardUser};
}

interface AdminUserFeedbackPanelState {
    feedback: UserFeedbackType[];
    hasMore: boolean;
    errorMessage?: string;
}

class AdminUserFeedbackPanel extends Component<AdminUserFeedbackPanelProps, AdminUserFeedbackPanelState> {

    constructor(props) {
        super(props);
        this.fetchPage = this.fetchPage.bind(this);
        this.state = {
            feedback: [],
            hasMore: true
        };
    }

    private async fetchPage(page = 0) {
        const response = await fetch('/api/feedback?page=' + page);
        if (response.ok) {
            const feedback = await response.json();
            this.setState({
                errorMessage: undefined,
                feedback: [...this.state.feedback, ...feedback.rows],
                hasMore: feedback.hasMore
            });
        } else if (!commonApiExceptionHandled(response.status, this.props.dispatch)) {
            this.setState({errorMessage: 'Error fetching feedback: ' + response.statusText});
        }
    }

    private renderUserDetails(feedback: UserFeedbackType): React.ReactElement {
        const user = this.props.allUsers[feedback.userId];
        if (user && user.emailAddress !== user.loginName) {
            return (
                <>
                    <div className='narrowColumn'>{feedback.loginName}</div>
                    <div className='narrowColumn'>{user.emailAddress}</div>
                </>
            );
        } else {
            return <div className='wideColumn'>{feedback.loginName}</div>;
        }
    }

    render() {
        return (
            <div className='adminConsole'>
                <div className='majorTitle'>User Feedback</div>
                {
                    this.state.errorMessage ? <div className='errorStyle'>{this.state.errorMessage}</div> : null
                }
                <div className='adminConsoleContent'>
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this.fetchPage}
                        hasMore={this.state.hasMore}
                        loader={<LoadingBar key='fetchFeedbackPending' id='fetchFeedbackPending'/>}
                        useWindow={false}
                    >
                        <ExpansionList className='userFeedbackList'>
                            {
                                this.state.feedback.map((feedback) => (
                                    <ExpansionPanel
                                        key={'feedback_' + feedback.id}
                                        label={
                                            <div className='userFeedbackPanel'>
                                                <div className='narrowColumn'>{moment(feedback.updatedAt).format('llll')}</div>
                                                {this.renderUserDetails(feedback)}
                                                <div className='narrowColumn'>{feedback.feedbackType}</div>
                                            </div>
                                        }
                                        footer={null}
                                    >
                                        <div dangerouslySetInnerHTML={{__html: feedback.text || '(none)'}}/>
                                    </ExpansionPanel>
                                ))
                            }
                        </ExpansionList>
                    </InfiniteScroll>
                </div>
            </div>
        );
    }
}

function mapStoreToProps(store: StoreWithSharedState) {
    return {
        allUsers: getAllUsersFromStore(store)
    };
}

export default connect(mapStoreToProps)(AdminUserFeedbackPanel);