import React, {Component} from 'react';
import {connect} from 'react-redux';

import {dismissFirstToastAction, getToastsFromStore} from '../reducers/snackbarToastReducer';
import BardSnackbar from '../presentation/BardSnackbar';

/**
 * This component is already in App.tsx, so you can display toast messages by simply dispatching the action
 * snackbarToastReducer.addToastMessageAction(message) to pop up a toast!
 */
class BardSnackbarContainer extends Component {
    render() {
        return (
            <BardSnackbar id='bardSnackbarContainer' toasts={this.props.toasts} onDismiss={() => {
                this.props.dispatch(dismissFirstToastAction());
            }}/>
        );
    }
}

function mapStoreToProps(store) {
    return {
        toasts: getToastsFromStore(store)
    }
}

export default connect(mapStoreToProps)(BardSnackbarContainer);