import React, {Component} from 'react';
import * as PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {indexOf} from 'lodash';

import {getFeatureFlagsFromStore} from '../../common/reducers/featureFlagsReducer';
import ReferenceData from '../../common/util/referenceData';
import * as featureFlags from '../../common/util/featureFlags';
import {getUserFromStore} from '../reducers/loginReducer';

/**
 * The flag package comes with its own ConnectedFlagsProvider, but it's hard-coded to expect the flags to be in
 * store.flags, so we couldn't put them in store.sharedState.  Also, the package depends on react, so couldn't be added
 * on the server to provide access to the reducer and action builder.  Hence, I've rolled our own.
 */
class BardFeatureFlagsProvider extends Component {

    static CONTEXT_NAME = 'bard-feature-flag-context';

    static childContextTypes = {
        [BardFeatureFlagsProvider.CONTEXT_NAME]: PropTypes.object
    };

    static getDerivedStateFromProps(props, state) {
        if (props.flags !== state.flags) {
            ReferenceData.getInstance().updateFeatureFlags(props.flags);
            return {flags: props.flags};
        } else {
            return null;
        }
    }

    constructor(props) {
        super(props);
        this.state = {flags: props.flags};
        // Perform one-off client-side feature flag initialisation here.
        featureFlags.mergeFlagFunctions({
            [featureFlags.FlagType.boolean]: {
                [featureFlags.BOOLEAN_USERNAME]: {
                    flagValue: (flag, store) => {
                        if (typeof(flag.value.data) !== 'string') {
                            return false;
                        }
                        const user = getUserFromStore(store);
                        const loginName = user.loginName.toLowerCase();
                        const list = flag.value.data.toLowerCase().split(/\s*,\s*/);
                        return indexOf(list, loginName) >= 0;
                    }
                }
            }
        });
    }

    getChildContext() {
        return {
            [BardFeatureFlagsProvider.CONTEXT_NAME]: this.props.flags
        }
    }

    render() {
        return (
            React.Children.only(this.props.children)
        );
    }
}

function mapStoreToProps(store) {
    return {
        flags: getFeatureFlagsFromStore(store)
    }
}

export default connect(mapStoreToProps)(BardFeatureFlagsProvider);