import React, {Component} from 'react';
import * as PropTypes from 'prop-types';
import {DialogContainer} from 'react-md';
import IconLabelButton from './IconLabelButton';

class NLGComponent extends Component {

    static propTypes = {
        exploreModel: PropTypes.object.isRequired,
        currentScenarioId: PropTypes.string.isRequired
    };

    state = {
        title: true,
        footer: true,
        width: '45%',
        visible: false
    };

    show = () => {
        this.setState({visible: true});
    };

    hide = () => {
        this.setState({visible: false});
    };

    showExplanation(scenario) {
        if (!scenario || !scenario.explanation || Object.keys(scenario.explanation).length === 0) {
            return 'No Explanation';
        } else if (scenario.explanation.error) {
            return (<div className='errorStyle'>{scenario.explanation.error}</div>)
        } else if (scenario.explanation.short) {
            return (<div className='summaryExplanationText'>{scenario.explanation.short}</div>)
        } else {
            return null;
        }
    }

    render() {
        const scenario = this.props.exploreModel.scenario[this.props.currentScenarioId];
        const {visible, width} = this.state;
        const actions = [{
            onClick: this.hide,
            primary: true,
            children: 'Close',
        }];

        return (
            <div>
                <div className='title summaryExplanation'>
                    <span>Summary Explanation</span>
                    <IconLabelButton className='bardButton'
                                     iconChildren='web_asset'
                                     label='Detailed Explanation'
                                     disabled={!scenario || !scenario.explanation || !scenario.explanation.long}
                                     onClick={this.show}
                    />
                </div>
                {this.showExplanation(scenario)}
                <div>
                    <DialogContainer
                        id='detailedExplanation'
                        visible={visible}
                        title='Detailed Explanation'
                        className='nlg-long-description'
                        onHide={this.hide}
                        aria-describedby='detailed-explanation-description'
                        modal
                        width={width}
                        actions={actions}
                        children={(<div className="nlg-long-explanation" dangerouslySetInnerHTML={{__html: scenario && scenario.explanation && scenario.explanation.long}}/>)}
                    >
                    </DialogContainer>
                </div>
            </div>
        );
    }
}

export default NLGComponent;