// read this later: https://bl.ocks.org/mbostock/1705868
// use two points to approximate tangent vector, and use that
// to construct a normal vector. We need to use refs for this

import React, { Component } from "react";
import PropTypes from "prop-types";
import sizeMe from "react-sizeme";
import { detect } from "detect-browser";

// eslint-disable-next-line no-unused-vars
import { ReactSVGPanZoom } from "react-svg-pan-zoom";

// eslint-disable-next-line no-unused-vars
import { times, clamp, isEqual, flatMap } from "lodash";

import * as cola from "webcola";
import * as constants from "../../../common/util/constants";

// Local components
import ArcCircle from "./ArcCircle";
import CoLaNode from "./CoLaNode";
import CoLaLink from "./CoLaLink";
import SVGText from "./SVGText";
import DeleteButton from "./DeleteButton";
import SVGDefs from "./SVGDefs";
import Menu from "./Menu";
import Vector from "./Vector";
import Label from "./Label";
import EditLabelButton from "./EditLabelButton";

import connectCursor from "../../images/connect.svg";
import "../../scss/CoLaNetworkVisComponent.scss";

const KEY_CODE_DELETE = 46;
const KEY_CODE_BACKSPACE = 8;
const KEY_CODE_ESC = 27;
//const KEY_CODE_ENTER = 13;

// Polyfill for IE11, which is missing String.startsWith
if (!String.prototype.startsWith) {
    // eslint-disable-next-line no-extend-native
    String.prototype.startsWith = function(searchString, position) {
        if (position === undefined) {
            position = 0;
        }
        return this.substr(position, searchString.length) === searchString;
    };
}


// From typescript generated code
//
var __extends =
    (this && this.__extends) ||
    (function() {
        var extendStatics =
            Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array &&
                function(d, b) {
                    d.__proto__ = b;
                }) ||
            function(d, b) {
                for (var p in b) if (b.hasOwnProperty(p)) d[p] = b[p];
            };
        return function(d, b) {
            extendStatics(d, b);
            function __() {
                this.constructor = d;
            }
            d.prototype =
                b === null
                    ? Object.create(b)
                    : ((__.prototype = b.prototype), new __());
        };
    })();

// Subclass in typescript-generated style, allowing overriding of protected methods.
const ControllableLayout = (function(_super) {
    __extends(ControllableLayout, _super);

    function ControllableLayout() {
        return _super.call(this) || this;
    }

    ControllableLayout.prototype.loop = function() {
        if (!_super.prototype.tick.call(this)) {
            requestAnimationFrame(this.loop.bind(this));
        }
    };

    // Start layout.
    ControllableLayout.prototype.kick = function() {
        requestAnimationFrame(this.loop.bind(this));
    };

    return ControllableLayout;
})(cola.Layout);

const SHORTEN_NONE = 0;
const SHORTEN_START = 2 ** 0;
const SHORTEN_END = 2 ** 2;
const SHORTEN_BOTH = SHORTEN_START | SHORTEN_END;

// shortenLine:
//     Given the two endpoints of a line segment, this function adjusts the
//     endpoints of the line to shorten the line by shortenDist at either
//     or both ends.
//
const shortenLine = (p1, p2, mode, shortenDist) => {
    if (mode === SHORTEN_NONE || shortenDist === 0) {
        return;
    }

    const rise = p1.y - p2.y;
    const run = p1.x - p2.x;
    const disty = Math.abs(rise);
    const distx = Math.abs(run);

    // Handle case where shorten length is greater than the length of the
    // line segment.
    if (
        mode === SHORTEN_BOTH &&
        ((distx > disty && shortenDist * 2 > distx) ||
            (disty >= distx && shortenDist * 2 > disty))
    ) {
        // Shorten both points to the centre point of the line.
        p1.x = p2.x = p1.x - run / 2;
        p1.y = p2.y = p1.y - rise / 2;
        return;
    } else if (
        mode === SHORTEN_START &&
        ((distx > disty && shortenDist > distx) ||
            (disty >= distx && shortenDist > disty))
    ) {
        // Shorten point to other endpoint.
        p1.x = p2.x;
        p1.y = p2.y;
        return;
    } else if (
        mode === SHORTEN_END &&
        ((distx > disty && shortenDist > distx) ||
            (disty >= distx && shortenDist > disty))
    ) {
        // Shorten point to other endpoint.
        p2.x = p1.x;
        p2.y = p1.y;
        return;
    }

    // Handle orthogonal line segments.
    if (p1.x === p2.x) {
        // Vertical
        const sign = p1.y < p2.y ? 1 : -1;

        if ((mode & SHORTEN_START) !== 0) {
            p1.y += sign * shortenDist;
        }
        if ((mode & SHORTEN_END) !== 0) {
            p2.y -= sign * shortenDist;
        }
        return;
    } else if (p1.y === p2.y) {
        // Horizontal
        const sign = p1.x < p2.x ? 1 : -1;

        if ((mode & SHORTEN_START) !== 0) {
            p1.x += sign * shortenDist;
        }
        if ((mode & SHORTEN_END) !== 0) {
            p2.x -= sign * shortenDist;
        }
        return;
    }

    const xpos = p1.x < p2.x ? -1 : 1;
    const ypos = p1.y < p2.y ? -1 : 1;

    const tangent = rise / run;

    if ((mode & SHORTEN_END) !== 0) {
        if (disty > distx) {
            p2.y += shortenDist * ypos;
            p2.x += shortenDist * ypos * (1 / tangent);
        } else if (disty < distx) {
            p2.y += shortenDist * xpos * tangent;
            p2.x += shortenDist * xpos;
        }
    }

    if ((mode & SHORTEN_START) !== 0) {
        if (disty > distx) {
            p1.y -= shortenDist * ypos;
            p1.x -= shortenDist * ypos * (1 / tangent);
        } else if (disty < distx) {
            p1.y -= shortenDist * xpos * tangent;
            p1.x -= shortenDist * xpos;
        }
    }
};

const mid = (a, b) => (a < b ? a + (b - a) / 2 : b + (a - b) / 2);

// This rounds edge paths by adding bezier control points that create
// a smooth rounding on each corner.
//
// I:  - point (array) [x,y]: current point coordinates
//     - i (integer): index of 'point' in the array 'a'
//     - a (array): complete array of points coordinates
// O:  - (string) 'C x2,y2 x1,y1 x,y': SVG cubic bezier C command
const roundCommand = (point, i, a) => {
    let p1 = { x: a[i - 1].x, y: a[i - 1].y };
    let p2 = { x: point.x, y: point.y };

    let mode = SHORTEN_BOTH;
    if (i === 1) {
        mode = SHORTEN_END;
    } else if (i + 1 === a.length) {
        mode = SHORTEN_START;
    }

    const shortenDist = CoLaNetworkVisComponent.nodePadding * 1.5;
    shortenLine(p1, p2, mode, shortenDist);

    let result = "";
    if (i > 1) {
        // Complete curve.
        let cp = { x: mid(p1.x, a[i - 1].x), y: mid(p1.y, a[i - 1].y) };
        result += `${cp.x},${cp.y} ${p1.x},${p1.y} `;
    }

    if (i + 1 === a.length) {
        // Last segment, so don't shorten.
        if ((point.x !== p1.x && point.y !== p1.y) || i === 1) {
            // But only display last seqment if non-zero length (since
            // otherwise the segment would be completed by bezier), or if
            // the edge only has a single segment (so there is no bezier).
            result += `L ${point.x},${point.y} `;
        }
    } else {
        result += `L ${p2.x},${p2.y} `;
        // Begin curve.
        let cp = { x: mid(p2.x, point.x), y: mid(p2.y, point.y) };
        result += `C ${cp.x},${cp.y} `;
    }

    return result;
};

// Render the svg <path> element
// I:  - points (array): points coordinates
//     - command (function)
//       I:  - point (array) [x,y]: current point coordinates
//           - i (integer): index of 'point' in the array 'a'
//           - a (array): complete array of points coordinates
//       O:  - (string) a svg path command
// O:  - (string): a Svg <path> element
const svgPathD = (points, command) => {
    // build the D attributes by looping over the points
    return points.reduce(
        (acc, point, i, a) =>
            i === 0
                ? `M ${point.x},${point.y} `
                : `${acc} ${command(point, i, a)}`,
        ""
    );
};

export const lineFunction = (points, offset) => {
    const transformedPoints = points.map(({ x, y }) => ({
        x: x + offset.x,
        y: y + offset.y
    }));

    return {
        points: transformedPoints,
        d: svgPathD(transformedPoints, roundCommand)
    };
};

// Direction from vector.
// Looks at the position of point c from the directed segment ab and
// returns the following:
//      1   counterclockwise
//      0   collinear
//     -1   clockwise
//
// The 'maybeZero' argument can be used to adjust the tolerance of the
// function.  It will be most accurate when 'maybeZero' == 0.0, the default.
//
const vecDir = (a, b, c, maybeZero = 0.0) => {
    console.assert(maybeZero >= 0);

    const area2 = (b.x - a.x) * (c.y - a.y) - (c.x - a.x) * (b.y - a.y);

    if (area2 < -maybeZero) {
        return -1;
    } else if (area2 > maybeZero) {
        return 1;
    }
    return 0;
};

/**
 * BN renderer component using WebCoLa. This implementation allows users to add and delete arcs between
 * nodes and reposition nodes, as long as the readOnly prop is false.
 *
 * Trying out somewhat similar to this approach: http://bl.ocks.org/sxywu/61a4bd0cfc373cf08884
 *
 * @property graphDescription {object} The BN node and arc data for this component to display.
 * @property size {{width, height}} The pixel width and height of the parent container for this component.  This
 * implementation uses react-sizeme to calculate this information.
 * @property readOnly {boolean} (optional, default false) If true, all UI tools to modify the BN should be disabled.
 * @property alwaysPerformForceDirectedLayout {boolean} (optional, default true) If true, BN layout always performs force directed layout.
 * Otherwise force directed layout gets disabled when graph converges, and then can only be re-enabled by clicking a button.
 * @property draggableNodes {boolean} (optional, default true) If true, nodes can be dragged around.
 * @property onChange {function} (optional) Function to call with updated BN data.  Will be merged with graphDescription.
 * @property onNodeSelected {function} (optional) If provided, a user clicking on a node will invoke this function with
 * the variableId of the selected node passed as the only parameter.
 * @property isAcyclic {boolean} (optional, default true) If true, the UI will not allow the user to add arcs which
 * create cycles.
 * @property styles {object} (optional) This object may contains keys equal to variableIds in the BN.  The values will
 * be merged with the SVG attributes of the corresponding nodes, allowing customisation of stroke, fill, visibility, etc.
 * @property focusNodes {string[]} (optional) If provided, the variableIds listed in this array indicate which nodes
 * the component should ensure are visible (potentially panning or zooming as appropriate).
 * @property customNodeOverlayFunction {(node: Node) => ReactComponent} (optional) Renders component above node from top left position.
 * @property customNodeDrawingSVGFunction {(node: Node, size: {width, height}, nodeStyle: string) => ReactElement} (optional) Returns custom SVG to draw node.
 * @property isValidConnection {(fromNodeId: string, toNodeId: string) => boolean} (optional) Given the node ids for a pair of nodes
 * the user is creating an edge between, returns a boolean denoting whether an edge between them should be allowed.
 */
export class CoLaNetworkVisComponent extends Component {
    static BNVariablePropType = PropTypes.shape({
        attributes: PropTypes.shape({
            state: PropTypes.objectOf(
                // keys are state names
                PropTypes.shape({
                    stateName: PropTypes.string,
                    rationale: PropTypes.string,
                    description: PropTypes.string
                })
            ),
            order: PropTypes.arrayOf(PropTypes.string)
        }),
        data: PropTypes.shape({
            name: PropTypes.string,
            type: PropTypes.string,
            rationale: PropTypes.string,
            description: PropTypes.string,
            fixedValues: PropTypes.array,
            isTargetVariable: PropTypes.bool,
            label: PropTypes.string,
            maxStates: PropTypes.number,
            minStates: PropTypes.number
        })
    });

    static BNGraphPropType = PropTypes.shape({
        nodes: PropTypes.shape({
            variable: PropTypes.objectOf(
                // variable IDs
                CoLaNetworkVisComponent.BNVariablePropType
            ),
            order: PropTypes.arrayOf(PropTypes.string),
            timestamp: PropTypes.string
        }),
        /** Connections between BN variable nodes **/
        connections: PropTypes.arrayOf(
            PropTypes.shape({
                source: PropTypes.string.isRequired,
                target: PropTypes.string.isRequired,
                label: PropTypes.string
            })
        ),
        /** Where are BN variable nodes positioned **/
        coords: PropTypes.objectOf(
            // variable IDs
            PropTypes.shape({
                x: PropTypes.number,
                y: PropTypes.number
            })
        ),
        timestamp: PropTypes.string
    }).isRequired;

    static propTypes = {
        graphDescription: CoLaNetworkVisComponent.BNGraphPropType,
        size: PropTypes.shape({
            width: PropTypes.number,
            height: PropTypes.number
        }).isRequired,
        readOnly: PropTypes.bool,
        alwaysPerformForceDirectedLayout: PropTypes.bool,
        draggableNodes: PropTypes.bool,
        onChange: PropTypes.func,
        onNodeSelected: PropTypes.func,
        isAcyclic: PropTypes.bool,
        styles: PropTypes.objectOf(
            PropTypes.shape({
                fill: PropTypes.string,
                stroke: PropTypes.string
            })
        ),
        focusNodes: PropTypes.arrayOf(PropTypes.string),
        customNodeOverlayFunction: PropTypes.func,
        customNodeDrawingSVGFunction: PropTypes.func,
        isValidConnection: PropTypes.func
    };

    static noopFunction = () => {};

    static defaultProps = {
        readOnly: false,
        isAcyclic: true,
        alwaysPerformForceDirectedLayout: false,
        draggableNodes: true,
        onChange: CoLaNetworkVisComponent.noopFunction,
        /* Example for testing custom node drawing with Artful Deception problem.
        customNodeDrawingSVGFunction: (node, size, nodeStyle) => {
            // Return null to use default node drawing.

            if (node.data.variableType === "1_target") {
                // Use default node
                const rounding = size.height / 2;
                const element = <rect className="nodeRect"
                        rx={rounding} ry={rounding}
                        x={-size.width / 2} y={-size.height / 2}
                        width={size.width} height={size.height}
                        style={nodeStyle} />;

                return element;
            }
            else if (node.data.type === "Binary") {
                // Rect
                const element = <rect className="nodeRect"
                        x={-size.width / 2} y={-size.height / 2}
                        width={size.width} height={size.height}
                        style={nodeStyle} />;
                return element;
            }
            else {
                // Choice
                const left = -size.width / 2;
                const top = -size.height / 2;
                const right = size.width / 2;
                const bottom = size.height / 2;
                const halfHeight = bottom / 2;

                class Point {
                    constructor(x, y)
                    {
                        this.x = x;
                        this.y = y;
                    }

                    toString() {
                        return String(this.x) + "," + String(this.y);
                    }
                }

                const points = [
                    new Point(left, 0),
                    new Point((left + halfHeight), top),
                    new Point((right - halfHeight), top),
                    new Point(right, 0),
                    new Point((right - halfHeight), bottom),
                    new Point((left + halfHeight), bottom)
                ]

                const element = <polygon className="nodeChoice"
                        points={points.join(" ")} style={nodeStyle} />;
                return element;
            }
        },
        */
        styles: {}
    };

    // Canvas
    static canvasWidth = 8000;
    static canvasHeight = 8000;

    static nodeRectRadius = 10;
    static nodeRectWidth = 300;
    static nodeRectHeight = 40;

    static nodeRectHorizontalPadding = 20;
    static nodeRectShrinkToFit = true;
    static nodeRectMinWidth = 60;

    // SVG positions markers (used for arrowheads) with their centre at the
    // end of the stroke, so we need to shorten the stroke by this much so
    // the arrowhead ends at the edge of ndoes.
    static arrowLength = 15;

    static flowLayoutMinSeparation = CoLaNetworkVisComponent.nodeRectHeight +
        50;

    static nodeColour = constants.colourLightBlueGrey;
    static arcColour = constants.colourLightBlueGrey;

    static highlightColour = constants.colourPaleBlueGrey;
    static highlightRadius = 10;

    static tierColour = constants.colourPaleBlueGrey;

    static textShadowColour = "white";
    static textColour = constants.colourTextGrey;

    // Space added to each edge of each node for non-overlap purposes.
    static nodePadding = 15;
    static allowNodeOverlapsDuringDrag = true;

    // Space around nodes for edge routing.  This should be between zero and
    // the nodePadding value.
    static routingPaddingReduction = 1;

    constructor(props) {
        super(props);

        this.state = {
            // Used to keep track of the arrow being created with a mouse
            mouseArrowTail: null,
            mouseArrowHead: null,
            // Keeps track which link is currently selected (optional)
            selectedLink: null,
            // Position for where the add arrow circle should be displayed
            mouseArrowCircleIndicator: null,
            // is the mouse button being pressed
            isMouseDown: false,
            // Whether or not to perform force directed layout
            // TODO: We could check if nodes given to the component have no positions
            //       and there are links, and could initially set this to true in
            //       that case (in order to obtain a reasonable initial layout).
            performForceDirectedLayout: this.props
                .alwaysPerformForceDirectedLayout,
            editInputValue: "",
            currentlyEditingLabel: null
        };

        this.nodes = null;
        this.links = null;

        // Assists with differentiating between single and double clicks on labels.
        this.labelClickTimer = null;

        this.browser = detect();

        // Track whether layout has been triggered by local user actions,
        // so we can ignore prop updates that result from our own actions.
        this.seenLocalUserAction = false;

        // Start WebCoLa
        this.restartGraph(this.props, false);
    }

    componentDidMount() {
        window.addEventListener("keyup", this.handleKeyUp);

        this.zoomToFitContent();
    }

    componentWillUnmount() {
        window.removeEventListener("keyup", this.handleKeyUp);

        this.cola.stop();

        if (this.seenLocalUserAction) {
            // This represents the rare case where the user has triggered
            // layout by dragging a node but the layout has not yet completed
            // and hence the properties haven't been updated with new node
            // positions.  Hence, store the state.
            this.updateGraphState(this.getCoordsForAllNodesFromWebCoLa());
        }
    }

    handleKeyUp = e => {
        // The Delete key on MacOS returns the backspace code, so handle both.
        if (
            (e.keyCode === KEY_CODE_DELETE ||
                e.keyCode === KEY_CODE_BACKSPACE) &&
            this.state.selectedLink != null && this.state.currentlyEditingLabel == null
        ) {
            // Remove the selected connection
            this.removeConnections([this.state.selectedLink]);
        }
    };

    handleMouseDown = () => {
        // Unselect everything when clicking on the canvas.
        // Clicks on objects don't propogate to here.
        this.unselectEverything();

        this.setState({
            isMouseDown: true
        });
    };

    handleMouseUp = () => {
        this.setState({
            isMouseDown: false
        });
    };

    findBoundingBox = (nodesById, spaceAround) => {
        let minX = 0,
            minY = 0,
            maxX = 0,
            maxY = 0;

        nodesById.forEach(nodeId => {
            const node = this.getNode(nodeId);

            if (node != null) {
                if (node.dom != null) {
                    const { x, y, width, height } = node.dom.getBBox();
                    minX = Math.min(minX, x);
                    minY = Math.min(minY, y);
                    maxX = Math.max(maxX, x + width);
                    maxY = Math.max(maxY, y + height);
                } else {
                    // use node's position and dimensions as fallback
                    minX = Math.min(minX, node.x);
                    minY = Math.min(minY, node.y);
                    maxX = Math.max(maxX, node.x + node.width);
                    maxY = Math.max(maxY, node.y + node.height);
                }
            }
        });

        return {
            minX: minX - spaceAround,
            minY: minY - spaceAround,
            maxX: maxX + spaceAround,
            maxY: maxY + spaceAround
        };
    };

    zoomToFitContent(props = this.props) {
        let { focusNodes } = props;

        if (!focusNodes || focusNodes.length === 0) {
            // Focus on all nodes by default (only when component mounts)
            focusNodes = props.graphDescription.nodes.order;
        }

        this.focusOnNodes(focusNodes);
    }

    focusOnNodes = focusNodes => {
        if (focusNodes && focusNodes.length > 0) {
            const viewBox = this.findBoundingBox(focusNodes, 50);

            if (!this.Viewer) {
                // for testing component with shallow rendering
                return;
            }

            // Center align
            this.Viewer.setPointOnViewerCenter(
                CoLaNetworkVisComponent.canvasWidth / 2 +
                        (viewBox.minX + (viewBox.maxX - viewBox.minX) / 2),
                CoLaNetworkVisComponent.canvasHeight / 2 +
                        (viewBox.minY + (viewBox.maxY - viewBox.minY) / 2),
                Math.min(
                    1, // Minimum default zoom level is set at 100%
                    Math.min(
                        this.props.size.width / (viewBox.maxX - viewBox.minX),
                        this.props.size.height / (viewBox.maxY - viewBox.minY)
                    )
                )
            );
        }
    };

    /**
     * Removes specified connections from WebCoLa.
     *
     * @param connections Which connections to remove from graph.
     */
    removeConnections = connections => {
        this.updateGraphState({
            connections: this.props.graphDescription.connections.filter(
                connection =>
                    connections.every(
                        otherConnection =>
                            otherConnection.source !== connection.source ||
                            otherConnection.target !== connection.target
                    )
            ),
            ...this.getCoordsForAllNodesFromWebCoLa()
        });

        // Unselect everything
        this.unselectEverything();
    };

    /**
     * Returns true if anything is selected. Currently only one link can
     * be selected at a time.
     */
    isAnythingSelected = () => this.state.selectedLink != null || this.state.currentlyEditingLabel != null;

    /**
     * Checks to see if a link has been selected or not.
     *
     * @param link Link which is checked to see if it has been selected or not.
     */
    isLinkSelected = link =>
        this.state.selectedLink != null &&
        this.state.selectedLink.source === link.source &&
        this.state.selectedLink.target === link.target;

    unselectEverything = () => {
        if (this.isAnythingSelected()) {
            this.setState({
                selectedLink: null,
                currentlyEditingLabel: null
            });
        }
    };

    UNSAFE_componentWillReceiveProps(nextProps) {
        if (
            !isEqual(
                this.props.graphDescription.nodes.order,
                nextProps.graphDescription.nodes.order
            ) ||
            !isEqual(
                this.props.graphDescription.connections,
                nextProps.graphDescription.connections
            )
        ) {
            this.restartGraph(nextProps, false);
        }

        if (!isEqual(this.props.focusNodes, nextProps.focusNodes)) {
            this.focusOnNodes(nextProps.focusNodes);
        }

        /* If container specifies different initial coordinates, and it does not come from us
       (by checking against WebCoLa's snapshot coordinates before notifying container) */
        if (
            (this.snapshotGraphDesc == null ||
                !isEqual(
                    this.snapshotGraphDesc.coords,
                    nextProps.graphDescription.coords
                )) &&
            !isEqual(
                this.props.graphDescription.coords,
                nextProps.graphDescription.coords
            )
        ) {
            // update coordinates
            this.nodes.forEach(node => {
                if (nextProps.graphDescription.coords[node.id] != null) {
                    node.px = node.x =
                        nextProps.graphDescription.coords[node.id].x;
                    node.py = node.y =
                        nextProps.graphDescription.coords[node.id].y;
                }
            });

            // Trigger layout and connector routing.
            this.cola.start(10, 20, 20);

            this.zoomToFitContent(nextProps);
        }
    }

    /**
     * Calculate an ideal width for nodes based on their label text size.
     */
    calcNodeWidth(node, key) {
        if (CoLaNetworkVisComponent.nodeRectShrinkToFit === false) {
            return CoLaNetworkVisComponent.nodeRectWidth;
        }

        const textStyle = CoLaNetworkVisComponent.separateNodeAndTextStyles(this.props.styles[key]).textStyle;

        let maxNodeWidth = CoLaNetworkVisComponent.nodeRectWidth;

        const {
            wordsWithComputedDimensions,
            spaceWidth,
            ellipsisWidth,
            hyphenWidth
        } = SVGText.calculateWordDimensions(
            node.data.name,
            textStyle,
            maxNodeWidth,
            CoLaNetworkVisComponent.nodeRectHorizontalPadding
        );

        const overallWidth = SVGText.calculateLines(
            wordsWithComputedDimensions,
            spaceWidth,
            maxNodeWidth,
            ellipsisWidth,
            hyphenWidth,
            CoLaNetworkVisComponent.nodeRectHorizontalPadding,
            CoLaNetworkVisComponent.nodeRectHeight
        ).overallWidth;

        let nodeWidth = overallWidth + 1 + (2 * CoLaNetworkVisComponent.nodeRectHorizontalPadding);

        // Fit within limits.
        nodeWidth = Math.min(nodeWidth, CoLaNetworkVisComponent.nodeRectWidth);
        nodeWidth = Math.max(nodeWidth, CoLaNetworkVisComponent.nodeRectMinWidth);

        return nodeWidth;
    }


    /**
     * Calculates width of a label based on the label text.  Used to
     * render link labels as SVGText.  Very similar to calcNodeWidth().
     * Currently uses node values.
     * @param {string} labeltext - the label text.
     */
    calcLabelWidth(labeltext) {
        const {
            wordsWithComputedDimensions,
            spaceWidth,
            ellipsisWidth,
            hyphenWidth
        } = SVGText.calculateWordDimensions(
            labeltext,
            {fill: "black"},
            CoLaNetworkVisComponent.nodeRectWidth,
            CoLaNetworkVisComponent.nodeRectHorizontalPadding
        );

        const overallWidth = SVGText.calculateLines(
            wordsWithComputedDimensions,
            spaceWidth,
            CoLaNetworkVisComponent.nodeRectWidth,
            ellipsisWidth,
            hyphenWidth,
            CoLaNetworkVisComponent.nodeRectHorizontalPadding,
            CoLaNetworkVisComponent.nodeRectHeight
        ).overallWidth;

        let labelWidth = overallWidth + 1 + (2 * CoLaNetworkVisComponent.nodeRectHorizontalPadding);

        // Fit within limits.
        labelWidth = Math.min(labelWidth, CoLaNetworkVisComponent.nodeRectWidth);
        labelWidth = Math.max(labelWidth, CoLaNetworkVisComponent.nodeRectMinWidth);

        return labelWidth;
    }

    /**
     * (Re)starts the WebCoLa instance with a graph specified by
     * a certain data structure (as specified in the prop types).
     */
    restartGraph({ graphDescription }, triggeredByLocalUserAction) {
        // Create a deep copy of variables and coords
        const variables = {};

        // If this call was triggered by a local user action, remember this.
        this.seenLocalUserAction =
            this.seenLocalUserAction || triggeredByLocalUserAction;

        graphDescription.nodes.order.forEach((key, index) => {
            if (!(key in graphDescription.nodes.variable)) return;

            // We want there to be some separation between nodes, so we pass WebCola
            // nodes sizes with padding.  (We then need to use the innerBounds
            // property of each node for it's actual dimensions).
            variables[key] = {
                id: key,
                index: index + 2, // needed for applying constraints when force layout is disabled
                width:
                    this.calcNodeWidth(graphDescription.nodes.variable[key], key) +
                    CoLaNetworkVisComponent.nodePadding * 2,
                height:
                    CoLaNetworkVisComponent.nodeRectHeight +
                    CoLaNetworkVisComponent.nodePadding * 2,
                ...graphDescription.nodes.variable[key],
                ...(graphDescription.coords[key] || {})
            };
        });

        // Two nodes used to set boundary constraints
        // (prevents nodes from going outside of the canvas)
        const topLeftBoundaryConstraintNode = {
            x:
                -CoLaNetworkVisComponent.canvasWidth / 2,
            y:
                -CoLaNetworkVisComponent.canvasHeight / 2,
            index: 0, // needed for applying constraints when force layout is disabled
            fixed: true,
            width: 1,
            height: 1,
            fixedWeight: 1e6
        };

        const bottomRightBoundaryConstraintNode = {
            x: CoLaNetworkVisComponent.canvasWidth / 2,
            y: CoLaNetworkVisComponent.canvasHeight / 2,
            index: 1, // needed for applying constraints when force layout is disabled
            width: 1,
            height: 1,
            fixed: true,
            fixedWeight: 1e6
        };

        this.nodes = graphDescription.nodes.order.map(key => variables[key]);

        const topLeftBoundaryConstraintNodeIndex = 0;
        const bottomRightBoundaryConstraintNodeIndex = 1;

        const boundaryConstraints = flatMap(
            times(this.nodes.length, i => [
                {
                    axis: "x",
                    type: "separation",
                    left: topLeftBoundaryConstraintNodeIndex,
                    right: i + 2,
                    gap: CoLaNetworkVisComponent.nodeRectWidth / 2
                },
                {
                    axis: "y",
                    type: "separation",
                    left: topLeftBoundaryConstraintNodeIndex,
                    right: i + 2,
                    gap: CoLaNetworkVisComponent.nodeRectHeight / 2
                },
                {
                    axis: "x",
                    type: "separation",
                    left: i + 2,
                    right: bottomRightBoundaryConstraintNodeIndex,
                    gap: CoLaNetworkVisComponent.nodeRectWidth / 2
                },
                {
                    axis: "y",
                    type: "separation",
                    left: i + 2,
                    right: bottomRightBoundaryConstraintNodeIndex,
                    gap: CoLaNetworkVisComponent.nodeRectHeight / 2
                }
            ])
        );

        this.links = graphDescription.connections.map(link => ({
            source: variables[link.source],
            target: variables[link.target],
            label: link.label
        }));

        if (this.cola) {
            this.cola.stop();
        }

        this.cola = new ControllableLayout()
            .nodes([
                topLeftBoundaryConstraintNode,
                bottomRightBoundaryConstraintNode,
                ...this.nodes
            ])
            .links(this.state.performForceDirectedLayout ? this.links : [])
            .avoidOverlaps(true)
            .flowLayout("y", CoLaNetworkVisComponent.flowLayoutMinSeparation)
            .symmetricDiffLinkLengths(80)
            .handleDisconnected(false);

        let constraints = boundaryConstraints;

        if (!this.state.performForceDirectedLayout) {
            /**
             * Internally call flow layout constraints code without actually including any
             * links in WebCoLa.
             *
             * In the future, there should be a method in WebCoLa that disables
             * force layout, like cola.disableForceLayout()
             */
            this._directedLinkConstraints = {
                axis: "y",
                getMinSeparation: () =>
                    CoLaNetworkVisComponent.flowLayoutMinSeparation
            };

            this.cola.linkAccessor.getMinSeparation = this.cola._directedLinkConstraints.getMinSeparation;
            constraints = constraints.concat(
                cola.generateDirectedEdgeConstraints(
                    this.nodes.length + 2,
                    this.links,
                    this.cola._directedLinkConstraints.axis,
                    this.cola.linkAccessor
                )
            );
        }

        this.cola.constraints(constraints).start(10, 20, 20);

        this.cola
            .on("start", () => {
                this.links.forEach(link => {
                    // Remove attribute d if it exists
                    if (link.d) {
                        delete link.d;
                    }
                });
            })
            .on("tick", () => {
                // Update node's innerBounds, i.e., the node size without padding.
                this.nodes.forEach(node => {
                    if (node.bounds) {
                        node.innerBounds = node.bounds.inflate(
                            -CoLaNetworkVisComponent.nodePadding
                        );
                    }
                });

                this.patchLinkUpdateIE();

                // Ensure component updates
                this.forceUpdate();
            })
            .on("end", () => {
                // Take a snapshot of WebCoLa's latest coordinates before notifying the container,
                this.snapshotGraphDesc = this.getCoordsForAllNodesFromWebCoLa();

                if (this.seenLocalUserAction) {
                    // Tell container that coordinates have been changed, button
                    // only do this if the change was triggered by a local user
                    // action.
                    this.updateGraphState(this.snapshotGraphDesc);
                    this.seenLocalUserAction = false;
                }

                // Disable force directed layout if we don't want to run it always
                if (
                    this.state.performForceDirectedLayout &&
                    !this.props.alwaysPerformForceDirectedLayout
                ) {
                    this.zoomToFitContent();

                    this.setState(
                        {
                            performForceDirectedLayout: false
                        },
                        () => this.restartGraph(this.props, false)
                    );
                }

                // if no nodes are being dragged, then perform edge routing
                if (!this.draggingNode) {
                    this.performEdgeRouting();
                    this.patchLinkUpdateIE();
                }
            });
    }

    updateGraphState = newState => {
        if (!this.props.readOnly) {
            this.props.onChange({
                ...this.props.graphDescription,
                ...newState
            });
        }
    };

    addConnection(fromNodeId, toNodeId) {
        let newConnections = {
            connections: [
                ...this.props.graphDescription.connections,
                {
                    source: fromNodeId,
                    target: toNodeId
                }
            ]
        };
        this.updateGraphState(newConnections);
    }

    // IE 11 support
    // Credit: https://stackoverflow.com/questions/15693178/svg-line-markers-not-updating-when-line-moves-in-ie10
    patchLinkUpdateIE = () => {
        if (this.browser.name === "ie") {
            this.links.filter(link => link.dom != null).forEach(link => {
                link.dom.parentNode.insertBefore(link.dom, link.dom);
            });
        }
    };

    performEdgeRouting = () => {
        // Update node's innerBounds, i.e., the node size without padding.
        this.nodes.forEach(node => {
            if (node.bounds) {
                node.innerBounds = node.bounds.inflate(
                    -CoLaNetworkVisComponent.nodePadding
                );
            }
        });

        try {
            // TODO: WebCoLa doesn't allow us to specify the length of arrows
            //       and assumes that they have a length of 5, which cause our
            //       arrow heads to be placed partially under the nodes.
            this.cola.prepareEdgeRouting(
                CoLaNetworkVisComponent.routingPaddingReduction
            );
        } catch (err) {
            console.error(err);
            return;
        }
        try {
            // WebCoLa's standard edges are drawn to the bounds given for each
            // node, but we specify nodes with larger bounds so that they have
            // padding for non-overlap purposes.  Hence we need to recompute the
            // paths for edges to the innerBounds, i.e., the node without the
            // padding.
            this.links.forEach(link => {
                var route = cola.makeEdgeBetween(
                    link.source.innerBounds,
                    link.target.innerBounds,
                    CoLaNetworkVisComponent.arrowLength
                );
                const { points, d } = lineFunction(
                    [route.sourceIntersection, route.arrowStart],
                    {
                        x: 0,
                        y: 0
                    }
                );

                link.d = d;
                link.points = points;
            });

            this.links.forEach(link => {
                // Set attribute d to use this for rendering the path
                let route = this.cola.routeEdge(
                    link,
                    CoLaNetworkVisComponent.arrowLength
                );
                const { d, points } = lineFunction(route, {
                    x: 0,
                    y: 0
                });

                link.d = d;
                link.points = points;
            });
            this.forceUpdate();

        } catch (err) {
            console.error(err);
        }
    };

    /**
     * Gets the latest coordinates of each node given id,
     * and stores it in the coords property.
     */
    getCoordsForAllNodesFromWebCoLa = () => {
        return {
            coords: this.nodes.reduce((coords, node) => {
                coords[node.id] = { x: node.x, y: node.y };
                return coords;
            }, {})
        };
    };

    hashCode(str) {
        let hash = 0;
        for (let index = 0; index < str.length; index++) {
            hash = (hash << 5) - hash + str.charCodeAt(index);
            hash = hash & hash; // Convert to 32bit integer
        }
        return hash;
    }

    getNode(variableId) {
        return this.nodes.find(node => node.id === variableId);
    }

    /**
     * Get node's data and attributes from graphDescription prop, given id. May
     * return undefined.
     *
     * @param {*} variableId
     */
    getVariable(variableId) {
        // Note: May return undefined
        return this.props.graphDescription.nodes.variable[variableId];
    }

    getNodeCoordsFromWebCoLa(variableId) {
        // Return either the saved coords for variableId, or some deterministic value
        if (!this.nodes) return null;
        /* Instead of loading from props, it uses the internal coordinates by searching
       through a list, given a node id. We are using a list as WebCoLa needs a list for
       nodes (correct me if I'm wrong). We could maintain a local dictionary variable that
       holds the latest coordinates (but doesn't inform the container of the latest
       coordinates) */
        const node = this.getNode(variableId);
        const dummyFunc = () => ({
            x:
                this.hashCode(variableId) % this.props.size.width -
                this.props.size.width / 2,
            y:
                this.hashCode(variableId) % this.props.size.height -
                this.props.size.height / 2
        });

        if (node == null || node.x == null || node.y == null) {
            return dummyFunc();
        }

        return { x: node.x, y: node.y };
    }

    areNodesConnected(fromNodeId, toNodeId, visited = {}) {
        if (fromNodeId === toNodeId) {
            // a node is always connected to itself
            return true;
        } else if (visited[fromNodeId]) {
            return false;
        } else {
            visited[fromNodeId] = true;
            let neighbours = this.props.graphDescription.connections.filter(
                ({ source }) => source === fromNodeId
            );
            return neighbours.some(({ target }) => {
                return (
                    target === toNodeId ||
                    this.areNodesConnected(target, toNodeId, visited)
                );
            });
        }
    }

    matchingLink = (node1Id, node2Id) =>
        this.props.graphDescription.connections.findIndex(
            connection =>
                connection.source === node1Id && connection.target === node2Id
        );

    isValidConnection(fromNodeId, toNodeId) {
        // Duplicate links are invalid.   Otherwise, if acyclic === true, check that the new connection won't create a
        // cycle, i.e. that there isn't currently a path from toNodeId back to fromNodeId
        const isDuplicate = this.matchingLink(fromNodeId, toNodeId) !== -1;
        let isValid = !isDuplicate &&
                (!this.props.isAcyclic || !this.areNodesConnected(toNodeId, fromNodeId));
        // If an isValidConnection prop is specified, call that function to
        // check link is a valid one.
        if (isValid && (typeof this.props.isValidConnection === "function") && fromNodeId && toNodeId) {
             isValid = this.props.isValidConnection(fromNodeId, toNodeId);
        }
        return isValid;
    }

    // For showing delete button when a link has been selected
    translateToMiddleOfLink = (
        link,
        sourceNode,
        targetNode,
        normalDistance
    ) => {
        let pos = {};

        if (link.dom) {
            // // get computed points from link
            // const { points = [] } = link;

            // // if no edge routing is not being applied, then points array would be empty
            // if (points.length === 0) {
            //     const { sourceCoords, targetCoords } = this.getLinkPosition({
            //         source: sourceNode,
            //         target: targetNode
            //     });

            //     points[0] = sourceCoords;
            //     points[1] = targetCoords;
            // }
            const length = link.dom.getTotalLength();
            const t = 0.5;
            const small_epsilon = 0.001;
            const large_epsilon = 0.1;

            const middlePoint = new Vector(
                link.dom.getPointAtLength(t * length)
            );

            const slightlyOffMiddlePoint = new Vector(
                link.dom.getPointAtLength((t + small_epsilon) * length)
            );

            const firstPointOfSegment = new Vector(
                link.dom.getPointAtLength((t - large_epsilon) * length)
            );

            const secondPointOfSegement = new Vector(
                link.dom.getPointAtLength((t + large_epsilon) * length)
            );

            // approximate normal vector
            let normalVector = {};
            let tol = 0.01

            if (
                vecDir(
                    firstPointOfSegment,
                    middlePoint,
                    secondPointOfSegement,
                    tol
                ) === 1
            ) {
                normalVector = slightlyOffMiddlePoint
                    .subtract(middlePoint)
                    .norm()
                    .rotate90DegreesClockwise();
            } else {
                normalVector = slightlyOffMiddlePoint
                    .subtract(middlePoint)
                    .norm()
                    .rotate90DegreesCounterClockwise();
            }

            pos = middlePoint.add(
                normalVector.scalarMultiply(
                    CoLaNetworkVisComponent.nodePadding * 2
                )
            );
        } else {
            // fallback to assuming that the edge is not being routed
            const { sourceCoords, targetCoords } = this.getLinkPosition({
                source: sourceNode,
                target: targetNode
            });

            const sourceVector = new Vector(sourceCoords),
                targetVector = new Vector(targetCoords);

            const midPointPositionVector = sourceVector
                .add(targetVector)
                .scalarMultiply(1 / 2);

            // ensure that delete button does not lie on top of arrow
            pos = targetVector
                .subtract(sourceVector)
                .norm()
                .rotate90DegreesCounterClockwise()
                .scalarMultiply(normalDistance)
                .add(midPointPositionVector);
        }

        return [`translate(${pos.x}, ${pos.y})`, pos];
    };

    /**
     * Given a link returns a visibility of "hidden" if either the source or
     * target node is hidden, otherwise returns "inherit".
     */
    getLinkVisibility = (link) => {
        const sourceVisibility = this.props.styles[link.source.id] &&
                this.props.styles[link.source.id].visibility != null
                ? this.props.styles[link.source.id].visibility : "inherit";
        const targetVisibility = this.props.styles[link.target.id] &&
                this.props.styles[link.target.id].visibility != null
                ? this.props.styles[link.target.id].visibility : "inherit";

        return (sourceVisibility === "hidden" || sourceVisibility === "collapse") ||
                (targetVisibility === "hidden" || targetVisibility === "collapse")
                ? "hidden" : "inherit";
    };

    renderArcs() {
        return (
            <g>
                {this.links
                    ? this.links.map(link => {
                          const {
                              sourceCoords,
                              targetCoords
                          } = this.getLinkPosition(link);

                          const visibility = this.getLinkVisibility(link);

                          if (
                              [
                                  sourceCoords.x,
                                  sourceCoords.y,
                                  targetCoords.x,
                                  targetCoords.y
                              ].some(isNaN)
                          ) {
                              console.warn(
                                  "Received NaN coordinates for link from",
                                  link.source.id,
                                  "to",
                                  link.target.id,
                                  "- not showing link"
                              );
                              return null;
                          }

                          const selected = this.isLinkSelected({
                              source: link.source.id,
                              target: link.target.id
                          });

                          return (
                              <g
                                  key={`line:${link.source.id}:${
                                      link.target.id
                                  }`}
                                  onDoubleClick={e => {
                                      e.preventDefault();
                                      e.stopPropagation();
                                  }}
                                  style={{
                                      visibility
                                  }}
                              >
                                  <CoLaLink
                                      className="linkHighlight"
                                      source={sourceCoords}
                                      target={targetCoords}
                                      d={link.d}
                                      style={{
                                          stroke: "transparent",
                                          strokeWidth:
                                              2 *
                                              CoLaNetworkVisComponent.highlightRadius,
                                          opacity: 0.8,
                                          cursor: "pointer"
                                      }}
                                      onMouseDown={e => {
                                          e.preventDefault();
                                          e.stopPropagation();
                                          this.handleLinkClick(e, link)
                                      }}
                                  />
                                  <CoLaLink
                                      source={sourceCoords}
                                      target={targetCoords}
                                      d={link.d}
                                      style={{
                                          stroke: selected
                                              ? constants.colourActionBlue
                                              : "transparent",
                                          pointerEvents: "none",
                                          strokeWidth: 6,
                                          opacity: 0.8
                                      }}
                                  />
                                  <CoLaLink
                                      refFunc={dom => (link.dom = dom)}
                                      source={sourceCoords}
                                      target={targetCoords}
                                      d={link.d}
                                      style={{
                                          stroke:
                                              CoLaNetworkVisComponent.arcColour,
                                          pointerEvents: "none",
                                          markerEnd: "url(#arrow)"
                                      }}
                                  />
                              </g>
                          );
                      })
                    : null}
                {this.renderMouseArc()}
            </g>
        );
    }

    /**
     * When creating a new arrow, a preview arrow is rendered on the screen from
     * the source node to where the mouse is.
     */
    renderMouseArc = () => {
        return (
            !this.props.readOnly &&
            this.state.mouseArrowTail !== null &&
            this.state.mouseArrowHead !== null &&
            ![
                this.state.mouseArrowTail.x,
                this.state.mouseArrowTail.y,
                this.state.mouseArrowHead.x,
                this.state.mouseArrowHead.y
            ].some(isNaN) && (
                <CoLaLink
                    style={{
                        pointerEvents: "none",
                        stroke: CoLaNetworkVisComponent.arcColour,
                        markerEnd: "url(#arrow)"
                    }}
                    source={this.state.mouseArrowTail}
                    target={new Vector(this.state.mouseArrowHead).subtract(
                        new Vector(this.state.mouseArrowHead)
                            .subtract(new Vector(this.state.mouseArrowTail))
                            .norm()
                            .scalarMultiply(CoLaNetworkVisComponent.arrowLength)
                    )}
                />
            )
        );
    };

    getGraphCoords = evt => {
        let dim = this.Viewer.ViewerDOM.getBoundingClientRect();
        let pt = this.Viewer.ViewerDOM.createSVGPoint();
        pt.x = evt.clientX - dim.left;
        pt.y = evt.clientY - dim.top;

        let transformed = pt.matrixTransform(this.svg.getCTM().inverse());

        return new Vector({
            x: transformed.x - CoLaNetworkVisComponent.canvasWidth / 2,
            y: transformed.y - CoLaNetworkVisComponent.canvasHeight / 2
        });
    };

    handleNodeMouseDown = (node, e) => {
        this.unselectEverything();

        if (!this.props.draggableNodes) return;

        // Register mouse state to update dragging cursor.
        this.setState({
            isMouseDown: true
        });

        if (CoLaNetworkVisComponent.allowNodeOverlapsDuringDrag) {
            this.cola.avoidOverlaps(false).start(10, 20, 20);
        }

        // store original translations of mouse x and y coordinates
        node.originalVector = this.getGraphCoords(e).subtract(new Vector(node));

        // Sets second bit of node.fixed to 1
        cola.Layout.dragStart(node);

        // Add window event handlers (with ability to remove them later)
        node.mouseDragFunc = e => this.handleNodeMouseDrag(node, e);
        node.mouseUpFunc = e => this.handleNodeMouseUp(node, e);
        window.addEventListener("mousemove", node.mouseDragFunc);
        window.addEventListener("mouseup", node.mouseUpFunc);

        // Prevent user selection whilst node is being dragged
        document.body.style.userSelect = "none";

        // tell component that a node is being dragged
        this.draggingNode = true;

        // Prevent text selection or canvas dragging by stopping they
        // propagation of the mouse-down event up to the canvas.
        e.preventDefault();
        e.stopPropagation();
        return false;
    };

    handleNodeMouseDrag = (node, e) => {
        // if we are dragging this node
        if (node.originalVector != null) {
            cola.Layout.drag(
                node,
                this.getGraphCoords(e).subtract(node.originalVector)
            );

            // Register as a local user action, then restart layout.
            this.seenLocalUserAction = true;
            this.cola.resume();
        }
    };

    handleNodeMouseUp = (node) => {

        if (CoLaNetworkVisComponent.allowNodeOverlapsDuringDrag) {
            this.cola.avoidOverlaps(true).start(10, 20, 20);
        }

        // resets second bit of node.fixed to 0
        cola.Layout.dragEnd(node);

        // Register mouse state to update dragging cursor.
        this.setState({
            isMouseDown: false
        });

        // Remove window event handlers
        window.removeEventListener("mousemove", node.mouseDragFunc);
        window.removeEventListener("mouseup", node.mouseUpFunc);

        // Restore user selection
        document.body.style.userSelect = "auto";

        // Delete original vector
        delete node.originalVector;

        // Delete event handlers
        delete node.mouseDragFunc;
        delete node.mouseUpFunc;

        // tell component that a node is no longer being dragged
        this.draggingNode = false;

        this.performEdgeRouting();
        this.patchLinkUpdateIE();
    };

    handleNewArcMouseDown = (node, e) => {
        const mouseArrowHeadCoords = this.getNewArcCirclePosition(
            node,
            this.getGraphCoords(e)
        );

        // Make a copy of node rectangle.
        let rectangle = node.innerBounds.inflate(0);

        const mouseArrowTailCoords = cola.makeEdgeTo(
            mouseArrowHeadCoords,
            rectangle,
            0
        );

        this.setState({
            mouseArrowTail: { ...mouseArrowTailCoords, nodeId: node.id },
            mouseArrowHead: {
                ...mouseArrowHeadCoords,
                nodeId: null
            }
        });

        window.addEventListener("mousemove", this.handleNewArcMouseDrag);
        window.addEventListener("mouseup", this.handleNewArcMouseUp);

        // Prevent user selection whilst drawing a new arc
        document.body.style.userSelect = "none";

        // Prevent text selection or canvas dragging by stopping they
        // propagation of the mouse-down event up to the canvas.
        e.preventDefault();
        e.stopPropagation();
        return false;
    };

    handleNewArcMouseDrag = e => {
        let mouseArrowTailCoords = {},
            mouseArrowHeadCoords = {};
        if (
            this.state.mouseArrowHead.nodeId &&
            this.isValidConnection(
                this.state.mouseArrowTail.nodeId,
                this.state.mouseArrowHead.nodeId
            )
        ) {
            const { sourceCoords, targetCoords } = this.getLinkPosition(
                {
                    source: this.getNode(this.state.mouseArrowTail.nodeId),
                    target: this.getNode(this.state.mouseArrowHead.nodeId)
                },
                true
            );

            mouseArrowTailCoords = sourceCoords;
            mouseArrowHeadCoords = targetCoords;
        } else {
            mouseArrowHeadCoords = this.getGraphCoords(e);
            const node = this.getNode(this.state.mouseArrowTail.nodeId);

            let rectangle = node.innerBounds.inflate(0);

            mouseArrowTailCoords = cola.makeEdgeTo(
                mouseArrowHeadCoords,
                rectangle,
                0
            );
        }

        this.setState({
            mouseArrowTail: {
                ...this.state.mouseArrowTail,
                ...mouseArrowTailCoords
            },
            mouseArrowHead: {
                ...this.state.mouseArrowHead,
                ...mouseArrowHeadCoords
            }
        });
    };

    handleNewArcMouseUp = () => {
        if (
            this.state.mouseArrowTail != null &&
            this.state.mouseArrowTail.nodeId != null &&
            this.state.mouseArrowHead != null &&
            this.state.mouseArrowHead.nodeId != null &&
            this.isValidConnection(
                this.state.mouseArrowTail.nodeId,
                this.state.mouseArrowHead.nodeId
            )
        ) {
            // add connection
            this.updateGraphState({
                connections: [
                    ...this.props.graphDescription.connections,
                    {
                        source: this.state.mouseArrowTail.nodeId,
                        target: this.state.mouseArrowHead.nodeId,
                        label: ""
                    }
                ],
                ...this.getCoordsForAllNodesFromWebCoLa()
            });
        }

        this.setState({
            mouseArrowTail: null,
            mouseArrowHead: null
        });
        window.removeEventListener("mousemove", this.handleNewArcMouseDrag);
        window.removeEventListener("mouseup", this.handleNewArcMouseUp);

        // Restore user selection
        document.body.style.userSelect = "auto";
    };

    /**
     * Finds the shortest distance between a point and the rectangle,
     * so that it can find where to place the circle to show that a new
     * directed edge can be added.
     *
     * Point A is top left, and point C is bottom right. Point
     * M is where the mouse is. Point O is the origin point.
     * We want to find point P, and return this vector to the caller
     * function.
     *
     *  O
     *  *
     *
     *      A                   B
     *       * --------------- *
     *       |                 |
     *       |               P *      * M
     *       |                 |
     *       *-----------------*
     *      D                   C
     *
     */
    getNewArcCirclePosition = (node, coords) => {
        if (node.innerBounds == null) {
            // set inner bounds value
            node.innerBounds = node.bounds.inflate(
                -CoLaNetworkVisComponent.nodePadding
            );
        }

        // position vectors
        const vectorOA = new Vector(node.x - (node.innerBounds.width() / 2),
                node.y - (node.innerBounds.height() / 2));
        const vectorOD = vectorOA.add(
            new Vector(node.innerBounds.width(), node.innerBounds.height())
        );

        // direction vectors
        const vectorAB = new Vector(node.innerBounds.width(), 0),
            vectorAD = new Vector(0, node.innerBounds.height());

        // Vector OM
        const vectorOM = new Vector(coords);

        const vectorAM = vectorOM.subtract(vectorOA),
            vectorDM = vectorOM.subtract(vectorOD);

        const projectionMOnAB = vectorAM.project(vectorAB),
            projectionMOnAD = vectorAM.project(vectorAD),
            projectionMOnCD = vectorDM.project(vectorAB.negate()),
            projectionMOnCB = vectorDM.project(vectorAD.negate());

        // ESLint doesn't like the unused variable in the deconstructed assignment.
        // eslint-disable-next-line
        const [, vectorOP] = [
            [vectorOA, projectionMOnAB, vectorAB],
            [vectorOA, projectionMOnAD, vectorAD],
            [vectorOD, projectionMOnCD, vectorAB.negate()],
            [vectorOD, projectionMOnCB, vectorAD.negate()]
        ].reduce(
            ([dist, vector], [positionVector, projectedVector, edgeVector]) => {
                const otherVector = edgeVector
                    .scalarMultiply(
                        clamp(
                            Math.sign(projectedVector.dot(edgeVector)) *
                                projectedVector.mag() /
                                edgeVector.mag(),
                            0,
                            1
                        )
                    )
                    .add(positionVector);
                const otherDist = otherVector.distSq(vectorOM);

                if (!dist || otherDist < dist) {
                    return [otherDist, otherVector];
                }

                return [dist, vector];
            },
            [null, null]
        );
        return vectorOP;
    };

    /**
     * Given connection, finds node positions and computes endpoints for
     * a link.
     *
     * getLinkPosition: (link: {source: Node, target: Node}, noGap: boolean) => {
     *  sourceCoords: Coordinates,
     *  targetCoords: Coordinates
     * }
     */
    getLinkPosition = (link, noGap) => {
        // Prefer innerBounds since nodes may have padding.
        let sourceInnerBounds = link.source.innerBounds
            ? link.source.innerBounds
            : link.source.bounds;
        let targetInnerBounds = link.target.innerBounds
            ? link.target.innerBounds
            : link.target.bounds;

        const { sourceIntersection, arrowStart } = cola.makeEdgeBetween(
            sourceInnerBounds,
            targetInnerBounds,
            noGap ? 0 : CoLaNetworkVisComponent.arrowLength
        );

        return {
            sourceCoords: sourceIntersection,
            targetCoords: arrowStart
        };
    };

    static separateNodeAndTextStyles(styles)
    {
        let nodeStyle = {
            filter: "url(#shadow)",
            fill: CoLaNetworkVisComponent.nodeColour
        };
        let textStyle = {
            fill: CoLaNetworkVisComponent.textColour
        };

        const TEXT_STYLE_PREFIX = "text_";

        if (styles) {
            for (let [styleProp, style] of Object.entries(styles))
            {
                if (styleProp === "opacity" || styleProp === "visibility") {
                    // Apply node opacity and visibility to both node and text.
                    nodeStyle[styleProp] = style;
                    textStyle[styleProp] = style;
                }
                else if (styleProp.startsWith(TEXT_STYLE_PREFIX)) {
                    // Apply styles with a "text_" prefix just to node text.
                    let textStyleProp = styleProp.substring(TEXT_STYLE_PREFIX.length);
                    textStyle[textStyleProp] = style;
                }
                else {
                    // Apply other styles just to the node.
                    nodeStyle[styleProp] = style;
                }
            }
        }

        return {
            nodeStyle,
            textStyle
        };
    }

    renderNodes() {
        return this.nodes !== null
            ? this.nodes.map(node => {
                  // Use node dimensions without padding.
                  let nodeWidth =
                      node.width - CoLaNetworkVisComponent.nodePadding * 2;
                  let nodeHeight =
                      node.height - CoLaNetworkVisComponent.nodePadding * 2;

                  if (
                      !this.props.graphDescription.nodes ||
                      !this.props.graphDescription.nodes.variable[node.id]
                  ) {
                      return null;
                  }

                  const {
                      nodeStyle,
                      textStyle
                  } = CoLaNetworkVisComponent.separateNodeAndTextStyles(this.props.styles[node.id]);

                  /**
                   * We need an extra g wrapper around the node, as getBBox does
                   * not take the transform attribute into account. We use getBBox
                   * so that we can focus on these nodes.
                   */
                  return (
                      <g
                          className="node"
                          key={"nodes:node:" + node.id}
                          ref={dom => (node.dom = dom)}
                      >
                          <g
                              transform={`translate(${node.x}, ${node.y})`}
                              className={
                                  this.props.draggableNodes
                                      ? this.state.isMouseDown
                                        ? "cursorGrabbing"
                                        : "cursorGrab"
                                      : ""
                              }
                              style={{
                                  cursor:
                                      this.state.mouseArrowCircleIndicator !=
                                      null
                                          ? `url("${connectCursor}"),auto`
                                          : this.state.mouseArrowTail != null &&
                                            this.state.mouseArrowTail.nodeId !=
                                                null
                                            ? this.isValidConnection(
                                                  this.state.mouseArrowTail
                                                      .nodeId,
                                                  this.state.mouseArrowHead
                                                      .nodeId
                                              )
                                              ? `url("${connectCursor}"),auto`
                                              : "not-allowed"
                                            : this.props.draggableNodes
                                              ? null // Use CSS to set grab/grabbing cursor value - see className
                                              : "pointer"
                              }}
                              onMouseEnter={() => {
                                  if (
                                      this.state.mouseArrowTail != null &&
                                      this.state.mouseArrowHead != null
                                  ) {
                                      // already dragging an arrow, so set state for which node we're hovering on
                                      this.setState({
                                          mouseArrowHead: {
                                              ...this.state.mouseArrowHead,
                                              nodeId: node.id
                                          }
                                      });
                                  }
                              }}
                              onMouseLeave={() => {
                                  if (
                                      this.state.mouseArrowTail != null &&
                                      this.state.mouseArrowHead != null
                                  ) {
                                      // we are already dragging an arrow, so remove node from state
                                      this.setState({
                                          mouseArrowHead: {
                                              ...this.state.mouseArrowHead,
                                              nodeId: null
                                          }
                                      });
                                  }
                              }}
                              onClick={() =>
                                  this.props.onNodeSelected &&
                                  this.props.onNodeSelected(node.id)
                              }
                          >
                              <CoLaNode
                                  width={nodeWidth}
                                  height={nodeHeight}
                                  rx={CoLaNetworkVisComponent.nodeRectRadius}
                                  ry={CoLaNetworkVisComponent.nodeRectRadius}
                                  horizontalPadding={CoLaNetworkVisComponent.nodeRectHorizontalPadding}
                                  textStyle={textStyle}
                                  style={nodeStyle}
                                  onMouseDown={e =>
                                      this.handleNodeMouseDown(node, e)
                                  }
                                  customNodeDrawingSVG={
                                      typeof this.props
                                          .customNodeDrawingSVGFunction ===
                                          "function" &&
                                      this.props.customNodeDrawingSVGFunction(node, {width: nodeWidth, height: nodeHeight}, nodeStyle)
                                  }
                                  customNodeOverlayComponent={
                                      typeof this.props
                                          .customNodeOverlayFunction ===
                                          "function" &&
                                      this.props.customNodeOverlayFunction(node)
                                  }
                              >
                                  {this.getVariable(node.id).data.name}
                              </CoLaNode>
                          </g>
                      </g>
                  );
              })
            : null;
    }

    /**
     * Handles user clicking on a link or its associated label.  If nothing is
     * being edited selects the link a flags buttons to be rendered.  If a label is
     * being edited but it is not the clicked link, stops editing and selects
     * clicked link and flags buttons to be rendered.  If the clicked link is being edited
     * select the clicked link.
     * @param {*} e - click event.
     * @param {*} link - the object associated with the clicked link.
     */
    handleLinkClick(e, link) {
        if (this.state.currentlyEditingLabel == null) {
            this.setState({
                selectedLink: {
                    source: link.source.id,
                    target: link.target.id,
                    label: link.label,
                    dom: link.dom,
                    buttonsVisible: true
                }
            });
        } else {
            if (link.source.id !== this.state.currentlyEditingLabel.source ||
                link.target.id !== this.state.currentlyEditingLabel.target) {
            this.setState({
                selectedLink: {
                    source: link.source.id,
                    target: link.target.id,
                    label: link.label,
                    dom: link.dom,
                    buttonsVisible: true
                },
                currentlyEditingLabel: null,
                editInputValue: null
            });
            } else {
                this.setState({
                    selectedLink: {
                        source: link.source.id,
                        target: link.target.id,
                        label: link.label,
                        dom: link.dom,
                        buttonsVisible: false
                    }})
            }
        }
    }

    /**
     * Finds all links with a label and returns JSX of Label.js to be rendered.
     */
    renderLabels() {
        var links_w_labels = this.links.filter(link =>
            link.label !== undefined && link.label !== null && link.label !== "" );
        return links_w_labels.length !== 0
            ? links_w_labels.map(link => {
                // Variables to be used for SVGText in Label.js
                let width = this.calcLabelWidth(link.label);
                let height = CoLaNetworkVisComponent.nodeRectHeight;
                const visibility = this.getLinkVisibility(link);
                let textStyle = {
                    fill: "black"
                };
                let horizontalPadding = CoLaNetworkVisComponent.nodeRectHorizontalPadding;
                return (
                    <g
                        key={link.source.id.concat(link.target.id)}
                        style={{
                            visibility
                        }}
                    >
                        <Label
                            transform={this.translateToMiddleOfLink(
                                link,
                                this.getNode(link.source.id),
                                this.getNode(link.target.id),
                                CoLaNetworkVisComponent.nodePadding * 2
                            )[0]}
                            link={link}
                            onDoubleClick={e => {
                                e.preventDefault();
                                e.stopPropagation();
                            }}
                            onClick={e => {
                                if (this.props.readOnly) {
                                    return;
                                }

                                // This handles single and double click.
                                e.preventDefault();
                                e.stopPropagation();
                                var editInput;
                                if (this.labelClickTimer !== null) {
                                    // Double CLick.  Begin editing label.
                                    this.labelClickTimer = clearTimeout(this.labelClickTimer);
                                    this.labelClickTimer = null;
                                    if (link.label !== null && link.label !== "") {
                                        editInput = link.label}
                                        else {
                                            editInput = ""
                                        };
                                    let pos = this.translateToMiddleOfLink(link,
                                        this.getNode(link.source.id),
                                        this.getNode(link.target.id),
                                        CoLaNetworkVisComponent.nodePadding * 2
                                    )[1];
                                    this.setState({
                                        currentlyEditingLabel: {
                                            source: link.source.id,
                                            target: link.target.id,
                                            label: link.label,
                                            x: pos.x,
                                            y: pos.y
                                        },
                                        selectedLink: {
                                            source: link.source.id,
                                            target: link.target.id,
                                            label: link.label,
                                            dom: link.dom,
                                            buttonsVisible: false
                                        },
                                        editInputValue: editInput
                                    });
                            } else { //Single click.  Select link and display buttons.
                                this.labelClickTimer = setTimeout(() => {this.handleLinkClick(e, link); this.labelClickTimer = null}, 250)
                            }
                            }}
                            width={width}
                            height={height}
                            textStyle={textStyle}
                            horizontalPadding={horizontalPadding}
                            visibility={visibility}
                        />
                    </g>
                )
            })
                : null;
    }

    /**
     * Node "highlights" are rectangles surrounding each actual node. It allows
     * users to add new arrows to the component.
     */
    renderNodeHighlights() {
        return this.nodes !== null && !this.props.readOnly
            ? this.nodes.map(node => {
                  // Use node dimensions without padding.
                  let nodeWidth =
                      node.width - CoLaNetworkVisComponent.nodePadding * 2;
                  let nodeHeight =
                      node.height - CoLaNetworkVisComponent.nodePadding * 2;

                  if (
                      !this.props.graphDescription.nodes ||
                      !this.props.graphDescription.nodes.variable[node.id]
                  ) {
                      return null;
                  }

                  /**
                   * We need an extra g wrapper around the node, as getBBox does
                   * not take the transform attribute into account. We use getBBox
                   * so that we can focus on these nodes.
                   */
                  return (
                      <g
                          key={"nodes:nodeShadow:" + node.id}
                          transform={`translate(${node.x}, ${node.y})`}
                      >
                          <CoLaNode
                              rx={CoLaNetworkVisComponent.nodeRectRadius}
                              ry={CoLaNetworkVisComponent.nodeRectRadius}
                              horizontalPadding={CoLaNetworkVisComponent.nodeRectHorizontalPadding}
                              width={
                                  nodeWidth +
                                  2 * CoLaNetworkVisComponent.nodePadding
                              }
                              height={
                                  nodeHeight +
                                  2 * CoLaNetworkVisComponent.nodePadding
                              }
                              style={{
                                  fill: "transparent",
                                  cursor: `url("${connectCursor}"),auto`
                              }}
                              onMouseMove={e => {
                                  this.setState({
                                      mouseArrowCircleIndicator: this.getNewArcCirclePosition(
                                          node,
                                          this.getGraphCoords(e)
                                      )
                                  });
                              }}
                              onMouseOut={() => {
                                  if (this.state.mouseArrowCircleIndicator) {
                                      this.setState({
                                          mouseArrowCircleIndicator: null
                                      });
                                  }
                              }}
                              onMouseDown={e =>
                                  this.handleNewArcMouseDown(node, e)
                              }
                          />
                      </g>
                  );
              })
            : null;
    }

    editLabel(link) {
        this.updateGraphState({
            connections: this.props.graphDescription.connections.map(
                otherConnection => {
                    if (
                        link.source ===
                            otherConnection.source &&
                        link.target ===
                            otherConnection.target
                    ) {
                        return {
                            ...link
                        };
                    }

                    return otherConnection;
                }
            )
        });
    }

    /**
     * Function to handle submission of a label edit.  Updates the label,
     * stops editing and unselects everything.
     */
    handleEditSubmit(event) {
        event.preventDefault();
        event.stopPropagation();
        this.editLabel({
            source: this.state.currentlyEditingLabel.source,
            target: this.state.currentlyEditingLabel.target,
            label: this.state.editInputValue
            }
        );
        this.setState({
            currentlyEditingLabel: null,
            editInputValue: null,
            selectedLink: null
        })
    }

    /**
     * Handles a change in the input for editing labels.  Updates state value for th
     * edit input.  This is what is rendedered in the input element.
     */
    handleEditChange(event) {
        this.setState({editInputValue: event.target.value})
    }

    focusEditInput(e){
        e.preventDefault();
        e.stopPropagation();
        this.editInput.focus();
    }

    /**
     * Triggers on keydown while editing label.  When ESC is hit, stops
     * editing and unselects link.
     */
    handleEditKeyDown(event){
        if (event.keyCode === KEY_CODE_ESC) {
            this.setState({
                currentlyEditingLabel: null,
                selectedLink: null,
                editInputValue: null
            })
        };
    }
    /**
     * If a label is being edited returns JSX for the editing input.  This is a form with
     * single input element within a foreignObject.
     */
    renderEditInput() {
        if (this.state.currentlyEditingLabel != null) {
            return (
            <g
                transform={`translate(${this.state.currentlyEditingLabel.x + this.calcLabelWidth(this.state.currentlyEditingLabel.label)/2}, ${this.state.currentlyEditingLabel.y-11})`}
            >
            <foreignObject
                onMouseDown={e => {
                    e.stopPropagation()
                }}
                width="1px"
                height="1px"
            >
                <form onSubmit={this.handleEditSubmit.bind(this)}>
                <input
                    type="text"
                    value={this.state.editInputValue}
                    onChange={this.handleEditChange.bind(this)}
                    ref={(input) => {this.editInput = input;}}
                    onClick={this.focusEditInput.bind(this)}
                    autoFocus
                    onKeyDown={this.handleEditKeyDown.bind(this)}
                    placeholder="Edit Label Here"
                    style={{border: "1px solid black", padding: "3px 3px"}}
                    />
                </form>
            </foreignObject>
            </g>
            )
        }
    }



    /**
     * Renders extra components if the BN layout component is not set to
     * read only.
     */
    renderExtra() {
        if (this.props.readOnly) {
            return null;
        }
        var pos = null;
        var translateString = "";
        if (this.state.selectedLink) {
            pos = this.translateToMiddleOfLink(
                this.state.selectedLink,
                this.getNode(this.state.selectedLink.source),
                this.getNode(this.state.selectedLink.target),
                CoLaNetworkVisComponent.nodePadding * 2
                )[1];
            if (this.state.selectedLink.label !== "") {
                translateString = `translate(${pos.x + this.calcLabelWidth(this.state.selectedLink.label)/2}, ${pos.y})`
            } else{
                translateString = `translate(${pos.x - 15}, ${pos.y})`
            }

            }
        return (
            <g>
                {this.state.mouseArrowCircleIndicator &&
                    !this.state.mouseArrowTail && (
                        <ArcCircle
                            x={this.state.mouseArrowCircleIndicator.x}
                            y={this.state.mouseArrowCircleIndicator.y}
                        />
                    )}
                {this.state.mouseArrowHead &&
                    this.state.mouseArrowHead.nodeId &&
                    this.isValidConnection(
                        this.state.mouseArrowTail.nodeId,
                        this.state.mouseArrowHead.nodeId
                    ) && (
                        <ArcCircle
                            x={this.state.mouseArrowHead.x}
                            y={this.state.mouseArrowHead.y}
                        />
                    )}
                {this.state.selectedLink &&
                this.state.selectedLink.buttonsVisible === true && (
                    <g
                    transform={translateString}
                    style= {{overflow: "visible"}}
                    >
                        <DeleteButton
                            onDelete={() => {
                                this.removeConnections([
                                    {
                                        source: this.state.selectedLink.source,
                                        target: this.state.selectedLink.target
                                    }
                                ]);
                            }}
                        />
                        <EditLabelButton
                            onclick={() => {
                                var editInput;
                                if (this.state.selectedLink.label !== null && this.state.selectedLink.label !== "") {
                                editInput = this.state.selectedLink.label}
                                else {
                                    editInput = ""
                                };
                                let pos = this.translateToMiddleOfLink(
                                    this.state.selectedLink,
                                    this.getNode(this.state.selectedLink.source),
                                    this.getNode(this.state.selectedLink.target),
                                    CoLaNetworkVisComponent.nodePadding * 2
                                    )[1];
                                this.setState({
                                    currentlyEditingLabel: {
                                        source: this.state.selectedLink.source,
                                        target: this.state.selectedLink.target,
                                        label: this.state.selectedLink.label,
                                        dom: this.state.selectedLink.dom,
                                        x: pos.x,
                                        y: pos.y
                                    },
                                    editInputValue: editInput,
                                    selectedLink: {
                                        ...this.state.selectedLink,
                                        buttonsVisible: false
                                    }
                                });
                            }}
                        />
                    </g>
                )}
            </g>
        );
    }

    render() {
        // Shadow from https://stackoverflow.com/questions/6088409/svg-drop-shadow-using-css3

        // Allow zooming out to fix the entire canvas.
        let padding = 40;
        let scaleFactorMin = Math.min(
            (this.props.size.width - padding) /
                    CoLaNetworkVisComponent.canvasWidth,
            (this.props.size.height - padding) /
                    CoLaNetworkVisComponent.canvasHeight
        );
        return (
            <div className="bayesNetDisplay">
                <div
                    className="bayesNetGraph"
                    onMouseDown={this.handleMouseDown}
                    onMouseUp={this.handleMouseUp}
                >
                    <ReactSVGPanZoom
                        className="CoLaNetworkVisComponent"
                        miniaturePosition="none"
                        scaleFactorOnWheel={1.1}
                        scaleFactorMin={scaleFactorMin}
                        scaleFactorMax={2}
                        tool="auto"
                        toolbarPosition="none"
                        background={"#cccccc"}
                        SVGStyle={{ filter: "url(#shadow)" }}
                        width={this.props.size.width}
                        height={this.props.size.height}
                        detectAutoPan={this.state.isMouseDown}
                        ref={Viewer => {
                            if (Viewer) {
                                this.Viewer = Viewer;
                                this.svg = Viewer.ViewerDOM.getElementsByTagName(
                                    "g"
                                )[0];
                            }
                        }}
                    >
                        <svg
                            width={CoLaNetworkVisComponent.canvasWidth}
                            height={CoLaNetworkVisComponent.canvasHeight}
                        >
                            <style>
                                {this.state.mouseArrowTail != null
                                    ? `svg { cursor: url("${connectCursor}"),auto; }`
                                    : "svg { cursor: default; }"}
                            </style>
                            <SVGDefs
                                arrowLength={
                                    CoLaNetworkVisComponent.arrowLength
                                }
                                arcColour={CoLaNetworkVisComponent.arcColour}
                            />
                            <g
                                transform={`translate(${CoLaNetworkVisComponent.canvasWidth /
                                    2}, ${CoLaNetworkVisComponent.canvasHeight /
                                    2})`}
                            >
                                {this.renderNodeHighlights()}
                                {this.renderArcs()}
                                {this.renderNodes()}
                                {this.renderLabels()}
                                {this.renderExtra()}
                                {this.renderEditInput()}
                            </g>
                        </svg>
                    </ReactSVGPanZoom>
                    <Menu
                        alwaysPerformForceDirectedLayout={
                            this.props.alwaysPerformForceDirectedLayout
                        }
                        performForceDirectedLayout={
                            this.state.performForceDirectedLayout
                        }
                        onPerformForceDirectedLayout={() =>
                            this.setState(
                                { performForceDirectedLayout: true },
                                () => this.restartGraph(this.props, true)
                            )
                        }
                        onZoomToFit={() => this.zoomToFitContent()}
                    />
                </div>
            </div>
        );
    }
}

export default sizeMe({ monitorHeight: true })(CoLaNetworkVisComponent);
