import React, {Component} from 'react';
import classNames from 'classnames';

import * as constants from '../../common/util/constants';
import KeyVariablesComponent from '../presentation/KeyVariablesComponent';
import KeyFacts from './KeyFactsContainer';
import KeyQuestionsContainer from './KeyQuestionsContainer';
import MethodSelection from './MethodSelectionContainer';
import Suggestions from './SuggestionsContainer';
import BayesNetConnectionEditor from '../presentation/BayesNetConnectionEditor';
import BayesNetParametersContainer from './BayesNetParametersContainer';
import ExploreModelContainer from './ExploreModelContainer';
import CandidateSolutionContainer from './CandidateSolutionContainer';
import ReportContainer from './ReportContainer';

const syncVariableToComponent = {
    [constants.SYNC_KEY_QUESTIONS]: KeyQuestionsContainer,
    [constants.SYNC_KEY_VARIABLES]: KeyVariablesComponent,
    [constants.SYNC_KEY_FACTS]: KeyFacts,
    [constants.SYNC_METHOD_SELECTION] : MethodSelection,
    [constants.SYNC_COMMENTS_SUGGESTIONS] : Suggestions,
    [constants.SYNC_BN_GRAPH]: BayesNetConnectionEditor,
    [constants.SYNC_BN_PARAMETERS]: BayesNetParametersContainer,
    [constants.SYNC_EXPLORE_MODEL]: ExploreModelContainer,
    [constants.SYNC_CANDIDATE_SOLUTION]: CandidateSolutionContainer,
    [constants.SYNC_REPORT]: ReportContainer
};

interface SyncVariableContainerProps {
    syncVariables: string[];
    className?: string;
    [otherProps: string]: any;
}

class SyncVariableContainer extends Component<SyncVariableContainerProps> {

    render() {
        const {syncVariables, ...otherProps} = this.props;
        return (
            <div className={classNames('syncVariableContainer', this.props.className)}>
                {
                    syncVariables.map((field) => {
                        const Component = syncVariableToComponent[field];
                        return (
                            <Component key={field} {...otherProps} />
                        );
                    })
                }
            </div>
        );
    }
}

export default SyncVariableContainer;
