import Moment from 'moment';

export function getFormattedMessageDateStamp(timestamp) {
    return Moment().diff(timestamp, 'days') === 0 ? 'Today' : Moment(timestamp).format('Do MMM YYYY');
}

export function getFormattedMessageTimeStamp(timestamp) {
    return Moment().diff(timestamp, 'days') === 0 ?
        Moment(timestamp).format('LT') :
        getFormattedTimeStamp(timestamp);
}

export function getFormattedTimeStamp(timestamp) {
    return Moment(timestamp).format('LT') + ' ' + Moment(timestamp).format('ll').replace(new RegExp('\\,?\\s' + Moment(timestamp).format('YYYY')), '');
}

export function getRemainingTime(problemStep) {
    let now = Moment();
    let end;
    let hours = 0;

    end = getStepRoundEndTime(problemStep, problemStep.activityDuration);

    if (end) {
        hours = Moment.duration(end.diff(now)).asHours();
    }

    return {hours, end};
}

export function getStepRoundEndTime(problemStep, duration) {
    let launchTime = problemStep.roundLaunched ? problemStep.roundLaunched : problemStep.stepLaunched;
    let end;

    if (launchTime) {
        launchTime = new Date(launchTime);
        end = Moment(new Date(launchTime.setTime(launchTime.getTime() + (duration*60*60*1000))));
    }    
    
    return end;
}
