import {
    addFieldData,
    BardFilterObject,
    BardFilterSuggestions,
    fieldMatchesFilter,
    parseFilterString,
    suggestFilterFields
} from './commonFilter';
import {SingleProblemStepType} from '../reducers/allTeamsReducer';
import {TEAM_USER_FILTER_TYPE, TeamUserFilter, teamUserMatchesFilter} from './teamUserFilter';

type TeamFilterKeys = Exclude<keyof SingleProblemStepType, 'id' | 'problemId' | 'problem' | 'stepId' | 'stepLaunched'
    | 'roundSequenceNumber' | 'roundLaunched' | 'activityDuration' | 'route' | 'actualCompletion' | 'teamId'
    | 'problemLabel' | 'reportSubmittedAt' | 'systemReportSubmittedAt' | 'submittedReportEmpty' | 'reportLocation'
    | 'notifiedWillBeClosed' | 'notifiedDueToBeClosed' | 'notifiedExceedToBeClosed'>;

export type TeamFilter = {
    users?: BardFilterObject<TeamUserFilter>
} & {
    [field in TeamFilterKeys]?: string | boolean | number | Array<string>;
};

export type TeamFilterObject = BardFilterObject<TeamFilter>;

export type TeamFilterSuggestions = BardFilterSuggestions<TeamFilter>;

export const TEAM_FILTER_TYPE = 'teamFilter';

export const teamFilterFieldTypes: {[key in keyof Required<TeamFilter>]: string | string[]} = {
    launchMode: ['none', 'facilitator', 'RTJ', 'solo', 'strathclyde'],
    state: ['none', 'notReady', 'ready', 'active', 'suspended', 'closed'],
    launched: 'boolean',
    users: TEAM_USER_FILTER_TYPE
};

addFieldData(TEAM_FILTER_TYPE, teamFilterFieldTypes, 'launchMode');

export function parseTeamFilterString(filter: string): TeamFilterObject {
    return parseFilterString<TeamFilter>(filter, TEAM_FILTER_TYPE);
}

// Test if the last token in filter string could auto-complete to values in TeamFilterKeys.
export function suggestTeamFilterFields(filterString: string): TeamFilterSuggestions {
    const filter = parseFilterString(filterString, TEAM_FILTER_TYPE);
    return suggestFilterFields(filter);
}

export function teamMatchesFilter(team: SingleProblemStepType, filter?: TeamFilterObject) {
    if (!filter || !filter.fields) {
        return true;
    }
    for (let field of filter.fields) {
        const filterValue = filter.where[field];
        if (teamFilterFieldTypes[field] === TEAM_USER_FILTER_TYPE) {
            if (!team.users || !team.users.reduce((anyMatch, user) => (anyMatch || teamUserMatchesFilter(user, filterValue)), false)) {
                return false;
            }
        } else {
            const teamValue = team[field];
            if ((Array.isArray(filterValue) && filterValue.reduce((noneMatch, filterValue) => (
                noneMatch && !fieldMatchesFilter(teamValue, filterValue, TEAM_FILTER_TYPE, field)
            ), true)) || (!Array.isArray(filterValue) && !fieldMatchesFilter(teamValue, filterValue, TEAM_FILTER_TYPE, field))) {
                return false;
            }
        }
    }
    return true;
}
