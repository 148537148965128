import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {FontIcon, Drawer, Button} from 'react-md';
import classNames from 'classnames';

import HeaderCurrentStep from './HeaderCurrentStep';
import ReferenceData from '../../common/util/referenceData';
import * as constants from '../../common/util/constants';
import DataStatusEnum from '../../common/DataStatusEnum';
import WhatsNextData from '../util/WhatsNextData';
import StatusIconUser from './StatusIconUser';

class WhatsNext extends Component {

    static propTypes = {
        user: PropTypes.object.isRequired,
        problemStep: PropTypes.object.isRequired,
        round: PropTypes.number.isRequired
    };

    constructor(props) {
        super(props);
        this.state = {isVisible: false};
    }

    handleVisibility = (visible) => {
        this.setState({ isVisible : visible });
    };

    //Get the current step or if step = Build Model get the published step
    getStep(stepId){
        let resultStep = null;
        
        if(stepId !== constants.STEP_BAYESIAN_SOLUTION)
        {
            resultStep = ReferenceData.getInstance().getStepDescription(stepId);
        }else{
            let step = this.getPublishedBuildModelStepInfo();
            resultStep = step ? ReferenceData.getInstance().getStepDescription(step) : null;
            
            if(!resultStep){
                if (this.props.user.db.role === constants.ROLE_ANALYST) {
                    resultStep = ReferenceData.getInstance().getStepDescription(constants.STEP_VARIABLES);
                } else {
                    resultStep = ReferenceData.getInstance().getStepDescription(stepId);
                }
            }
        }
        return resultStep;
    }

    //Check if the the user has published
    isPublished(publishedStepMask){
        if(this.props.user.db.role === constants.ROLE_ANALYST){
            if(this.props.problemStep.stepId !== constants.STEP_BAYESIAN_SOLUTION && this.props.problemStep.stepId !== constants.STEP_SINGLE_STRUCTURE)
            {
                if(publishedStepMask === 1 << this.props.problemStep.stepId){
                    return true;
                }
            }else{
                return !!this.getPublishedBuildModelStepInfo();
            }
        }else if(this.props.user.db.role === constants.ROLE_FACILITATOR){
            if(this.props.problemStep.stepId === constants.STEP_EXPLORE_PROBLEM){
                return (this.props.user.status[DataStatusEnum.GROUP][constants.SYNC_KEY_FACTS].timestamp !== undefined)
            }
        }

        return false;
    }

    //Get the what's next help content from the WhatsNextData.js file
    getWhatsNext(id) {
        let result = '';

        const whatsNextContent = this.props.user.db.role === constants.ROLE_ANALYST 
                                    ? WhatsNextData.getInstance().getWhatsNextContentForAnalyst() 
                                    : WhatsNextData.getInstance().getWhatsNextContentForFacilitator();
        
                                    Object.keys(whatsNextContent).forEach((next) => {
            if (whatsNextContent[next].id === id) {
                result = whatsNextContent[next];
            }
        });
        
        return result;
    }

    //Get the published step info if step = Build Model
    getPublishedBuildModelStepInfo(){
        
        const stepDescriptions = ReferenceData.getInstance().getAllStepDescriptions();
        let resultStepId = null;
        let result;

        Object.keys(stepDescriptions).forEach((key) => {
            if(stepDescriptions[key].parentStepId === constants.STEP_BAYESIAN_SOLUTION)
            {
                let stepId = stepDescriptions[key].id;
                let status = (this.props.user.db.role === constants.ROLE_FACILITATOR) ? DataStatusEnum.GROUP : DataStatusEnum.PUBLISHED;    
                if(stepId === constants.STEP_EXPLORE_MODEL){
                    if(this.props.user.status[status][constants.SYNC_EXPLORE_MODEL].timestamp){
                        resultStepId = stepId;
                    }
                }
                else if(stepId === constants.STEP_PARAMETERS){
                    if(this.props.user.status[status][constants.SYNC_BN_PARAMETERS].timestamp){
                        resultStepId = stepId;
                    }
                }
                else if(stepId === constants.STEP_STRUCTURE){
                    if(this.props.user.status[status][constants.SYNC_BN_GRAPH].timestamp){
                        resultStepId = stepId;
                    }
                }
                else if(stepId === constants.STEP_VARIABLES){
                    if(this.props.user.status[status][constants.SYNC_KEY_VARIABLES].timestamp){
                        resultStepId = stepId;
                    }
                }
                else if(stepId === constants.STEP_SINGLE_STRUCTURE){
                    if(this.props.user.status[status][constants.SYNC_BN_GRAPH].timestamp){
                        resultStepId = stepId;
                    }
                }
            }
        });
        const step = ReferenceData.getInstance().getStepDescription(resultStepId);
        result = step ? step.id : null;
        return result;
    }

    //Get the text for what's next help content
    getWhatsNextText(content, round, published){
        let whatsNextText = null;
        let result = '';
        
        if(round === constants.ROUND_OPEN){ // If Step == Build Model, find the last published step and display information accordingly
            let publishedBNStep = this.getPublishedBuildModelStepInfo(); // Find the latest published step

            // If no content is published in the Build Model step, display generic content
            whatsNextText = publishedBNStep ? this.getWhatsNext(publishedBNStep).openPublished : content.open; 
            
        }else if(round === constants.ROUND_PRIVATE){
            if(published){
                whatsNextText = content.privatePublished;
            }else{
                whatsNextText = content.private;
            }
        }else if(round === constants.ROUND_EXCHANGE || round === constants.ROUND_DELPHI_EXCHANGE){
            if(published){
                whatsNextText = content.exchangePublished;
            }else{
                whatsNextText = content.exchange;
            }
        }else if(round === constants.ROUND_REPORT_EXCHANGE){
            if(published){
                whatsNextText = content.reportPublished;
            }else{
                whatsNextText = content.report;
            }
        }

        if(whatsNextText != null){
            result = whatsNextText.map((index, whatsNext) => {
            if (whatsNextText[whatsNext] === '') {
                return null;
            }

            return  <div key={index} className="whatsNextNumberedDiv">
                        <span className="whatsNextNumber">{whatsNext + 1}.&nbsp;</span> 
                        <span>{whatsNextText[whatsNext]}</span>
                    </div>;
            });
        }

        return result;

    }

    //canned content for now
    //TODO: Get content from the WhatsNextData after the logic is decided
    getWhatsNextProcess(){
        let stepId = this.props.problemStep.stepId;
        let roundId = this.props.round;
        let isFacilitator = this.props.user.db.role === constants.ROLE_FACILITATOR;
        let stepName = '';
        let text = [];

        if(isFacilitator){
            text.push("Consider if you want to change Group Draft based on what you saw there, or join the Discussion if appropriate.");
        }else{
            if(stepId !== constants.STEP_NBR_REPORT && stepId !== constants.STEP_BR_REPORT){
                if(this.props.problemStep.launchMode !== constants.LAUNCH_MODE_STRATHCLYDE_EXPERIMENT){
                    text.push("When the Facilitator has published a Group version, you should review it. Then consider if you want to change your Draft based on what you saw there, or join the Discussion if appropriate.");
                }
            }else{
                if(this.props.problemStep.launchMode !== constants.LAUNCH_MODE_STRATHCLYDE_EXPERIMENT){
                    text.push("When the Facilitator has published a Group version, you should review it. Join the Discussion if appropriate.");
                }
            }
        }
        if((stepId === constants.STEP_EXPLORE_PROBLEM || stepId === constants.STEP_BR_CANDIDATE_SOLUTION || stepId === constants.STEP_NBR_CANDIDATE_SOLUTION) && (stepId !== constants.STEP_NBR_REPORT && stepId !== constants.STEP_BR_REPORT)){
            stepName = this.getStep(stepId).name;
            if(isFacilitator){
                text.push("If the group is satisfied with the contents of Step "+stepName+" on the Group screen , consider launching the next Round/Step.");
            }else
                text.push("In Step "+stepName+", you have to wait for the Facilitator to move you forward to the next Round/Step.");
        }else{
            if(this.props.problemStep.launchMode === constants.LAUNCH_MODE_STRATHCLYDE_EXPERIMENT){
                text.push("Before this round is finished, you must click on the PUBLISH MY DRAFT so that your work in this experiment is recorded.");
                text.push("In Exchange round, view the published content from other analysts in the Published tab and rate them using  the slider provided");
                text.push("You can go back to revise your Structure at any time in the My Draft tab.");
            }
            else if((stepId !== constants.STEP_NBR_REPORT && stepId !== constants.STEP_BR_REPORT)&&( this.getStep(stepId).id !== constants.STEP_EXPLORE_MODEL)){
                stepName = this.getStep(stepId).name;
                text.push("In Step "+stepName+", when you are happy with your current Step work, click on the next Step and work on that.");
            }
        }
        if(roundId === constants.ROUND_OPEN){
            text.push("From Step 5. EXPLORE NETWORK, you can go back to any of the earlier Build Network steps (2. VARIABLES, 3. STRUCTURE and 4. PARAMETERS) and revise your Draft.");
        }

        let textDisplay = text.map((element, index) => {
                            return(
                                <div className="whatsNextNumberedDiv"  key={index}>
                                    <span className="whatsNextNumber">{++index}.&nbsp;</span> 
                                    <span>{element} </span>
                                </div>  
                                )
                            });
        return(
            <div className="nextProcessDiv">
                {textDisplay}
                               
            </div>
        );
    }

    render(){
        const isPublished = this.isPublished(this.props.user.db.publishedStepMask);
        const step = this.getStep(this.props.problemStep.stepId);
        const whatsNextContent = step ? this.getWhatsNext(step.id) : null;
        const isOpenRound = this.props.round === constants.ROUND_OPEN ;
        const isOpenPublished = this.getPublishedBuildModelStepInfo();
        const stepName = (isOpenRound && isOpenPublished) ? this.getStep(this.getPublishedBuildModelStepInfo()).name : (step ? step.name : null) ;

        return (
                <div className="whatsNextHelp">
                        <div onClick={() => this.setState({ isVisible: !this.state.isVisible })}>
                            <FontIcon className="md-link whatsNextHelpIcon">help</FontIcon>
                        </div>
                        <Drawer
                            id="simple-drawer-example"
                            type={Drawer.DrawerTypes.TEMPORARY}
                            visible={this.state.isVisible}
                            position='right'
                            onVisibilityChange={this.handleVisibility}
                            header={(
                                    <div className="whatsNextHeader"> 
                                        <Button icon onClick={() => this.setState({ isVisible: !this.state.isVisible })} className="whatsNextcloseBtn">close</Button>
                                        <div style={{display:"inline-block", paddingLeft: "10px"}}>What should I do next?</div>                                        
                                    </div>
                            )}
                            >
                            <div className="whatsNextDiv">
                                <div className="whatsNextStepInfoDiv">
                                    <HeaderCurrentStep problemStepId={this.props.problemStepId} page='whatsnext' />
                                    <div className="myProgressDiv"> 
                                        <div className='imageDiv toolTipContainer'>
                                            <StatusIconUser className={classNames('iconStyles', {
                                                facilitator: this.props.user.db.role === constants.ROLE_FACILITATOR,
                                                analyst: this.props.user.db.role === constants.ROLE_ANALYST,
                                                observer: this.props.user.db.role === constants.ROLE_OBSERVER,
                                                unallocated: this.props.user.db.role === constants.ROLE_UNALLOCATED,
                                                soloAnalyst: this.props.user.db.role === constants.ROLE_SOLO_ANALYST
                                            })}/>
                                            <span className="toolTip">You have the role of {this.props.userRoleName} for this problem</span>
                                        </div>
                                        <div className="headerLabelDiv">
                                            <span className="headerTitle bolder">MY PROGRESS</span>
                                            <div className="problemStep">
                                                <div style={{display:"block"}}>
                                                    Draft {isOpenRound ?
                                                                (isOpenPublished ? 
                                                                    'published ' 
                                                                    :
                                                                    'not yet published '
                                                                )   
                                                                :
                                                                (isPublished ? 
                                                                    'published ' 
                                                                    : 
                                                                    'not yet published '
                                                                )
                                                            }
                                                    in {stepName} step
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="whatsNextContentDiv">
                                    <h3>Here are some things you could do next:</h3>
                                        <h4>
                                            In the {stepName} step 
                                            {this.props.round === constants.ROUND_PRIVATE ? ' Private round' : (this.props.round === constants.ROUND_EXCHANGE ? ' Exchange round' : '')}
                                        </h4>
                                        {whatsNextContent ? this.getWhatsNextText(whatsNextContent, this.props.round, isPublished) : null}
                                    <h3>What’s the next step in the process?</h3>
                                        {this.getWhatsNextProcess()}
                                </div>
                            </div>
                        </Drawer>
                </div>
        )
    }
}

export default WhatsNext;