import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Tab, TabList, TabPanel, Tabs} from 'react-tabs';
import {TextField, Divider} from 'react-md';
import {getFormattedMessageTimeStamp} from '../util/timeUtils';

import {getUserFromStore} from '../reducers/loginReducer';
import {getAllUsersFromStore, updateUserListAction} from '../reducers/allUsersReducer';
import {getServerStateFromStore, scheduleShutdownAction, cancelScheduleShutdownAction} from '../../common/reducers/serverStateReducer';
import AdminUserPanel from './AdminUserPanel';
import AdminProblemsPanel from './AdminProblemsPanel';
import AdminProjectsPanel from './AdminProjectsPanel';
import AdminEmailTemplatePanel from './AdminEmailTemplatePanel';
import AdminCronPanel from './AdminCronPanel';
import AdminFeatureFlagPanel from './AdminFeatureFlagPanel';
import AdminUserFeedbackPanel from './AdminUserFeedbackPanel';
import {withRouter} from 'react-router-dom';
import {ADMIN_PATH_BASE} from '../../common/clientServer/navigation';
import AdminConfigPanel from './AdminConfigPanel';
import {getListenerIdFromStore} from '../reducers/listenerIdReducer';
import LoadingBar from '../container/ProblemValidationComponent';

import '../scss/adminPanel.scss';

const DEFAULT_HOUR_BEFORE_SHUTDOWN = 10;
const MIN_HOUR_BEFORE_SHUTDOWN = 1;

class AdminTemplate extends Component {

    constructor(props) {
        super(props);
        this.state = {
            exportDatabase: false,
            message: '',
            hoursBeforeShutdown: DEFAULT_HOUR_BEFORE_SHUTDOWN,
            shutdownScheduled: props.serverState.shutdownScheduled
        };
        this.scheduleShutdown = this.scheduleShutdown.bind(this);
        this.cancelScheduleShutdown = this.cancelScheduleShutdown.bind(this);
    }

    componentDidMount() {
        if (!this.props.allUsers || Object.keys(this.props.allUsers).length === 0) {
            fetch('/api/user', {
                method: 'get',
                credentials: 'include'
            }).then((response) => {
                if (response.ok) {
                    return response.json()
                        .then((allUsers) => {
                            this.props.dispatch(updateUserListAction(allUsers));
                        });
                }
            });
        }
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.setState({shutdownScheduled: newProps.serverState.shutdownScheduled});
    }

    scheduleShutdown() {
        this.setState({shutdownScheduled: true}, ()=> {
            this.props.dispatch(scheduleShutdownAction(this.state.hoursBeforeShutdown));
        });    
    }

    cancelScheduleShutdown() {
        this.setState({shutdownScheduled: false}, () => {
            this.props.dispatch(cancelScheduleShutdownAction());
        });
    }

    renderAdminPanel() {
        return (
            <div className='adminConsole frontPage'>
                This is the administration console for BARD. Choose your action from the tabs above or the
                buttons below.
                <section className='dataExport'>
                    <div className='adminButtonsDiv'>
                        <input type='button' value='Export database to JSON'
                            className='buttonStyle regularButtonStyle'
                            disabled={this.state.exportDatabase}
                            onClick={() => {
                                this.setState({
                                    exportDatabase: true,
                                    message: 'Working...'
                                });
                                fetch('/api/exportDB', {
                                    method: 'get',
                                    credentials: 'include'
                                })
                                    .then((response) => {
                                        if (response.ok) {
                                            this.setState({
                                                exportDatabase: false,
                                                message: 'The database has been saved to database.json on the server.'
                                            });
                                        } else {
                                            response.text()
                                                .then((message) => {
                                                    this.setState({
                                                        exportDatabase: false,
                                                        message
                                                    });
                                                });
                                        }
                                    })
                            }}
                        />
                    </div>
                    <div>
                        {this.state.message}
                    </div>
                </section>
                <Divider />
                <section className='scheduleShutdown'>
                    {
                        this.state.shutdownScheduled ? (
                            <div className='adminButtonsDiv md-grid md-grid--stacked'>
                                <div className='shutdownScheduledMsg md-cell md-cell--4 md-cell--middle'>
                                    <span>BARD has been scheduled to be unavailable at {getFormattedMessageTimeStamp(this.props.serverState.scheduledShutdownTimeStamp)}</span>
                                </div>
                                <button
                                    className='buttonStyle regularButtonStyle md-cell md-cell--1 md-cell--middle'
                                    onClick={this.cancelScheduleShutdown}
                                >Cancel</button>
                            </div>
                        ) : (
                        <div className='adminButtonsDiv md-grid md-grid--stacked'>
                            <TextField
                                id='duration-before-shutdown'
                                placeholder='Enter time remaining before shutdown'
                                type='number'
                                label='Time remaining in hours'
                                className='md-cell md-cell--2 md-cell--middle'
                                value={this.state.hoursBeforeShutdown}
                                onChange={(val) => {
                                    let hoursBeforeShutdown = +val;
                                    if (hoursBeforeShutdown < MIN_HOUR_BEFORE_SHUTDOWN) {
                                        this.setState({ hoursBeforeShutdown: MIN_HOUR_BEFORE_SHUTDOWN });
                                    } else {
                                        this.setState({ hoursBeforeShutdown: hoursBeforeShutdown });
                                    }
                                }}
                            />
                            <button
                                className='buttonStyle regularButtonStyle md-cell md-cell--2 md-cell--middle'
                                onClick={this.scheduleShutdown}
                            >Schedule a shutdown</button>
                        </div>
                        )
                    }
                </section>
                <Divider />
            </div>
        );
    }

    render() {
        if (!this.props.listenerId) {
            return (
                <div className='loadingProblem'>
                    <div className="loadingBarContentContainer"><LoadingBar /></div>
                </div>
            );
        }
        if (!this.props.user || !this.props.user.admin) {
            return <div>Not admin user?</div>;
        }
        return (
            <Tabs
                className='reactTabs adminTabs'
                selectedTabClassName='selected'
                onSelect={(index) => {
                    this.props.history.push(ADMIN_PATH_BASE + '/' + index);
                }}
                selectedIndex={Number(this.props.match.params.tabIndex || 0)}
            >
                <TabList>
                    <Tab>Admin</Tab>
                    <Tab>Users</Tab>
                    <Tab>Problems</Tab>
                    <Tab>Projects</Tab>
                    <Tab>Email Templates</Tab>
                    <Tab>Schedules</Tab>
                    <Tab>System Configuration</Tab>
                    <Tab>Feature Flags</Tab>
                    <Tab>User Feedback</Tab>
                </TabList>

                <TabPanel>
                    {this.renderAdminPanel()}
                </TabPanel>
                <TabPanel>
                    <AdminUserPanel/>
                </TabPanel>
                <TabPanel>
                    <AdminProblemsPanel/>
                </TabPanel>
                <TabPanel>
                    <AdminProjectsPanel/>
                </TabPanel>
                <TabPanel>
                    <AdminEmailTemplatePanel />
                </TabPanel>
                <TabPanel>
                    <AdminCronPanel />
                </TabPanel>
                <TabPanel>
                    <AdminConfigPanel />
                </TabPanel>
                <TabPanel>
                    <AdminFeatureFlagPanel/>
                </TabPanel>
                <TabPanel>
                    <AdminUserFeedbackPanel/>
                </TabPanel>
            </Tabs>
        );
    }
}

function mapStoreToProps(store) {
    return {
        listenerId: getListenerIdFromStore(store),
        user: getUserFromStore(store),
        allUsers: getAllUsersFromStore(store),
        serverState: getServerStateFromStore(store)
    };
}

export default withRouter(connect(mapStoreToProps)(AdminTemplate));

