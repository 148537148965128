import React, {ChangeEvent, Component, FocusEvent} from 'react';

interface SingleInputStringProps {
    inputType: 'text' | 'password' | 'number' | 'datetime-local';
    onChange: (value: string) => void;
    content?: string;
}

interface SingleInputBooleanProps {
    inputType: 'checkbox';
    onChange: (value: boolean) => void;
    content?: boolean;
}

interface SingleInputOtherProps {
    title?: string;
    readOnly?: boolean;
    onBlur?: (evt: FocusEvent<HTMLInputElement>) => void;
    className?: string;
    focus?: boolean;
    [otherProp: string]: any;
}

type SingleInputProps = (SingleInputStringProps | SingleInputBooleanProps) & SingleInputOtherProps;

class SingleInput extends Component<SingleInputProps> {

    private inputElement?: HTMLDivElement;

    static defaultProps = {
        readOnly: false,
        onBlur: null
    };

    componentDidMount() {
        if (this.props.focus && this.inputElement) {
            this.inputElement.focus();
        }
    }

    render() {
        const inputValue = (this.props.inputType === 'checkbox') ? 'checked' : 'value';
        const { inputType, content, onChange, onBlur, focus, className, title, ...otherProps } = this.props;
        let attributes = {
            type: inputType,
            [inputValue]: content,
            ...(this.props.readOnly ? undefined : {onChange: (evt: ChangeEvent<HTMLInputElement>) => {
                    (onChange as any)(evt.target[inputValue]);
            }}),
            ...(this.props.readOnly || !onBlur ? undefined : {onBlur: (evt: FocusEvent<HTMLInputElement>) => {
                    onBlur(evt);
                }}),
            ...(focus && { ref: (self) => { this.inputElement = self; } }),
        };
        let classNameObj = {
            ...(className && { className }),
        };
        if (title) {
            return inputType === 'checkbox' ? (
                <label className="cf">
                    <input {...classNameObj} {...attributes} {...otherProps} />
                    <span {...classNameObj}>{title}</span>
                </label>
            ) : (
                <label className="cf">
                    <span {...classNameObj}>{title}</span>
                    <input {...classNameObj} {...attributes} {...otherProps} />
                </label>
            );
        } else {
            return (
                <input {...classNameObj} {...attributes} {...otherProps} />
            );
        }
    }
}

export default SingleInput;