const ADD_TOAST_MESSAGE = 'add_toast_message';
const DISMISS_FIRST_TOAST = 'dismiss_first_toast';

const snackbarToastReducer = (state = [], action) => {
    switch (action.type) {
        case ADD_TOAST_MESSAGE:
            return [...state, {text: action.message}];
        case DISMISS_FIRST_TOAST:
            return state.slice(1);
        default:
            return state;
    }
};

export default snackbarToastReducer;

export function addToastMessageAction(message) {
    return {type: ADD_TOAST_MESSAGE, message, isClientOnly: true};
}

export function dismissFirstToastAction() {
    return {type: DISMISS_FIRST_TOAST, isClientOnly: true};
}

export function getToastsFromStore(store) {
    return store.clientState.snackbarToasts;
}