import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import moment from 'moment';
import {FontIcon} from 'react-md';

import GroupManagementTimer from './GroupManagementTimer';

import ReferenceData from '../../common/util/referenceData';
import * as constants from '../../common/util/constants';
import ConnectedFlag from '../container/ConnectedFlag';
import {FEATURE_USERS_OPT_OUT_TO_ROLE} from '../../common/util/featureFlags';

import {getLoggedInUserIdFromStore} from '../reducers/loginReducer';
import {endpointURL} from '../util/userActivity';
import {grantOptOutRequestAction, demoteUserAction} from '../../common/reducers/groupManagementReducer';
import ConfirmationDialog from './ConfirmationDialog';
import AlertDialog from './AlertDialog';
import {NOTIF_GLOBAL, getNotificationsForProblemStepAndUserFromStore, dismissNotificationAction} from '../../common/reducers/notificationsReducer';
import {getTeamFromStore} from "../../common/reducers/allTeamsReducerGetters";
import {
    getAllUsersForProblemFromStore,
    getUserForProblemFromStore
} from "../../common/reducers/allUsersForTeamReducerGetters";


const NO_ACTION = 0;
const DEMOTE_USER = 1;
const GRANT_OPT_OUT = 2;

const FacilitatorTitle = 'Facilitator';
const AnalystsTitle = 'Analysts';
const ObserversTitle = 'Observers';

class GroupManagementComponent extends Component {

    static propTypes = {
        problemStepId: PropTypes.number.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            userActivity: [],
            needConfirmation: false,
            confirmationTitle: '',
            confirmationDialogContent: '',
            alert: false,
            alertDialogContent: '',
            action: NO_ACTION,
            user: null
        };

        this.demoteUser = this.demoteUser.bind(this);
        this.actionFunc = this.actionFunc.bind(this);
        this.takeAction = this.takeAction.bind(this);
        this.getUserActivity = this.getUserActivity.bind(this);
        this.actionCancelled = this.actionCancelled.bind(this);
        this.alertCancelled = this.alertCancelled.bind(this);
        this.dismissNotificationIfNecessary = this.dismissNotificationIfNecessary.bind(this);
    }


    capitalise(string) {
        string = string.toLowerCase();
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    demoteUser(action, userId, problemStepId, fromRole, toRole=constants.ROLE_OBSERVER) {  
        return fetch('/api/team/demote/user', {
            method: 'put',
            headers: new Headers({'Content-Type': 'application/json'}),
            credentials: 'include',
            body: JSON.stringify({user_id: userId, problem_step_id: problemStepId, to_role: toRole})
        })
        .then(response => {
            if (!response.ok) {
                return;
            }

            response.json().then(data => {
                if (data.success) {
                    switch (action) {
                        case DEMOTE_USER:
                            this.props.dispatch(demoteUserAction(problemStepId, userId, fromRole, toRole));
                            break;
                        case GRANT_OPT_OUT:
                            this.props.dispatch(grantOptOutRequestAction(problemStepId, userId, fromRole, toRole));
                            break;
                        default:    
                    }
                    this.setState({}, this.dismissNotificationIfNecessary(this.props));
                }
                else {
                    this.setState({alert: true, alertDialogContent: 'Opt out failed: Your request was unsuccessful.'});
                }
            });
        });
    }

    alertCancelled() {
        this.setState({alert: false});
    }

    actionCancelled() {
        this.setState({needConfirmation: false});
    }

    takeAction() {
        let action = this.state.action;
        let userId = this.state.user.db.userId;
        let problemStepId = this.props.problemStepId;
        let fromRole = this.props.usersForProblem[userId].db.role;
        let toRole;
        switch (action) {
            case DEMOTE_USER:
                toRole = constants.ROLE_OBSERVER;
                this.setState({needConfirmation: false}, () => {
                    this.demoteUser(action, userId, problemStepId, fromRole, toRole);
                });
               break;
            case GRANT_OPT_OUT:
                toRole = fromRole === constants.ROLE_FACILITATOR ? constants.ROLE_ANALYST : constants.ROLE_OBSERVER;
                this.setState({needConfirmation: false}, () => {
                    this.demoteUser(action, userId, problemStepId, fromRole, toRole);
                });
                break;
            default:    
        }
    }

    actionFunc(action, user) {

        let confirmationTitle = '';
        let confirmationDialogContent = '';
        switch (action) {
            case DEMOTE_USER:
                confirmationTitle = 'Opt User Out';
                switch (user.db.role) {
                    case constants.ROLE_FACILITATOR:
                        confirmationDialogContent = <span><b>{user.db.displayName}'s</b> role will change from Facilitator to Analyst.<br/><br/>The Facilitator will still be able to participate in the problem in the Analyst role. The most appropriate Analyst or Observer will be assigned the Facilitator role as their replacement.<br/><br/><b>This user will no longer act in the Facilitator role.</b><br/><b>This action cannot be undone.</b></span>;
                        break;

                    case constants.ROLE_ANALYST:
                        confirmationDialogContent = <span><b>{user.db.displayName}'s</b> role will change from Analyst to Observer.<br/><br/>Consider opting out an Analyst if they are unable to contribute to the group on an ongoing basis. The Analyst will still be able to follow the problem in the Observer role. The most appropriate Observer will be assigned an Analyst role as their replacement.<br/><br/><b>This action cannot be undone.</b></span>;
                        break;
                    
                    default:
                        confirmationDialogContent = <span>Consider opting out an Observer if they are unable to participate on an ongoing basis. The Observer will still be able to follow the problem.<br/><br/><b>This action cannot be undone.</b></span>;
                }
                break;
            
            case GRANT_OPT_OUT:
                confirmationTitle = 'Opt out requested';
                const promotion = (this.props.team.problemStep.stepId === null) ? '' : (
                    (this.props.user.db.role === constants.ROLE_FACILITATOR ? ' Your' : ' Their') + ' role will be automatically filled with the most appropriate team member.'
                );
                switch (user.db.role) {
                    case constants.ROLE_FACILITATOR:
                        confirmationDialogContent = <span>You have requested to opt out of the Facilitator role for this problem.<br/><br/>You will still be able to participate in the problem in the Analyst role.{promotion}<br/><br/><b>You will no longer act in the Facilitator role.</b> The current Facilitator solutions for each step (Group Draft and Group) will be copied over to your Analyst solutions (My Draft and Published).<br/><br/><b>This action cannot be undone.</b></span>;                        break;

                    case constants.ROLE_ANALYST:
                        confirmationDialogContent = <span><b>{user.db.displayName}</b> has requested to opt out of this problem.<br/><br/>The team member will still be able to follow the problem in the Observer role.{promotion}<br/><br/><b>This action cannot be undone.</b></span>;
                        break;
                    
                    default:
                        confirmationDialogContent = <span><b>{user.db.displayName}</b> has requested to opt out of this problem.<br/><br/>The team member will still be able to follow the problem in the Observer role.<br/><br/><b>This action cannot be undone.</b></span>;
                }
                break;
            
            default:    
        }

        this.setState({needConfirmation: true, confirmationTitle, confirmationDialogContent, action, user});
    }

    getUserActivity(props) {
        let users = [];
        Object.keys(props.usersForProblem).forEach((userId) => {
            users.push(userId)
        }); 
        
        let userIds = JSON.stringify(users);
        
        let url = endpointURL + '/' + userIds + '/' + props.problemStepId + '/';
        fetch(url, {
            method: 'get',
            headers: new Headers({'Content-Type': 'application/json'}),
            credentials: 'include',
        })
        .then(response => {
            if (!response.ok) {
                return;
            }
    
            response.json().then(data => {
                let userActivity = this.state.userActivity;
                data.userActivity.forEach((user) => {
                    let date = new Date(user.lastActivityAt);
                    if (!userActivity[user.userId] || date > userActivity[user.userId])
                        userActivity[user.userId] = date; 
                });
                this.setState({userActivity});
            });
        });
  
        this.dismissNotificationIfNecessary(props); 
    };

    dismissNotificationIfNecessary(props) {
        if (props.allNotifications && props.allNotifications[NOTIF_GLOBAL] && props.allNotifications[NOTIF_GLOBAL]['optOutRequest']) {
            let optOutRequest = false;
            Object.keys(props.usersForProblem).forEach((userId) => { 
                optOutRequest = optOutRequest || (props.usersForProblem[userId].db.groupManagementState === constants.GRP_MGMT_OPT_OUT_REQUESTED);
            });

            if (!optOutRequest) {
                this.props.dispatch(dismissNotificationAction([constants.TAG_OPT_OUT_REQUEST], props.userId, props.problemStepId));
            }
        }
    }

    renderSectionHeader(title) {
        return (
            <div className={'groupManagementSectionHeader ' + title}>
                <div className={'heading3 groupManagementSectionTitle ' + title}>{title}</div>      
                <div className="flex-grid">
                    <div className="flex-column-3 heading4 groupManagementColumnHeader groupManagementMemberColumnHeader">Group member</div>                        
                    <div className="flex-column-2 heading4 groupManagementColumnHeader groupManagementCurrentRoleColumnHeader">Current role</div>
                    <div className="flex-column-2 heading4 groupManagementColumnHeader">Last seen</div>
                    <ConnectedFlag flagName={FEATURE_USERS_OPT_OUT_TO_ROLE} equalTo={constants.ROLE_OBSERVER_NAME}>
                        <div className="flex-column-8 heading4 groupManagementColumnHeader">Action</div>
                    </ConnectedFlag>
                    <ConnectedFlag flagName={FEATURE_USERS_OPT_OUT_TO_ROLE} equalTo={constants.ROLE_ANALYST_NAME}>
                        {title === FacilitatorTitle
                            ? <div className="flex-column-8 heading4 groupManagementColumnHeader">Action</div>
                            : <div className="flex-column-8 heading4 groupManagementColumnHeader"/>
                        }
                    </ConnectedFlag>
                </div>
                <hr/>
            </div>
        )
    }

    renderFacilitator(user) {
        let groupManagementState = user.db.groupManagementState;
        let groupManagementStateDate = user.db.groupManagementStateDate;

        let actionText;
        let action = NO_ACTION;
        switch (groupManagementState) {
            case constants.GRP_MGMT_NOP:
            case constants.GRP_MGMT_USER_PROMOTED:
                if (this.state.userActivity.length > 0) {
                    let latestActivity = this.state.userActivity[user.db.userId];
                    if (latestActivity === undefined || isNaN(latestActivity.getTime())) {      // assume 'never' - but should not happen for facilitator
                        actionText = (
                            <span className='groupManagementAction demoteUser demoteFacilitator'>
                                <FontIcon className="md-18 md-action demoteIcon">arrow_downward</FontIcon>
                                OPT THIS USER OUT
                            </span>
                        );
                        action = DEMOTE_USER;
                    }
                    else {        
                        let minutes = (new Date() - latestActivity) / 1000 / 60;
                        let days = minutes / 1440;

                        if (days > constants.FACILITATOR_DELINQUENT_DAYS) {      // should not happen for facilitator
                            actionText = (
                                <span className='groupManagementAction demoteUser demoteFacilitator'>
                                    <FontIcon className="md-18 md-action demoteIcon">arrow_downward</FontIcon>
                                    OPT THIS USER OUT
                                </span>
                            );
                            action = DEMOTE_USER;
                        }
                    }
                }
                break;
            case constants.GRP_MGMT_OPT_OUT_REQUESTED:
                actionText = (
                    <span className='groupManagementAction optOutRequest facilitatorOptOutRequest'>
                        <FontIcon className="md-red md-18 personIcon">person</FontIcon>
                        OPT OUT REQUESTED
                    </span>
                );
                action = GRANT_OPT_OUT;
                break;
            case constants.GRP_MGMT_OPT_OUT_REQUEST_GRANTED:
                actionText = (
                    <span className='groupManagementAction userOptedOut facilitatorOptedOut'>
                        {'Opted out ' + moment(groupManagementStateDate).format('D MMM YYYY')}
                    </span>
                );
                break;
            case constants.GRP_MGMT_USER_DEMOTED:
                actionText = (
                    <span className='groupManagementAction userDemoted facilitatorDemoted'>
                        {'Opted out by Facilitator ' + moment(groupManagementStateDate).format('D MMM YYYY')}
                    </span>
                );
                break;
            default:
        }

        return (
            <div className="flex-grid userRow facilitatorRow facilitator override">
                <span className="flex-column-3 groupManagementColumn groupManagementMemberColumn">{user.db.displayName}</span>
                <span className="flex-column-2 groupManagementColumn groupManagementCurrentRoleColumn roleBackgroundColour">{this.capitalise(this.props.roles[user.db.role].role)}</span>
                <span className="flex-column-2 groupManagementColumn groupManagementLastSeenColumn"><GroupManagementTimer latestActivity={this.state.userActivity.length > 0 ? this.state.userActivity[user.db.userId] : null} userRole={user.db.role} alertClassName={'alert'}/></span>
                <span className="flex-column-8 groupManagementColumn groupManagementActionColumn" onClick={()=>(action === NO_ACTION) ? null : this.actionFunc(action, user)}>{actionText}</span>
            </div>
        )
    }

    renderAnalyst(user) {
        let groupManagementState = user.db.groupManagementState;
        let groupManagementStateDate = user.db.groupManagementStateDate;

        let actionText;
        let action = NO_ACTION;
        switch (groupManagementState) {
            case constants.GRP_MGMT_NOP:
            case constants.GRP_MGMT_USER_PROMOTED:
                if (this.state.userActivity.length > 0) {
                    let latestActivity = this.state.userActivity[user.db.userId];
                    if (latestActivity === undefined || isNaN(latestActivity.getTime())) {      // assume 'never'
                        actionText = (
                            <span className='groupManagementAction demoteUser demoteAnalyst'>
                                <FontIcon className="md-18 md-action demoteIcon">arrow_downward</FontIcon>
                                OPT THIS USER OUT
                            </span>
                        );
                        action = DEMOTE_USER;
                    }
                    else {        
                        let minutes = (new Date() - latestActivity) / 1000 / 60;
                        let days = minutes / 1440;

                        if (days > constants.ANALYST_DELINQUENT_DAYS) {
                            actionText = (
                                <span className='groupManagementAction demoteUser demoteAnalyst'>
                                    <FontIcon className="md-18 md-action demoteIcon">arrow_downward</FontIcon>
                                    OPT THIS USER OUT
                                </span>
                            );
                            action = DEMOTE_USER;
                        }
                    }
                }
                break;
            case constants.GRP_MGMT_OPT_OUT_REQUESTED:
                actionText = (
                    <span className='groupManagementAction optOutRequest analystOptOutRequest'>
                        <FontIcon className="md-red md-18 personIcon">person</FontIcon>
                        OPT OUT REQUESTED
                    </span>
                );
                action = GRANT_OPT_OUT;
                break;
            case constants.GRP_MGMT_OPT_OUT_REQUEST_GRANTED:
                actionText = (
                    <span className='groupManagementAction userOptedOut analystOptedOut'>
                        {'Opted out ' + moment(groupManagementStateDate).format('D MMM YYYY')}
                    </span>
                );
                break;
            case constants.GRP_MGMT_USER_DEMOTED:
                actionText = (
                    <span className='groupManagementAction userDemoted analystDemoted'>
                        {'Opted out by Facilitator ' + moment(groupManagementStateDate).format('D MMM YYYY')}
                    </span>
                );
                break;
            default:
        }

        return (
            <div key={user.db.userId} className={'groupManagementSection Analysts'}>
                <div className="flex-grid userRow analystRow analyst override">
                    <span className="flex-column-3 groupManagementColumn groupManagementMemberColumn">{user.db.displayName}</span>
                    <span className="flex-column-2 groupManagementColumn groupManagementCurrentRoleColumn roleBackgroundColour">{this.capitalise(this.props.roles[user.db.role].role)}</span>
                    <span className="flex-column-2 groupManagementColumn groupManagementLastSeenColumn"><GroupManagementTimer latestActivity={this.state.userActivity.length > 0 ? this.state.userActivity[user.db.userId] : null} userRole={user.db.role} alertClassName={'alert'}/></span>
                    <ConnectedFlag flagName={FEATURE_USERS_OPT_OUT_TO_ROLE} equalTo={constants.ROLE_OBSERVER_NAME}>
                        <span className="flex-column-8 groupManagementColumn groupManagementActionColumn" onClick={()=>(action === NO_ACTION) ? null : this.actionFunc(action, user)}>{actionText}</span>
                    </ConnectedFlag>
                    <ConnectedFlag flagName={FEATURE_USERS_OPT_OUT_TO_ROLE} equalTo={constants.ROLE_ANALYST_NAME}>
                        <span className="flex-column-8 groupManagementColumn groupManagementActionColumn"/>
                    </ConnectedFlag>
                </div>
            </div>
        )
    }

    renderObserver(user) {
        let groupManagementState = user.db.groupManagementState;
        let groupManagementStateDate = user.db.groupManagementStateDate;

        let actionText;
        let action = NO_ACTION;
        switch (groupManagementState) {
            case constants.GRP_MGMT_NOP:
                if (this.state.userActivity.length > 0) {
                    let latestActivity = this.state.userActivity[user.db.userId];
                    if (latestActivity === undefined || isNaN(latestActivity.getTime())) {      // assume 'never'
                        actionText = (
                            <span className='groupManagementAction demoteUser demoteObserver'>
                                <FontIcon className="md-18 md-action demoteIcon">arrow_downward</FontIcon>
                                OPT THIS USER OUT
                            </span>
                        );
                        action = DEMOTE_USER;
                    }
                    else {        
                        let minutes = (new Date() - latestActivity) / 1000 / 60;
                        let days = minutes / 1440;

                        if (days > constants.OBSERVER_DELINQUENT_DAYS) {
                            actionText = (
                                <span className='groupManagementAction demoteUser demoteObserver'>
                                    <FontIcon className="md-18 md-action demoteIcon">arrow_downward</FontIcon>
                                    OPT THIS USER OUT
                                </span>
                            );
                            action = DEMOTE_USER;
                        }
                    }
                }
                break;
            case constants.GRP_MGMT_OPT_OUT_REQUESTED:
                actionText = (
                    <span className='groupManagementAction optOutRequest observerOptOutRequest'><FontIcon className="md-red md-18 personIcon">person</FontIcon>
                        OPT OUT REQUESTED
                    </span>
                );
                action = GRANT_OPT_OUT;
                break;
            case constants.GRP_MGMT_OPT_OUT_REQUEST_GRANTED:
                actionText = (
                    <span className='groupManagementAction userOptedOut observerOptedOut'>
                        {'Opted out ' + moment(groupManagementStateDate).format('D MMM YYYY')}
                    </span>
                );
                break;
            case constants.GRP_MGMT_USER_DEMOTED:
                actionText = (
                    <span className='groupManagementAction userDemoted observerDemoted'>
                        {'Opted out by Facilitator ' + moment(groupManagementStateDate).format('D MMM YYYY')}
                    </span>
                );
                break;
            default:
        }

        return (
            <div key={user.db.userId} className={'groupManagementSection Observers'}>
                <div className="flex-grid userRow observerRow observer override">
                <span className="flex-column-3 groupManagementColumn groupManagementMemberColumn">{user.db.displayName}</span>
                <span className="flex-column-2 groupManagementColumn groupManagementCurrentRoleColumn roleBackgroundColour">{this.capitalise(this.props.roles[user.db.role].role)}</span>
                <span className="flex-column-2 groupManagementColumn groupManagementLastSeenColumn"><GroupManagementTimer latestActivity={this.state.userActivity.length > 0 ? this.state.userActivity[user.db.userId] : null} userRole={user.db.role} alertClassName={'alert'}/></span>
                <ConnectedFlag flagName={FEATURE_USERS_OPT_OUT_TO_ROLE} equalTo={constants.ROLE_OBSERVER_NAME}>
                    <span className="flex-column-8 groupManagementColumn groupManagementActionColumn" onClick={()=>(action === NO_ACTION) ? null : this.actionFunc(action, user)}>{actionText}</span>
                </ConnectedFlag>
                <ConnectedFlag flagName={FEATURE_USERS_OPT_OUT_TO_ROLE} equalTo={constants.ROLE_ANALYST_NAME}>
                        <span className="flex-column-8 groupManagementColumn groupManagementActionColumn"/>
                </ConnectedFlag>
            </div>
            </div>
        )
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.getUserActivity(props);
    }

    componentDidMount() {  
        this.getUserActivity(this.props);
    }


    render() {

        if (!this.props.userId || this.props.user.db.role !== constants.ROLE_FACILITATOR) {   // sanity check
            return null;
        }
       
        if (this.state.userActivity.length === 0) {
            return null;
        }

        return (
            <div className='myWorkspaceStyle'>
                <div className = "threeColumnLayout">
                    <div className = "workspaceLeftColumn">
                    </div>
        
                    <div className="workspaceContentColumn">
                        <div className='groupManagementPage'>
                
                            <div className='heading2 groupManagementHeaderDiv'>Group Management</div>

                            <div>
                                {this.renderSectionHeader(FacilitatorTitle)}

                                {this.renderFacilitator(this.props.user)}

                                {this.renderSectionHeader(AnalystsTitle)}

                                {this.props.usersForProblem && Object.keys(this.props.usersForProblem).map((index) => {
                                    return this.props.usersForProblem[index].db.role === constants.ROLE_ANALYST ? this.renderAnalyst(this.props.usersForProblem[index]) : null
                                })}

                                {this.renderSectionHeader(ObserversTitle)}

                                {this.props.usersForProblem && Object.keys(this.props.usersForProblem).map((index) => {
                                    return this.props.usersForProblem[index].db.role === constants.ROLE_OBSERVER ? this.renderObserver(this.props.usersForProblem[index]) : null
                                })}

                            </div>
                        
                            <ConfirmationDialog
                                visible={this.state.needConfirmation}
                                dialogTitle={this.state.confirmationTitle}
                                onHideAction={this.takeAction} onCancelAction={this.actionCancelled}
                                dialogContent={this.state.confirmationDialogContent}
                                width={500}
                                actionButtonContent="CONFIRM"
                            />
                
                            <AlertDialog
                                visible={this.state.alert}
                                dialogTitle={'ALERT'}
                                onHideAction={this.alertCancelled} onCancelAction={this.alertCancelled}
                                dialogContent={this.state.alertDialogContent}
                                width={330} height={185}
                            />
                        </div>
                    </div>

                    <div className = "workspaceRightColumn">
                    </div>
                    
                </div>
            </div>
        )
   }
}

function mapStoreToProps(store, myProps) {
    const problemStepId = myProps.problemStepId;
    const userId = getLoggedInUserIdFromStore(store);

    return {
        roles: ReferenceData.getInstance().getAllUserRoles(),
        team: getTeamFromStore(store, problemStepId),
        userId,
        user: getUserForProblemFromStore(store, problemStepId, userId),
        usersForProblem: getAllUsersForProblemFromStore(store, problemStepId),
        allNotifications: getNotificationsForProblemStepAndUserFromStore(store, problemStepId, userId)
    }
}

export default connect(mapStoreToProps)(GroupManagementComponent);