import * as constants from '../../common/util/constants';

const WhatsNextAnalyst = {
    [constants.STEP_EXPLORE_PROBLEM]: {
        id: constants.STEP_EXPLORE_PROBLEM,
        private: ["If you have unpublished changes in your current Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","Is there anything you want to change in your Draft?"],
        privatePublished: ["If you have unpublished changes in your current Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.", "Is there anything you want to change in your Draft?"],
        exchange: ["If you have unpublished changes in your current Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","Is there anything you want to change in your Draft?", "Review the published Group version showing the combination of your work and all other Analysts' work.","Consider if you want to change your Draft based on what you see there, and Publish it again."],
        exchangePublished: ["If you have unpublished changes in your current Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","Is there anything you want to change in your Draft?", "Review the published Group version showing the combination of your work and all other Analysts' work.","Consider if you want to change your Draft based on what you see there, and Publish it again."]
    },
    [constants.STEP_BAYESIAN_SOLUTION]: {
        id: constants.STEP_BAYESIAN_SOLUTION,
        open: ["Start by working on Group Draft of the First Step: VARIABLES in the Build Bayesian Network. Once the Analysts have published their content refer to them in Published screen and copy anything that needs to be added to the Group Draft. Once satisfied with the content, publish by clicking on the Publish button. The content will then be shared to the Analysts on the Group screen.","Similarly work incrementally on the subsequent steps STRUCTURE, PARAMETERS and EXPLORE MODEL to create a Bayesian Network.","Go back to previously published steps in Build Bayesian Solution to modify the content.","Don't forget to click Publish to share the content with the Analysts and make it part of the final solution.","Review and contribute to any recent Discussion activity."],
        openPublished: ["If you have unpublished changes in your current Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.", "Is there anything you want to change in your Draft?","Review and contribute to any recent Discussion activity."]
    },
    [constants.STEP_VARIABLES]: {
        id: constants.STEP_VARIABLES,
        open: ["Start by identifying Variables in the Problem.","If you have unpublished changes in your current Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","You will be able to view other Analysts' work in the Published screen after you publish.", "The content published by the Facilitator can be viewed in the Group screen after you publish." , "You can initiate and participate in discussions in Discussion screen after you publish."],
        openPublished: ["If you have unpublished changes in your current Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","Is there anything you want to change in your Draft?","Review the GROUP DRAFT published by the Facilitator. Consider if you want to change your Draft based on the Group Draft, or contribute to the Discussion. The Group Draft forms the basis of the Group's final submission.", "Review any Published contributions from other Analysts."]
    },
    [constants.STEP_STRUCTURE]: {
        id: constants.STEP_STRUCTURE,
        open: ["If you have unpublished changes in your current Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","Is there anything you want to change in your Draft?","Review the GROUP DRAFT published by the Facilitator. Consider if you want to change your Draft based on the Group Draft, or contribute to the Discussion. The Group Draft forms the basis of the Group's final submission.", "Review any Published contributions from other Analysts."],
        openPublished: ["If you have unpublished changes in your current Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","Is there anything you want to change in your Draft?","Review the GROUP DRAFT published by the Facilitator. Consider if you want to change your Draft based on the Group Draft, or contribute to the Discussion. The Group Draft forms the basis of the Group's final submission.", "Review any Published contributions from other Analysts."]
    },
    [constants.STEP_PARAMETERS]: {
        id: constants.STEP_PARAMETERS,
        open: ["If you have unpublished changes in your current Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","Is there anything you want to change in your Draft?","Review the GROUP DRAFT published by the Facilitator. Consider if you want to change your Draft based on the Group Draft, or contribute to the Discussion. The Group Draft forms the basis of the Group's final submission.", "Review any Published contributions from other Analysts."],
        openPublished: ["If you have unpublished changes in your current Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","Is there anything you want to change in your Draft?","Review the GROUP DRAFT published by the Facilitator. Consider if you want to change your Draft based on the Group Draft, or contribute to the Discussion. The Group Draft forms the basis of the Group's final submission.", "Review any Published contributions from other Analysts."]
    },
    [constants.STEP_EXPLORE_MODEL]: {
        id: constants.STEP_EXPLORE_MODEL,
        open: ["If you have unpublished changes in your current Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","Is there anything you want to change in your Draft?","Review the GROUP DRAFT published by the Facilitator. Consider if you want to change your Draft based on the Group Draft, or contribute to the Discussion. The Group Draft forms the basis of the Group's final submission.", "Review any Published contributions from other Analysts."],
        openPublished: ["If you have unpublished changes in your current Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","Is there anything you want to change in your Draft?","Review the GROUP DRAFT published by the Facilitator. Consider if you want to change your Draft based on the Group Draft, or contribute to the Discussion. The Group Draft forms the basis of the Group's final submission.", "Review any Published contributions from other Analysts."]
    },
    [constants.STEP_BR_CANDIDATE_SOLUTION]: {
        id: constants.STEP_BR_CANDIDATE_SOLUTION,
        private: ["If you have unpublished changes in your current Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","Is there anything you want to change in your Draft?"],
        privatePublished: ["If you have unpublished changes in your current Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.", "Is there anything you want to change in your Draft?"],
        exchange: ["If you have unpublished changes in your current Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","Is there anything you want to change in your Draft?", "Review any Published contributions from other Analysts. Consider if you want to change your Draft based on their contributions, or start a Discussion topic.","Review and contribute to any recent Discussion activity."],
        exchangePublished: ["Review any Published contributions from other Analysts. Consider if you want to change your Draft based on their contributions, or start a Discussion topic.","Review and contribute to any recent Discussion activity."]
    },
    [constants.STEP_BR_REPORT]: {
        id: constants.STEP_BR_REPORT,
        report: ["Wait for the Facilitator to publish the Report.","Facilitator published Report will be available to review in the Group screen.","Review and contribute to any recent Discussion activity."],
        reportPublished: ["Review Published content from Facilitator on Group screen.","Start a Discussion topic.","Review and contribute to any recent Discussion activity."]
    },
    [constants.STEP_NBR_CANDIDATE_SOLUTION]: {
        id: constants.STEP_NBR_CANDIDATE_SOLUTION,
        private: ["If you have unpublished changes in your current Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","Is there anything you want to change in your Draft?"],
        privatePublished: ["If you have unpublished changes in your current Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.", "Is there anything you want to change in your Draft?"],
        exchange: ["If you have unpublished changes in your current Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","Is there anything you want to change in your Draft?", "Review any Published contributions from other Analysts. Consider if you want to change your Draft based on their contributions, or start a Discussion topic.","Review and contribute to any recent Discussion activity."],
        exchangePublished: ["Review any Published contributions from other Analysts. Consider if you want to change your Draft based on their contributions, or start a Discussion topic.","Review and contribute to any recent Discussion activity."]
    },
    [constants.STEP_NBR_REPORT]: {
        id: constants.STEP_NBR_REPORT,
        report: ["Wait for the Facilitator to publish the Report.","Facilitator published Report will be available to review in the Group screen.","Review and contribute to any recent Discussion activity."],
        reportPublished: ["Review Published content from Facilitator on Group screen.","Start a Discussion topic.","Review and contribute to any recent Discussion activity."]
    }
};

const WhatsNextFacilitator = {
    [constants.STEP_EXPLORE_PROBLEM]: {
        id: constants.STEP_EXPLORE_PROBLEM,
        private: ["If Analysts aren't Publishing their Drafts and the end of the Private Round is nearing, give them a nudge via MESSAGES.","Once enough Analysts have published their Drafts, and it is nearing the end of the Private Round, collate them in the Group Draft, and Publish it.","If Analysts haven't engaged and it is nearing the end of the Round, you have the option of extending the Private Round (via the ADMIN panel). Make sure you message Analysts at the same time giving them another nudge.","Once you have published the Group Draft, and the time is up for the end of the Private Round, go to ADMIN and launch the Exchange Round."],
        privatePublished: ["Keep an eye on new Published work from Analysts, and either re-collate them into a new Group draft, or add them incrementally to the current Group Draft.","As the end of the Exchange Round draws near, make a decision about BN/NBN route, and finalise and rePublish the Group Draft.", "If you have unpublished changes in the Group Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.", "Is there anything you want to change in the Group Draft?"],
        exchange: ["If you have unpublished changes in the Group Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","Is there anything you want to change in the Group Draft?","If Analysts are all engaged and there are still significant differences in their views on what the Key Elements are, you have the Option of extending the Exchange Round (via the ADMIN panel)", "Once the end of the Exchange Round is reached, go to ADMIN and launch the Build Network step."],
        exchangePublished: ["If you have unpublished changes in the Group Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","Is there anything you want to change in the Group Draft?","If Analysts are all engaged and there are still significant differences in their views on what the Key Elements are, you have the Option of extending the Exchange Round (via the ADMIN panel)", "Once the end of the Exchange Round is reached, go to ADMIN and launch the Build Network STEP_BAYESIAN_SOLUTION."]
    },
    [constants.STEP_BAYESIAN_SOLUTION]: {
        id: constants.STEP_BAYESIAN_SOLUTION,
        open: ["Once the Analysts have published their content, review them in the Published screen and make a version in the Group draft that is a coherent combination of their work. You can copy a single Analysts work into as the starting point at each step.","Once satisfied with Group Draft content, and when enough Analysts have already produced their own versions, publish by clicking on the Publish button. The content will then be shared to the Analysts on the Group screen.","Similarly work incrementally on the subsequent steps STRUCTURE, PARAMETERS and EXPLORE MODEL to create a Bayesian Network.","Go back to previously published steps in Build Bayesian Solution to modify the content.","Don't forget to click Publish to share the content with the Analysts and make it part of the final solution.","Review and contribute to any recent Discussion activity."],
        openPublished: ["If you have unpublished changes in the Group Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.", "Is there anything you want to change in your Draft?","Review and contribute to any recent Discussion activity."]
    },
    [constants.STEP_VARIABLES]: {
        id: constants.STEP_VARIABLES,
        open: ["Start by identifying Variables in the Problem.","If you have unpublished changes in your Group Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","You will be able to view other Analysts' work in the Published screen after you publish.", "The content published by the Facilitator can be viewed in the Group screen after you publish." , "You can initiate and participate in discussions in Discussion screen after you publish."],
        openPublished: ["If you have unpublished changes in the Group Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","Is there anything you want to change in Group Draft?","Review any Published contributions from other Analysts.","Consider if you want to change Group Draft based on the Analysts' submissions, or contribute to the Discussion. The Group Draft forms the basis of the Group's final submission."]
    },
    [constants.STEP_STRUCTURE]: {
        id: constants.STEP_STRUCTURE,
        open: ["If you have unpublished changes in your Group Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","You will be able to view other Analysts' work in the Published screen after you publish.", "The content published by the Facilitator can be viewed in the Group screen after you publish." , "You can initiate and participate in discussions in Discussion screen after you publish."],
        openPublished: ["If you have unpublished changes in your Group Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","Is there anything you want to change in Group Draft?","Review any Published contributions from other Analysts.","Consider if you want to change Group Draft based on the Analysts' submissions, or contribute to the Discussion. The Group Draft forms the basis of the Group's final submission."]
    },
    [constants.STEP_PARAMETERS]: {
        id: constants.STEP_PARAMETERS,
        open: ["If you have unpublished changes in your current Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","Is there anything you want to change in your Draft?","Review the GROUP DRAFT published by the Facilitator. Consider if you want to change your Draft based on the Group Draft, or contribute to the Discussion. The Group Draft forms the basis of the Group's final submission.", "Review any Published contributions from other Analysts."],
        openPublished: ["If you have unpublished changes in your current Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","Is there anything you want to change in your Draft?","Review the GROUP DRAFT published by the Facilitator. Consider if you want to change your Draft based on the Group Draft, or contribute to the Discussion. The Group Draft forms the basis of the Group's final submission.", "Review any Published contributions from other Analysts."]
    },
    [constants.STEP_EXPLORE_MODEL]: {
        id: constants.STEP_EXPLORE_MODEL,
        open: ["Explore the Network created in Structure step by running different scenarios.","If you have unpublished changes in your Group Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","You will be able to view other Analysts' work in the Published screen after you publish.", "The content published by the Facilitator can be viewed in the Group screen after you publish." , "You can initiate and participate in discussions in Discussion screen after you publish."],
        openPublished: ["If you have unpublished changes in your Group Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","Is there anything you want to change in Group Draft?","Review any Published contributions from other Analysts.","Consider if you want to change Group Draft based on the Analysts' submissions, or contribute to the Discussion. The Group Draft forms the basis of the Group's final submission."]
    },
    [constants.STEP_BR_CANDIDATE_SOLUTION]: {
        id: constants.STEP_BR_CANDIDATE_SOLUTION,
        private: ["If you have unpublished changes in Group Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","Is there anything you want to change in Group Draft?"],
        privatePublished: ["If you have unpublished changes in Group Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.", "Is there anything you want to change in Group Draft?"],
        exchange: ["If you have unpublished changes in Group Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","Is there anything you want to change in Group Draft?", "Review any Published contributions from other Analysts. Consider if you want to change Group Draft based on their contributions, or start a Discussion topic.","Review and contribute to any recent Discussion activity."],
        exchangePublished: ["Review any Published contributions from other Analysts. Consider if you want to change Group Draft based on their contributions, or start a Discussion topic.","Review and contribute to any recent Discussion activity."]
    },
    [constants.STEP_BR_REPORT]: {
        id: constants.STEP_BR_REPORT,
        report: ["If you have unpublished changes in Group Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","Is there anything you want to change in Group Draft?", "There will be no Analyst contribution for this step.","Start a Discussion topic to encourage Analysts to discuss the Report.","Review and contribute to any recent Discussion activity."],
        reportPublished: ["Consider if you want to change Group Draft based on their contributions","Start a Discussion topic to encourage Analysts to discuss the Report.","Review and contribute to any recent Discussion activity."]
    },
    [constants.STEP_NBR_CANDIDATE_SOLUTION]: {
        id: constants.STEP_NBR_CANDIDATE_SOLUTION,
        private: ["If you have unpublished changes in Group Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","Is there anything you want to change in Group Draft?"],
        privatePublished: ["If you have unpublished changes in Group Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.", "Is there anything you want to change in Group Draft?"],
        exchange: ["If you have unpublished changes in Group Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","Is there anything you want to change in Group Draft?", "Review any Published contributions from other Analysts. Consider if you want to change Group Draft based on their contributions, or start a Discussion topic.","Review and contribute to any recent Discussion activity."],
        exchangePublished: ["Review any Published contributions from other Analysts. Consider if you want to change Group Draft based on their contributions, or start a Discussion topic.","Review and contribute to any recent Discussion activity."]
    },
    [constants.STEP_NBR_REPORT]: {
        id: constants.STEP_NBR_REPORT,
        report: ["If you have unpublished changes in Group Draft, and are satisfied with it, go to the PUBLISH button (bottom right) and click to publish.","Is there anything you want to change in Group Draft?", "There will be no Analyst contribution for this step.","Start a Discussion topic to encourage Analysts to discuss the Report.","Review and contribute to any recent Discussion activity."],
        reportPublished: ["Consider if you want to change Group Draft based on their contributions","Start a Discussion topic to encourage Analysts to discuss the Report.","Review and contribute to any recent Discussion activity."]
    }
};

export default class WhatsNextData {
    
    static getInstance() {
        return WhatsNextData.instance || (WhatsNextData.instance = new WhatsNextData());
    }

    getWhatsNextContentForAnalyst() {
        return WhatsNextAnalyst;
    }

    getWhatsNextContentForFacilitator() {
        return WhatsNextFacilitator;
    }
    
}