import {Action, AnyAction, Reducer} from 'redux';

import ensureFieldPath from '../ensureFieldPath';
import {RESET_PROBLEM_STEP, SET_NOTES} from '../util/constants';



export function getDBSyncActionTypes() {
    return [SET_NOTES, RESET_PROBLEM_STEP];
}

export interface UserNotesAction extends Action {
    notes: string,
    problemStepId: number,
    userId: number
}

export type UserNotesReducerType = string;

const userNotesReducer: Reducer<UserNotesReducerType> = (state = '', action: UserNotesAction | AnyAction) => {
    switch (action.type) {
        case RESET_PROBLEM_STEP:
            return '';
        case SET_NOTES:
            return action.notes;
        default:
            return state;
    }
};

export default userNotesReducer;

export function setUserNotesAction(notes, problemStepId, userId): UserNotesAction {
    return {type: SET_NOTES, notes, problemStepId, userId};
}

export function getUserNotesFromStore(store, userId, problemStepId): string {
    return store.sharedState.teams[problemStepId].user[userId].notes;
}

export function setUserNotesInStore(store, problemStepId: number, userId: number, userNotes: string) {
    ensureFieldPath(store, 'sharedState', 'teams', problemStepId, 'user', userId);
    store.sharedState.teams[problemStepId].user[userId].notes = userNotes;
}
