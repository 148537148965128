import React from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Moment from 'moment';
import Select from 'react-select';
import {OnChangeValue} from 'react-select';
import {DialogContainer, TextField, List, ListItemControl, Button, FontIcon} from 'react-md';
import {find, indexOf, sortBy} from 'lodash';
import {v4} from 'uuid';
import classNames from 'classnames';

import {ChatMessageType} from '../../common/clientServer/chatMessage';
import {handleErrors, commonApiExceptionHandled} from '../util/ajaxUtils';
import {getFormattedMessageDateStamp, getFormattedMessageTimeStamp} from '../util/timeUtils';
import {buildUserRoleClass} from '../util/userStyle';
import {getLoggedInUserIdFromStore, getUserFromStore} from '../reducers/loginReducer';
import {
    saveUserProblemDraftMessageAction,
    deleteUserProblemDraftMessageAction,
    SingleUserForProblemReducerType,
    AllUsersForTeamReducerType
} from '../../common/reducers/allUsersForTeamReducer';
import {CombinedTeamReducerType} from '../../common/reducers/allTeamsReducer';
import {
    ChatForUserState,
    createInitChatAction,
    createSendChatMessageAction,
    getChatsForProblemAndUserFromStore
} from '../../common/reducers/chatsReducer';
import * as constants from '../../common/util/constants';
import ReferenceData from '../../common/util/referenceData';
import NotificationContainer from '../container/NotificationContainer';
import BardSnackbar from './BardSnackbar';
import SingleInput from './SingleInput';
import {getProblemDBFromStore, SingleProblemType} from '../../common/reducers/allProblemsReducer';
import commonSelectStyles from '../util/commonSelectStyles';
import {ObjectMapState} from '../../common/util/genericReducers';
import {DraftMessageType} from '../../server/orm/dao/userProblemDAO';
import DispatchProp from '../../@types/dispatchProp';
import {StoreWithSharedState} from '../../common/reducers/sharedStateReducer';
import {getTeamFromStore} from '../../common/reducers/allTeamsReducerGetters';
import {
    getAllUsersForProblemFromStore,
    getUserProblemDraftMessage
} from '../../common/reducers/allUsersForTeamReducerGetters';

import '../scss/userMessages.scss';

const DATE_FORMAT = 'YYYY-MM-DD';
const MSG_SEND_MESSAGE = {
    text: (<span>Your chat message has been sent and can be viewed on each individual chat channel.</span>)
};

interface UserMessagesOwnProps {
    problemStepId: number;
}

interface UserMessagesStoreProps {
    currentUser: SingleUserForProblemReducerType;
    allUsersForProblem: AllUsersForTeamReducerType;
    userId: number;
    team: CombinedTeamReducerType;
    problem: SingleProblemType;
    disabled: boolean;
    recipients: number[];
    chats: ObjectMapState<ChatForUserState>;
    draftMessage: DraftMessageType;
}

type UserMessagesProps = UserMessagesOwnProps & UserMessagesStoreProps & DispatchProp;

interface TemporaryMessage {
    id: string;
    chatId: string;
    multicastId?: string;
    createdAt: string;
    text: string;
    senderId: number;
    recipientId: number;
}

interface UserMessagesState {
    modalIsOpen: boolean;
    chatInputBuffer: string;
    currentDay: string;
    lastFetchedDates: {[key: number]: string};
    userTemporaryMessages: TemporaryMessage[];
    notAllowSendMessage: boolean;
    hasFetchedAllRecords: boolean;
    toastMessages: {text: React.ReactElement}[];
    sendChatViaEmail: boolean;
    targetRecipients: number[];
    isFetchingRecord: boolean;
    multiSelect?: boolean;
    selectedChatId?: string;
    latestMessageId?: string;
    canScrollToBottom?: boolean;
    isDraft?: boolean;
    errorMessage?: string;
    messages: {[chatId: string]: {[dateFrom: string]: ChatMessageType[]}};
    dismissNotification: {[userId: number]: () => void};
}

interface RecipientOption {
    label: React.ReactElement,
    value: number
}

class UserMessages extends React.Component<UserMessagesProps, UserMessagesState> {

    static propTypes = {
        problemStepId: PropTypes.number.isRequired
    };

    private chatContent;

    constructor(props) {
        super(props);
        this.state = {
            modalIsOpen: false,
            chatInputBuffer: '',
            currentDay: Moment().format(DATE_FORMAT),
            userTemporaryMessages: [],
            notAllowSendMessage: true,
            hasFetchedAllRecords: false,
            toastMessages: [],
            sendChatViaEmail: false,
            targetRecipients: [],
            isFetchingRecord: false,
            lastFetchedDates: {},
            messages: {},
            dismissNotification: {}
        };
        this.openModal = this.openModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.onChange = this.onChange.bind(this);
        this.onChatSendViaEmailSelectionChanged = this.onChatSendViaEmailSelectionChanged.bind(this);
        this.sendMessage = this.sendMessage.bind(this);
        this.fetchChatMessages = this.fetchChatMessages.bind(this);
        this.renderItem = this.renderItem.bind(this);
        this.renderMessages = this.renderMessages.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.reloadChat = this.reloadChat.bind(this);
        this.triggerFetchChatMessages = this.triggerFetchChatMessages.bind(this);
        this.handleChange = this.handleChange.bind(this);
    }

    UNSAFE_componentWillReceiveProps(props: UserMessagesProps) {
        if (this.state.targetRecipients.length !== 0 && !this.state.multiSelect) {
            const chat = this.findChat(props.chats, this.state.targetRecipients[0]);

            if (chat) {
                this.setState({
                    selectedChatId: chat.id,
                }, () => {
                    if (!chat.latestMessageId || this.state.latestMessageId !== chat.latestMessageId) {
                        this.setState({
                            latestMessageId: chat.latestMessageId,
                            currentDay: Moment(chat.latestMessageTimestamp).format(DATE_FORMAT)
                        }, () => {
                            this.fetchChatMessages(chat.id, this.state.currentDay);
                        });
                    }
                });
            }
        }
    }

    componentDidUpdate() {
        if (this.state.canScrollToBottom) {
            this.scrollToBottom();
        }
    }

    handleScroll(evt) {
        if (evt.target.scrollTop < 10) {
            this.triggerFetchChatMessages();
        }
    }

    openModal() {

        if (this.props.draftMessage && this.props.draftMessage.targetRecipients && this.props.draftMessage.targetRecipients.length > 0) {
            if (this.props.draftMessage.targetRecipients.length === 1) {
                this.reloadChat(this.props.draftMessage.targetRecipients);
                this.setState({multiSelect: false});
            } else {
                this.setState({multiSelect: true});
            }
            this.setState({
                targetRecipients: this.props.draftMessage.targetRecipients,
                modalIsOpen: true,
                chatInputBuffer: this.props.draftMessage.draft,
                isDraft: false,
                notAllowSendMessage: (this.props.draftMessage.draft === '' || this.props.draftMessage.targetRecipients.length < 1)
            })
        }
        else if (this.state.targetRecipients.length === 0) { // first load or no user in targetRecipients
            if (this.props.recipients.length >= 0) {
                this.setState({multiSelect: false, modalIsOpen: true, isDraft: true});
                this.reloadChat([this.props.recipients[0]]);
            }
        } else { // subsequent loading of message modal
            this.setState({modalIsOpen: true, isDraft: true});
            if (this.state.selectedChatId) {
                this.fetchChatMessages(this.state.selectedChatId, this.state.currentDay);
            }
        }
    }

    closeModal() {
        if (this.state.isDraft && this.state.chatInputBuffer.length > 0 && this.state.targetRecipients.length > 0) {
            const draftMessage = {targetRecipients: this.state.targetRecipients, draft: this.state.chatInputBuffer};
            this.props.dispatch(saveUserProblemDraftMessageAction(this.props.problemStepId, Number(this.props.userId), draftMessage));
        } else if (this.props.draftMessage && this.state.chatInputBuffer.length === 0) {
            this.props.dispatch(deleteUserProblemDraftMessageAction(this.props.problemStepId, Number(this.props.userId)));
        }
        this.setState({
            toastMessages: [],
            isDraft: false,
            modalIsOpen: false,
            notAllowSendMessage: (this.state.chatInputBuffer === '' || this.state.targetRecipients.length < 1)
        });
    }

    onChange(text) {
        this.setState({
            toastMessages: [],
            chatInputBuffer: text,
            notAllowSendMessage: (text === '' || this.state.targetRecipients.length === 0),
            isDraft: true
        });
    }

    onChatSendViaEmailSelectionChanged(val) {
        this.setState({sendChatViaEmail: val});
    }

    findChat(chats: ObjectMapState<ChatForUserState>, recipientId: number): ChatForUserState | undefined {
        if (chats) {
            return find(chats, (chat) => {
                return indexOf(chat.participants, recipientId) >= 0;
            });
        } else {
            return undefined;
        }
    }

    reloadChat(recipients: number[]) {
        this.setState({targetRecipients: recipients});
        if (recipients.length === 1) {
            this.reloadMessages(recipients[0]);
        } else {
            this.setState({selectedChatId: undefined});
            recipients.forEach((recipient) => {
                let chat = this.findChat(this.props.chats, recipient);
                if (!chat) {
                    this.setInitChat(recipient);
                }
            });
        }
    }

    setInitChat(recipientId: number) {
        this.props.dispatch(createInitChatAction(this.props.problemStepId, [Number(this.props.userId), recipientId]));
    }

    reloadMessages(userId?: number) {
        if (userId) {
            const chat = this.findChat(this.props.chats, userId);

            if (chat) {
                this.setState({
                    multiSelect: false,
                    targetRecipients: [userId],
                    selectedChatId: chat.id,
                    currentDay: chat.latestMessageId ? Moment(chat.latestMessageTimestamp).format(DATE_FORMAT) : this.state.currentDay,
                }, () => {
                    this.fetchChatMessages(chat.id, this.state.currentDay);
                });
            } else {
                this.setState({
                    targetRecipients: [userId]
                });
                this.props.dispatch(createInitChatAction(this.props.problemStepId, [Number(this.props.userId), Number(userId)]));
            }
        }
    }

    dispatchSendMessageAction(targetId, chat, multicastId, sendChatViaEmail) {
        const chatId = chat ? chat : this.state.selectedChatId;
        let action = createSendChatMessageAction(this.props.userId, targetId, this.state.chatInputBuffer, chatId, this.props.problemStepId, multicastId, sendChatViaEmail);
        this.setState({
            toastMessages: multicastId ? [MSG_SEND_MESSAGE] : [],
            chatInputBuffer: '',
            notAllowSendMessage: true,
            sendChatViaEmail: false,
            isDraft: false,
            userTemporaryMessages: [...this.state.userTemporaryMessages, {
                id: action.messageId,
                chatId: action.chatId,
                multicastId: action.multicastId,
                createdAt: action.createdAt,
                text: action.text,
                senderId: action.senderId,
                recipientId: action.recipientId
            }]
        });
        this.props.dispatch(action);
    }

    sendMessage() {
        if (this.state.chatInputBuffer === '') {
            return;
        }
        if (this.state.multiSelect) {
            const multicastId = v4();
            const sendChatViaEmail = this.state.sendChatViaEmail;
            this.state.targetRecipients.forEach((targetRecipient) => {
                const chat = this.findChat(this.props.chats, targetRecipient);
                if (chat) {
                    this.dispatchSendMessageAction(targetRecipient, chat.id, multicastId, sendChatViaEmail);
                }
            })
        } else {
            this.dispatchSendMessageAction(this.state.targetRecipients[0], null, null, this.state.sendChatViaEmail);
        }
    }

    triggerFetchChatMessages() {
        if (!this.state.isFetchingRecord && !this.state.hasFetchedAllRecords && this.state.selectedChatId) {
            const lastFetchedDay = this.state.lastFetchedDates[this.state.selectedChatId];
            this.setState({canScrollToBottom: false}, () => {
                this.fetchChatMessages(this.state.selectedChatId, Moment(lastFetchedDay).subtract(1, 'days').format(DATE_FORMAT));
            });
        }
    }

    fetchChatMessages(chatId, dateFrom) {
        this.setState({isFetchingRecord: true});
        return fetch(`/api/chats/${chatId}/messages?dateFrom=${encodeURIComponent(dateFrom)}`, {
            credentials: 'include',
            method: 'GET'
        })
            .then(handleErrors)
            .then((response) => {
                return response.json().then((body) => {
                    let messages: ChatMessageType[] = body.messages;

                    // use buffer
                    if (dateFrom === this.state.currentDay) {
                        Array.prototype.push.apply(messages, this.state.userTemporaryMessages.filter((tm) => {
                            return tm.chatId === chatId && messages.filter((m) => {
                                return m.id === tm.id
                            }).length === 0
                        }))
                    }
                    let currentChatMessageGroup = {...this.state.messages[chatId], [dateFrom]: messages};
                    let currentTotalMessagesFetched = Object.keys(currentChatMessageGroup).reduce((result, dateVal) => {
                        return result + currentChatMessageGroup[dateVal].length;
                    }, 0);

                    let hasFetchedAllRecords = currentTotalMessagesFetched === this.props.chats[chatId].messageCount;

                    this.setState({
                        messages: {
                            ...this.state.messages,
                            [chatId]: currentChatMessageGroup
                        },
                        lastFetchedDates: {...this.state.lastFetchedDates, [chatId]: dateFrom},
                        isFetchingRecord: false,
                        userTemporaryMessages: this.state.userTemporaryMessages.filter((tm) => {
                            return tm.chatId !== chatId
                        }),
                        canScrollToBottom: dateFrom === this.state.currentDay,
                        hasFetchedAllRecords: hasFetchedAllRecords
                    });

                    // dismiss notification if required.
                    if (this.state.modalIsOpen) {
                        if (this.props.currentUser.db.role === constants.ROLE_FACILITATOR) {
                            if (this.state.targetRecipients.length === 1) { // only dismiss message when one user is selected.
                                this.state.dismissNotification[this.state.targetRecipients[0]]();
                            }
                        } else {
                            this.state.dismissNotification[this.props.userId]();
                        }
                    }
                });
            })
            .catch((err) => {
                let errorCode = Number(err.message);
                commonApiExceptionHandled(errorCode, this.props.dispatch);
                this.setState({errorMessage: 'A problem occurred.', isFetchingRecord: false});
            });
    }

    getUserRole(userId) {
        return ReferenceData.getInstance().getAllUserRoles()[this.props.allUsersForProblem[userId].db.role]
    }

    getDisplayName(userId) {
        let user = this.props.allUsersForProblem[userId];
        return user ? (this.props.currentUser.db.userId === userId ? `${user.db.displayName} (ME)` : user.db.displayName) : constants.DISPLAY_REMOVED_USER;
    }

    scrollToBottom() {
        if (!this.chatContent) {
            return;
        }
        const scrollHeight = this.chatContent.scrollHeight;
        const height = this.chatContent.clientHeight;
        const maxScrollTop = scrollHeight - height;
        this.chatContent.scrollTop = maxScrollTop > 0 ? maxScrollTop : 0;
    }

    renderItem(message, key) {
        let formattedTimestamp = getFormattedMessageTimeStamp(message.createdAt);
        let senderRoleClass = buildUserRoleClass(this.getUserRole(message.senderId).role, this.props.currentUser.db.userId, message.senderId);
        let recipientRoleClass = buildUserRoleClass(this.getUserRole(message.recipientId).role, this.props.currentUser.db.userId, message.recipientId);
        let timestampClass = `activityTimeStamp ${senderRoleClass} override roleBackgroundColour`;

        return <ListItemControl
            className='chatMessageItem'
            key={key}
            leftAvatar={<div className={timestampClass}><span>{formattedTimestamp}</span></div>}
            primaryText=""
            primaryAction={
                <div>
                    <div className="messageTitle primary">
                        <span className={classNames('roleColour', senderRoleClass, 'override')}>
                            {this.getDisplayName(message.senderId)}
                        </span> to <span className={classNames('roleColour', recipientRoleClass, 'override')}>
                            {this.getDisplayName(message.recipientId)}
                        </span>:
                    </div>
                    <div className="messageBody">{message.text}</div>
                </div>
            }
        />;
    }

    renderMessages(messageDate, key) {
        const messages = this.state.selectedChatId ? this.state.messages[this.state.selectedChatId][messageDate] : [];

        return (
            <div key={key}>
                <div className='chatDate largeTextFont-px'>{getFormattedMessageDateStamp(messageDate)}</div>
                {
                    messages.length === 0 ? (<div>No messages</div>) : messages.map(this.renderItem)
                }
            </div>
        );
    }

    getOptions(): RecipientOption[] {
        return this.props.recipients.map((userId) => {
            return ({
                label: (
                    <span className='recipientOption'>
                        <span>{this.props.allUsersForProblem[userId].db.displayName}</span>
                        <NotificationContainer
                            tags={[constants.TAG_CHAT]}
                            keyValue={userId.toString()}
                            setDismiss={(dismiss) => {
                                this.setState((state) => ({dismissNotification: {...state.dismissNotification, [userId]: dismiss}}))
                            }}
                            showFlag={true}
                        />
                    </span>
                ),
                value: userId
            });
        });
    }

    render() {
        if (this.props.userId === undefined || this.props.currentUser === undefined) {
            return null;
        } else {
            const isFacilitator = this.props.currentUser.db.role === constants.ROLE_FACILITATOR;
            return (
                <>
                    <div className='headerMessages'>
                        <div onClick={this.props.disabled ? undefined : this.openModal}>
                            <FontIcon
                                className={"md-22 md-link messagesIcon" + (this.props.disabled ? " disabled" : "")}>message</FontIcon>
                            <NotificationContainer tags={[constants.TAG_CHAT]} showFlag={true} setDismiss={(dismiss) => {
                                this.setState((state) => ({dismissNotification: {...state.dismissNotification, [this.props.userId]: dismiss}}))
                            }}/>
                            <div className="headerMessagesLabel">MESSAGES</div>
                        </div>
                        {this.props.disabled ?
                            <span
                                className={this.state.modalIsOpen ? "toolTip toolTipMessages hide" : "toolTip toolTipMessages"}>Messages are unavailable for
                                {this.props.problem.isTraining ? ' Training ' :
                                    (this.props.team.problemStep.state === constants.STATE_CLOSED ? ' Closed ' : '')} Problems</span> :
                            <span
                                className={this.state.modalIsOpen ? "toolTip toolTipMessages hide" : "toolTip toolTipMessages"}>Messages between you and the {isFacilitator ? 'Analysts' : 'Facilitator'}</span>
                        }
                    </div>
                    {this.props.disabled ? null : (
                        <DialogContainer
                            id="chatMessages"
                            visible={this.state.modalIsOpen}
                            width='840px'
                            height='750px'
                            title="Facilitator messages"
                            onHide={this.closeModal}
                            actions={[
                                <Button key='closeButton' className="primaryActionButton" raised={true} primary={true}
                                        onClick={this.closeModal}>CLOSE</Button>,
                                <Button key='sendButton'
                                        className={classNames('sendMessageActionButton', {
                                        analyst: this.props.currentUser.db.role === constants.ROLE_ANALYST,
                                        facilitator: this.props.currentUser.db.role === constants.ROLE_FACILITATOR,
                                        observer: this.props.currentUser.db.role === constants.ROLE_OBSERVER,
                                        roleBackgroundColour: !this.state.notAllowSendMessage
                                    })}
                                    disabled={this.state.notAllowSendMessage}
                                    raised={true} primary={true} onClick={this.sendMessage}
                                >
                                    SEND MESSAGE
                                </Button>
                            ]}
                            autosizeContent={false}
                            stackedActions={false}
                        >
                            {
                                this.props.recipients.length === 0 ? (this.renderNoRecipients()) : (null)
                            }

                            {this.renderRecipients()}

                            {this.renderChatContent()}

                            {this.renderChatControl(isFacilitator)}
                            {
                                this.state.toastMessages
                                    ? (<BardSnackbar
                                        id='userMessagesSnackbar'
                                        toasts={this.state.toastMessages}
                                        autohide={true}
                                        positionLeftSecond={true}
                                        size={BardSnackbar.SIZE_LARGE}
                                        onDismiss={() => {}}
                                    />)
                                    : null
                            }

                        </DialogContainer>
                    )}
                </>
            );
        }
    }

    renderNoRecipients() {
        return <div className='noRecipientInfo'>There are no receipients.</div>;
    }

    handleChange(selectedRecipients: OnChangeValue<RecipientOption, true>) {
        const recipients = selectedRecipients.map((option) => (option.value));
        this.setState({
            toastMessages: [],
            notAllowSendMessage: (this.state.chatInputBuffer === '' || recipients.length < 1),
            isDraft: true
        });
        if (recipients.length <= 1) {
            this.setState({multiSelect: false}, () => {
                if (recipients.length === 1) {
                    this.state.dismissNotification[recipients[0]]();
                }
                this.reloadChat(recipients);
            });
        } else {
            this.setState({multiSelect: true, targetRecipients: recipients}, () => {
                this.reloadChat(this.state.targetRecipients);
            });
        }

    }

    renderRecipients() {
        if (this.props.recipients.length === 0) {
            return null;
        }
        const options = this.props.disabled ? undefined : this.getOptions();
        return (this.props.currentUser.db.role === constants.ROLE_FACILITATOR) ? (
            <Select
                className='chatRecipients'
                classNamePrefix='Select'
                isClearable={true}
                closeMenuOnSelect={false}
                isSearchable={false}
                options={options}
                onChange={this.handleChange}
                value={(options || []).filter(({value}) => (this.state.targetRecipients.indexOf(value) >= 0))}
                styles={commonSelectStyles}
                placeholder="Select..."
                isMulti={true}
            />
        ) : (
            null
        );
    }

    renderChatControl(isFacilitator) {
        if (this.props.recipients.length === 0) {
            return (null);
        }
        const recipient = this.state.targetRecipients.length > 0 ? this.state.targetRecipients[0] : undefined;
        const label = this.state.multiSelect ? 'Message to multiple recipients' : `Message to ${this.getDisplayName(recipient)}`;
        return (
            <div className="chatControl">
                <div className="chatBox">
                    <TextField
                        id="chatInput"
                        label={label}
                        rows={2}
                        maxRows={4}
                        value={this.state.chatInputBuffer}
                        onChange={this.onChange}
                        disabled={this.state.targetRecipients.length === 0}
                    />
                </div>
                {
                    isFacilitator ? (<div className='chatSendViaEmail'>
                        <SingleInput id="chatSendViaEmail" content={this.state.sendChatViaEmail}
                                     inputType='checkbox' onChange={this.onChatSendViaEmailSelectionChanged}/>
                        <label htmlFor="chatSendViaEmail">Send a copy via email</label>
                    </div>) : (null)
                }
            </div>
        );
    }

    renderChatContent() {
        if (this.props.recipients.length === 0) {
            return (null);
        }

        if (this.state.multiSelect) {
            return (
                <div className="chatContent">
                    &nbsp;
                </div>
            );
        }

        if (this.state.targetRecipients.length === 0) {
            return (
                <div className="chatContent">
                    Select one or more recipients for a chat message.
                </div>
            );
        }

        return (
            <div className="chatContent"
                 ref={(chatContent) => {
                     this.chatContent = chatContent;
                 }}
                 onScroll={this.handleScroll}>
                {
                    this.state.hasFetchedAllRecords ? (null) : (
                        <Button className="secondaryActionButton chatLoadPrevious" flat={true}
                                onClick={this.triggerFetchChatMessages}>Show Previous Day</Button>)
                }

                {
                    (this.state.selectedChatId && this.state.messages[this.state.selectedChatId]) ?
                        (
                            <List className="md-list-unstyled chatMessageList md-list-generic">
                                {
                                    sortBy(Object.keys(this.state.messages[this.state.selectedChatId]), (messageGroupDate) => {
                                        return Moment(messageGroupDate);
                                    }).map(this.renderMessages)
                                }
                            </List>
                        ) : this.state.hasFetchedAllRecords ? null : (<span>loading...</span>)
                }
            </div>
        );
    }
}

function getChatRecipients(users: AllUsersForTeamReducerType, currentUser: SingleUserForProblemReducerType): number[] {
    const sendToFacilitator = (currentUser.db.role !== constants.ROLE_FACILITATOR);
    return Object.keys(users)
        .map((userIdString) => (Number(userIdString)))
        .filter((userId) => (
            userId !== currentUser.db.userId && (users[userId].db.role === constants.ROLE_FACILITATOR) === sendToFacilitator
        ));
}

function mapStoreToProps(store: StoreWithSharedState, myProps: UserMessagesOwnProps): UserMessagesStoreProps {
    const problemStepId = myProps.problemStepId;
    const userId = getLoggedInUserIdFromStore(store);
    const team = getTeamFromStore(store, myProps.problemStepId);
    const problem = getProblemDBFromStore(store, team.problemStep.problemId);
    const disabled = (problem.isTraining || team.problemStep.state === constants.STATE_CLOSED);
    let users, currentUser, chats, recipients, draftMessage;
    if (!disabled) {
        users = getAllUsersForProblemFromStore(store, problemStepId);
        currentUser = users[getUserFromStore(store).id];
        chats = getChatsForProblemAndUserFromStore(store, String(problemStepId), String(userId));
        recipients = getChatRecipients(users, currentUser);
        draftMessage = getUserProblemDraftMessage(store, myProps.problemStepId, userId);
    }
    return {
        currentUser: currentUser,
        allUsersForProblem: users,
        userId,
        team,
        problem,
        disabled,
        recipients,
        chats,
        draftMessage
    };
}

export default connect(mapStoreToProps)(UserMessages);
