import React from 'react';
import ReactDOM from 'react-dom';

import 'tether-shepherd/dist/css/shepherd-theme-arrows.css';
import './client/scss/tour.scss';
import './client/scss/app.scss';

import App from './client/App';
// import registerServiceWorker from './util/registerServiceWorker';

ReactDOM.render(<App />, document.getElementById('root'));
// eslint-disable-next-line
//registerServiceWorker();


