import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Select from 'react-select';
import {DialogContainer, Button} from 'react-md';
import {detect} from 'detect-browser';

import RichTextField from './RichTextField';
import {getUserFromStore} from '../reducers/loginReducer';
import {DEFAULT_FEEDBACK_TYPE, BARD_FEEDBACK_TYPES, BARD_HELP_TYPES, getUserFeedbackFromStore, updateUserFeedbackAction, submitUserFeedbackAction} from '../../common/reducers/userFeedbackReducer';
import {UPLOAD_IMAGE_PATH} from "../../common/clientServer/navigation";

class UserFeedback extends Component {

    static propTypes = {
        type: PropTypes.string.isRequired,
        onClose: PropTypes.func,
        className: PropTypes.string
    };  

    constructor(props) {
        super(props);

        let options = [];
        switch (this.props.type) {
            case 'userFeedback':
                options = BARD_FEEDBACK_TYPES;
                break;
            case 'bardHelp':
                options = BARD_HELP_TYPES;
                break;
            default:
        }

        const browser = detect();
        const browserName = browser ? browser.name : 'Unrecognised';

        this.state = {
            browserName: browserName,
            flushChanges: {},
            modalIsOpen: false,
            feedbackType: this.props.type === 'userFeedback'
                            ? (this.props.userFeedback ? this.props.userFeedback.feedbackType : DEFAULT_FEEDBACK_TYPE)
                            : (this.props.bardHelp ? this.props.bardHelp.feedbackType : DEFAULT_FEEDBACK_TYPE),
            options: options,
            text: this.props.type === 'userFeedback'
                    ? (this.props.userFeedback ? this.props.userFeedback.text :'')
                    : (this.props.batdHelp ? this.props.bardHelp.text : '')
        };

        this.openModal = this.openModal.bind(this);
        this.afterOpenModal = this.afterOpenModal.bind(this);
        this.closeModal = this.closeModal.bind(this);
        this.update = this.update.bind(this);
        this.submit = this.submit.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.onClose = this.onClose.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.onChangeText = this.onChangeText.bind(this);
        this.onChangeType = this.onChangeType.bind(this);
        this.addFlushChanges = this.addFlushChanges.bind(this);

    }

    getLocation() {
        return window.location.href;
    }

    addFlushChanges(key, handler) {
        this.setState((oldState) => ({flushChanges: {...oldState.flushChanges, [key]: handler}}));
    }

    openModal() {
        this.setState({
            modalIsOpen: true
        });
    }

    afterOpenModal() { }

    closeModal() {
        this.setState({ 
            modalIsOpen: false
        }, () => { if (typeof(this.props.onClose) !== 'undefined') { this.props.onClose(); } });
    }

    update() {
        this.props.dispatch(updateUserFeedbackAction(this.props.user.id,
                                                     this.props.type, 
                                                     this.props.user.loginName,
                                                     this.state.browserName,
                                                     this.state.feedbackType === DEFAULT_FEEDBACK_TYPE ? (this.props.type === 'userFeedback' ? 'enhancement' : 'bardhelp') : this.state.feedbackType, 
                                                     this.getLocation(), 
                                                     this.state.text));

        this.closeModal();
    }

    submit() {
        // need to do some string replacements to turn the text into html text
        let text = this.state.text
        
                            // need to replace '&lt;' with '<' and '&gt;' with '>'
            
                            .replace(/&lt;/g, "<")
                            .replace(/&gt;/g, ">")

                            // need to replace '\n' with '<br>'
                           
                            .replace(/\n/g, "<br>")
            
                            // the following replaces are because I didn't like the spacing in the html email
                            // I don't know if this is specific to CKEdit, but it helps to strip "<br>" following
                            // any of "</p>", "</h[1-6]>"
                            // and yes, I know there are more elegant ways of pulling out the headers - I could, 
                            // for example, have used a regex match and pulled out the match and put the header 
                            // matched back in, but sometimes just unrolling things is easier and cleaner

                            .replace(/<\/p><br>/g, "</p>")
                            .replace(/<\/h1><br>/g, "</h1>")
                            .replace(/<\/h2><br>/g, "</h2>")
                            .replace(/<\/h3><br>/g, "</h3>")
                            .replace(/<\/h4><br>/g, "</h4>")
                            .replace(/<\/h5><br>/g, "</h5>")
                            .replace(/<\/h6><br>/g, "</h6>");
         
        this.props.dispatch(submitUserFeedbackAction(this.props.user.id, 
                                                     this.props.type, 
                                                     this.props.user.loginName,
                                                     this.state.browserName,
                                                     this.state.feedbackType === DEFAULT_FEEDBACK_TYPE ? (this.props.type === 'userFeedback' ? 'enhancement' : 'bardhelp') : this.state.feedbackType, 
                                                     this.getLocation(), 
                                                     text));
 
        this.setState({
            feedbackType: DEFAULT_FEEDBACK_TYPE,
            text: ''
        }, () => {this.update();});
    }

    onCancel() {
        Object.keys(this.state.flushChanges).forEach((key) => {
            this.state.flushChanges[key]();
        });

        this.setState({
            feedbackType: DEFAULT_FEEDBACK_TYPE,
            text: ''
        }, () => {this.update();});
    }

    onClose() {
        Object.keys(this.state.flushChanges).forEach((key) => {
            this.state.flushChanges[key]();
        });

        this.setState({ }, () => {this.update()})
    }

    onSubmit() {
        Object.keys(this.state.flushChanges).forEach((key) => {
            this.state.flushChanges[key]();
        });

        this.setState({ }, () => {this.submit()});
    }

    onChangeText(text) {
        this.setState({
            text
        });
    }

    onChangeType(type) {
        if (type) {
            this.setState({ feedbackType: type.value });
        } else {
            this.setState({ feedbackType: (this.props.type === 'userFeedback' ? 'enhancement' : 'bardhelp') });
        }
    }

    renderFeedbackType() {
        const value = this.state.feedbackType === DEFAULT_FEEDBACK_TYPE ? (this.props.type === 'userFeedback' ? 'enhancement' : 'bardhelp') : this.state.feedbackType;
        const selectedOption = this.state.options.find((option) => (option.value === value));
        return (
            <Select className='feedbackTypeSelect'
                value={selectedOption}
                options={this.state.options}
                onChange={this.onChangeType}
            />
        );
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (this.props.type === 'userFeedback' && props.userFeedback) {
            this.setState({
                feedbackType: props.userFeedback.feedbackType,
                text: props.userFeedback.text
            });
        }
        else if (this.props.type === 'bardHelp' && props.bardHelp) {
            this.setState({
                feedbackType: props.bardHelp.feedbackType,
                text: props.bardHelp.text
            });
        }
    }


    render() {
        if (typeof(this.props.user) === 'undefined') {
            return null;
        } else {
    
            let introText = this.props.type === 'userFeedback' ? (
                <p>
                    The BARD online tool supports problem analysis and decision making, both by individuals and collaboratively for groups.
                    <br/><br/>
                    The BARD team is always striving to improve the BARD tool to better suit the needs of our users.  Your feedback will be 
                    very valuable to us as we develop and improve the BARD tool.  Please enter your feedback below, and provide as much detail
                    as possible - the more detailed your feedback the greater the likelihood that we can address your feedback accurately.
                    <br/><br/>
                </p>
            ) : (
                <p>
                    The BARD online tool supports problem analysis and decision making, both by individuals and collaboratively for groups.
                    <br/><br/>
                    If you need help with, or have questions about, BARD please enter your request for help below, and provide as much detail as 
                    possible - the more detailed your request for help the greater the likelihood that we can address your request accurately.
                    <br/><br/>
                </p>
            );

            let richTextFieldConfig = {
                uploadUrl: (this.props.type === 'userFeedback' || this.props.type === 'bardHelp')? UPLOAD_IMAGE_PATH : ``,
                toolbarCanCollapse: true,
                readOnly: false
            };

            let className = this.props.className ? this.props.className + ' ' + this.props.type : this.props.type;
            let divText = this.props.type === 'userFeedback' ? 'BARD Feedback' : 'BARD Help Desk';

            return (
                <div className={className} onClick={this.openModal}>
                    {divText}

                    <DialogContainer
                        id="feedbackContainer"
                        visible={this.state.modalIsOpen}
                        width='820px'
                        height='665px'
                        title={this.props.type === 'userFeedback' ? 'Give Us Your Feedback' : 'Enter Your BARD Help Request'}
                        onHide={this.onClose}
                        onShow={this.afterOpenModal}
                        actions={[  
                                    <Button className='secondaryActionButton' flat onClick={this.onCancel}>DISCARD</Button>, 
                                    <Button className='secondaryActionButton' flat onClick={this.onClose}>SAVE FOR LATER</Button>, 
                                    <Button className='primaryActionButton' raised primary onClick={this.onSubmit}>SUBMIT</Button>
                                ]}
                        autosizeContent={false}
                        stackedActions={false}
                    >
                        <div className='feedbackIntro'>
                        {introText}
                            <div>
                                <span>{this.props.type === 'userFeedback' ? 'Select the type of feedback you want to provide' : 'Select the type of help you need'}&nbsp;&nbsp;</span>
                                {this.renderFeedbackType()}
                                <span>&nbsp;&nbsp;then enter the details below.</span>
                            </div>
                        </div>

                        <RichTextField
                            className='feedbackText'
                            text={this.state.text}
                            forceUpdate={this.state.text === ''}
                            onChange={this.onChangeText}
                            addFlushChanges={this.addFlushChanges}
                            config={richTextFieldConfig}
                        />
                    </DialogContainer>
                </div>
            );
        }
    }
}

function mapStoreToProps(store) {
    const user = getUserFromStore(store);
    return {
        user,
        userFeedback: getUserFeedbackFromStore(store, user.id, 'userFeedback'),
        bardHelp: getUserFeedbackFromStore(store, user.id, 'bardHelp')
    }
}

export default connect(mapStoreToProps)(UserFeedback);
