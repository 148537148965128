import React from 'react';
import {connect} from 'react-redux';
import {match, RouteComponentProps, withRouter} from 'react-router-dom';
import {Button} from 'react-md';
import {FontIcon} from 'react-md';
import moment from 'moment';

import * as constants from '../../common/util/constants';
import {getUserFromStore} from '../reducers/loginReducer';
import {AllTeamsReducerType} from '../../common/reducers/allTeamsReducer'
import {AllProblemsReducerType, getAllProblemDBsFromStore} from '../../common/reducers/allProblemsReducer';
import IconLabelButton from './IconLabelButton';
import ProblemSummary from './ProblemSummary';
import ProblemDetail from './ProblemDetail';
import {getDefaultProblemStepIdFromStore} from '../reducers/defaultProblemStepIdReducer';
import {buildRestPath, PROBLEM_PATH, SELECT_PATH} from '../../common/clientServer/navigation';
import {getBardTrainingUrlFromStore} from '../../common/reducers/bardTrainingUrlReducer';
import {getLoginPathFromStore} from '../../common/reducers/loginPathReducer';
import {BardUser} from '../../common/clientServer/bardUser';
import {getAllTeamsFromStore} from '../../common/reducers/allTeamsReducerGetters';

import '../scss/foyer.scss';

interface FoyerProps {
    user: BardUser;
    teams: AllTeamsReducerType;
    problems: AllProblemsReducerType;
    defaultProblemStepId?: number;
    bardTrainingUrl: string;
    TELoginPath: string;
    match: match<{problemStepId?: string}>;
}

interface FoyerState {
    selectedTeamId: number;
    problemStep: number | null;
    readyTeamIds: number[];
    trainingTeamIds: number[];
    closedTeamIds: number[];
    activeTeamIds: number[];
    hideTrainingProblems: boolean;
}

class Foyer extends React.Component<FoyerProps & RouteComponentProps, FoyerState> {

    constructor(props: FoyerProps & RouteComponentProps) {
        super(props);
        this.state = {
            selectedTeamId: -1,
            problemStep: null,
            readyTeamIds: [],
            trainingTeamIds: [],
            closedTeamIds: [],
            activeTeamIds: [],
            hideTrainingProblems: true
        };        
        this.onSelectProblem = this.onSelectProblem.bind(this);
    }

    /**
     * Sort a list of problemStepIds using the list of getter functions to pull out field values of the corresponding
     * problemStep and associated problem to sort on.  The IDs are sorted on the values of the fields returned by the
     * first getter first, and then any for which those values are equal are sorted using the values of the fields
     * returned by the second getter, etc.  If all fields are equal, sort by id, so there is always a deterministic
     * order.
     */
    static sortProblemStepIds(idList, ...fieldGetters) {
        idList.sort((id1, id2) => {
            for (let index = 0; index < fieldGetters.length; ++index) {
                const field1 = fieldGetters[index](id1);
                const field2 = fieldGetters[index](id2);
                if (field1 < field2) {
                    return -1;
                } else if (field1 > field2) {
                    return 1;
                }
                // Else continue around the for loop and compare the fields returned by the next getter.
            }
            // All fields returned by the getters are equal - sort by id.
            return (id1 === id2) ? 0 : ((id1 < id2) ? -1 : 1);
        })
    }

    static canSelectTeam(team, userId, problem): boolean {
        if (!team) {
            return false;
        }

        if (!team.problemStep) {
            return false
        }

        if (!team.user) {
            return false
        }

        if (!problem) {
            return false
        }

        if (problem.isTraining) {
            return true;
        }

        if (!team.user[userId]) {
            return false
        }

        return ((team.problemStep.state === constants.STATE_READY || team.problemStep.state === constants.STATE_ACTIVE || team.problemStep.state === constants.STATE_CLOSED) && (team.user !== null) && (team.user[userId] !== null));
    }

    static isTeamIdValid(teamId: number | undefined, props: FoyerProps): teamId is number {
        return (teamId && props.teams[teamId]
            && Foyer.canSelectTeam(props.teams[teamId], props.user.id, props.problems[props.teams[teamId].problemStep.problemId]))
            || false;
    }

    static getDerivedStateFromProps(props: FoyerProps, state: FoyerState) {
        let activeTeamIds: number[] = [];
        let trainingTeamIds: number[] = [];
        let readyTeamIds: number[] = [];
        let closedTeamIds: number[] = [];

        const {teams, user, problems} = props;

        if (!teams || Object.keys(teams).length === 0 || !problems || Object.keys(problems).length === 0) {
            return null;
        }

        Object.keys(teams)
            .map((teamIdString) => (Number(teamIdString)))
            .forEach((teamId) => {
                const currentTeam = teams[teamId];
                const problemId = currentTeam.problemStep.problemId;
                const currentProblem = problems[problemId];
                if (Foyer.canSelectTeam(currentTeam, user.id, currentProblem)) {
                    if (currentProblem.isTraining) {
                        trainingTeamIds.push(teamId);
                    } else {
                        switch (currentTeam.problemStep && currentTeam.problemStep.state) {
                            case constants.STATE_ACTIVE:
                                activeTeamIds.push(teamId);
                                break;
                            case constants.STATE_CLOSED:
                                closedTeamIds.push(teamId);
                                break;
                            case constants.STATE_READY:
                                readyTeamIds.push(teamId);
                                break;
                            default:
                        }
                    }
                }
            });

        // Sort the various problemStepId lists by different criteria.
        Foyer.sortProblemStepIds(activeTeamIds, (id) => (problems[teams[id].problemStep.problemId].title));
        Foyer.sortProblemStepIds(trainingTeamIds,
            (id) => (teams[id].problemStep.state),
            (id) => (problems[teams[id].problemStep.problemId].title),
            (id) => (teams[id].problemStep.stepId)
        );
        Foyer.sortProblemStepIds(closedTeamIds, (id) => (problems[teams[id].problemStep.problemId].title));
        Foyer.sortProblemStepIds(readyTeamIds,
            (id) => (problems[teams[id].problemStep.problemId].expectedStart && moment(problems[teams[id].problemStep.problemId].expectedStart).valueOf()),
            (id) => (problems[teams[id].problemStep.problemId].title)
        );

        const paramProblemStepId = props.match && Number(props.match.params.problemStepId);

        let selectedTeamId = Foyer.isTeamIdValid(state.selectedTeamId, props) ? state.selectedTeamId
            : Foyer.isTeamIdValid(paramProblemStepId, props) ? paramProblemStepId
            : Foyer.isTeamIdValid(props.defaultProblemStepId, props) ? props.defaultProblemStepId
            : -1;

        // If no team has been selected, default to the first problem in the most interesting list with values.
        if (selectedTeamId < 0) {
            if (activeTeamIds.length > 0) {
                selectedTeamId = activeTeamIds[0];
            } else if (trainingTeamIds.length > 0) {
                selectedTeamId = trainingTeamIds[0];
            } else if (closedTeamIds.length > 0) {
                selectedTeamId = closedTeamIds[0];
            } else if (readyTeamIds.length > 0) {
                selectedTeamId = readyTeamIds[0];
            }
        }

        let hideTrainingProblems = true;
        trainingTeamIds.forEach((trainingProblemId) => {
            if(trainingProblemId === selectedTeamId){
                hideTrainingProblems = false;
            }
        });

        return {activeTeamIds, trainingTeamIds, closedTeamIds, readyTeamIds, hideTrainingProblems, selectedTeamId};
    }

    onSelectProblem(problemStepId) {
        this.props.history.replace(buildRestPath(SELECT_PATH, {problemStepId}));
        this.setState({selectedTeamId: problemStepId});
    }   

    renderTrainingPanel() {
        return <div className='trainingBanner' >
            <div className='minorTitle-px trainingTitle' > Bard Training </div>
            <div className='trainingInner' >
                <div className='refresher' >
                    If you want to do more or need a refresher, BARD Training is always available. </div>
                <div className='buttonContainer' >
                    <IconLabelButton iconChildren='help' label='Open BARD Training' target='_blank' rel='noopener noreferrer' href={this.props.bardTrainingUrl} />
                </div>
            </div>
        </div>;
    }

    getProblem(problems, problemId) {
        return problems[problemId];
    }

    renderLeftPanelProblems(whichProblems, assignedProblemIds) {

        if (!this.props.teams) {
            return null;
        }

        if (assignedProblemIds.length === 0) {
            return null;
        }

        let infoStr = '';
        switch (whichProblems) {
            case constants.STATE_ACTIVE:
                infoStr = 'AVAILABLE PROBLEMS (' + assignedProblemIds.length + ')';
                break;

            case constants.STATE_TRAINING_PROBLEM:
                infoStr = 'TRAINING PROBLEMS (' + assignedProblemIds.length + ')';
                break;

            case constants.STATE_CLOSED:
                infoStr = 'CLOSED PROBLEMS (' + assignedProblemIds.length + ')';
                break;
                
            case constants.STATE_READY:
                infoStr = 'SCHEDULED PROBLEMS (' + assignedProblemIds.length + ')';
                break;

            default:
                break;
        }
        const selectedProblem = assignedProblemIds.length > 0 && assignedProblemIds.reduce((selected, stepId) => {
            if(this.state.selectedTeamId === stepId){
                selected = stepId
            }
            return selected;
        },null);

        return (
            <div className="foyerLeftPanelProblemsDisplay">
                <div className='textFont foyerPageLeftPanelHeaderDiv'>{infoStr}</div>
                {
                    (whichProblems === constants.STATE_TRAINING_PROBLEM && this.state.hideTrainingProblems) ? (
                        <div className='trainingProblemsIntro'>
                            <p>
                                Use these BARD training problems to try out what you have learned in&nbsp;
                                <a target='_blank' rel='noopener noreferrer' href={this.props.bardTrainingUrl}>BARD training</a>.
                            </p>
                            <Button raised={true} className='buttonStyle openButtonStyle' onClick={() => {
                                this.setState({hideTrainingProblems: false, selectedTeamId: selectedProblem || assignedProblemIds[0] })
                            }}>
                                Show Training Problems
                            </Button>
                        </div>
                    ) : (
                        assignedProblemIds.map((teamId) => (
                            <ProblemSummary
                                key={teamId}
                                teamId={Number(teamId)}
                                selectedTeamId={this.state.selectedTeamId}
                                problemState={whichProblems}
                                problemSelectionHandler={this.onSelectProblem}
                            />))
                    )
                }
            </div>
        );
    }

    renderLeftPanel() {
        return (
            <div>
                {this.renderLeftPanelProblems(constants.STATE_ACTIVE, this.state.activeTeamIds)}
                {this.renderLeftPanelProblems(constants.STATE_TRAINING_PROBLEM, this.state.trainingTeamIds)}
                {this.renderLeftPanelProblems(constants.STATE_CLOSED, this.state.closedTeamIds)}
                {this.renderLeftPanelProblems(constants.STATE_READY, this.state.readyTeamIds)}
            </div>
        );
    }

    renderRightPanel() {
        if (this.state.selectedTeamId < 0) {
            console.log('Foyer: renderRightPanel: selectedTeamId = ' + this.state.selectedTeamId);
            return null;
        }

        let selectedTeamId = Number(this.state.selectedTeamId);
        let selectedTeam = this.props.teams[selectedTeamId];
        if (!selectedTeam) {
            console.log('Foyer: renderRightPanel: invalid selectedTeamId = ' + this.state.selectedTeamId);
            return null;
        }
        const selectedProblem = this.getProblem(this.props.problems, selectedTeam.problemStep.problemId);

        if (selectedProblem.isTraining && this.state.hideTrainingProblems) {
            return (<div className='foyerRightPanel'/>);
        }

        return (
            <div className='foyerRightPanel'>
                <div className='textFont foyerPageRightPanelHeaderDiv'>SELECTED PROBLEM</div>
                <ProblemDetail problemStepId={selectedTeamId} problemOpenedHandler={() => {this.props.history.push(buildRestPath(PROBLEM_PATH, {problemStepId: selectedTeamId}));}} />
            </div>
        );
    }

    render () {
        return (
            <div className='foyerPage'>

                {this.renderTrainingPanel()}

                <div className='textFont foyerPageMainInfoDiv'/>

                {
                    (this.state.activeTeamIds.length + this.state.trainingTeamIds.length +
                        this.state.closedTeamIds.length + this.state.readyTeamIds.length === 0) ? (
                        <div className='textFont noProblems'>
                            <p className='fetching'>Fetching your problems...</p>

                            <p><FontIcon className='md-light'>warning</FontIcon> Note that some anti-malware software
                                (such as Sophos or Adaware) can interfere with BARD's communication.  If you
                                continue to see this message, try adding an exception for BARD to your anti-malware
                                configuration.</p>
                        </div>
                    ) : (
                        <div className='foyerColumns'>

                            <div className='foyerLeftPanel textFont'>
                                {this.renderLeftPanel()}
                            </div>

                            {this.renderRightPanel()}

                        </div>
                    )
                }
            </div>
        )
    }

}

function mapStoreToProps(store) {
    return {
        user: getUserFromStore(store),
        teams: getAllTeamsFromStore(store),
        problems: getAllProblemDBsFromStore(store),
        defaultProblemStepId: getDefaultProblemStepIdFromStore(store),
        bardTrainingUrl: getBardTrainingUrlFromStore(store),
        TELoginPath: getLoginPathFromStore(store)
    };
}

export default withRouter(connect(mapStoreToProps)(Foyer));
