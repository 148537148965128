import React, { Component } from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getLoggedInUserIdFromStore} from '../reducers/loginReducer';
import {getTeamFromStore} from "../../common/reducers/allTeamsReducerGetters";
import {getUserForProblemFromStore} from "../../common/reducers/allUsersForTeamReducerGetters";

class ProblemSelectedContainer extends Component {

    static propTypes = {
        whenSelected: PropTypes.bool.isRequired,
        problemStepId: PropTypes.number
    };

    render() {
        const show = (!!this.props.userForProblem) === this.props.whenSelected;
        if (show) {
            return <div className="homeHeaderUserWrapPanes">{this.props.children}</div>;
        } else {
            return null;
        }
    }
}

function mapStoreToProps(store, myProps) {
    const userId = getLoggedInUserIdFromStore(store);
    const team = myProps.problemStepId ? getTeamFromStore(store, myProps.problemStepId) : null;
    return {
        userForProblem: team ? getUserForProblemFromStore(store, myProps.problemStepId, userId) : null,
    }
}

export default connect(mapStoreToProps)(ProblemSelectedContainer);
