import React from "react";

const SVGDefs = ({ arrowLength, arcColour }) => (
    <defs>
        <marker
            id="arrow"
            markerWidth={arrowLength}
            markerHeight="5"
            refX="0"
            refY="2.5"
            orient="auto"
            markerUnits="strokeWidth"
        >
            <path d={`M0,0 L0,5 L${arrowLength / 2},2.5 z`} fill={arcColour} />
        </marker>
        <filter id="shadow" x="-50%" y="-50%" width="200%" height="200%">
            <feGaussianBlur in="SourceAlpha" stdDeviation="3" />
            <feComponentTransfer>
                <feFuncA type="linear" slope="0.1" />
            </feComponentTransfer>
            <feMerge>
                <feMergeNode />
                <feMergeNode in="SourceGraphic" />
            </feMerge>
        </filter>
    </defs>
);

export default SVGDefs;
