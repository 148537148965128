import {SingleProblemType} from '../reducers/allProblemsReducer';
import {
    addFieldData,
    BardFilterObject,
    BardFilterSuggestions,
    fieldMatchesFilter,
    parseFilterString,
    suggestFilterFields
} from './commonFilter';
import {TEAM_FILTER_TYPE, teamMatchesFilter} from './teamFilter';
import {TEAM_USER_FILTER_TYPE, TeamUserFilter} from './teamUserFilter';

type ProblemFilterKeys = Exclude<keyof SingleProblemType, 'id' | 'question' | 'evidence' | 'resource' | 'created' | 'expectedStart' | 'expectedCompletion' | 'Projects'>;

export type ProblemFilter = {
    users?: BardFilterObject<TeamUserFilter>
} & {
    [field in ProblemFilterKeys]?: string | boolean | number | Array<string>;
};

export type ProblemFilterObject = BardFilterObject<ProblemFilter>;

export type ProblemFilterSuggestions = BardFilterSuggestions<ProblemFilter>;

export const problemFilterFieldTypes: {[key in keyof Required<ProblemFilter>]: string} = {
    title: 'string',
    summary: 'string',
    scenario: 'string',
    isTraining: 'boolean',
    teams: TEAM_FILTER_TYPE,
    users: TEAM_USER_FILTER_TYPE
};

export const PROBLEM_FILTER_TYPE = 'problemFilter';

addFieldData(PROBLEM_FILTER_TYPE, problemFilterFieldTypes, 'title');

export function parseProblemFilterString(filter: string): ProblemFilterObject {
    return parseFilterString<ProblemFilter>(filter, PROBLEM_FILTER_TYPE);
}

// Test if the last token in filter string could auto-complete to values in ProblemFilterKeys.
export function suggestProblemFilterFields(filterString: string): ProblemFilterSuggestions {
    const filter = parseFilterString(filterString, PROBLEM_FILTER_TYPE);
    return suggestFilterFields(filter);
}

export function problemMatchesFilter(problem: SingleProblemType, filter?: ProblemFilterObject) {
    if (!filter || !filter.fields) {
        return true;
    }
    for (let field of filter.fields) {
        const filterValue = filter.where[field];
        if (problemFilterFieldTypes[field] === TEAM_FILTER_TYPE) {
            if (!problem.teams || !problem.teams.reduce((anyMatch, team) => (anyMatch || teamMatchesFilter(team, filterValue)), false)) {
                return false;
            }
        } else if (problemFilterFieldTypes[field] === TEAM_USER_FILTER_TYPE) {
            if (!problem.teams || !problem.teams.reduce((anyMatch, team) => (
                anyMatch || teamMatchesFilter(team, {key: TEAM_USER_FILTER_TYPE, fields: ['users'], where: {users: filterValue}, tokens: {users: filterValue}})
            ), false)) {
                return false;
            }
        } else {
            const problemValue = problem[field];
            if ((Array.isArray(filterValue) && filterValue.reduce((noneMatch, filterValue) => (
                noneMatch && !fieldMatchesFilter(problemValue, filterValue, PROBLEM_FILTER_TYPE, field)
            ), true)) || (!Array.isArray(filterValue) && !fieldMatchesFilter(problemValue, filterValue, PROBLEM_FILTER_TYPE, field))) {
                return false;
            }
        }
    }
    return true;
}