import React from "react";
import * as constants from "../../../common/util/constants";

const ArcCircle = ({ x, y }) => (
    <circle
        cx={x}
        cy={y}
        r={4}
        fill="white"
        stroke={constants.colourActionBlue}
        strokeWidth={2}
        style={{
            pointerEvents: "none",
            filter: "url(#shadow)"
        }}
    />
);

export default ArcCircle;
