import React, {Component} from 'react';
import {throttle} from 'lodash';
import PropTypes from 'prop-types'
import {v4} from 'uuid';

import CKEditor from './CKEditor';
import LoadingBar from './LoadingBar';

class RichTextField extends Component {

    static propTypes = {
        text: PropTypes.string.isRequired,
        onRevert: PropTypes.bool,
        forceUpdate: PropTypes.bool,
        onChange: PropTypes.func.isRequired,
        readOnly: PropTypes.bool,
        addFlushChanges: PropTypes.func,
        focus: PropTypes.bool,
        onTextStart: PropTypes.func
    };

    static defaultProps = {
        readOnly: false,
        forceUpdate: false
    };

    constructor(props) {
        super(props);
        this.onChange = this.onChange.bind(this);
        this.dispatchChange = throttle(this.dispatchChange.bind(this), 5000);
        this.flushDispatchChange = this.flushDispatchChange.bind(this);
        this.flushChangeId = v4();
        this.onCkEditorInstanceReady = this.onCkEditorInstanceReady.bind(this);
        this.state = {
            text: props.text,
            CKEditorLoaded: false
        };
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        if (!this.ckEditor.editorInstance) {
            console.warn('updating props on RichTextField with no current editor instance');
        }
        if (newProps.readOnly !== this.props.readOnly) {
            this.ckEditor.editorInstance && this.ckEditor.editorInstance.setReadOnly(newProps.readOnly);
        }
        if (newProps.text !== this.props.text && newProps.text !== this.state.text) {
            if (newProps.readOnly || newProps.forceUpdate || newProps.onRevert) { // TODO should be able to amalgamate forceUpdate and onRevert
                this.setState({text: newProps.text});
                // CKEditor doesn't care about prop changes :(
                this.ckEditor.editorInstance && this.ckEditor.editorInstance.setData(newProps.text);
            }
            // Otherwise, ignore the update.  Trust our internal state.
        }
    }

    UNSAFE_componentWillMount() {
        if (this.props.addFlushChanges) {
            this.props.addFlushChanges(this.flushChangeId, this.flushDispatchChange);
        }
    }

    componentWillUnmount() {
        this.flushDispatchChange();
    }

    onCkEditorInstanceReady(evt) {
        this.setState({CKEditorLoaded: evt.editor.loaded});
    }

    onChange(evt) {
        let value = evt.editor.getData();
        this.dispatchChange(value);
        if (this.props.onTextStart) {
            this.props.onTextStart(value === '');
        }
        this.setState({text: value});
    }

    flushDispatchChange() {
        this.dispatchChange.flush();
    }

    dispatchChange(value) {
        this.props.onChange(value);
    }

    render() {
        // For more CKEditor config options see https://docs.ckeditor.com/#!/api/CKEDITOR.config
        let readOnlyConfig = this.props.readOnly ? {
            toolbar: 'print',
            toolbarCanCollapse: false,
            removePlugins: 'elementspath,sourcearea,image2',
            allowedContent: true
        } : {
            removePlugins: 'sourcearea'
        };
        return (
            <div><CKEditor
                content={this.state.text}
                events={{
                    change: this.onChange,
                    blur: () => {
                        this.flushDispatchChange();
                    },
                    instanceReady: this.onCkEditorInstanceReady
                }}
                ref={(ckEditor) => { this.ckEditor = ckEditor; }}
                scriptUrl='/ckeditor/ckeditor.js'
                config={{
                    disallowedContent: 'img{width,height};',
                    image2_alignClasses: ['align-left', 'align-center', 'align-right'],
                    extraPlugins: 'dragresize,openlink,print',
                    uploadUrl: this.props.config.uploadUrl,
                    toolbarCanCollapse: true,
                    openlink_modifier: 0,
                    openlink_enableReadOnly: true,
                    readOnly: this.props.readOnly,
                    startupFocus: this.props.focus,
                    ...readOnlyConfig,
                    ...this.props.config
                }}
            />
            {!this.state.CKEditorLoaded ? (<LoadingBar id='initCKEditor' />) : (null)}
            </div>);
    }
}

export default RichTextField
