import React, {Component} from 'react';
import {connect, DispatchProp} from 'react-redux';
import moment from 'moment';
import Select from 'react-select';
import classNames from 'classnames';
import {Button, DialogContainer, Slider} from 'react-md';
import {debounce} from 'lodash';
import memoizeOne from 'memoize-one';

import NotificationContainer from '../container/NotificationContainer';
import DataStatusEnum from '../../common/DataStatusEnum';
import {
    AllUsersForTeamReducerType,
    SingleUserForProblemReducerType
} from '../../common/reducers/allUsersForTeamReducer';
import ReferenceData, {StepDescription} from '../../common/util/referenceData';
import SyncVariableContainer from '../container/SyncVariableContainer';
import {CombinedTeamReducerType, SingleProblemStepType} from '../../common/reducers/allTeamsReducer';
import * as constants from '../../common/util/constants';
import {Tag} from '../../common/util/constants';
import {dismissNotificationAction} from '../../common/reducers/notificationsReducer';
import {getLoggedInUserIdFromStore} from '../reducers/loginReducer';
import {doesRoundMatch, getCurrentRound} from '../../common/util/roundLogic';
import IconLabelButton from './IconLabelButton';
import {
    appendPublishedDataAction,
    copyPublishedDataAction
} from '../../common/reducers/allStatusesForUserAndTeamReducer';
import {getSyncVariablesForPublish} from '../../common/util/buildBayesianModelDataUtils';
import {v4} from 'uuid';
import {addToastMessageAction} from '../reducers/snackbarToastReducer';
import {FeatureFlagsReducerType, getFeatureFlagsFromStore} from '../../common/reducers/featureFlagsReducer';
import StatusIconUser from './StatusIconUser';
import TipsComponent from './TipsComponent';
import {FEATURE_HIDE_PUBLISH_DATE, getFlagValue} from '../../common/util/featureFlags';
import {
    getRatingsForProblemStepAndSyncVariableFromStore,
    RatingForSyncVariableType,
    updateRatingAction
} from '../../common/reducers/ratingsReducer';
import commonSelectStyles from '../util/commonSelectStyles';
import {getProblemStepFromStore, getTeamFromStore} from '../../common/reducers/allTeamsReducerGetters';
import {
    getAllUsersForProblemFromStore,
    getUserForProblemFromStore
} from '../../common/reducers/allUsersForTeamReducerGetters';

import '../scss/published.scss';

const defaultProps = {
    status: DataStatusEnum.PUBLISHED,
    role: constants.ROLE_ANALYST,
    readOnly: false

};

type PublishedComponentDefaultProps = Readonly<typeof defaultProps>;

interface PublishedComponentOwnProps {
    stepId: number;
    problemStepId: number;
    tabTense: number;
    buttonBarDiv: HTMLDivElement | null;
}

interface PublishedComponentStoreProps {
    team: CombinedTeamReducerType;
    problemStep: SingleProblemStepType;
    userForProblem: SingleUserForProblemReducerType;
    userId: number;
    users: AllUsersForTeamReducerType;
    stepDescription: StepDescription;
    publishSyncVariables: string[];
    featureFlags: FeatureFlagsReducerType;
    ratings?: RatingForSyncVariableType;
    isProblemStepClosed: boolean;
}

type PublishedComponentProps = PublishedComponentOwnProps & PublishedComponentDefaultProps & DispatchProp & PublishedComponentStoreProps;

interface PublishedComponentState {
    selectedUserId?: number;
    displayReplaceWarning: boolean;
    rating?: number;
}

class PublishedComponent extends Component<PublishedComponentProps, PublishedComponentState> {

    static defaultProps = defaultProps;

    static getDerivedStateFromProps(props, state) {
        // Discard selected user if no longer valid.
        if (!props.users[state.selectedUserId] || PublishedComponent.isUserPublishedPrivate(props, state.selectedUserId)) {
            return {selectedUserId: null};
        } else {
            return null;
        }
    }

    // This static method is memoized after the class definition.
    static calculateRatings(rawRatings, currentAuthorUserId, myUserId, myRating) {
        let bestRating = 0;
        let forUser = {};
        const ratingsForUser = !currentAuthorUserId ? (rawRatings && rawRatings.forUser) : {
            ...(rawRatings && rawRatings.forUser),
            [currentAuthorUserId]: {
                ratings: {
                    ...(rawRatings && rawRatings.forUser && rawRatings.forUser[currentAuthorUserId] && rawRatings.forUser[currentAuthorUserId].ratings),
                    [myUserId]: myRating
                }
            }
        };
        Object.keys(ratingsForUser || {}).forEach((authorUserId) =>
        {
            if (ratingsForUser[authorUserId].ratings) {
                let total = 0.0;
                let count = 0;
                Object.keys(ratingsForUser[authorUserId].ratings).forEach((ratingUserId) => {
                    const userRating = ratingsForUser[authorUserId].ratings[ratingUserId];
                    total += userRating ? userRating : 0;
                    count += userRating ? 1 : 0;
                });
                forUser[authorUserId] = {
                    count,
                    average: count > 0 ? (total / count) : 0
                };
                if (forUser[authorUserId].average >= bestRating && count >= 2) {
                    bestRating = forUser[authorUserId].average;
                }
            }
        });
        return {
            bestRating,
            forUser
        };
    }

    static isUserPublishedPrivate(props, userId) {
        return (
            Number(props.userId) !== Number(userId) && +props.tabTense !== constants.STEP_TENSE.PAST
            && props.team.user[props.userId].db.role !== constants.ROLE_FACILITATOR
            && props.team.user[userId].db.role !== constants.ROLE_FACILITATOR
            && doesRoundMatch([constants.ROUND_PRIVATE, constants.ROUND_DELPHI_EXCHANGE], props.team.user[userId], props.problemStep, props.stepId)
        );
    }

    constructor(props) {
        super(props);
        this.shouldAppearInDropdown = this.shouldAppearInDropdown.bind(this);
        this.onCopyPublished = this.onCopyPublished.bind(this);
        this.doCopyPublished = this.doCopyPublished.bind(this);
        this.onSliderChange = this.onSliderChange.bind(this);
        this.dispatchRatingChange = debounce(this.dispatchRatingChange.bind(this), 5000);
        this.state = {
            displayReplaceWarning: false
        };
    }

    componentDidMount() {
        if (this.props.status === DataStatusEnum.GROUP) {
            // For Group screen, dismiss any notification when this component is mounted.
            this.dismissNotification(null);
        }
        const selectedUserId = this.getDefaultSelectedUserId();
        if (selectedUserId !== null) {
            this.setState({selectedUserId, rating: this.getMyRating(selectedUserId)});
        }
    }

    componentDidUpdate() {
        if (this.state.selectedUserId === null) {
            const selectedUserId = this.getDefaultSelectedUserId();
            if (selectedUserId !== null) {
                this.setState({selectedUserId, rating: this.getMyRating(selectedUserId)});
            }
        }
    }

    componentWillUnmount() {
        (this.dispatchRatingChange as any).flush();
    }

    getMyRating(authorUserId, selectedUserId = this.state.selectedUserId) {
        if (authorUserId === selectedUserId) {
            return this.state.rating;
        } else {
            return this.props.ratings
                && this.props.ratings.forUser[authorUserId]
                && this.props.ratings.forUser[authorUserId].ratings[this.props.userId];
        }
    }

    shouldAppearInDropdown(userId) {
        return (this.props.team.user[userId].db.role === this.props.role || this.getPublishedDate(userId));
    }

    getPublishedDate(userId) {
        let user = this.props.team.user[userId];

        // publish timestamps are more or less equal, so pick any of them that are defined
        const timestamp = (this.props.stepDescription.syncVariables || []).reduce((timestamp, syncVariable) => {
            if (!user || !user.status || !user.status[this.props.status]) {
                return timestamp;
            } else {
                let variableData = user.status[this.props.status][syncVariable];
                return (variableData && variableData.timestamp) || timestamp;
            }
        }, undefined);
        // If we're hiding the publish date, we still need to return something truthy from this method.
        const formatted = (getFlagValue(this.props.featureFlags[FEATURE_HIDE_PUBLISH_DATE])) ? ' ' : moment(timestamp).format('llll');
        return timestamp ? formatted : '';
    }

    getNotificationTag(): Tag[] {
        return (this.props.status === DataStatusEnum.PUBLISHED) ? ['draftPublished'] : ['groupDraftPublished'];
    }

    dismissNotification(userId) {
        this.props.dispatch(dismissNotificationAction(this.getNotificationTag(), this.props.userId, this.props.problemStepId, this.props.stepId, userId));
    }

    getPublishedUsers() {
        return Object.keys(this.props.users)
            .filter(this.shouldAppearInDropdown);
    }

    isFacilitator() {
        return this.props.team.user[this.props.userId] && this.props.team.user[this.props.userId].db.role === constants.ROLE_FACILITATOR;
    }

    getUserFlags() {
        return this.getPublishedUsers()
            .reduce((userFlags, userId) => {
                const published = this.getPublishedDate(userId);
                const isMe = Number(this.props.userId) === Number(userId);
                const isPrivate = PublishedComponent.isUserPublishedPrivate(this.props, userId);
                const isDisabled = !published || isPrivate;
                userFlags[userId] = {
                    published,
                    isMe,
                    isPrivate,
                    isDisabled
                };
                return userFlags;
            }, {});

    }

    getOptions() {
        const ratings = PublishedComponent.calculateRatings(this.props.ratings, this.state.selectedUserId, this.props.userId, this.state.rating);
        const userFlags = this.getUserFlags();
        return Object.keys(userFlags)
            .sort((userId1, userId2) => {
                const displayName1 = this.props.users[userId1].db.displayName;
                const displayName2 = this.props.users[userId2].db.displayName;
                return (displayName1 === displayName2) ? 0 : (displayName1 < displayName2) ? -1 : 1
            })
            .map((userIdString) => {
                const userId = Number(userIdString);
                const {published, isMe, isPrivate, isDisabled} = userFlags[userId];
                const myRating = this.getMyRating(userId);
                const {count = 0, average = null} = (ratings && ratings.forUser[userId]) || {};
                return {
                    label: (
                        <span className={classNames('selectedUser', {
                            roleColour: !isDisabled && isMe
                        })}>

                            <span className="columnUser">{this.props.users[userId].db.displayName}
                                <span className="columnUserMe">{isMe ? 'Me' : ''}</span>
                            </span>                            
                            
                            <span className='columnPublishedDate'>
                                {isPrivate ? '(Private)' : (published ? 'Published ' + published : 'Not yet published')}
                            </span>

                            <span className="columnNotificationDot">
                                <NotificationContainer
                                    tags={this.getNotificationTag()}
                                    keyValue={userIdString}
                                    stepId={this.props.stepId}
                                    showFlag={true}
                                    disabled={isDisabled}
                                />
                            </span>

                            {
                                (!this.state || !this.props.problemStep || !this.props.stepDescription.ratingSyncVariable) ? null : (
                                    isDisabled ? null : (
                                        this.props.userForProblem.db.role === constants.ROLE_ANALYST ? (
                                            !myRating ? (
                                                <span className='pleaseRate columnRatings'>{'Please rate'}</span>
                                            ) : (
                                                <span className='columnRatings'>
                                                    <span className={classNames('columnRateOther analystRatingValue analyst other roleBackgroundColour roleBorderColour', {
                                                        highestAverageScore: average && average >= ratings.bestRating && count >= 2
                                                    })}>
                                                        {average.toFixed(1)}
                                                    </span>
                                                    <span className='columnRateAverage analystRatingValue analyst roleBackgroundColour roleBorderColour'>{myRating}</span>
                                                </span>
                                            )
                                        ) : (
                                            !average ? (
                                                <span className='columnNoRating'>No analysts have rated</span>
                                            ) : (
                                                <span className='columnRatings'>
                                                    <span className={classNames('analystRatingValue facilitatorAverageColumn analyst other roleBackgroundColour roleBorderColour', {
                                                        highestAverageScore: average && average >= ratings.bestRating && count >= 2
                                                    })}>
                                                        {average.toFixed(1)}
                                                    </span>
                                                    <span className='facilitatorRatingValue'>
                                                        {count}
                                                        <StatusIconUser className={classNames('iconStyles', 'analyst', 'other')}/>
                                                    </span>
                                                </span>
                                            )
                                        )
                                    )
                                )
                            }
                        </span>
                    ),
                    value: (published ? userId : ''),
                    disabled: isDisabled
                };
            });
    }

    /**
     * Choose the user to select by default in the drop-down.
     */
    getDefaultSelectedUserId() {
        if (this.props.users[this.props.userId] && this.getPublishedDate(this.props.userId)) {
            return this.props.userId;
        } else {
            const userFlags = this.getUserFlags();
            const enabledUsers = Object.keys(userFlags).filter((userId) => (!userFlags[userId].isDisabled));
            if (enabledUsers.length > 0) {
                return Number(enabledUsers[0]);
            } else if (this.props.isProblemStepClosed) {
                // Fall back on any user who was at least assigned to the problem
                const allUserIds = Object.keys(this.props.users);
                if (allUserIds.length > 0) {
                    return Number(allUserIds[0]);
                }
            }
        }
        return null;
    }

    onCopyPublished() {
        if (!this.isFacilitator() || this.props.stepDescription.copyPublished === constants.COPY_PUBLISHED_REPLACE) {
            this.setState({displayReplaceWarning: true});
        } else {
            this.doCopyPublished(true);
        }
    }

    doCopyPublished(append) {
        if (!this.state.selectedUserId) {
            return;
        }
        const fromUser = this.props.users[this.state.selectedUserId].status[this.props.status];
        const isFacilitator = this.isFacilitator();
        const targetStatus = isFacilitator ? DataStatusEnum.GROUP_DRAFT : DataStatusEnum.MY_DRAFT;
        const targetStatusName = isFacilitator ? 'Group Draft' : 'My Draft';

        const publishedData = this.props.publishSyncVariables.reduce((publishedData, syncVariable) => {
            const syncVarData = fromUser[syncVariable];
            if (syncVariable === constants.SYNC_KEY_FACTS) {
                // Alas, we need this special case code to generate new GUIDs for the key facts, so the copy is
                // non-destructive to any previously copied key facts.
                const order = syncVarData.order.map(() => (v4()));
                const fact = order.reduce((fact, factId, index) => {
                    fact[factId] = fromUser[syncVariable].fact[syncVarData.order[index]];
                    return fact;
                }, {});
                publishedData[syncVariable] = {fact, order};
            } else {
                publishedData[syncVariable] = syncVarData;
            }
            return publishedData;
        }, {});

        if (append) {
            this.props.dispatch(appendPublishedDataAction(
                this.props.problemStepId,
                this.props.userId,
                targetStatus,
                publishedData,
                this.props.publishSyncVariables
            ));
        } else {
            this.props.dispatch(copyPublishedDataAction(
                this.props.problemStepId,
                this.props.userId,
                targetStatus,
                publishedData,
                this.props.publishSyncVariables
            ));
        }
        this.props.dispatch(addToastMessageAction('Published data from ' +
            this.props.users[this.state.selectedUserId].db.displayName +
            (append ? ' appended to ' : ' copied to ')
            + targetStatusName));
        this.setState({displayReplaceWarning: false});
    }

    onSliderChange(rating) {
        this.setState({rating});
        this.dispatchRatingChange(rating);
    }

    dispatchRatingChange(rating) {
        if (this.state.selectedUserId !== undefined && this.props.stepDescription.ratingSyncVariable) {
            this.props.dispatch(updateRatingAction(this.props.problemStepId, this.props.stepDescription.ratingSyncVariable, this.state.selectedUserId, this.props.userId, rating));
        }
    }

    renderLeftColumn() {
        const isRTJ = (this.props.problemStep.launchMode === constants.LAUNCH_MODE_REAL_TIME_JURY);

        return (this.props.stepId === constants.STEP_PARAMETERS || this.props.stepId === constants.STEP_EXPLORE_MODEL || this.props.stepId === constants.STEP_STRUCTURE || this.props.stepId === constants.STEP_SINGLE_STRUCTURE) ? null : (
            <div className="workspaceLeftColumn">
                {this.props.stepId === constants.STEP_EXPLORE_PROBLEM && !isRTJ?
                    (!this.state.selectedUserId || this.props.isProblemStepClosed ?
                        null
                        :
                        (this.props.tabTense === constants.STEP_TENSE.PAST ? // Explore Problem is complete
                            (Number(this.props.userId) !== Number(this.state.selectedUserId) ? //Other Analysts' published
                                    <div className="pageTip problemDescriptionPageTip01">
                                        Here are the Key Questions, Hypotheses and Evidence items published
                                        by <b>{this.props.users[this.state.selectedUserId].db.displayName}</b>.
                                        The Explore Problem step is finished. Refer to the Group tab for the combined list published by the facilitator.
                                    </div>
                                    :
                                    <div className="pageTip problemDescriptionPageTip01">
                                    Here are the Key Questions, Hypotheses and Evidence items you published and shared with the team. Now that the Explore Problem step is finished, you can no longer change these. Refer to the Group tab for the combined list published by the facilitator.
                                    </div>
                            )
                            :
                            (Number(this.props.userId) !== Number(this.state.selectedUserId) ? //Other Analysts' published
                                    <div className="pageTip problemDescriptionPageTip01">
                                        Here are the Key Questions, Hypotheses and Evidence items published
                                        by <b>{this.props.users[this.state.selectedUserId].db.displayName}</b>. Refer to the Group tab for the combined list published by the facilitator.
                                    </div>
                                    :
                                    <div className="pageTip problemDescriptionPageTip01">
                                    Here are the Key Questions, Hypotheses and Evidence items you published and shared with the team. Refer to the Group tab for the combined list published by the facilitator.
                                    </div>
                            )
                                
                        )
                )
                :
                null
                }
                <TipsComponent status={this.props.status} step={this.props.stepId} side={constants.TIP_SCREEN_LEFT} problemStep={this.props.problemStep} userProblem={this.props.userForProblem.db}/>
            </div>
        );
    }

    renderNoFacilitatorWarning() {
        return <div className='noFacilitatorWarning'>
                <p><b>THIS GROUP SOLUTION IS NO LONGER ACTIVE</b></p>
                <p>The Facilitator has stepped down into an Analyst role. This Group solution WILL NOT be
                    submitted as your group's solution - the top rated Analyst solution will be
                    automatically submitted shortly before the problem closes.</p>
            </div>
    }

    renderMiddleColumn() {
        return (
            <div className="workspaceContentColumn workspaceContentDiv">
                <SyncVariableContainer
                    className={classNames('override', {
                        facilitator: this.props.role === constants.ROLE_FACILITATOR,
                        analyst: this.props.role !== constants.ROLE_FACILITATOR,
                        other: Number(this.props.userId) !== Number(this.state.selectedUserId)
                    })}
                    problemStepId={this.props.problemStepId}
                    syncVariables={this.props.stepDescription.syncVariables || []}
                    stepId={this.props.stepId}
                    userId={this.state.selectedUserId}
                    status={this.props.status}
                    tabTense={this.props.tabTense}
                    readOnly={true}
                    stepReadOnly={this.props.readOnly}
                    buttonBarDiv={this.props.buttonBarDiv}
                />
            </div>
        );
    }

    renderRightColumn() {
        return (this.props.stepId === constants.STEP_PARAMETERS || this.props.stepId === constants.STEP_EXPLORE_MODEL) ? null : (
            <div className="workspaceRightColumn">
                <TipsComponent status={this.props.status} step={this.props.stepId} side={constants.TIP_SCREEN_RIGHT} problemStep={this.props.problemStep} userProblem={this.props.userForProblem.db}/>
            </div>
        );
    }

    renderThreeColumns() {
        return (
            <div className={classNames('threeColumnLayout', {
                'wideContent': this.props.stepId === constants.STEP_STRUCTURE
            })}>
                {this.renderLeftColumn()}
                {this.renderMiddleColumn()}
                {this.renderRightColumn()}
            </div>
        );
    }

    renderNoContent(round) {
        const isRTJ = (this.props.problemStep.launchMode === constants.LAUNCH_MODE_REAL_TIME_JURY);
        return (
            <div className="problemLaunchDiv noPublishedSolutions">
                {this.props.status === DataStatusEnum.GROUP ?
                    (this.isFacilitator() ?
                        'As Facilitator, when you publish a Group Draft, it will appear here for Analysts to view.'
                        :
                        (this.props.problemStep.state === constants.STATE_CLOSED) ?
                        'The Facilitator has not published a Group Content for this step.' :
                        'The Facilitator has not yet published Group Content for this step. You will see a notification red dot when it is available.'
                    )
                    :
                    (round === constants.ROUND_EXCHANGE || round === constants.ROUND_PRIVATE ?
                        !this.isFacilitator() ?
                            (this.props.tabTense === constants.STEP_TENSE.PAST?
                                    <div>
                                        This step is complete. You did not publish content for this step. You can view
                                        other
                                        Analysts' work by selecting them from the list displayed above.
                                        <br/>
                                        The final combined Group list of all Analysts’ key elements can be seen under
                                        the
                                        “Group” screen.
                                    </div>
                                    :
                                    <div>
                                   You and other analysts haven't published for this step.
                                    <br/>
                                    The final combined Group list of all Analysts’ key elements can be seen under
                                    the
                                    “Group” screen.
                                    </div>
                            )
                            :'Analysts have not published their work. You can contact the analysts (via Messages) to remind them to publish their work.'
                        :
                        (this.props.tabTense === constants.STEP_TENSE.PAST && !this.isFacilitator() ?
                            <div>
                                    Analysts haven't published for this step.
                                    <br/>
                                    The final combined Group list of all Analysts’ key elements can be seen under
                                    the
                                    “Group” screen.
                            </div>
                            :
                            isRTJ ?
                                'Analysts have not published their work. You can contact the analysts (via Messages) to remind them to publish their work.'
                                :
                                this.props.tabTense === constants.STEP_TENSE.FUTURE_EDITABLE ?
                                    'Analysts have not published content for this step.'
                                    :
                                    <div>
                                    This step is complete. Analysts did not publish content for this step.
                                    <br/>
                                    The final combined Group list of all Analysts’ key elements can be seen under
                                    the
                                    “Group” screen.
                                    </div>
                        )
                    )
                }
            </div>
        );
    }

    renderSelectionBar() {
        let options = this.getOptions();

        const isFacilitator = this.isFacilitator();
        const showCopy = !this.props.readOnly && (isFacilitator
            ? this.props.stepDescription.copyPublished === constants.COPY_PUBLISHED_REPLACE
            : this.props.stepDescription.copyPublished !== constants.COPY_PUBLISHED_NONE);
        const showAdd = !this.props.readOnly && isFacilitator && this.props.stepDescription.copyPublished === constants.COPY_PUBLISHED_APPEND;

        const disableCopy = !this.state.selectedUserId || this.state.selectedUserId === this.props.userId;
        const copyStepName = this.props.stepDescription.parentStepId ?
            'entire ' + ReferenceData.getInstance().getAllStepDescriptions()[this.props.stepDescription.parentStepId].name :
            this.props.stepDescription.name;
        const copyTarget = isFacilitator ? 'Group Draft' : 'My Draft';

        const showRatingSlider = this.props.problemStep && !this.props.readOnly && this.props.stepDescription.ratingSyncVariable && this.props.userForProblem.db.role === constants.ROLE_ANALYST;

        return (
            <div className="publishedSelectionBar">
                {
                    (!showCopy && !showAdd && !showRatingSlider) ? null : <div className='publishedSelectionFiller'/>
                }
                {
                    (this.props.role === constants.ROLE_FACILITATOR) ? null : (
                        <div className='selectWrapper'>
                            <Select
                                options={options}
                                isSearchable={true}
                                value={options.filter(({value}) => (value === this.state.selectedUserId))}
                                isOptionDisabled={(option) => (option.disabled)}
                                styles={commonSelectStyles}
                                onChange={(selected) => {
                                    if (selected) {
                                        (this.dispatchRatingChange as any).flush();
                                        const selectedUserId = Number(selected.value);
                                        this.setState({selectedUserId, rating: this.getMyRating(selectedUserId)}, () => {
                                            this.dismissNotification(selectedUserId);
                                        });
                                    }
                                }}
                            />
                        </div>
                    )
                }
                {
                    !showRatingSlider ? null : (
                        <div className="ratingsSliderWrapper toolTipContainer">
                            <div className='ratingSlider'>
                                <Slider
                                    discrete={true}
                                    id="ratingSlider"
                                    discreteTicks={1}
                                    discreteValueClassName="discreteSliderStyle"
                                    value={this.state.rating || constants.REPORT_RATING_MINIMUM}
                                    onChange={this.onSliderChange}
                                    label={`label`} // this is hidden, but width of slider seems to depend on label width...
                                    min={constants.REPORT_RATING_MINIMUM}
                                    max={constants.REPORT_RATING_MAXIMUM}
                                    step={1}
                                    valuePrecision={1}
                                />
                            </div>
                            <span className="toolTip toolTipRating">
                                My rating of this Report
                            </span>
                        </div>
                    )
                }
                {
                    (!showCopy && !showAdd) ? null : (
                        <div className='copyButton'>
                            <IconLabelButton
                                iconChildren={showAdd ? 'library_add' : 'content_copy'}
                                label={(showAdd ? 'Add to ' : 'Copy to ') + copyTarget}
                                disabled={disableCopy}
                                onClick={this.onCopyPublished}
                            />
                        </div>
                    )
                }
                <DialogContainer
                    id='replaceWarningDialog'
                    dialogClassName='replaceWarningDialog'
                    visible={this.state.displayReplaceWarning}
                    title={'Copy to ' + copyTarget}
                    onHide={() => {this.setState({displayReplaceWarning: false})}}
                    actions={[
                        {
                            children: 'Cancel',
                            onClick: () => {this.setState({displayReplaceWarning: false})}
                        },
                        <Button key='replaceGroupDraft' raised={true} primary={true} onClick={
                            () => this.doCopyPublished(false)
                        }>Replace {copyTarget}</Button>
                    ]}
                >
                    <p>This solution for the {copyStepName} step will be copied into {copyTarget}.</p>
                    <p><strong>Your current {copyTarget} will be replaced.</strong></p>
                    <p>Do you want to replace your current {copyTarget}?</p>
                </DialogContainer>
            </div>
        );
    }

    render() {
        const round = getCurrentRound(this.props.stepId, this.props.problemStep);

        const noFacilitatorWarning = (this.props.role === constants.ROLE_FACILITATOR && Object.keys(this.props.users).filter((userId) => (this.props.users[userId].db.role === this.props.role)).length === 0);

        return (
            <div className='publishedComponent'>
                {this.renderSelectionBar()}
                {noFacilitatorWarning ? this.renderNoFacilitatorWarning() : null}
                {
                    (this.state.selectedUserId !== null)
                        ? this.renderThreeColumns()
                        : (noFacilitatorWarning ? null : this.renderNoContent(round))
                }
            </div>
        );
    }

}

function mapStoreToProps(store, myProps): PublishedComponentStoreProps {
    const problemStepId = myProps.problemStepId;
    const problemStep = getProblemStepFromStore(store, problemStepId);

    const loggedInUserId = getLoggedInUserIdFromStore(store);
    const userForProblem = getUserForProblemFromStore(store, problemStepId, loggedInUserId);
    const userId = userForProblem.db.userId;

    const stepDescription = ReferenceData.getInstance().getStepDescription(myProps.stepId);

    return {
        team: getTeamFromStore(store, problemStepId),
        problemStep,
        userForProblem,
        userId: userForProblem.db.userId,
        users: getAllUsersForProblemFromStore(store, problemStepId),
        stepDescription,
        publishSyncVariables: getSyncVariablesForPublish(store, problemStepId, userId, myProps.stepId) || [],
        featureFlags: getFeatureFlagsFromStore(store),
        ratings: (stepDescription && stepDescription.ratingSyncVariable) ?
            getRatingsForProblemStepAndSyncVariableFromStore(store, problemStepId, stepDescription.ratingSyncVariable) : undefined,
        isProblemStepClosed: problemStep.state === constants.STATE_CLOSED
    }
}

PublishedComponent.calculateRatings = memoizeOne(PublishedComponent.calculateRatings);

export default connect<PublishedComponentStoreProps, DispatchProp,
    PublishedComponentOwnProps & Partial<PublishedComponentDefaultProps>>(mapStoreToProps)(PublishedComponent);