import {StoreWithSharedState} from './sharedStateReducer';
import * as constants from '../util/constants';
import {getProblemDBFromStore} from './allProblemsReducer';
import {getTeamFromStore} from './allTeamsReducerGetters';
import {AllUsersForTeamReducerType, SingleUserForProblemReducerType, UserForTeamType} from './allUsersForTeamReducer';

export const initialUserForTeamType: UserForTeamType = {
    userId: 0,
    problemStepId: 0,
    role: constants.ROLE_OBSERVER,
    displayName: '',
    timeSpent: 0.0,
    isNew: true,
    groupManagementState: constants.GRP_MGMT_NOP,
    publishedStepMask: 0,
    trainingUser: false
};

// ======== extracted getter functions for allUsersForTeamReducer, to break circular import

export function getAllUsersForProblemFromStore(store: StoreWithSharedState, problemStepId: number): AllUsersForTeamReducerType {
    return store.sharedState.teams[problemStepId].user;
}

export function getAllUsersWithRoleForProblemFromStore(store: StoreWithSharedState, problemStepId, role: constants.UserRole) {
    let users = store.sharedState.teams[problemStepId].user;

    return Object.keys(users).reduce((result, key) => {
        if (users[key].db.role === role) {
            result[key] = users[key];
        }

        return result;
    }, {});
}

export function getFacilitator(users: AllUsersForTeamReducerType): number | null {
    return Object.keys(users).reduce<number | null>((result, userId) => (
        (users[userId].db.role === constants.ROLE_FACILITATOR) ? Number(userId) : result
    ), null);
}

export function getRawUserForProblemFromStore(store: StoreWithSharedState, problemStepId: number | string, userId: number) {
    return store.sharedState && store.sharedState.teams && store.sharedState.teams[problemStepId] && store.sharedState.teams[problemStepId].user && store.sharedState.teams[problemStepId].user[userId];
}

export function getUserForProblemFromStore(store: StoreWithSharedState, problemStepId: number | string, userId: number): SingleUserForProblemReducerType {
    const team = store && store.sharedState && store.sharedState.teams && store.sharedState.teams[problemStepId];
    const isClosed = team && team.problemStep && team.problemStep.state === constants.STATE_CLOSED;
    let user = team && team.user[userId];
    if (isClosed || (!user && team)) {
        const problem = getProblemDBFromStore(store, team.problemStep.problemId);
        if (isClosed || (problem && problem.isTraining)) {
            user = {   // fake userProblem object for training and closed problems.
                db: {
                    ...initialUserForTeamType,
                    problemStepId: Number(problemStepId),
                    userId: constants.USER_ANY,
                    displayName: (user && user.db && user.db.displayName) || 'Training User',
                    role: constants.ROLE_OBSERVER,
                    isNew: false
                },
                status: {},
                notes: ''
            }
        }
    }
    return user;
}

export function getRoleForProblemFromStore(store: StoreWithSharedState, problemStepId: number | string, userId: number | string): number {
    const team = getTeamFromStore(store, problemStepId);
    if (team.problemStep.state === constants.STATE_CLOSED) {
        return constants.ROLE_OBSERVER;
    } else if (getProblemDBFromStore(store, team.problemStep.problemId).isTraining) {
        return constants.ROLE_ANALYST;
    } else {
        return team.user[userId].db.role;
    }
}

// Draft chat message is part of user-problem content as we need only one draft per user per problem
export function getUserProblemDraftMessage(store: StoreWithSharedState, problemStepId: number | string, userId: number | string) {
    return store.sharedState.teams[problemStepId].user[userId].db.draftMessage;
}