import {combineReducers, Reducer} from 'redux';
import moment from 'moment';

import ensureFieldPath from '../ensureFieldPath';
import {objectMapReducer, ObjectMapState, singleFieldReducer} from '../util/genericReducers';
import {RESET_PROBLEM_STEP} from '../util/constants';
import {deepFilter} from '../util/immutable';
import { StoreWithSharedState } from './sharedStateReducer';
import {UserForTeamType} from './allUsersForTeamReducer';

// ======== Constants

export const POST_DISCUSSION_TOPIC = 'post_discussion_topic';
export const HIDE_DISCUSSION_TOPIC = 'hide_discussion_topic';
export const UPDATE_OR_CREATE_DISCUSSION_COMMENT = 'update_or_create_discussion_comment';
export const POST_DISCUSSION_COMMENT = 'post_discussion_comment';
export const HIDE_DISCUSSION_COMMENT = 'hide_discussion_comment';
export const DISCARD_DRAFT_COMMENT = 'discard_draft_comment';

// ======== Reducers

export interface DiscussionTopicState {
    id: number;
    topicTitle: string;
    createdBy: number;
    createdUserProblemId?: number;
    created: Date;
    problemStepId: number;
    stepId: number;
    visible: boolean;
    reasonHidden?: string;
    hiddenBy?: number;
    hiddenUserProblemId?: number;
    DiscussionComments?: DiscussionCommentState[];
    creator?: UserForTeamType;
    remover?: UserForTeamType;
}

export interface DiscussionCommentState {
    comment: string;
    commentId: string;
    createdBy: number;
    createdUserProblemId?: number;
    creator?: UserForTeamType;
    created: Date;
    visible: boolean;
    reasonHidden?: string;
    hiddenBy?: number;
    topicId: number;
    draft: boolean;
    hiddenUserProblemId?: number;
    remover?: UserForTeamType;
}

const commentReducer: Reducer<DiscussionCommentState[]> = (state = [], action) => {
    switch (action.type) {
        case UPDATE_OR_CREATE_DISCUSSION_COMMENT:
            return state.filter((comment) => (comment.commentId !== action.commentId))
                .concat([{
                    comment: action.comment,
                    commentId: action.commentId,
                    createdBy: action.userId,
                    createdUserProblemId: action.userProblem.id,
                    created: action.created,
                    visible: action.visible,
                    creator: action.userProblem,
                    draft: action.draft,
                    topicId: action.topicId
                }]);
        case POST_DISCUSSION_COMMENT:
            return state.map(comment => comment.commentId === action.commentId ? {
                ...comment,
                draft: action.draft,
                created: action.created
            } : comment);
        case HIDE_DISCUSSION_COMMENT:
            return state.map(comment => comment.commentId === action.commentId ? {
                ...comment,
                visible: action.visible,
                reasonHidden: action.reasonHidden,
                hiddenBy: action.hiddenBy,
                hiddenUserProblemId: action.hiddenUserProblem.id,
                remover: action.hiddenUserProblem
            } : comment);
        case DISCARD_DRAFT_COMMENT:
            return state.filter((comment) => (comment.commentId !== action.commentId || !comment.draft));
        default:
            return state;
    }
};

const commentsForAllTopicsReducer = objectMapReducer('topicId', commentReducer);

const topicsForProblemAndStepReducer: Reducer<ObjectMapState<DiscussionTopicState>> = (state = {}, action) => {
    switch (action.type) {
        case POST_DISCUSSION_TOPIC:
            return {
                ...state, [action.topicId]: {
                    topicTitle: action.topicTitle,
                    createdBy: action.userId,
                    createdUserProblemId: action.userProblem.id,
                    visible: action.visible,
                    creator: action.userProblem,
                    created: action.created
                }
            };
        case HIDE_DISCUSSION_TOPIC:
            return {
                ...state, [action.topicId]: {
                    ...state[action.topicId],
                    visible: action.visible,
                    reasonHidden: action.reasonHidden,
                    hiddenBy: action.hiddenBy,
                    hiddenUserProblemId: action.hiddenUserProblem.id,
                    remover: action.hiddenUserProblem
                }
            };
        default:
            return state;
    }
};

export interface TopicsForProblemState {
    step: ObjectMapState<DiscussionTopicState>;
}

export interface DiscussionsState {
    topics: TopicsForProblemState;
    comments: ObjectMapState<DiscussionCommentState[]>;
}

const topicsForProblemReducer = singleFieldReducer('step', {step: {}}, objectMapReducer('stepId', topicsForProblemAndStepReducer));

const discussionsReducer = combineReducers<DiscussionsState>({
    topics: topicsForProblemReducer,
    comments: commentsForAllTopicsReducer
});

export const initialDiscussionsState: DiscussionsState = {topics: {step: {}}, comments: {}};

export const alsoRemoveDraftTopicDiscussionsReducer: Reducer<DiscussionsState> = (state = initialDiscussionsState, action) => {
    let newState: DiscussionsState = discussionsReducer(state, action);
    if (action.type === DISCARD_DRAFT_COMMENT && (!newState.comments[action.topicId] || newState.comments[action.topicId].length === 0)) {
        // If we discard the only comment on a topic, also remove the topic
        return deepFilter(newState, ['topics', 'step', action.stepId, '*'], (_, actualPath) => (actualPath[3] !== action.topicId));
    } else {
        return newState;
    }
};

export default alsoRemoveDraftTopicDiscussionsReducer;

// ======== DBSync functions
// (used by DBSync and for unit testing)

export function getDBSyncActionTypes() {
    return [POST_DISCUSSION_TOPIC, HIDE_DISCUSSION_TOPIC, UPDATE_OR_CREATE_DISCUSSION_COMMENT, POST_DISCUSSION_COMMENT, HIDE_DISCUSSION_COMMENT, RESET_PROBLEM_STEP, DISCARD_DRAFT_COMMENT];
}

export function setDiscussionTopicInStore(store: StoreWithSharedState, topicsState: DiscussionTopicState) {
    ensureFieldPath(store, 'sharedState', 'teams', topicsState.problemStepId, 'discussion', 'topics', 'step', topicsState.stepId);

    store.sharedState.teams[topicsState.problemStepId].discussion.topics.step[topicsState.stepId][topicsState.id] = topicsState;
}

export function setDiscussionCommentInStore(store: StoreWithSharedState, discussionTopic: DiscussionTopicState, discussionComment: DiscussionCommentState) {
    ensureFieldPath(store, 'sharedState', 'teams', discussionTopic.problemStepId, 'discussion', 'comments', discussionComment.topicId, []);
    store.sharedState.teams[discussionTopic.problemStepId].discussion.comments[discussionComment.topicId] = [
        ...store.sharedState.teams[discussionTopic.problemStepId].discussion.comments[discussionComment.topicId],
        discussionComment
    ];
}

// ======== Utility functions

export const sortCommentsFn = (a, b) => {
    const timestampA = moment(a.created).valueOf();
    const timestampB = moment(b.created).valueOf();
    return (timestampA < timestampB) ? -1 : (timestampA === timestampB) ? 0 : 1;
};

/**
 * Filter topics and comments for a given problemStep using a provided filter function.
 *
 * @param discussion The part of the Redux store containing discussion.  This is not mutated.
 * @param filterOutUser A filter function returning true (filter out) or false (keep).  Invoked with createdBy userId
 * for the given topic or comment.
 * @return {any} A copy of the discussion part of the Redux store, filtered using the provided function.
 */
export function filterDiscussionsByUser(discussion: DiscussionsState, filterOutUser: (userId: string | number) => boolean) {
    const discussionTopics = discussion.topics;
    if (discussionTopics && discussionTopics.step) {
        // Filter out topics for problemStep where the filter function returns true.
        let removedTopicIds: string[] = [];
        const topicsStep = Object.keys(discussionTopics.step).reduce((topicsStep: undefined | ObjectMapState<DiscussionTopicState>, stepId: string) => {
            const topicsForStep = Object.keys(discussionTopics.step[stepId]).reduce((topicsForStep: undefined | DiscussionTopicState, topicId: string) => {
                const topic = discussionTopics.step[stepId][topicId];
                if (filterOutUser(topic.createdBy)) {
                    topicsForStep = topicsForStep || {...discussionTopics.step[stepId]};
                    delete(topicsForStep![topicId]);
                    removedTopicIds.push(topicId);
                }
                return topicsForStep;
            }, undefined);
            if (topicsForStep) {
                topicsStep = topicsStep || {...discussionTopics.step};
                topicsStep[stepId] = topicsForStep;
            }
            return topicsStep;
        }, undefined);
        // Also remove all comments for filtered-out topics, and filter comments in the surviving topics.
        const comments = Object.keys(discussion.comments).reduce((comments: undefined | ObjectMapState<DiscussionCommentState[]>, topicId: string) => {
            if (removedTopicIds.indexOf(topicId) >= 0) {
                comments = comments || {...discussion.comments};
                delete(comments![topicId]);
            } else {
                const originalComments = discussion.comments[topicId] || [];
                const filteredComments = originalComments.filter((comment) => (!filterOutUser(comment.createdBy)));
                if (filteredComments.length < originalComments.length) {
                    comments = comments || {...discussion.comments};
                    comments![topicId] = filteredComments;
                }
            }
            return comments;
        }, undefined);
        if (topicsStep || comments) {
            return {
                topics: topicsStep ? {...discussion.topics, step: topicsStep} : discussion.topics,
                comments: comments || discussion.comments
            };
        }
    }
    return discussion;
}

// ======== Action generator functions

export function getActivityActionTypes() {
    return [HIDE_DISCUSSION_TOPIC, POST_DISCUSSION_COMMENT, HIDE_DISCUSSION_COMMENT];
}

export function discussPostTopicAction(problemStepId, topicId, stepId, userId, userProblem, topicTitle) {
    return {type: POST_DISCUSSION_TOPIC, problemStepId, topicId, stepId, userId, userProblem, topicTitle, visible: true, reasonHidden: null, hiddenBy: null, hiddenUserProblem: null, created: moment().format()};
}

export function discussHideTopicAction(problemStepId, stepId, userId, topicTitle, reasonHidden, hiddenBy, hiddenUserProblem, topicId) {
    return { type: HIDE_DISCUSSION_TOPIC, problemStepId, stepId, userId, topicTitle, visible: false, reasonHidden, hiddenBy, hiddenUserProblem, topicId }
}

export function discussUpdateOrCreateCommentAction(commentId, comment, topicId, userId, userProblem, problemStepId, stepId) {
    return {type: UPDATE_OR_CREATE_DISCUSSION_COMMENT, commentId, comment, topicId, userId, userProblem, problemStepId, stepId, visible: true, reasonHidden: null, hiddenBy: null, hiddenUserProblem: null, draft: true, created: moment().format()};
}

export function discussPostCommentAction(commentId, comment, topicId, userId, userProblem, problemStepId, stepId) {
    return {type: POST_DISCUSSION_COMMENT, commentId, comment, topicId, userId, userProblem, problemStepId, stepId, visible: true, reasonHidden: null, hiddenBy: null, hiddenUserProblem: null, draft: false, created: moment().format() };
}

export function discussHideCommentAction(problemStepId, stepId, userId, commentId, topicId, reasonHidden, hiddenBy, hiddenUserProblem) {
    return { type: HIDE_DISCUSSION_COMMENT, problemStepId, stepId, userId, commentId, visible: false, reasonHidden, hiddenBy, hiddenUserProblem, topicId }
}

export function discardDraftCommentAction(problemStepId, stepId, userId, topicId, commentId) {
    return {type: DISCARD_DRAFT_COMMENT, problemStepId, stepId, userId, topicId, commentId};
}

// ======== Getter functions

export function getDiscussionForProblemFromStore(store: StoreWithSharedState, problemStepId) {
    return store.sharedState.teams[problemStepId].discussion;
}

export function getDiscussionTopicsForProblemAndStepFromStore(store: StoreWithSharedState, problemStepId, stepId) {
    return store.sharedState.teams[problemStepId].discussion.topics.step && store.sharedState.teams[problemStepId].discussion.topics.step[stepId];
}