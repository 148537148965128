import {combineReducers, Reducer} from 'redux';
import {v4} from 'uuid';

import {objectMapReducer, orderedListReducer, ObjectMapState} from '../util/genericReducers';
import DataStatusEnum from '../DataStatusEnum';

// ======== Constants

import {UPDATE_VARIABLE_TYPE} from '../util/constants';

const ADD_VARIABLE_STATE = 'add_variable_state';
const UPDATE_VARIABLE_STATE = 'update_variable_state';
const DELETE_VARIABLE_STATE = 'delete_variable_state';
const REORDER_VARIABLE_STATE = 'reorder_variable_state';


export interface SingleVariableAttributeState {
    stateName: string;
    description: string;
    rationale: string;
}

export interface KeyVariablesAttributeState {
    state: ObjectMapState<SingleVariableAttributeState>;
    order: string[];
}

export const initialSingleVariableAttribute: SingleVariableAttributeState = {
    stateName: '',
    description: '',
    rationale: ''
};

// ======== Reducers

const singleVariableStateReducer: Reducer<SingleVariableAttributeState> = (state = initialSingleVariableAttribute, action) => {
    switch (action.type) {
        case UPDATE_VARIABLE_STATE:
            return {...state, ...action.value};
        default:
            return state;
    }
};

const allVariableStatesReducer = objectMapReducer('stateId', singleVariableStateReducer, {deleteActionType: DELETE_VARIABLE_STATE});

const variableStateOrderReducer = orderedListReducer('stateId', ADD_VARIABLE_STATE, DELETE_VARIABLE_STATE, REORDER_VARIABLE_STATE);

const keyVariablesStatesReducer = combineReducers<KeyVariablesAttributeState>({
    state: allVariableStatesReducer,
    order: variableStateOrderReducer
});

const typeControlledAttributesReducer: Reducer<KeyVariablesAttributeState> = (state = {order: [], state: {}}, action) => {
    switch (action.type) {
        case UPDATE_VARIABLE_TYPE:
            const typeData = action.typeData || {
                fixedValues: null,
                minStates: 0,
                maxStates: 0
            };
            let result = {
                state: {...state.state},
                order: [...(state.order || [])]
            };
            if (typeData.fixedValues) {
                let order;
                if (state.order) {
                    // If the names don't all match, discard the states.  Otherwise, re-order them and use in place.
                    const stateNames = state.order.map((id) => (state.state[id].stateName));
                    order = typeData.fixedValues.reduce((order, name) => {
                        if (order !== undefined) {
                            const index = stateNames.indexOf(name);
                            if (index < 0) {
                                return undefined;
                            }
                            order.push(state.order[index]);
                        }
                        return order;
                    }, []);
                }
                if (order) {
                    result.order = order;
                    result.state = order.reduce((all, id) => {
                        all[id] = state.state[id];
                        return all;
                    }, {});
                    return result;
                } else {
                    result = {state: {}, order: []};
                }
            }
            let suffix = 1, attributeId;
            for (let index = result.order.length; index < typeData.minStates; ++index) {
                do {
                    attributeId = typeData.type + ' state ' + suffix++;
                } while (result.state[attributeId]);
                result.state[attributeId] = singleVariableStateReducer(undefined, {type: '@@whatever'});
                result.order.push(attributeId);
                if (typeData.fixedValues) {
                    result.state[attributeId] = {
                        ...result.state[attributeId],
                        stateName: typeData.fixedValues[index]
                    };
                }
            }
            while (result.order.length > typeData.maxStates) {
                const key = result.order.pop();
                delete(result.state[key!]);
            }
            return result;
        default:
            return keyVariablesStatesReducer(state, action);
    }
};

export default typeControlledAttributesReducer;

// ======== DBSync functions
// (used by DBSync and for unit testing)

export function getDBSyncActionTypes() {
    return [ADD_VARIABLE_STATE, UPDATE_VARIABLE_TYPE, UPDATE_VARIABLE_STATE, DELETE_VARIABLE_STATE, REORDER_VARIABLE_STATE];
}

// ======== Action generator functions

export function addKeyVariableStateAction(problemStepId: number, userId: number, status: DataStatusEnum, variableId) {
    return {type: ADD_VARIABLE_STATE, problemStepId, userId, status, variableId, stateId: v4()};
}

export function updateKeyVariableStateAction(problemStepId: number, userId: number, status: DataStatusEnum, variableId, stateId, value) {
    return {type: UPDATE_VARIABLE_STATE, problemStepId, userId, status, variableId, stateId, value};
}

export function deleteKeyVariableStateAction(problemStepId: number, userId: number, status: DataStatusEnum, variableId, stateId, index) {
    return {type: DELETE_VARIABLE_STATE, problemStepId, userId, status, variableId, stateId, index};
}

export function reorderKeyVariableStateAction(problemStepId: number, userId: number, status: DataStatusEnum, variableId, oldIndex: number, newIndex: number) {
    return {type: REORDER_VARIABLE_STATE, problemStepId, userId, status, variableId, oldIndex, newIndex};
}
