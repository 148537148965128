import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {SortableElement, SortableHandle} from 'react-sortable-hoc';
import classNames from 'classnames';
import {MenuButton} from 'react-md';

import '../scss/reorderableComponents.scss';

const DragHandle = SortableHandle(() => (
    <div className="material-icons dragHandle toolTipContainer">
        drag_handle
        <span className='toolTip toolTipDraggable'>Drag to rearrange</span>
    </div>
));

/**
 * An element which can be dragged to be reordered, as long as its parent was wrapped by react-sortable-hoc's
 * SortableContainer() function, such as ReorderableAddableContainer.  A drag handle is rendered to give the user
 * somewhere to click.  A "delete" button can also be rendered, which calls the provided callback function when clicked.
 *
 * @property disabledDelete {Boolean} (default false) If true, the "delete" button is not shown.
 * @property onDelete {Function} The function to call when the "delete" button is clicked
 * @property disableReorder {Boolean} (default false) If true, the drag handle is not rendered.
 * @property readOnly {Boolean} (default false) disable all editing (equivalent to disableDelete=true disableReorder=true).
 * @property className {string} The className set on the top-level div of this component
 * @property title {string} (optional) If provided, the element will have a title at the top.  This will also change the
 * layout of the element, putting the delete/menu control to the right of the label, and the drag handle on the right.
 * @property additionalOptions {array} (optional) An array of menu items.  If provided, a "..." drop-down menu will be
 * rendered instead of the usual delete icon (delete is assumed to be in the menu, and its callback function and enabled
 * status needs to be managed by the caller)
 *
 * <b>NOTE:</b> This component is wrapped with react-sortable-hoc's SortableElement function, which means that it also
 * accepts all of SortableElement's properties.  See
 * https://github.com/clauderic/react-sortable-hoc#sortableelement-hoc for more information.  In particular:
 *
 * @property index {Number} (REQUIRED) This is the element's sortable index within its collection.  Elements are ordered
 * with ascending indicies, and these index values are what are provided to the SortableContainer's onSortEnd callback.
 * @property disabled {Boolean} (default false) If true, the ability to drag the element to reorder it is disabled.
 * This is unfortunately not passed through by react-sortable-hoc to our code, so it is effectively duplicated by the
 * disableReorder property.  If the SortableContainer's useDragHandle prop is true, it is sufficient to just set
 * disableReorder (because without any rendered drag handle, the element cannot be dragged).
 */
class ReorderableDeleteableElement extends Component {

    static propTypes = {
        disableDelete: PropTypes.bool,
        onDelete: PropTypes.func,
        disableReorder: PropTypes.bool,
        readOnly: PropTypes.bool,
        status: PropTypes.number,
        className: PropTypes.string,
        title: PropTypes.string,
        additionalOptions: PropTypes.array
    };

    static defaultProps = {
        disableDelete: false,
        disableReorder: false,
        readOnly: false
    };

    renderDeleteButtonOrMenu() {
        const TOOLTIP_DELETE = this.props.className === 'hypotheses' ? ' Delete this hypothesis' : (this.props.className === 'evidence' ? ' Delete this evidence' : null);
        return this.props.additionalOptions ? (
            <MenuButton
                id='reorderableDeletableMenu'
                className='menuButton toolTipContainer'
                icon
                menuItems={this.props.additionalOptions}
                disabled={this.props.additionalOptions.reduce((result, option) => (result && option.disabled), true)}
                anchor={{
                    x: MenuButton.HorizontalAnchors.INNER_LEFT,
                    y: MenuButton.VerticalAnchors.BOTTOM
                }}
                position={MenuButton.Positions.TOP_LEFT}
            >
                more_horiz
                {this.props.additionalOptions.reduce((result, option) => (result && option.disabled), true) ?
                        '' :
                        <span className="toolTip">Delete, change, copy variable</span>
                }
            </MenuButton>
        ) : (
            <div className='deleteButton actionColor material-icons toolTipContainer' onClick={() => {
                this.props.onDelete();
            }}>
            <div className={TOOLTIP_DELETE ? 'toolTip toolTipRight' : ''}>{TOOLTIP_DELETE}</div>
                delete
            </div>
        );
    }

    render() {
        return (
            <div className={classNames('reorderableDeleteableContainer', this.props.className, {
                titleLayout: this.props.title
            })}>
                {!this.props.title ? null : <span className='reorderableTitle semiMajorTitle-px'>{this.props.title}</span>}
                {(this.props.disableDelete || this.props.readOnly) ? null : this.renderDeleteButtonOrMenu()}
                {(this.props.disableReorder || this.props.readOnly) ? null : <DragHandle/>}
                <div className='reorderableDeleteableElement'>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default SortableElement(ReorderableDeleteableElement);