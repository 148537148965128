import {objectMapReducer, timestampedReducer, timestampedActionCreator, ObjectMapState, TimeStampedAction, TimestampedState} from '../util/genericReducers';
import DataStatusEnum from '../DataStatusEnum';
import ensureFieldPath from '../ensureFieldPath';
import * as constants from '../util/constants';
import { Reducer, combineReducers } from 'redux';
import { StoreWithSharedState } from './sharedStateReducer';

const UPDATE_QUESTION = 'update_question';

export interface SingleQuestionState {
    text: string;
}

export interface KeyQuestionsState extends TimestampedState {
    questions: ObjectMapState<SingleQuestionState>;
}

export interface UpdateQuestionAction extends TimeStampedAction{
    type: string;
    problemStepId: number;
    userId: number;
    status: DataStatusEnum;
    questionId: string;
    text: string;
}

export function getDBSyncActionTypes() {
    return [ UPDATE_QUESTION ];
}

const singleQuestionReducer: Reducer<SingleQuestionState> = (state = {text: ''}, action) => {
    switch (action.type) {
        case UPDATE_QUESTION:
            return { ...state, text: action.text };
        default:
            return state;
    }
};

const allKeyQuestionsReducer = objectMapReducer('questionId', singleQuestionReducer);

const keyQuestionsReducer: Reducer<ObjectMapState<SingleQuestionState>> = (state = {}, action) => {
    switch (action.type) {
        case constants.COPY_PUBLISHED_ACTION:
            return action.fromUser.keyQuestions ? {...state, ...action.fromUser.keyQuestions.questions} : state;
        case constants.APPEND_PUBLISHED_ACTION:
            if (action.fromUser.keyQuestions && action.fromUser.keyQuestions.questions) {
                const publishedQuestions = action.fromUser.keyQuestions.questions;
                return Object.keys(publishedQuestions).reduce((previous, questionId) => {
                    previous[questionId] = {
                        text: publishedQuestions[questionId].text +
                            ((state[questionId] && state[questionId].text) ? '\n----------------\n' + state[questionId].text : '')
                    };
                    return previous;
                }, {...state});
            } else {
                return state;
            }
        default:
            return allKeyQuestionsReducer(state, action);
    }
};

const keyQuestionsCombineReducers = combineReducers<KeyQuestionsState>({
    questions: keyQuestionsReducer,
    timestamp: (state: string = '') => (state)
});

export default timestampedReducer(keyQuestionsCombineReducers);

export function updateQuestionAction(problemStepId: number, userId: number, status: DataStatusEnum, questionId: string, text: string): UpdateQuestionAction {
    return timestampedActionCreator({type: UPDATE_QUESTION, problemStepId, userId, status, questionId, text });
}

export function getKeyQuestionsFromStore(store: StoreWithSharedState, problemStepId: number, userId: number, status: DataStatusEnum): KeyQuestionsState {
    return store.sharedState.teams[problemStepId].user[userId].status[status].keyQuestions;
}

export function setKeyQuestionsInStore(store: StoreWithSharedState, problemStepId, userId, status, keyQuestions: KeyQuestionsState) {
    ensureFieldPath(store, 'sharedState', 'teams', problemStepId, 'user', userId, 'status', [status]);
    store.sharedState.teams[problemStepId].user[userId].status[status].keyQuestions = keyQuestions;
}