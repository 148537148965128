import moment from 'moment';
import {indexOf, without} from 'lodash';

import * as constants from './constants';
import ReferenceData, {StepDescriptionMap} from './referenceData';
import {
    getKeyVariablesFromStore,
    getKeyVariableType,
    initialKeyVariablesState,
    KeyVariablesState,
    VariableType
} from '../reducers/keyVariablesReducer';
import {BayesNetGraphState, Connection, Coordinate, getBayesNetGraphFromStore} from '../reducers/bayesNetGraphReducer';
import {
    BayesNetParameterEnum,
    BayesNetParametersState,
    BayesNetParameterType,
    getBayesNetParametersFromStore,
    ProbabilityDistribution
} from '../reducers/bayesNetParametersReducer';
import {
    BASE_SCENARIO,
    ExploreModelState,
    getExploreModelDataFromStore,
    SingleScenarioState
} from '../reducers/exploreModelReducer';
import {SingleUserForProblemReducerType} from '../reducers/allUsersForTeamReducer';
import {getFeatureFlagsFromStore} from '../reducers/featureFlagsReducer';
import {FEATURE_SOLO_BARD_SAVE_REVERT_ALL_STEPS, getFlagValue} from './featureFlags';
import {safeOrderObject} from './safeOrder';
import {ObjectMapState} from './genericReducers';
import {getUserForProblemFromStore} from '../reducers/allUsersForTeamReducerGetters';

function getRouteToStepId(stepData, startingStepId, targetStepId) {
    if (startingStepId === null) {
        return null;
    } else if (Number(startingStepId) === Number(targetStepId)) {
        return [startingStepId];
    } else {
        const nextStep = stepData[startingStepId].nextStep;
        if (Array.isArray(nextStep)) {
            return nextStep.reduce((result, stepId) => {
                if (result) {
                    return result;
                } else {
                    const route = getRouteToStepId(stepData, stepId, targetStepId);
                    return route ? [Number(startingStepId), ...route] : null;
                }
            }, null);
        } else {
            const route = getRouteToStepId(stepData, nextStep, targetStepId);
            return route ? [Number(startingStepId), ...route] : null;
        }
    }
}

export function canReachStepId(stepData, startingStepId, targetStepId) {
    for (let stepId = startingStepId; stepId !== null; stepId = stepData[stepId].nextStep) {
        if (Array.isArray(stepId)) {
            return stepId.reduce((canReach, stepId) => (canReach || canReachStepId(stepData, stepId, targetStepId)), false);
        } else if (Number(stepId) === Number(targetStepId)) {
            return true;
        }
    }
    return false;
}

function calculateSiblingStepId(stepDescriptions: StepDescriptionMap, userForProblem: SingleUserForProblemReducerType, currentStepId: number) {
    // In sub-steps, Facilitators and Solo Analysts publish all sibling steps; Analyst re-publishes all steps they've
    // already published, plus the currentStepId if that's an unpublished step.
    const publishAllSiblingSteps = (userForProblem.db.role === constants.ROLE_FACILITATOR || userForProblem.db.role === constants.ROLE_SOLO_ANALYST);
    const startingStepId = getStartingStepIdForStepId(stepDescriptions, currentStepId);
    let lastStepId = startingStepId;
    for (let stepId = startingStepId; stepId !== null; stepId = Array.isArray(stepDescriptions[stepId!].nextStep) ? stepDescriptions[stepId!].nextStep![0] : stepDescriptions[stepId!].nextStep) {
        if (publishAllSiblingSteps || stepId === currentStepId || ((userForProblem.db.publishedStepMask || 0) & (1<<stepId!)) !== 0) {
            lastStepId = stepId;
        }
    }
    return lastStepId;
}

function getStartingStepIdForStepId(stepDescriptions, currentStepId): number | null {
    return Object.keys(stepDescriptions).reduce((startingStepId: number | null, stepId) => {
        return startingStepId || (stepDescriptions[stepId].startingStep && canReachStepId(stepDescriptions, stepId, currentStepId) ? Number(stepId) : null)
    }, null);
}

function getRouteFromStartToStepId(stepDescriptions, currentStepId): number[] {
    return Object.keys(stepDescriptions).reduce((result, stepId) => (
        result || (stepDescriptions[stepId].startingStep ? getRouteToStepId(stepDescriptions, stepId, currentStepId) : null)
    ), null) || [];
}

function accumulateSyncVariables(stepDescriptions, currentStepId) {
    const route = getRouteFromStartToStepId(stepDescriptions, currentStepId);
    return route.reduce((result, stepId) => (
        result.concat(stepDescriptions[stepId].syncVariables)
    ), []);
}

/**
 * Test if array2 is a permutation of array1, and if so return an array of indexes into array1 to create array2
 *
 * @param array1 The original array
 * @param array2 The permuted array
 * @returns An empty array if the arrays are identical, null if the arrays are not permutations of one another, or an
 * array of indexes into array1 of the values of array2 otherwise.
 */
function arrayPermutation<T extends number | string>(array1: T[], array2: T[]): number[] | null {
    let isPermutation = true;
    let isIdentical = true;
    if (array1.length === array2.length) {
        let result = array2.map((value, index) => {
            let array1Index = indexOf(array1, value);
            if (array1Index < 0) {
                isPermutation = false;
            } else if (array1Index !== index) {
                isIdentical = false;
            }
            return array1Index;
        });
        if (isPermutation) {
            return (isIdentical) ? [] : result;
        }
    }
    // Arrays are too different
    return null;
}

function getSourceVariables(bayesNetData, targetVariableId): string[] {
    let sourceVariableIds = {};
    bayesNetData.connections.forEach(({source, target}) => {
        if (target === targetVariableId) {
            sourceVariableIds[source] = true;
        }
    });
    return safeOrderObject(bayesNetData.nodes, 'variable')
        .filter((variableId) => (sourceVariableIds[variableId]));
}

function newBayesNetModelDataMigration<T>(updated: T, migrated: boolean): BayesNetModelDataMigration<T> {
    return {updated, migrated};
}

function useOrCreateVariablesData(variableData: KeyVariablesState): BayesNetModelDataMigration<KeyVariablesState> {
    if (variableData) {
        return newBayesNetModelDataMigration(variableData, false);
    } else {
        return newBayesNetModelDataMigration({
            order: [],
            variable: {},
            timestamp: moment().format()
        }, true);
    }
}

function migrateVariablesToConnections(variableData: KeyVariablesState, graphData: BayesNetGraphState): BayesNetModelDataMigration<BayesNetGraphState> {
    let result: BayesNetModelDataMigration<BayesNetGraphState> = newBayesNetModelDataMigration(graphData, false);
    if (!graphData || !graphData.timestamp || moment(graphData.timestamp).valueOf() <= moment(variableData.timestamp).valueOf()) {
        // Compare updated variableData with old graphData to see what changed.
        let connections: Connection[] = [], coords: ObjectMapState<Coordinate> = {};
        if (graphData && graphData.connections) {
            connections = graphData.connections.filter((connection) => {
                const sourceVariable = variableData.variable[connection.source];
                const targetVariable = variableData.variable[connection.target];
                // Allow connections if both the source and target still exist, and as long as the connection is still
                // legal (utility nodes may not be sources, and decision variables may not be targets).
                return (
                    sourceVariable && targetVariable
                    && getKeyVariableType(sourceVariable.data) !== VariableType.UTILITY
                    && getKeyVariableType(targetVariable.data) !== VariableType.DECISION
                );
            });
        }

        if (graphData && graphData.coords) {
            coords = Object.keys(graphData.coords).reduce((newCoords, nodeId) => {
                // Keep coords for nodes that still exist in keyVariables
                if (variableData.variable[nodeId]) {
                    newCoords[nodeId] = graphData.coords[nodeId];
                }
                return newCoords;
            }, {});
        }

        result = newBayesNetModelDataMigration<BayesNetGraphState>({
            nodes: {...variableData},
            connections,
            coords,
            timestamp: variableData.timestamp,
        }, true);
    }
    return result;
}

/**
 * Calculate the permutation map from old indicies to new indicies of the condition cases, given a certain permutation
 * if source variables and their states.
 */
function mapPermutation(originalArities, newArityFactors, sourcePermutation, statePermutations, index = 0, base = 0, result: number[] = []) {
    if (index < sourcePermutation.length) {
        let variableIndex = sourcePermutation[index];
        for (let state = 0; state < originalArities[variableIndex]; ++state) {
            let stateIndex = statePermutations[variableIndex] ? statePermutations[variableIndex][state] : state;
            mapPermutation(originalArities, newArityFactors, sourcePermutation, statePermutations, index + 1, base + newArityFactors[index]*stateIndex, result);
        }
    } else {
        result.push(base);
    }
    return result;
}

function calculatePermutation(originalArities, sourcePermutation, statePermutations) {
    const originalArityFactors: number[] = [];
    let total = 1;
    for (let index = originalArities.length - 1; index >= 0; index--) {
        originalArityFactors[index] = total;
        total *= originalArities[index];
    }
    let newArityFactors = sourcePermutation.map((index) => (originalArityFactors[index]));
    return mapPermutation(originalArities, newArityFactors, sourcePermutation, statePermutations);
}

function migrateConnectionsToParameters(graphData: BayesNetGraphState, parameterData: BayesNetParametersState): BayesNetModelDataMigration<BayesNetParametersState> {
    let result: BayesNetModelDataMigration<BayesNetParametersState> = newBayesNetModelDataMigration(parameterData, false);
    if (!parameterData || !parameterData.timestamp || moment(parameterData.timestamp).valueOf() <= moment(graphData.timestamp).valueOf()) {
        let probabilities: ObjectMapState<ProbabilityDistribution[]> = {};
        let parameters: ObjectMapState<BayesNetParameterType> = {};
        // Can compare updated graphData with old parameterData to see what changed.
        if (parameterData && parameterData.probabilities) {
            Object.keys(parameterData.probabilities).forEach((variableId) => {
                if (!graphData.nodes.variable[variableId]) {
                    // variableId has gone - discard this variable's CPT
                    return;
                }
                let conditionalProbabilityTable = parameterData.probabilities[variableId];
                const statePermutation = arrayPermutation(parameterData.nodes.variable[variableId].attributes.order, graphData.nodes.variable[variableId].attributes.order);
                if (statePermutation === null) {
                    // variableId's states have changed - discard this variable's CPT
                    return;
                } else if (statePermutation.length > 0) {
                    // variableId's states are the same but have been reordered... reorder CPT to match
                    conditionalProbabilityTable = conditionalProbabilityTable.map((row) => {
                        return row.map((_, index) => (row[statePermutation[index]]));
                    });
                }
                // Check that the sources to this variable haven't changed.
                let parameterDataSources = getSourceVariables(parameterData, variableId);
                let graphDataSources = getSourceVariables(graphData, variableId);
                let sourcePermutation = arrayPermutation(parameterDataSources, graphDataSources);
                if (sourcePermutation === null) {
                    // Source variables for variableId have changed - discard this variable's CPT
                    return;
                }
                // Check that the states of those sources haven't changed.
                let graphDataSourceStatePermutation: number[][] = [];
                for (let index = 0; index < graphDataSources.length; ++index) {
                    let sourceVariableId = graphDataSources[index];
                    let sourceStatePermutation = arrayPermutation(parameterData.nodes.variable[sourceVariableId].attributes.order, graphData.nodes.variable[sourceVariableId].attributes.order);
                    if (sourceStatePermutation === null) {
                        // Source variable states have changed - discard this variable's CPT
                        return;
                    } else if (sourceStatePermutation.length > 0) {
                        graphDataSourceStatePermutation[index] = sourceStatePermutation;
                    }
                }
                if (sourcePermutation.length > 0 || graphDataSourceStatePermutation.length > 0) {
                    // Source variables and states are the same but have been reordered... reorder the CPT to match
                    if (sourcePermutation.length === 0) {
                        sourcePermutation = parameterDataSources.map((_, index) => (index));
                    }
                    let originalArities = parameterDataSources.map((variableId) => (parameterData.nodes.variable[variableId].attributes.order.length));
                    let permutation = calculatePermutation(originalArities, sourcePermutation, graphDataSourceStatePermutation);
                    conditionalProbabilityTable = conditionalProbabilityTable.map((_, index) => (conditionalProbabilityTable[permutation[index]]));
                }
                // Nothing has changed too much to handle!  Keep this variable's CPT and parameters
                probabilities[variableId] = conditionalProbabilityTable;
                parameters[variableId] = (parameterData.parameters && parameterData.parameters[variableId]) || {type: BayesNetParameterEnum.MANUAL};
            });
        }
        result = newBayesNetModelDataMigration({
            ...graphData,
            probabilities,
            parameters,
            timestamp: graphData.timestamp
        }, true);
    }
    return result;
}

function migrateParametersToExploreModel(parameterData: BayesNetParametersState, exploreModelData: ExploreModelState): BayesNetModelDataMigration<ExploreModelState> {
    let result: BayesNetModelDataMigration<ExploreModelState> = newBayesNetModelDataMigration(exploreModelData, false);
    if (!exploreModelData || !exploreModelData.timestamp || moment(exploreModelData.timestamp).valueOf() <= moment(parameterData.timestamp).valueOf()) {
        let scenario: ObjectMapState<SingleScenarioState> = {
            [BASE_SCENARIO]: {
                data: {
                    scenarioId: BASE_SCENARIO,
                    evidence: {variable: {}, order: []}
                },
                monitorVariables: [],
                monitorVariableFocusStates: []
            }
        };
        // Can compare updated parameterData with old exploreModelData to see what changed.
        if (exploreModelData && exploreModelData.scenario) {
            // Migrate scenarios
            Object.keys(exploreModelData.scenario).forEach((scenarioId) => {
                const oldScenario = exploreModelData.scenario[scenarioId];
                // Discard any monitor variables that refer to variables that no longer exist.
                let thisScenario = {
                    ...oldScenario,
                    monitorVariables: (oldScenario.monitorVariables || []).filter((variableId) => (parameterData.nodes.variable[variableId])),
                    timestamp: parameterData.timestamp
                };
                // Discard beliefs and explanation if they need to be updated.
                if (!oldScenario.timestamp || moment(oldScenario.timestamp).isBefore(moment(parameterData.timestamp))) {
                    delete(thisScenario.beliefs);
                    delete(thisScenario.explanation);
                }
                // Discard evidence that refers to variables that no longer exist, or whose node states have changed
                (oldScenario.data.evidence.order || []).forEach((variableId) => {
                    let deleteEvidenceNode = false;
                    if (!parameterData.nodes.variable[variableId]) {
                        // variable has been deleted - discard this evidence node
                        deleteEvidenceNode = true;
                    } else {
                        let evidenceNodeStates = arrayPermutation(exploreModelData.bayesNet.nodes.variable[variableId].attributes.order, parameterData.nodes.variable[variableId].attributes.order);
                        if (evidenceNodeStates === null || evidenceNodeStates.length > 0) {
                            // evidence node states have changed - discard this evidence node.
                            deleteEvidenceNode = true;
                        }
                    }
                    if (deleteEvidenceNode) {
                        delete(thisScenario.data.evidence.variable[variableId]);
                        thisScenario.data.evidence.order = without(thisScenario.data.evidence.order, variableId);
                    }
                });
                scenario[scenarioId] = thisScenario;
            });
        }
        result = newBayesNetModelDataMigration<ExploreModelState>({
            ...exploreModelData,
            bayesNet: parameterData,
            scenario,
            timestamp: parameterData.timestamp
        }, true);
    }
    return result;
}

function migrateData(previousData, syncVariable, store, problemStepId, userId, status): BayesNetModelDataMigration {
    switch (syncVariable) {
        case constants.SYNC_KEY_VARIABLES:
            const variableData = getKeyVariablesFromStore(store, problemStepId, userId, status);
            // eslint-disable-next-line react-hooks/rules-of-hooks
            return useOrCreateVariablesData(variableData);
        case constants.SYNC_BN_GRAPH:
            const graphData = getBayesNetGraphFromStore(store, problemStepId, userId, status);
            return migrateVariablesToConnections(previousData, graphData);
        case constants.SYNC_BN_PARAMETERS:
            const parameterData = getBayesNetParametersFromStore(store, problemStepId, userId, status);
            return migrateConnectionsToParameters(previousData, parameterData);
        case constants.SYNC_EXPLORE_MODEL:
            const exploreModelData = getExploreModelDataFromStore(store, problemStepId, userId, status);
            return migrateParametersToExploreModel(previousData, exploreModelData);
        default:
            throw new Error('invalid syncVariable passed to migrateData');
    }
}

const getAllSyncVariables = function (stepDescriptions) {
    const allSyncVariables = Object.keys(stepDescriptions).reduce((allSyncVariables, stepId) => {
        const stepDescription = stepDescriptions[stepId];
        if (stepDescription.syncVariables) {
            stepDescription.syncVariables.forEach((syncVariable) => {
                allSyncVariables[syncVariable] = true;
            })
        }
        return allSyncVariables;
    }, {});
    return Object.keys(allSyncVariables);
};

type BayesNetModelTypes = KeyVariablesState | BayesNetGraphState | BayesNetParametersState | ExploreModelState;

export interface BayesNetModelDataMigration<T = BayesNetModelTypes> {
    updated: T,
    migrated: boolean
}

/**
 * Return the syncVariables to be published for the given stepId.  In singleton steps, the syncVariable(s)
 * are simply those of the current step.  In steps which have a parent, the Facilitator publishes sync variables across
 * all sibling steps, while an analyst re-publishes all sibling steps they've already published.
 *
 * In addition, for soloAnalyst users, if the FEATURE_SOLO_BARD_SAVE_REVERT_ALL_STEPS feature flag is on, they "publish"
 * all syncVariables in every step.
 *
 * @param store The redux store state
 * @param problemStepId The ID of the current problem
 * @param userId The ID of the user
 * @param stepId The stepId of the step whose data we want to publish
 * @returns An array of syncVariables to publish
 */
export function getSyncVariablesForPublish(store, problemStepId, userId, stepId): string[] | undefined {
    const userForProblem = getUserForProblemFromStore(store, problemStepId, userId);
    const stepDescriptions = ReferenceData.getInstance().getAllStepDescriptions();
    if (userForProblem.db.role === constants.ROLE_SOLO_ANALYST) {
        const featureFlag = getFeatureFlagsFromStore(store);
        if (getFlagValue(featureFlag[FEATURE_SOLO_BARD_SAVE_REVERT_ALL_STEPS], store)) {
            return getAllSyncVariables(stepDescriptions);
        }
    }
    let result: string[] | undefined;
    if (stepDescriptions[stepId].parentStepId) {
        // In sub-steps, we use the sync variables of multiple sibling steps.
        const siblingStepId = calculateSiblingStepId(stepDescriptions, userForProblem, stepId);
        result = accumulateSyncVariables(stepDescriptions, siblingStepId);
    } else {
        // If not in a sub-step, just use current step's syncVariables
        result = stepDescriptions[stepId].syncVariables;
    }
    return result;
}

/**
 * Get the Bayesian model data for the given Sync Variable.  This data may simply be the latest data the user entered for the
 * step, but it might also have to be "migrated" from changes the user has made to earlier steps since last editing
 * this step.  For example, if the user entered two variables, made a connection between them in Structure and then went
 * back to Variables and deleted one, the connection needs to be discarded.  This method would return the structure save
 * data without the invalid connection when called with the stepId for Structure.
 *
 * @param store The redux store state
 * @param problemStepId The ID of the current problem
 * @param userId The ID of the user whose data we want to get
 * @param status The status (from DataStatusEnum) of the data we want to get
 * @param currentSyncVariable The syncvariable to which we want the roll forward process to continue.
 * @returns {updated, migrated} the updated save data for the syncVariable of the given stepId, plus a migrated flag
 * indicating whether the data was migrated from earlier save data or is up-to-date.
 */
export function getBayesianModelData<T = BayesNetModelTypes>(store, problemStepId, userId, status, currentSyncVariable: constants.BNSyncVariableType): BayesNetModelDataMigration<T> {
    let indexOfCurrentSyncVariable = constants.BN_SYNC_VARIABLES_LIST.indexOf(currentSyncVariable);
    const syncVariables = constants.BN_SYNC_VARIABLES_LIST.slice(0, indexOfCurrentSyncVariable + 1);
    return syncVariables.reduce<BayesNetModelDataMigration<any>>((previousData, syncVariable) => {
        return migrateData(previousData.updated, syncVariable, store, problemStepId, userId, status);
    }, {updated: initialKeyVariablesState, migrated: false});
}


export function findSourceVariables(bnParameters: BayesNetParametersState, variableId: string) {
    let sourceVariableIds = {};
    bnParameters.connections.forEach(({source, target}) => {
        if (target === variableId) {
            sourceVariableIds[source] = true;
        }
    });
    return safeOrderObject(bnParameters.nodes, 'variable').filter((variableId) => (sourceVariableIds[variableId]));
}

export function findTargetVariables(bnParameters: BayesNetParametersState, variableId: string) {
    let targetVariableIds = {};
    bnParameters.connections.forEach(({source, target}) => {
        if (source === variableId) {
            targetVariableIds[target] = true;
        }
    });
    return safeOrderObject(bnParameters.nodes, 'variable').filter((variableId) => (targetVariableIds[variableId]));
}

export function calculateCombinations(nodes: KeyVariablesState, connections: Connection[]): {[variableId: string]: number} {
    let result: {[variableId: string]: number} = {};
    if (nodes) {
        safeOrderObject(nodes, 'variable').forEach((variableId) => {
            result[variableId] = 1;
        });
        connections.forEach(({source, target}) => {
            result[target] *= nodes.variable[source].attributes.order.length;
        });
    }
    return result;
}

