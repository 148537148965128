import React, {Component} from 'react';
import {Redirect, withRouter} from 'react-router-dom';
import {connect} from 'react-redux';
import {isEmpty} from 'lodash';

import BardTemplate from './BardTemplate';
import {getLoggedInUserIdFromStore} from '../reducers/loginReducer';
import {getAllProblemDBsFromStore} from '../../common/reducers/allProblemsReducer';
import {getListenerIdFromStore} from '../reducers/listenerIdReducer';
import { handleErrors } from '../util/ajaxUtils';
import {buildRestPath, OPEN_PROBLEM_ENDPOINT, SELECT_PATH} from '../../common/clientServer/navigation';
import * as constants from '../../common/util/constants';
import { handleRouteChange } from '../util/userActivity';
import LoadingBar from '../presentation/LoadingBar';
import {getAllTeamsFromStore, getTeamFromStore} from "../../common/reducers/allTeamsReducerGetters";
import {getUserForProblemFromStore} from "../../common/reducers/allUsersForTeamReducerGetters";

class ProblemValidationComponent extends Component {
    historyUnlistener;

    constructor(props) {
        super(props);

        this.loadProblem = this.loadProblem.bind(this);
    }

    loadProblem() {
        if (this.props.shouldLoadTeamData) {
            const url = buildRestPath(OPEN_PROBLEM_ENDPOINT, {problemStepId: this.props.problemStepId});
            fetch(url, {
                credentials: 'include',
                method: 'PUT',
                headers: {'content-type': 'application/json', [constants.HEADER_LISTENER_ID]: this.props.listenerId},
                body: JSON.stringify({action: constants.OPEN_PROBLEM_ACTION})
            })
            .then(handleErrors);
        }
    }

    componentDidMount() {
        this.historyUnlistener = this.props.history.listen(handleRouteChange(this.props.location.pathname));
        this.loadProblem();
    }

    componentDidUpdate() {
        this.loadProblem();
    }

    componentWillUnmount() {
        if (this.historyUnlistener) {
            this.historyUnlistener();
        }
    }

    render() {

        if (this.props.problemStepId &&
            this.props.listenerId &&
            this.props.allTeams &&
            this.props.problems && 
            ((this.props.canOpenProblem) || 
             (this.props.team && this.props.problems[this.props.team.problemStep.problemId].isTraining)
            ) &&
            this.props.userForProblem.db.role !== constants.ROLE_UNALLOCATED &&
            this.props.team.problemStep.state !== constants.STATE_ARCHIVED
        ) {
            return <BardTemplate problemStepId={this.props.problemStepId}/>;
        } else if (!isEmpty(this.props.allTeams) && (!this.props.userForProblem || this.props.userForProblem.db.role === constants.ROLE_UNALLOCATED
            || !this.props.team.problemStep.launched || this.props.team.problemStep.state === constants.STATE_ARCHIVED)) {
            // We have problem data, but this user doesn't seem to be assigned to this problemStepId - redirect back to selection
            return <Redirect to={SELECT_PATH} />;
        } else {
            // With the ability to open a tab directly in a problem, the Redux store may not have loaded yet... just wait
            return <div className='loadingProblem'>
                <div className="loadingBarContentContainer"><LoadingBar /></div>
            </div>;
        }
    }
}

function mapStoreToProps(store, myProps) {
    const problemStepId = myProps.match && Number(myProps.match.params.problemStepId);
    const allTeams = getAllTeamsFromStore(store);
    const team = getTeamFromStore(store, problemStepId);
    const userId = getLoggedInUserIdFromStore(store);
    const listenerId = getListenerIdFromStore(store);
    const userForProblem = getUserForProblemFromStore(store, problemStepId, userId);
    const problems = getAllProblemDBsFromStore(store);
    const shouldLoadTeamData = team && !team.isFullyLoaded;
    const canOpenProblem = userForProblem && team && team.problemStep.launched && !shouldLoadTeamData;
    return {
        problemStepId,
        listenerId,
        team,
        allTeams,
        userForProblem,
        problems,
        userId,
        canOpenProblem,
        shouldLoadTeamData
    };
}

export default withRouter(connect(mapStoreToProps)(ProblemValidationComponent));