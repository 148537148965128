import React, {Component} from 'react';
import {connect} from 'react-redux';
import classnames from 'classnames';
import {RouteComponentProps} from 'react-router';
import {withRouter} from 'react-router-dom';

import {
    dismissNotificationAction,
    getNotificationsForProblemStepAndUserFromStore,
    NOTIF_GLOBAL,
    NotificationsForUserType,
    NotificationType
} from '../../common/reducers/notificationsReducer';
import {getLoggedInUserIdFromStore} from '../reducers/loginReducer';
import DispatchProp from '../../@types/dispatchProp';
import {Tag} from '../../common/util/constants';
import {StoreWithSharedState} from '../../common/reducers/sharedStateReducer';

interface NotificationContainerOwnProps {
    tags: Tag[];
    keyValue?: number | string;
    stepId?: number;
    showCounter?: boolean;
    showFlag?: boolean;
    clickToDismiss?: boolean;
    setDismiss?: (dismiss: () => void) => void;
    acknowledge?: string[];
    className?: string;
    disabled?: boolean
}

interface NotificationContainerStoreProps {
    userId: number;
    problemStepId: number;
    notifications: NotificationsForUserType;
}

type NotificationContainerProps = NotificationContainerOwnProps & NotificationContainerStoreProps & DispatchProp;

/**
 * By default, this component displays or hides its children based on the state of the notification system.  It is
 * considered active (shows its children) if any of the tags in its props have a non-zero non-false value.
 *
 * See https://gitlab.erc.monash.edu.au/CREATE-BARD/Bard/wikis/notification-system
 *
 * @prop {Array<string>} tags The tag or tags this component examines to determine whether it is active or not
 * @prop {number | string} keyValue (optional, default undefined) This is used in conjunction with PER_KEY_ type
 * tags to specify which key to check.
 * @prop {number} stepId (optional, default whole-problem) If specified, this notification is specific to the given step
 * of the current problem; otherwise, it applies to the entire problem.  This is useful when users working on a problem
 * might be in different steps.
 * @prop {boolean} showCounter (optional, default false) If this flag is true, the component will *not* show its
 * children when active, but instead shows the total value of all its flags.
 * @prop {boolean} showFlag (optional, default false) If this flag is true, the component will *not* show its
 * children when active, but instead shows an icon.
 * @prop {boolean} clickToDismiss (optional, default false) If this flag is true, the component will have an onClick
 * handler which dispatches an action to dismiss the notification it displays.
 * @prop {() => boolean} setDismiss (optional, default no-op) If supplied, this function will be invoked on mount with
 * the parameter set to a function which will dismiss this component's notification if it is set.  This can be used to
 * allow other actions on the parent page to dismiss the notification.
 * @prop {Array<string>} acknowledge (optional) If clickToDismiss is true and this option is set, the notification
 * system will send a notification with the tags listed in acknowledge back to the forUserId user.
 * @prop {string} className (optional) If specified, changes the behaviour of the NotificationContainer - instead of
 * showing or hiding its children based on the notifications, the children will always be shown, but if the
 * notifications are active they will be wrapped in a parent div with the given className(s).
 * @prop {boolean} disabled (optional, default false) If true, the notification will be hidden.
 */
class NotificationContainer extends Component<NotificationContainerProps> {

    constructor() {
        super(undefined as any);
        this.onNotificationClick = this.onNotificationClick.bind(this);
        this.dismiss = this.dismiss.bind(this);
    }

    componentDidMount() {
        if (this.props.setDismiss) {
            this.props.setDismiss(this.dismiss);
        }
    }

    onNotificationClick(evt) {
        evt.stopPropagation();
        this.dismiss();
    }

    dismiss() {
        if (this.sumAllTagValues() > 0) {
            this.props.dispatch(dismissNotificationAction(this.props.tags, this.props.userId, this.props.problemStepId, this.props.stepId, this.props.keyValue, this.props.acknowledge));
        }
    }

    getTagValue(tagData) {
        if (tagData) {
            switch (tagData.type) {
                case NotificationType.FLAG:
                    return tagData.value ? 1 : 0;
                case NotificationType.COUNT:
                    return tagData.value;
                case NotificationType.PER_KEY_FLAG:
                    if (this.props.keyValue) {
                        return tagData.value[this.props.keyValue] ? 1 : 0;
                    } else {
                        return Object.keys(tagData.value).reduce((total, userId) => total + (tagData.value[userId] ? 1 : 0), 0);
                    }
                case NotificationType.PER_KEY_COUNT:
                    if (this.props.keyValue) {
                        return tagData.value[this.props.keyValue];
                    } else {
                        return Object.keys(tagData.value).reduce((total, userId) => total+parseInt(tagData.value[userId], 10), 0);
                    }
                default:
                    break;
            }
        }
        return 0;
    }

    sumTagValues(notifications) {
        let result = 0;
        if (notifications) {
            this.props.tags.forEach((tag) => {
                result += this.getTagValue(notifications[tag]);
            });
        }
        return result;
    }

    sumAllTagValues() {
        let value = 0;
        if (this.props.notifications) {
            value = this.sumTagValues(this.props.notifications[NOTIF_GLOBAL]);
            if (this.props.stepId) {
                value += this.sumTagValues(this.props.notifications[this.props.stepId]);
            }
        }
        return value;
    }

    render() {
        let value = this.sumAllTagValues();
        if ((!this.props.disabled && value > 0) || this.props.className) {
            let className = classnames({
                clickToDismiss: this.props.clickToDismiss,
                notificationCounter: this.props.showCounter || this.props.showFlag
            }, (!this.props.disabled && value > 0) ? this.props.className : undefined);
            const onClick = (this.props.clickToDismiss) ? this.onNotificationClick : undefined;
            if (this.props.showCounter) {
                return <span onClick={onClick} className={className}>{value}</span>;
            } else if (this.props.showFlag) {
                return <span onClick={onClick} className={className}/>;
            } else {
                return <span onClick={onClick} className={className}>{this.props.children}</span>;
            }
        } else {
            return null;
        }
    }
}

function mapStoreToProps(store: StoreWithSharedState, myProps: NotificationContainerOwnProps & RouteComponentProps<{problemStepId: string}>): NotificationContainerStoreProps {
    const userId = getLoggedInUserIdFromStore(store);
    const problemStepId = myProps.match && Number(myProps.match.params.problemStepId);
    return {
        userId,
        problemStepId,
        notifications: getNotificationsForProblemStepAndUserFromStore(store, problemStepId, userId)
    };
}

export default withRouter(connect(mapStoreToProps)(NotificationContainer));