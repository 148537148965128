import * as React from 'react';

import * as constants from '../../common/util/constants';
import {safeOrderObject} from '../../common/util/safeOrder';
import {
    getKeyVariableType,
    KeyVariablesState,
    SingleVariableState,
    VariableType
} from '../../common/reducers/keyVariablesReducer';

// Style properties with a "text_" have this prefix removed and are applied
// to the SVG text object used to display node text.

export function getBaseBayesNetStyle(nodes: KeyVariablesState) {
    return safeOrderObject(nodes, 'variable').reduce((styles, variableId) => {
        switch (getKeyVariableType(nodes.variable[variableId].data)) {
            case VariableType.TARGET:
                styles[variableId] = {
                    fill: constants.colourTargetVariable,
                    stroke: constants.colourTargetVariable,
                    text_fill: constants.colourLightGrey,
                    text_fontWeight: "bold"
                };
                break;
            case VariableType.OTHER:
                styles[variableId] = {
                    fill: constants.colourOtherVariable,
                    stroke: constants.colourOtherVariable,
                    text_fontWeight: "bold"
                };
                break;
            case VariableType.DECISION:
                styles[variableId] = {
                    fill: constants.colourDecisionVariable,
                    stroke: constants.colourDecisionVariable,
                    text_fontWeight: "bold"
                };
                break;
            case VariableType.UTILITY:
                styles[variableId] = {
                    fill: constants.colourUtilityVariable,
                    stroke: constants.colourUtilityVariable,
                    text_fill: constants.colourLightGrey,
                    text_fontWeight: "bold"
                };
                break;
            default:
                break;
        }
        return styles;
    }, {});
}

export function getBayesNetNodeShape(node: SingleVariableState, size: {width: number, height: number}, nodeStyle?: React.CSSProperties): React.ReactElement | undefined {
    const description = node.data.description.trim() ? node.data.description : 'No description set in Step 2';
    const left = -size.width / 2;
    const top = -size.height / 2;

    switch (getKeyVariableType(node.data)) {
        case VariableType.DECISION:
            return (
                <g>
                    <title>{description}</title>
                    <rect x={left} y={top}
                          width={size.width} height={size.height}
                          style={nodeStyle}
                    />
                </g>
            );
        case VariableType.UTILITY:
            const right = -left;
            const bottom = -top;
            const halfHeight = size.height / 4;
            return (
                <g>
                    <title>{description}</title>
                    <polygon points={`${left},0 ${left + halfHeight},${top} ${right - halfHeight},${top} ${right},0 ` +
                        `${right - halfHeight},${bottom} ${left + halfHeight},${bottom}`}
                         style={nodeStyle}
                    />
                </g>
            );
        default:
            return (
                <g>
                    <title>{description}</title>
                    <rect x={left} y={top}
                          width={size.width} height={size.height}
                          rx={10} ry={10}
                          style={nodeStyle}
                    />
                </g>
            );
    }
}