import {AnyAction} from 'redux';

import {ObjectMapState} from '../util/genericReducers';
import Project from '../clientServer/project';
import {StoreWithSharedState} from './sharedStateReducer';
import ensureFieldPath from '../ensureFieldPath';
import ProjectUser from '../clientServer/projectUser';
import ProjectProblem from '../clientServer/projectProblem';

// ======== Constants

export enum ProjectsReducerActionTypes {
    UPDATE_PROJECT = 'update-project',
    UPDATE_USERS = 'update-project-users',
    ADD_USER = 'add-project-user',
    UPDATE_PROBLEMS = 'update-project-problems',
    ADD_PROBLEM = 'add-project-problem'
}

// ======== Action Generators

export function updateProjectAction(project: Project) {
    return {type: ProjectsReducerActionTypes.UPDATE_PROJECT, projectId: project.id, project};
}

export function updateProjectUsersAction(projectId: number, users: ObjectMapState<ProjectUser>) {
    return {type: ProjectsReducerActionTypes.UPDATE_USERS, projectId, users};
}

export function addProjectUserAction(projectId: number, userId: number, readOnly: boolean = false) {
    return {type: ProjectsReducerActionTypes.ADD_USER, projectId, user: {projectId, userId, readOnly}};
}

export function updateProjectProblemsAction(projectId: number, problems: ObjectMapState<ProjectProblem>) {
    return {type: ProjectsReducerActionTypes.UPDATE_PROBLEMS, projectId, problems};
}

export function addProjectProblemAction(projectId: number, problemId: number) {
    return {type: ProjectsReducerActionTypes.ADD_PROBLEM, projectId, problem: {ProjectId: projectId, ProblemId: problemId}};
}

// ======== Reducers

export type ProjectsReducerType = ObjectMapState<Project>;

export default function projectsReducer(state: ProjectsReducerType = {}, action: AnyAction): ProjectsReducerType {
    switch (action.type) {
        case ProjectsReducerActionTypes.UPDATE_PROJECT:
            return {...state, [action.project.id]: action.project};
        case ProjectsReducerActionTypes.UPDATE_USERS:
            return {...state, [action.projectId]: {...state[action.projectId], users: action.users}};
        case ProjectsReducerActionTypes.ADD_USER:
            return {...state, [action.projectId]: {...state[action.projectId], users: {...state[action.projectId].users, [action.user.userId]: action.user}}};
        case ProjectsReducerActionTypes.UPDATE_PROBLEMS:
            return {...state, [action.projectId]: {...state[action.projectId], problems: action.problems}};
        case ProjectsReducerActionTypes.ADD_PROBLEM:
            return {...state, [action.projectId]: {...state[action.projectId], problems: {...state[action.projectId].problems, [action.problem.ProblemId]: action.problem}}};
        default:
            return state;
    }
}

// ======== DBSync functions
// (used by DBSync and for unit testing)

export function getActivityActionTypes() {
    return [ProjectsReducerActionTypes.UPDATE_PROJECT, ProjectsReducerActionTypes.UPDATE_USERS,
        ProjectsReducerActionTypes.ADD_USER, ProjectsReducerActionTypes.UPDATE_PROBLEMS, ProjectsReducerActionTypes.ADD_PROBLEM];
}

export function setProjectInStore(store: StoreWithSharedState, project?: Project) {
    ensureFieldPath(store, 'sharedState', 'projects');
    if (project) {
        store.sharedState.projects[project.id] = project;
    }
}

export function getAllProjectsFromStore(store: StoreWithSharedState): ProjectsReducerType {
    return store.sharedState.projects;
}