import React from "react";

const DeleteButton = ({ onDelete }) => (
    <g
        onMouseDown={e => {
            e.preventDefault();
            e.stopPropagation();
        }}
        onClick={onDelete}
    >
        <circle
            className="deleteButton"
            r={12}
            style={{ filter: "url(#shadow)" }}
        />
        <text
            fill="white"
            textAnchor="middle"
            alignmentBaseline="central"
            style={{ pointerEvents: "none" }}
        >
            &#10005;
        </text>
        <title>Delete Link</title>
    </g>
);

export default DeleteButton;
