import React, {Component} from 'react';
import PropTypes from 'prop-types'

import * as constants from '../../common/util/constants';

class GroupManagementTimer extends Component {

    static propTypes = {
        latestActivity: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        userRole: PropTypes.number,
        className: PropTypes.string,
        alertClassName: PropTypes.string
    };

    static defaultProps = {
        userRole: constants.ROLE_OBSERVER
    };

    constructor(props) {
        super(props);
        this.state = {
            latestActivity: props.latestActivity
        };
    }

    classList(days) {
        let list = 'groupManagementTimerDisplay';
        if (this.props.className) {
            list += ' ' + this.props.className;
        }
        
        if (this.props.alertClassName) {
            if (this.props.userRole === constants.ROLE_FACILITATOR && (days < 0 || days > constants.FACILITATOR_DELINQUENT_DAYS)) {
                list += ' ' + this.props.alertClassName;
            }
            else if (this.props.userRole === constants.ROLE_ANALYST && (days < 0 || days > constants.ANALYST_DELINQUENT_DAYS)) {
                list += ' ' + this.props.alertClassName;
            }
            else if (this.props.userRole === constants.ROLE_OBSERVER && (days < 0)) {
                list += ' ' + this.props.alertClassName;
            }
        }
        return list;
    }

    timerText() {
        if (this.state.latestActivity === null) {
            let classes = this.classList(-1);
            return <span className={classes}>{'Unknown'}</span>;
        }
        else if (this.state.latestActivity === undefined || isNaN(this.state.latestActivity.getTime())) {
            let classes = this.classList(-1);
            return <span className={classes}>{'Never'}</span>;
        }
        else {
            let minutes = (new Date() - this.state.latestActivity) / 1000 / 60;
            let days = Math.trunc(minutes / 1440);

            let text;
            if (minutes < 5) text = 'Now';
            else if (minutes < 60) text = '< 1 hour ago';
            else if (minutes < 1440) text = '< 1 day ago';
            else if (minutes < 2880) text = '< 2 days ago';
            else if (minutes < 4320) text = '< 3 days ago';
            
            if (!text) {
                text = Math.trunc(days) + ' days ago';
            }

            let classes = this.classList(days);
            return <span className={classes}>{text}</span>;
        }
    }


    UNSAFE_componentWillReceiveProps(props) {
        this.setState({latestActivity: props.latestActivity}, this.setState({timerText: this.timerText()}));
    }

    componentDidMount() {
        this.setState({timerText: this.timerText()});
        let timerHandler = setInterval(() => {
            this.setState({timerText: this.timerText()});
        }, 1000);

        this.setState({
            ...this.state,
            timerHandler: timerHandler
        });
    }

    componentWillUnmount() {
        clearInterval(this.state.timerHandler);
    }

    render() {
        return (
            <span className={this.props.className ? ('groupManagementTimerDisplay ' + this.props.className) : 'groupManagementTimerDisplay'}>{this.state.timerText}</span>
        );
    }
}

export default GroupManagementTimer;