import React, {Component} from 'react';
import {connect, DispatchProp} from 'react-redux';
import {throttle} from 'lodash';
import {v4} from 'uuid';

import {getLoggedInUserIdFromStore} from '../reducers/loginReducer';
import {BayesNetGraphState, setBayesNetGraphAction} from '../../common/reducers/bayesNetGraphReducer';
import DataStatusEnum from '../../common/DataStatusEnum'
import BayesNetDisplayComponent from './BNCoLaLayout/CoLaNetworkVisComponent';
import {getBayesianModelData} from '../../common/util/buildBayesianModelDataUtils';
import {getBaseBayesNetStyle, getBayesNetNodeShape} from '../util/bayesNetStyle';
import HelpTips from './HelpTips';
import * as constants from '../../common/util/constants';
import {safeBNParameters} from '../../common/util/safeOrder';
import DownloadNetworkComponent from './DownloadNetworkComponent';
import UploadNetworkComponent from '../container/UploadNetworkComponent';
import {getKeyVariableType, VariableType} from '../../common/reducers/keyVariablesReducer';

import '../scss/bayesNetConnectionEditor.scss';

const defaultProps = {
    readOnly: false,
    status: DataStatusEnum.MY_DRAFT
};

type BayesNetConnectionEditorDefaultProps = Readonly<typeof defaultProps>;

interface BayesNetConnectionEditorOwnProps {
    stepId: number;
    problemStepId: number;
    addFlushChanges: (id: string, callback: () => void) => void;
    userId?: number;
    buttonBarDiv: HTMLDivElement | null;
}

interface BayesNetConnectionEditorStoreProps {
    userId: number;
    bayesNetGraph: BayesNetGraphState;
    migrated: boolean;
}

type BayesNetConnectionEditorProps = BayesNetConnectionEditorOwnProps & BayesNetConnectionEditorDefaultProps & BayesNetConnectionEditorStoreProps & DispatchProp;

interface BayesNetConnectionEditorState {
    graph: BayesNetGraphState;
}

class BayesNetConnectionEditor extends Component<BayesNetConnectionEditorProps, BayesNetConnectionEditorState> {

    static defaultProps = defaultProps;

    private readonly flushChangeId: string;

    constructor(props) {
        super(props);
        this.state = {graph: this.makeGraphSafe(props.bayesNetGraph)};
        this.dispatchChange = throttle(this.dispatchChange.bind(this), 5000);
        this.flushDispatchChange = this.flushDispatchChange.bind(this);
        this.isValidConnection = this.isValidConnection.bind(this);
        this.flushChangeId = v4();
    }

    private makeGraphSafe(bayesNetGraph: BayesNetGraphState): BayesNetGraphState {
        return {
            ...safeBNParameters(bayesNetGraph),
            connections: bayesNetGraph.connections.map((connection) => ({
                ...connection,
                label: connection.label || ''
            }))
        };
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({graph: this.makeGraphSafe(props.bayesNetGraph)});
    }

    UNSAFE_componentWillMount() {
        if (this.props.addFlushChanges) {
            this.props.addFlushChanges(this.flushChangeId, this.flushDispatchChange);
        }
    }

    componentWillUnmount() {
        this.flushDispatchChange();
    }

    flushDispatchChange() {
        if (this.props.migrated) {
            this.dispatchChange(this.props.bayesNetGraph, true);
        }
        (this.dispatchChange as any).flush();
    }

    dispatchChange(newState: BayesNetGraphState, migrated = false) {
        this.props.dispatch(setBayesNetGraphAction(this.props.problemStepId, this.props.userId, this.props.status, newState, migrated));
    }

    isValidConnection(fromVariableId: string, toVariableId: string): boolean {
        // Indicate if a connection is allowed between the two variables. In addition to the built-in rules of the
        // BayesNetDisplayComponent to prevent cycles, we need to ensure that no connections are made FROM utility
        // variables or TO decision variables.
        return (
            getKeyVariableType(this.props.bayesNetGraph.nodes.variable[fromVariableId].data) !== VariableType.UTILITY
            && getKeyVariableType(this.props.bayesNetGraph.nodes.variable[toVariableId].data) !== VariableType.DECISION
        );
    }

    render() {
        return (
            <UploadNetworkComponent problemStepId={this.props.problemStepId} buttonBarDiv={this.props.buttonBarDiv} uploadDisabled={this.props.readOnly}>
                <DownloadNetworkComponent problemStepId={this.props.problemStepId} userId={this.props.userId}
                                          status={this.props.status} buttonBarDiv={this.props.buttonBarDiv}
                                          buttonClassName='lightTheme'
                >
                    <div className='bayesNetConnectionEditor'>
                        <div style={{textAlign:"right",padding:"10px 10px 10px 10px"}}>
                            <HelpTips helpId={constants.HELP_STRUCTURE} problemStepId={this.props.problemStepId}/>
                        </div>
                        <BayesNetDisplayComponent
                            graphDescription={this.state.graph}
                            onChange={(newState) => {
                                newState = safeBNParameters(newState);
                                this.setState({graph: newState});
                                this.dispatchChange(newState);
                            }}
                            readOnly={this.props.readOnly}
                            styles={getBaseBayesNetStyle(this.state.graph.nodes)}
                            customNodeDrawingSVGFunction={getBayesNetNodeShape}
                            isValidConnection={this.isValidConnection}
                        />
                    </div>
                </DownloadNetworkComponent>
            </UploadNetworkComponent>
        );
    }

}

function mapStoreToProps(store, myProps): BayesNetConnectionEditorStoreProps {
    const problemStepId = myProps.problemStepId;
    const userId = myProps.userId || getLoggedInUserIdFromStore(store);
    const status = (myProps.status === undefined) ? BayesNetConnectionEditor.defaultProps.status : myProps.status;
    const {updated, migrated} = getBayesianModelData(store, problemStepId, userId, status, constants.SYNC_BN_GRAPH);
    const bayesNetGraph = updated as BayesNetGraphState;
    return {
        userId,
        bayesNetGraph,
        migrated
    };
}

export default connect<BayesNetConnectionEditorStoreProps, DispatchProp, BayesNetConnectionEditorOwnProps>(mapStoreToProps)(BayesNetConnectionEditor);
