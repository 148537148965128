import React from "react";
import PropTypes from "prop-types";

/**
 * Credit: https://codepen.io/techniq/pen/rLXwJJ
 *
 * Copyright 2016 Sean Lynch
 * Copyright 2018 Monash University
 *
 * Permission is hereby granted, free of charge, to any person obtaining a copy of this software and associated documentation files (the "Software"), to deal in the Software without restriction, including without limitation the rights to use, copy, modify, merge, publish, distribute, sublicense, and/or sell copies of the Software, and to permit persons to whom the Software is furnished to do so, subject to the following conditions:
 *
 * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
 *
 * THE SOFTWARE IS PROVIDED "AS IS", WITHOUT WARRANTY OF ANY KIND, EXPRESS OR IMPLIED, INCLUDING BUT NOT LIMITED TO THE WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND NONINFRINGEMENT. IN NO EVENT SHALL THE AUTHORS OR COPYRIGHT HOLDERS BE LIABLE FOR ANY CLAIM, DAMAGES OR OTHER LIABILITY, WHETHER IN AN ACTION OF CONTRACT, TORT OR OTHERWISE, ARISING FROM, OUT OF OR IN CONNECTION WITH THE SOFTWARE OR THE USE OR OTHER DEALINGS IN THE SOFTWARE.
 */
/**
 * Modified by Saurabh Joshi to support truncation, by specifying height of box.
 *
 * TODO: This code has only been designed and tested specifically for the WebCoLa
 * React component. More work needs to be done to generalise the SVG Text component to
 * all possible use cases.
 */
class SVGText extends React.Component {
    static propTypes = {
        /** String to be displayed on the screen */
        children: PropTypes.string.isRequired,
        /** Horizontally align text to the left, center, or to the right */
        horizontalAlign: PropTypes.oneOf(["left", "center", "right"]),
        /** Vertically align text to the top, middle, or the bottom */
        verticalAlign: PropTypes.oneOf(["top", "middle", "bottom"]),
        /** How much padding to be placed on both left and right of the text */
        horizontalPadding: PropTypes.number
    };

    static defaultProps = {
        lineHeight: 1,
        capHeight: 0.71,
        horizontalAlign: "left",
        verticalAlign: "top",
        horizontalPadding: 0,
        x: 0,
        y: 0
    };

    constructor(props) {
        super(props);

        this.state = {
            lines: []
        };
    }

    UNSAFE_componentWillMount() {
        const {
            wordsWithComputedDimensions,
            spaceWidth,
            ellipsisWidth,
            hyphenWidth
        } = SVGText.calculateWordDimensions(this.props.children, this.props.style, this.props.width, this.props.nodeRectHorizontalPadding);
        this.wordsWithComputedDimensions = wordsWithComputedDimensions;
        this.spaceWidth = spaceWidth;
        this.ellipsisWidth = ellipsisWidth;
        this.hyphenWidth = hyphenWidth;

        const lines = SVGText.calculateLines(
            this.wordsWithComputedDimensions,
            this.spaceWidth,
            this.props.width,
            this.ellipsisWidth,
            this.hyphenWidth,
            this.props.horizontalPadding,
            this.props.height
        ).lines;
        this.setState({ lines: lines });
    }

    render() {
        // TODO: determine lineHeight and dy dynamically (using passed in props)
        const { lineHeight, capHeight, ...props } = this.props;
        const dy = capHeight;
        const { x, y, width, height } = props;

        const hozAlignToTextAnchorStyleDict = {
            left: "start",
            center: "middle",
            right: "end"
        };

        const vertAlignToDominantBaselineStyleDict = {
            top: "text-before-edge",
            middle: "central",
            bottom: "text-after-edge"
        };

        const style = {
            textAnchor: hozAlignToTextAnchorStyleDict[props.horizontalAlign],
            dominantBaseline:
                vertAlignToDominantBaselineStyleDict[props.verticalAlign]
        };

        const xOffset = {
            left: 0,
            center: width / 2,
            right: width
        };

        const yOffset = {
            top: 0,
            middle: height / 2,
            bottom: height
        };

        const dyAlign = {
            top: 0,
            middle: -(this.state.lines.length - 1) * lineHeight / 2,
            bottom: -(this.state.lines.length - 1) * lineHeight
        };

        return (
            <text
                style={{ ...style, ...this.props.style }}
                y={y + yOffset[this.props.verticalAlign]}
                transform={`translate(${xOffset[this.props.horizontalAlign]})`}
                dy={`${dy}em`}
            >
                {this.state.lines.map((line, index) => (
                    <tspan
                        key={index}
                        x={x}
                        y={y + yOffset[this.props.verticalAlign]}
                        dy={`${index * lineHeight +
                            dyAlign[this.props.verticalAlign]}em`}
                        textAnchor="middle"
                        dominantBaseline={style.dominantBaseline}
                    >
                        {line}
                    </tspan>
                ))}
            </text>
        );
    }

    componentDidUpdate(nextProps) {
        if (this.props.children !== nextProps.children) {
            const {
                wordsWithComputedDimensions,
                spaceWidth,
                ellipsisWidth,
                hyphenWidth
            } = SVGText.calculateWordDimensions(this.props.children, this.props.style, this.props.width, this.props.nodeRectHorizontalPadding);
            this.wordsWithComputedDimensions = wordsWithComputedDimensions;
            this.spaceWidth = spaceWidth;
            this.ellipsisWidth = ellipsisWidth;
            this.hyphenWidth = hyphenWidth;
        }

        const lines = SVGText.calculateLines(
            this.wordsWithComputedDimensions,
            this.spaceWidth,
            this.props.width,
            this.ellipsisWidth,
            this.hyphenWidth,
            this.props.horizontalPadding,
            this.props.height
        ).lines;
        const newLineAdded = this.state.lines.length !== lines.length;
        /*const wordMoved = this.state.lines.some(
            (line, index) => line.length !== lines[index].length
        );*/ //This breaks if this.state.lines is longer then lines.  In this case lines[index] will return undefined.
        const wordChanged = this.state.lines.some(
            (line, index) => line !== lines[index]
        );
        // Only update if number of lines or a word in a line has changed.  Also deals with the case where the current text is the empty string.
        if (newLineAdded || wordChanged) {
            this.setState({ lines });
        }
    }

    static calculateWordDimensions(textString, style, boxWidth, boxHorizontalPadding) {
        // Calculate length of each word to be used to determine number of words per line
        const words = textString.split(/\s+/);
        var svg = document.createElementNS("http://www.w3.org/2000/svg", "svg");
        var text = document.createElementNS(
            "http://www.w3.org/2000/svg",
            "text"
        );
        Object.assign(text.style, style);
        svg.appendChild(text);
        document.body.appendChild(svg);

        text.textContent = "\u00A0"; // Unicode space
        const spaceWidth = text.getComputedTextLength();

        text.textContent = "\u2026"; // Ellipsis
        const ellipsisWidth = text.getComputedTextLength();

        text.textContent = "-"; // Hyphen
        const hyphenWidth = text.getComputedTextLength();

        var wordsWithComputedDimensions = [];
        for (let word of words) {


            while (word.length > 0)
            {
                text.textContent = word;
                let wordInfo = {
                    word,
                    width: text.getComputedTextLength(),
                    height: text.getBBox().height
                };

                // If this single word is too long to fit into the node.  We
                // split it up into multiple words, each ending in a hyphen,
                // that will just fit within the node.  That is what the
                // following loop and if-statement are doing.

                // Used if the individual word is too wide to fit within the node.
                let wordOverflow = "";

                // While the word is too long to fit within node, take
                // characters from the end and put them on the beginning of
                // a wordOverflow string.
                while (word.length > 1 && wordInfo.width + hyphenWidth + 2 * boxHorizontalPadding >=
                boxWidth)
                {
                    wordOverflow = word[word.length - 1] + wordOverflow;
                    word = word.substring(0, word.length - 1);
                    text.textContent = word;
                    wordInfo = {
                        word,
                        width: text.getComputedTextLength(),
                        height: text.getBBox().height
                    };
                }

                // Escape if the rectangle is too small even for 1 character.
                // Prevents infinite loop if passed a small rectangle.
                if (word.length === 1 && wordInfo.width + hyphenWidth + 2 * boxHorizontalPadding >=
                boxWidth) {
                    break;
                }

                // If there is any word overflow, then add a hyphen to the
                // end of the word
                if (wordOverflow.length > 0)
                {
                    word += "-";
                    text.textContent = word;
                    wordInfo = {
                        word,
                        width: text.getComputedTextLength(),
                        height: text.getBBox().height
                    };
                }

                wordsWithComputedDimensions.push(wordInfo);

                // Make the wordOverflow be the next word.
                word = wordOverflow;
                wordOverflow = "";
            }
        }

        document.body.removeChild(svg);

        return { wordsWithComputedDimensions, spaceWidth, ellipsisWidth, hyphenWidth };
    }

    static calculateLines(
        wordsWithComputedDimensions,
        spaceWidth,
        lineWidth,
        ellipsisWidth,
        hyphenWidth,
        horizontalPadding,
        maxHeight
    ) {
        const wordsByLines = wordsWithComputedDimensions.reduce(
            (result, { word, width, height }) => {
                const lastLine = result[result.length - 1] || {
                    words: [],
                    width: 0,
                    height: 0,
                    priorHeight: 0
                };

                if (lastLine.truncated) {
                    // Do not add any more words
                    return result;
                }

                if (lastLine.words.length === 0) {
                    // First word on line
                    const newLine = {
                        words: [{ word, width }],
                        width,
                        height,
                        priorHeight: lastLine.priorHeight + lastLine.height
                    };

                    result.push(newLine);
                } else if (
                    lastLine.width +
                        width +
                        lastLine.words.length * spaceWidth +
                        2 * horizontalPadding <
                    lineWidth
                ) {
                    // Word can be added to an existing line
                    lastLine.words.push({ word, width });
                    lastLine.width += width;
                    lastLine.height = Math.max(lastLine.height, height);
                } else {
                    // Word too long to fit on existing line
                    if (
                        lastLine.priorHeight + lastLine.height + height >=
                        maxHeight
                    ) {
                        // Multiline would exceed total height, so truncate the text instead
                        // Iteratively remove words one at a time, until we can fit the ellipsis.
                        while (
                            lastLine.words.length > 1 &&
                            lastLine.width +
                                lastLine.words.length * spaceWidth +
                                ellipsisWidth +
                                2 * horizontalPadding >=
                                lineWidth
                        ) {
                            lastLine.width -=
                                lastLine.words[lastLine.words.length - 1].width;
                            lastLine.words.pop();
                        }

                        if (lastLine.words.length === 1)
                        {
                            // Remove hyphen from end of word, before ellipsis.
                            lastLine.width -= hyphenWidth;
                            let wordInfo = lastLine.words[lastLine.words.length - 1];
                            wordInfo.word = wordInfo.word.substring(0, wordInfo.word.length - 2);
                            wordInfo.length -= hyphenWidth;
                        }

                        lastLine.truncated = true;
                    } else {
                        const newLine = {
                            words: [{ word, width }],
                            width,
                            height,
                            priorHeight: lastLine.priorHeight + lastLine.height
                        };
                        result.push(newLine);
                    }
                }

                return result;
            },
            []
        );

        // Balance wrapped lines
        if (wordsByLines.length > 1) {
            // So long as there is more than 1 line, then shuffle words from
            // longer lines to shorter line to get an overall balance.
            let change = false;
            do {
                change = false;

                // Find the longest line and its length.
                let longestLineIndex = -1;
                let longestLineLength = 0;
                for (let [lineIndex, line] of wordsByLines.entries()) {
                    if (line.width > longestLineLength) {
                        longestLineIndex = Number(lineIndex);
                        longestLineLength = line.length;
                    }
                }

                if (longestLineIndex === -1)
                {
                    continue;
                }

                let longestLine = wordsByLines[longestLineIndex];

                if (longestLineIndex > 0) {
                    // If not the first line, check the previous line.
                    let prevLine = wordsByLines[longestLineIndex - 1];
                    let firstWord = longestLine.words[0];

                    // If the overall width can be made shorter by moving a
                    // word from the longest line to the prev line, then do it.
                    if (prevLine.width + firstWord.width < longestLine.width) {
                        prevLine.words.push(longestLine.words.shift());
                        prevLine.width += firstWord.width;
                        longestLine.width -= firstWord.width;
                        change = true;
                    }
                }

                if (longestLineIndex + 1 < wordsByLines.length) {
                    // If not the last line, check the next line.
                    let nextLine = wordsByLines[longestLineIndex + 1];
                    let lastWord = longestLine.words[longestLine.words.length - 1];

                    // If the overall width can be made shorter by moving a
                    // word from the longest line to the next line, then do it.
                    if (nextLine.width + lastWord.width < longestLine.width) {
                        nextLine.words.unshift(longestLine.words.pop());
                        nextLine.width += lastWord.width;
                        longestLine.width -= lastWord.width;
                        change = true;
                    }
                }


            } while (change)

        }

        // Return the lines as an array of strings.
        let lines = wordsByLines.map(
                line =>
                    line.words.map(wordObject => wordObject.word).join(" ") +
                    (line.truncated ? "\u2026" : "")
            );

        // Return the overall width of the longest line.
        let overallWidth = 0;
        for (let line of wordsByLines) {
            let spacesN = line.words.length >= 1 ? line.words.length - 1: 0;
            let width = line.width + (spacesN * spaceWidth);
            if (line.truncated) {
                width += ellipsisWidth;
            }
            overallWidth = Math.max(overallWidth, width);
        }

        return {
            lines,
            overallWidth
        };
    }
}

export default SVGText;
