import * as constants from '../../common/util/constants';
import {doesValueMatch} from '../../common/util/matchLogic';
import ReferenceData from '../../common/util/referenceData';

export function isStepDisabled(tense) {
    return tense === constants.STEP_TENSE.FUTURE;
}

export function isFutureEditableStep(stepId, currentStepId) {
    const allStepDescriptions = ReferenceData.getInstance().getAllStepDescriptions();
    return !!(allStepDescriptions[stepId].futureEnabled && doesValueMatch(currentStepId, allStepDescriptions[stepId].futureEnabled));
}
