import {v4} from 'uuid';
import moment from 'moment';
import {Reducer} from 'redux';

import {ChatPojo} from '../../server/orm/dao/chatDAO';
import {objectMapReducer, ObjectMapState, singleFieldReducer} from '../util/genericReducers';
import ensureFieldPath from '../ensureFieldPath';
import {RESET_PROBLEM_STEP} from '../util/constants';
import { StoreWithSharedState } from './sharedStateReducer';

export const INIT_CHAT = 'init_chat';
export const INIT_CHAT_AND_SEND = 'init_chat_and_send';
export const SEND_CHAT_MESSAGE = 'send_chat_message';

export interface InitChatAction {
    chatId: string;
    type: string;
    problemStepId: number;
    participants: number[];
}

export interface InitChatAndSendAction {
    chatId: string;
    type: string;
    problemStepId: number;
    participants: number[];
    messageId: string;
    senderId: number;
    recipientId: number;
    text: string;
    createdAt: string;
}

export interface SendChatMessageAction {
    messageId: string;
    type: string;
    senderId: number;
    recipientId: number;
    participants: number[];
    problemStepId: number;
    text: string;
    chatId: string;
    multicastId?: string;
    createdAt: string;
    sendCopyViaEmail: boolean;
}

export interface ChatForUserState {
    id?: string,
    latestMessageId?: string,
    problemStepId?: number,
    participants?: number[],
    messageCount: number,
    latestMessageTimestamp?: Date
}

export function getDBSyncActionTypes() {
    return [ INIT_CHAT, INIT_CHAT_AND_SEND, SEND_CHAT_MESSAGE, RESET_PROBLEM_STEP ];
}

export function getActivityActionTypes() {
    return [ INIT_CHAT_AND_SEND, SEND_CHAT_MESSAGE ];
}

const chatForUserReducer: Reducer<ChatForUserState> = (state = {messageCount: 0}, action) => {
    switch(action.type) {
        case INIT_CHAT:
            let initChatAction = action as InitChatAction;
            return {
                ...state,
                participants: initChatAction.participants,
                id: initChatAction.chatId,
                problemStepId: initChatAction.problemStepId
            };
        case INIT_CHAT_AND_SEND:
            let initChatAndSendAction = action as InitChatAndSendAction;
            return {
                ...state,
                participants: initChatAndSendAction.participants,
                id: initChatAndSendAction.chatId,
                problemStepId: initChatAndSendAction.problemStepId,
                latestMessageId: initChatAndSendAction.messageId,
                latestMessageTimestamp: new Date(initChatAndSendAction.createdAt)
            };
        case SEND_CHAT_MESSAGE:
            let sendChatMessage = action as SendChatMessageAction;
            return {
                ...state,
                latestMessageId: sendChatMessage.messageId,
                latestMessageTimestamp: new Date(sendChatMessage.createdAt),
                messageCount: state.messageCount + 1
            };
        default:
            return state;
    }
};

export interface ChatForUsersState {
    chats: ObjectMapState<ChatForUserState>;
}

export interface ChatForProblemState {
    users: ObjectMapState<ChatForUsersState>;
}

const chatForUsersReducer = singleFieldReducer('chats', {chats: {}}, objectMapReducer('chatId', chatForUserReducer));

const chatForProblemReducer = singleFieldReducer('users', {users: {}}, objectMapReducer('participants', chatForUsersReducer));

const chatsReducer = objectMapReducer('problemStepId', chatForProblemReducer);

export type ChatsReducerType = ObjectMapState<ChatForProblemState>;

const resettableChatsReducer: Reducer<ChatsReducerType> = (state = {}, action) => {
    switch (action.type) {
        case RESET_PROBLEM_STEP:
            let result = {...state};
            delete(result[action.problemStepId]);
            return result;
        default:
            return chatsReducer(state, action);
    }
};

export default resettableChatsReducer;

// action creators
export function createInitChatAction(problemStepId: number, participants: number[]): InitChatAction {
    return { type: INIT_CHAT, problemStepId: problemStepId, participants: participants, chatId: v4() };
}

export function createInitChatAndSendAction(senderId: number, recipientId: number, text: string, problemStepId: number): InitChatAndSendAction {
    return { type: INIT_CHAT_AND_SEND, problemStepId: problemStepId, participants: [senderId, recipientId], chatId: v4(), messageId: v4(), senderId: senderId, recipientId: recipientId, text: text, createdAt: moment().format() };
}

export function createSendChatMessageAction(senderId: number, recipientId: number, text: string, chatId: string, problemStepId: number, multicastId: string | undefined, sendCopyViaEmail: boolean): SendChatMessageAction {
    return { type: SEND_CHAT_MESSAGE, messageId: v4(), senderId: senderId, recipientId: recipientId, participants: [senderId, recipientId], problemStepId, text, chatId, multicastId, createdAt: moment().format(), sendCopyViaEmail: sendCopyViaEmail };
}

export function setChatInStore(store, chatPojo: ChatPojo): void {
    let participants: number[] = JSON.parse(chatPojo.participants);  

    const latestMessage = chatPojo.messages && chatPojo.latestMessageId && chatPojo.messages.find((message) => message.id === chatPojo.latestMessageId);

    for (let userId of participants) {
        ensureFieldPath(store, 'sharedState', 'chats', chatPojo.problemStepId, 'users', userId, 'chats');

        store.sharedState.chats[chatPojo.problemStepId].users[userId].chats[chatPojo.id] = {
            id: chatPojo.id,
            latestMessageId: chatPojo.latestMessageId,
            problemStepId: chatPojo.problemStepId,
            participants: participants,
            messageCount: chatPojo.messages ? chatPojo.messages.length : 0,
            latestMessageTimestamp: latestMessage ? latestMessage.createdAt : undefined
        };
    }
}

/**
 * Getters
 */

export function getChatsForProblemAndUserFromStore(store: StoreWithSharedState, problemStepId: string, userId: string): ObjectMapState<ChatForUserState> {
    return store.sharedState.chats[problemStepId] && store.sharedState.chats[problemStepId].users &&
        store.sharedState.chats[problemStepId].users[userId] && store.sharedState.chats[problemStepId].users[userId].chats;
}

