import React, {Component} from 'react';
import {BrowserRouter as Router, Route as PublicRoute, Switch, Redirect} from 'react-router-dom';
import {applyMiddleware, createStore, compose} from 'redux'
import reduxThunk from 'redux-thunk';
import {Provider} from 'react-redux';
import moment from 'moment';
import {GoogleOAuthProvider} from '@react-oauth/google';

import './polyfills';
import PrivateRoute from './container/PrivateRoute';
import mainReducer from './reducers/clientMainReducer';
import pushDispatchesMiddleware from './util/pushDispatchesMiddleware';
import PageHeader from './presentation/PageHeader';
import HomePage from './presentation/HomePage';
import ServiceUnavailablePage from './presentation/ServiceUnavailablePage';
import AdminTemplate from './presentation/AdminPanel';
import ProblemContextRouter from './container/ProblemContextRouter';
import Foyer from './presentation/Foyer';
import ProblemValidationComponent from './container/ProblemValidationComponent';
import * as constants from '../common/util/constants';
import * as navigation from '../common/clientServer/navigation';
import BardSnackbarContainer from './container/BardSnackbarContainer';
import ProblemDescriptionComponent from './presentation/ProblemDescriptionComponent';
import DefaultProblemLabelComponent from './container/DefaultProblemLabelComponent';
import BardFeatureFlagsProvider from './container/BardFeatureFlagsProvider';
import VisibleWhenLoggedIn from './container/VisibleWhenLoggedIn';
import EventSourceReduxDispatcher from './container/EventSourceReduxDispatcher';
import TourGuideComponent from './container/TourGuideComponent';
import PageViewLoggingComponent from './container/PageViewLoggingComponent';
import BardGlobalErrorContainer from './container/BardGlobalErrorContainer';
import ProblemSaveLoadComponent from './presentation/problemSaveLoadComponent';

// Set the default format for moment to include milliseconds
moment.defaultFormat = constants.TIMESTAMP_FORMAT;

const composeEnhancers = (window as any).__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
const store = createStore(mainReducer, composeEnhancers(
    applyMiddleware(reduxThunk, pushDispatchesMiddleware)
));

export default class App extends Component {

    render() {
        return (
            <Provider store={store}>
                <BardFeatureFlagsProvider>
                    <BardGlobalErrorContainer>
                        <GoogleOAuthProvider clientId={constants.GOOGLE_CLIENT_ID}>
                            <Router>
                                <div className="topLevelDiv">
                                    <TourGuideComponent/>
                                    <PageViewLoggingComponent/>
                                    <DefaultProblemLabelComponent/>
                                    <VisibleWhenLoggedIn singleChild={true}>
                                        <EventSourceReduxDispatcher/>
                                    </VisibleWhenLoggedIn>
                                    <PublicRoute exact={true} path={navigation.ROOT_PATH} component={PageHeader}/>
                                    {
                                        [navigation.PROBLEM_PATH, navigation.SELECT_PATH, navigation.ADMIN_PATH, navigation.VERSION_PATH, navigation.PROBLEM_SAVE_LOAD_PATH]
                                            .map((path) => <PublicRoute key={path} path={path} component={PageHeader}/>)
                                    }
                                    <ProblemContextRouter/>
                                    <Switch>
                                        <PublicRoute exact={true} path={navigation.ROOT_PATH} component={HomePage}/>
                                        <PublicRoute exact={true} path={navigation.SERVICE_UNAVAIL_PATH} component={ServiceUnavailablePage}/>
                                        <PublicRoute path={navigation.VERSION_PATH} render={() => null}/>
                                        <PrivateRoute path={navigation.ADMIN_PATH} component={AdminTemplate}/>
                                        <PrivateRoute path={navigation.SELECT_PATH} component={Foyer}/>
                                        <PrivateRoute path={navigation.PROBLEM_PATH} component={ProblemValidationComponent}/>
                                        <PrivateRoute path={navigation.PROBLEM_DESCRIPTION_PATH} component={ProblemDescriptionComponent}/>
                                        <PrivateRoute path={navigation.PROBLEM_SAVE_LOAD_PATH} component={ProblemSaveLoadComponent}/>
                                        <Redirect to={navigation.ROOT_PATH}/>
                                    </Switch>
                                    <BardSnackbarContainer/>
                                </div>
                            </Router>
                        </GoogleOAuthProvider>
                    </BardGlobalErrorContainer>
                </BardFeatureFlagsProvider>
            </Provider>
        );
    }
}
