import {v4} from 'uuid';

import {getOfflineFromStore, logoutAction, offlineAction, onlineAction} from '../reducers/loginReducer';
import {getListenerIdFromStore} from '../reducers/listenerIdReducer';
import {HEADER_LISTENER_ID, LOGOUT_REASON_SESSION_INVALID, LOGOUT_REASON_SERVICE_UNAVAILABLE} from '../../common/util/constants';

let promiseChain = Promise.resolve();

const pushDispatchesMiddleware = store => next => action => {
    const listenerId = getListenerIdFromStore(store.getState());
    if (typeof(action) !== 'object') {
        return next(action);
    }
    const actionWithGUID = {actionId: v4(), ...action};
    let nextState = next(actionWithGUID);
    if (!actionWithGUID.fromServer && !actionWithGUID.isClientOnly) {
        // Only forward events to the server which originated locally, as determined by the fromServer object, and are
        // not client-only.
        let originalPromiseChain = promiseChain = promiseChain.then(function () {
            return fetch('/api/action', {
                method: 'put',
                credentials: 'include',
                headers: {'content-type': 'application/json', [HEADER_LISTENER_ID]: listenerId},
                body: JSON.stringify(actionWithGUID)
            });
        }).then(function (response) {
            if (response.ok) {
                if (getOfflineFromStore(store.getState())) {
                    nextState = next(onlineAction());
                }
            } else {
                if (response.status >= 400 && response.status < 500) {
                    nextState = next(logoutAction(LOGOUT_REASON_SESSION_INVALID));
                } else if (response.state === 503) {
                    nextState = next(logoutAction(LOGOUT_REASON_SERVICE_UNAVAILABLE));
                }else {
                    nextState = next(offlineAction());
                }
            }
        }).catch(function () {
            nextState = next(offlineAction());
        }).then(function () {
            // Clean up promise chain if it hasn't grown in the mean time
            if (originalPromiseChain === promiseChain) {
                promiseChain = Promise.resolve();
            }
            originalPromiseChain = null;
        });
    }
    return nextState;
};

export default pushDispatchesMiddleware;
