import {combineReducers, Reducer} from 'redux';

import allProblemsReducer, {AllProblemsReducerType} from './allProblemsReducer';
import allTeamsReducer, {AllTeamsReducerType} from './allTeamsReducer';
import chatsReducer, {ChatsReducerType} from './chatsReducer';
import candidateSolutionTemplateReducer, {CandidateSolutionTemplateReducerType} from './candidateSolutionTemplateReducer';
import userFeedbackReducer, {UserFeedbackReducerType} from './userFeedbackReducer';
import serverStateReducer, {ServerState} from './serverStateReducer';
import featureFlagsReducer, {FeatureFlagsReducerType} from './featureFlagsReducer';
import loginPathReducer, {LoginPathReducerType} from './loginPathReducer';
import bardTrainingUrlReducer, {BardTrainingUrlReducerType} from './bardTrainingUrlReducer';
import projectsReducer, {ProjectsReducerType} from './projectsReducer';
import {BnWrapperState, BnWrapperStateReducer, initialBnWrapperState} from './bnWrapperStateReducer';

export const STORE_INIT = 'STORE_INIT';

export interface SharedStateReducerType {
    problem: AllProblemsReducerType,
    teams: AllTeamsReducerType,
    chats: ChatsReducerType,
    candidateSolutionTemplate: CandidateSolutionTemplateReducerType,
    feedback: UserFeedbackReducerType,
    serverState: ServerState,
    featureFlags: FeatureFlagsReducerType,
    loginPath: LoginPathReducerType,
    bardTrainingUrl: BardTrainingUrlReducerType,
    projects: ProjectsReducerType,
    bnWrapperState: BnWrapperState
}

const combinedSharedStateReducer = combineReducers<SharedStateReducerType>({
    problem: allProblemsReducer,
    teams: allTeamsReducer,
    chats: chatsReducer,
    candidateSolutionTemplate: candidateSolutionTemplateReducer,
    feedback: userFeedbackReducer,
    serverState: serverStateReducer,
    featureFlags: featureFlagsReducer,
    loginPath: loginPathReducer,
    bardTrainingUrl: bardTrainingUrlReducer,
    projects: projectsReducer,
    bnWrapperState: BnWrapperStateReducer
});

const sharedStateReducer: Reducer<SharedStateReducerType> = (state, action) => {
    switch (action.type) {
        case STORE_INIT:
            return {...action.store};
        default:
            return combinedSharedStateReducer(state, action);
    }
};

export default sharedStateReducer;

export function storeInitAction(store: SharedStateReducerType, fromServer = {}) {
    return {type: STORE_INIT, store, fromServer};
}

export interface StoreWithSharedState {
    sharedState: SharedStateReducerType;
}

export function initialEmptyStoreWithSharedState(): StoreWithSharedState {
    return {
        sharedState: {
            problem: {},
            teams: {},
            chats: {},
            candidateSolutionTemplate: {},
            feedback: {},
            serverState: {
                serviceAvailable: true,
                shutdownScheduled: false,
                scheduledShutdownTimeStamp: '',
                timestamp: ''
            },
            featureFlags: {},
            loginPath: '',
            bardTrainingUrl: '',
            projects: {},
            bnWrapperState: initialBnWrapperState
    }};
}