import {BardUser} from '../clientServer/bardUser';
import {
    addFieldData,
    BardFilterObject,
    BardFilterSuggestions,
    fieldMatchesFilter,
    parseFilterString,
    suggestFilterFields
} from './commonFilter';

type BardUserFilterKeys = Exclude<keyof BardUser, 'id' | 'loginPassword' | 'lastLogin' | 'lastLogout' | 'Projects'>;

export type BardUserFilter = {
    [field in BardUserFilterKeys]?: string | boolean | number | Array<string>;
};

export type BardUserFilterObject = BardFilterObject<BardUserFilter>;

export type BardUserFilterSuggestions = BardFilterSuggestions<BardUserFilter>;

export const userFilterFieldTypes: {[key in keyof Required<BardUserFilter>]: string} = {
    admin: 'boolean',
    loginName: 'string',
    status: 'number',
    tags: 'stringArray',
    title: 'string',
    givenName: 'string',
    familyName: 'string',
    emailAddress: 'string',
    postalAddress: 'string',
    phoneMobile: 'string',
    phoneLandline: 'string'
};

export const BARD_USER_FILTER_TYPE = 'bardUserFilter';

addFieldData(BARD_USER_FILTER_TYPE, userFilterFieldTypes, 'loginName');

export function parseBardUserFilterString(filter: string): BardUserFilterObject {
    // Default to loginName or emailAddress = value.  TODO Can't currently do OR, just do loginName
    return parseFilterString<BardUserFilter>(filter, BARD_USER_FILTER_TYPE);
}

// Test if the last token in filter string could auto-complete to values in BardUserFilterKeys.
export function suggestUserFilterFields(filterString: string): BardUserFilterSuggestions {
    const filter = parseFilterString(filterString, BARD_USER_FILTER_TYPE);
    return suggestFilterFields(filter);
}

export function userMatchesFilter(user: BardUser, filter?: BardUserFilterObject) {
    if (!filter || !filter.fields) {
        return true;
    }
    for (let field of filter.fields) {
        let filterValue = filter.where[field];
        if (userFilterFieldTypes[field] === 'stringArray') {
            filterValue = !filterValue ? [] : Array.isArray(filterValue) ? filterValue : [filterValue];
            const matchAllTags = false;
            if (filterValue.length > 0 && !filterValue.reduce((match, tag) => {
                const userTagsMatch = user.tags && user.tags.reduce(
                    (any, userTag) => (any || tag === userTag.name), false);
                return matchAllTags ? match && userTagsMatch : match || userTagsMatch;
            }, matchAllTags)) {
                return false;
            }
        } else if ((Array.isArray(filterValue) && filterValue.reduce((noneMatch, filterValue) => (
            noneMatch && !fieldMatchesFilter(user[field], filterValue, BARD_USER_FILTER_TYPE, field)
        ), true)) || (!Array.isArray(filterValue) && !fieldMatchesFilter(user[field], filterValue, BARD_USER_FILTER_TYPE, field))) {
            return false;
        }
    }
    return true;
}