import React, {Component} from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {FontIcon} from 'react-md';
import onScreenTipsData from '../help/onScreenTipsData';
import * as constants from '../../common/util/constants';
import {isProblemInMode} from '../../common/util/problemMode';


class TipsComponent extends Component {

    static propTypes = {
        status: PropTypes.number,
        step: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        side: PropTypes.string,
        problemStep: PropTypes.object,
        userProblem: PropTypes.object
    };

    renderOnscreenTips(key, tips){
        
        const obj = tips.tipObj;
        const isMatch = (this.props.step === constants.HELP_STEP_PROBLEM_DESC) ? true : this.checkIfMatchesRoleStatus(obj);
        const isTip = isMatch ? tips.tipName === 'tip' : false;
        const isCheck = tips.tipName.indexOf('REMEMBER') >= 0 ? true : (tips.tipName.indexOf('AVOID') >= 0 ? false : null);

        return (isMatch ?
                    <div key={key} className="tipsGroupStyle">
                        <div className = {classNames('pageTip problemDescriptionPageTip01', {
                        pageTipLess: (!isTip)
                        })}>
                        { !isTip ? <strong>{tips.tipName} </strong>: ''}
                        </div>
                        
                        {
                            Object.keys(obj).map((key, index) => (
                                this.renderTipText(key, obj[index], isCheck)
                            ))
                        }
                    </div>
                :
                null
        );
    }

    checkIfMatchesRoleStatus(obj){
        return obj.reduce((match, element) => {
            const doesStatusMatch = this.findMatch(element.statuses, this.props.status);
            const doesRoleMatch = this.findMatch(element.bardRoles, this.props.userProblem.role);
            const doesModeMatch = this.checkModeMatch(element.bardModes);

            if(doesStatusMatch && doesRoleMatch && doesModeMatch){
                match =  true;
            }
            return match;
            
        }, false);
    }

    buildTipComponent(key, finalData, ischeck){
        const tipText = finalData.text;
        const tipsLinks = finalData.link;
        return (
            <div key={key} className="tipsTextIconGroup">
                {
                    Object.keys(tipText).map((key, index) => {

                        return <div key={key}
                                    className={ischeck !== null ? 'pageTip pageTipLess problemDescriptionPageTip01 tipsCheckIcon' : 'pageTip pageTipLess problemDescriptionPageTip01'}>
                            {ischeck !== null ?
                                (ischeck ? '✔ ' : '✖ ')
                                : ''
                            }
                            {tipText[index]}
                            {
                                !finalData.icons ? null : finalData.icons.map((iconData, index) => (
                                    <FontIcon key={key + '_icon_' + index} className={classNames('md-18 inlineIcon', iconData.cssClass)}>
                                        {iconData.icon}
                                    </FontIcon>
                                ))
                            }
                        </div>
                    })
                }
                {
                    !tipsLinks ? null : tipsLinks.map((link, index) => (
                        <div className="tipsIconStyle" key={key + '_link_' + index}>
                            {
                                (!link.linkText ?
                                        link.linkName
                                        :
                                        <a key={key} target='_blank' href={link.linkText} rel="noopener noreferrer">
                                            {link.linkName}
                                        </a>
                                )
                            }
                        </div>
                    ))
                }
            </div>
        ); 
    }

    checkModeMatch(bardModes){
        if(bardModes.length !== 0){
            return isProblemInMode(bardModes, this.props.problemStep);
        }else{
            return true;
        }
    }

    renderTipText(key, textArray, isCheck){
        let match = false;

        if(this.props.step === constants.HELP_STEP_PROBLEM_DESC){
            match = true;
        }else{
            let doesStatusMatch = this.findMatch(textArray.statuses, this.props.status);
            let doesRoleMatch = this.findMatch(textArray.bardRoles, this.props.userProblem.role);
            let doesModeMatch = (this.props.step === constants.HELP_STEP_PROBLEM_DESC) ? true : this.checkModeMatch(textArray.bardModes);

            if(doesStatusMatch && doesRoleMatch && doesModeMatch){
                match = true;
            }
        }
        if (match) {        
            return this.buildTipComponent(key, textArray, isCheck);
        
        }else{
            return null;
        }
    }

    findMatch(fromArray, param){

        let match = false;
        let newArray = [];

        if(fromArray.length === 0 || (param === null || param < 0)){
            return true;
        }else{
            Object.keys(fromArray).map((index) => (
                newArray[index] = fromArray[index]
            ));
            newArray.forEach((element) => {
                if(element === param){
                    match = true;
                }
            })
        }
        return match;
    }


    render(){
        const onScreenTips = onScreenTipsData.getInstance().getOnscreenTips(this.props.step, this.props.side);
        let tips = onScreenTips && onScreenTips.tipContent;

        return tips ? 
                <div className="tipsComponent">
                    {
                        Object.keys(tips).map((key, index) => (
                                this.renderOnscreenTips(key, tips[index])
                        ))
                    }
                </div>
                
                : null;
    }

}

export default TipsComponent;
