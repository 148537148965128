const UPDATE_USER_LIST = 'updateUserList';
const ADD_TO_USER_LIST = 'addToUserList';
const CLEAR_USER_LIST = 'clearUserList';

function allUsersReducer(state = {}, action) {
    switch (action.type) {
        case UPDATE_USER_LIST:
            return action.allUsers.reduce((accum, user) => {
                accum[user.id] = user;
                return accum;
            }, {...state});
        case ADD_TO_USER_LIST:
            return { ...state, [action.user.id]: action.user };
        case CLEAR_USER_LIST:
            return {};
        default:
            return state;
    }
}

export default allUsersReducer;

export function updateUserListAction(allUsers) {
    return { type: UPDATE_USER_LIST, allUsers, isClientOnly: true };
}

export function addToUserListAction(user) {
    return { type: ADD_TO_USER_LIST, user };
}

export function clearAllUsersAction() {
    return { type: CLEAR_USER_LIST, isClientOnly: true };
}

export function getAllUsersFromStore(store) {
    return store.clientState.allUsers;
}