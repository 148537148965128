import React, {PureComponent} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {TextField, Button, DialogContainer} from 'react-md';
import moment from 'moment';
import Select from 'react-select';

import Timer from './Timer';
import IconLabelButton from './IconLabelButton';
import {extendCurrentRoundAction} from '../../common/reducers/allTeamsReducer';
import {getProblemDBFromStore} from '../../common/reducers/allProblemsReducer';
import {getRemainingTime, getStepRoundEndTime} from '../util/timeUtils';
import LaunchComponent from './LaunchComponent';
import commonSelectStyles from '../util/commonSelectStyles';
import {getProblemStepFromStore} from "../../common/reducers/allTeamsReducerGetters";

import '../scss/currentStepTimer.scss';

const EXTEND_BTN_TXT = 'EXTEND';
const MIN_DURATION_EXT = 1;
class CurrentStepTimer extends PureComponent {
    constructor(props) {
        super(props);
        this.extendCurrentRoundDuration = this.extendCurrentRoundDuration.bind(this);
        this.buildDialogTitle = this.buildDialogTitle.bind(this);
        this.showExtendDurationDialog = this.showExtendDurationDialog.bind(this);
        this.onCancel = this.onCancel.bind(this);
        this.state = {
            showExtendDurationDialog: false,
            dialogTitle: this.buildDialogTitle(this.props.step, this.props.round),
            duration: MIN_DURATION_EXT,
            durationUnit: LaunchComponent.DEFAULT_UNIT,
            canEnableExtension: true
        }
    }

    static propTypes = {
        problemStepId: PropTypes.number.isRequired,
        step: PropTypes.string.isRequired,
        stepId: PropTypes.number.isRequired,
        round: PropTypes.string.isRequired,
        roundId: PropTypes.number.isRequired,
        onExtend: PropTypes.func
    };

    extendCurrentRoundDuration() {
        const endDate = this.getExtendedEndDate(this.props.problemStep);
        const stepRoundEndTime = getStepRoundEndTime(this.props.problemStep, this.props.problemStep.activityDuration);
        const duration = endDate.diff(stepRoundEndTime, 'minutes') / 60;
        this.setState({showExtendDurationDialog: false, duration: MIN_DURATION_EXT, durationUnit: LaunchComponent.DEFAULT_UNIT});
        const extendAction = extendCurrentRoundAction(this.props.problemStepId, this.props.stepId, duration);
        this.props.dispatch(extendAction);
        if (this.props.onExtend) {
            this.props.onExtend(extendAction);
        }
    }

    showExtendDurationDialog() {
        this.setState({showExtendDurationDialog: true});
    }

    onCancel() {
        this.setState({showExtendDurationDialog: false});
    }

    buildDialogTitle(currentStep, currentRound) {
        return `Extend duration for ${currentStep}/${currentRound}`;
    }

    getExtendedEndDate(problemStep) {
        const base = getStepRoundEndTime(problemStep, problemStep.activityDuration);
        if (!base || base.isBefore(moment())) {
            return moment().add(this.state.duration, this.state.durationUnit);
        } else {
            return base.add(this.state.duration, this.state.durationUnit);
        }
    }

    canEnableExtension(problemStep, problem) {
        const end = this.getExtendedEndDate(problemStep);
        return moment.max(end, moment(problem.expectedCompletion)) !== end;
    }

    durationInputChanged(props = this.props) {
        this.setState({canEnableExtension: this.canEnableExtension(props.problemStep, props.problem)});
    }

    UNSAFE_componentWillMount() {
        this.durationInputChanged();
    }

    UNSAFE_componentWillReceiveProps(newProps) {
        this.durationInputChanged(newProps);
    }

    render() {
        const {hours, end} = getRemainingTime(this.props.problemStep);
        return (
            <div className="md-grid currentStepTimer">
                <div className="md-cell md-cell--3" id="time-remaining-lbl">Time Remaining</div>
                <div className="md-cell md-cell--4 md-cell--1-offset" id="time-remaining">
                    <div>{hours <= 0 ? "EXPIRED" : <Timer className='timeRemaining' deadline={end.toString()} suffix={true}/>}</div>
                </div>
                
                <div className="md-cell md-cell--4 md-cell--center">
                    <IconLabelButton className="secondaryActionButton lightBackground" iconChildren="timer" label={EXTEND_BTN_TXT} onClick={this.showExtendDurationDialog} />
                </div>
                <div>
                    <DialogContainer
                        id="extendDuration"
                        visible={this.state.showExtendDurationDialog}
                        width={590}
                        height={!this.state.canEnableExtension ? 230 : 215}
                        title={this.state.dialogTitle}
                        onHide={() => {}}
                        actions={[<Button className="secondaryActionButton" flat onClick={this.onCancel}>CANCEL</Button>, <Button raised primary onClick={this.extendCurrentRoundDuration} disabled={!this.state.canEnableExtension}>{EXTEND_BTN_TXT}</Button>]}>
                        <div>
                            <div className='durationInputs'>
                                <TextField id="extended-duration" fullWidth={false} type="number"
                                           value={this.state.duration}
                                           onChange={(value) => {
                                               this.setState({duration: Math.max(Number(value), MIN_DURATION_EXT)}, () => {
                                                   this.durationInputChanged();
                                               });
                                           }}
                                />
                                <Select
                                    className='durationUnit'
                                    options={LaunchComponent.DURATION_UNITS}
                                    value={LaunchComponent.DURATION_UNITS.filter(({value}) => (value === this.state.durationUnit))}
                                    isClearable={false}
                                    styles={commonSelectStyles}
                                    onChange={(selected) => {
                                        this.setState({durationUnit: selected.value}, () => {
                                            this.durationInputChanged();
                                        })
                                    }}
                                />
                            </div>
                            {
                                this.state.canEnableExtension ? null : (
                                    <div><span className='smallTextFont-px warning'>You cannot extend the duration of current step beyond the problem end date of {end.format('LLLL')}!</span></div>
                                )
                            }
                        </div>
                        
                    </DialogContainer>
                </div>
            </div>
        );
    }
}

function mapStoreToProps(store, myProps) {
    const problemStep = getProblemStepFromStore(store, myProps.problemStepId);
    return {
        problemStep,
        problem: getProblemDBFromStore(store, problemStep.problemId)
    }
}

export default connect(mapStoreToProps)(CurrentStepTimer);
