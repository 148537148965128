import {AnyAction, combineReducers, Reducer} from 'redux';
import moment from 'moment';
import {ThunkAction} from 'redux-thunk';
import {isFinite} from 'lodash';

import {UserForTeamAction} from "./allUsersForTeamReducer";
import DataStatusEnum from '../DataStatusEnum';
import keyQuestionsReducer, {KeyQuestionsState} from './keyQuestionsReducer';
import keyFactsReducer, {KeyFactsState} from './keyFactsReducer';
import keyVariablesReducer, {KeyVariablesState} from './keyVariablesReducer';
import methodSelectionReducer, {MethodSelectionState} from './methodSelectionReducer';
import suggestionsReducer, {SuggestionsReducerType} from './suggestionsReducer';
import bayesNetGraphReducer, {BayesNetGraphState} from './bayesNetGraphReducer';
import bayesNetParametersReducer, {BayesNetParametersState} from './bayesNetParametersReducer';
import exploreModelReducer, {ExploreModelState} from './exploreModelReducer';
import candidateSolutionReducer, {SingleCandidateSolutionState} from './candidateSolutionReducer';
import reportReducer, {ReportState} from './reportReducer';
import * as constants from '../util/constants';
import {timestampedActionCreator} from '../util/genericReducers';
import ensureFieldPath from '../ensureFieldPath';
import {StoreWithSharedState} from './sharedStateReducer';
import {getUserForProblemFromStore} from './allUsersForTeamReducerGetters';

// ======== Constants

const NON_ACTION = {type: '@@NO_SUCH_ACTION'};

export interface StatusForUserAndTeamAction extends UserForTeamAction {
    status: DataStatusEnum
}

export type SingleStatusForUserAndTeamReducerType = {
    keyFacts: KeyFactsState,
    keyQuestions: KeyQuestionsState,
    keyVariables: KeyVariablesState,
    methodSelection: MethodSelectionState,
    suggestions: SuggestionsReducerType,
    bayesNetGraph: BayesNetGraphState,
    bayesNetParameters: BayesNetParametersState,
    exploreModel: ExploreModelState,
    candidateSolution: SingleCandidateSolutionState,
    report: ReportState
}

// NOTE: the field names here need to match the values of the corresponding syncVariable in constants.ts
export const singleStatusForUserAndTeamReducer = combineReducers<SingleStatusForUserAndTeamReducerType>({
    keyFacts: keyFactsReducer,
    keyQuestions: keyQuestionsReducer,
    keyVariables: keyVariablesReducer,
    methodSelection: methodSelectionReducer,
    suggestions: suggestionsReducer,
    bayesNetGraph: bayesNetGraphReducer,
    bayesNetParameters: bayesNetParametersReducer,
    exploreModel: exploreModelReducer,
    candidateSolution: candidateSolutionReducer,
    report: reportReducer
});

export const initialStatusState = {
    [DataStatusEnum.MY_DRAFT]: singleStatusForUserAndTeamReducer(undefined, NON_ACTION),
    [DataStatusEnum.PUBLISHED]: singleStatusForUserAndTeamReducer(undefined, NON_ACTION),
    [DataStatusEnum.GROUP_DRAFT]: singleStatusForUserAndTeamReducer(undefined, NON_ACTION),
    [DataStatusEnum.GROUP]: singleStatusForUserAndTeamReducer(undefined, NON_ACTION)
};

// ======== Reducers

export function getPublishedStatus(status: DataStatusEnum): DataStatusEnum {
    switch (status) {
        case DataStatusEnum.MY_DRAFT:
            return DataStatusEnum.PUBLISHED;
        case DataStatusEnum.GROUP_DRAFT:
            return DataStatusEnum.GROUP;
        default:
            throw new Error('No published status for DataStatusEnum value ' + status);
    }
}

export type AllStatusesForUserAndTeamReducerType = {
    [key: number]: SingleStatusForUserAndTeamReducerType
};

const allStatusesForUserAndTeamReducer: Reducer<AllStatusesForUserAndTeamReducerType> = (state = initialStatusState, action: StatusForUserAndTeamAction | AnyAction) => {
    switch (action.type) {
        case constants.RESET_PROBLEM_STEP:
            return initialStatusState;
        case constants.STATUS_CHANGE:
            let toStatus = getPublishedStatus(action.status);
            if (toStatus === null) {
                return state;
            }
            let submitObj = {...state[toStatus]};
            const submitTime = moment().format();
            action.syncVariables.forEach((syncVariable) => {
                submitObj[syncVariable] = {...state[action.status][syncVariable]};
                // We want to leave undefined timestamps as undefined when they're copied to toStatus, so they're
                // treated correctly when rolling values forward.  However, if *all* of the syncVariables have undefined
                // timestamps (indicated by action.deltaTimetamp === undefined, which means the user has hit publish
                // without editing anything), we need to set the timestamps so the publish actually has a timestamp
                // associated with it.  Fortunately, the rollforward doesn't do any useful work when nothing has been
                // edited, so we can safely set the timestamps to any value (submitTime, as it happens).
                const variableTimestamp = submitObj[syncVariable].timestamp;
                submitObj[syncVariable]['timestamp'] = (action.deltaTimestamp === undefined) ? submitTime :
                    (!variableTimestamp) ? undefined :
                        moment(Date.parse(variableTimestamp) + action.deltaTimestamp).format();
            });
            return {...state, [toStatus]: submitObj};
        case constants.REVERT_STATUS_CHANGE:
            let revertFromStatus = getPublishedStatus(action.status);
            if (revertFromStatus === null) {
                return state;
            }

            let revertObj = {...state[action.status]};
            action.syncVariables.forEach((syncVariable) => {
                revertObj[syncVariable] = {...state[revertFromStatus][syncVariable], onRevert: true};
            });

            return {...state, [action.status]: revertObj};
        default:
            if (action.status !== undefined) {
                const stateIn = state[action.status];
                const stateOut = singleStatusForUserAndTeamReducer(stateIn, action);
                return (stateOut === stateIn) ? state : {...state, [action.status]: stateOut};
            } else {
                return state;
            }
    }
};

export default allStatusesForUserAndTeamReducer;

// ======== messaging functions

export function getUserStatusesActionTypes() {
    return [constants.STATUS_CHANGE];
}

// ======== DBSync functions
// (used by DBSync and for unit testing)
export function setUserStatusForProblemStep(store, problemStepId, userId, status: DataStatusEnum, object) {
    ensureFieldPath(store, 'sharedState', 'teams', problemStepId, 'user', userId, 'status');
    store.sharedState.teams[problemStepId].user[userId].status[status] = object;
}

// ======== Action generator functions

export function rawStatusChangeAction(problemStepId: number, userId: number, status: DataStatusEnum, stepId: number, syncVariables: string[], deltaTimestamp?: number): StatusForUserAndTeamAction {
    return {type: constants.STATUS_CHANGE, problemStepId, userId, status, stepId, syncVariables, deltaTimestamp};
}

export function statusChangeAction(problemStepId: number, userId: number, status: DataStatusEnum, stepId: number, syncVariables: string[]): ThunkAction<void, StoreWithSharedState, null, StatusForUserAndTeamAction> {
    // Redux thunk action
    return (dispatch, getState) => {
        const state = getState();
        const user = getUserForProblemFromStore(state, problemStepId, userId);
        const mostRecentTimestamp = syncVariables
            .map((syncVariable) => (user.status[status][syncVariable].timestamp))
            .map((timestampStr) => (Date.parse(timestampStr)))
            .filter((timestampVal) => (isFinite(timestampVal)))
            .reduce<number | undefined>((max, timestampVal) => (max === undefined ? timestampVal : Math.max(max, timestampVal)), undefined);
        // Calculate a delta-T which, when added to all syncVariable timestamps, will make the most recent timestamp equal now.
        const deltaTimestamp = mostRecentTimestamp === undefined ? undefined : Date.now() - mostRecentTimestamp;
        dispatch(rawStatusChangeAction(problemStepId, userId, status, stepId, syncVariables, deltaTimestamp));
    };
}

export function copyPublishedDataAction(problemStepId: number, userId: number, status: DataStatusEnum, fromUser: Partial<SingleStatusForUserAndTeamReducerType>, syncVariables: string[]) {
    return {type: constants.COPY_PUBLISHED_ACTION, problemStepId, userId, status, fromUser, syncVariables};
}

export function appendPublishedDataAction(problemStepId: number, userId: number, status: DataStatusEnum, fromUser: Partial<SingleStatusForUserAndTeamReducerType>, syncVariables: string[]) {
    return {type: constants.APPEND_PUBLISHED_ACTION, problemStepId, userId, status, fromUser, syncVariables};
}

export function revertStatusChangeAction(problemStepId: number, userId: number, status: DataStatusEnum, syncVariables: string[]): StatusForUserAndTeamAction {
    return timestampedActionCreator({type: constants.REVERT_STATUS_CHANGE, problemStepId, userId, status, syncVariables});
}


// ======== Getter functions

export function getStatusFromStore(store: StoreWithSharedState, problemStepId: number, userId: number, status: DataStatusEnum): SingleStatusForUserAndTeamReducerType {
    return store.sharedState.teams[problemStepId].user[userId].status[status];
}

export function getAllStatusesFromStore(store: StoreWithSharedState, problemStepId: number, userId: number): AllStatusesForUserAndTeamReducerType {
    return store.sharedState.teams[problemStepId].user[userId].status;
}
