
// ======== Constants

import {StoreWithSharedState} from './sharedStateReducer';

export interface BNEngine {
    name: string;
    netClass: string;
    fileSuffix: string[];
}

export interface BnWrapperState {
    engines: BNEngine[];
}

enum BnWrapperStateActionEnum {
    UPDATE_BN_ENGINES = 'update-bn-engines'
}

// ======== Action generator functions

export interface UpdateBNEnginesActionType {
    type: BnWrapperStateActionEnum.UPDATE_BN_ENGINES;
    engines: BNEngine[];
}

export function updateBNEnginesAction(engines: BNEngine[]): UpdateBNEnginesActionType {
    return {type: BnWrapperStateActionEnum.UPDATE_BN_ENGINES, engines};
}

type BNEngineStateAction = UpdateBNEnginesActionType;

// ======== Reducers

export const initialBnWrapperState = {
    engines: []
};

export function BnWrapperStateReducer(state: BnWrapperState = initialBnWrapperState, action: BNEngineStateAction): BnWrapperState {
    switch (action.type) {
        case BnWrapperStateActionEnum.UPDATE_BN_ENGINES:
            return {
                ...state,
                engines: action.engines
            };
        default:
            return state;
    }
}

// ======== DBSync functions
// (used by DBSync and for unit testing)

export function getDBSyncActionTypes() {
    return [BnWrapperStateActionEnum.UPDATE_BN_ENGINES];
}

export function setBnWrapperStateInStore(store: StoreWithSharedState, engines: BNEngine[]) {
    store.sharedState.bnWrapperState = {engines};
}

// ======== Getter functions

export function getBnWrapperStateFromStore(store: StoreWithSharedState): BnWrapperState {
    return store.sharedState.bnWrapperState;
}
