import React, {Component} from 'react';
import {Redirect, withRouter} from 'react-router-dom'
import {connect} from 'react-redux';

import {getUserFromStore} from '../reducers/loginReducer';
import {ADMIN_PATH_BASE, SELECT_PATH} from '../../common/clientServer/navigation';

class ProblemContextRouter extends Component {

    render() {
        if (this.props.user && this.props.user.admin !== (this.props.location.pathname.indexOf(ADMIN_PATH_BASE) === 0)) {
            return <Redirect to={this.props.user.admin ? ADMIN_PATH_BASE : SELECT_PATH} />;
        }
        return null;
    }
}

function mapStoreToProps(store) {
    return {
        user: getUserFromStore(store)
    };
}

export default withRouter(connect(mapStoreToProps)(ProblemContextRouter));