import React, {Component} from 'react';
import {connect} from 'react-redux';

import HelpContent from './HelpContent';
import {StoreWithSharedState} from '../../common/reducers/sharedStateReducer';
import {SingleProblemStepType} from '../../common/reducers/allTeamsReducer';
import {getLoggedInUserIdFromStore} from '../reducers/loginReducer';
import {SingleUserForProblemReducerType} from '../../common/reducers/allUsersForTeamReducer';
import {getProblemStepFromStore} from '../../common/reducers/allTeamsReducerGetters';
import {getUserForProblemFromStore} from '../../common/reducers/allUsersForTeamReducerGetters';

interface HelpTipsStoreProps {
    problemStep: SingleProblemStepType;
    user: SingleUserForProblemReducerType;
}

interface HelpTipsOwnProps {
    problemStepId: number;
    helpId: string;
}

type HelpTipsProps = HelpTipsOwnProps & HelpTipsStoreProps;

class HelpTips extends Component<HelpTipsProps> {
    render() {
        return (
            <HelpContent helpTipId={this.props.helpId} user={this.props.user.db} step={this.props.problemStep}/>
        );
    }
}

function mapStoreToProps(store: StoreWithSharedState, ownProps: HelpTipsOwnProps): HelpTipsStoreProps {
    const problemStep = getProblemStepFromStore(store, ownProps.problemStepId);
    const userId = getLoggedInUserIdFromStore(store);
    return {
        problemStep,
        user: getUserForProblemFromStore(store, ownProps.problemStepId, userId)
    };
}

export default connect(mapStoreToProps)(HelpTips);