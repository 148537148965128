import React, {Component} from 'react';
import PropTypes from 'prop-types';

import DataStatusEnum from '../../common/DataStatusEnum';
import * as constants from '../../common/util/constants';

//Commented-out code - may be uncommented in future
class MethodSelectionTableComponent extends Component {

    static propTypes = {
        users: PropTypes.object.isRequired,
        readOnly: PropTypes.bool.isRequired,
        page:PropTypes.string.isRequired,
        groupMethodSelection: PropTypes.number,
        launchStep:PropTypes.number
    };

    static defaultProps = {
        method: -1,
        groupMethodSelection: null,
        readOnly: false
    };

    onSelectMethod(method) {
        this.props.onSelectMethod(method);
    }

    getLaunchStep(){
        this.props.getLaunchStep();
    }
    
    render() {

    //commented out for US-143    //let isGroupMethodSelected = (!(this.props.launchStep === constants.STEP_EXPLORE_PROBLEM) && this.props.groupMethodSelection);
        
    //commented out for US-143    //let analystSelected = -1; 

        let published = DataStatusEnum.PUBLISHED;
        let countBN = 0;
        let countNBN = 0;
        let countNONE = 0;
        let countNoVote = 0;
        
        Object.keys(this.props.users).forEach((user) => {
            this.props.users[user].status[published] && this.props.users[user].status[published].methodSelection.method === constants.METHOD_BN ?
                countBN++ :
                (this.props.users[user].status[published] && this.props.users[user].status[published].methodSelection.method === constants.METHOD_NBN ?
                    countNBN++ :
                    (this.props.users[user].status[published] && this.props.users[user].status[published].methodSelection.method === constants.METHOD_NONE ?
                        countNONE++ :
                        countNoVote++
                    )
                )
        });
    
        //commented out for US-143
        // if(this.props.page === constants.SUBTAB_INFORMATION_FACILITATOR){ 
            
        //     //Set the selected index of methodselection for private round
        //     analystSelected = this.props.getAnalystSelectionByCount(countBN, countNBN);
        // }
        
        return(
            
            <table className='mediumTextFont methodDivMethodTable'>
                <tbody>
                    <tr>
                        <td>
                        <table className='methodDivMethodTable'>
                            <tbody>
                                {/*Row to display count for BN*/}
                                <tr id='problemMethod-BN' 
                                    className={'methodDivMethodRow' + (this.props.method === constants.METHOD_BN  && this.props.page === constants.SUBTAB_MY_DRAFT ? ' selected' : (this.props.groupMethodSelection === constants.METHOD_BN 
                                        // && isGroupMethodSelected 
                                        ? ' groupSelected' :
                                //commented out for US-143    // (analystSelected === constants.METHOD_BN ? ' analystSelected' : '')
                                    ''
                                    ))} 
                                    onClick={() => {
                                        if (!this.props.readOnly) {
                                            this.onSelectMethod(constants.METHOD_BN);
                                        }
                                    }}>

                                    <td className='methodSelectionTd firstMethodBorder'>
                                        <div className='methodVoteRow'>
                                            <div className={"material-icons methodDivDoneIconStyle" + (this.props.method === constants.METHOD_BN  && this.props.page === constants.SUBTAB_MY_DRAFT ? ' selected' : (this.props.groupMethodSelection === constants.METHOD_BN 
                                            // && isGroupMethodSelected 
                                            ? ' selected' : 
                                        //commented out for US-143    //(analystSelected === constants.METHOD_BN ? ' selected' : '')
                                            ''
                                            ))}>done</div>
                                            <div className='methodDivMethodText'>
                                                {this.props.page === constants.SUBTAB_MY_DRAFT ? 'This problem is suited to a Bayesian Network' : 'Bayesian network'}
                                            </div>
                                        </div>
                                    </td>
                                    {/* commented out for US-143 */}
                                    {/* <td className="textFont methodSelectionVoteTd firstMethodVoteBorder">
                                    {!(this.props.readOnly) && ((this.props.page === constants.SUBTAB_MY_DRAFT && this.props.role === constants.ROLE_FACILITATOR) || this.props.page === constants.SUBTAB_INFORMATION_FACILITATOR) ? countBN +' votes' : ''}
                                    </td> */}
                                </tr>
                                {/*Row to display count for NBN*/}
                                <tr id='problemMethod-NBN' 
                                    className={'methodDivMethodRow ' + (this.props.method === constants.METHOD_NBN  && this.props.page === constants.SUBTAB_MY_DRAFT ? ' selected' : (this.props.groupMethodSelection === constants.METHOD_NBN 
                                        // && isGroupMethodSelected 
                                        ? ' groupSelected' :
                                //commented out for US-143    //(analystSelected === constants.METHOD_NBN ? ' analystSelected' : '')
                                    ''
                                    ))}
                                    onClick={() => {
                                        if (!this.props.readOnly) {
                                            this.onSelectMethod(constants.METHOD_NBN);
                                        }
                                    }}>

                                    <td className='methodSelectionTd methodBorder'>
                                        <div className='methodVoteRow'>
                                            <div className={"material-icons methodDivDoneIconStyle" + (this.props.method === constants.METHOD_NBN  && this.props.page === constants.SUBTAB_MY_DRAFT ? ' selected' : (this.props.groupMethodSelection === constants.METHOD_NBN 
                                            // && isGroupMethodSelected 
                                            ? ' selected' : 
                                        //commented out for US-143    //(analystSelected === constants.METHOD_NBN ? ' selected' : '')
                                            ''
                                            ))}>done</div>
                                            <div className='methodDivMethodText'>
                                                {this.props.page === constants.SUBTAB_MY_DRAFT ? 'This problem is NOT suited to a Bayesian Network' : 'Skip Bayesian network'}
                                            </div>
                                        </div>
                                    </td>
                                    {/* //commented out for US-143 */}
                                    {/* <td className="textFont methodSelectionVoteTd methodVoteBorder">
                                    {this.props.readOnly ? '': ((this.props.page === constants.SUBTAB_MY_DRAFT && this.props.role === constants.ROLE_FACILITATOR) || this.props.page === constants.SUBTAB_INFORMATION_FACILITATOR ? countNBN +' votes' : '')}
                                    </td> */}
                                </tr>

                    {/*Row to display count for NOT CLEAR*/}
                                {(this.props.page === constants.SUBTAB_MY_DRAFT && this.props.role !== constants.ROLE_FACILITATOR) 
                                //|| (this.props.page === constants.SUBTAB_INFORMATION_FACILITATOR &&  this.props.page === constants.SUBTAB_MY_DRAFT) 
                                ?
                                <tr id='problemMethod-NONE' 
                                    className={'methodDivMethodRow ' + (this.props.page === constants.SUBTAB_MY_DRAFT && this.props.method === constants.METHOD_NONE ? ' selected' :'')} 
                                    onClick={() => ((this.props.readOnly || this.props.page === constants.SUBTAB_MY_DRAFT) ? (this.props.role === constants.ROLE_FACILITATOR ? '' : this.onSelectMethod(constants.METHOD_NONE)): '')}>

                                    <td className={'methodSelectionTd methodBorder'  + (this.props.role === constants.ROLE_FACILITATOR ? ' disabledMethodSelection' : '')}>
                                        <div className='methodVoteRow'>
                                            <div className={"material-icons methodDivDoneIconStyle" + ((this.props.method) === constants.METHOD_NONE ? ' selected' : '')}>done</div>
                                            <div className='methodDivMethodText'>
                                                {this.props.page === constants.SUBTAB_MY_DRAFT ? 'It is NOT CLEAR if this problem is suited to a Bayesian Network' : 'Not Clear'}
                                            </div>
                                        </div>
                                    </td>
                                    {/* //commented out for US-143 */}
                                    {/* <td className={'textFont methodSelectionVoteTd methodVoteBorder'  + (this.props.role === constants.ROLE_FACILITATOR ? ' disableMethodVotes' : '')}>
                                    {this.props.readOnly ? '': ((this.props.page === constants.SUBTAB_MY_DRAFT && this.props.role === constants.ROLE_FACILITATOR) || this.props.page === constants.SUBTAB_INFORMATION_FACILITATOR ? countNONE +' votes' : '')}
                                    </td> */}
                                </tr>
                                : null 
                                }
                            </tbody>
                        </table>
                        </td>
                    </tr>
                    {/* //commented out for US-143 */}
                    {/* {this.props.readOnly ? null : ((this.props.page === constants.SUBTAB_MY_DRAFT && this.props.role === constants.ROLE_FACILITATOR) || this.props.page === constants.SUBTAB_INFORMATION_FACILITATOR ? 
                    <tr>{/*Row to display count for analysts who have NOT VOTED*/}
                        {/* <td>
                            <table className="methodDivMethodTable">
                                <tbody>
                                    <tr>
                                        <td className="textFont notVotedAnalystsTd">
                                        {countNoVote} {countNoVote === 1 ? "analyst " : "analysts"} have not voted yet
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </td>
                    </tr> :
                    null 
                    )} */}

                    {/*Display text for Explore Network exchange round*/}
                    
                    {this.props.groupMethodSelection ?  
                        <tr>{/*Row to display count for analysts who have NOT VOTED*/}
                            <td>
                                <table className="methodDivMethodTable">
                                    <tbody>
                                        <tr>
                                            <td className="textFont">
                                            You can change the nominated approach in EXPLORE PROBLEM / Group Draft
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </td>
                        </tr> :
                    null
                    }


                </tbody>
            </table>
        );
        
    }
}

export default MethodSelectionTableComponent;