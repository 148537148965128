import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {withRouter} from 'react-router-dom';

import {getLoggedInUserIdFromStore} from '../reducers/loginReducer';
import {getUserForProblemFromStore} from '../../common/reducers/allUsersForTeamReducerGetters';

class RoleContainer extends Component {

    static propTypes = {
        userRole: PropTypes.oneOfType([ PropTypes.number, PropTypes.arrayOf(PropTypes.number) ]).isRequired,
        userRoleForProblem: PropTypes.number,
        problemStepId: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    };
    
    static defaultProps = {
        userRoleForProblem: undefined
    };

    doesRoleMatch() {
        if (Array.isArray(this.props.userRole)) {
            for (let index = 0; index < this.props.userRole.length; ++index) {
                if (this.props.userRole[index] === this.props.userRoleForProblem) {
                    return true;
                }
            }
        } else if (this.props.userRole === this.props.userRoleForProblem) {
            return true;
        }
        return false;
    }

    render() {
        if (this.doesRoleMatch()) {
            return <div className="roleContainerWrap">{this.props.children}</div>;
        } else {
            return null;
        }
    }

}

function mapStoreToProps(store, myProps) {
    const problemStepId = myProps.problemStepId || myProps.match.params.problemStepId;
    const userId = getLoggedInUserIdFromStore(store);
    const userRoleForProblem = myProps.userRoleForProblem || getUserForProblemFromStore(store, problemStepId, userId).db.role;
    return {
        userRoleForProblem
    }
}

export default withRouter(connect(mapStoreToProps)(RoleContainer));