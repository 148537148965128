import {flatten} from 'lodash';

import * as constants from './constants';

interface ReportTemplate {
    heading: string;
    headingLevel: number;
    perQuestion: boolean;
    instructions: {[method: string]: string};
}

export const REPORT_QUESTION_HEADING_LEVEL = 1;

export const REPORT_TEMPLATE: {[key: string]: ReportTemplate} = {
    basicSituation: {
        heading: 'Brief Summary of Situation',
        headingLevel: 1,
        perQuestion: false,
        instructions: {
            [constants.METHOD_NBN]: `
(Optional) Write a brief paraphrase of the situation to set the context.
`,
            [constants.METHOD_BN]: `
(Optional) Write a brief paraphrase of the situation to set the context; this could include describing the structure of your BN.
`
        }
    },
    competingHypotheses: {
        heading: 'Hypotheses to assess',
        headingLevel: 1,
        perQuestion: false,
        instructions: {
            [constants.METHOD_NBN]: `
**List the main hypotheses in the problem** (i.e., statements that, when you assess their probabilities, will answer the question). Also, before any evidence and reasoning are taken into account, please note whether:
* some hypotheses are *initially* more likely than others
* some of the hypotheses could be true *simultaneously* (if this is a special situation that the evidence relates to differently, then you may want to define it as a separate hypothesis)
* *all* of the hypotheses could be false (if so, then you may want to define it as an additional “null” hypothesis)
`,
            [constants.METHOD_BN]: `
**List the main hypotheses in the problem** (i.e., statements that, when you assess their probabilities, will answer the question). Also, before any evidence and reasoning are taken into account, please note whether:
* some hypotheses are *initially* more likely than others; in the base scenario of your BN (6. EXPLORE PROBLEM), you should see the initial probabilities of your hypotheses (key states of your target variables)
* some of the hypotheses could be true *simultaneously*; by entering a probability of 100% for one hypothesis (key state of target variable), you can see how this impacts on the probability of another hypothesis/target variable)
* *all* of the hypotheses could be false
`
        }
    },
    evaluateSources: {
        heading: 'Reliability of sources',
        headingLevel: 1,
        perQuestion: false,
        instructions: {
            [constants.METHOD_NBN]: `
**Evaluate all the sources of evidence,** by noting:
* their reliability in general (e.g., their track record)
* if any of them are biased towards providing specific kinds of evidence
* if any source is probably influenced by another source’s evidence
`,
            [constants.METHOD_BN]: `
**Evaluate all the sources of evidence,** by noting:
* their reliability in general (e.g., their track record)
* if any of them are biased towards providing specific kinds of evidence
* if any source is probably influenced by another source’s evidence

Note:
* A source is less _reliable_ when it has higher error rates (e.g., the combined false positive and false negative rates).
* It’s _biased_ when its rates vary (e.g., if the false positive rate is higher than the false negative rate, then it’s overly bold about providing positive reports, whereas if the false positive rate is lower, then it’s overly cautious). 
* If there’s an arrow in your BN from one source to another (because the second source is influenced by the first), then state the second source’s error rates more than once, given different evidence provided by the first source.
`
        }
    },
    assessment: {
        heading: 'Assessment of how evidence impacts on hypotheses',
        headingLevel: 2,
        perQuestion: true,
        instructions: {
            [constants.METHOD_NBN]: `
**For each hypothesis, explain why the evidence makes it more or less likely.** You can include:
* why each piece is relevant (or not relevant), including any linking steps
* how much each piece supports (or undermines) this hypothesis individually
* why some pieces have more impact than others (e.g., more reliable source, or more directly connected)
* if the combined evidence has a different impact than this suggests (e.g., two pieces weak individually are very strong when combined), and why
`,
            [constants.METHOD_BN]: `
**For each hypothesis, explain why the evidence makes it more or less likely.** You can include:
* why each piece is relevant (or not relevant), including any linking steps; your BN structure shows how evidence is linked
* how much each piece supports (or undermines) this hypothesis individually; enter each piece of evidence into your BN individually in a Scenario in Step 5. EXPLORE PROBLEM
* why some pieces have more impact than others (e.g., more reliable source, or more directly connected)
* if the combined evidence has a different impact than this suggests (e.g., two pieces weak individually are very strong when combined), and why; enter all pieces of evidence into your BN in a single combined Scenario in 5. EXPLORE PROBLEM. If their combined impact is surprising, then it’s usually because these pieces of evidence are linked to each other, or because they have a common effect
`
        }
    },
    conclusions: {
        heading: 'Conclusions',
        headingLevel: 2,
        perQuestion: true,
        instructions: {
            [constants.METHOD_NBN]: `
**State clearly and succinctly your answer to the question (and any sub-parts)**, with a summary of the reasoning. 
* Include the likelihood of each hypothesis after taking the evidence and reasoning into account
* You may use rough estimates, e.g., approximately 75% (likely)  
`,
            [constants.METHOD_BN]: `
**State clearly and succinctly your answer to the question (and any sub-parts)**, with a summary of the reasoning. 
* Include the likelihood of each hypothesis after taking the evidence and reasoning into account
* You should use precise probabilities from a Scenario for your BN (Step 5. EXPLORE PROBLEM) that includes all the problem evidence, as well as the Intelligence Guidelines recommended qualitative description, e.g., 75.3% (likely)
* You may use rough estimates, e.g., approximately 75% (likely)  
`
        }
    },
    assumptionsAndMissingInformation: {
        heading: 'Assumptions and missing information',
        headingLevel: 1,
        perQuestion: false,
        instructions: {
            [constants.METHOD_NBN]: `
**Identify any key assumptions** underlying your analysis, explaining how they could be mistaken and what difference that would make. Also, **note any missing information** that would be useful for assessing the hypotheses.
`,
            [constants.METHOD_BN]: `
**Identify any key assumptions** underlying your analysis, explaining how they could be mistaken and what difference that would make. Also, **note any missing information** that would be useful for assessing the hypotheses.
`
        }
    },
    tablesAndDiagrams: {
        heading: 'Tables and/or diagrams',
        headingLevel: 1,
        perQuestion: false,
        instructions: {
            [constants.METHOD_NBN]: `
Include any tables or diagrams that make your reasoning clearer.
`,
            [constants.METHOD_BN]: `
Include any tables or diagrams that make your reasoning clearer. In particular, you could:
* Include a screenshot of the Bayesian network structure you used to analyse the problem showing the hypothesis/target and evidence variables you used in the analysis. 
* Include screenshots of conditional probability tables associated with specific pieces of evidence 
* Include screenshots of key evidence combinations from 6. EXPLORE PROBLEM (e.g., Base Scenario, Scenario with single piece of evidence, Scenario with an intermediate combination of evidence, Scenario with all evidence). 

See Training Problems "Drug Cheat Full" and "3 Nations Full" for examples.
`
        }
    }
};

export function getReportSections(problemQuestions: {[questionId: string]: string}) {
    const sectionIds = Object.keys(REPORT_TEMPLATE);
    const perQuestionSectionIds = sectionIds.filter((sectionId) => (REPORT_TEMPLATE[sectionId].perQuestion));
    let questionNumber = 1;
    const questionSectionIds = flatten(Object.keys(problemQuestions).map((questionId) => (
        [questionId + '#' + (questionNumber++), ...perQuestionSectionIds.map((sectionId) => (questionId + '#' + sectionId))]
    )));
    let beforePerQuestions = true;
    return sectionIds.reduce((result: string[], sectionId) => {
        const section = REPORT_TEMPLATE[sectionId];
        if (section.perQuestion) {
            if (beforePerQuestions) {
                beforePerQuestions = false;
                return result.concat(questionSectionIds);
            } else {
                return result;
            }
        } else {
            return [...result, sectionId];
        }
    }, []);
}


export function buildReportHtml(problemQuestions: {[questionId: string]: string}, reportSections: {[sectionId: string]: string} = {}) {
    const sections = getReportSections(problemQuestions);
    const content = sections.reduce((html, fullSectionId) => {
        let sectionId = fullSectionId, questionId;
        if (!REPORT_TEMPLATE[sectionId]) {
            [questionId, sectionId] = sectionId.split('#');
        }
        const section = REPORT_TEMPLATE[sectionId];
        if (!isNaN(Number(sectionId))) {
            return html + `<div><h${REPORT_QUESTION_HEADING_LEVEL}>Question ${sectionId}</h${REPORT_QUESTION_HEADING_LEVEL}><div class="questionText">${problemQuestions[questionId]}</div></div>`;
        } else if (reportSections[fullSectionId] && reportSections[fullSectionId].trim()) {
            return html + `<div><h${section.headingLevel}>${section.heading}</h${section.headingLevel}><div>${reportSections[fullSectionId]}</div></div>`;
        } else {
            return html;
        }
    }, '');
    return `<div class="reportFromTemplate">${content}</div>`;
}