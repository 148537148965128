import {EPSILON} from '../../common/util/constants';

/**
 * Round off a floating point value to a smaller number of significant figures.
 * Assumes that EPSILON is 5*10^-n for some n.
 *
 * @param value The number to round off, which is expected to be between 0 and 1 inclusive.
 * @returns {number} The same value rounded off to within +/- EPSILON.
 */
export function roundFractionToEpsilon(value) {
    const factor = 0.5 / EPSILON;
    return Math.round(value * factor) / factor;
}

/**
 * Round off a percentage value to a smaller number of significant figures.
 * Assumes that EPSILON is 5*10^-n for some n.
 *
 * @param value The percentage number to round off, which is expected to be between 0 and 100 inclusive.
 * @returns {number} The same value rounded off to within +/- 100*EPSILON
 */
export function roundPercentToEpsilon(value) {
    const factor = 0.005 / EPSILON;
    return Math.round(value * factor) / factor;
}

/**
 * Normalise an array of numbers such that they total to 1.
 * @param probabilities An array of floating point numbers
 * @return {number[]} An array with the values from probabilities scaled such that their sum equals 1.0 +/- EPSILON
 */
export function normaliseNumberArray(probabilities) {
    let total = probabilities.reduce((total, value) => (total + Number(value)), 0);
    // If total is zero, just distribute probabilities equally; otherwise, scale value by 1/total.
    const adjustValue = (total > 0) ? ((value) => (value / total)) : (() => (1 / probabilities.length));
    // Iterate over values and adjust using the above fn.  Accumulate and factor in rounding errors.
    let roundingError = 0;
    return probabilities.map((value) => {
        let normalised = adjustValue(value) + roundingError;
        let rounded = roundFractionToEpsilon(normalised);
        roundingError = normalised - rounded;
        return rounded;
    });
}

