import React, {Component} from 'react';
import {connect} from 'react-redux';
import {toString as cronToString} from 'cronstrue';
import classNames from 'classnames';
import {capitalize, lowerCase} from 'lodash';

import { getAllCronsFromStore, updateCronsListAction, updateCronAction } from '../reducers/allCronsReducer';
import moment from 'moment';

class AdminCronPanel extends Component {
    constructor() {
        super();
        this.state = {};
    }

    componentDidMount() {
        fetch('/api/crons', {
            method: 'get',
            credentials: 'include'
        }).then((response) => {
            if (!response.ok) {
                return;
            }

            response.json()
            .then((crons) => {
                this.props.dispatch(updateCronsListAction(crons));
            });
        });
    }

    render() {
       return this.state.cronId ? this.renderJobs() : this.renderList();
    }

    viewJobs(cronId) {
        this.setState({...this.state, ...{cronId, jobs: []}});
        fetch('/api/crons/jobs?cron_id=' + cronId, {
            method: 'get',
            credentials: 'include',
            headers: {'content-type': 'application/json'},
            qs: {id: cronId}
        }).then((response) => {
            if (response.ok) {
                response.json()
                .then((jobs) => {
                    this.setState({...this.state, jobs});
                });
            } else {
                this.setState({
                    ...this.state,
                    error: 'Server error',
                    isProcessing: undefined
                });
            }
        });
    }

    runCron(cronId) {
        fetch('/api/crons/run', {
            method: 'put',
            credentials: 'include',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({id: cronId})
        }).then((response) => {
            if (response.ok) {
                response.json()
                .then((updatedCron) => {
                    this.props.dispatch(updateCronAction(updatedCron));
                });
            } else {
                this.setState({
                    ...this.state,
                    error: 'Server error',
                    isProcessing: undefined
                });
            }
        });
    }

    toggleCron(cron) {
        cron.enabled = !cron.enabled;
        fetch('/api/crons', {
            method: 'put',
            credentials: 'include',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({id: cron.id, enabled: cron.enabled})
        }).then((response) => {
            if (response.ok) {
                response.json().then((updatedCron) => {
                    this.props.dispatch(updateCronAction(updatedCron));
                });
            } else {
                this.setState({
                    ...this.state,
                    error: 'Server error',
                    isProcessing: undefined
                });
            }
        });
    }

    removeJob(jobId) {
        fetch('/api/crons/jobs/remove', {
            method: 'put',
            credentials: 'include',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({id: jobId})
        }).then((response) => {
            if (response.ok) {
                response.json()
                .then(() => {
                   this.setState({...this.state, jobs: this.state.jobs.filter(j => j.id !== jobId)});
                });
            } else {
                this.setState({
                    ...this.state,
                    error: 'Server error',
                    isProcessing: undefined
                });
            }
        });
    }

    renderJobs() {
        const cron = this.props.crons[this.state.cronId] || {}; 
        return (
            <div className='adminConsole'>
                <div className='majorTitle'>Previous executions of {cron.name} </div>
                <div className='adminConsoleContent'>
                    <table className="admin-table list-crons">
                        <colgroup>
                            <col width="100px"/>
                            <col width="100px"/>
                            <col width="100px"/>
                            <col/>
                            <col width="250px"/>
                            <col width="250px"/>
                            <col width="100px"/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Code</th>
                                <th>Status</th>
                                <th>Message</th>
                                <th>Run At</th>
                                <th>Completed At</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.jobs && this.state.jobs.map((job) => {
                                return (
                                    <tr key={job.id}>
                                        <td>{job.id}</td>
                                        <td>{job.code}</td>
                                        <td>{job.error ? <span className='errorStyle'>Error</span> : job.isRunning ? 'Running...' : 'Completed'}</td>
                                        <td>{job.error || job.summary}</td>
                                        <td>{job.runAt ? moment(job.runAt).format('llll') : null}</td>
                                        <td>{job.completedAt ? moment(job.completedAt).format('llll') : null}</td>
                                        <td><button onClick={() => this.removeJob(job.id)}>Remove</button></td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </table>
                </div>
                <div className='adminButtonsDiv'>
                    <input type='button' value='Reload' className='buttonStyle regularButtonStyle' onClick={() => {
                        this.viewJobs(this.state.cronId);
                    }}/>
                    <input type='button' value='Back' className='buttonStyle regularButtonStyle' onClick={() => {
                        this.setState({cronId: null});
                    }}/>
                </div>
            </div>
        );
    }

    renderEnabledButton(cronId) {
        const cron = this.props.crons[cronId];
        return (
            <button onClick={() => {
                this.toggleCron(cron)
            }} className={classNames({
                enabled: cron.enabled,
                disabled: !cron.enabled
            })}>
                {cron.enabled ? 'Enabled' : 'Disabled'}
            </button>
        )
    }

    renderSchedule(cron) {
        if (!cron.schedule) {
            return cron.nextInvocation ? moment(cron.nextInvocation).format('llll') : 'Not Scheduled';
        } else if (cron.schedule.match(/^[0-9*]/)) {
            return (
                <div>
                    <div>{cronToString(cron.schedule)}</div>
                    <div>{moment(cron.nextInvocation).format('llll')}</div>
                </div>
            );
        } else {
            return (
                <div>
                    <div>{capitalize(lowerCase(cron.schedule.replace('-', 'minus').replace('+', 'plus')))}</div>
                    <div>{moment(cron.nextInvocation).format('llll')}</div>
                </div>
            );
        }
    }

    renderList() {
        return (
            <div className='adminConsole'>
                <div className='majorTitle'>Schedules</div>
                <div className='adminConsoleContent'>
                    <table className="admin-table list-crons">
                        <colgroup>
                            <col width="1"/>
                            <col/>
                            <col width="300px"/>
                            <col width="100px"/>
                            <col width="250px"/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Name</th>
                                <th>Schedule</th>
                                <th>Status</th>
                                <th className="center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.crons && Object.keys(this.props.crons).map((cronId) => {
                                return (
                                    <tr key={cronId}>
                                        <td>{this.props.crons[cronId].id}</td>
                                        <td>{this.props.crons[cronId].name}</td>
                                        <td>{this.renderSchedule(this.props.crons[cronId])}</td>
                                        <td>{this.renderEnabledButton(cronId)}</td>
                                        <td className="center"><button onClick={() => this.runCron(cronId)}>Run Manually</button>&nbsp;&nbsp;<button onClick={() => this.viewJobs(cronId)}>View</button></td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

function mapStoreToProps(store) {
    return {
        crons: getAllCronsFromStore(store)
    };
}

export default connect(mapStoreToProps)(AdminCronPanel);
