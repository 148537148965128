import React, {Component} from 'react';
import {connect} from 'react-redux';
import {capitalize, lowerCase} from 'lodash';
import ReactTags from 'react-tag-autocomplete';
import InfiniteScroll from 'react-infinite-scroller';

import {addToUserListAction, getAllUsersFromStore, updateUserListAction, clearAllUsersAction} from '../reducers/allUsersReducer';
import SingleInput from './SingleInput';
import LoadingBar from './LoadingBar';
import {BardUser, UserTag} from '../../common/clientServer/bardUser';
import {parseBardUserFilterString, userMatchesFilter} from '../../common/filter/bardUserFilter';
import FilterSuggestionField from './FilterSuggestionField';

import 'react-tag-autocomplete/example/styles.css'
import DispatchProp from '../../@types/dispatchProp';
import {StoreWithSharedState} from '../../common/reducers/sharedStateReducer';
import RecentActivity from './RecentActivity';

interface AdminUserPanelOwnProps {
    userIdToEdit?: number;
    finishedEditingUser?: () => void;
}

interface AdminUserPanelStoreProps {
    allUsers: {[id: string]: BardUser};
}

type AdminUserPanelProps = AdminUserPanelOwnProps & AdminUserPanelStoreProps & DispatchProp;

interface AdminUserPanelState {
    userSelected?: string;
    editUser?: BardUser;
    activityUserId?: string;
    loginPasswordConfirm?: string;
    error?: string;
    hasMore?: boolean;
    importCSV?: boolean;
    importStatus?: string;
    exportFields?: {[key: string]: boolean};
    userFilter?: string;
    activityFilter?: string;
}

class AdminUserPanel extends Component<AdminUserPanelProps, AdminUserPanelState> {

    PageSize = 20;

    UserFields = ['loginName', 'emailAddress', 'tags', 'title', 'givenName', 'familyName', 'postalAddress', 'phoneMobile', 'phoneLandline'];

    static getDerivedStateFromProps(props: AdminUserPanelProps, state: AdminUserPanelState) {
        if (props.userIdToEdit && !state.editUser && props.allUsers[props.userIdToEdit]) {
            return {editUser: {...props.allUsers[props.userIdToEdit], loginPassword: ''}}
        }
        return null;
    }

    constructor(props: AdminUserPanelProps) {
        super(props);
        this.fetchMoreUsers = this.fetchMoreUsers.bind(this);
        this.state = {
            hasMore: true
        };
    }

    renderBadges({admin, tags}: BardUser) {
        return (
            <div className='badges'>
                {admin ? <span>Admin</span> : null}
                {(tags || []).map(({name}) => (<span key={name} className='tag'>{name}</span>))}
            </div>
        )
    }

    fetchMoreUsers(page: number) {
        const numUsers = this.props.allUsers ? Object.keys(this.props.allUsers).length : 0;
        if (numUsers < page * this.PageSize) {
            this.setState({hasMore: false});
            return;
        }
        const query = page < 0 ? '' : `?page=${page}&pageSize=${this.PageSize}`;
        fetch(`/api/user${query}`, {
            method: 'get',
            credentials: 'include'
        }).then((response) => {
            return response.ok ? response.json()
                .then((allUsers) => {
                    this.props.dispatch(updateUserListAction(allUsers));
                }) : undefined;
        });
    }

    renderExportFieldsCheckboxes(fields: string[]) {
        return fields.map((name) => {
            return <SingleInput key={name} inputType='checkbox' title={name}
                                content={(this.state.exportFields && this.state.exportFields[name]) || false}
                                onChange={(value: boolean) => {
                                    this.setState({exportFields: {...this.state.exportFields, [name]: value}});
                                }}/>
        });
    }

    renderExportCSV() {
        const filter = parseBardUserFilterString(this.state.userFilter || '');
        const filteredUserIds = Object.keys(this.props.allUsers).filter((userId) => (userMatchesFilter(this.props.allUsers[userId], filter)));
        return (
            <div className='adminConsole'>
                <div className='majorTitle'>Export Users to CSV file</div>
                <div className='adminConsoleContent'>
                    <p>Select fields to export.</p>
                    {this.renderExportFieldsCheckboxes(this.UserFields)}
                    <p>Filter users - only users who match the below filter will be exported.</p>
                    <FilterSuggestionField
                        type='user'
                        filter={this.state.userFilter}
                        onChange={(userFilter) => {this.setState({userFilter})}}
                    />
                </div>
                <div className='adminButtonsDiv'>
                    <input type='button'
                           value={`Export ${filteredUserIds.length} user${filteredUserIds.length === 1 ? '' : 's'}`}
                           className='buttonStyle regularButtonStyle' onClick={() => {
                        const params = {
                            fields: Object.keys(this.state.exportFields!).join(','),
                            filter: this.state.userFilter || ''
                        };
                        const queryString = `/api/user/export?${Object.keys(params).map((key) => (`${key}=${(params[key])}`)).join('&')}`;
                        window.open(queryString);
                    }}/>
                    <input type='button' value='Done' className='buttonStyle regularButtonStyle' onClick={() => {
                        this.setState({exportFields: undefined});
                    }}/>
                </div>
            </div>
        );
    }

    renderImportCSV() {
        return (
            <div className='adminConsole'>
                <div className='majorTitle'>Import Users from CSV file</div>
                <div className='adminConsoleContent'>
                    <p>
                        Upload a CSV file with user details to automatically import or update users in the system. The
                        first row of the CSV must contain field names, including any or all of the names listed below.
                        At a minimum, the records must contain a loginName or emailAddress.
                    </p>
                    <p>{this.UserFields.join(',')},loginPassword</p>
                    <input type='file' name='file' onChange={(evt) => {
                        const target = evt.target;
                        if (target.files && target.files.length > 0) {
                            const fileReader = new FileReader();
                            fileReader.onloadend = (() => {
                                fetch('/api/user/import', {
                                    method: 'post',
                                    credentials: 'include',
                                    body: fileReader.result
                                }).then((response) => {
                                    if (!response.ok) {
                                        response.text().then((text) => {
                                            this.setState({importStatus: text})
                                        });
                                    } else {
                                        this.setState({importStatus: 'CSV file successfully uploaded to server, users updated.'});
                                        this.props.dispatch(clearAllUsersAction());
                                        this.fetchMoreUsers(-1);
                                    }
                                });
                            });
                            fileReader.readAsText(target.files[0]);
                        }
                    }}/>
                    {this.state.importStatus ? (
                        <p>{this.state.importStatus}</p>
                    ) : null}
                </div>
                <div className='adminButtonsDiv'>
                    <input type='button' value='Done' className='buttonStyle regularButtonStyle' onClick={() => {
                        this.setState({importCSV: false});
                    }}/>
                </div>
            </div>
        );
    }

    getFilteredUsers() {
        if (!this.state.userFilter) {
            return Object.keys(this.props.allUsers);
        }
        const filter = parseBardUserFilterString(this.state.userFilter);
        return Object.keys(this.props.allUsers)
            .filter((userId) => (userMatchesFilter(this.props.allUsers[userId], filter)));
    }

    renderAllUsers() {
        return (
            <div className='adminConsole'>
                <div className='majorTitle'>Users</div>
                <p>Select a user to edit below, or click 'New User' to create a new one.</p>
                <div className='adminConsoleContent'>
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={this.fetchMoreUsers}
                        hasMore={this.state.hasMore}
                        loader={<LoadingBar key={0} id='fetchUsersPending'/>}
                        useWindow={false}
                    >
                        {
                            this.props.allUsers && this.getFilteredUsers().map((userId) => {
                                return (
                                    <div className='adminTableRow' key={userId}>
                                        <label>
                                            <div className='adminTableRadioButton'>
                                                <input type='radio' name='title1' value={userId}
                                                       onClick={(evt: any) => {
                                                           this.setState({userSelected: evt.target.value});
                                                       }}/>
                                            </div>
                                            <div className='adminTableTitle'>
                                                <div><b>User:</b>
                                                    <span
                                                        className='mediumTextFont'>{this.props.allUsers[userId].loginName}</span>
                                                    {this.renderBadges(this.props.allUsers[userId])}
                                                </div>
                                            </div>
                                            <hr/>
                                        </label>
                                    </div>
                                );
                            })
                        }
                    </InfiniteScroll>
                </div>
                <div className='adminButtonsDiv'>
                    <FilterSuggestionField
                        type='user'
                        className='filterInput'
                        placeholder='Filter users'
                        filter={this.state.userFilter}
                        onChange={(userFilter) => {this.setState({userFilter})}}
                    />
                    <input type='button' value='New User' className='buttonStyle regularButtonStyle' onClick={() => {
                        this.setState({
                            editUser: {
                                id: 0,
                                admin: false,
                                loginName: 'newUser',
                                loginPassword: '',
                                title: '',
                                givenName: '',
                                familyName: '',
                                emailAddress: '',
                                postalAddress: '',
                                phoneMobile: '',
                                phoneLandline: '',
                                status: 0
                            },
                            userSelected: undefined
                        });

                    }}/>
                    <input type='button' value='Export Users...' className='buttonStyle regularButtonStyle'
                           onClick={() => {
                               this.setState({exportFields: {}})
                           }}/>
                    <input type='button' value='Import Users...' className='buttonStyle regularButtonStyle'
                           onClick={() => {
                               this.setState({importCSV: true, importStatus: undefined})
                           }}/>
                    <input type='button' value='Edit User' className='buttonStyle regularButtonStyle'
                           disabled={!this.state.userSelected} onClick={() => {
                        this.setState({
                            editUser: {...this.props.allUsers[this.state.userSelected!], loginPassword: ''},
                            userSelected: undefined
                        });
                    }}/>
                    <input type='button' value='View User Activity' className='buttonStyle regularButtonStyle'
                           disabled={!this.state.userSelected} onClick={() => {
                        this.setState({activityUserId: this.state.userSelected});
                    }}/>
                </div>
            </div>
        );
    }

    renderEditUser() {
        const editUser = this.state.editUser!;
        // It's possible that we're waiting on the user to load
        return (!editUser.loginName) ? 'Loading...' : (
            <div className='adminConsole'>
                <p>For existing users, you can leave their password blank to leave it unchanged.</p>
                <div className='errorStyle'>{this.state.error}</div>
                <div className='adminConsoleContent'>
                    <div className='inputSet'>
                        <SingleInput inputType='text' size={60} title='Login name:' onChange={(value: string) => {
                            this.setState({editUser: {...editUser, loginName: value}});
                        }} content={editUser.loginName || ''}/>
                        <div className='cf'>
                            <span>Tags:</span>
                            <div className='inputField tagsField'>
                                <ReactTags
                                    tags={editUser.tags || []}
                                    allowNew={true}
                                    handleAddition={(tag: UserTag) => {
                                        const tags = [...(editUser.tags || []), tag];
                                        this.setState({editUser: {...editUser, tags}})
                                    }}
                                    handleDelete={(index) => {
                                        const tags = (editUser.tags || []).slice(0);
                                        tags.splice(index, 1);
                                        this.setState({editUser: {...editUser, tags}});
                                    }}
                                />
                            </div>
                        </div>
                        <SingleInput inputType='password' size={60} title='Password:' onChange={(value: string) => {
                            this.setState({editUser: {...editUser, loginPassword: value}});
                        }} content={editUser.loginPassword || ''}/>
                        <SingleInput inputType='password' size={60} title='Confirm:' onChange={(value: string) => {
                            this.setState({loginPasswordConfirm: value});
                        }} content={this.state.loginPasswordConfirm || ''}/>
                        <SingleInput inputType='checkbox' title='Administrator privileges?' onChange={(value: boolean) => {
                            this.setState({editUser: {...editUser, admin: value}});
                        }} content={editUser.admin}/>

                        {['title', 'givenName', 'familyName', 'emailAddress', 'postalAddress', 'phoneMobile', 'phoneLandline'].map((field) => (
                            <SingleInput key={field} inputType='text' size={60} title={capitalize(lowerCase(field))}
                                         onChange={(value) => {
                                             this.setState({editUser: {...editUser, [field]: value}});
                                         }} content={editUser[field] || ''}/>
                        ))}
                    </div>
                </div>
                <div className='adminButtonsDiv'>
                    <input type='button' value='Cancel' className='buttonStyle regularButtonStyle' onClick={() => {
                        this.setState({editUser: undefined, error: undefined});
                        if (this.props.finishedEditingUser) {
                            this.props.finishedEditingUser();
                        }
                    }}/>
                    <input type='button' value='Save' className='buttonStyle regularButtonStyle' onClick={() => {
                        this.setState({error: undefined});
                        if (this.validUser()) {
                            fetch('/api/user', {
                                method: 'put',
                                credentials: 'include',
                                headers: {'content-type': 'application/json'},
                                body: JSON.stringify(editUser)
                            }).then((response) => {
                                if (response.ok) {
                                    response.json().then((json) => {
                                        if (json.error) {
                                            this.setState({error: json.error});
                                        } else {
                                            this.props.dispatch(addToUserListAction(json));
                                            this.setState({editUser: undefined});
                                            if (this.props.finishedEditingUser) {
                                                this.props.finishedEditingUser();
                                            }
                                        }
                                    });
                                } else {
                                    this.setState({error: 'Server error'});
                                }
                            });
                        }
                    }}/>
                </div>
            </div>
        );
    }

    validUser() {
        let user = this.state.editUser!;
        if (!user['id'] && !user.loginPassword) {
            this.setState({ error: 'A newly created user must have a password.'});
            return false;
        }
        if (user.loginPassword && user.loginPassword !== this.state.loginPasswordConfirm) {
            this.setState({ error: 'Password and confirm password do not match.'});
            return false;
        }
        return ['loginName', 'emailAddress'].reduce((valid, field) => {
            if (!user[field]) {
                this.setState((prevState) => {
                    if (prevState.error) {
                        return {error: prevState.error + ', ' + capitalize(lowerCase(field))}
                    } else {
                        return {error: 'The following mandatory fields require values: ' + capitalize(lowerCase(field))}
                    }
                });
                return false;
            }
            return valid;
        }, true);
    }

    renderUserActivity() {
        let recentActivity;
        return (
            <div className='adminConsole'>
                <div className='majorTitle'>Activity for user {this.props.allUsers[this.state.activityUserId!].loginName}</div>
                <div className='adminConsoleContent'>
                    <RecentActivity userId={this.state.activityUserId}
                                    onRef={(child) => {recentActivity = child}}
                                    filterString={this.state.activityFilter}
                    />
                </div>
                <div className='adminButtonsDiv'>
                    <SingleInput className='filterInput' inputType='text' size={60} placeholder='Filter activity'
                                 content={this.state.activityFilter || ''}
                                 onChange={(activityFilter: string) => {
                                     this.setState({activityFilter})
                                 }}/>
                    <input type='button' value='Refresh' className='buttonStyle regularButtonStyle'
                           onClick={() => (recentActivity && recentActivity.triggerFetchActivities(true))}
                    />
                    <input type='button' value='Done' className='buttonStyle regularButtonStyle'
                           onClick={() => {this.setState({activityUserId: undefined})}}
                    />
                </div>
            </div>
        );
    }

    render() {
        if (this.state.editUser) {
            return this.renderEditUser();
        } else if (this.state.exportFields !== undefined) {
            return this.renderExportCSV();
        } else if (this.state.importCSV) {
            return this.renderImportCSV();
        } else if (this.state.activityUserId) {
            return this.renderUserActivity();
        } else {
            return this.renderAllUsers();
        }
    }

}

function mapStoreToProps(store: StoreWithSharedState): AdminUserPanelStoreProps {
    return {
        allUsers: getAllUsersFromStore(store)
    };
}

export default connect(mapStoreToProps)(AdminUserPanel);