import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, List, ListItemControl} from 'react-md';
import {slice} from 'lodash';

import {commonApiExceptionHandled, handleErrors} from '../util/ajaxUtils';
import {getFormattedMessageTimeStamp} from '../util/timeUtils';
import {buildUserRoleClass} from '../util/userStyle';
import {getLoggedInUserIdFromStore} from '../reducers/loginReducer';
import {SingleProblemStepType} from '../../common/reducers/allTeamsReducer';
import LoadingBar from './LoadingBar';
import {
    appendRecentActivityAction,
    fetchRecentActivityErroredAction,
    getRecentActivityFromStore,
    initFetchRecentActivityAction,
    RecentActivityReducerType
} from '../reducers/recentActivityReducer';
import ReferenceData from '../../common/util/referenceData';
import {
    AllUsersForTeamReducerType,
    SingleUserForProblemReducerType
} from '../../common/reducers/allUsersForTeamReducer';
import {getDiscussionForProblemFromStore, TopicsForProblemState} from '../../common/reducers/discussionsReducer';
import DispatchProp from '../../@types/dispatchProp';
import {
    buildRestPath,
    GET_PROBLEM_ACTIVITY_ENDPOINT,
    GET_USER_ACTIVITY_ENDPOINT
} from '../../common/clientServer/navigation';
import {getProblemStepFromStore} from '../../common/reducers/allTeamsReducerGetters';
import {
    getAllUsersForProblemFromStore,
    getUserForProblemFromStore
} from '../../common/reducers/allUsersForTeamReducerGetters';
import {UserActivityResponse} from '../../common/clientServer/userActivity';

interface RecentActivityOwnProps {
    problemStepId?: number;
    userId?: number;
    limit?: number;
    onRef: (self?: RecentActivity) => void;
    filterString?: string
}

interface RecentActivityStoreProps {
    loggedInUserId: number;
    currentUser?: SingleUserForProblemReducerType;
    allUsersForProblem?: AllUsersForTeamReducerType;
    referenceData: ReferenceData;
    problemStep?: SingleProblemStepType;
    discussionTopics?: TopicsForProblemState;
    recentActivities?: RecentActivityReducerType;
}

type RecentActivityProps = RecentActivityOwnProps & DispatchProp & RecentActivityStoreProps;

class RecentActivity extends Component<RecentActivityProps> {

    private scrollingDiv;

    constructor(props) {
        super(props);
        this.state = {};
        this.renderItem = this.renderItem.bind(this);
        this.handleScroll = this.handleScroll.bind(this);
        this.triggerFetchActivities = this.triggerFetchActivities.bind(this);
        this.fetchLatestActivities = this.fetchLatestActivities.bind(this);
    }

    componentDidMount() {
        if (this.props.onRef) {
            this.props.onRef(this);
        }
        this.fetchLatestActivities(this.props.problemStepId, this.props.userId, 0, this.props.limit);
    }

    componentWillUnmount() {
        if (this.props.onRef) {
            this.props.onRef(undefined);
        }
    }

    componentDidUpdate(): void {
        // Check if we need to fetch more.
        this.handleScroll();
    }

    UNSAFE_componentWillReceiveProps(props) {
        if (this.props.problemStepId !== props.problemStepId || this.props.userId !== props.userId || this.props.limit !== props.limit) {
            this.fetchLatestActivities(props.problemStepId, props.userId, 0, props.limit);
        }
    }

    fetchLatestActivities(problemStepId, userId, offset = 0, limit = 10) {
        this.props.dispatch(initFetchRecentActivityAction(problemStepId, userId));
        const url = (problemStepId)
            ? buildRestPath(GET_PROBLEM_ACTIVITY_ENDPOINT, {problemStepId}) :
            buildRestPath(GET_USER_ACTIVITY_ENDPOINT, {userId});
        return fetch(url + `?offset=${offset}&limit=${limit}`, {
            credentials: 'include',
            method: 'GET'
        })
            .then(handleErrors)
            .then((response) => {
                return response.json().then((body: UserActivityResponse) => {
                    if (problemStepId === this.props.problemStepId && userId === this.props.userId) {
                        this.props.dispatch(appendRecentActivityAction(problemStepId, userId, body));
                    }
                });
            })
            .catch((err) => {
                let errorCode = Number(err.message);
                if (!commonApiExceptionHandled(errorCode, this.props.dispatch)) {
                    this.props.dispatch(fetchRecentActivityErroredAction(problemStepId, userId));
                }
            });
    }

    handleScroll() {
        if (this.scrollingDiv) {
            let scrollHeight = this.scrollingDiv.scrollHeight;
            let offsetHeight = this.scrollingDiv.offsetHeight;
            let scrollTop = this.scrollingDiv.scrollTop;
            let isNearlyAtBottom = (scrollHeight - offsetHeight - scrollTop) < 50;

            if (isNearlyAtBottom) {
                this.triggerFetchActivities();
            }
        }
    }

    public triggerFetchActivities(force = false) {
        if (!this.props.recentActivities || ((force || !this.props.recentActivities.hasFetchedAllRecord) && !this.props.recentActivities.isFetchingRecord)) {
            this.fetchLatestActivities(this.props.problemStepId, this.props.userId,
                force || !this.props.recentActivities ? 0 : this.props.recentActivities.currentOffset, this.props.limit);
        }
    }

    renderItem(activity, key) {
        let formattedTimestamp = getFormattedMessageTimeStamp(activity.metadata.createdAt);
        let creatorRoleClass = buildUserRoleClass(activity.metadata.creatorRole, this.props.loggedInUserId, activity.metadata.createdBy);
        let timestampClass = `activityTimeStamp ${creatorRoleClass} override roleBackgroundColour`;
        return <ListItemControl className="recentActivityItem"
            key={key}
            leftAvatar={<div className={timestampClass}><span>{formattedTimestamp}</span></div>}
            primaryText=""
            primaryAction={
                <div>
                    <div className="primary" dangerouslySetInnerHTML={{__html: activity.message.primary}}/>
                    <div className="secondary">{activity.message.secondary}</div>
                </div>
            }
            />
    }

    getActivities() {
        if (!this.props.recentActivities) {
            return [];
        } else {
            const filtered = this.props.recentActivities.activities
                .filter((activity) => (!this.props.filterString
                    || activity.message.primary.toLowerCase().indexOf(this.props.filterString.toLowerCase()) >= 0
                    || activity.message.secondary.toLowerCase().indexOf(this.props.filterString.toLowerCase()) >= 0));
            return this.props.limit ? slice(filtered, 0, this.props.limit) : filtered;

        }
    }

    render() {
        if (this.props.recentActivities === undefined) {
            return (
                <div>
                    <LoadingBar />
                </div>
            );
        }

        let numActivities = this.props.recentActivities.activities.length;

        return (
            <div className='recentActivityDiv' onScroll={this.handleScroll} ref={(div) => {this.scrollingDiv = div}}>
                {
                    (numActivities === 0 && !this.props.recentActivities.isFetchingRecord) ? (
                        this.props.problemStepId ? "No activity on this problem yet." : "No activity by this user yet."
                    ) : (
                        <List className="md-list-unstyled recentActivityList md-list-generic">
                            {this.getActivities().map(this.renderItem)}
                        </List>
                    )
                }
                {
                    (this.props.recentActivities.isFetchingRecord && (this.props.limit === undefined || numActivities < this.props.limit)) ? (
                        <LoadingBar />
                    ) : null
                }
                {
                    (this.props.limit || this.props.recentActivities.hasFetchedAllRecord) ? (
                        null
                    ) : (
                        <Button className="secondaryActionButton" flat={true} onClick={() => {this.triggerFetchActivities()}}>SHOW MORE</Button>
                    )
                }
            </div>
        );
    }

}

function mapStoreToProps(store, myProps): RecentActivityStoreProps {
    const problemStepId = myProps.problemStepId;
    const users = problemStepId ? getAllUsersForProblemFromStore(store, problemStepId) : undefined;
    const loggedInUserId = getLoggedInUserIdFromStore(store);
    const currentUser = problemStepId ? getUserForProblemFromStore(store, problemStepId, loggedInUserId) : undefined;
    const discussion = problemStepId ? getDiscussionForProblemFromStore(store, problemStepId) : undefined;
    return {
        loggedInUserId,
        currentUser,
        allUsersForProblem: users,
        referenceData: ReferenceData.getInstance(),
        problemStep: problemStepId ? getProblemStepFromStore(store, problemStepId) : undefined,
        discussionTopics: discussion ? discussion.topics : undefined,
        recentActivities: getRecentActivityFromStore(store, problemStepId, myProps.userId)
    }
}

export default connect(mapStoreToProps)(RecentActivity);
