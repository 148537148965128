import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { CircularProgress } from 'react-md';

import '../scss/loadingBar.scss';

class LoadingBar extends Component {
    static propTypes = {
        id: PropTypes.string.isRequired
    };

    static defaultProps = {
        id: 'loading-progress'
    };

    render() {
        return (<CircularProgress id={this.props.id} />);
    }
}

export default LoadingBar;
