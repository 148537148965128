import * as constants from './constants';
import {getProblemDBFromStore} from '../reducers/allProblemsReducer';
import { ProblemPojo } from '../../server/orm/dao/problemDAO';
import {doesTeamExistInStore, getProblemStepFromStore} from '../reducers/allTeamsReducerGetters';

export function getProblemType(problem: ProblemPojo): number {
    if (problem.isTraining) {
        return constants.PROBLEM_TRAINING;
    } else {
        return constants.PROBLEM_INTERNAL_BARD;
    }
}

export function getProblemStepProblemType(storeState, problemStepId) {
    const problemStep = doesTeamExistInStore(storeState, problemStepId) && getProblemStepFromStore(storeState, problemStepId);
    if (problemStep) {
        const problem = getProblemDBFromStore(storeState, problemStep.problemId);
        return getProblemType(problem);
    }
    return null;
}