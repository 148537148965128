import React, {Component} from 'react';
import * as PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {ExpansionList} from 'react-md';
import moment from 'moment';

import Discussion from '../presentation/Discussion';
import {getLoggedInUserIdFromStore} from '../reducers/loginReducer';
import ReferenceData from '../../common/util/referenceData';
import {
    discussPostTopicAction,
    getDiscussionForProblemFromStore,
    getDiscussionTopicsForProblemAndStepFromStore
} from '../../common/reducers/discussionsReducer';
import SingleInput from '../presentation/SingleInput';
import {getChatsForProblemAndUserFromStore} from '../../common/reducers/chatsReducer';
import * as constants from '../../common/util/constants';
import {getCurrentRound} from '../../common/util/roundLogic';
import TipsComponent from '../presentation/TipsComponent';
import * as navigation from '../../common/clientServer/navigation';
import LoadingBar from '../presentation/LoadingBar';
import {addToastMessageAction} from '../reducers/snackbarToastReducer';
import {getProblemStepFromStore} from "../../common/reducers/allTeamsReducerGetters";
import {
    getAllUsersForProblemFromStore,
    getUserForProblemFromStore
} from "../../common/reducers/allUsersForTeamReducerGetters";

import '../scss/app.scss';
import '../scss/react-md.scss';
import '../scss/discussion.scss';

class DiscussionContainer extends Component {

    static propTypes = {
        problemStepId: PropTypes.number.isRequired,
        stepId: PropTypes.number.isRequired,
        readOnly: PropTypes.bool
    };

    constructor(props) {
        super(props);
        this.state = {
            newTopicOpen: false,
            fetchingTopicId: false
        };
        this.onNewTopic = this.onNewTopic.bind(this);
    }

    async onNewTopic() {
        if (this.state.topicTitle) {
            this.setState({
                fetchingTopicId: true
            });
            const response = await fetch(navigation.CREATE_DISCUSSION_TOPIC, {
                method: 'post',
                credentials: 'include',
                headers: {'content-type': 'application/json'},
                body: JSON.stringify({
                    problemStepId: this.props.problemStepId,
                    stepId: this.props.stepId,
                    topicTitle: this.state.topicTitle
                })
            });
            if (response.ok) {
                const topicId = await response.json();
                const topicTitle = this.state.topicTitle;
                this.setState({
                    fetchingTopicId: false,
                    topicTitle: '',
                    newTopicOpen: false,
                    createdNewTopic: true
                }, () => {
                    this.props.dispatch(discussPostTopicAction(this.props.problemStepId, topicId, this.props.stepId, this.props.userId, this.props.user.db, topicTitle));
                });
            } else {
                this.setState({
                    fetchingTopicId: false
                });
                this.props.dispatch(addToastMessageAction('There was a server error creating your topic - please try again later.'));
            }
        }
    }

    newTopicUI() {
        if (this.state.newTopicOpen) {
            return (
                <div className="discussionTopicDiv">
                    <div>New Topic</div>
                    <div>
                        <SingleInput className='newDiscussionInput' content={this.state.topicTitle || ''}
                                     inputType='text' placeholder='Describe the topic' focus
                                     onChange={(value) => (this.setState({topicTitle: value}))} />
                    </div>
                    <div className="buttonBar">
                        <input type="button" value="Cancel" disabled={this.state.fetchingTopicId} onClick={() => {this.setState({topicTitle: '', newTopicOpen: false})}}
                               className='md-btn--text md-pointer--hover buttonStyle linkButtonStyle regularButtonStyle'
                        />
                        {
                            this.state.fetchingTopicId ? <LoadingBar id='fetchingTopicId'/> : (
                                <input type="button" value="Create" disabled={!this.state.topicTitle} onClick={this.onNewTopic}
                                       className='md-btn--raised md-btn--text md-pointer--hover mdActionButton buttonStyle regularButtonStyle roleBackgroundColour'
                                />
                            )
                        }
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    
    getUserDisplayNameForProblem(props, userId) {
        if (props.usersForProblem[userId]) {
            return props.usersForProblem[userId].db.displayName + (userId === props.userId ? ' (Me)' : '');
        } else {
            return constants.DISPLAY_REMOVED_USER;
        }
    }

    render() {

        return (
            <div className='myWorkspaceStyle'>
                <div className = "threeColumnLayout">
                    <div className = "workspaceLeftColumn">
                        <TipsComponent step={this.props.stepId} side={constants.TIP_SCREEN_LEFT} status={constants.HELP_SUBSTEP_DISCUSSION} problemStep={this.props.problemStep} userProblem={this.props.user.db}/>
                    </div>
                    <div className="workspaceContentColumn">
                        <div className="discussionDiv">
                            
                            {
                                this.props.readOnly ? null : (
                                    <div className={"discussionStartNewTopic" + ((this.props.user.db.role === constants.ROLE_OBSERVER) ? " disabled" : "")} onClick={() => {
                                        this.setState({newTopicOpen: (this.props.user.db.role !== constants.ROLE_OBSERVER)});
                                    }}>+ START A NEW TOPIC</div>
                                )
                            }
                            {this.newTopicUI()}
                            <div className='topicsDiv'>
                                <ExpansionList>
                                {
                                    this.props.discussionTopics && Object.keys(this.props.discussionTopics)
                                        .sort((t1, t2) => {
                                            const comments = this.props.discussionComments;
                                            const lastPostedComment1 = comments[t1] ? comments[t1].reduce((last, comment) => (comment.draft ? last : comment), {}) : {};
                                            const lastPostedComment2 = comments[t2] ? comments[t2].reduce((last, comment) => (comment.draft ? last : comment), {}) : {};
                                            const time1 = moment(lastPostedComment1.created || this.props.discussionTopics[t1].created);
                                            const time2 = moment(lastPostedComment2.created || this.props.discussionTopics[t2].created);
                                            return time1.isSame(time2) ? 0 : time1.isBefore(time2) ? 1 : -1;
                                        })
                                        .map((topicId) => (
                                            <Discussion dispatch={this.props.dispatch}
                                                topicId={topicId} key={topicId}
                                                defaultExpanded={this.state.createdNewTopic}
                                                userId={this.props.userId}
                                                userProblem={this.props.user}
                                                problemStepId={this.props.problemStepId} stepId={this.props.stepId}
                                                topic={this.props.discussionTopics[topicId]}
                                                discussionComments={this.props.discussionComments[topicId]}
                                                usersForProblem={this.props.usersForProblem}
                                                userRole={this.props.user.db.role}
                                                chatsForProblemAndUser={this.props.chatsForProblemAndUser}
                                                readOnly={this.props.readOnly}
                                                visible={this.props.discussionTopics[topicId].visible}
                                            />
                                    ))
                                }
                                
                                </ExpansionList>
                                <div>&nbsp;</div>
                            </div>
                        </div>
                    </div>
                    <div className = "workspaceRightColumn">
                        <TipsComponent step={this.props.stepId} side={constants.TIP_SCREEN_RIGHT} status={constants.HELP_SUBSTEP_DISCUSSION} problemStep={this.props.problemStep} userProblem={this.props.user.db}/>
                    </div>
                </div>
            </div>
        );
    }
}

function isReadOnly(stepId, user, problemStep) {
    return (user.db.role !== constants.ROLE_FACILITATOR && getCurrentRound(stepId, problemStep) === constants.ROUND_DELPHI_EXCHANGE);
}

function mapStoreToProps(store, myProps) {
    const problemStepId = myProps.problemStepId;
    const problemStep = getProblemStepFromStore(store, problemStepId);
    const userId = getLoggedInUserIdFromStore(store);
    const user = getUserForProblemFromStore(store, problemStepId, userId);
    const readOnly = isReadOnly(myProps.stepId, user, problemStep);
    return {
        userId,
        discussionTopics: getDiscussionTopicsForProblemAndStepFromStore(store, problemStepId, myProps.stepId),
        discussionComments: getDiscussionForProblemFromStore(store, problemStepId).comments,
        user: user,
        usersForProblem: getAllUsersForProblemFromStore(store, problemStepId),
        steps: ReferenceData.getInstance().getAllStepDescriptions(),
        chatsForProblemAndUser: getChatsForProblemAndUserFromStore(store, String(problemStepId), String(userId)),
        problemStep: problemStep,
        readOnly: myProps.readOnly || readOnly
    }
}

export default connect(mapStoreToProps)(DiscussionContainer);
