import {timestampedReducer, TimestampedState} from '../util/genericReducers';
import DataStatusEnum from '../DataStatusEnum';
import ensureFieldPath from '../ensureFieldPath';
import * as constants from '../util/constants';
import { Reducer } from 'redux';
import {StoreWithSharedState} from './sharedStateReducer';
import {AllTeamsReducerType} from './allTeamsReducer';

// ======== Constants
export const UPDATE_REPORT = 'update_report';
export const UPDATE_REPORT_SECTION = 'update-report-section';
export const REPORT_FINISHED = 'report_finished';
export const REPORT_ERROR = 'report_error';
export const SUBMIT_REPORT = 'submit_report';
export const PREPEND_REPORT = 'prepend_report';
export const SET_REPORT_TEMPLATE = 'set-report-template';


// ======== Reducers

function prependText(newText, existingText) {
    if (newText && existingText) {
        return newText + '\n______________________________\n' + existingText;
    } else {
        return newText || existingText || '';
    }
}

export interface ReportState extends TimestampedState {
    template?: number;
    text?: string;
    section?: {[key: string]: string};
    onRevert?: boolean;
    error?: any;
    buildingReport?: boolean;
}

export const initialReportState: ReportState = {};

const singleReportReducer: Reducer<ReportState> = (state = initialReportState, action) => {
    switch (action.type) {
        case constants.COPY_PUBLISHED_ACTION:
            return action.fromUser.report ? {...action.fromUser.report} : state;
        case constants.APPEND_PUBLISHED_ACTION:
            if (action.fromUser.report) {
                if (action.fromUser.report.text) {
                    return {
                        ...state,
                        template: action.fromUser.report.template,
                        text: prependText(action.fromUser.report.text, state.text)
                    };
                } else if (action.fromUser.report.section) {
                    // Prepend all sections
                    return {
                        ...state,
                        template: action.fromUser.report.template,
                        section: Object.keys(action.fromUser.report.section).reduce((all, sectionId) => {
                            all[sectionId] = prependText(action.fromUser.report.section[sectionId], (state.section || {})[sectionId]);
                            return all;
                        }, {...state.section})
                    };
                }
            }
            return state;
        case UPDATE_REPORT_SECTION:
            return {...state, section: {...state.section, [action.section]: action.text}, text: undefined};
        case PREPEND_REPORT:
            if (action.sectionId && action.template) {
                return {
                    ...state,
                    section: {...state.section, [action.sectionId]: prependText(action.text, state.section ? state.section[action.sectionId] : '')},
                    template: state.template || action.template
                }
            } else {
                return {...state, text: prependText(action.text, state.text)};
            }
        case UPDATE_REPORT:
            if (state['text'] === action.text) {
                return state;
            }
            return {...state, text: action.text, onRevert: false};
        case SET_REPORT_TEMPLATE:
            return {...state, template: action.template};
        case SUBMIT_REPORT:
            return {...state, buildingReport: true, error: null};
        case REPORT_FINISHED:
            return {...state, buildingReport: false};
        case REPORT_ERROR:
            return {...state, buildingReport: false, error: action.error};
        default:
            return state;
    }
};

export default timestampedReducer(singleReportReducer, (_oldState, _newState, action) => {
    return (action.type !== SUBMIT_REPORT && action.type !== REPORT_FINISHED && action.type !== REPORT_ERROR);
});

// ======== Service functions

export function getActivityActionTypes() {
    return [SUBMIT_REPORT];
}

// ======== DBSync functions
// (used by DBSync and for unit testing)
export function getDBSyncActionTypes() {
    return [UPDATE_REPORT, SUBMIT_REPORT, REPORT_FINISHED, REPORT_ERROR, UPDATE_REPORT_SECTION, SET_REPORT_TEMPLATE, PREPEND_REPORT];
}

export function setReportInStore(store: StoreWithSharedState, problemStepId, userId, status: DataStatusEnum, object: ReportState) {
    ensureFieldPath(store, 'sharedState', 'teams', problemStepId, 'user', userId, 'status', [status]);
    store.sharedState.teams[problemStepId].user[userId].status[status].report = object;
}

// ======== Action Generators

export function prependReportAction(problemStepId, userId, status: DataStatusEnum, text: string, sectionId?: string, template?: number) {
    return {type: PREPEND_REPORT, problemStepId, userId, status, text, sectionId, template};
}

export function updateReportAction(problemStepId, userId, status: DataStatusEnum, text) {
    return {type: UPDATE_REPORT, problemStepId, userId, status, text};
}

export function submitReportAction(problemStepId, userId, status) {
    return {type: SUBMIT_REPORT, problemStepId, userId, status};
}

export function generateReportErrorAction(problemStepId, userId, status: DataStatusEnum, error) {
    return {type: REPORT_ERROR, problemStepId, userId, status, error};
}

export function generateReportFinishedAction(problemStepId, userId, status) {
    return {type: REPORT_FINISHED, problemStepId, userId, status};
}

export function setReportTemplateAction(problemStepId: number | string, userId: number | string, status: DataStatusEnum, template: number) {
    return {type: SET_REPORT_TEMPLATE, problemStepId, userId, status, template};
}

export function updateReportSectionAction(problemStepId: number | string, userId: number | string, status: DataStatusEnum, section: string, text: string) {
    return {type: UPDATE_REPORT_SECTION, problemStepId, userId, status, section, text};
}

// ======== Getter functions
export function getReportsFromStore(store, problemStepId, userIds, status: DataStatusEnum) {
    let reports = {};
    userIds.forEach((userId) => {
        reports[userId] = getReportFromStore(store, problemStepId, userId, status);
    });
    return reports;
}

export function getReportFromStore(store: StoreWithSharedState, problemStepId: number, userId: number, status: DataStatusEnum): ReportState {
    return getReportFromProblemStep(store.sharedState.teams, problemStepId, userId, status);
}

export function getReportFromProblemStep(teams: AllTeamsReducerType, problemStepId: number, userId: number, status: DataStatusEnum): ReportState {
    return teams[problemStepId].user[userId].status[status].report;
}
