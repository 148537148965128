import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import scrollIntoView from 'scroll-iv';
import { startCase } from 'lodash';

import * as constants from '../../common/util/constants';
import { getProblemDBFromStore } from '../../common/reducers/allProblemsReducer';
import { getUserFromStore } from '../reducers/loginReducer';
import HeaderCurrentStep from './HeaderCurrentStep';
import HeaderCurrentTimer from './HeaderCurrentTimer';
import UserDetailsComponent from './UserDetailsComponent';
import ReferenceData from '../../common/util/referenceData';
import ProblemCalendar from './ProblemCalendar';
import {getTeamFromStore} from "../../common/reducers/allTeamsReducerGetters";
import {getRoleForProblemFromStore} from "../../common/reducers/allUsersForTeamReducerGetters";

class ProblemSummary extends Component {

    static propTypes = {
        teamId: PropTypes.number.isRequired,
        selectedTeamId: PropTypes.number.isRequired,
        problemState: PropTypes.number.isRequired,
        problemSelectionHandler: PropTypes.func.isRequired
    };

    constructor(props) {
        super(props);

        this.onSelectProblem = this.onSelectProblem.bind(this);
    }

    onSelectProblem(teamId) {
        this.props.problemSelectionHandler(teamId);
    }

    render() {
        const isNew = !this.props.problemIsTraining && !this.props.problemIsClosed && this.props.team.user[this.props.user.id].db.isNew && this.props.team.problemStep.state === constants.STATE_ACTIVE;

        let problemTitle = this.props.problem.title;
        if (this.props.problemState === constants.STATE_TRAINING_PROBLEM) {
            if (this.props.problemIsClosed) {
                problemTitle = 'Training - ' + problemTitle + ' - Complete';
            } else if (this.props.team.problemStep.stepId === null) {
                problemTitle = 'Training - ' + problemTitle + ' - Interactive';
            } else {
                const stepDescription = ReferenceData.getInstance().getStepDescription(this.props.team.problemStep.stepId);
                problemTitle = 'Training - ' + problemTitle + ' - ' + startCase((stepDescription.tabName || stepDescription.name).toLowerCase());
            }
        }
        return (
            <div
                className={classNames('foyerProblemSummaryDiv', {
                    foyerProblemSummarySelectedDiv: this.props.teamId === this.props.selectedTeamId
                })}
                key={this.props.teamId}
                onClick={() => this.onSelectProblem(this.props.teamId)}
                ref={(element) => {
                    if (element && this.props.teamId === this.props.selectedTeamId) {
                        scrollIntoView(element, { behavior: 'smooth', block: 'nearest' });
                    }
                }}
            >
                <div className='foyerProblemSummaryMainHeaderDiv'>
                    <div className='foyerProblemTitle'>
                        <span className="majorTitle">{problemTitle}</span>
                        {isNew ? <span className='problemIndicatorDiv'>New!</span> : null}
                    </div>
                    <ProblemCalendar side={'left'} problemStepId={this.props.teamId} />
                </div>


                <div className='foyerProblemSummaryDetailsDiv'>
                    <div>
                        <div className='miniTitle' style={{ clear: 'left' }}>SUMMARY</div>
                        <div className='textFont foyerProblemSummarySummaryDiv'>{this.props.problem.summary}</div>
                    </div>
                </div>

                <div className='foyerProblemSummaryStateDiv'>
                    <HeaderCurrentStep problemStepId={this.props.teamId} page={constants.PAGE_FOYER} />
                    <HeaderCurrentTimer problemStepId={this.props.teamId} page={constants.PAGE_FOYER} />
                    <UserDetailsComponent
                        problemStepId={this.props.teamId}
                        page={constants.PAGE_FOYER}
                        userRole={this.props.myRole}
                    />
                </div>
            </div>
        );
    }
}

function mapStoreToProps(store, myProps) {
    const teamId = myProps.teamId;
    const team = getTeamFromStore(store, teamId);
    const problem = getProblemDBFromStore(store, team.problemStep.problemId);
    const user = getUserFromStore(store);
    return {
        user,
        team,
        problem,
        problemIsClosed: team.problemStep.state === constants.STATE_CLOSED,
        problemIsTraining: problem.isTraining,
        myRole: getRoleForProblemFromStore(store, teamId, user.id),
    }
}

export default connect(mapStoreToProps)(ProblemSummary);
