import {Component} from 'react';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import {HEADER_LISTENER_ID,} from '../../common/util/constants';

class PageViewLoggingComponent extends Component {
    constructor(props) {
        super(props);
        this.state = {
            lastLocation: ''
        };
    }

    componentDidUpdate() {
        if (this.props.location.pathname === "/") {
            return;
        }

        if (this.props.location.pathname !== this.state.lastLocation) {
            fetch('/api/analytics/pageView', {
                method: 'POST',
                credentials: 'include',
                headers: {'content-type': 'application/json', [HEADER_LISTENER_ID]: this.props.store.clientState.listenerId},
                body: JSON.stringify({})
            });

            this.setState({lastLocation: this.props.location.pathname, lastStore: this.props.store});
        }
    }

    render() {
        return null;
    }
}

function mapStoreToProps(store) {
    return {store};
}

export default withRouter(connect(mapStoreToProps)(PageViewLoggingComponent));
