import React, {Component} from 'react';
import SingleInput from './SingleInput';
import moment from 'moment';

class AdminConfigPanel extends Component {
    constructor() {
        super();
        this.state = {
            configs: [],
            environmentVars: {}
        };
    }

    componentDidMount() {
        fetch('/api/configurations', {
            method: 'get',
            credentials: 'include'
        }).then((response) => {
            if (response.ok) {
                response.json()
                    .then((configs) => {
                        this.setState(() => ({configs}));
                    });
            }
        });
        fetch('/api/getenv', {
            credentials: 'include'
        }).then((response) => {
            if (response.ok) {
                response.json()
                    .then((environmentVars) => {
                        this.setState(() => ({environmentVars}));
                    });
            }
        });
    }

    edit(config) {
      config = {...config, editing: true};
      this.setState({...this.state, configs: this.state.configs.map(c => {
        if (c.key === config.key) {
          return config;
        }
        return c;
      })});
    }

    cancel(config) {
      config = {...config, editing: false};
      this.setState({...this.state, configs: this.state.configs.map(c => {
        if (c.key === config.key) {
          return config;
        }
        return c;
      })});
    }

    onValueChange(config) {
      return (e) => {
        config = {...config, value: e.target.value};
        this.setState({...this.state, configs: this.state.configs.map(c => {
          if (c.key === config.key) {
            return config;
          }
          return c;
        })});
      }
    }

    onDateValueChange(key, value) {
      value = moment(value).utc().format('x');
      const config = {...this.state.configs[key], value};
      this.setState({...this.state, configs: this.state.configs.map(c => {
        if (c.key === config.key) {
          return config;
        }
        return c;
      })});
    }

    save(config) {
      fetch('/api/configurations', {
          method: 'put',
          credentials: 'include',
          headers: {'content-type': 'application/json'},
          body: JSON.stringify({key: config.key, value: config.value})
      }).then((response) => {
          if (response.ok) {
            config = {...config, editing: false};
            this.setState({...this.state, configs: this.state.configs.map(c => {
              if (c.key === config.key) {
                return config;
              }
              return c;
            })});
          } else {
              this.setState({
                  ...this.state,
                  error: 'Server error'
              });
          }
      });


    }

    render() {
      return (
            <div className='adminConsole'>
                <div className='majorTitle'>Configurations</div>
                <div className='adminConsoleContent'>
                    <table className="admin-table list-configurations">
                        <colgroup>
                            <col width="300px"/>
                            <col/>
                            <col width="200px" />
                        </colgroup>
                        <thead>
                            <tr>
                                <th>Key</th>
                                <th>Value</th>
                                <th className="center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            this.state.configs && Object.keys(this.state.configs).map((configId) => {
                                return (
                                    <tr key={configId}>
                                        <td>{this.state.configs[configId].key}</td>
                                        <td>
                                          <div style={{display: this.state.configs[configId].editing ? 'none' : 'block'}}>    { (this.state.configs[configId].type === 'datetime' && +this.state.configs[configId].value) ? moment(+this.state.configs[configId].value).format('YYYY-MM-DD HH:mm A') : this.state.configs[configId].value}
                                          </div>
                                          <div>
                                            { 
                                              this.state.configs[configId].type === 'datetime' 
                                              ? (<SingleInput inputType='datetime-local'
                                              style={{display: this.state.configs[configId].editing ? 'block' : 'none'}} 
                                              content={+this.state.configs[configId].value && moment(+this.state.configs[configId].value).format('YYYY-MM-DDTHH:mm')}
                                              onChange={(value) => {
                                                  this.onDateValueChange(configId, value);
                                              }}/>)
                                              : (<input onChange={e => this.onValueChange(this.state.configs[configId])(e)} value={this.state.configs[configId].value} style={{display: this.state.configs[configId].editing ? 'block' : 'none'}} />)
                                            }
                                          </div>
                                        </td>
                                        <td className="center">
                                          {
                                            this.state.configs[configId].editable
                                            ? (
                                              this.state.configs[configId].editing
                                              ? (
                                                <div>
                                                <button onClick={() => this.save(this.state.configs[configId])}>Save</button>
                                                <button onClick={() => this.cancel(this.state.configs[configId])}>Cancel</button>
                                                </div>
                                              )
                                              : (
                                                <button onClick={() => this.edit(this.state.configs[configId])}>Edit</button>)
                                              )
                                            : ''
                                          }
                                        </td>
                                    </tr>
                                );
                            })
                        }
                        {
                            Object.keys({...process.env, ...this.state.environmentVars})
                                .filter((variable) => (variable.indexOf('BARD_') >= 0))
                                .sort()
                                .map((variable) => (
                                    <tr key={variable}>
                                        <td>{variable}</td>
                                        <td>{this.state.environmentVars[variable] || process.env[variable]}</td>
                                        <td>(Environment variable)</td>
                                    </tr>
                                ))
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

export default AdminConfigPanel;
