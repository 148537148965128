import React, {ChangeEvent, Component} from 'react';
import {connect} from 'react-redux';
import {indexOf, isEqual, range} from 'lodash';
import {Button, ExpansionList, ExpansionPanel, FontIcon} from 'react-md';
import classNames from 'classnames';
import memoizeOne from 'memoize-one';
import Select from 'react-select';
import neatCsv from 'neat-csv';

import ProbabilityTableComponent from '../presentation/ProbabilityTableComponent';
import {
    BayesNetParameterEnum,
    BayesNetParametersState,
    BayesNetParameterType,
    NoisyOrParameterType,
    ProbabilityDistribution,
    setBayesNetParametersForVariableAction,
    setBayesNetParametersStateAction,
    setBayesNetProbabilitiesForVariableAction
} from '../../common/reducers/bayesNetParametersReducer';
import {getLoggedInUserIdFromStore} from '../reducers/loginReducer';
import DataStatusEnum from '../../common/DataStatusEnum';
import {
    calculateCombinations,
    findSourceVariables,
    findTargetVariables,
    getBayesianModelData
} from '../../common/util/buildBayesianModelDataUtils';
import BayesNetDisplayComponent from '../presentation/BNCoLaLayout/CoLaNetworkVisComponent';
import * as constants from '../../common/util/constants';
import {getBaseBayesNetStyle, getBayesNetNodeShape} from '../util/bayesNetStyle';
import HelpTips from '../presentation/HelpTips';
import {safeBNParameters, safeOrderObject} from '../../common/util/safeOrder';
import {ObjectMapState} from '../../common/util/genericReducers';
import ConfirmationDialog from '../presentation/ConfirmationDialog';
import {StoreWithSharedState} from '../../common/reducers/sharedStateReducer';
import DispatchProp from '../../@types/dispatchProp';
import DownloadNetworkComponent from '../presentation/DownloadNetworkComponent';
import UploadNetworkComponent from './UploadNetworkComponent';
import {VariableType} from '../../common/reducers/keyVariablesReducer';
import {buildRestPath, UPLOAD_CASE_FILE_PATH} from '../../common/clientServer/navigation';
import {getUserForProblemFromStore} from '../../common/reducers/allUsersForTeamReducerGetters';

import '../scss/BayesNetParameters.scss';
import commonSelectStyles from '../util/commonSelectStyles';

interface BayesNetParametersOwnProps {
    userId?: number;
    readOnly: boolean;
    status: DataStatusEnum;
    problemStepId: number;
    buttonBarDiv: HTMLDivElement | null;
}

interface BayesNetParametersStoreProps {
    userId: number;
    migrated: boolean;
    bnParameters: BayesNetParametersState;
    userRole?: number;
}

type BayesNetParametersContainerProps = BayesNetParametersOwnProps & BayesNetParametersStoreProps & DispatchProp;

interface BayesNetParametersContainerState {
    probabilities: ObjectMapState<ProbabilityDistribution[]>;
    initialProbabilities: ObjectMapState<ProbabilityDistribution[]>;
    parameters: ObjectMapState<BayesNetParameterType>;
    initialParameters: ObjectMapState<BayesNetParameterType>;
    currentVariableId: string | null;
    numberMode: boolean;
    questionsMode: boolean;
    parametersOpen: boolean;
    dialogConfirmChangeFunction?: string;
    variableIdToReset?: string;
    showLearnParameters?: boolean;
    learnParametersError?: string;
    learnParametersCSVText?: string;
    learnParametersAlreadyDefined?: string;
}

export interface ValidityType {
    valid: boolean;
    incomplete: boolean;
    equalsOne?: boolean;
    moreThanOne?: boolean;
}

interface ParameterFunction {
    name: string;
    isApplicable: (variableId: string) => boolean;
    initialParameters: BayesNetParameterType;
    renderParameterInputs?: (variableId: string) => JSX.Element;
    generateCPTFromParameters: boolean;
}

class BayesNetParametersContainer extends Component<BayesNetParametersContainerProps, BayesNetParametersContainerState> {

    static NUM_CASES = 'NumCases';

    static defaultProps = {
        readOnly: false,
        status: DataStatusEnum.MY_DRAFT
    };

    static getDerivedStateFromProps(props: BayesNetParametersContainerProps, state: BayesNetParametersContainerState) {
        const propProbabilitiesChanged = (state.initialProbabilities !== props.bnParameters.probabilities);
        const propParametersChanged = (state.initialParameters !== props.bnParameters.parameters);
        return {
            currentVariableId: (state.currentVariableId && props.bnParameters.nodes.variable[state.currentVariableId]) ? state.currentVariableId : null,
            probabilities: propProbabilitiesChanged ? props.bnParameters.probabilities : state.probabilities,
            initialProbabilities: propProbabilitiesChanged ? props.bnParameters.probabilities : state.initialProbabilities,
            parameters: propParametersChanged ? props.bnParameters.parameters : state.parameters,
            initialParameters: propParametersChanged ? props.bnParameters.parameters : state.initialParameters
        };
    }

    private readonly parameterFunctions: {[key in BayesNetParameterEnum]: ParameterFunction} = {
        [BayesNetParameterEnum.MANUAL]: {
            name: 'Manually',
            isApplicable: () => (true),
            initialParameters: {type: BayesNetParameterEnum.MANUAL},
            generateCPTFromParameters: false
        },
        [BayesNetParameterEnum.NOISY_OR]: {
            name: 'Using Noisy OR',
            isApplicable: (variableId: string) => {
                // Noisy OR is applicable if there are at least two inputs, and the source and target variables are all boolean
                const sourceVariables = findSourceVariables(this.props.bnParameters, variableId);
                return sourceVariables.length >= 2
                    && sourceVariables.reduce((allBoolean, sourceId) => (
                        allBoolean && (this.props.bnParameters.nodes.variable[sourceId].data.type === 'Boolean')
                    ), this.props.bnParameters.nodes.variable[variableId].data.type === 'Boolean')
            },
            initialParameters: {type: BayesNetParameterEnum.NOISY_OR, probabilities: {}},
            renderParameterInputs: (variableId: string) => {
                const currentVariable = this.props.bnParameters.nodes.variable[variableId];
                const probabilitiesKeys = currentVariable.attributes.order;
                const sourceVariables = findSourceVariables(this.props.bnParameters, variableId);
                const parameters = this.state.parameters[variableId] as NoisyOrParameterType;
                return (
                    <div>
                        <div>
                            Noisy OR is a technique which allows the full conditional probability table to be calculated
                            from the probabilities of each input individually.  It requires that:
                            <ul>
                                <li>All possible causes for {currentVariable.data.name} are listed (you can add an "other causes" node).</li>
                                <li>Inputs with false values do not have any influence on {currentVariable.data.name}.</li>
                                <li>The inputs are independent.</li>
                            </ul>
                        </div>
                        <ol className='conditionCases'>
                        {
                            sourceVariables.map((sourceId: string, index: number) => {
                                const sourceVariable = this.props.bnParameters.nodes.variable[sourceId];
                                return (
                                    <React.Fragment key={`parameter_${sourceId}`}>
                                        {(index > 0) ? <div className='orDivider'><b>OR</b><hr/></div> : null}
                                        {range(sourceVariable.attributes.order.length - 1).map((index) => (
                                            <ProbabilityTableComponent
                                                mode='QLIST'
                                                key={`noisyOr_${sourceId}_${index}`}
                                                variableId={variableId}
                                                variable={currentVariable}
                                                probabilities={parameters.probabilities[sourceId] || []}
                                                probabilitiesKeys={probabilitiesKeys}
                                                validity={this.checkValidityCPTSingleRow(parameters.probabilities[sourceId], currentVariable.data.variableType === VariableType.UTILITY)}
                                                onChange={(variableId: string, permutation: number, newProbabilities: ProbabilityDistribution) => (
                                                    this.updateNoisyOrParameters(variableId, sourceId, permutation, newProbabilities)
                                                )}
                                                onCommitChange={(variableId: string, permutation: number, newProbabilities: ProbabilityDistribution) => {
                                                    this.commitNoisyOrParameters(variableId, sourceId, permutation, newProbabilities);
                                                }}
                                                numberMode={this.state.numberMode}
                                                sourceVariableIds={[sourceId]}
                                                bnParameters={this.props.bnParameters}
                                                permutation={index}
                                            />
                                        ))}
                                    </React.Fragment>
                                );
                            })
                        }
                        </ol>
                    </div>
                );
            },
            generateCPTFromParameters: true
        }
    };

    private calculateCombinations;

    constructor(props) {
        super(props);
        this.updateProbabilities = this.updateProbabilities.bind(this);
        this.commitProbabilities = this.commitProbabilities.bind(this);
        this.calculateCombinations = memoizeOne(calculateCombinations);
        this.calculateValidity = memoizeOne(this.calculateValidity.bind(this));
        this.uploadLearningFile = this.uploadLearningFile.bind(this);
        this.state = {
            probabilities: props.bnParameters.probabilities,
            initialProbabilities: props.bnParameters.probabilities,
            parameters: props.bnParameters.parameters,
            initialParameters: props.bnParameters.parameters,
            currentVariableId: null,
            numberMode: true,
            questionsMode: true,
            parametersOpen: false
        };

        this.switchView = this.switchView.bind(this);
    }

    checkValidityCPTSingleRow(probabilities: ProbabilityDistribution, isUtilityVariable: boolean): ValidityType {
        if (isUtilityVariable) {
            const incomplete = !probabilities || probabilities.length === 0 || probabilities[0] === null;
            return {valid: !incomplete, incomplete};
        } else if (probabilities) {
            let incomplete = false;
            let total = probabilities.reduce<number>((total, value) => {
                if (value === null) {
                    incomplete = true;
                    return total;
                } else {
                    return total + Number(value);
                }
            }, 0);
            let equalsOne = (Math.abs(total - 1.0) < constants.EPSILON);
            let moreThanOne = total > 1.0 + constants.EPSILON;
            let valid = !incomplete && equalsOne;
            return {valid, incomplete, equalsOne, moreThanOne};
        } else {
            return {valid: false, incomplete: true};
        }
    }

    updateProbabilities(variableId: string, permutation: number = 0, newProbabilities: ProbabilityDistribution): ObjectMapState<ProbabilityDistribution[]> {
        const newCpt: ProbabilityDistribution[] = (this.state.probabilities[variableId] || [[]]).slice();
        newCpt[permutation] = newProbabilities;
        const probabilities = {...this.state.probabilities, [variableId]: newCpt};
        this.setState({probabilities});
        return probabilities;
    }

    commitProbabilities(variableId: string, permutation: number = 0, newProbabilities: ProbabilityDistribution) {
        const probabilities = this.updateProbabilities(variableId, permutation, newProbabilities);
        if (this.props.migrated) {
            this.props.dispatch(setBayesNetParametersStateAction(this.props.problemStepId, this.props.userId, this.props.status, {
                ...this.props.bnParameters,
                probabilities
            }));
        } else {
            this.props.dispatch(setBayesNetProbabilitiesForVariableAction(this.props.problemStepId, this.props.userId, this.props.status, variableId, newProbabilities, permutation));
        }
    }

    updateNoisyOrParameters(variableId: string, sourceId: string, _permutation: number, newProbabilities: ProbabilityDistribution) {
        const parameters = this.state.parameters[variableId] as NoisyOrParameterType;
        this.setState({parameters: {
                ...this.state.parameters,
                [variableId]: {
                    type: BayesNetParameterEnum.NOISY_OR,
                    probabilities: {
                        ...parameters.probabilities,
                        [sourceId]: newProbabilities
                    }
                }
        }});
    }

    commitNoisyOrParameters(variableId: string, sourceId: string, permutation: number, newProbabilities: ProbabilityDistribution) {
        this.updateNoisyOrParameters(variableId, sourceId, permutation, newProbabilities);
        this.setState({}, () => {
            if (this.props.migrated) {
                this.props.dispatch(setBayesNetParametersStateAction(this.props.problemStepId, this.props.userId, this.props.status, {
                    ...this.props.bnParameters,
                    parameters: this.state.parameters
                }))
            } else {
                this.props.dispatch(setBayesNetParametersForVariableAction(this.props.problemStepId, this.props.userId, this.props.status, variableId, this.state.parameters[variableId]));
            }
        });
    }

    calculateValidity(probabilities: ObjectMapState<ProbabilityDistribution[]>): ObjectMapState<ValidityType[]> {
        let result: ObjectMapState<ValidityType[]> = {};
        if (probabilities) {
            Object.keys(probabilities).forEach((variableId) => {
                const variable = this.props.bnParameters.nodes.variable[variableId];
                const isUtilityVariable = variable.data.variableType === VariableType.UTILITY;
                result[variableId] = probabilities[variableId].map((cpt) => (
                    this.checkValidityCPTSingleRow(cpt, isUtilityVariable)
                ));
            });
        }
        return result;
    }

    validateVariable(variableId) {
        const combinations = this.calculateCombinations(this.props.bnParameters.nodes, this.props.bnParameters.connections);
        const variable = this.props.bnParameters.nodes.variable[variableId];
        if (!variable.data.name) {
            return 'has no name.';
        } else if (variable.data.name.trim() !== variable.data.name) {
            return 'has a name that starts or ends with spaces.';
        } else if (safeOrderObject(this.props.bnParameters.nodes, 'variable').reduce((result, otherVariableId) => {
                return result || (otherVariableId !== variableId && variable.data.name.toLowerCase() === this.props.bnParameters.nodes.variable[otherVariableId].data.name.toLowerCase());
            }, false)) {
            return 'has the same name as another variable.';
        } else if (variable.attributes.order.length === 0 && variable.data.variableType !== VariableType.UTILITY) {
            return 'has no type.';
        } else if (combinations[variableId] === 0) {
            return 'has parents with no type.';
        } else {
            let nameCount = {};
            return safeOrderObject(variable.attributes, 'state').reduce<string | null>((problem, stateId) => {
                const stateName = variable.attributes.state[stateId].stateName;
                nameCount[stateName] = 1 + (nameCount[stateName] || 0);
                return problem
                    || (stateName ? null : 'contains one or more states with no name.')
                    || (stateName === stateName.trim() ? null : 'contains one or more states with names starting or ending with spaces.')
                    || (nameCount[stateName] === 1 ? null : 'contains states with duplicate names.');
            }, null);
        }
    }

    uploadLearningFile(evt: ChangeEvent<HTMLInputElement>) {
        const target = evt.target;
        this.setState({learnParametersError: undefined});
        if (target.files && target.files.length === 1) {
            const reader = new FileReader();
            reader.onload = async () => {
                const text = reader.result;
                // validate CSV.
                const csv = await neatCsv(text);
                if (csv.length === 0) {
                    this.setState({learnParametersError: 'Error: file contains no values.'});
                    return;
                }
                // Validate file contains only valid variable names
                const names = Object.keys(csv[0]);
                const variableIds = names.filter((name) => (name !== BayesNetParametersContainer.NUM_CASES))
                    .map((name) => (safeOrderObject(this.props.bnParameters.nodes, 'variable'))
                    .find((variableId) => (this.props.bnParameters.nodes.variable[variableId].data.name === name)) || '');
                const variables = variableIds
                    .map((variableId) => (variableId ? this.props.bnParameters.nodes.variable[variableId] : undefined));
                const unknown = names.filter((name, index) => (name !== BayesNetParametersContainer.NUM_CASES && !variables[index]));
                if (unknown.length > 0) {
                    this.setState({
                        learnParametersError: 'Error: Row 1 of file contains unknown variable name'
                            + (unknown.length > 1 ? 's: ' : ': ') + unknown.join(', ')
                    });
                    return;
                }
                // Validate state names.
                const stateNames = variables.map((variable) => (safeOrderObject(variable!.attributes, 'state')
                    .map((attributeId) => (variable!.attributes.state[attributeId].stateName))));
                const stateError = csv.reduce((error, row, rowIndex) => (
                    variables.reduce((error, variable, columnIndex) => {
                        const stateName = row[variable!.data.name];
                        return error || (
                            stateName !== '*' && stateName !== '?' && stateNames[columnIndex].indexOf(stateName) < 0
                                ? `Error: Row ${rowIndex + 2} contains unknown state name "${stateName}" for variable ${variable!.data.name}` : ''
                        )
                    }, error)
                ), '');
                if (stateError) {
                    this.setState({learnParametersError: stateError});
                    return;
                }
                // Check if variables already have parameters set.
                const haveParameters = variableIds.filter((variableId) => (
                    this.props.bnParameters.probabilities[variableId] && this.props.bnParameters.probabilities[variableId].length > 0
                ));
                if (haveParameters.length > 0) {
                    this.setState({
                        learnParametersCSVText: text as string,
                        learnParametersAlreadyDefined: haveParameters
                            .map((variableId) => (this.props.bnParameters.nodes.variable[variableId].data.name))
                            .join(', ')
                    })
                } else {
                    await this.sendCaseFileToBNWrapper(text);
                }

            };
            reader.readAsText(target.files[0]);
        }
    }

    private async sendCaseFileToBNWrapper(text) {
        const response = await fetch(buildRestPath(UPLOAD_CASE_FILE_PATH, {
            problemStepId: this.props.problemStepId,
            userId: this.props.userId
        }), {
            method: 'post',
            credentials: 'include',
            headers: {
                'Content-Type': 'text/plain; charset=UTF-8'
            },
            body: text
        });
        if (response.ok) {
            this.setState({showLearnParameters: false, learnParametersError: undefined, learnParametersAlreadyDefined: undefined, learnParametersCSVText: undefined});
        } else {
            console.error(response);
            this.setState({showLearnParameters: true, learnParametersError: 'There was a problem learning parameters from the file you selected.'});
        }
    }

    renderVariableIcon(variableId) {
        let tooltip = 'All questions answered.';
        let valid = true;
        let incomplete = false;

        const variableProblem = this.validateVariable(variableId);
        const combinations = this.calculateCombinations(this.props.bnParameters.nodes, this.props.bnParameters.connections);
        if (variableProblem) {
            tooltip = 'This variable ' + variableProblem;
            valid = false;
        } else {
            range(combinations[variableId]).forEach((permutation) => {
                let validity = this.getValidity(variableId, permutation);
                if (!validity.valid) {
                    if (validity.moreThanOne) {
                        valid = false;
                        tooltip = 'The probabilities add to more than 100%';
                    } else if (validity.incomplete) {
                        incomplete = true;
                        tooltip = 'You have not yet answered all questions for this variable.';
                    } else {
                        valid = false;
                        tooltip = 'The probabilities add to less than 100%';
                    }
                }
            });
        }
        return (
            <div className='variableStatus toolTipContainer'>
                {
                    !valid ? (
                        <FontIcon className='warning'>warning</FontIcon>
                    ) : incomplete ? (
                        <FontIcon className='incomplete'>warning</FontIcon>
                    ) : (
                        <FontIcon className='done'>done</FontIcon>
                    )
                }
                <span className='toolTip justifyLeft'>{tooltip}</span>
            </div>
        );
    }

    renderVariables() {
        return safeOrderObject(this.props.bnParameters.nodes, 'variable')
            .filter((variableId) => (this.props.bnParameters.nodes.variable[variableId].data.variableType !== VariableType.DECISION))
            .map((variableId) => {
                const name = this.props.bnParameters.nodes.variable[variableId].data.name;
                return <div key={variableId} className={classNames('variable', {
                    selected: this.state.currentVariableId === variableId
                })} onClick={() => {
                    this.setState({currentVariableId: variableId});
                }}>
                    {name ?
                        <div className="paramStateNameDiv">
                            {name}
                        </div>
                        :
                        <span>&nbsp;</span>}
                    {this.renderVariableIcon(variableId)}
                    {this.props.readOnly ? null : (
                        <div className='variableStatus toolTipContainer'>
                            <FontIcon onClick={() => {
                                this.setState({variableIdToReset: variableId});
                            }}>delete</FontIcon>
                            <span className='toolTip justifyLeft'>Discard the Probability Distribution this variable.</span>
                        </div>
                    )}
                </div>
            });
    }

    renderNumberOrVerbalToggle() {
        const currentVariable = this.state.currentVariableId ? this.props.bnParameters.nodes.variable[this.state.currentVariableId] : undefined;
        if (currentVariable && currentVariable.data.variableType === VariableType.UTILITY) {
            return null;
        } else if (this.state.numberMode) {
            return (<div className='numberVerbalToggle' title='Switch to words' onClick={() => {
                this.setState({numberMode: false});
            }}>123 <span className='material-icons'>swap_horiz</span></div>);
        } else {
            return (<div className='numberVerbalToggle' title='Switch to percentages' onClick={() => {
                this.setState({numberMode: true});
            }}>ABC <span className='material-icons'>swap_horiz</span></div>);
        }
    }

    renderStructure() {
        let focusNodes;
        let styles = getBaseBayesNetStyle(this.props.bnParameters.nodes);
        if (this.state.currentVariableId) {
            let sourceVariables = findSourceVariables(this.props.bnParameters, this.state.currentVariableId);
            let targetVariables = findTargetVariables(this.props.bnParameters, this.state.currentVariableId);
            focusNodes = [this.state.currentVariableId, ...sourceVariables, ...targetVariables];
            safeOrderObject(this.props.bnParameters.nodes, 'variable').forEach((variableId) => {
                if (variableId === this.state.currentVariableId) {
                    styles[variableId] = {
                        ...styles[variableId],
                        strokeWidth: 5,
                        stroke: constants.colourLightActionBlue
                    }
                } else if (indexOf(sourceVariables, variableId) < 0 && indexOf(targetVariables, variableId) < 0) {
                    styles[variableId] = {
                        ...styles[variableId],
                        visibility: "hidden"
                    }
                }
            });
        } else {
            focusNodes = this.props.bnParameters.nodes.order;
        }
        return (
            <DownloadNetworkComponent problemStepId={this.props.problemStepId} userId={this.props.userId}
                                      status={this.props.status} buttonBarDiv={this.props.buttonBarDiv}
                                      buttonClassName='lightTheme'
            >
                <div className='bayesNetDisplayStructureForParameters'>
                    <BayesNetDisplayComponent
                        graphDescription={safeBNParameters(this.props.bnParameters)}
                        readOnly={true}
                        styles={styles}
                        customNodeDrawingSVGFunction={getBayesNetNodeShape}
                        focusNodes={focusNodes}
                    />
                </div>
            </DownloadNetworkComponent>
        );
    }

    getProbabilities(variableId, permutation) {
        if (this.state.probabilities[variableId] && this.state.probabilities[variableId][permutation]) {
            return this.state.probabilities[variableId][permutation];
        } else {
            return [];
        }
    }

    getValidity(variableId, permutation): ValidityType {
        const validity = this.calculateValidity(this.state.probabilities);
        if (validity[variableId] && validity[variableId][permutation]) {
            return validity[variableId][permutation];
        } else {
            return {valid: false, incomplete: true};
        }
    }

    renderConditionCasesForVariable(mode: string, currentVariableId: string, readOnly: boolean) {
        const currentVariable = this.props.bnParameters.nodes.variable[currentVariableId];
        const probabilitiesKeys = currentVariable.attributes.order;
        const combinations = this.calculateCombinations(this.props.bnParameters.nodes, this.props.bnParameters.connections);
        const sourceVariableIds = (combinations[currentVariableId] > 1) ? findSourceVariables(this.props.bnParameters, currentVariableId) : undefined;
        return (
            range(combinations[currentVariableId]).map((index) => (
                <ProbabilityTableComponent
                    mode={mode}
                    key={currentVariableId + '_' + index}
                    readOnly={readOnly}
                    variableId={currentVariableId}
                    variable={currentVariable}
                    probabilities={this.getProbabilities(currentVariableId, index)}
                    probabilitiesKeys={probabilitiesKeys}
                    validity={this.getValidity(currentVariableId, index)}
                    onChange={this.updateProbabilities}
                    onCommitChange={this.commitProbabilities}
                    numberMode={this.state.numberMode}
                    sourceVariableIds={sourceVariableIds}
                    bnParameters={this.props.bnParameters}
                    permutation={index}
                />
            ))
        );
    }

    renderAllConditionCases(readOnly: boolean) {
        const currentVariableId = this.state.currentVariableId;
        if (!currentVariableId) {
            return '';
        }
        const problem = this.validateVariable(currentVariableId);
        if (problem) {
            return (
                <div className='errorStyle'>
                    The selected variable {problem} Please go back to VARIABLES and fix the problem.
                </div>
            );
        } else if (this.state.questionsMode) {
            return (
                <ol className='conditionCases'>
                    {this.renderConditionCasesForVariable('QLIST', currentVariableId, readOnly)}
                </ol>
            );
        } else {
            const currentVariable = this.props.bnParameters.nodes.variable[currentVariableId];
            const isUtilityVariable = currentVariable.data.variableType === VariableType.UTILITY;
            const probabilitiesKeys = isUtilityVariable ? [''] : this.props.bnParameters.nodes.variable[currentVariableId].attributes.order;
            const combinations = this.calculateCombinations(this.props.bnParameters.nodes, this.props.bnParameters.connections);
            const sourceVariableIds = (combinations[currentVariableId] > 1) ? findSourceVariables(this.props.bnParameters, currentVariableId) : undefined;

            return (
                <table className='conditionCases'>
                    <thead>
                    {
                        sourceVariableIds ? (
                            <tr>
                                {sourceVariableIds.map((variableId) =>
                                    <th rowSpan={isUtilityVariable ? 1 : 2} key={variableId}
                                        className='sourceVar'>{this.props.bnParameters.nodes.variable[variableId].data.name}</th>
                                )}
                                <th className='headerFirstRow' colSpan={probabilitiesKeys.length}
                                    key={currentVariableId}>{this.props.bnParameters.nodes.variable[currentVariableId].data.name}</th>
                            </tr>
                        ) : null
                    }
                    {
                        isUtilityVariable ? null : (
                            <tr className='headerSecondRow'>
                                {probabilitiesKeys.map((attributeId) =>
                                    <th key={attributeId}>{this.props.bnParameters.nodes.variable[currentVariableId].attributes.state[attributeId].stateName}</th>
                                )}
                            </tr>
                        )
                    }
                    </thead>

                    {this.renderConditionCasesForVariable('CPT', currentVariableId, readOnly)}

                </table>
            );
        }
    }

    switchView(questionsMode) {
        this.setState({questionsMode: !questionsMode});
    }

    renderQuestionsOrTableToggle() {
        return this.state.questionsMode ? (
            <div className='questionModeControl toolTipContainer'>
                <i className='material-icons cptButton' onClick={() => {
                    this.switchView(this.state.questionsMode)
                }}>grid_on</i>
                <span className='toolTip'>Switch to table mode.</span>
            </div>
        ) : (
            <div className='questionModeControl toolTipContainer'>
                <i className='material-icons questionButton' onClick={() => {
                    this.switchView(this.state.questionsMode)
                }}>view_list</i>
                <span className='toolTip'>Switch to question mode</span>
            </div>
        );
    }

    renderMiddlePanel() {
        const currentVariable = this.state.currentVariableId ? this.props.bnParameters.nodes.variable[this.state.currentVariableId] : undefined;
        return (
            <div className="workspaceContentColumn">
                {
                    currentVariable && currentVariable.data.variableType === VariableType.UTILITY ? null
                        : this.renderParameterChoice()
                }
                {this.renderQuestionsOrTable()}
            </div>
        );
    }

    private getSelectedParameterType(variableId: string): BayesNetParameterEnum {
        const parameters = this.props.bnParameters.parameters[variableId];
        return (parameters && parameters.type) ? parameters.type : BayesNetParameterEnum.MANUAL;
    }

    private changeVariableFunction(variableId: string, type: string) {
        const parameters = this.parameterFunctions[type].initialParameters;
        this.props.dispatch(setBayesNetParametersForVariableAction(this.props.problemStepId, this.props.userId, this.props.status, variableId, parameters));
        if (type !== BayesNetParameterEnum.MANUAL) {
            this.props.dispatch(setBayesNetProbabilitiesForVariableAction(this.props.problemStepId, this.props.userId, this.props.status, variableId, []));
            this.setState({parametersOpen: true});
        }
    }

    renderParameterChoice() {
        const variableId = this.state.currentVariableId;
        if (!variableId) {
            return null;
        } else {
            const parameterOptions = Object.keys(this.parameterFunctions).map((key) => ({
                label: this.parameterFunctions[key].name,
                value: key,
                data: !this.parameterFunctions[key].isApplicable(variableId)
            }));
            const selected = this.getSelectedParameterType(variableId);
            const parameterFunction = this.parameterFunctions[selected];
            const selectedOption = parameterOptions.find((option) => (option.value === selected));
            return (
                <div className="parameterChoice">
                    <ExpansionList animateContent={false}>
                        <ExpansionPanel
                            expanded={this.state.parametersOpen}
                            onExpandToggle={(parametersOpen: boolean) => {
                                this.setState({parametersOpen});
                            }}
                            headerClassName='heading'
                            label={
                                <div className="parameterChoiceSelect" onClick={(evt) => {evt.stopPropagation()}}>
                                    <div className="parameterChoiceLabel">
                                    {this.props.bnParameters.nodes.variable[variableId].data.name} is configured
                                    </div>
                                    <div className="parameterChoiceSelectWrap">
                                        <Select
                                            value={selectedOption}
                                            onChange={(option) => {
                                                if (!option) {
                                                    return;
                                                }
                                                const currentType = this.props.bnParameters.parameters[variableId] ? this.props.bnParameters.parameters[variableId].type : BayesNetParameterEnum.MANUAL;
                                                let shouldConfirm;
                                                if (currentType === BayesNetParameterEnum.MANUAL) {
                                                    shouldConfirm = (this.props.bnParameters.probabilities[variableId] || []).reduce((anyValues, row) => (
                                                        anyValues || row.reduce((anyValues, value) => (anyValues || value !== null), false)
                                                    ), false);
                                                } else {
                                                    shouldConfirm = !isEqual(this.props.bnParameters.parameters[variableId], this.parameterFunctions[currentType].initialParameters);
                                                }
                                                if (shouldConfirm) {
                                                    this.setState({dialogConfirmChangeFunction: option.value});
                                                } else {
                                                    this.changeVariableFunction(variableId, option.value);
                                                }
                                            }}
                                            styles={commonSelectStyles}
                                            isClearable={false} isSearchable={false}
                                            isOptionDisabled={(option) => (option.data)}
                                            options={parameterOptions}
                                        />
                                    </div>
                                </div>
                            }
                            footer={null}
                        >
                            {parameterFunction.renderParameterInputs ? parameterFunction.renderParameterInputs(variableId) : null}
                        </ExpansionPanel>
                    </ExpansionList>
                </div>
            );
        }
    }

    renderQuestionsOrTable() {
        if (!this.state.currentVariableId) {
            return null;
        }
        const currentVariable = this.props.bnParameters.nodes.variable[this.state.currentVariableId];
        const label = this.state.questionsMode ? 'Questions for'
            : currentVariable.data.variableType === VariableType.UTILITY ? 'Utility values for'
                : 'Conditional Probabilities for';
        const selectedParameterType = this.getSelectedParameterType(this.state.currentVariableId);
        const readOnly = (this.props.readOnly || this.parameterFunctions[selectedParameterType].generateCPTFromParameters);
        return (
            <div className='parameterConditionCasePanel'>
                <div className='heading'>
                    {this.renderQuestionsOrTableToggle()}
                    <div className="variableNameMiddleDiv">
                        <div className='nameDiv'>
                            {label + ': ' + this.props.bnParameters.nodes.variable[this.state.currentVariableId].data.name}
                        </div>
                    </div>
                    {this.renderNumberOrVerbalToggle()}
                </div>
                {readOnly ? null : <HelpTips helpId={constants.HELP_PARAMETERS} problemStepId={this.props.problemStepId}/>}
                <div className='conditionCasePanel'>
                    {this.renderAllConditionCases(readOnly)}
                </div>
            </div>
        );
    }

    renderParameters() {
        const isSoloUser = this.props.userRole === constants.ROLE_SOLO_ANALYST;
        const structureTip = <div className="pageTip">
                                In this step, the structure shown is only a fragment of the full model, showing the parents and children of the variable you’ve selected.
                            </div>;
        const varTip = <div className="methodDivTipDiv leftPageTip">
                            <strong>REMEMBER:</strong><br/>
                            ✔	To run scenarios in Explore Network, you must have valid parameter ticks [✓] for each variable <br/>
                            ✔	BUT you can publish at any time <br/>
                            <div>&nbsp;</div>
                            <strong>AVOID:</strong><br/>
                            ✖	Don’t use 0 and 100% unless it is logically or physically impossible or certain, respectively.<br/>
                        </div>;
        const tipStr1 = "Select each Variable on the left and answer the questions that appear below. This will fill the Probability Distribution (PD) for your Variables. ";
        const tipStr2 = "Publish your inputs to make them available to be viewed by your group.";
        const tipStr3 = "Select each Variable. You will see the Probability Distribution (PD) for the Variables.";

        const allVariablesValid = safeOrderObject(this.props.bnParameters.nodes, 'variable').reduce((valid, variableId) => (
            valid && this.validateVariable(variableId) === null
        ), true);

        return (
            <div className='bnParametersTopLevel'>
                <div className="threeColumnLayout">
                    <div className="workspaceLeftColumn">
                        <div className='parametersInstructionDiv'>
                            {!this.props.readOnly ? 'Provide ' : ''} Variable Probabilities
                        </div>
                        <HelpTips helpId={constants.HELP_PARAMETERS} problemStepId={this.props.problemStepId}/>
                    </div>
                    <div className="workspaceContentColumn">
                        <div className='pageTip'>
                            {this.props.readOnly ? tipStr3 : tipStr1 + (!isSoloUser ? tipStr2 : "")}
                         </div>
                    </div>
                    <div className='workspaceRightColumn'/>
                </div>
                <div className='bnParametersContainer'>
                    <div className='parameterVariablePanel'>
                        <div className='heading'>
                            Variables
                        </div>
                        {
                            this.props.readOnly ? null : (
                                <div className='learnButton toolTipContainer'>
                                    <Button flat={true} className='mdActionButton roleBackgroundColour'
                                            onClick={() => {this.setState({showLearnParameters: true})}}
                                            disabled={this.props.bnParameters.nodes.order.length === 0 || !allVariablesValid}
                                    >
                                        Learn parameters...
                                    </Button>
                                    <span className='toolTip justifyLeft'>
                                        {
                                            this.props.bnParameters.nodes.order.length === 0 ? (
                                                'You must defined some variables before trying to learn parameters.'
                                            ) : !allVariablesValid ? (
                                                'All your variables must be valid before trying to learn parameters.'
                                            ) : (
                                                'Calculate the probabilities of your variables by uploading a spreadsheet of example values.'
                                            )
                                        }
                                    </span>
                                </div>
                            )
                        }
                        {this.renderVariables()}
                        {this.props.readOnly ? null : varTip}
                    </div>

                    {this.renderMiddlePanel()}

                    <div className='parameterStructurePanel'>
                        <div className='heading'>Structure</div>
                        {structureTip}
                        {this.renderStructure()}
                    </div>
                </div>
                <ConfirmationDialog
                    visible={this.state.dialogConfirmChangeFunction !== undefined}
                    dialogTitle='Confirm changing parameter type'
                    onHideAction={() => {
                        this.changeVariableFunction(this.state.currentVariableId!, this.state.dialogConfirmChangeFunction!);
                        this.setState({dialogConfirmChangeFunction: undefined})
                    }}
                    onCancelAction={() => {this.setState({dialogConfirmChangeFunction: undefined})}}
                    dialogContent={
                        <div>
                            Are you sure you want to change how {
                                this.state.currentVariableId && this.props.bnParameters.nodes.variable[this.state.currentVariableId] ?
                                    this.props.bnParameters.nodes.variable[this.state.currentVariableId].data.name : null
                            } is configured?  The parameter values you have entered will be lost.
                        </div>
                    }
                />
                <ConfirmationDialog
                    visible={this.state.variableIdToReset !== undefined}
                    dialogTitle='Confirm variable reset'
                    onHideAction={() => {
                        this.props.dispatch(setBayesNetProbabilitiesForVariableAction(this.props.problemStepId, this.props.userId, this.props.status, this.state.variableIdToReset!, []));
                        this.setState({variableIdToReset: undefined})
                    }}
                    onCancelAction={() => {this.setState({variableIdToReset: undefined})}}
                    dialogContent={
                        <div>
                            Are you sure you want to discard the Probability Distribution for {
                            this.state.currentVariableId && this.props.bnParameters.nodes.variable[this.state.currentVariableId] ?
                                this.props.bnParameters.nodes.variable[this.state.currentVariableId].data.name : null
                            }?
                        </div>
                    }
                />
                <ConfirmationDialog
                    className='learnParametersDialog'
                    visible={this.state.showLearnParameters === true}
                    dialogTitle={!this.state.learnParametersAlreadyDefined ? 'Learn parameters from examples' : 'Overwrite existing parameters'}
                    width={800}
                    height={!this.state.learnParametersAlreadyDefined ? '80vh' : undefined}
                    onCancelAction={() => {this.setState({showLearnParameters: false, learnParametersError: undefined, learnParametersAlreadyDefined: undefined, learnParametersCSVText: undefined})}}
                    actionButtonContent={!this.state.learnParametersAlreadyDefined ? 'Upload examples...' : 'Proceed'}
                    onHideAction={async () => {
                        if (this.state.learnParametersAlreadyDefined) {
                            await this.sendCaseFileToBNWrapper(this.state.learnParametersCSVText);
                        } else {
                            this.setState({showLearnParameters: true}, () => {
                                document.getElementById('hiddenFileInput')!.click();
                            });
                        }
                    }}
                    dialogContent={
                        !this.state.learnParametersAlreadyDefined ? (
                            <div>
                                {
                                    this.state.learnParametersError ? <p className='uploadError'>{this.state.learnParametersError}</p> : null
                                }
                                <p>
                                    BARD can calculate values for your parameters from examples.  To use this feature, you
                                    will need a CSV file containing example values of some or all of your variables, such as
                                    the results of several experiments or observations.
                                </p>
                                <p>
                                    Each row of the file is a single observation of the values of your variables that
                                    occurred together.  The fact that you observed these variable values together, in
                                    conjunction with the causal links you defined in your Structure, allows BARD to infer that
                                    it should increase the probability that these variable values should occur together in the
                                    future.
                                </p>
                                <p>
                                    The first row of the file must contain the names of the variables you wish to use.  The
                                    complete list of your variables would be:
                                </p>
                                <pre>
                                {safeOrderObject(this.props.bnParameters.nodes, 'variable').map((variableId) => {
                                    const escapedName = this.props.bnParameters.nodes.variable[variableId].data.name
                                        .replace('"', '\\"');
                                    return (escapedName.indexOf(',') < 0) ? escapedName : `"${escapedName}"`;
                                }).join(',')}
                            </pre>
                                <p>
                                    Each subsequent row in the file contains the observed values that occurred together,
                                    with the state name for a particular variable listed in the column under that variable's
                                    name.  If a state name contains commas, it needs to be enclosed in double quotes.
                                </p>
                                <p>
                                    You may also include a column with the special variable name <code>NumCases</code>, whose
                                    values are numbers.  This tells BARD that you observed the particular set of variable
                                    values a number of times, and allows you to avoid duplicate rows in your file if your
                                    examples contain the same values multiple times.  NumCases does not have to be a whole
                                    number, so it can be used to represent a frequency instead of a count if you wish.  If
                                    omitted, it is assumed that each row occurred once.
                                </p>
                                <p>
                                    The special value of ? or * may be given if the value of the variable is unknown
                                    for the given row.  If you included a <code>NumCases</code> column in your file, its
                                    value must be a number in every row (not ? or *).
                                </p>
                                <input id='hiddenFileInput' type='file' hidden={true} onChange={this.uploadLearningFile}/>
                            </div>
                        ) : (
                            <div>
                                <p>
                                    The following variables listed in your CSV file already have probability distributions
                                    defined in BARD: {this.state.learnParametersAlreadyDefined}
                                </p>
                                <p>
                                    If you proceed, the current probability distributions for those variables will be
                                    discarded and replaced with values derived from your CSV file.
                                </p>
                                <p>Do you want to proceed?</p>
                            </div>
                        )
                    }
                />
            </div>
        );
    }

    render() {
        return (
            <UploadNetworkComponent problemStepId={this.props.problemStepId} buttonBarDiv={this.props.buttonBarDiv} uploadDisabled={this.props.readOnly}>
                {this.renderParameters()}
            </UploadNetworkComponent>
        );
    }
}

function mapStoreToProps(store: StoreWithSharedState, myProps: BayesNetParametersOwnProps): BayesNetParametersStoreProps {
    const problemStepId = myProps.problemStepId;
    const userId = Number(myProps.userId || getLoggedInUserIdFromStore(store));
    const status = (myProps.status === undefined) ? BayesNetParametersContainer.defaultProps.status : myProps.status;
    const {updated: bnParameters, migrated} = getBayesianModelData(store, problemStepId, userId, status, constants.SYNC_BN_PARAMETERS);
    const userFromStore = userId && problemStepId && getUserForProblemFromStore(store, problemStepId, userId);
    const userRole = (userId && userFromStore) ? userFromStore.db.role : undefined;
    return {
        userId,
        bnParameters: bnParameters as BayesNetParametersState,
        migrated,
        userRole
    };
}

export default connect(mapStoreToProps)(BayesNetParametersContainer);
