import * as constants from './constants';
import {ProblemStepPojo} from '../../server/orm/dao/problemStepDAO';
import {getRoundId} from './roundLogic';

/**
 * Check if the current context is in any of the "modes" listed in modeList.
 *
 * @param {string[]} modeList An array of mode names, strings from the constants file starting with MODE_
 * @param {ProblemStepPojo} problemStep The current problemStep for the problem, as returned by
 * getProblemStepFromStore(store, problemStepId), or by accessing getTeamFromStore(store, problemStepId).problemStep
 * @return {boolean} True if the context is in any of the modes listed in modeList, or false otherwise.
 */
export function isProblemInMode(modeList: (string | number)[], problemStep: ProblemStepPojo): boolean {

    return modeList.reduce((inMode: boolean, modeName) => {
        if (!inMode) {
            switch (modeName) {
                case constants.LAUNCH_MODE_FACILITATOR_ADVANCES:
                    return problemStep.launchMode === constants.LAUNCH_MODE_FACILITATOR_ADVANCES;
                case constants.LAUNCH_MODE_REAL_TIME_JURY:
                    return problemStep.launchMode === constants.LAUNCH_MODE_REAL_TIME_JURY;
                case constants.LAUNCH_MODE_SOLO_BARD:
                    return problemStep.launchMode === constants.LAUNCH_MODE_SOLO_BARD;
                case constants.LAUNCH_MODE_STRATHCLYDE_EXPERIMENT:
                    return problemStep.launchMode === constants.LAUNCH_MODE_STRATHCLYDE_EXPERIMENT;
                case constants.MODE_DELPHI_EXCHANGE:
                    return getRoundId(problemStep.stepId, problemStep.roundSequenceNumber) === constants.ROUND_DELPHI_EXCHANGE;
                case constants.MODE_DIRECT_EXCHANGE:
                    return getRoundId(problemStep.stepId, problemStep.roundSequenceNumber) === constants.ROUND_EXCHANGE;
                case constants.MODE_OPEN_ROUND:
                    return getRoundId(problemStep.stepId, problemStep.roundSequenceNumber) === constants.ROUND_OPEN;
                case constants.MODE_PRIVATE_ROUND:
                    return getRoundId(problemStep.stepId, problemStep.roundSequenceNumber) === constants.ROUND_PRIVATE;
                default:
                    return false;
            }
        }
        return inMode;
    }, false)
}