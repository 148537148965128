export const LOGIN = 'LOGIN';
export const LOGOUT = 'LOGOUT';
const OFFLINE = 'OFFLINE';
const ONLINE = 'ONLINE';

const INITIAL_STATE = {
    isLoggedIn: false,
    user: null,
    offline: false
};

function loginReducer(state = INITIAL_STATE, action) {
    switch (action.type) {
        case LOGIN:
            return {...state, isLoggedIn: true, user: action.user, error: ''};
        case LOGOUT:
            return {...state, isLoggedIn: false, user: null, error: action.error || ''};
        case OFFLINE:
            return {...state, offline: true};
        case ONLINE:
            return {...state, offline: false};
        default:
            return state;
    }
}

export default loginReducer;

export function loginAction(user) {
    return {type: LOGIN, user};
}

export function logoutAction(error = '') {
    return {type: LOGOUT, error};
}

export function offlineAction() {
    return {type: OFFLINE, isClientOnly: true};
}

export function onlineAction() {
    return {type: ONLINE, isClientOnly: true};
}

export function getUserFromStore(store) {
    return store.clientState.login.user;
}

export function getLoggedInUserIdFromStore(store) {
    return getUserFromStore(store).id;
}

export function getIsLoggedInFromStore(store) {
    return store.clientState.login.isLoggedIn;
}

export function getLoginErrorFromStore(store) {
    return store.clientState.login.error;
}

export function getOfflineFromStore(store) {
    return store.clientState.login.offline;
}
