import {Reducer} from 'redux';

import DataStatusEnum from '../DataStatusEnum';
import ensureFieldPath from '../ensureFieldPath';
import {timestampedReducer, TimestampedState} from '../util/genericReducers';
import { StoreWithSharedState } from './sharedStateReducer';

const SELECT_METHOD = 'select_method';

export interface MethodSelectionState extends TimestampedState {
    method?: 0 | 1 | 2;
    rationale?: string;
}

export function getDBSyncActionTypes() {
    return [SELECT_METHOD];
}

const methodSelectionReducer: Reducer<MethodSelectionState> = (state = {}, action) => {
    switch (action.type) {
        case SELECT_METHOD:
            let methodSelectionObject: any = {...state};

            if (action.method !== null)
                methodSelectionObject.method = action.method;
            if (action.rationale !== null)
                methodSelectionObject.rationale = action.rationale;

            return methodSelectionObject;
        default:
            return state;
    }
};

export default timestampedReducer(methodSelectionReducer);

export function updateMethodSelectionAction(problemStepId, userId, status: DataStatusEnum, method, rationale) {
    return {type: SELECT_METHOD, problemStepId, userId, status, method, rationale};
}

export function getMethodSelectionFromStore(store: StoreWithSharedState, problemStepId, userId, status: DataStatusEnum) {
    return store.sharedState.teams[problemStepId].user[userId].status[status].methodSelection;
}

export function setMethodSelectionInStore(store: StoreWithSharedState, problemStepId, userId, status: DataStatusEnum, methodSelection) {
    ensureFieldPath(store, 'sharedState', 'teams', problemStepId, 'user', userId, 'status', [status]);
    store.sharedState.teams[problemStepId].user[userId].status[status].methodSelection = methodSelection;
}
