import React from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';

import DispatchProp from '../../@types/dispatchProp';
import {StoreWithSharedState} from '../../common/reducers/sharedStateReducer';
import {
    getAllProjectsFromStore,
    ProjectsReducerType,
    updateProjectAction, updateProjectProblemsAction,
    updateProjectUsersAction
} from '../../common/reducers/projectsReducer';
import {PROJECT_PATH} from '../../common/clientServer/navigation';
import SingleInput from './SingleInput';
import Project from '../../common/clientServer/project';
import MultiAssignComponent from './MultiAssignComponent';
import {getAllUsersFromStore} from '../reducers/allUsersReducer';
import {ObjectMapState} from '../../common/util/genericReducers';
import {BardUser} from '../../common/clientServer/bardUser';
import {parseBardUserFilterString, userMatchesFilter} from '../../common/filter/bardUserFilter';
import {getLoggedInUserIdFromStore} from '../reducers/loginReducer';
import {PROJECT_SYSADMIN} from '../../common/util/constants';
import FilterSuggestionField from './FilterSuggestionField';
import {AllProblemsReducerType, getAllProblemDBsFromStore} from '../../common/reducers/allProblemsReducer';
import {problemMatchesFilter} from '../../common/filter/problemFilter';
import ProjectUser from '../../common/clientServer/projectUser';

interface AdminProjectsPanelStoreProps {
    projects: ProjectsReducerType;
    loggedInUserId: number;
    allUsers: ObjectMapState<BardUser>;
    problems: AllProblemsReducerType;
}

type AdminProjectsPanelProps = AdminProjectsPanelStoreProps & DispatchProp;

interface AdminProjectsPanelAssignUsers {
    project: Project;
    usersAssigned: {[key: string]: boolean};
    unassignedFilter?: string;
}

interface AdminProjectsPanelAssignProblems {
    project: Project;
    problemsAssigned: {[key: string]: boolean};
    unassignedFilter?: string;
}

interface AdminProjectsPanelState {
    projectSelected?: string;
    editProject?: Project;
    assignUsers?: AdminProjectsPanelAssignUsers;
    assignProblems?: AdminProjectsPanelAssignProblems;
}

class AdminProjectsPanel extends React.Component<AdminProjectsPanelProps, AdminProjectsPanelState> {

    constructor(props: AdminProjectsPanelProps) {
        super(props);
        this.renderUserToAssign = this.renderUserToAssign.bind(this);
        this.renderProblemToAssign = this.renderProblemToAssign.bind(this);
        this.state = {
        };
    }

    private renderEditProject() {
        const project = this.state.editProject!;
        return (
            <div className='adminConsole'>
                <div className='adminConsoleContent'>
                    <table>
                        <tbody>
                            <tr>
                                <td><b>Project Name:</b></td>
                                <td>
                                    <SingleInput inputType='text' content={project.name} onChange={(value: string) => {
                                        this.setState({editProject: {...project, name: value}});
                                    }}/>
                                </td>
                            </tr>
                        </tbody>
                    </table>
                </div>
                <div className='adminButtonsDiv'>
                    <input type='button' value='Cancel' className='buttonStyle regularButtonStyle' onClick={() => {
                        this.setState({editProject: undefined});
                    }}/>
                    <input type='button' value='Save' className='buttonStyle regularButtonStyle' onClick={() => {
                        this.props.dispatch(updateProjectAction(this.state.editProject!));
                        this.setState({editProject: undefined});
                    }}/>
                </div>
            </div>
        );
    }

    private renderUserToAssign(userId: number | string) {
        userId = Number(userId);
        const assignUsers = this.state.assignUsers!;
        const project = assignUsers.project;
        const myProjectUser = project.users[this.props.loggedInUserId];
        const isSysadmin = this.props.projects[PROJECT_SYSADMIN] ? this.props.projects[PROJECT_SYSADMIN].users[this.props.loggedInUserId] !== undefined : false;
        const userProjectUser: ProjectUser = project.users[userId] || {
            userId,
            projectId: project.id,
            readOnly: false
        };
        const user = this.props.allUsers[userId];
        const canEdit = (isSysadmin || (myProjectUser && !myProjectUser.readOnly)) && userId !== this.props.loggedInUserId;
        return assignUsers.usersAssigned[userId] ? (
            <div className='userForProject'>
                <div className='userDisplayName'>{user.loginName}</div>
                {
                    !user.admin ? null : (
                        <div className={classNames('projectRW', {canEdit, readOnly: userProjectUser.readOnly})} onClick={() => {
                            if (canEdit) {
                                this.setState({
                                    assignUsers: {
                                        ...assignUsers,
                                        project: {
                                            ...assignUsers.project,
                                            users: {
                                                ...assignUsers.project.users,
                                                [userId]: {
                                                    ...userProjectUser,
                                                    readOnly: !userProjectUser.readOnly
                                                }
                                            }
                                        }
                                    }
                                });
                            }
                        }}>
                            {userProjectUser.readOnly ? 'read-only' : 'read-write'}
                        </div>
                    )
                }
            </div>
        ) : (
            <div className={classNames('userNotAssigned', {admin: user.admin})} title={user.admin ? 'User is an admin' : ''}>
                <div className="userDisplayName">{user.loginName}</div>
                <div className="userEmail">({user.emailAddress})</div>
            </div>
        )
    }

    private renderAssignUsersToProject() {
        const assignUsers = this.state.assignUsers!;
        const sysadminProject = assignUsers.project.id === PROJECT_SYSADMIN;
        return (
            <div className='adminConsole'>
                <div className='adminConsoleContent'>
                    {sysadminProject ? <p><b>NOTE:</b> admins assigned to this project become sysadmins, with access to the entire system!</p> : null}
                    <MultiAssignComponent
                        allIds={Object.keys(this.props.allUsers).filter((id) => (
                            (!sysadminProject || this.props.allUsers[id].admin)
                            && (assignUsers.usersAssigned[id]
                                || userMatchesFilter(this.props.allUsers[id], parseBardUserFilterString(assignUsers.unassignedFilter || '')))
                        ))}
                        selectedIds={assignUsers.usersAssigned}
                        renderItem={this.renderUserToAssign}
                        onSelectionChanged={(id, selected) => {
                            this.setState({assignUsers: {...assignUsers, usersAssigned: {...assignUsers.usersAssigned, [id]: selected}}});
                        }}
                    />
                </div>
                <div className='adminButtonsDiv'>
                    <FilterSuggestionField
                        key='userSuggestionField'
                        type='user'
                        className='filterInput'
                        placeholder='Filter unassigned users'
                        filter={assignUsers.unassignedFilter}
                        onChange={(unassignedFilter) => {
                            this.setState({assignUsers: {...assignUsers, unassignedFilter}});
                        }}
                    />
                    <input type='button' value='Cancel' className='buttonStyle regularButtonStyle' onClick={() => {
                        this.setState({assignUsers: undefined});
                    }}/>
                    <input type='button' value='Save' className='buttonStyle regularButtonStyle' onClick={async () => {
                        let users = {...assignUsers.project.users};
                        Object.keys(assignUsers.usersAssigned).forEach((id) => {
                            if (!assignUsers.usersAssigned[id]) {
                                delete(users[id]);
                            } else if (!assignUsers.project.users[id]) {
                                users[id] = {
                                    userId: Number(id),
                                    projectId: assignUsers.project.id,
                                    readOnly: false
                                };
                            }
                        });
                        this.props.dispatch(updateProjectUsersAction(assignUsers.project.id, users));
                        this.setState({assignUsers: undefined});
                    }}/>
                </div>
            </div>
        );
    }

    private renderProblemToAssign(problemId: number | string) {
        return <div>{this.props.problems[problemId].title}</div>;
    }

    private renderAssignProblemsToProject() {
        const assignProblems = this.state.assignProblems!;
        return (
            <div className='adminConsole'>
                <div className='adminConsoleContent'>
                    <MultiAssignComponent
                        allIds={Object.keys(this.props.problems).filter((id) => (
                            !this.props.problems[id].isTraining
                            && (assignProblems.problemsAssigned[id]
                                || problemMatchesFilter(this.props.problems[id], parseBardUserFilterString(assignProblems.unassignedFilter || ''))
                            )
                        ))}
                        selectedIds={assignProblems.problemsAssigned}
                        renderItem={this.renderProblemToAssign}
                        onSelectionChanged={(id, selected) => {
                            this.setState({assignProblems: {...assignProblems, problemsAssigned: {...assignProblems.problemsAssigned, [id]: selected}}});
                        }}
                    />
                </div>
                <div className='adminButtonsDiv'>
                    <FilterSuggestionField
                        key='userSuggestionField'
                        type='user'
                        className='filterInput'
                        placeholder='Filter unassigned users'
                        filter={assignProblems.unassignedFilter}
                        onChange={(unassignedFilter) => {
                            this.setState({assignProblems: {...assignProblems, unassignedFilter}});
                        }}
                    />
                    <input type='button' value='Cancel' className='buttonStyle regularButtonStyle' onClick={() => {
                        this.setState({assignProblems: undefined});
                    }}/>
                    <input type='button' value='Save' className='buttonStyle regularButtonStyle' onClick={async () => {
                        let problems = {...assignProblems.project.problems};
                        Object.keys(assignProblems.problemsAssigned).forEach((id) => {
                            if (!assignProblems.problemsAssigned[id]) {
                                delete(problems[id]);
                            } else if (!assignProblems.project.problems[id]) {
                                problems[id] = {
                                    ProblemId: Number(id),
                                    ProjectId: assignProblems.project.id
                                };
                            }
                        });
                        this.props.dispatch(updateProjectProblemsAction(assignProblems.project.id, problems));
                        this.setState({assignProblems: undefined});
                    }}/>
                </div>
            </div>
        );
    }

    private renderAllProjects() {
        const isSysadmin = this.props.projects[PROJECT_SYSADMIN] ? this.props.projects[PROJECT_SYSADMIN].users[this.props.loggedInUserId] !== undefined : false;
        return (
            <div className='adminConsole'>
                <div className='majorTitle'>Projects</div>
                <p>Projects are used to control which users and problems a given administrator can edit and assign.</p>
                <div className='adminConsoleContent'>
                    {
                        Object.keys(this.props.projects).map((projectId) => {
                            const project = this.props.projects[projectId];
                            return (
                                <div key={projectId} className='adminTableRow'>
                                    <label>
                                        <div className='adminTableRadioButton'>
                                            <input type='radio' name='projectInputName' value={projectId}
                                                   checked={projectId === this.state.projectSelected}
                                                   onChange={(evt: any) => {
                                                       this.setState({projectSelected: evt.target.value});
                                                   }}/>
                                        </div>
                                        <div className='adminTableTitle'>
                                            <div>
                                                <b>Project:</b>
                                                <span className='mediumTextFont'>{project.name}</span>
                                                <div className='badges'>
                                                    {
                                                        !isSysadmin && project.users[this.props.loggedInUserId].readOnly ? (
                                                            <span title='You can see users and problems in this project, and assign users to problem teams, but cannot edit their details.'>Read-only</span>
                                                        ) : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        <hr/>
                                    </label>
                                </div>
                            )
                        })
                    }
                </div>
                <div className='adminButtonsDiv'>
                    <input type='button' value='Add Project' className='buttonStyle regularButtonStyle' onClick={async () => {
                        const response = await fetch(PROJECT_PATH, {method: 'put', credentials: 'include'});
                        if (response.ok) {
                            const projectPojo = await response.json();
                            this.setState({editProject: projectPojo, projectSelected: undefined});
                        }
                    }}/>
                    <input type='button' value='Edit Project' className='buttonStyle regularButtonStyle' onClick={() => {
                        this.setState({
                            editProject: this.props.projects[this.state.projectSelected!],
                            projectSelected: undefined
                        });
                    }} disabled={!this.state.projectSelected}/>
                    <input type='button' value='Assign Users to Project' className='buttonStyle regularButtonStyle' onClick={() => {
                        const projectId = this.state.projectSelected!;
                        const project = this.props.projects[projectId];
                        const usersAssigned = Object.keys(project.users)
                            .reduce((all, userId) => ({...all, [userId]: true}), {});
                        this.setState({
                            assignUsers: {
                                project,
                                usersAssigned
                            }, projectSelected: undefined
                        });
                    }} disabled={!this.state.projectSelected}/>
                    <input type='button' value='Assign Problems to Project' className='buttonStyle regularButtonStyle' onClick={() => {
                        const projectId = this.state.projectSelected!;
                        const project = this.props.projects[projectId];
                        const problemsAssigned = Object.keys(project.problems)
                            .reduce((all, problemId) => ({...all, [problemId]: true}), {});
                        this.setState({
                            assignProblems: {
                                project,
                                problemsAssigned
                            }, projectSelected: undefined
                        });
                    }} disabled={!this.state.projectSelected}/>
                </div>
            </div>
        );
    }

    public render() {
        if (this.state.editProject) {
            return this.renderEditProject();
        } else if (this.state.assignUsers) {
            return this.renderAssignUsersToProject();
        } else if (this.state.assignProblems) {
            return this.renderAssignProblemsToProject();
        } else {
            return this.renderAllProjects();
        }
    }

}

function mapStoreToProps(store: StoreWithSharedState): AdminProjectsPanelStoreProps {
    return {
        projects: getAllProjectsFromStore(store),
        loggedInUserId: getLoggedInUserIdFromStore(store),
        allUsers: getAllUsersFromStore(store),
        problems: getAllProblemDBsFromStore(store)
    };
}

export default connect(mapStoreToProps)(AdminProjectsPanel);