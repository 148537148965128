import React from 'react';
import {AnyAction, Dispatch} from 'redux';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import {Button} from 'react-md';

import * as constants from '../../common/util/constants';
import {CombinedTeamReducerType} from '../../common/reducers/allTeamsReducer';
import {getProblemDBFromStore, SingleProblemType} from '../../common/reducers/allProblemsReducer';
import {getUserFromStore} from '../reducers/loginReducer';
import {UserForTeamType} from '../../common/reducers/allUsersForTeamReducer';
import HeaderCurrentStep from './HeaderCurrentStep';
import HeaderCurrentTimer from './HeaderCurrentTimer';
import UserDetailsComponent from './UserDetailsComponent';
import RoleContainer from '../container/RoleContainer';
import HelpContent from './HelpContent';
import ReferenceData from '../../common/util/referenceData';
import RecentActivity from './RecentActivity';
import ProblemCalendar from './ProblemCalendar';
import {setDefaultProblemStepIdAction} from '../reducers/defaultProblemStepIdReducer';
import {BardUser} from '../../common/clientServer/bardUser';
import {sendUserOpenProblem} from '../util/userActivity';
import {getTeamFromStore} from '../../common/reducers/allTeamsReducerGetters';
import {getRoleForProblemFromStore, initialUserForTeamType} from '../../common/reducers/allUsersForTeamReducerGetters';

interface ProblemDetailProps {
    problemStepId: number;
    problemOpenedHandler: () => void;
    dispatch: Dispatch<AnyAction>;
    user: BardUser;
    team: CombinedTeamReducerType;
    problem: SingleProblemType;
    problemIsClosed: boolean;
    problemIsTraining: boolean;
    isNew: boolean;
    myRole: number;
    isOpenDisabled: boolean;
    isProblemClosedForHelp: boolean;
}

class ProblemDetail extends React.Component<ProblemDetailProps> {
    static propTypes = {
        problemStepId: PropTypes.number.isRequired,
        problemOpenedHandler: PropTypes.func.isRequired
    };

    constructor(props: ProblemDetailProps) {
        super(props);

        this.onOpen = this.onOpen.bind(this);
    }

    onOpen(problemStepId) {
        this.props.problemOpenedHandler();
        this.props.dispatch(setDefaultProblemStepIdAction(problemStepId));
        sendUserOpenProblem(problemStepId)!.catch(() => {});
    }

    render() {
        return (
            <div className='foyerProblemDetailDiv'>
                <div className='foyerProblemDetailMainHeaderDiv'>
                        <div className="foyerProblemTitleDiv">
                            <span className='majorTitle foyerProblemRightPanelTitle'>{this.props.problem.title}</span>
                            {this.props.isNew ? <span className='problemIndicatorDiv'>New!</span> : null}
                        </div>
                        <ProblemCalendar side={'right'} problemStepId={this.props.problemStepId} />
                    </div>

                    <div className='foyerProblemDetailDetailsDiv'>
                        <div>
                            <div className='miniTitle' style={{clear: 'left'}}>SUMMARY</div>
                            <div className='textFont foyerProblemDetailSummaryDiv'>{this.props.problem.summary}</div>
                        </div>
                    </div>
                    {this.props.isProblemClosedForHelp ? null :
                    <HelpContent
                        problemStep={fetchStepName(this.props.team)}
                        step={this.props.team.problemStep}
                        defaultStepId={this.props.team.problemStep.stepId}
                        user={this.props.problem.isTraining ? getTrainingUser(this.props.team.problemStep).db : this.props.team.user[this.props.user.id].db}
                    />
                    }
                    <div className='foyerOpenDiv'>
                        {/* @ts-ignore */}
                        <RoleContainer userRole={[constants.ROLE_OBSERVER, constants.ROLE_ANALYST, constants.ROLE_FACILITATOR, constants.ROLE_SOLO_ANALYST]} userRoleForProblem={this.props.myRole} problemStepId={this.props.problemStepId}>
                            <div className='foyerOpenButtonDiv'>
                                <Button 
                                    raised={true} className='buttonStyle bigButtonStyle openButtonStyle'
                                    disabled={this.props.isOpenDisabled}
                                    onClick={() => this.onOpen(this.props.problemStepId)}>
                                    OPEN PROBLEM
                                </Button>
                            </div> 
                        </RoleContainer>
                    </div>

                    {
                        (!this.props.team.problemStep.launched || this.props.myRole === constants.ROLE_SOLO_ANALYST) ? null : (
                            <div className='foyerProblemDetailActivityDiv'>
                                <div className='foyerProblemDetailActivityHeaderDiv'>
                                    <span className='miniTitle foyerProblemDetailActivityDivHeader'>LATEST ACTIVITY</span>
                                </div>
                                <RecentActivity problemStepId={this.props.problemStepId} limit={5} />
                            </div>
                        )
                    }
                    
                    <div className='foyerProblemDetailStateDiv'>
                        <HeaderCurrentStep problemStepId={this.props.problemStepId} page={constants.PAGE_FOYER}/>
                        <HeaderCurrentTimer problemStepId={this.props.problemStepId} page={constants.PAGE_FOYER}/>
                        <UserDetailsComponent
                            problemStepId={this.props.problemStepId} 
                            page={constants.PAGE_FOYER}
                            userRole={this.props.myRole}
                        />
                    </div>
            </div>
        );
    }
}

function isTeamActive(team) {
    return (team.problemStep && team.problemStep.launched && team.problemStep.stepId);
}

function fetchStepName(team) {
    const stepName = isTeamActive(team) ? ReferenceData.getInstance().getStepDescriptionName(team.problemStep.stepId).toUpperCase() : null;
    return stepName || 'BARD';
}

function getTrainingUser(problemStep): {db: UserForTeamType} {
    return {
        db: {
            ...initialUserForTeamType,
            role: (problemStep.stepId === null && problemStep.state === constants.STATE_CLOSED)? 4 : 3,
        }
    };
}

function mapStoreToProps(store, myProps) {
    const user = getUserFromStore(store);
    const problemStepId = myProps.problemStepId;
    const team = getTeamFromStore(store, problemStepId);
    const problem = getProblemDBFromStore(store, team.problemStep.problemId);
    const problemIsClosed = team.problemStep.state === constants.STATE_CLOSED;
    const problemIsTraining = problem.isTraining;
    return {
        user,
        team,
        problem,
        problemIsClosed,
        problemIsTraining,
        isNew: !problemIsTraining && !problemIsClosed && team.user[user.id].db.isNew,
        myRole: getRoleForProblemFromStore(store, problemStepId, user.id),
        isOpenDisabled: !problemIsTraining && !problemIsClosed && !team.problemStep.launched,
        isProblemClosedForHelp: team.problemStep.state === constants.STATE_CLOSED && team.problemStep.roundSequenceNumber === null && !problem.isTraining
    }
}

export default connect(mapStoreToProps)(ProblemDetail);
