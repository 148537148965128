import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {Button} from 'react-md';
import classNames from 'classnames';

import '../scss/iconLabelButton.scss';

class IconLabelButton extends Component {

    static propTypes = {
        id: PropTypes.string,
        onClick: PropTypes.func,
        className: PropTypes.string,
        iconChildren: PropTypes.string,
        label: PropTypes.string,
        disabled: PropTypes.bool,
        target: PropTypes.string,
        href: PropTypes.string,
        download: PropTypes.bool,
        primary: PropTypes.bool,
        iconBefore: PropTypes.bool,
        toolTip: PropTypes.string
    };

    render() {
        return (
            <div className={this.props.toolTip ? 'toolTipContainer' : ''}>
                <Button
                    id={this.props.id}
                    className={classNames('iconLabelButton', this.props.className)}
                    flat
                    primary={this.props.primary}
                    disabled={this.props.disabled}
                    target={this.props.target}
                    href={this.props.disabled ? undefined : this.props.href}
                    download={this.props.download}
                    onClick={this.props.onClick}
                    iconChildren={this.props.iconChildren}
                    iconBefore={this.props.iconBefore}
                    children={this.props.label}
                />
                <div className={this.props.toolTip ? 'toolTip viewProblemToolTip' : ''}>{this.props.toolTip}</div>
            </div>
        );
    }
}

export default IconLabelButton;