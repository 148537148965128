import React, {Component} from 'react';
import {RouteComponentProps} from 'react-router';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import {withRouter} from 'react-router-dom';
import {kebabCase} from 'lodash';

import * as constants from '../../common/util/constants';
import NotificationContainer from './NotificationContainer';
import MyDraftComponent from '../presentation/MyDraftComponent';
import ProblemDescriptionComponent from '../presentation/ProblemDescriptionComponent';
import InformationAdminComponent from '../presentation/InformationAdminComponent';
import GroupManagementComponent from '../presentation/GroupManagementComponent';
import PublishedComponent from '../presentation/PublishedComponent';
import DiscussionContainer from './DiscussionContainer';
import DataStatusEnum from '../../common/DataStatusEnum';
import classNames from 'classnames';
import {doesRoundMatch, getCurrentRound} from '../../common/util/roundLogic';
import {doesValueMatch} from '../../common/util/matchLogic';
import {buildRestPath, PROBLEM_PATH} from '../../common/clientServer/navigation';
import ReferenceData, {SubtabData} from '../../common/util/referenceData';
import {getProblemType} from '../../common/util/problemType';
import {SingleUserForProblemReducerType} from '../../common/reducers/allUsersForTeamReducer';
import {SingleProblemStepType} from '../../common/reducers/allTeamsReducer';
import {SingleProblemType} from '../../common/reducers/allProblemsReducer';

import '../scss/app.scss';
import '../scss/react-md.scss';
import '../scss/recentActivity.scss';

interface SubtabsContainerProps extends RouteComponentProps<{subtabName?: string}> {
    problemStepId: number;
    stepId: number;
    tabName: string;
    tabTense: number;
    subTabs: SubtabData[];
    user: SingleUserForProblemReducerType;
    problemStep: SingleProblemStepType;
    problem: SingleProblemType;
    defaultName?: string;
    tabClassName?: string;
    readOnly?: boolean;
    notificationDisabled?: boolean;
    allowMinimalSubtabs?: boolean;
}

interface SubtabsContainerState {
    buttonBarDiv: HTMLDivElement | null;
}

class SubtabsContainer extends Component<SubtabsContainerProps, SubtabsContainerState> {

    private buttonBarDiv: HTMLDivElement | null = null;

    constructor(props) {
        super(props);
        this.state = {
            buttonBarDiv: null
        };
    }

    componentDidMount() {
        this.redirectToFullPath();
        this.setState({buttonBarDiv: this.buttonBarDiv});
    }

    componentDidUpdate() {
        this.redirectToFullPath();
    }

    redirectToFullPath() {
        // Ensure that we always have a full path with tab and subtab names.
        if (!this.props.match || this.props.match.params.subtabName === undefined) {
            // Default to the first enabled tab
            const filteredSubTabs = this.getFilteredSubTabs();
            const subtabName = this.props.defaultName || filteredSubTabs.reduce((result, subtabData) => {
                return result || (this.isSubTabDisabled(subtabData) ? '' : this.getSubTabName(subtabData))
            }, '');
            if (!subtabName) {
                console.error('SubtabsContainer failed to determine subtabName - this will cause problems.');
            } else {
                const fullPath = buildRestPath(PROBLEM_PATH, {problemStepId: this.props.problemStepId, tabName: this.props.tabName, subtabName});
                this.props.history.replace(fullPath);
            }
        }
    }

    renderSubtab(subTabData) {
        if (this.isSubTabDisabled(subTabData)) {
            return null;
        }
        switch (subTabData.title) {
            case constants.SUBTAB_PROBLEM_DESCRIPTION:
                return <ProblemDescriptionComponent />;
            case constants.SUBTAB_MY_DRAFT:
                return <MyDraftComponent
                    problemStepId={this.props.problemStepId}
                    stepId={this.props.stepId}
                    readOnly={this.props.readOnly}
                    tabTense={this.props.tabTense}
                    buttonBarDiv={this.state.buttonBarDiv}
                />;
            case constants.SUBTAB_PUBLISHED:
                return <PublishedComponent
                    problemStepId={this.props.problemStepId}
                    stepId={this.props.stepId} readOnly={this.props.readOnly}
                    tabTense={this.props.tabTense}
                    buttonBarDiv={this.state.buttonBarDiv}
                />;
            case constants.SUBTAB_GROUP_DRAFT:
                return <MyDraftComponent
                    problemStepId={this.props.problemStepId}
                    stepId={this.props.stepId}
                    status={DataStatusEnum.GROUP_DRAFT}
                    tabTense={this.props.tabTense}
                    buttonBarDiv={this.state.buttonBarDiv}
                />;
            case constants.SUBTAB_GROUP:
                return <PublishedComponent
                    problemStepId={this.props.problemStepId}
                    stepId={this.props.stepId}
                    status={DataStatusEnum.GROUP}
                    role={constants.ROLE_FACILITATOR}
                    tabTense={this.props.tabTense}
                    buttonBarDiv={this.state.buttonBarDiv}
                />;
            case constants.SUBTAB_DISCUSSION:
                return <DiscussionContainer problemStepId={this.props.problemStepId} stepId={this.props.stepId} readOnly={this.props.user.db.role === constants.ROLE_OBSERVER}/>;
            case constants.SUBTAB_INFORMATION_FACILITATOR:
            case constants.SUBTAB_INFORMATION_ANALYST:
            case constants.SUBTAB_INFORMATION_OBSERVER:
                return <InformationAdminComponent problemStepId={this.props.problemStepId}/>;
             case constants.SUBTAB_GROUP_MANAGEMENT:
                return <GroupManagementComponent problemStepId={this.props.problemStepId}/>;
            default:
                return null;
        }
    }

    getFilteredSubTabs() {
        return this.props.subTabs.filter((subTabData) => (
            doesValueMatch(this.props.user.db.role, subTabData.visibleRole)
            && doesValueMatch(getProblemType(this.props.problem), subTabData.problemType)
        ));
    }

    isSubTabDisabled(subTabData) {
        let disabled = !doesValueMatch(this.props.user.db.role, subTabData.enabledRole);
        if (!disabled && subTabData.round !== undefined && this.props.user.db.role !== constants.ROLE_FACILITATOR) {
            // Disable this subtab if current round does not match the given value
            disabled = !doesRoundMatch(subTabData.round, this.props.user, this.props.problemStep, this.props.stepId, undefined, this.props.tabTense);
        }
        return disabled;
    }

    getSubTabName(subTabData) {
        return kebabCase(subTabData.title);
    }

    getTabName(stepId){
        const stepdesc = ReferenceData.getInstance().getAllStepDescriptions();
        return stepdesc[stepId].name;
    }

    renderMinimalSubtabs(subTabData) {
        return (
            <div className={classNames('subtabsContainer', {
                completedStepTab: this.props.tabTense === constants.STEP_TENSE.PAST,
                currentStepTab: this.props.tabTense === constants.STEP_TENSE.PRESENT,
                futureStepTab: this.props.tabTense === constants.STEP_TENSE.FUTURE,
                futureEditableStepTab: this.props.tabTense === constants.STEP_TENSE.FUTURE_EDITABLE
            })}>
                <div className={classNames('minimalSubtabsBar', 'react-tabs__tab-list', this.props.tabClassName)}>
                    &nbsp;
                    <span className='popOutIcon toolTipContainer'>
                        <a target='_blank' href={window.location.href} rel='noopener noreferrer'>
                            <span className='material-icons'>open_in_new</span>
                            <span className="toolTip">Open in another browser tab</span>
                        </a>
                    </span>
                </div>
                <div className='mainContentPanel react-tabs__tab-panel'>
                    {this.renderSubtab(subTabData)}
                </div>
                <div key='workspaceButtonDiv' className='workspaceButtonDiv' ref={(buttonElement) => {
                    this.buttonBarDiv = buttonElement;
                }}/>
            </div>
        );
    }

    renderSubTabs(filteredSubTabs) {
        const currentRound = getCurrentRound(this.props.stepId, this.props.problemStep);
        const isDelphiExchangeRound = (currentRound === constants.ROUND_DELPHI_EXCHANGE);
        const isPrivateRound = (currentRound === constants.ROUND_PRIVATE);
        const subtabName = (this.props.match && this.props.match.params.subtabName) || this.props.defaultName;
        const selectedIndex = filteredSubTabs.reduce((result, subTabData, index) => (
            (!this.isSubTabDisabled(subTabData) && this.getSubTabName(subTabData) === subtabName) ? index : result
        ), 0);
        const isFacilitator = (this.props.user.db.role === constants.ROLE_FACILITATOR);
        return (
            <div className={classNames('subtabsContainer', {
                completedStepTab: this.props.tabTense === constants.STEP_TENSE.PAST,
                currentStepTab: this.props.tabTense === constants.STEP_TENSE.PRESENT,
                futureStepTab: this.props.tabTense === constants.STEP_TENSE.FUTURE,
                futureEditableStepTab: this.props.tabTense === constants.STEP_TENSE.FUTURE_EDITABLE
            })}>
                <Tabs className="reactTabs" selectedIndex={selectedIndex} selectedTabClassName='selected'
                      onSelect={(index) => {
                          const subTabData = filteredSubTabs[index];
                          this.props.history.push(buildRestPath(PROBLEM_PATH, {problemStepId: this.props.problemStepId, tabName: this.props.tabName, subtabName: this.getSubTabName(subTabData)}));
                      }}
                >
                    <TabList className={classNames('react-tabs__tab-list', this.props.tabClassName)}>
                        {
                            filteredSubTabs.map((subTabData, index) => {
                                const isSubTabDisabled = this.isSubTabDisabled(subTabData);
                                return (

                                    <Tab key={index} disabled={isSubTabDisabled}>
                                            <span className={classNames('subTabs','subTab-'+this.getSubTabName(subTabData))}>
                                                {subTabData.title}
                                                {
                                                    ((isFacilitator && subTabData.facilitatorTags) || subTabData.tags) ? (
                                                        <NotificationContainer
                                                            tags={(isFacilitator && subTabData.facilitatorTags) || subTabData.tags}
                                                            showFlag={true}
                                                            stepId={this.props.stepId}
                                                            disabled={this.props.notificationDisabled}
                                                        />
                                                    ) : null
                                                }
                                            </span>
                                        <span className="toolTip toolTipSubtab">
                                                {!(subTabData.title === constants.SUBTAB_DISCUSSION) ?
                                                    subTabData.description
                                                    :
                                                    <span>
                                                    {isPrivateRound ?
                                                        (isSubTabDisabled ?
                                                                <span>Discussion about the {this.getTabName(this.props.stepId)} step not available, communicate in this Delphi process via the Facilitator</span>
                                                                :
                                                                <span>Discussion about the {this.getTabName(this.props.stepId)} step</span>
                                                        )
                                                        :
                                                        (isDelphiExchangeRound ?
                                                                (isSubTabDisabled ?
                                                                        <span>Discussion about the {this.getTabName(this.props.stepId)} step not available, communicate in this Delphi process via the Facilitator</span>
                                                                        :
                                                                        <span>Discussion about the {this.getTabName(this.props.stepId)} step</span>
                                                                )
                                                                :
                                                                (isSubTabDisabled ?
                                                                        <span>Discussion about {this.getTabName(this.props.stepId)} step will be available after you have published</span>
                                                                        :
                                                                        <span>Discussion about the {this.getTabName(this.props.stepId)} step</span>
                                                                )
                                                        )
                                                    }
                                                    </span>
                                                }
                                            </span>
                                    </Tab>
                                );
                            })
                        }
                        <span className='popOutIcon toolTipContainer'>
                            <a target='_blank' href={window.location.href} rel='noopener noreferrer'>
                                <span className='material-icons'>open_in_new</span>
                                <span className="toolTip">Open in another browser tab</span>
                            </a>
                        </span>
                    </TabList>
                    {
                        filteredSubTabs.map((subTabData, index) => {
                            return (
                                <TabPanel key={index} selectedClassName='mainContentPanel'>
                                    {this.renderSubtab(subTabData)}
                                </TabPanel>
                            );
                        })
                    }
                </Tabs>
                <div key='workspaceButtonDiv' className='workspaceButtonDiv' ref={(buttonElement) => {
                    this.buttonBarDiv = buttonElement;
                }}/>
            </div>
        );
    }

    render() {
        const filteredSubTabs = this.getFilteredSubTabs();
        return (this.props.allowMinimalSubtabs && filteredSubTabs.length === 1) ? (
            this.renderMinimalSubtabs(filteredSubTabs[0])
        ) : (
            this.renderSubTabs(filteredSubTabs)
        );
    }

}

export default withRouter(SubtabsContainer);
