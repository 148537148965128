import React, {Component} from 'react';
import Moment from 'moment';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {FontIcon} from 'react-md';

import * as constants from '../../common/util/constants';
import {getLoggedInUserIdFromStore} from '../reducers/loginReducer';
import {getProblemDBFromStore} from '../../common/reducers/allProblemsReducer';
import LaunchComponent from './LaunchComponent';
import RecentActivity from './RecentActivity';
import ReferenceData from '../../common/util/referenceData';
import HeaderCurrentStep from './HeaderCurrentStep';
import {getTeamFromStore} from "../../common/reducers/allTeamsReducerGetters";
import {getUserForProblemFromStore} from "../../common/reducers/allUsersForTeamReducerGetters";

class InformationAdminComponent extends Component {

    static propTypes = {
        problemStepId: PropTypes.number.isRequired
    };

    constructor(props) {
        super(props);

        this.state = {
            selected: ''
        };

        this.onScroll = this.onScroll.bind(this);
    }

    onScroll(evt) {
        this.recentActivity.handleScroll(evt);
    }

    renderProblemCalendar(){

        let result = null;

        if((!this.props.problem.isTraining && this.props.team.problemStep.state === constants.STATE_CLOSED) || this.props.team.problemStep.state === constants.STATE_READY || this.props.team.problemStep.state === constants.STATE_ACTIVE){
            result =
            <div className="inlineBlockDisplay">
                <div className="inlineBlockDisplay iconStyles">
                    <FontIcon>date_range</FontIcon>
                </div>
                        <div className="textFont informationAdminLeftPanelStartDate">
                            {Moment(this.props.problem.expectedStart).format("DD MMM YYYY hh:mm A")}
                        </div>  
                        <div className="textFont informationAdminLeftPanelUntil">&ndash;</div> 
                        <div className="textFont informationAdminLeftPanelEndDate">
                            {Moment(this.props.problem.expectedCompletion).format("DD MMM YYYY hh:mm A")}
                        </div>
            </div>;
        }

        return result;
    }

    render() {

        const analystCount = Object.keys(this.props.team.user).reduce((count, userId) => {
            return count + (this.props.team.user[userId].db.role === constants.ROLE_ANALYST ? 1 : 0);
        }, 0);

        return (

            <div className='informationAdminPage'>

                {/* <div className='informationAdminTopPanel'>
                    <div className='textFont informationAdminTopPanelDetailDiv'>
                        GUIDE: The steps to resolve a problem using BARD are tabs that are seen at the top. The Facilitator has to launch some of the steps in the problem to open the step to be worked on by the Analysts. Each step has sub-steps or rounds depending on what is being done at the step.
                    </div>
                </div> */}

                <div className="informationAdminColumns">

                    <div className='informationAdminLeftPanel'>
                        <div className='minorTitle informationAdminLeftPanelHeaderDiv'>Problem Information</div>
                        <div className='textFont informationAdminLeftPanelDetailDiv'>

                            <div className='textFont informationAdminLeftPanelTable'>
                                <div className="majorTitle">{this.props.problem.title}</div>
                                {this.renderProblemCalendar()}
                                <div className='informationAdminLeftPanelGroup'>
                                    <FontIcon className="md-dark iconStyles">group</FontIcon>
                                    <div className="informationAdminLeftPanelGroupName">{analystCount} ANALYST(S)</div>
                                </div>
                                <div className='informationAdminLeftPanelStep'>
                                    <HeaderCurrentStep problemStepId={this.props.problemStepId} page={constants.PAGE_STATUS}/>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className='informationAdminMiddlePanel' onScroll={this.onScroll}>
                        <div className='heading2 informationAdminMiddlePanelHeaderDiv'>Latest Activity</div>
                        <div className='textFont informationAdminMiddlePanelDetailDiv'>
                            <RecentActivity problemStepId={this.props.problemStepId} onRef={ref => (this.recentActivity = ref)} />
                        </div>
                    </div>

                    {
                        (this.props.userRole === constants.ROLE_FACILITATOR && this.props.team.problemStep.stepId !== null) ? (
                            <div className='informationAdminRightPanel'>
                                <div className='majorTitle informationAdminRightPanelHeaderDiv'>
                                    Current Step
                                </div>
                                <div className='textFont informationAdminRightPanelDetailDiv'>
                                    <LaunchComponent problemStepId={this.props.problemStepId}/>
                                </div>
                            </div>
                        ) : (
                            null
                        )
                    }

                </div>

            </div>
        )
   }
}

function mapStoreToProps(store, myProps) {
    const problemStepId = myProps.problemStepId;
    const userId = getLoggedInUserIdFromStore(store);
    const team = getTeamFromStore(store, problemStepId);
    return {
        team,
        problem: getProblemDBFromStore(store, team.problemStep.problemId),
        userRole: getUserForProblemFromStore(store, problemStepId, userId).db.role,
        rounds: ReferenceData.getInstance().getAllRounds()
    }
}

export default connect(mapStoreToProps)(InformationAdminComponent);