import DataStatusEnum from '../../common/DataStatusEnum';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import Select from 'react-select';
import moment from 'moment';

import * as constants from '../../common/util/constants';
import ReferenceData from '../../common/util/referenceData';
import HelpContent from './HelpContent';
import MethodSelectionTableComponent from './MethodSelectionTableComponent';
import {getMethodSelectionFromStore} from '../../common/reducers/methodSelectionReducer';
import {getLoggedInUserIdFromStore} from '../reducers/loginReducer';
import {
    launchRoundAction,
    launchFacilitatorAdvancedProblemAction,
    closeProblemAction
} from '../../common/reducers/allTeamsReducer';
import {getProblemDBFromStore} from '../../common/reducers/allProblemsReducer';
import CurrentStepTimer from './CurrentStepTimer';
import SingleInput from './SingleInput';
import {getNextStepAndRound, getRoundId} from '../../common/util/roundLogic';
import commonSelectStyles from '../util/commonSelectStyles';
import {getProblemStepFromStore} from "../../common/reducers/allTeamsReducerGetters";
import {
    getAllUsersForProblemFromStore,
    getUserForProblemFromStore
} from "../../common/reducers/allUsersForTeamReducerGetters";

class LaunchComponent extends Component {

    static DURATION_UNITS = [
        {label: 'Minutes', value: 'minute'},
        {label: 'Hours', value: 'hour'},
        {label: 'Days', value: 'day'},
    ];

    static DEFAULT_UNIT = LaunchComponent.DURATION_UNITS[1].value;

    static propTypes = {
        problemStepId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
        readOnly: PropTypes.bool,
        defaultDuration: PropTypes.number,
        defaultUnit: PropTypes.oneOf(LaunchComponent.DURATION_UNITS.map((unit) => (unit.value))),
        onLaunch: PropTypes.func,
        onClose: PropTypes.func,
        onExtend: PropTypes.func
    };

    static defaultProps = {
        readOnly: false,
        defaultDuration: 0,
        defaultUnit: LaunchComponent.DEFAULT_UNIT
    };

    constructor(props) {
        super(props);
        this.state = {
            durationSize: this.props.defaultDuration,
            durationUnit: this.props.defaultUnit,
            selectedIndex: 0,
            errorMsg: '',
            ...this.updateStateFromProps(props)
        };
        this.onLaunch = this.onLaunch.bind(this);
        this.onCloseProblem = this.onCloseProblem.bind(this);
        this.onChangeDuration = this.onChangeDuration.bind(this);
        this.getAnalystSelectionByCount = this.getAnalystSelectionByCount.bind(this);
        this.updateMethodSelectionForExchangeRound = this.updateMethodSelectionForExchangeRound.bind(this);
    }

    updateStateFromProps(props) {
        const {nextStepId, nextRoundSequenceNumber} = getNextStepAndRound(props.problemStep);
        return {
            launchStepId: Array.isArray(nextStepId) ? nextStepId : [nextStepId],
            launchRoundSequenceNumber: nextRoundSequenceNumber,
            currentRoundId: getRoundId(props.problemStep.stepId, props.problemStep.roundSequenceNumber)
        };
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState(this.updateStateFromProps(props), () => {
            this.updateMethodSelectionForExchangeRound(); //Need to do a callback to use the modified state variables immediately after setting them
        });
    }

    UNSAFE_componentWillMount() {
        this.updateMethodSelectionForExchangeRound();
    }

    getLaunchRoundId() {
        return (this.state.selectedIndex === undefined) ? null : getRoundId(this.state.launchStepId[this.state.selectedIndex], this.state.launchRoundSequenceNumber);
    }

    onLaunch() {
        if (this.state.durationSize <= 0) {
            this.setState({errorMsg: 'NOT LAUNCHED: Please enter a positive duration'});
        } else {
            const now = moment();
            const duration = moment.duration(this.state.durationSize, this.state.durationUnit);
            const problemExpectedCompletion = moment(this.props.problem.expectedCompletion);
            if (now.clone().add(duration).isAfter(problemExpectedCompletion)) {
                this.setState({errorMsg: 'NOT LAUNCHED: Step would extend beyond expected problem end date (' + moment(this.props.problem.expectedCompletion).format('YYYY-MM-DD HH:mm:ss') + ')'});
            } else {
                if (this.props.problemStep.launched === null) {
                    this.props.dispatch(launchFacilitatorAdvancedProblemAction(this.props.problemStepId, duration.asHours()));
                } else {
                    const launchAction = launchRoundAction(this.props.problemStepId, moment().format(), this.state.launchStepId[this.state.selectedIndex], this.state.launchRoundSequenceNumber, this.getLaunchRoundId(), duration.asHours());
                    this.props.dispatch(launchAction);
                    if (this.props.onLaunch) {
                        this.props.onLaunch(launchAction);
                    }
                }
                this.setState({selectedIndex: 0, durationSize: this.props.defaultDuration, durationUnit: this.props.defaultUnit, errorMsg: ''});
            }
        }
    }

    onCloseProblem() {
        const closeAction = closeProblemAction(this.props.problemStepId, moment().format());
        this.props.dispatch(closeAction);
        if (this.props.onClose) {
            this.props.onClose(closeAction);
        }
    }

    onChangeDuration(valueNum) {
        this.setState({durationSize: Number(valueNum), errorMsg: ''});
    }

    getAnalystSelectionByCount(countBN, countNBN) {
        if (countBN > countNBN) {
            return constants.METHOD_BN;
        } else if (countBN < countNBN) {
            return constants.METHOD_NBN;
        }
    }

    updateMethodSelectionForExchangeRound() {
        if (this.props.groupMethodSelection && this.state.launchRoundSequenceNumber === 0) {
            let selectedIndex;
            switch (this.props.groupMethodSelection.method) {
                case constants.METHOD_BN:
                    selectedIndex = 0;
                    break;
                case constants.METHOD_NBN:
                    selectedIndex = 1;
                    break;
                default:
                    selectedIndex = undefined;
                    break;
            }
            if (selectedIndex !== this.state.selectedIndex) {
                this.setState({selectedIndex});
            }
        }
    }

    createTipString() {
        let str = '';
        const stepId = (this.state.selectedIndex === undefined) ? null : this.state.launchStepId[this.state.selectedIndex];
        if (stepId) {
            str += this.props.steps[stepId].name;
            if (stepId !== constants.STEP_BAYESIAN_SOLUTION) {
                str += ' / ' + this.props.rounds[this.getLaunchRoundId()].description;
            }
        }

        return str;
    }

    render() {
        if (this.props.problemStep.state === constants.STATE_READY || this.props.problemStep.state === constants.STATE_ACTIVE) {

            const stepDescriptions = this.props.steps;

            let launchMsgDiv = null;
            let inputDiv = null;
            let infoDiv = null;
            let launchButtonDiv = null;

            let inputLabel = '';
            let str = '';
            let allocateStr = '';

            const notLaunched = (this.props.problemStep.launched === null);

            let isLaunchDisabled = (this.state.selectedIndex === undefined);

            let currentStepDiv = (
                <div>
                    <div
                        className={'adminPanelCurrentStepOuterDiv' + (notLaunched ? ' adminPanelProblemNotLaunched' : '')}>
                        <div className='foyerProblemSummaryStepInnerDiv'>
                            {notLaunched ? 'NOT LAUNCHED' : stepDescriptions[this.props.problemStep.stepId].name}
                        </div>
                    </div>
                    <span
                        className='adminPanelCurrentStepRound'>{this.state.currentRoundId ? this.props.rounds[this.state.currentRoundId].description : ''}</span>
                </div>
            );

            if (this.state.selectedIndex === undefined || this.state.launchStepId[this.state.selectedIndex] !== null) {

                inputLabel = isLaunchDisabled ?
                    'Tip: No nominated approach by the facilitator yet. Choose one and publish in the group draft to launch the next step'
                    : 'Tip: The next step is: ';

                str += this.createTipString();

                launchMsgDiv =
                    (this.props.groupMethodSelection) ?

                        //Launch //section of the Admin panel for the Build Network
                        <div className="radio launchMsgLine toolTipContainer">
                            Nominated approach - Facilitator
                            <MethodSelectionTableComponent
                                users={this.props.users}
                                groupMethodSelection={this.props.groupMethodSelection ? this.props.groupMethodSelection.method : null}
                                readOnly={true}
                                page={constants.SUBTAB_INFORMATION_FACILITATOR}
                                getAnalystSelectionByCount={this.getAnalystSelectionByCount}
                            />
                            <span className="toolTip launchApproachTip">Approach must be selected and published in the Group Draft</span>
                        </div> :
                        null;

                //SECTION TO CLICK TO GET MORE INFORMATION ABOUT THE STEP BEING LAUNCHED

                infoDiv = (isLaunchDisabled || !this.props.user) ? null : (
                        <div className='launchProblemDetailStepInfoDiv'>
                            <HelpContent
                                problemStep=
                                    {
                                        stepDescriptions[this.state.launchStepId[this.state.selectedIndex]] ?
                                            (this.props.problemStep.launched ? stepDescriptions[this.state.launchStepId[this.state.selectedIndex]].name.toUpperCase() : 'Explore Problem') : null
                                    }
                                step={this.props.problemStep}
                                defaultStepId={this.props.steps[this.state.launchStepId[this.state.selectedIndex]].id}
                                user={this.props.user.db}
                            />
                        </div>
                    );

                //SECTION TO INPUT & SET THE NUMBER OF HOURS FOR THE STEP BEING LAUNCHED
                inputDiv = isLaunchDisabled ? null : (
                    <div className='textFont launchInputDiv'>
                        <SingleInput inputType='number' className='launchInput'
                                     content={this.state.durationSize} onChange={this.onChangeDuration}/>
                        <Select
                            className='launchInputUnitSelect'
                            options={LaunchComponent.DURATION_UNITS}
                            value={LaunchComponent.DURATION_UNITS.filter(({value}) => (value === this.state.durationUnit))}
                            styles={commonSelectStyles}
                            isClearable={false}
                            onChange={(selected) => {
                                this.setState({durationUnit: selected.value})
                            }}
                        />
                    </div>
                );


                //SECTION TO DISPLAY LAUNCH BUTTON
                launchButtonDiv =
                    <div className='launchButtonDiv toolTipContainer'>
                        <button className='buttonStyle bigButtonStyle launchButtonStyle'
                                disabled={isLaunchDisabled}
                                onClick={this.onLaunch}>
                            {this.props.problemStep.launched === null
                                ? 'LAUNCH PROBLEM'
                                : (this.state.launchRoundSequenceNumber === 0 ? 'LAUNCH STEP' : 'LAUNCH ROUND')
                            }
                        </button>
                        {isLaunchDisabled ?
                            <span className="toolTip launchButtonTip">To launch the next step, select an approach in Group Draft and Publish</span>
                            :
                            null}
                    </div>


            } else {
                launchButtonDiv = <div className='launchButtonDiv'>
                    <button className='bigButtonStyle closeButtonStyle' onClick={this.onCloseProblem}>CLOSE PROBLEM
                    </button>
                </div>
            }

            //SECTION TO DISPLAY ERRORS
            let errorDiv =
                <div className='textFont launchErrorDiv'>
                    {this.state.errorMsg}
                </div>;

            return (
                <div className='launchDiv'>

                    <div className="currentStepBodyDiv" style={{align: "center"}}>
                        <div className='adminPanelCurrentStepDiv'>
                            {currentStepDiv}
                        </div>
                        <div className='adminPanelTimeRemainingDiv'>
                            {notLaunched ? (null) : (
                                <CurrentStepTimer
                                    problemStepId={Number(this.props.problemStepId)}
                                    stepId={this.props.problemStep.stepId}
                                    step={stepDescriptions[this.props.problemStep.stepId].name}
                                    roundId={this.state.currentRoundId}
                                    round={this.state.currentRoundId ? this.props.rounds[this.state.currentRoundId].description : ''}
                                    onExtend={this.props.onExtend}
                                />
                            )}
                        </div>
                        <div className='adminPanelLaunchInfoDiv'>
                            {launchMsgDiv}
                        </div>
                    </div>
                    <div className="adminPanelNextStepHeaderDiv">
                        Next Step
                    </div>
                    <div className='launchBodyDiv'>
                        <div className="launchMsgLine">{inputLabel}{str}{allocateStr}
                        </div>
                        {infoDiv}
                        <br/>
                        {this.state.selectedIndex !== undefined && this.state.launchStepId[this.state.selectedIndex] === null
                            ? 'The problem can be closed.'
                            :
                            (isLaunchDisabled ? null :
                                    <div className='mediumTextFont-px'>
                                        Set time for {str}
                                    </div>
                            )
                        }
                        {inputDiv}
                        {errorDiv}

                    </div>

                    <div className='launchButtonContainer'>
                        {launchButtonDiv}
                    </div>
                </div>
            )
        } else {
            return (
                <div>
                    Not able to launch.
                </div>
            );
        }
    }
}

function mapStoreToProps(store, myProps) {
    const problemStepId = myProps.problemStepId;
    let userId = myProps.userId || getLoggedInUserIdFromStore(store);
    const steps = ReferenceData.getInstance().getAllStepDescriptions();
    const problemStep = getProblemStepFromStore(store, problemStepId);
    const users = getAllUsersForProblemFromStore(store, problemStepId);
    const stepDescription = problemStep.stepId && steps[problemStep.stepId];
    let groupMethodSelection;
    if (stepDescription && stepDescription.nextStepSyncVariable === constants.SYNC_METHOD_SELECTION) {
        const facilitatorId = Object.keys(users).reduce((id, userId) => (id || (users[userId].db.role === constants.ROLE_FACILITATOR ? userId : null)), null);
        groupMethodSelection = getMethodSelectionFromStore(store, problemStepId, facilitatorId, DataStatusEnum.GROUP)
    }
    return {
        users,
        steps,
        rounds: ReferenceData.getInstance().getAllRounds(),
        problemStepId,
        problemStep,
        problem: getProblemDBFromStore(store, problemStep.problemId),
        groupMethodSelection,
        user: getUserForProblemFromStore(store, problemStepId, userId)
    }
}

export default connect(mapStoreToProps)(LaunchComponent);