import {AnyAction} from 'redux';
import {merge} from 'lodash';

import {FEATURE_FLAG_DEFAULT_VALUES, FeatureFlag} from '../util/featureFlags';
import ensureFieldPath from '../ensureFieldPath';

export const SET_FEATURE_FLAGS = 'set-feature-flags';

export type FeatureFlagsReducerType = {[key: string]: FeatureFlag};

export default function featureFlagsReducer(state: FeatureFlagsReducerType = FEATURE_FLAG_DEFAULT_VALUES, action: AnyAction): FeatureFlagsReducerType {
    switch (action.type) {
        case SET_FEATURE_FLAGS:
            // Use lodash merge to do deep merge of flags and state
            return merge({}, state, action.flags);
        default:
            return state;
    }
}

export function getDBSyncActionTypes() {
    return [SET_FEATURE_FLAGS];
}

export function setFeatureFlagsInStore(store, object) {
    ensureFieldPath(store, 'sharedState');
    store.sharedState.featureFlags = {...store.sharedState.featureFlags, ...object};
}

export function setFeatureFlagsAction(flags: {[key: string]: any}) {
    return {type: SET_FEATURE_FLAGS, flags};
}

export function getFeatureFlagsFromStore(store): FeatureFlagsReducerType {
    return store.sharedState.featureFlags
}