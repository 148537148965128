import React, { PureComponent } from 'react';
import { Button, DialogContainer } from 'react-md';
import PropTypes from 'prop-types';

import '../scss/alertDialog.scss';

class AlertDialog extends PureComponent {
    
    static propTypes = {
        dialogContent: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        visible: PropTypes.bool.isRequired,
        dialogTitle: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
        width: PropTypes.number,
        height: PropTypes.number
    };

    constructor(props) {
        super(props);
        this.state = {
            visible: props.visible
        };

        this.hide = this.hide.bind(this);
    }

    UNSAFE_componentWillReceiveProps(nextProps) {
        this.setState({ visible: nextProps.visible });
    }

    hide() {
        this.setState({ visible: false });
        this.props.onCancelAction();
    }

    render() {
        const { visible } = this.state;

        const actions = [
            <Button raised primary onClick={this.hide}>OK</Button>
        ];

        return (
            <div>
                <DialogContainer
                    id='bard-simple-alert-modal'
                    visible={visible}
                    onHide={this.hide}
                    actions={actions}
                    title={this.props.dialogTitle}
                    width={this.props.width}
                    height={this.props.height}>
                    <div className='alertDialogText'>
                        {this.props.dialogContent}
                    </div>
                </DialogContainer>
            </div>
        );
    }
}

export default AlertDialog
