import {AnyAction, Reducer} from 'redux';

import DataStatusEnum from '../DataStatusEnum';
import ensureFieldPath from '../ensureFieldPath';
import {singleFieldReducer, objectMapReducer, timestampedReducer, ObjectMapState} from '../util/genericReducers';
import * as constants from '../util/constants';
import {StoreWithSharedState} from './sharedStateReducer';
import {BayesNetGraphState, initialBayesNetGraphState} from './bayesNetGraphReducer';

export interface SetBayesNetProbabilitiesForVariableAction extends AnyAction {
    problemStepId: number;
    userId: number;
    status: DataStatusEnum;
    variableId: string;
    permutation?: number;
    probabilities: ProbabilityDistribution | ProbabilityDistribution[];
}

export enum BayesNetParameterEnum {
    MANUAL = 'manual',
    NOISY_OR = 'noisyOr'
}

export interface ManualParameterType {
    type: BayesNetParameterEnum.MANUAL
}

export interface NoisyOrParameterType {
    type: BayesNetParameterEnum.NOISY_OR,
    probabilities: {[sourceVariableId: string]: ProbabilityDistribution}
}

export type BayesNetParameterType = ManualParameterType | NoisyOrParameterType;

export type ProbabilityDistribution = (number | null)[];

export interface BayesNetParametersState extends BayesNetGraphState {
    probabilities: ObjectMapState<ProbabilityDistribution[]>;
    parameters: ObjectMapState<BayesNetParameterType>;
}

export function isNoisyOrParameterType(parameters: BayesNetParameterType): parameters is NoisyOrParameterType {
    return (parameters as NoisyOrParameterType).probabilities !== undefined;
}

// ======== Constants

const SET_BN_PARAMETERS_STATE = 'set BN parameters state';
const SET_BN_PROBABILITIES_FOR_VARIABLE = 'set BN probabilities for variable';
const SET_BN_PARAMETERS_FOR_VARIABLE = 'set BN parameters for variable';

export const initialBayesNetParametersState: BayesNetParametersState = {
    ...initialBayesNetGraphState,
    probabilities: {},
    parameters: {}
};

// ======== Reducers

const probabilitiesForSpecificVariableReducer: Reducer<ProbabilityDistribution[]> = (state = [], action: SetBayesNetProbabilitiesForVariableAction | AnyAction) => {
    if (action.permutation === undefined) {
        return action.probabilities as ProbabilityDistribution[];
    } else {
        let result = [...state];
        result[action.permutation] = action.probabilities as ProbabilityDistribution;
        return result;
    }
};

const probabilitiesForAllVariablesReducer = singleFieldReducer('probabilities', initialBayesNetParametersState,
    objectMapReducer('variableId', probabilitiesForSpecificVariableReducer));

const parametersForSpecificVariableReducer: Reducer<BayesNetParameterType> = (_state, action: SetBayesNetProbabilitiesForVariableAction | AnyAction) => {
    return action.parameters;
};

const parametersForAllVariablesReducer = singleFieldReducer('parameters', initialBayesNetParametersState,
    objectMapReducer('variableId', parametersForSpecificVariableReducer));

const bayesNetParametersReducer: Reducer<BayesNetParametersState> = (state = initialBayesNetParametersState, action) => {
    switch (action.type) {
        case SET_BN_PARAMETERS_STATE:
            return action.parametersState;
        case SET_BN_PROBABILITIES_FOR_VARIABLE:
            return probabilitiesForAllVariablesReducer(state, action);
        case SET_BN_PARAMETERS_FOR_VARIABLE:
            return parametersForAllVariablesReducer(state, action);
        default:
            return state;
    }
};

const bayesNetParametersTimestampedReducer = timestampedReducer(bayesNetParametersReducer);

const bayesNetParametersCopyableReducer: Reducer<BayesNetParametersState> = (state, action) => {
    switch (action.type) {
        case constants.COPY_PUBLISHED_ACTION:
        case constants.APPEND_PUBLISHED_ACTION:
            return action.fromUser.bayesNetParameters ? {...action.fromUser.bayesNetParameters} : state;
        default:
            return bayesNetParametersTimestampedReducer(state, action);
    }
};

export default bayesNetParametersCopyableReducer;

// ======== Service functions
// (used by DBSync and for unit testing)

export function getDBSyncActionTypes() {
    return [SET_BN_PARAMETERS_STATE, SET_BN_PROBABILITIES_FOR_VARIABLE, SET_BN_PARAMETERS_FOR_VARIABLE];
}

export function setBayesNetParametersInStore(store: StoreWithSharedState, problemStepId: number, userId: number, status: DataStatusEnum, object: BayesNetParametersState) {
    ensureFieldPath(store, 'sharedState', 'teams', problemStepId, 'user', userId, 'status', [status]);
    store.sharedState.teams[problemStepId].user[userId].status[status].bayesNetParameters = object;
}

export function getParameterUpdateFromAction(action: AnyAction): BayesNetParameterType | null {
    switch (action.type) {
        case SET_BN_PARAMETERS_STATE:
            return action.parametersState.parameters[action.variableId];
        case SET_BN_PARAMETERS_FOR_VARIABLE:
            return action.parameters;
        default:
            return null;
    }
}

// ======== Action generator functions

export function setBayesNetParametersStateAction(problemStepId: number, userId: number, status: DataStatusEnum, parametersState: BayesNetParametersState) {
    return {type: SET_BN_PARAMETERS_STATE, problemStepId, userId, status, parametersState};
}

export function setBayesNetProbabilitiesForVariableAction(problemStepId: number, userId: number, status: DataStatusEnum, variableId: string, probabilities: ProbabilityDistribution | ProbabilityDistribution[], permutation?: number): SetBayesNetProbabilitiesForVariableAction {
    return {type: SET_BN_PROBABILITIES_FOR_VARIABLE, problemStepId, userId, status, variableId, probabilities, permutation};
}

export function setBayesNetParametersForVariableAction(problemStepId: number, userId: number, status: DataStatusEnum, variableId: string, parameters: BayesNetParameterType) {
    return {type: SET_BN_PARAMETERS_FOR_VARIABLE, problemStepId, userId, status, variableId, parameters};
}

// ======== Getter functions

export function getBayesNetParametersFromStore(store: StoreWithSharedState, problemStepId: number, userId: number, status: DataStatusEnum): BayesNetParametersState {
    return store.sharedState.teams[problemStepId].user[userId].status[status].bayesNetParameters;
}
