import React from "react";

const CoLaLink = ({
    className,
    source,
    target,
    d,
    onMouseDown,
    style,
    refFunc
}) => (
    <path
        className={className}
        ref={refFunc}
        d={
            d
                ? d
                : `
M ${source.x}  ${source.y}
L ${target.x}  ${target.y}
`
        }
        style={{ fill: "none", strokeWidth: 2, ...style }}
        onMouseDown={onMouseDown}
    />
);

export default CoLaLink;
