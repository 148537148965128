import React from "react";
import PropTypes from "prop-types";
import ReactDOM from "react-dom";
import loadScript from 'load-script';

const defaultScriptUrl = "https://cdn.ckeditor.com/4.8.0/standard/ckeditor.js";

/**
 * @author codeslayer1
 * @description CKEditor component to render a CKEditor textarea with defined configs and all CKEditor events handler
 * https://github.com/codeslayer1/react-ckeditor/blob/master/src/ckeditor.js
 */
class CKEditor extends React.Component {
  constructor(props) {
    super(props);

    //Bindings
    this.onLoad = this.onLoad.bind(this);

    //State initialization
    this.state = {
      isScriptLoaded: this.props.isScriptLoaded,
      config: this.props.config
    };
  }

  //load ckeditor script as soon as component mounts if not already loaded
  componentDidMount() {
    if(!this.props.isScriptLoaded){
      loadScript(this.props.scriptUrl, this.onLoad);
    }else{
      this.onLoad();
    }
  }

  reloadEditor() {
      clearTimeout(this.timeout);
      if (!this.editorInstance.ui.editor.loaded) {
        loadScript(this.props.scriptUrl, this.onLoad);
      }
  }

  componentWillUnmount() {
    this.unmounting = true;
  }

  onLoad() {
    if (this.unmounting) return;

    this.setState({
      isScriptLoaded: true
    });

    if (!window.CKEDITOR) {
      console.error("CKEditor not found");
      return;
    }

    if (this.editorInstance) {
        try {
          this.editorInstance.destroy(true);
        } catch (e) {
          console.log('Error trying to remove duplicate CKEditor:', e);
        }
    }

    this.editorInstance = window.CKEDITOR.appendTo(
      ReactDOM.findDOMNode(this),
      this.state.config,
      this.props.content
    );

    //Register listener for custom events if any
    for (let event in this.props.events){
      const eventHandler = this.props.events[event];

      this.editorInstance.on(event, eventHandler);
    }

    this.timeout = setTimeout(this.reloadEditor.bind(this), 2000);
  }

  render() {
    return <div className={this.props.activeClass} />;
  }
}

CKEditor.defaultProps = {
  content: "",
  config: {},
  isScriptLoaded: false,
  scriptUrl: defaultScriptUrl,
  activeClass: "",
  events: {}
};

CKEditor.propTypes = {
  content: PropTypes.any,
  config: PropTypes.object,
  isScriptLoaded: PropTypes.bool,
  scriptUrl: PropTypes.string,
  activeClass: PropTypes.string,
  events: PropTypes.object
};

export default CKEditor;