import React, {Component, ReactElement} from 'react';
import { Button, DialogContainer } from 'react-md';
import classNames from 'classnames';

import '../scss/confirmationDialog.scss';

interface ConfirmationDialogProps {
    dialogTitle: String | ReactElement;
    dialogContent: String | ReactElement;
    onHideAction: () => void;
    onCancelAction: () => void;
    className?: string;
    visible: boolean;
    actionButtonContent?: String | ReactElement;
    width?: number | string;
    height?: number | string;
    actionButtonDisabled?: boolean;
    allowVerticalScroll?: boolean;
}

class ConfirmationDialog extends Component<ConfirmationDialogProps> {
    
    constructor(props) {
        super(props);
        this.confirm = this.confirm.bind(this);
        this.cancel = this.cancel.bind(this);
        this.hide = this.hide.bind(this);
    }

    hide(cancelling) {
        if (cancelling) {
            this.props.onCancelAction();
        } else {
            this.props.onHideAction();
        }
    }

    cancel() {
        this.hide(true);
    }

    confirm() {
        this.hide(false);
    }

    render() {
        const actions = [
            { secondary: false, children: 'Cancel', onClick: this.cancel },
            <Button key='primaryButton' raised={true} primary={true} disabled={this.props.actionButtonDisabled}
                className={classNames('flatButtonStyle', 'roleBackgroundColour', {disabled: this.props.actionButtonDisabled})}
                onClick={this.confirm}
            >
                {this.props.actionButtonContent || 'Confirm'}
            </Button>
        ];

        return (
            <div className={this.props.className}>
                <DialogContainer
                    className={classNames({autoSizingHeight: !this.props.height})}
                    id='bard-simple-confirmation-modal'
                    visible={this.props.visible}
                    autosizeContent={false}
                    onHide={this.hide}
                    actions={actions}
                    title={this.props.dialogTitle}
                    width={this.props.width}
                    height={this.props.height}>
                    <div className='confirmationDialogText'>
                        {this.props.dialogContent}
                    </div>
                </DialogContainer>
            </div>
        );
    }
}

export default ConfirmationDialog
