import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getUserFromStore} from '../reducers/loginReducer';
import ReferenceData from '../../common/util/referenceData';
import * as constants from '../../common/util/constants';
import WhatsNext from './WhatsNext';
import {getProblemDBFromStore} from '../../common/reducers/allProblemsReducer';
import {getProblemStepFromStore, getTeamFromStore} from "../../common/reducers/allTeamsReducerGetters";
import {getUserForProblemFromStore} from "../../common/reducers/allUsersForTeamReducerGetters";

import bardCurrentStepImg from '../images/STATUS_ICONS_STEPS.svg';

class HeaderCurrentStep extends Component {

    static propTypes = {
        problemStepId: PropTypes.number.isRequired,
        page: PropTypes.string
    };

    static defaultProps = {
        page: constants.PAGE_HEADER
    };


    render() {
        const toolTip = <span className='toolTip toolTipHeaderIcons'>There are 7 steps in the BARD process</span>;
        const problemIsTraining = this.props.problem.isTraining;
        const problemIsClosed = this.props.problemStep.state === constants.STATE_CLOSED;

        const stepDescription = this.props.problemStep.stepId && ReferenceData.getInstance().getStepDescription(this.props.problemStep.stepId);
        const roundId = (stepDescription && this.props.problemStep.roundSequenceNumber !== null) ? stepDescription.stepRounds[this.props.problemStep.roundSequenceNumber] : null;
        const rounds = ReferenceData.getInstance().getAllRounds();

        const isStatusPage = this.props.page === constants.PAGE_STATUS;
        const displayWhatsNext = !isStatusPage && this.props.page === constants.PAGE_HEADER && roundId && (this.props.user.db.role !== constants.ROLE_OBSERVER) &&!problemIsTraining && !problemIsClosed && this.props.team.isFullyLoaded;

        return (!problemIsClosed && this.props.problemStep.stepId === null) ? null : (
            <div
                className={'headerCurrentStep ' + (this.props.page === constants.PAGE_FOYER ? 'foyerProblemStatusDivStyles' : '') + (problemIsTraining ? 'training' : '')}>
                <div className='imageDiv toolTipContainer'>
                    <img className='iconStyles' src={bardCurrentStepImg} alt="BARD"/>
                    {toolTip}
                </div>
                <div className="headerLabelDiv">
                    {
                        displayWhatsNext ? 
                        (
                            <WhatsNext  user={this.props.user} 
                                        problemStep={this.props.problemStep}
                                        problemStepId={this.props.problemStepId} 
                                        round={roundId}
                            />
                        ) : 
                        (
                            null
                        )
                    }
                    {
                        (
                            problemIsClosed ? (
                                <div className='problemStep'>PROBLEM CLOSED</div>
                            ) : (
                                (this.props.problemStep.launched === null) ? (
                                    <div className='problemStep'>NOT LAUNCHED</div>
                                ) : (
                                    <div className='problemStep'>
                                        <div className='problemStepName'>{stepDescription && (stepDescription.tabName || stepDescription.name)}</div>
                                        <div className='problemStepRound'>{roundId && (roundId !== constants.ROUND_OPEN) && (roundId !== constants.ROUND_REPORT_EXCHANGE) ? ' ('+rounds[roundId].description+')' : ''}</div>
                                    </div>
                                )
                            )
                        )
                    }
                </div>
            </div>
        );
    }
}

function mapStoreToProps(store, myProps) {
    const loggedInUser = getUserFromStore(store);
    const problemStep = getProblemStepFromStore(store, myProps.problemStepId);
    return {
        problemStep,
        team: getTeamFromStore(store, myProps.problemStepId),
        user: getUserForProblemFromStore(store, myProps.problemStepId, loggedInUser.id),
        problem: getProblemDBFromStore(store, problemStep.problemId)
    };
}

export default connect(mapStoreToProps)(HeaderCurrentStep);