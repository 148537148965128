import React, {Component} from 'react';
import {Link} from 'react-router-dom';

import {LOGOUT_REASON_SERVICE_UNAVAILABLE} from '../../common/util/constants';
import { ROOT_PATH } from '../../common/clientServer/navigation';
import '../scss/app.scss';
import '../scss/503.scss';

class ServiceUnavailablePage extends Component {
    render() {
        return (
            <div className='homeBody homeBodyTextStyle md-grid--stacked'>
                <div className='md-cell md-cell--4 md-cell--middle'>
                    {LOGOUT_REASON_SERVICE_UNAVAILABLE}
                </div>
                <div className='md-cell md-cell--4 md-cell--middle'><Link className="redirectLink" to={ROOT_PATH}>Go back to Login page.</Link></div>            </div>
        );
    }
}

export default ServiceUnavailablePage;
