import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {SortableContainer} from 'react-sortable-hoc';

import '../scss/reorderableComponents.scss';

/**
 * A container whose children can be dragged to be reordered (assuming they have been wrapped by react-sortable-hoc's
 * SortableElement() function, such as ReorderableDeleteableElement).  The children appear in the body of the element,
 * as usual.  The component can also render an "add" button which calls the provided callback function when clicked.
 *
 * @property disableAdd {Boolean} (default false) If true, the "add" button is not shown.
 * @property addLabel {String} (default '') The string to show in the "add" button.
 * @property onAdd {Function} The function to call when the "add" button is clicked.
 * @property readOnly {Boolean} (default false) disable all editing (equivalent to disableAdd=true).
 * @property maxChildren {Number} If provided, the "add" button will be automatically disabled if the number of children
 * that this component encloses equals or exceeds this number.
 * @property numChildren {Number} If provided, this contains the current number of children of the container.  Otherwise,
 * the length of the react props.children prop is used.
 *
 * <b>NOTE:</b> This component is wrapped with react-sortable-hoc's SortableContainer function, which means that it also
 * accepts all of SortableContainer's properties.  See
 * https://github.com/clauderic/react-sortable-hoc#sortablecontainer-hoc for more information.  Some properties of
 * particular relevance:
 *
 * @property onSortEnd {Function} If provided, this is called when a user finishes dragging a child element.  It is
 * called with parameters ({oldIndex, newIndex, collection}, event).  See react-sortable-hoc documentation for more
 * details.
 */
class ReorderableAddableContainer extends Component {

    static propTypes = {
        disableAdd: PropTypes.bool,
        addLabel: PropTypes.string,
        onAdd: PropTypes.func,
        readOnly: PropTypes.bool,
        maxChildren: PropTypes.number,
        numChildren: PropTypes.number
    };

    static defaultProps = {
        disableAdd: false,
        addLabel: '',
        readOnly: false
    };

    renderAddButton() {
        const TOOLTIP_ADD = this.props.addLabel === 'HYPOTHESIS' ? 'Add: “Add a Hypothesis”' : this.props.addLabel === 'EVIDENCE' ? 'Add: “Add an Evidence”' 
                        : this.props.addLabel.indexOf('TARGET') >= 0 ? "Add a Target Variable" : this.props.addLabel.indexOf('OTHER') >= 0 ? "Add Other Variable" 
                        : this.props.addLabel === 'NEW SCENARIO' ? 'Add a New Scenario' : null;

        if (this.props.disableAdd || this.props.readOnly ||
                (typeof(this.props.maxChildren) !== 'undefined' && (this.props.numChildren || this.props.children.length) >= this.props.maxChildren)) {
            return null;
        } else {
            return (
                <div className="reorderableAddableAddControl">
                    <span className="actionColor textLinkStyle addIconStyle toolTipContainer" onClick={() => {
                        this.props.onAdd();
                    }}>
                    <span className={TOOLTIP_ADD ? 'toolTip toolTipRight' : ''}>{TOOLTIP_ADD}</span>
                        <span className="material-icons addIconStyle">add</span>
                        {this.props.addLabel}
                    </span>
                </div>
            );
        }
    }

    render() {
        return (
            <div className="reorderableAddableContainer">
                {this.props.children}
                {this.renderAddButton()}
            </div>
        );
    }
}

function defaultUseDragHandleTrue(WrappedComponent) {
    return (props) => (<WrappedComponent useDragHandle={true} {...props}/>);
}

export default defaultUseDragHandleTrue(SortableContainer(ReorderableAddableContainer));