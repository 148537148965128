import React from "react"; // eslint-disable-line no-unused-vars

import SVGText from "./SVGText";

const CoLaNode = ({
    x = 0,
    y = 0,
    children,
    onMouseEnter,
    onMouseLeave,
    onMouseDown,
    onMouseMove,
    onMouseOut,
    rx,
    ry,
    width,
    height,
    style,
    textStyle,
    horizontalPadding,
    customNodeDrawingSVG,
    customNodeOverlayComponent
}) => (
    <g
        transform={`translate(${x}, ${y})`}
        onMouseEnter={onMouseEnter}
        onMouseLeave={onMouseLeave}
        onMouseDown={onMouseDown}
        onMouseMove={onMouseMove}
        onMouseOut={onMouseOut}
    >
        {
            (customNodeDrawingSVG) ?
                customNodeDrawingSVG
            :
                <rect className="nodeRect"
                    rx={rx}
                    ry={ry}
                    x={-width / 2}
                    y={-height / 2}
                    width={width}
                    height={height}
                    style={style} />
        }
        <SVGText
            style={{
                ...textStyle
            }}
            x={-width / 2}
            y={-height / 2}
            width={width}
            height={height}
            horizontalPadding={horizontalPadding}
            horizontalAlign="center"
            verticalAlign="middle"
        >
            {typeof children === "string" ? children : ""}
        </SVGText>
        {customNodeOverlayComponent}
    </g>
);

export default CoLaNode;
