import {AnyAction, Reducer} from 'redux';

import {objectMapReducer, ObjectMapState} from '../util/genericReducers';
import ensureFieldPath from '../ensureFieldPath';
import {RESET_PROBLEM_STEP, USER_ANY, Tag} from '../util/constants';
import { StoreWithSharedState } from './sharedStateReducer';

const ADD_NOTIFICATION = 'addNotification';
export const DISMISS_NOTIFICATION = 'dismissNotification';

export const NOTIF_GLOBAL = 'global';

// WARNING: the values of the labels in this enum are persisted in the DB.  Once there is production data, do not change
// them unless you want to do the work to migrate the data in the DB as well.
export enum NotificationType {
    FLAG = 1,
    COUNT = 2,
    PER_KEY_FLAG = 3,
    PER_KEY_COUNT = 4
}

export interface SingleNotificationState {
    type: NotificationType;
    sender?: number;
    value: ObjectMapState<number | boolean> | number | boolean;
}

export function getDBSyncActionTypes() {
    return [ ADD_NOTIFICATION, DISMISS_NOTIFICATION, RESET_PROBLEM_STEP ];
}

const addNotificationValuePerKeyValueReducer: Reducer<number|boolean> = (state = false, action) => {
    const type = action.notificationType;
    switch (type) {
        case NotificationType.PER_KEY_FLAG:
            if (!state) {
                return true;
            }
            break;
        case NotificationType.PER_KEY_COUNT:
            return (Number(state) || 0) + 1;
        default:
            break;
    }
    return state;
};

const addNotificationPerKeyValueReducer = objectMapReducer('keyValue', addNotificationValuePerKeyValueReducer);

const initialSingleNotificationState: SingleNotificationState = {type: NotificationType.FLAG, value: false};

const addNotification: Reducer<SingleNotificationState> = (state = initialSingleNotificationState, action) => {
    const type = action.notificationType;
    switch (type) {
        case NotificationType.FLAG:
            if (!state.value) {
                return { type, sender: action.sender, value: true };
            }
            break;
        case NotificationType.COUNT:
            return { type, value: (Number(state.value) || 0) + 1 };
        case NotificationType.PER_KEY_FLAG:
        case NotificationType.PER_KEY_COUNT:
            let value: ObjectMapState<number | boolean> = state.value ? state.value as ObjectMapState<number | boolean> : {};
            return { type, sender: action.sender, value: addNotificationPerKeyValueReducer(value, action) };
        default:
            break;
    }
    return state;
};

const removeNotificationPerKeyValueReducer = objectMapReducer('keyValue',
    (state?: number | boolean) => (state === undefined ? false : state),
    {deleteActionType: DISMISS_NOTIFICATION});

const dismissNotification: Reducer<SingleNotificationState> = (state = initialSingleNotificationState, action) => {
    const type = state && state.type;
    switch (type) {
        case NotificationType.PER_KEY_FLAG:
        case NotificationType.PER_KEY_COUNT:
            if (action.keyValue) {
                return { type, value: removeNotificationPerKeyValueReducer(state.value as ObjectMapState<number | boolean>, action) };
            }
            break;
        default:
            break;
    }
    // Returning undefined from a sub-reducer of objectMapReducer removes the value from parent.
    return undefined as any;
};

const perRecipientPerStepPerTagReducer: Reducer<SingleNotificationState> = (state = initialSingleNotificationState, action) => {
    switch (action.type) {
        case ADD_NOTIFICATION:
            return addNotification(state, action);
        case DISMISS_NOTIFICATION:
            return dismissNotification(state, action);
        default:
            return state;
    }
};

const perRecipientPerStepReducer: Reducer<ObjectMapState<SingleNotificationState>> = objectMapReducer('tags', perRecipientPerStepPerTagReducer);

export type NotificationsForUserType = ObjectMapState<ObjectMapState<SingleNotificationState>>;

const perRecipientReducer: Reducer<NotificationsForUserType> = objectMapReducer('stepId', perRecipientPerStepReducer);

export type NotificationsReducerType = ObjectMapState<NotificationsForUserType>;

const allRecipientsReducer: Reducer<NotificationsReducerType> = objectMapReducer('recipients', perRecipientReducer);

const notificationsReducer: Reducer<NotificationsReducerType> = (state = {}, action) => {
    switch (action.type) {
        case RESET_PROBLEM_STEP:
            return {};
        case ADD_NOTIFICATION:
        case DISMISS_NOTIFICATION:
            return allRecipientsReducer(state, action);
        default:
            return state;
    }
};

export default notificationsReducer;

export function shouldAcknowledge(action: AnyAction): boolean {
    return (action.type === DISMISS_NOTIFICATION && action.acknowledge);
}

export function addNotificationAction(tags: Tag[], sender: number, recipients: number[], notificationType: NotificationType, problemStepId: string | number, stepId: string | number = NOTIF_GLOBAL, keyValue?: number) {
    let filteredRecipients = recipients.filter(recipient => recipient !== USER_ANY);
    return { type: ADD_NOTIFICATION, tags, sender, recipients: filteredRecipients, notificationType, problemStepId, stepId, keyValue };
}

export function dismissNotificationAction(tags: Tag[], recipient: number, problemStepId: string | number, stepId: string | number = NOTIF_GLOBAL, keyValue?: number | string, acknowledge?: string[]) {
    return { type: DISMISS_NOTIFICATION, tags, recipients: (recipient === USER_ANY ? [] : [ recipient ]), problemStepId, stepId, keyValue, acknowledge };
}

export function setNotificationForUserInStore(store: StoreWithSharedState, problemStepId: number, userId: number, notifications: NotificationsForUserType) {
    ensureFieldPath(store, 'sharedState', 'teams', problemStepId, 'notifications', userId);
    store.sharedState.teams[problemStepId].notifications[userId] = notifications;
}

export function getAllNotificationsForProblemStepFromStore(store: StoreWithSharedState, problemStepId): NotificationsReducerType {
    return store.sharedState.teams[problemStepId].notifications;
}

export function getNotificationsForProblemStepAndUserFromStore(store, problemStepId, userId): NotificationsForUserType {
    return store.sharedState.teams[problemStepId].notifications[userId];
}