import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import PlainTextField from '../presentation/PlainTextField';

import * as constants from '../../common/util/constants';
import MethodSelectionTableComponent from '../presentation/MethodSelectionTableComponent';
import DataStatusEnum from '../../common/DataStatusEnum';
import {getMethodSelectionFromStore, updateMethodSelectionAction} from '../../common/reducers/methodSelectionReducer';
import {getLoggedInUserIdFromStore} from '../reducers/loginReducer';
import HelpTips from '../presentation/HelpTips';
import {getProblemStepFromStore} from "../../common/reducers/allTeamsReducerGetters";
import {getAllUsersForProblemFromStore} from "../../common/reducers/allUsersForTeamReducerGetters";

class MethodSelectionContainer extends Component {

    static propTypes = {
        readOnly: PropTypes.bool,
        status: PropTypes.number,
        userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        problemStepId: PropTypes.number.isRequired,
        tabTense: PropTypes.number
    };

    static defaultProps = {
        readOnly: false,
        status: DataStatusEnum.MY_DRAFT
    };

    constructor(props) {
        super(props);
        this.state =    {   method: props.methodSelection.method, 
                            rationale: props.methodSelection.rationale || ((typeof(props.methodSelection.rationale) !== 'undefined') ? props.methodSelection.rationale : ''), 
                            savedRationale: props.methodSelection.rationale || ((typeof(props.methodSelection.rationale) !== 'undefined') ? props.methodSelection.rationale : ''),  
                            readOnly: false
                        };
    
        this.onSelectMethod = this.onSelectMethod.bind(this);
        this.onRationaleChange = this.onRationaleChange.bind(this);
    }

    componentDidMount() {
        this.checkMethodNeedsUpdating(this.props);
    }

    UNSAFE_componentWillReceiveProps(props) {
        this.setState({method: props.methodSelection.method});
        this.setState({rationale: ((typeof(props.methodSelection.rationale) !== 'undefined') ? props.methodSelection.rationale : '')});
        this.checkMethodNeedsUpdating(props);
    }

    checkMethodNeedsUpdating(props) {
        // A bit of a hack... if readOnly is false but tabTense === past, we're in the special situation where the
        // Facilitator is editing the group draft after launching the next step.  Ensure the group draft method matches
        // the published method that was used to determine the route the problem took.
        const method = props.methodSelectionGroup.method;
        if (!props.readOnly && props.tabTense === constants.STEP_TENSE.PAST && props.methodSelection.method !== method) {
            this.setState({method});
            this.props.dispatch(updateMethodSelectionAction(this.props.problemStepId, this.props.userId, this.props.status, method, null));
        }
    }

    onRationaleChange(rationale) {
        if (rationale !== this.state.savedRationale) {
            this.setState({rationale, savedRationale: rationale});
            this.props.dispatch(updateMethodSelectionAction(this.props.problemStepId, this.props.userId, this.props.status, null, rationale));
        }
    }

    onSelectMethod(method) {
        if (!this.props.readOnly) {
            if (method !== this.state.method) {
                this.setState({method});
                this.props.dispatch(updateMethodSelectionAction(this.props.problemStepId, this.props.userId, this.props.status, method, null));
            }
        }
    }

    render() {
        return (
            (this.props.users[this.props.userId].db.role === constants.ROLE_FACILITATOR && this.props.problemStep.launchMode === constants.LAUNCH_MODE_FACILITATOR_ADVANCES) ?
            <div className='methodDiv'>
                <div className='minorTitle instructionNumberDivStyle roleBackgroundColour'>
                    <span className="instructionNumberListStyle">5</span>
                    <span className='toolTipContainer instructionDivStyle'>
                        {this.props.readOnly ? constants.appContextDict.exploreProblem.APPROACH_SELECTION_LABEL_READ_ONLY : constants.appContextDict.exploreProblem.APPROACH_SELECTION_LABEL} Approach
                        <span className='toolTip'>If the problem contains a casual story, uncertainty, and conflicting evidence then a Bayesian Network may be best for exploring and solving the problem. If the problem can be solved without a BN, e.g. by logic, or a mathematical formula, consider skipping the Bayesian modelling part.</span>
                    </span>
                </div>
                
                {this.props.readOnly ? null :  <HelpTips helpId={constants.HELP_EXPLORE_PROBLEM_SELECT_APPROACH} problemStepId={this.props.problemStepId}/>}

                <div className='methodDivContentDiv'>

                    <div className='methodDivMethodApproachDiv'>
                        <div className='keyBoldTitle methodDivApproachHeaderDiv'>Approach{this.props.readOnly ? null :  <HelpTips helpId={constants.HELP_EXPLORE_PROBLEM_APPROACH} problemStepId={this.props.problemStepId}/> }</div>
                        
                        <MethodSelectionTableComponent 
                            method={this.state.method}
                            readOnly={this.props.readOnly || this.props.tabTense === constants.STEP_TENSE.PAST}
                            role={this.props.users[this.props.userId].db.role}
                            page={constants.SUBTAB_MY_DRAFT}
                            onSelectMethod={this.onSelectMethod}
                            users={this.props.users}
                        />
                    </div>
                    <div className='methodDivMethodRationaleDiv'>
                        <div className="keyBoldTitle methodDivRationaleHeaderDiv">Rationale{this.props.readOnly ? null :  <HelpTips helpId={constants.HELP_EXPLORE_PROBLEM_APPROACH_RATIONALE} problemStepId={this.props.problemStepId}/>}</div>
                        
                        <div><PlainTextField className='methodDivRationaleField'
                                        text={this.state.rationale}
                                        onChange={this.onRationaleChange}
                                        rows={1}
                                        cols={40}
                                        readOnly={this.props.readOnly}
                                        placeholder={this.props.readOnly ? "" : "[ You might want to provide your rationale as input to the group.Also, if there are any other tools or resources that you think would be useful in approaching this problem, enter them here. ]"}
                        />
                        </div>
                    </div>
                </div>
            </div>
            : 
            null
        );
    }
}

function mapStoreToProps(store, myProps) {
    
    let problemStepId = myProps.problemStepId;
    let userId = myProps.userId || getLoggedInUserIdFromStore(store);
    const status = (myProps.status === undefined) ? MethodSelectionContainer.defaultProps.status : myProps.status;
    return {
        users: getAllUsersForProblemFromStore(store, problemStepId),
        problemStepId,
        userId,
        problemStep: getProblemStepFromStore(store, problemStepId),
        methodSelection: getMethodSelectionFromStore(store, problemStepId, userId, status),
        methodSelectionGroup: getMethodSelectionFromStore(store, problemStepId, userId, DataStatusEnum.GROUP)
    };
}

export default connect(mapStoreToProps)(MethodSelectionContainer);