import React, {Component} from 'react';
import * as PropTypes from 'prop-types';
import {connect} from 'react-redux';

import {getLoggedInUserIdFromStore} from '../reducers/loginReducer';
import DataStatusEnum from '../../common/DataStatusEnum'
import PlainTextField from "../presentation/PlainTextField";
import {
    addKeyFactAction, deleteKeyFactAction, getKeyFactsFromStore, KeyFactType,
    reorderKeyFactsAction, updateKeyFactRationaleAction, updateKeyFactTextAction
} from "../../common/reducers/keyFactsReducer";
import ReorderableAddableContainer from "./ReorderableAddableContainer";
import ReorderableDeleteableElement from "./ReorderableDeleteableElement";
import HelpTips from '../presentation/HelpTips';
import * as constants from '../../common/util/constants';
import {safeOrderObject} from '../../common/util/safeOrder';

import '../scss/keyFacts.scss';

class KeyFactsContainer extends Component {

    static propTypes = {
        readOnly: PropTypes.bool,
        status: PropTypes.number,
        userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        problemStepId: PropTypes.number.isRequired
    };

    static defaultProps = {
        readOnly: false,
        status: DataStatusEnum.MY_DRAFT
    };

    renderKeyFacts(keyFactType) {
        return (
            <ReorderableAddableContainer
                readOnly={this.props.readOnly}
                onAdd={() => {
                    this.props.dispatch(addKeyFactAction(this.props.problemStepId, this.props.userId, this.props.status, keyFactType));
                }}
                addLabel={keyFactType === KeyFactType.HYPOTHESIS ? 'HYPOTHESIS' : 'EVIDENCE'}
                onSortEnd={({oldIndex, newIndex}) => {
                    this.props.dispatch(reorderKeyFactsAction(this.props.problemStepId, this.props.userId, this.props.status, oldIndex, newIndex));
                }}
            >
                <div className={keyFactType === KeyFactType.HYPOTHESIS ? "hypothesesHeading semiMajorTitle-px" : "evidenceHeading semiMajorTitle-px"}>
                    <div className={keyFactType === KeyFactType.HYPOTHESIS ? "hypothesesElement" : "evidenceElement" }>
                        {keyFactType === KeyFactType.HYPOTHESIS ? 'Hypotheses' : 'Evidence'}
                    </div>
                    <div className={keyFactType === KeyFactType.HYPOTHESIS ? "hypothesesRationale toolTipContainer" : "evidenceRationale toolTipContainer"}>
                        <div className="toolTip keyFactsToolTip">{keyFactType === KeyFactType.HYPOTHESIS ? "Why is this hypothesis important? " : "Why is this evidence important?"}</div>
                        Rationale
                    </div>
                </div>
                {
                    safeOrderObject(this.props.keyFacts, 'fact').map((keyFactId, index) => {
                        let keyFact = this.props.keyFacts.fact[keyFactId];
                        return ((keyFact.keyFactType || KeyFactType.HYPOTHESIS) !== keyFactType) ? null : (
                            <ReorderableDeleteableElement
                                readOnly={this.props.readOnly}
                                className={keyFactType === KeyFactType.HYPOTHESIS ? "hypotheses" : "evidence"}
                                key={keyFactId}
                                index={index}
                                onDelete={() => {
                                    this.props.dispatch(deleteKeyFactAction(this.props.problemStepId, this.props.userId, this.props.status, keyFactId, index));
                                }}
                            >
                                <div className="keyFactDiv keyFactElement">
                                    <div className="keyFactInputDiv"><PlainTextField
                                        className="keyFactInput"
                                        rows={1}
                                        placeholder=""
                                        text={keyFact.text || ''}
                                        onChange={(value) => {
                                            this.props.dispatch(updateKeyFactTextAction(this.props.problemStepId, this.props.userId, this.props.status, keyFactId, value));
                                        }}
                                        readOnly={this.props.readOnly}
                                        autoResize
                                    />
                                    </div>
                                </div>
                                <div className="keyFactDiv keyFactRationale">
                                    <div className="keyFactInputDiv">
                                        <PlainTextField
                                            className="keyFactInput"
                                            rows={1}
                                            placeholder=""
                                            text={keyFact.rationale || ''}
                                            onChange={(value) => {
                                                this.props.dispatch(updateKeyFactRationaleAction(this.props.problemStepId, this.props.userId, this.props.status, keyFactId, value));
                                            }}
                                            readOnly={this.props.readOnly}
                                            autoResize
                                        />
                                    </div>
                                </div>
                            </ReorderableDeleteableElement>
                        );
                    })
                }
            </ReorderableAddableContainer>
        );
    }

    render() {
        const TOOLTIP_EVIDENCE = 'Facts from the problem statement that supports or contradicts your hypothesis';
        return (
            <div className="keyFactContainerWrap">
                <div>
                    <div className='minorTitle instructionNumberDivStyle roleBackgroundColour'>
                        <span className="instructionNumberListStyle">3</span> 
                        <span className="toolTipContainer instructionDivStyle">
                            Identify Hypotheses
                            <span className='toolTip'>Hypotheses are statements that, when you assess their probabilities, will answer the questions stated in the problem.</span>
                        </span>
                    </div>
                    <HelpTips helpId={constants.HELP_EXPLORE_PROBLEM_HYPOTHESES} problemStepId={this.props.problemStepId}/>
                </div>
                {this.renderKeyFacts(KeyFactType.HYPOTHESIS)}
                <div>
                    <div className='minorTitle instructionNumberDivStyle roleBackgroundColour'>
                        <span className="instructionNumberListStyle">4</span>
                        <span className="toolTipContainer instructionDivStyle">
                            Identify Evidence
                            <span className='toolTip'>{TOOLTIP_EVIDENCE}</span>
                        </span>
                    </div>
                    <HelpTips helpId={constants.HELP_EXPLORE_PROBLEM_EVIDENCE} problemStepId={this.props.problemStepId} />
                </div>
                {this.renderKeyFacts(KeyFactType.EVIDENCE)}
            </div>
        );
    }
}

function mapStoreToProps(store, myProps) {
    const problemStepId = myProps.problemStepId;
    const userId = myProps.userId || getLoggedInUserIdFromStore(store);
    const status = (myProps.status === undefined) ? KeyFactsContainer.defaultProps.status : myProps.status;
    return {
        userId,
        keyFacts: getKeyFactsFromStore(store, problemStepId, userId, status)
    };
}

export default connect(mapStoreToProps)(KeyFactsContainer);
