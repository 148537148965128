import {AnyAction, combineReducers} from 'redux';

import sharedStateReducer from '../../common/reducers/sharedStateReducer';
import loginReducer, {LOGOUT} from './loginReducer';
import listenerIdReducer from './listenerIdReducer';
import allUsersReducer from './allUsersReducer';
import recentActivityReducer from './recentActivityReducer';
import pendingActionTopLevelStateReducer, {initialPendingActionReducerType} from './pendingActionReducer';
import snackbarToastReducer from './snackbarToastReducer';
import allEmailTemplatesReducer from './allEmailTemplatesReducer';
import allCronsReducer from './allCronsReducer';
import defaultProblemStepIdReducer from './defaultProblemStepIdReducer';

const clientReducers = combineReducers({
    login: loginReducer,
    listenerId: listenerIdReducer,
    allUsers: allUsersReducer,
    recentActivities: recentActivityReducer,
    pendingActions: (state) => (state || initialPendingActionReducerType), // state is actually managed by pendingActionTopLevelStateReducer
    snackbarToasts: snackbarToastReducer,
    allEmailTemplates: allEmailTemplatesReducer,
    allCrons: allCronsReducer,
    defaultProblemStepId: defaultProblemStepIdReducer
});

export type ClientStateReducerType = ReturnType<typeof clientReducers>;

export const clientStateReducer = (state: ClientStateReducerType | undefined, action: AnyAction): ClientStateReducerType => {
    if (action.fromServer && state) {
        return state;
    }
    return clientReducers(state, action);
};

const combinedTopLevelReducer = combineReducers({
    clientState: clientStateReducer,
    sharedState: sharedStateReducer
});

export type TopLevelReducerType = ReturnType<typeof combinedTopLevelReducer>;

const topLevelReducer = (state: TopLevelReducerType | undefined, action: AnyAction) => {
    // Special case - on logout, reset to initial state
    if (action.type === LOGOUT) {
        return combinedTopLevelReducer(undefined, action);
    } else {
        return combinedTopLevelReducer(pendingActionTopLevelStateReducer(state, action), action);
    }
};

export default topLevelReducer;