import {combineReducers, Reducer} from 'redux';

import {timestampedReducer, TimestampedState} from '../util/genericReducers';
import ensureFieldPath from '../ensureFieldPath';
import * as constants from '../util/constants';
import { StoreWithSharedState } from './sharedStateReducer';

// ======== Constants

const UPDATE_SUGGESTION = 'update_suggestion';

// ======== Reducers

export type SingleSuggestionReducerType = {
    suggestionText: string;
}

const singleSuggestionReducer = (state: SingleSuggestionReducerType = {suggestionText: ''}, action) => {
    switch (action.type) {
        case UPDATE_SUGGESTION:
            return {...state, suggestionText: action.text};
        default:
            return state;
    }
};

export interface SuggestionsReducerType extends TimestampedState {
    suggestion: SingleSuggestionReducerType
}

const suggestionsReducer = combineReducers<SuggestionsReducerType>({
    suggestion: singleSuggestionReducer,
    timestamp: (state = '') => (state)
});

const suggestionsCopyableReducer: Reducer<SuggestionsReducerType> = (state = {suggestion: {suggestionText: ''}}, action) => {
    switch (action.type) {
        case constants.COPY_PUBLISHED_ACTION:
            return action.fromUser.suggestions ? {...action.fromUser.suggestions} : state;
        case constants.APPEND_PUBLISHED_ACTION:
            const publishedSuggestions = action.fromUser.suggestions;
            if (publishedSuggestions) {
                return Object.keys(publishedSuggestions).filter((suggestionId) => (suggestionId !== 'timestamp')).reduce((previous, suggestionId) => {
                    previous[suggestionId] = {
                        suggestionText: publishedSuggestions[suggestionId].suggestionText +
                            ((state[suggestionId] && state[suggestionId].suggestionText) ? '\n----------------\n' + state[suggestionId].suggestionText : '')
                    };
                    return previous;
                }, {...state});
            } else {
                return state;
            }
        default:
            return suggestionsReducer(state, action);
    }
};

export default timestampedReducer(suggestionsCopyableReducer);

// ======== DBSync functions
// (used by DBSync and for unit testing)

export function getDBSyncActionTypes() {
    return [UPDATE_SUGGESTION];
}

export function setSuggestionsInStore(store: StoreWithSharedState, problemStepId, userId, status, object) {
    ensureFieldPath(store, 'sharedState', 'teams', problemStepId, 'user', userId, 'status', status);
    store.sharedState.teams[problemStepId].user[userId].status[status].suggestions = object;
}

// ======== Action Generators


export function updateSuggestionsAction(problemStepId, userId, status, text) {
    return {type: UPDATE_SUGGESTION, problemStepId, userId, status, text}
}

// ======== Getter functions

export function getSuggestionsFromStore(store: StoreWithSharedState, problemStepId, userId, status) {
    return store.sharedState.teams[problemStepId].user[userId].status[status].suggestions;
}
