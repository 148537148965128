import React, {Component, ReactElement} from 'react';
import {connect, DispatchProp} from 'react-redux';
import {Button} from 'react-md';
import moment from 'moment';
import Select from 'react-select';
import ReactMarkdown from 'react-markdown';
import classNames from 'classnames';
import {createPortal} from 'react-dom';

import RichTextField from '../presentation/RichTextField';
import BardSnackbar from '../presentation/BardSnackbar';
import {SingleProblemStepType} from '../../common/reducers/allTeamsReducer';
import {
    getReportFromStore,
    updateReportAction, updateReportSectionAction,
    prependReportAction, submitReportAction, setReportTemplateAction, ReportState
} from '../../common/reducers/reportReducer';
import {
    getCandidateSolutionFromStore,
    SingleCandidateSolutionState
} from '../../common/reducers/candidateSolutionReducer';
import {getLoggedInUserIdFromStore} from '../reducers/loginReducer';
import DataStatusEnum from '../../common/DataStatusEnum';
import IconLabelButton from '../presentation/IconLabelButton';
import * as constants from "../../common/util/constants";
import {getFormattedTimeStamp} from '../util/timeUtils';
import HelpTips from '../presentation/HelpTips';
import {
    CandidateSolutionTemplateReducerType,
    getCandidateSolutionTemplatesFromStore
} from '../../common/reducers/candidateSolutionTemplateReducer';
import {getReportSections, REPORT_TEMPLATE} from '../../common/util/formBasedReports';
import {getProblemDBFromStore, SingleProblemType} from '../../common/reducers/allProblemsReducer';
import {REPORT_QUESTION_HEADING_LEVEL} from '../../common/util/formBasedReports';
import {buildRestPath, DOWNLOAD_REPORT_ENDPOINT, UPLOAD_PROBLEM_IMAGE_PATH} from '../../common/clientServer/navigation';
import ReferenceData from '../../common/util/referenceData';
import {addToastMessageAction} from '../reducers/snackbarToastReducer';
import {getProblemStepFromStore} from '../../common/reducers/allTeamsReducerGetters';
import {getUserForProblemFromStore} from '../../common/reducers/allUsersForTeamReducerGetters';

import '../scss/report.scss';
import '../../common/util/formBasedReports.css';
import commonSelectStyles from '../util/commonSelectStyles';

const NOT_SUBMITTED_MSG = 'Not yet submitted';
const SUBMITTING_MSG = 'Submitting...';
const SUBMITTED_MSG_TMPL = 'Report submitted';
const ERROR_MSG = 'There was an error submitting the report';

const headingClassNames = {
    1: 'heading2',
    2: 'heading3',
    3: 'heading4'
};

const defaultProps = {
    readOnly: false,
    status: DataStatusEnum.GROUP_DRAFT
};

type ReportContainerDefaultProps = Readonly<typeof defaultProps>;

interface ReportContainerOwnProps {
    userId: number;
    problemStepId: number;
    addFlushChanges: (id: string, callback: () => void) => void;
    buttonBarDiv: HTMLDivElement | null;
}

interface ReportContainerStoreProps {
    userId: number;
    loggedInUserId: number;
    userRole: number;
    report: ReportState;
    groupCandidateSolution: SingleCandidateSolutionState;
    problemStep: SingleProblemStepType;
    templates: CandidateSolutionTemplateReducerType;
    problem: SingleProblemType;
    isProblemStepClosed: boolean;
}

type ReportContainerProps = ReportContainerOwnProps & ReportContainerDefaultProps & DispatchProp & ReportContainerStoreProps;

interface ReportContainerState {
    toastMessages: ({text: string | ReactElement, message: string})[];
    forceUpdate: boolean;
    flushChanges: {[key: string]: () => void};
}

class ReportContainer extends Component<ReportContainerProps, ReportContainerState> {

    static defaultProps = defaultProps;

    constructor(props) {
        super(props);
        this.addFlushChanges = this.addFlushChanges.bind(this);
        this.submitReport = this.submitReport.bind(this);
        this.state = {
            toastMessages: [],
            forceUpdate: false,
            flushChanges: {}
        };
        this.onChange = this.onChange.bind(this);
        this.onDismiss = this.onDismiss.bind(this);
    }

    addFlushChanges(key, handler) {
        this.props.addFlushChanges && this.props.addFlushChanges(key, handler);
        this.setState((oldState) => ({flushChanges: {...oldState.flushChanges, [key]: handler}}));
    }

    componentDidMount() {
        if(!this.props.report.template && this.props.userRole === constants.ROLE_SOLO_ANALYST) {
            this.props.dispatch(setReportTemplateAction(this.props.problemStepId, this.props.userId, this.props.status, constants.METHOD_BN));
        }
        this.setSnackbarState();
    }

    componentDidUpdate() {
        if (!this.props.readOnly && this.state.forceUpdate)
            this.setState({forceUpdate: false});
        this.setSnackbarState();
    }

    setSnackbarState() {
        if (this.shouldShowReportBar(this.props)) {
            let message;
            if (this.props.report.buildingReport) {
                message = SUBMITTING_MSG;
            } else if (this.props.report.error && !this.props.isProblemStepClosed) {
                message = ERROR_MSG;
            } else if (this.isReportBuilt(this.props)) {
                message = `${SUBMITTED_MSG_TMPL} ${getFormattedTimeStamp(this.props.problemStep.systemReportSubmittedAt || this.props.problemStep.reportSubmittedAt)}`;
            } else {
                message = NOT_SUBMITTED_MSG;
            }
            if (this.state.toastMessages.length !== 1 || this.state.toastMessages[0].message !== message) {
                this.setState({toastMessages: [{text: <span>{message}</span>, message}]});
            }
        }
    }

    shouldShowReportBar(props = this.props) {
        return (props.status === DataStatusEnum.GROUP || props.userRole === constants.ROLE_SOLO_ANALYST);
    }

    canSeeSubmitReportButton(props = this.props) {
        return (props.userRole === constants.ROLE_FACILITATOR || props.userRole === constants.ROLE_SOLO_ANALYST);
    }

    isReportBuilt(props = this.props) {
        return (props.report && !props.report.buildingReport
            && (props.problemStep.systemReportSubmittedAt || props.problemStep.reportSubmittedAt));
    }

    onChange(text) {
        if (!this.props.readOnly) {
            if (this.props.report.template && text === this.props.templates[this.props.report.template].template) {
                text = '';
            }
            this.props.dispatch(updateReportAction(this.props.problemStepId, this.props.userId, this.props.status, text));
        }
    }

    onDismiss() {
        this.setState({toastMessages: []});
    }

    onCopyFromCandidateSolution() {
        if (!this.props.readOnly && this.props.groupCandidateSolution && this.props.groupCandidateSolution.text) {
            this.props.dispatch(prependReportAction(this.props.problemStepId, this.props.userId, this.props.status, this.props.groupCandidateSolution.text));
            this.setState({forceUpdate: true});
        }
    }

    submitReport() {
        // Flush any pending changes in the rich text field.
        Object.keys(this.state.flushChanges).forEach((key) => {
            this.state.flushChanges[key]();
        });
        // Use setState callback to ensure we wait until all state changes have completed before submitting.
        this.setState({}, () => {
            this.props.dispatch(submitReportAction(this.props.problemStepId, this.props.userId, this.props.status));
        });
    }

    cameFromCandidateSolution() {
        const allStepDescriptions = ReferenceData.getInstance().getAllStepDescriptions();
        return this.props.problemStep.route && this.props.problemStep.route.find(([stepId]) => (
            (allStepDescriptions[stepId] && allStepDescriptions[stepId].syncVariables
            && allStepDescriptions[stepId].syncVariables!.indexOf(constants.SYNC_CANDIDATE_SOLUTION) >= 0)
            || false
        ));
    }

    getInitialReportText() {
        return this.props.report.text
            || ((this.cameFromCandidateSolution() || !this.props.report.template) ? '' :
                this.props.templates[this.props.report.template].template);
    }

    renderCopyFromCandidateSolutionButton() {
        if (!this.props.readOnly) {
            if (this.cameFromCandidateSolution()) {
                return (
                    <div className='toolTipContainer floatRight reportCopyCandidateSolnWrap'>
                        <IconLabelButton
                            id='reportCopyButton'
                            className='black'
                            iconChildren='content_copy'
                            label='Copy from Group Candidate Solution'
                            onClick={() => {this.onCopyFromCandidateSolution()}}
                        />
                        <span className="toolTip toolTipLeft">Copy the Group Candidate Solution to the Report</span>
                    </div>
                );
            }
        }
        return null;
    }

    renderSelectTemplate() {
        const options = [
            {label: 'Report based on a Bayesian network.', value: constants.METHOD_BN},
            {label: 'Report not based on Bayesian networks.', value: constants.METHOD_NBN}
        ];
        return (this.props.readOnly || this.cameFromCandidateSolution() || this.props.userRole === constants.ROLE_SOLO_ANALYST) ? null : (
            <div className="reportSelect">
                <div title="Report template">
                    <HelpTips helpId={this.props.userRole === constants.ROLE_FACILITATOR ? constants.HELP_TIPS_COMBINED_REPORTF_SELECT : constants.HELP_TIPS_COMBINED_REPORTA_SELECT} problemStepId={this.props.problemStepId}/>
                    <Select
                        placeholder='Select report type...'
                        options={options}
                        className='selectType'
                        value={options.filter(({value}) => (value === this.props.report.template))}
                        styles={commonSelectStyles}
                        onChange={(selected) => {
                            if (selected) {
                                this.props.dispatch(setReportTemplateAction(this.props.problemStepId, this.props.userId, this.props.status, selected.value));
                                this.setState({forceUpdate: true});
                            }
                        }}
                        isSearchable={true}
                    />
                </div>
            </div>
        );
    }

    renderDownloadButton() {
        return !this.props.buttonBarDiv ? null : createPortal((
            <div className="downloadReportButtonDiv toolTipContainer">
                <IconLabelButton
                    id='reportLink'
                    className='small lightTheme'
                    disabled={!this.isReportBuilt()}
                    href={buildRestPath(DOWNLOAD_REPORT_ENDPOINT, {problemStepId: this.props.problemStepId})}
                    iconChildren={'get_app'}
                    label={'DOWNLOAD SUBMITTED REPORT'}
                />
                 <div className='toolTip toolTipDownloadReport'>Download last submitted Report as PDF</div>
            </div>
        ), this.props.buttonBarDiv);
    }

    renderSubmitReportButton() {
        const disabledSubmitButton = !this.props.report ||
            (this.props.problemStep.reportSubmittedAt && moment(this.props.problemStep.reportSubmittedAt).isSameOrAfter(moment(this.props.report.timestamp)));
        return (!this.canSeeSubmitReportButton() || !this.props.buttonBarDiv) ? null : (
            createPortal((
                <div className="submitReportButtonDiv">
                    <Button raised={true} primary={true} className='mdActionButton roleBackgroundColour submitReportButtonStyle'
                            onClick={this.submitReport} disabled={disabledSubmitButton}>
                        SUBMIT REPORT
                    </Button>
                </div>
            ), this.props.buttonBarDiv)
        );
    }

    renderSubmitReportBar() {
        return (this.shouldShowReportBar()) ? (
            <>
                {this.renderDownloadButton()}
                {this.renderSubmitReportButton()}
            </>
        ) : null;
    }

    renderEditableReportSection(fullSectionId) {
        let sectionId = fullSectionId, questionId;
        if (!REPORT_TEMPLATE[sectionId]) {
            [questionId, sectionId] = sectionId.split('#');
        }
        const uploadUrl = buildRestPath(UPLOAD_PROBLEM_IMAGE_PATH, {problemStepId: this.props.problemStepId});
        return (isNaN(sectionId)) ? (
            <div key={sectionId} className='reportSection'>
                <div className={headingClassNames[REPORT_TEMPLATE[sectionId].headingLevel]}>{REPORT_TEMPLATE[sectionId].heading}</div>
                <ReactMarkdown className='reportInstructions' source={REPORT_TEMPLATE[sectionId].instructions[this.props.report.template!]}/>
                <div className='reportSectionCKEditor'>
                    <RichTextField
                        text={(this.props.report.section && this.props.report.section[fullSectionId]) || ''}
                        onRevert={this.props.report.onRevert}
                        forceUpdate={this.state.forceUpdate}
                        addFlushChanges={this.props.readOnly ? undefined : this.addFlushChanges}
                        onChange={(text) => {
                            this.props.dispatch(updateReportSectionAction(this.props.problemStepId, this.props.userId, this.props.status, fullSectionId, text))
                        }}
                        readOnly={this.props.readOnly}
                        config={{uploadUrl, height: '100'}}
                    />
                </div>
            </div>
        ) : (
            <div key={questionId} className='reportSection'>
                <div className={headingClassNames[REPORT_QUESTION_HEADING_LEVEL]}>Question {sectionId}</div>
                <div dangerouslySetInnerHTML={{__html: this.props.problem.question[questionId]}}/>
            </div>
        );
    }

    renderReadOnlyReportSection(fullSectionId) {
        let sectionId = fullSectionId, questionId;
        if (!REPORT_TEMPLATE[sectionId]) {
            [questionId, sectionId] = sectionId.split('#');
        }
        const showAdd = (this.props.userRole === constants.ROLE_FACILITATOR && this.props.status === DataStatusEnum.PUBLISHED);
        const sectionText = (this.props.report.section && this.props.report.section[fullSectionId] && this.props.report.section[fullSectionId].trim()) || '';
        return (!isNaN(sectionId)) ? (
            <div key={questionId} className='reportSection'>
                <div className={headingClassNames[REPORT_QUESTION_HEADING_LEVEL]}>Question {sectionId}</div>
                <div className='questionText' dangerouslySetInnerHTML={{__html: this.props.problem.question[questionId]}}/>
            </div>
        ) : (sectionText) ? (
            <div key={sectionId} className='reportSection'>
                {
                    showAdd ? (
                        <IconLabelButton iconChildren='library_add' label='Add section to Group Draft' onClick={() => {
                            this.props.dispatch(prependReportAction(this.props.problemStepId, this.props.loggedInUserId, DataStatusEnum.GROUP_DRAFT, sectionText, fullSectionId, this.props.report.template));
                            this.props.dispatch(addToastMessageAction(`Content prepended to the corresponding section of Group Draft`));
                        }}/>
                    ) : null
                }
                <div className={headingClassNames[REPORT_TEMPLATE[sectionId].headingLevel]}>{REPORT_TEMPLATE[sectionId].heading}</div>
                <div dangerouslySetInnerHTML={{__html: sectionText}}/>
            </div>
        ) : null;
    }

    renderReportIntroduction() {
        return !this.props.report.template ? null : (
            <div className='reportInstructions'>
                <p><strong>How much you need to write under each heading will depend upon the problem. At least address the main points in bold below.</strong> However, the more good points you make in your solution, the better!</p>
                {
                    this.props.userRole === constants.ROLE_FACILITATOR ? (
                        <p>
                            You may wish to start your Group Draft by copying the highest rated Published report (using
                            <span className='sampleButton'><span className='material-icons'>library_add</span> Add to Group Draft</span>
                            in the Published sub-tab). You can then use each section's
                            <span className='sampleButton'><span className='material-icons'>library_add</span> Add Section to Group Draft</span>
                            to copy good points from other Analysts' solutions in the relevant section.
                        </p>
                    ) : (this.props.userRole === constants.ROLE_SOLO_ANALYST ?
                            null
                        :
                        <p>
                            As well as writing your own points, you can copy any good points from&nbsp;
                            {this.props.report.template === constants.METHOD_BN ? 'the automatic BN analysis text, and from ' : ''}
                            other Analysts’ solutions.
                        </p>
                    )
                }
            </div>
        )
    }

    renderHeading() {
        let heading;
        switch (this.props.status) {
            case DataStatusEnum.PUBLISHED:
                heading = 'Analyst solution';
                break;
            case DataStatusEnum.GROUP:
                heading = 'Group solution';
                break;
            default:
                heading = 'Write the problem report';
                break;
        }
        return (
            <div className='minorTitle instructionNumberDivStyle roleBackgroundColour'>
                <span className="toolTipContainer instructionDivStyle">{heading}</span>
            </div>
        );
    }

    renderHelpTips() {
        const isFacilitator = (this.props.userRole === constants.ROLE_FACILITATOR);
        let helpId;
        switch (this.props.status) {
            case DataStatusEnum.PUBLISHED:
                helpId = isFacilitator ? constants.HELP_TIPS_COMBINED_REPORTF_PUBLISHED : constants.HELP_TIPS_COMBINED_REPORTA_PUBLISHED;
                break;
            case DataStatusEnum.GROUP:
                helpId = isFacilitator ? constants.HELP_TIPS_COMBINED_REPORTF_GROUP : constants.HELP_TIPS_COMBINED_REPORTA_GROUP;
                break;
            default:
                helpId = isFacilitator ? constants.HELP_TIPS_COMBINED_REPORTF : constants.HELP_TIPS_COMBINED_REPORTA;
                break;
        }
        return (
            <HelpTips helpId={helpId} problemStepId={this.props.problemStepId}/>
        );
    }

    renderReportEditor() {
        if (this.props.report.text || this.cameFromCandidateSolution()) {
            // Old style all-in-big-RichTextField report
            const uploadUrl = buildRestPath(UPLOAD_PROBLEM_IMAGE_PATH, {problemStepId: this.props.problemStepId});
            return (
                <div className="reportTextAreaDiv">
                    <RichTextField
                        text={this.getInitialReportText()}
                        onRevert={this.props.report.onRevert}
                        forceUpdate={this.state.forceUpdate}
                        addFlushChanges={this.props.readOnly ? undefined : this.addFlushChanges}
                        onChange={this.onChange}
                        readOnly={this.props.readOnly}
                        config={{uploadUrl, height: '400'}}
                    />
                </div>
            );
        } else if (!this.props.report.template) {
            return null;
        } else if (this.props.readOnly) {
            // Render the report in read-only mode
            return (
                <div className='reportFromTemplate'>
                    {
                        getReportSections(this.props.problem.question).map((sectionId) => (
                            <div key={sectionId}>
                                {this.renderReadOnlyReportSection(sectionId)}
                            </div>
                        ))
                    }
                </div>
            );
        } else {
            // Form-based report
            return (
                <div className="reportTextAreaDiv">
                    {this.renderReportIntroduction()}
                    {
                        getReportSections(this.props.problem.question).map((sectionId) => (
                            <div key={sectionId}>
                                {this.renderEditableReportSection(sectionId)}
                            </div>
                        ))
                    }
                </div>
            );
        }
    }

    render() {
        const tip = this.props.status === DataStatusEnum.GROUP ? (
            (this.props.report.text || this.props.report.section) ? (
                    this.props.userRole === constants.ROLE_FACILITATOR ? null : (
                        <div className="pageTip">This is the most recent Draft REPORT published by the Facilitator.
                            Any earlier submitted REPORT can be downloaded.</div>
                    )
                ) : (
                    this.props.userRole === constants.ROLE_FACILITATOR ? (
                        <div className="pageTip">No Group Report published. Use the Group Draft tab to publish a
                            proposed Group solution.</div>
                    ) : (
                        <div className="pageTip">The Facilitator has not yet Published a DRAFT Report. You will
                            see a notification red dot when it is available.</div>
                    )
                )
            ) : null;

        return (
            
            <div className="reportContainerDiv">
                <div className="reportWorkSpace">
                    <div className="reportHelpTips">
                        <div className="reportPageTip">{tip}</div>
                    </div>

                    {this.renderHeading()}
                    {this.renderHelpTips()}

                    <div className={classNames({hide: this.props.readOnly})}>
                        {this.renderCopyFromCandidateSolutionButton()}
                        {this.renderSelectTemplate()}
                    </div>

                    {this.renderReportEditor()}
                </div>

                {
                    this.state.toastMessages
                        ? (<BardSnackbar
                            id='reportSubmissionStateSnackbar'
                            toasts={this.state.toastMessages}
                            autohide={false}
                            positionCenter={true}
                            onDismiss={this.onDismiss}
                            lightTheme={true}
                            size={BardSnackbar.SIZE_SMALL}
                        />)
                        : null
                }
                {this.renderSubmitReportBar()}
            </div>
        );
    }
}

function mapStoreToProps(store, ownProps): ReportContainerStoreProps {
    const problemStepId = ownProps.problemStepId;
    const loggedInUserId = getLoggedInUserIdFromStore(store);
    const userId = ownProps.userId || loggedInUserId;
    const status = (ownProps.status === undefined) ? DataStatusEnum.GROUP_DRAFT : ownProps.status;
    const problemStep = getProblemStepFromStore(store, problemStepId);
    return {
        userId,
        loggedInUserId,
        userRole: getUserForProblemFromStore(store, problemStepId, loggedInUserId).db.role,
        report: getReportFromStore(store, problemStepId, userId, status),
        groupCandidateSolution: getCandidateSolutionFromStore(store, problemStepId, userId, DataStatusEnum.GROUP),
        problemStep,
        templates: getCandidateSolutionTemplatesFromStore(store),
        problem: getProblemDBFromStore(store, problemStep.problemId),
        isProblemStepClosed: problemStep.state === constants.STATE_CLOSED
    };
}

export default connect<ReportContainerStoreProps, DispatchProp,
    ReportContainerOwnProps & Partial<ReportContainerDefaultProps>>(mapStoreToProps)(ReportContainer);
