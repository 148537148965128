import moment from 'moment';
import { AnyAction, Reducer } from 'redux';

interface ObjectMapReducerOptions {
    deleteActionType?: string;
}

export interface TimeStampedAction extends AnyAction {
    timestamp: string
}

export type ObjectMapState<T> = {[key: string]: T};

export interface TimestampedState {
    timestamp?: string;
}

/**
 * This function builds a reducer to manage multiple identical pieces of substate stored in a map (actually, a regular
 * Javascript object being treated like a map), each managed by the same sub-reducer, stored under different key values.
 * For example, if you have a reducer than can handle the actions for a single problem, then calling this function with
 * an actionKey of 'problemStepId' and that (sub)reducer will return a reducer that can handle multiple problems, with each
 * problem's state stored in a top-level object under its own problemStepId.  Actions will operate on the appropriate
 * problem state using action.problemStepId to work out what piece of the multiple-problems state to pass to the subReducer.
 *
 * @param actionKey The field stored in the action which contains the key into the state at this level which needs to be
 * reduced.  Note that this key can be a single value, or it can be an array of key values, in which case the action is
 * performed on each key value in the array.
 * @param subReducer The reducer which will be called to (potentially) give a new value for the sub-state stored under
 * the key(s).  A slightly hacky non-Redux behaviour for this subReducer is that if it returns a result of undefined,
 * the key(s) are deleted.  It is preferable to instead use the options.deleteActionType parameter to perform deletions
 * if they happen unconditionally on a given action type; this "return undefined to delete" mechanism exists to support
 * conditional deletes.  It does have the nice semantics that using it to delete a key that doesn't currently exist
 * means the state is returned verbatim (because the returned state from subReducer === the (non-existent) previous
 * state, i.e. they're both undefined).
 * @param options (optional) Contains optional options for this reducer - see below.
 * @param options.field (optional) If specified, drills down into that field in the state before looking up the keys derived
 * from actionKey.
 * @param options.deleteActionType (optional) If specified, an action with the given action type which contains the expected
 * actionKey will delete the state under that key value(s).
 *
 * @returns A reducer which manages multiple keyed sub-states using the common subReducer on each.
 *
 */
export const objectMapReducer = <T>(actionKey: string, subReducer: Reducer<T>, options?: ObjectMapReducerOptions): Reducer<ObjectMapState<T>> => (state = {}, action) => {
    if (action[actionKey] === undefined) {
        return state;
    }
    let {deleteActionType = null} = (options) ? options : {};
    const key = action[actionKey];
    const keyedState = state;
    let result: ObjectMapState<T> | null = null;
    if (Array.isArray(key)) {
        key.forEach((singleKey) => {
            result = updateSingleKey(subReducer, deleteActionType, result, keyedState, singleKey, action);
        });
    } else {
        result = updateSingleKey(subReducer, deleteActionType, result, keyedState, key, action);
    }
    if (!result) {
        return state;
    } else {
        return result;
    }
};

// Internal function used by objectMapReducer
const updateSingleKey = <T>(subReducer: Reducer<T>, deleteActionType: string | null, result: ObjectMapState<T> | null, state: ObjectMapState<T>, key: string, action: AnyAction): ObjectMapState<T> | null => {
    if (deleteActionType && action.type === deleteActionType) {
        if (state[key]) {
            if (!result) {
                result = {...state};
            }
            delete(result[key]);
        }
    } else {
        const subState = subReducer(state[key], action);
        if (subState !== state[key]) {
            if (!result) {
                result = {...state};
            }
            if (subState === undefined) {
                delete(result[key]);
            } else {
                result[key] = subState;
            }
        }
    }
    return result;
};

/**
 * Builds a reducer which operates on a single field of the given state, using the supplied fieldReducer to update that
 * field.
 *
 * @param field The field name to be updated.
 * @param initial The initial value to give state if it's undefined.
 * @param fieldReducer A reducer which operates on the type of the field.
 * @returns A reducer which updates only the given field using the fieldReducer, and otherwise leaves state alone.
 */
export const singleFieldReducer = <T, F extends keyof T>(field: F, initial: T, fieldReducer: Reducer<T[F]>): Reducer<T> => (state = initial, action) => {
    const stateBefore: T[F] | undefined = state ? state[field] : undefined;
    const stateAfter = fieldReducer(stateBefore, action);
    if (stateBefore === stateAfter) {
        return state;
    } else {
        return {...(state as any), [field]: stateAfter};
    }
};

/**
 * Create a reducer which manages an array of values.  Values can be added, deleted and reordered.
 *
 * @param {string} valueKey The field name in the action containing the value being operated on.
 * @param {string} addAction The action type of the action to add the value to the array.
 * @param {string} deleteAction The action type of the action to delete the value from the array.
 * @param {string} reorderAction The action type of the action to move a value to a new index in the array.  This action
 * must have fields 'oldIndex' and 'newIndex' to indicate the value to move.
 */
export const orderedListReducer = (valueKey: string, addAction: string, deleteAction: string, reorderAction: string): Reducer<string[]> => (state = [], action) => {
    switch (action.type) {
        case addAction:
            return [...state, action[valueKey]];
        case deleteAction:
            let result = state.slice();
            result.splice(action.index, 1);
            return result;
        case reorderAction:
            const movedKey = state[action.oldIndex];
            let array = state.slice();
            array.splice(action.oldIndex, 1);
            return [...array.slice(0, action.newIndex), movedKey, ...array.slice(action.newIndex)];
        default:
            return state;
    }
};

export type ShouldUpdateFunction<S> = (stateIn: S | undefined, stateOut: S, action: AnyAction) => boolean;

/**
 * Wraps a reducer so that whenever it changes its managed state, a top-level field "timestamp" is updated to the
 * current time.
 * @param wrappedReducer The reducer whose changes need to be timestamped.
 * @param shouldUpdate (optional) If provided, this function will be called with the old and new state and action; if it
 * returns false, the timestamp will not be updated.
 */
export const timestampedReducer = <TS extends TimestampedState>(wrappedReducer: Reducer<TS>, shouldUpdate?: ShouldUpdateFunction<TS>): Reducer<TS> => (state, action) => {
    let newState: TS = wrappedReducer(state, action);
    if (action.type.indexOf('@@') < 0 && newState !== state
            && (action.timestamp || state !== undefined)
            && (!shouldUpdate || shouldUpdate(state, newState, action))) {
        newState.timestamp = action.timestamp || moment().format();
    }
    return newState;
};

/**
 * Creates an action with time stamp.
 * @param actionPayload the action kvp payload.
 */
export const timestampedActionCreator = (actionPayload) => {
    return { ...actionPayload, timestamp: moment().format() };
};
