import React, {Component} from 'react';
import {DispatchProp} from 'react-redux';
import PropTypes from 'prop-types';
import isRequiredIf from 'react-proptype-conditional-require';

import ReorderableAddableContainer from '../container/ReorderableAddableContainer';
import ReorderableDeleteableElement from '../container/ReorderableDeleteableElement';
import PlainTextField from './PlainTextField';
import {
    addKeyVariableStateAction,
    deleteKeyVariableStateAction,
    KeyVariablesAttributeState,
    reorderKeyVariableStateAction,
    updateKeyVariableStateAction
} from '../../common/reducers/keyVariablesStatesReducer';
import * as validation from '../../common/util/validation';
import {safeOrderObject} from '../../common/util/safeOrder';
import {ValidationValue} from '../../common/util/validation';
import {validateVariableStates} from '../../common/util/specificValidations';

interface KeyVariablesStatesComponentProps extends DispatchProp {
    problemStepId: number;
    userId: number;
    status: number;
    variableId: string;
    readOnly: boolean;
    attributes: KeyVariablesAttributeState;
    minStates?: number;
    maxStates?: number;
    reorderable?: boolean;
    fixedValues?: boolean;
    validation?: ValidationValue;
    updateValidation: (update: ValidationValue) => void;
    stateLabel: string;
}

class KeyVariablesStatesComponent extends Component<KeyVariablesStatesComponentProps> {

    static propTypes = {
        problemStepId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        userId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        status: PropTypes.number.isRequired,
        variableId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
        dispatch: PropTypes.func.isRequired,
        readOnly: PropTypes.bool.isRequired,
        attributes: PropTypes.object.isRequired,
        minStates: PropTypes.number,
        maxStates: PropTypes.number,
        reorderable: PropTypes.bool,
        fixedValues: PropTypes.bool,
        validation: isRequiredIf(PropTypes.object, (props) => (!props.readOnly)),
        updateValidation: isRequiredIf(PropTypes.func, (props) => (!props.readOnly)),
        stateLabel: PropTypes.string
    };

    static defaultProps = {
        readOnly: false,
        fixedValues: false,
        stateLabel: 'State'
    };

    constructor(props) {
        super(props);
        this.state = {}; // Need state so we can call setState to wait for batched state changes in other components to settle.
    }

    render() {
        const prefix = this.props.variableId + '_';
        const otherValues = safeOrderObject(this.props.attributes, 'state').reduce((all, stateId) => {
            all[prefix + stateId] = this.props.attributes.state[stateId].stateName;
            return all;
        }, {});
        return (
            <ReorderableAddableContainer
                disableAdd={this.props.readOnly}
                addLabel="STATE"
                onAdd={() => {
                    this.props.dispatch(addKeyVariableStateAction(this.props.problemStepId, this.props.userId, this.props.status, this.props.variableId));
                }}
                onSortEnd={({oldIndex, newIndex}) => {
                    this.props.dispatch(reorderKeyVariableStateAction(this.props.problemStepId, this.props.userId, this.props.status, this.props.variableId, oldIndex, newIndex));
                }}
                maxChildren={this.props.maxStates}
                numChildren={this.props.attributes.order.length}
            >
                {
                    this.props.attributes.order.length === 0 ? null : (
                        <div className='targetVariableStatesHeadings targetVariableStateRow'>
                            <div className="miniTitle">{this.props.stateLabel}s</div>
                            <div className="miniTitle">{this.props.stateLabel} Descriptions</div>
                            <div className="miniTitle">{this.props.stateLabel} Rationales</div>
                        </div>
                    )
                }
                {
                    safeOrderObject(this.props.attributes, 'state').map((stateId, index) => {
                        let state = this.props.attributes.state[stateId];
                        return (
                            <ReorderableDeleteableElement
                                index={index}
                                key={stateId}
                                onDelete={() => {
                                    this.props.dispatch(deleteKeyVariableStateAction(this.props.problemStepId, this.props.userId, this.props.status, this.props.variableId, stateId, index));
                                    // Discard any validation failures for this variable, but wait for state to settle first
                                    this.setState({}, () => {
                                        this.props.updateValidation(validation.deleteValidationsForNames([prefix + stateId],
                                            safeOrderObject(this.props.attributes, 'state').map((stateId) => (prefix + stateId))));
                                    });
                                }}
                                disableDelete={this.props.attributes.order.length <= (this.props.minStates || 0)}
                                disableReorder={!this.props.reorderable}
                                readOnly={this.props.readOnly}
                            >
                                <div className="targetVariableStateRow">
                                    <PlainTextField
                                        className='mostlyFullWidthInput'
                                        rows={1}
                                        cols={3}
                                        text={state.stateName}
                                        placeholder={this.props.stateLabel + ' Name'}
                                        onChange={(value) => {
                                            this.props.dispatch(updateKeyVariableStateAction(this.props.problemStepId, this.props.userId, this.props.status, this.props.variableId, stateId, {
                                                stateName: value
                                            }));
                                        }}
                                        readOnly={this.props.readOnly || this.props.fixedValues}
                                        validationName={this.props.variableId + '_' + stateId}
                                        validation={this.props.validation}
                                        onValidate={(name, value, oldValue) => {
                                            this.props.updateValidation(validateVariableStates(name, value, oldValue, otherValues));
                                        }}
                                    />
                                    <PlainTextField
                                        className='mostlyFullWidthInput'
                                        rows={3}
                                        cols={5}
                                        text={state.description}
                                        placeholder={this.props.stateLabel + ' Description'}
                                        onChange={(value) => {
                                            this.props.dispatch(updateKeyVariableStateAction(this.props.problemStepId, this.props.userId, this.props.status, this.props.variableId, stateId, {
                                                description: value
                                            }));
                                        }}
                                        readOnly={this.props.readOnly}
                                    />
                                    <PlainTextField
                                        className='mostlyFullWidthInput'
                                        rows={3}
                                        cols={5}
                                        text={state.rationale}
                                        placeholder={this.props.stateLabel + ' Rationale'}
                                        onChange={(value) => {
                                            this.props.dispatch(updateKeyVariableStateAction(this.props.problemStepId, this.props.userId, this.props.status, this.props.variableId, stateId, {
                                                rationale: value
                                            }));
                                        }}
                                        readOnly={this.props.readOnly}
                                    />
                                </div>
                            </ReorderableDeleteableElement>
                        );
                    })
                }
            </ReorderableAddableContainer>
        );
    }
}

export default KeyVariablesStatesComponent;