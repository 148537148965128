import React, {Component} from 'react';
import {connect} from 'react-redux';

import '../scss/pendingActionIndicator.scss';

import {getPendingActionsFromStore, PendingActionReducerType} from '../reducers/pendingActionReducer';
import LoadingBar from './LoadingBar';

interface PendingActionIndicatorProps {
    pendingActions: PendingActionReducerType;
}

class PendingActionIndicator extends Component<PendingActionIndicatorProps> {

    static ACTION_WARN = 20;

    private pendingActionTimeouts: {[actionId: string]: number} = {};

    componentDidUpdate(prevProps: Readonly<PendingActionIndicatorProps>): void {
        if (this.props.pendingActions !== prevProps.pendingActions) {
            for (let action of this.props.pendingActions.actions) {
                // For each new action with a saved state, log a warning to the console if it's still pending after ACTION_WARN seconds.
                if (this.props.pendingActions.states[action.actionId] && !prevProps.pendingActions.states[action.actionId]) {
                    this.pendingActionTimeouts[action.actionId] = window.setTimeout(() => {
                        if (this.props.pendingActions.states[action.actionId]) {
                            console.warn(`Client action of type ${action.type} still pending after ${PendingActionIndicator.ACTION_WARN} seconds:`, action);
                        }
                        delete(this.pendingActionTimeouts[action.actionId]);
                    }, PendingActionIndicator.ACTION_WARN * 1000);
                }
            }
        }
    }

    componentWillUnmount(): void {
        for (let actionId of Object.keys(this.pendingActionTimeouts)) {
            window.clearTimeout(this.pendingActionTimeouts[actionId]);
        }
    }

    render() {
        return this.props.pendingActions.actions.length === 0 ? (
            <span className='pendingActionIndicator'>&nbsp;</span>
        ) : (
            <span className='pendingActionIndicator' title='Your changes are being saved to the server'>
                <LoadingBar id='actionsPending'/>
            </span>
        );
    }
}

function mapStoreToProps(store) {
    return {
        pendingActions: getPendingActionsFromStore(store)
    };
}

export default connect(mapStoreToProps)(PendingActionIndicator);