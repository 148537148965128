import {SET_LISTENER_ID} from '../../common/util/constants';

const listenerIdReducer = (state = null, action) => {
    switch (action.type) {
        case SET_LISTENER_ID:
            return action.listenerId;
        default:
            return state;
    }
};

export default listenerIdReducer;

export function getListenerIdFromStore(store) {
    return store.clientState.listenerId;
}

// Function for testing

export function setListenerIdInStore(store = {}, listenerId) {
    store.clientState = {...store.clientState, listenerId};
}