import {merge} from 'lodash';

import * as constants from './constants';
import {StoreWithSharedState} from '../reducers/sharedStateReducer';

export enum FlagType {
    boolean = 'Boolean',
    select = 'Select'
}

interface FeatureOption {
    label: string;
    value: any;
}

export interface FeatureFlag {
    key: string;
    name: string;
    value: any;
    type: FlagType;
    options?: (string | FeatureOption)[];
    isClientOnly?: boolean; // True if this feature flag is only used to control client-side code, false if it is checked on the server.
    adminConsoleComment?: string;
}

export interface FeatureFlagType {
    type: string;
    label: string;
    flagValue: (flag: FeatureFlag, store?: StoreWithSharedState) => any;
    isClientOnly?: boolean;
}

export const FEATURE_EXPLORE_PROBLEM_TYPE = 'explore-problem-type';
export const FEATURE_CANDIDATE_SOLUTION_TYPE = 'candidate-solution-type';
export const STEP_MODE_DIRECT_EXCHANGE = 'Direct Exchange - allow Analyst-to-Analyst exchanges';
export const STEP_MODE_DELPHI_EXCHANGE = 'Delphi Exchange - exchange via Facilitator only';
export const STEP_MODE_OPEN = 'Real-Time Judge - Analyst publishes to progress';

export const FEATURE_USERS_OPT_OUT_TO_ROLE = 'users-opt-out-to-role';

export const FEATURE_SOLO_BARD_SAVE_REVERT_ALL_STEPS = 'solo-bard-save-revert-all-steps';
export const FEATURE_REAL_TIME_JURY = 'real-time-jury';
export const FEATURE_HIDE_PUBLISH_DATE = 'hide-publish-date';

export const FEATURE_DECISION_NETWORKS = 'decision-networks';

//Flag to enable to disable product tour
export const FEATURE_PRODUCT_TOUR = 'no_product_tour';

export const BOOLEAN_DIRECT = 'boolean-direct';
export const BOOLEAN_USERNAME = 'boolean-username';

export const FLAG_FUNCTIONS: {[key: string]: {[key: string]: FeatureFlagType}} = {
    [FlagType.boolean]: {
        [BOOLEAN_DIRECT]: {
            type: BOOLEAN_DIRECT,
            label: 'Toggle the feature directly',
            flagValue: (flag) => (flag.value.data)
        },
        [BOOLEAN_USERNAME]: {
            type: BOOLEAN_USERNAME,
            label: 'Users with login name in list',
            flagValue: () => {}, // Client-side-only functions defined in BardFeatureFlagsProvider.js
            isClientOnly: true
        }
    }
};

export function mergeFlagFunctions(update) {
    merge(FLAG_FUNCTIONS, update);
}

export const getFlagValue = (flag: FeatureFlag, store?: StoreWithSharedState) => {
    if (flag.type === FlagType.select && flag.options) {
        // If the selected value for flag is a FeatureOption, return its value - otherwise return the string value.
        return flag.options.reduce((value, option) => (
            value || (typeof(option) === 'object' && option.label === flag.value ? option.value : value)), undefined
        ) || flag.value;
    }
    const flagTypeData = FLAG_FUNCTIONS[flag.type];
    if (flag.value.type && flagTypeData[flag.value.type].isClientOnly && !flag.isClientOnly) {
        throw new Error(`Feature flag type ${flag.value.type} cannot be checked on the server, but flag ${flag.key} is used on the server`);
    }
    return flag.value.type? flagTypeData[flag.value.type].flagValue(flag, store) : flagTypeData[flag.type].flagValue(flag,store);
    
};

export const FEATURE_FLAG_DEFAULT_VALUES: {[key: string]: FeatureFlag} = {
    [FEATURE_EXPLORE_PROBLEM_TYPE]: {
        key: FEATURE_EXPLORE_PROBLEM_TYPE,
        name: 'Explore Problem round type',
        type: FlagType.select,
        options: [STEP_MODE_DIRECT_EXCHANGE, STEP_MODE_DELPHI_EXCHANGE, STEP_MODE_OPEN],
        value: STEP_MODE_DIRECT_EXCHANGE,
        adminConsoleComment: 'Real Time Jury problems override this selection and always use Real-Time Judge.'
    },
    [FEATURE_CANDIDATE_SOLUTION_TYPE]: {
        key: FEATURE_CANDIDATE_SOLUTION_TYPE,
        name: 'Candidate Solution round type',
        type: FlagType.select,
        options: [STEP_MODE_DIRECT_EXCHANGE, STEP_MODE_DELPHI_EXCHANGE, STEP_MODE_OPEN],
        value: STEP_MODE_DIRECT_EXCHANGE,
        adminConsoleComment: 'Real Time Jury problems override this selection and always use Real-Time Judge.'
    },
    [FEATURE_SOLO_BARD_SAVE_REVERT_ALL_STEPS]: {
        key: FEATURE_SOLO_BARD_SAVE_REVERT_ALL_STEPS,
        name: 'Make the save/revert buttons for Solo Analysts affect data for all steps',
        type: FlagType.boolean,
        value: {type: BOOLEAN_DIRECT, data: true},
        isClientOnly: true
    },
    [FEATURE_REAL_TIME_JURY]: {
        key: FEATURE_REAL_TIME_JURY,
        name: 'Launch scheduled problems in Real Time Jury mode',
        type: FlagType.boolean,
        value: {type: BOOLEAN_DIRECT, data: true}
    },
    [FEATURE_USERS_OPT_OUT_TO_ROLE]: {
        key: FEATURE_USERS_OPT_OUT_TO_ROLE,
        name: 'Opted out users have role of:',
        type: FlagType.select,
        options: [constants.ROLE_ANALYST_NAME, constants.ROLE_OBSERVER_NAME],
        value: constants.ROLE_ANALYST_NAME
    },
    [FEATURE_HIDE_PUBLISH_DATE]: {
        key: FEATURE_HIDE_PUBLISH_DATE,
        name: 'Hide published date in the Published subtab of all steps',
        type: FlagType.boolean,
        value: {type: BOOLEAN_DIRECT, data: false}
    },
    [FEATURE_PRODUCT_TOUR]: {
        key: FEATURE_PRODUCT_TOUR,
        name: 'Enable product tour feature',
        type: FlagType.boolean,
        value: {type: BOOLEAN_DIRECT, data: false}
    },
    [FEATURE_DECISION_NETWORKS]: {
        key: FEATURE_DECISION_NETWORKS,
        name: 'Enable Decision variables and Utility nodes in Key Variables step',
        type: FlagType.boolean,
        value: {type: BOOLEAN_DIRECT, data: true},
        isClientOnly: true
    }
};
