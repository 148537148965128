import '../scss/problemDescription.scss';

import React, {Component} from 'react';
import {connect} from 'react-redux';
import classNames from 'classnames';
import {RouteComponentProps} from 'react-router';
import {withRouter} from 'react-router-dom';
import scrollIntoView from 'scroll-iv';

import EventSourceReduxDispatcher from '../container/EventSourceReduxDispatcher';
import * as constants from '../../common/util/constants';
import TipsComponent from './TipsComponent';
import {getProblemDBFromStore, SingleProblemType} from '../../common/reducers/allProblemsReducer';
import {getTeamFromStore} from '../../common/reducers/allTeamsReducerGetters';
import {StoreWithSharedState} from '../../common/reducers/sharedStateReducer';

const SECTION_SUMMARY  = 'summary';
const SECTION_SCENARIO = 'scenario';

interface ProblemDescriptionComponentProps {
    problem: SingleProblemType;
}

interface ProblemDescriptionComponentState {
    selected: null;
    scrolledTo: null;
}

class ProblemDescriptionComponent extends Component<ProblemDescriptionComponentProps, ProblemDescriptionComponentState> {

    constructor(props) {
        super(props);

        this.state = {
            selected: null,
            scrolledTo: null
        };

        this.onSelect = this.onSelect.bind(this);
    }

    onSelect(selected) {
        this.setState({selected});
    }

    renderHeadingButton(key, label) {
        return (
            <button
                key={key}
                title={key}
                className={classNames('semiMajorTitle-px', 'problemSummaryButtonStyle', {
                    selected: (this.state.selected === key)
                })}
                onClick={() => {this.onSelect(key)}}
            >
                {label}
            </button>
        );
    }

    renderHeadingButtonList(items: {[questionId: string]: string}, order: string[], title: string, tooltip: string, labelBase: string | null = null) {
        return (!items || order.length === 0) ? null : (
            <div className='problemDescriptionLeftDetailDiv'>
                <div className='mediumTextFont-px toolTipContainer problemDescriptionTitle'>{title}<span className='toolTip toolTipProblemDesc'>{tooltip}</span></div>
                {
                    order.map((key, index) => (
                        this.renderHeadingButton(key, labelBase ? (labelBase + ' ' + (index + 1)) : key)
                    ))
                }
            </div>
        );
    }

    renderLeftPanel() {
        const problem = this.props.problem;
        return (
            <div className='problemDescriptionLeftPanel'>

                <div className='majorTitle-px'>{problem.title}</div>

                <div className='problemDescriptionLeftDetailDiv'>
                    {this.renderHeadingButton(SECTION_SUMMARY, 'Summary')}
                    {this.renderHeadingButton(SECTION_SCENARIO, 'Scenario')}
                </div>

                {this.renderHeadingButtonList(problem.question, Object.keys(problem.question || {}), 'KEY QUESTIONS', 'All key questions must be answered in the Group’s final submitted intelligence report for this problem.', 'Question')}
                {this.renderHeadingButtonList(problem.evidence.content, problem.evidence.order, 'EVIDENCE', 'Supporting evidence')}
                {this.renderHeadingButtonList(problem.resource, Object.keys(problem.resource || {}), 'RESOURCES', 'These additional resources provide important background information.')}

            </div>
        );
    }

    renderProblemDetail(key, title, tooltip, value, isHtml = false) {
        return (
            <div key={key} className={classNames('problemDescriptionDetailDiv', {
                selected: (this.state.selected === key)
            })} ref={(element) => {
                if (element && this.state.selected === key && this.state.scrolledTo !== key) {
                    scrollIntoView(element, {behavior: 'smooth', block: 'nearest'});
                    this.setState({scrolledTo: key});
                }
            }}>
                <div className='midMajorTitle-px problemDescriptionRightSubTitleDiv'>{title}</div>
                {
                    (!tooltip) ? null : (
                        <div className='pageTip problemDescriptionPageTipQuestions'>{tooltip}</div>
                    )
                }
                {
                    isHtml ? (
                        <div className='medium-largeTextFont-px problemDescriptionRightTextDiv' dangerouslySetInnerHTML={{__html: value}}/>
                    ) : (
                        <div className='medium-largeTextFont-px problemDescriptionRightTextDiv'>{value}</div>
                    )
                }
            </div>
        );
    }

    renderProblemDetailList(items: {[questionId: string]: string}, order: string[], labelBase: string | null = null, isHtml = true) {
        return (!items || order.length === 0) ? null : (
            <div>
                {
                    order.map((key, index) => (
                        this.renderProblemDetail(key, labelBase ? (labelBase + ' ' + (index + 1)) : key, null, items[key], isHtml)
                    ))
                }
            </div>
        );
    }

    renderRightPanel() {
        // Render resources as hyperlinks with icons
        const problem = this.props.problem;
        const resources = !problem.resource ? {} :
            Object.keys(problem.resource).reduce((resources, key) => {
                resources[key] = (
                    <div className='problemDescriptionResourceLink'>
                        <a target='_blank' rel='noopener noreferrer' href={this.props.problem.resource[key]}>
                            <span className='material-icons'>open_in_new</span>&nbsp;{key}
                        </a>
                    </div>
                );
                return resources;
            }, {});
        return (
            <div className='problemDescriptionRightPanel'>

                <div className='problemDescriptionRightDetailDiv'>
                    <div className='majorTitle-px problemDescriptionRightTitleDiv'>{problem.title}</div>
                    
                    {this.renderProblemDetail(SECTION_SUMMARY, 'Problem Summary', null, problem.summary)}
                    {this.renderProblemDetail(SECTION_SCENARIO, 'Scenario', null, problem.scenario, true)}
                    {this.renderProblemDetailList(problem.question, Object.keys(problem.question || {}), 'Question')}
                    {this.renderProblemDetailList(problem.evidence.content, problem.evidence.order)}
                    {this.renderProblemDetailList(resources, Object.keys(resources), 'Resource', false)}

                </div>
            </div>
        );
    }

    render() {
        return !this.props.problem ? <EventSourceReduxDispatcher/> : (
            <div className='problemDescriptionPage'>
                <EventSourceReduxDispatcher/>

                <div className="threeColumnLayout">
                    <div className="workspaceLeftColumn">
                        {this.renderLeftPanel()}
                    </div>
                    <div className="workspaceContentColumn">
                        <div className="workspaceContentDiv">
                            {this.renderRightPanel()}
                        </div>
                    </div>
                    <div className="workspaceRightColumn">
                        <TipsComponent step={constants.HELP_STEP_PROBLEM_DESC} side={constants.TIP_SCREEN_RIGHT}/>
                    </div>
                </div>
            </div>
        );
    }
}

function mapStoreToProps(store: StoreWithSharedState, myProps: Partial<ProblemDescriptionComponentProps> & RouteComponentProps<{problemStepId: string}>): ProblemDescriptionComponentProps {
    const problemStepId = Number(myProps.match.params.problemStepId);

    if (myProps.problem) {
        return {problem: myProps.problem};
    }

    const team = getTeamFromStore(store, problemStepId);
    return {
        problem: team && getProblemDBFromStore(store, team.problemStep.problemId)
    }
}

export default withRouter(connect(mapStoreToProps)(ProblemDescriptionComponent));