import {v4} from "uuid";
import {combineReducers, Reducer} from "redux";

import {objectMapReducer, orderedListReducer, timestampedReducer, ObjectMapState, TimestampedState} from "../util/genericReducers";
import ensureFieldPath from '../ensureFieldPath';
import * as constants from '../util/constants';
import {safeOrderObject} from '../util/safeOrder';
import { StoreWithSharedState } from "./sharedStateReducer";

// ======== Constants

const ADD_KEY_FACT = 'add_key_fact';
const DELETE_KEY_FACT = 'delete_key_fact';
const UPDATE_KEY_FACT_TEXT = 'update_key_fact_text';
const UPDATE_KEY_FACT_RATIONALE = 'update_key_fact_rationale';
const REORDER_KEY_FACT = 'reorder_key_fact';

export enum KeyFactType {
    HYPOTHESIS = 'hypothesis',
    EVIDENCE = 'evidence'
}

export interface SingleKeyFactState {
    text: string;
    rationale: string;
    keyFactType?: KeyFactType;
}

export interface KeyFactsState extends TimestampedState {
    fact: ObjectMapState<SingleKeyFactState>;
    order: string[];
}

// ======== Reducers

const singleKeyFactReducer: Reducer<SingleKeyFactState> = (state = {text: '', rationale: ''}, action) => {
    switch (action.type) {
        case ADD_KEY_FACT:
            return {text: '', rationale: '', keyFactType: action.keyFactType};
        case UPDATE_KEY_FACT_TEXT:
            return {...state, text: action.text};
        case UPDATE_KEY_FACT_RATIONALE:
            return {...state, rationale: action.rationale};
        default:
            return state;
    }
};

const allKeyFactsReducer = objectMapReducer('factId', singleKeyFactReducer, {deleteActionType: DELETE_KEY_FACT});

const keyFactsOrderReducer = orderedListReducer('factId', ADD_KEY_FACT, DELETE_KEY_FACT, REORDER_KEY_FACT);

const keyFactsReducer = combineReducers<KeyFactsState>({
    fact: allKeyFactsReducer,
    order: keyFactsOrderReducer,
    timestamp: (state: string = '') => (state)
});

export const initialKeyFactsState: KeyFactsState = {fact: {}, order: []};

const keyFactsCopyableReducer: Reducer<KeyFactsState> = (state = initialKeyFactsState, action) => {
    switch (action.type) {
        case constants.COPY_PUBLISHED_ACTION:
            return action.fromUser.keyFacts ? {...action.fromUser.keyFacts} : state;
        case constants.APPEND_PUBLISHED_ACTION:
            const publishedFacts = action.fromUser.keyFacts;
            if (publishedFacts) {
                return safeOrderObject(publishedFacts, 'fact').reduce((previous, factId) => {
                    previous.fact[factId] = publishedFacts.fact[factId];
                    return previous;
                }, {...state, fact: {...state.fact}, order: [...publishedFacts.order, ...state.order]});
            } else {
                return state;
            }
        default:
            return keyFactsReducer(state, action);
    }
};

export default timestampedReducer(keyFactsCopyableReducer);

// ======== DBSync functions
// (used by DBSync and for unit testing)

export function getDBSyncActionTypes() {
    return [ADD_KEY_FACT, DELETE_KEY_FACT, UPDATE_KEY_FACT_RATIONALE, UPDATE_KEY_FACT_TEXT, REORDER_KEY_FACT];
}

export function setKeyFactsInStore(store: StoreWithSharedState, problemStepId, userId, status, object) {
    ensureFieldPath(store, 'sharedState', 'teams', problemStepId, 'user', userId, 'status', status);
    store.sharedState.teams[problemStepId].user[userId].status[status].keyFacts = object;
}

// ======== Action Generators

export function addKeyFactAction(problemStepId, userId, status, keyFactType = KeyFactType.HYPOTHESIS) {
    return {type: ADD_KEY_FACT, problemStepId, userId, status, factId: v4(), keyFactType};
}

export function deleteKeyFactAction(problemStepId, userId, status, factId, index) {
    return {type: DELETE_KEY_FACT, problemStepId, userId, status, factId, index}
}

export function updateKeyFactTextAction(problemStepId, userId, status, factId, text) {
    return {type: UPDATE_KEY_FACT_TEXT, problemStepId, userId, status, factId, text}
}

export function updateKeyFactRationaleAction(problemStepId, userId, status, factId, rationale) {
    return {type: UPDATE_KEY_FACT_RATIONALE, problemStepId, userId, status, factId, rationale}
}

export function reorderKeyFactsAction(problemStepId, userId, status, oldIndex, newIndex) {
    return {type: REORDER_KEY_FACT, problemStepId, userId, status, oldIndex, newIndex};
}

// ======== Getter functions

export function getKeyFactsFromStore(store: StoreWithSharedState, problemStepId, userId, status) {
    return store.sharedState.teams[problemStepId].user[userId].status[status].keyFacts;
}
