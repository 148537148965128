import {objectMapReducer, timestampedActionCreator} from '../../common/util/genericReducers';
import {RenderedRecentActivityType, UserActivityResponse} from '../../common/clientServer/userActivity';

export const APPEND_RECENT_ACTIVITY = 'APPEND_RECENT_ACTIVITY';
export const INIT_FETCH = 'INIT_FETCH';
const FETCH_ERRORED = 'FETCH_ERRORED';

export interface RecentActivityReducerType {
    currentOffset: number;
    errorMessage: string | null;
    activities: RenderedRecentActivityType[];
    activityIds: {[id: string]: boolean};
    isFetchingRecord: boolean;
    hasFetchedAllRecord: boolean;
}

const INITIAL_STATE: RecentActivityReducerType = {
    currentOffset: 0,
    errorMessage: null,
    activities: [],
    activityIds: {},
    isFetchingRecord: false,
    hasFetchedAllRecord: false
};

function recentActivityForProblemReducer(state: RecentActivityReducerType = INITIAL_STATE, action): RecentActivityReducerType {
    switch (action.type) {
        case APPEND_RECENT_ACTIVITY:
            const latestActivities: UserActivityResponse = action.payload;
            const newActivities = latestActivities.activities.filter((activity) => (!state.activityIds[activity.metadata.id]));
            if (newActivities.length === 0) {
                return {
                    ...state,
                    isFetchingRecord: false,
                    hasFetchedAllRecord: latestActivities.activities.length === 0,
                    errorMessage: null
                };
            }
            const activities = [...state.activities, ...newActivities];
            activities.sort((a1, a2) => {
                const t1 = new Date(a1.metadata.createdAt).getTime();
                const t2 = new Date(a2.metadata.createdAt).getTime();
                return t1 < t2 ? 1 : t1 === t2 ? 0 : -1;
            });
            const activityIds = newActivities.reduce((all, activity) => {
                all[activity.metadata.id] = true;
                return all;
            }, {...state.activityIds});
            return {
                currentOffset: latestActivities.offset,
                activities,
                activityIds,
                isFetchingRecord: false,
                hasFetchedAllRecord: latestActivities.count <= activities.length,
                errorMessage: null
            };
        case INIT_FETCH:
            return { ...state, isFetchingRecord: action.isFetchingRecord };
        case FETCH_ERRORED:
            return { ...state, errorMessage: action.errorMessage, isFetchingRecord: action.isFetchingRecord, hasFetchedAllRecord: action.hasFetchedAllRecord };
        default:
            return state;
    }
}

const recentActivityReducer = objectMapReducer<RecentActivityReducerType>('activityKey', recentActivityForProblemReducer);

export default recentActivityReducer;

export function getActivityKey(problemStepId?: number, userId?: number) {
    if (!problemStepId && !userId) {
        throw new Error('One of problemStepId or userId must be specified');
    }
    return problemStepId ? 'team_' + problemStepId : 'user_' + userId;
}

export function appendRecentActivityAction(problemStepId: number | undefined, userId: number | undefined, payload: UserActivityResponse) {
    return timestampedActionCreator({ type: APPEND_RECENT_ACTIVITY, activityKey: getActivityKey(problemStepId, userId), payload, isClientOnly: true });
}

export function initFetchRecentActivityAction(problemStepId: number | undefined, userId: number | undefined) {
    return timestampedActionCreator({ type: INIT_FETCH, activityKey: getActivityKey(problemStepId, userId), isFetchingRecord: true, isClientOnly: true });
}

export function fetchRecentActivityErroredAction(problemStepId: number | undefined, userId: number | undefined) {
    return timestampedActionCreator({ type: FETCH_ERRORED, activityKey: getActivityKey(problemStepId, userId), errorMessage: 'A problem occurred.', isFetchingRecord: false, hasFetchedAllRecord: false, isClientOnly: true });
}

export function getRecentActivityFromStore(store, problemStepId: number, userId: number): RecentActivityReducerType | undefined {
    const activityKey = getActivityKey(problemStepId, userId);
    return (store.clientState && store.clientState.recentActivities) ? store.clientState.recentActivities[activityKey] : undefined;
}
