import React, {Component} from 'react';
import {connect} from 'react-redux';
import {v4} from 'uuid';
import moment from 'moment';
import {arrayMove} from 'react-sortable-hoc';
import classNames from 'classnames';
import {without, isEmpty} from 'lodash';
import {DialogContainer} from 'react-md';
import memoizeOne from 'memoize-one';

import {
    deleteProblemStepAction,
    launchStrathclydeProblemAction,
    resetProblemStepStateAction,
    addProblemStepAction,
    closeProblemAction,
    launchFacilitatorAdvancedProblemAction,
    launchRealTimeJuryProblemAction,
    launchSoloBardProblemAction,
    launchRoundAction,
    extendCurrentRoundAction,
    AllTeamsReducerType,
    initialSingleProblemStepType,
    CombinedTeamReducerType,
    reopenProblemAction,
    setArchiveProblemAction
} from '../../common/reducers/allTeamsReducer';
import {
    AllProblemsReducerType,
    getAllProblemDBsFromStore, initialSingleProblemState, SingleProblemType,
    updateProblemAction
} from '../../common/reducers/allProblemsReducer';
import SingleInput from './SingleInput';
import {getUserFromStore} from '../reducers/loginReducer';
import PlainTextField from './PlainTextField';
import RichTextField from './RichTextField';
import ReorderableAddableContainer from '../container/ReorderableAddableContainer';
import ReorderableDeleteableElement from '../container/ReorderableDeleteableElement';
import {getAllUsersFromStore} from '../reducers/allUsersReducer';
import * as constants from '../../common/util/constants';
import {
    AllUsersForTeamReducerType,
    updateUserDBForProblem,
    updateUserRolesForProblem, UserForTeamType
} from '../../common/reducers/allUsersForTeamReducer';
import ReferenceData from '../../common/util/referenceData';
import LoadingBar from './LoadingBar';
import {getProblemType} from '../../common/util/problemType';
import StatusIconUser from './StatusIconUser';
import IconLabelButton from './IconLabelButton';
import {
    buildRestPath,
    CREATE_PROBLEM,
    CREATE_PROBLEM_STEP,
    DOWNLOAD_REPORT_ENDPOINT,
    DOWNLOAD_BN_ENDPOINT,
    DOWNLOAD_TEAM_REPORTS_ENDPOINT,
    UPLOAD_IMAGE_PATH
} from '../../common/clientServer/navigation';
import ProblemDescriptionComponent from './ProblemDescriptionComponent';
import {safeOrderObject} from '../../common/util/safeOrder';
import {addToastMessageAction} from '../reducers/snackbarToastReducer';
import LaunchComponent from './LaunchComponent';
import {getNextStepAndRound, getRoundId} from '../../common/util/roundLogic';
import FilterSuggestionField from './FilterSuggestionField';
import {parseBardUserFilterString, userMatchesFilter} from '../../common/filter/bardUserFilter';
import {BardUser} from '../../common/clientServer/bardUser';
import DispatchProp from '../../@types/dispatchProp';
import {ProblemStepPojo} from '../../server/orm/dao/problemStepDAO';
import {parseProblemFilterString, problemMatchesFilter} from '../../common/filter/problemFilter';
import MultiAssignComponent from './MultiAssignComponent';
import {ObjectMapState} from '../../common/util/genericReducers';
import DownloadNetworkComponent from './DownloadNetworkComponent';
import AdminUserPanel from './AdminUserPanel';
import RecentActivity from './RecentActivity';
import {BnWrapperState, getBnWrapperStateFromStore} from '../../common/reducers/bnWrapperStateReducer';
import {getAllTeamsFromStore} from '../../common/reducers/allTeamsReducerGetters';

const ROLE_DISPLAY_MAP = {
    [constants.ROLE_FACILITATOR]: 'Facilitator',
    [constants.ROLE_ANALYST]: 'Analyst',
    [constants.ROLE_OBSERVER]: 'Observer',
    [constants.ROLE_SOLO_ANALYST]: 'Solo Analyst',
    [constants.ROLE_UNALLOCATED]: 'Unallocated'
};

const ROLE_SELECTION_CYCLE_MAP = {
    [constants.ROLE_FACILITATOR]: constants.ROLE_ANALYST,
    [constants.ROLE_ANALYST]: constants.ROLE_OBSERVER,
    [constants.ROLE_OBSERVER]: constants.ROLE_UNALLOCATED,
    [constants.ROLE_UNALLOCATED]: constants.ROLE_FACILITATOR
};

const DOWNLOAD_REPORT_BTN_LBL = 'Download Report';
const DOWNLOAD_TEAM_REPORTS_BTN_LBL = 'Download Team Reports';
const DOWNLOAD_BN_BTN_LBL = 'Download BNs';
const LAUNCH_SOLO_BARD_BTN_LBL = 'Launch Problem';
const CLOSE_SOLO_BARD_BTN_LBL = 'Close Problem';

interface AdminProblemsPanelStoreProps {
    user: BardUser;
    problemDBs: AllProblemsReducerType;
    problemSteps: AllTeamsReducerType;
    allUsers: ObjectMapState<BardUser>;
    bnWrapperState: BnWrapperState;
}

type AdminProblemsPanelProps = AdminProblemsPanelStoreProps & DispatchProp;

interface UsersToAssignType {
    problemId?: number;
    problemStepId?: number;
    assignTo: string;
    role?: number;
    users: AllUsersForTeamReducerType;
    assignedUsers: {[key: string]: boolean};
    unassignedFilter: string;
    pendingSoloBardAnalysts?: number;
}

interface PopUpMenuItem {
    label: string;
    onClick: () => void;
    disabled?: boolean;
}

function isPopUpMenuItem(item: any): item is PopUpMenuItem {
    return (item.label && item.onClick);
}

interface PopUpMenuType {
    style: {[key: string]: any};
    items: (PopUpMenuItem | React.ReactElement)[];
}

interface AdminProblemsPanelState {
    flushChanges: {[key: string]: () => void};
    usersToAssign?: UsersToAssignType;
    problemStepSelected?: number[];
    problemSelected?: number;
    editProblem?: SingleProblemType;
    awaitingProblemId?: boolean;
    preview?: boolean;
    resetProblemStep?: number[];
    deleteProblemStep?: ProblemStepPojo;
    popUpMenu?: PopUpMenuType;
    launchProblemStepIds?: number[];
    awaitingProblemStepId?: number;
    problemFilter?: string;
    userIdToEdit?: number;
    viewActivity?: boolean;
}

class AdminProblemsPanel extends Component<AdminProblemsPanelProps, AdminProblemsPanelState> {

    static getDerivedStateFromProps(props: AdminProblemsPanelProps, state: AdminProblemsPanelState) {
        // Filter out any problemStepSelected values if the corresponding problemStep no longer exists.
        const problemStepsFiltered = (state.problemStepSelected && props.problemSteps) ?
            state.problemStepSelected.filter((id) => (props.problemSteps[id])) : undefined;
        const problemStepSelected = (problemStepsFiltered && problemStepsFiltered.length > 0) ? problemStepsFiltered : undefined;
        return {problemStepSelected};
    }

    constructor(props: AdminProblemsPanelProps) {
        super(props);
        this.dispatchUsersForProblemStep = this.dispatchUsersForProblemStep.bind(this);
        this.addFlushChanges = this.addFlushChanges.bind(this);
        this.onSave = this.onSave.bind(this);
        this.onCancelModal = this.onCancelModal.bind(this);
        this.getStepsForAllProblems = memoizeOne(this.getStepsForAllProblems.bind(this));
        this.renderSingleUserForProblemStep = this.renderSingleUserForProblemStep.bind(this);
        this.state = {
            flushChanges: {}
        };
    }

    updateNestedState(key, value, ...fields) {
        this.setState((state) => {
            let result, current;
            result = current = {};
            fields.forEach((field) => {
                current[field] = {...(state && state[field])};
                current = current[field];
                state = state && state[field];
            });
            if (value === undefined) {
                delete(current[key]);
            } else {
                current[key] = value;
            }
            return result;
        });
    }

    addFlushChanges(key, handler) {
        this.setState((oldState) => ({flushChanges: {...oldState.flushChanges, [key]: handler}}));
    }

    getStepsForAllProblems(problemSteps) {
        // Create reverse lookup map from problems to problemSteps
        return problemSteps && Object.keys(problemSteps).reduce((result, problemStepId) => {
            const problemId = problemSteps[problemStepId].problemStep.problemId;
            if (!result[problemId]) {
                result[problemId] = [problemStepId];
            } else {
                result[problemId].push(problemStepId);
            }
            return result;
        }, {});
    }

    async onSave() {
        // Validate
        let error = '';
        const savingProblem = this.state.editProblem!;
        // Ensure name is unique
        Object.keys(this.props.problemDBs).forEach((problemStepId) => {
            if (problemStepId !== String(savingProblem.id) && this.props.problemDBs[problemStepId].title === savingProblem.title) {
                error = 'The title "' + savingProblem.title + '" already exists.  It must be unique.';
            }
        });
        if (savingProblem.expectedStart && moment(savingProblem.expectedStart).isBefore()) {
            error = 'Start Date must be in the future.';
        } else if (savingProblem.expectedCompletion && moment(savingProblem.expectedCompletion).isBefore()) {
            error = 'Completion Date must be in the future.';
        } else if (savingProblem.expectedStart && savingProblem.expectedCompletion && moment(savingProblem.expectedStart).isAfter(moment(savingProblem.expectedCompletion))) {
            error = 'Completion Date must be later than Start Date.';
        } else if (!savingProblem.summary || !savingProblem.summary.trim()) {
            error = 'The Summary cannot be empty.';
        }
        if (error) {
            this.props.dispatch(addToastMessageAction(error));
            return;
        }
        Object.keys(this.state.flushChanges).forEach((key) => {
            this.state.flushChanges[key]();
        });
        let problemId = savingProblem.id;
        if (isNaN(problemId)) {
            this.setState({awaitingProblemId: true});
            const response = await fetch(CREATE_PROBLEM, {
                method: 'post',
                credentials: 'include'
            });
            if (response.ok) {
                problemId = await response.json();
                this.setState({awaitingProblemId: false});
            } else {
                console.error(response);
                this.props.dispatch(addToastMessageAction('There was a server error creating a new problem - please try again later.'));
                this.setState({awaitingProblemId: false});
                return;
            }
        }
        this.setState((prevState) => {
            this.props.dispatch(updateProblemAction(problemId, {...prevState.editProblem, id: problemId}));
            return {editProblem: undefined};
        });
    }

    renderEditUser() {
        return (
            <AdminUserPanel
                userIdToEdit={this.state.userIdToEdit}
                finishedEditingUser={() => {
                    this.setState({userIdToEdit: undefined});
                }}
            />
        )
    }

    renderEditProblem() {
        const editProblem = this.state.editProblem!;
        return (
            <div className='adminConsole'>
                <div className='adminConsoleContent editProblem'>
                    <table>
                        <tbody>
                        <tr>
                        <th>Problem Title:</th>
                            <td>
                                <SingleInput inputType='text' size={60} onChange={(value) => {
                                    this.updateNestedState('title', value, 'editProblem');
                                }} content={editProblem.title}/>
                            </td>
                        </tr>
                        <tr>
                            <th>Start Date:</th>
                            <td>
                                <SingleInput inputType='datetime-local'
                                             content={editProblem.expectedStart && moment(editProblem.expectedStart).format('YYYY-MM-DDTHH:mm')}
                                             onChange={(value: string) => {
                                                 this.updateNestedState('expectedStart', value ? moment(value) : null, 'editProblem');
                                             }}/>
                            </td>
                        </tr>
                        <tr>
                            <th>Completion Date:</th>
                            <td>
                                <SingleInput inputType='datetime-local'
                                             content={editProblem.expectedCompletion && moment(editProblem.expectedCompletion).format('YYYY-MM-DDTHH:mm')}
                                             onChange={(value: string) => {
                                                 this.updateNestedState('expectedCompletion', value ? moment(value) : null, 'editProblem');
                                             }}/>
                            </td>
                        </tr>
                        <tr>
                            <th>Summary:</th>
                            <td>
                                <PlainTextField className='resizeVertical fullWidth'
                                                text={editProblem.summary ? editProblem.summary : ''}
                                                rows={3} onChange={(value) => {
                                    this.updateNestedState('summary', value, 'editProblem');
                                }}/>
                            </td>
                        </tr>
                        <tr>
                            <th>Scenario:</th>
                            <td>
                                <RichTextField
                                    text={editProblem.scenario || ''}
                                    addFlushChanges={this.addFlushChanges}
                                    onChange={(value) => {
                                        this.updateNestedState('scenario', value, 'editProblem');
                                    }}
                                    config={{uploadUrl: UPLOAD_IMAGE_PATH}}
                                />
                            </td>
                        </tr>
                        </tbody>
                    </table>
                    <h2>Questions</h2>
                    <ReorderableAddableContainer
                        addLabel='Question'
                        onAdd={() => {
                            this.updateNestedState(v4(), '', 'editProblem', 'question');
                        }}
                    >
                        {
                            Object.keys(editProblem.question).map((questionId, index) => (
                                <ReorderableDeleteableElement
                                    key={questionId} index={index}
                                    disableReorder={true}
                                    onDelete={() => {
                                        this.updateNestedState(questionId, undefined, 'editProblem', 'question');
                                    }}
                                >
                                    <RichTextField
                                                 title={'Question ' + (index + 1)}
                                                 text={editProblem.question[questionId]}
                                                 addFlushChanges={this.addFlushChanges}
                                                 onChange={(value) => {
                                                     this.updateNestedState(questionId, value, 'editProblem', 'question');
                                                 }}/>
                                </ReorderableDeleteableElement>
                            ))
                        }
                    </ReorderableAddableContainer>
                    <h2>Evidence</h2>
                    <ReorderableAddableContainer
                        addLabel='Evidence'
                        onSortEnd={({oldIndex, newIndex}) => {
                            this.updateNestedState('order', arrayMove(editProblem.evidence.order, oldIndex, newIndex), 'editProblem', 'evidence');
                        }}
                        onAdd={() => {
                            const heading = 'Evidence ' + ((editProblem.evidence.order || []).length + 1);
                            const evidence = {
                                order: [...(editProblem.evidence.order || []), heading],
                                content: {...editProblem.evidence.content, [heading]: ''}
                            };
                            this.setState({editProblem: {...editProblem, evidence}});
                        }}
                    >
                        {
                            editProblem.evidence && !isEmpty(editProblem.evidence) ?
                            safeOrderObject(editProblem.evidence, 'content').map((heading, index) => (
                                <ReorderableDeleteableElement
                                    key={heading} index={index}
                                    onDelete={() => {
                                        let content = {...editProblem.evidence.content};
                                        delete(content[heading]);
                                        this.setState({editProblem: {...editProblem, evidence: {content, order: without(editProblem.evidence.order, heading)}}});
                                    }}
                                >
                                    <PlainTextField
                                        className='fullWidth' rows={1} changeOnBlur={true} text={heading}
                                        onChange={(newHeading) => {
                                            // Ensure newHeading doesn't collide with an existing heading
                                            const existingHeadingIndex = editProblem.evidence.order.indexOf(newHeading);
                                            let problem = '';
                                            if (!newHeading.trim()) {
                                                problem = 'Evidence heading may not be empty';
                                            } else if (existingHeadingIndex >= 0 && existingHeadingIndex !== index) {
                                                problem = `Evidence heading "${newHeading}" already exists for a different piece of evidence`;
                                            }
                                            if (problem) {
                                                window.alert(problem + ' - generating random GUID heading.  You should change this.');
                                                newHeading = v4();
                                            }
                                            let content = {
                                                ...editProblem.evidence.content,
                                                [newHeading]: editProblem.evidence.content[heading]
                                            };
                                            delete(content[heading]);
                                            let order = [...editProblem.evidence.order];
                                            order[index] = newHeading;
                                            this.updateNestedState('evidence', {content, order}, 'editProblem');
                                        }}
                                    />
                                    <RichTextField
                                        className='resizeVertical fullWidth'
                                        text={editProblem.evidence.content[heading]}
                                        addFlushChanges={this.addFlushChanges}
                                        onChange={(value) => {
                                            let content = {...editProblem.evidence.content};
                                            content[heading] = value;
                                            this.updateNestedState('content', content, 'editProblem', 'evidence');
                                        }}
                                        config={{uploadUrl: UPLOAD_IMAGE_PATH}}
                                    />
                                </ReorderableDeleteableElement>
                            ))
                            :
                            null
                        }
                    </ReorderableAddableContainer>
                    <h2>Resources</h2>
                    <ReorderableAddableContainer
                        addLabel='Resource Link'
                        onAdd={() => {
                            this.updateNestedState('', '', 'editProblem', 'resource');
                        }}
                    >
                        {
                            editProblem.resource ? (
                                Object.keys(editProblem.resource).map((text, index) => (
                                    <ReorderableDeleteableElement
                                        key={index} index={index}
                                        disableReorder={true} disabled={true}
                                        onDelete={() => {
                                            this.updateNestedState(text, undefined, 'editProblem', 'resource');
                                        }}
                                    >
                                        <div>{'Resource link ' + (index + 1)}</div>
                                        <PlainTextField className='fullWidth' rows={1} placeholder='Link text'
                                                        text={text} onChange={(value) => {
                                            let resource = {
                                                ...editProblem.resource,
                                                [value]: editProblem.resource[text]
                                            };
                                            delete(resource[text]);
                                            this.setState({editProblem: {...editProblem, resource}});
                                        }}/>
                                        <PlainTextField className='fullWidth' rows={1} placeholder='URL'
                                                        text={editProblem.resource[text]}
                                                        onChange={(value) => {
                                                            this.updateNestedState(text, value, 'editProblem', 'resource');
                                                        }}/>
                                    </ReorderableDeleteableElement>
                                ))
                            ) : (
                                null
                            )
                        }
                    </ReorderableAddableContainer>
                </div>
                {
                    this.state.awaitingProblemId ? (
                        <LoadingBar id='problemPending'/>
                    ) : (
                        <div className='adminButtonsDiv'>
                            <input type='button' value='Cancel' className='buttonStyle regularButtonStyle' onClick={() => {
                                this.setState({editProblem: undefined});
                            }}/>
                            <input type='button' value='Save' className='buttonStyle regularButtonStyle' onClick={this.onSave}/>
                            <input type='button' value='Preview' className='buttonStyle regularButtonStyle' onClick={() => {
                                this.setState({preview: true});
                            }}/>
                        </div>
                    )
                }
                <DialogContainer
                    id='problemPreviewPanel'
                    visible={!!this.state.preview}
                    title="Problem Preview"
                    onHide={() => {this.setState({preview: false})}}
                    autosizeContent={false}
                    stackedActions={false}
                >
                    <ProblemDescriptionComponent
                        problem={this.state.editProblem}
                    />
                </DialogContainer>
            </div>
        );
    }

    getUserNumberFromOrder(userId) {
        const usersToAssign = this.state.usersToAssign!;
        let count = 0;
        for (let id of Object.keys(usersToAssign.assignedUsers)) {
            if (usersToAssign.assignedUsers[id]) {
                count++;
                if (Number(id) === Number(userId)) {
                    return count;
                }
            }
        }
        return -1;
    }

    getUserDataFromState(userId): UserForTeamType {
        const usersToAssign = this.state.usersToAssign!;
        const displayName = (usersToAssign.problemStepId) ? `BARD User ${this.getUserNumberFromOrder(userId)}` : '';
        return (usersToAssign.users[userId] && usersToAssign.users[userId].db) ? usersToAssign.users[userId].db : {
            problemStepId: Number(usersToAssign.problemStepId),
            userId: Number(userId),
            role: usersToAssign.role || constants.ROLE_ANALYST,
            displayName,
            isNew: true,
            timeSpent: 0,
            publishedStepMask: 0,
            groupManagementState: 0,
            trainingUser: false
        };
    }

    renderUserRole(userData) {
        return (
            <span className={classNames('roleButton', 'roleBackgroundColour', 'override', {
                facilitator: userData.role === constants.ROLE_FACILITATOR,
                analyst: userData.role === constants.ROLE_ANALYST,
                observer: userData.role === constants.ROLE_OBSERVER,
                soloAnalyst: userData.role === constants.ROLE_SOLO_ANALYST,
                unallocated: userData.role === constants.ROLE_UNALLOCATED
            })} onClick={() => {
                if (!this.state.usersToAssign!.role) {
                    this.updateNestedState('role',
                        ROLE_SELECTION_CYCLE_MAP[userData.role],
                        'usersToAssign', 'users', userData.userId, 'db');
                    if (userData.groupManagementState !== constants.GRP_MGMT_NOP) {
                        this.updateNestedState('groupManagementState', constants.GRP_MGMT_NOP, 'usersToAssign', 'users', userData.userId, 'db');
                        this.updateNestedState('groupManagementStateDate', null, 'usersToAssign', 'users', userData.userId, 'db');
                    }
                }
            }}>
                {ROLE_DISPLAY_MAP[userData.role]}
            </span>
        );
    }

    renderSingleUserProgress(problemStepId, userId, isSoloAnalyst, problemStep) {
        if (this.props.problemSteps[problemStepId] && this.props.problemSteps[problemStepId].user[userId]) {
            const published = this.getStepNameRange([this.props.problemSteps[problemStepId].user[userId].db.publishedStepMask]);
            const notStarted = ReferenceData.getInstance().getStepDescriptionName(constants.STEP_NOT_LAUNCHED);
            return (isSoloAnalyst && problemStep && (problemStep.reportSubmittedAt || problemStep.systemReportSubmittedAt)) ? (
                `REPORT ${problemStep.systemReportSubmittedAt ? 'AUTO-' : ''}SUBMITTED`
            ) : (published && published !== notStarted) ? (
                (isSoloAnalyst ? 'Saved ' : 'Published ') + published
            ) : (
                isSoloAnalyst ? 'NEVER SAVED' : notStarted
            )
        } else {
            return '(new)';
        }
    }

    renderLaunchOrCloseButton(problemStep, userData) {
        if (!problemStep.state) {
            return <div className='cannotLaunchWithoutSaving'>Save before launching.</div>
        }

        if (problemStep.state === constants.STATE_ACTIVE) {
            return <IconLabelButton
                primary={true} className='small actionTheme closeSoloBardProblemButton'
                onClick={() => {this.props.dispatch(closeProblemAction(userData.problemStepId, moment().format()))}}
                label={CLOSE_SOLO_BARD_BTN_LBL}
                iconChildren={'stop'} />
        } else {
            return <IconLabelButton
                primary={true} className='small actionTheme launchSoloBardProblemButton'
                onClick={() => {this.props.dispatch(launchSoloBardProblemAction(userData.problemStepId))}}
                label={LAUNCH_SOLO_BARD_BTN_LBL}
                iconChildren={'play_arrow'} />
        }
    }

    renderSingleUserForProblemStep(userId) {
        if (this.state.usersToAssign!.assignedUsers[userId]) {
            const userData = this.getUserDataFromState(userId);
            const problemStep = userData.problemStepId && this.props.problemSteps[userData.problemStepId];
            const problemStepDb = userData.problemStepId && this.props.problemSteps[userData.problemStepId].problemStep;
            const isSoloAnalyst = (userData.role === constants.ROLE_SOLO_ANALYST);
            return (
                <div className='userForProblem'>
                    <div className='userDisplayName'>{this.props.allUsers[userId].loginName}</div>
                    <div className='userRole'>
                        Role: {this.renderUserRole(userData)}
                    </div>
                    <div className='userProgress'>{this.renderSingleUserProgress(userData.problemStepId, userId, isSoloAnalyst, problemStepDb)}</div>
                    {
                        isSoloAnalyst ? (
                            <IconLabelButton
                                primary={true} className='reportLink small actionTheme'
                                disabled={!problemStepDb || (!problemStepDb.systemReportSubmittedAt && !problemStepDb.reportSubmittedAt)}
                                href={problemStep ? buildRestPath(DOWNLOAD_REPORT_ENDPOINT, {problemStepId: userData.problemStepId}) + '?getFullZip=1' : '#'}
                                iconChildren={'get_app'}
                                label={DOWNLOAD_REPORT_BTN_LBL} />
                        ) : (
                            <div className='userProblemDisplayName'>
                                Display name:&nbsp;
                                <SingleInput inputType='text' content={userData.displayName} onChange={(value) => {
                                    this.updateNestedState('displayName', value, 'usersToAssign', 'users', userId, 'db');
                                }}/>
                            </div>
                        )
                    }
                    {
                        isSoloAnalyst ? (this.renderLaunchOrCloseButton(problemStepDb, userData)) : null
                    }
                    {
                        isSoloAnalyst ? (
                            <div>
                                <DownloadNetworkComponent
                                    className='downloadNetworkButton'
                                    problemStepId={userData.problemStepId}
                                    disabled={!problemStep || !this.canGetAgenaRiskDataFromProblemStep(problemStep, userData.userId)}
                                    label={DOWNLOAD_BN_BTN_LBL}
                                    buttonClassName='actionTheme'
                                    noSanityCheck={true}
                                />
                            </div>) : null
                    }
                    <div>
                        <IconLabelButton className='small actionTheme' iconChildren='edit' label='Edit User' onClick={() => {
                            this.setState({userIdToEdit: userId});
                        }}/>
                    </div>
                </div>
            );
        } else {
            return <div className="userNotAssigned">
                <div className="userDisplayName">{this.props.allUsers[userId].loginName}</div>
                <div className="userEmail">({this.props.allUsers[userId].emailAddress})</div>
            </div>;
        }
    }

    dispatchUsersForProblemStep() {
        let newUsersForProblem = {};
        let removedUsers: number[] = [];
        let facilitatorCount = 0;
        let unallocatedCount = 0;
        let roleMap = {};
        const usersToAssign = this.state.usersToAssign!;
        const problemStepId = usersToAssign.problemStepId!;
        const allUserProblems = this.props.problemSteps[problemStepId].user || {};
        let error = '';
        let uniqueNames = {};
        Object.keys(usersToAssign.assignedUsers)
            .filter((userId) => (usersToAssign.assignedUsers[userId]))
            .forEach((userId) => {
                newUsersForProblem[userId] = this.getUserDataFromState(userId);
                const role = newUsersForProblem[userId].role;
                if (role === constants.ROLE_FACILITATOR) {
                    facilitatorCount++;
                    roleMap[userId] = role;
                } else if (allUserProblems[userId] && allUserProblems[userId].db.role !== role) {
                    roleMap[userId] = role;
                }
                if (role === constants.ROLE_UNALLOCATED) {
                    unallocatedCount++;
                }
                const trimmedDisplayName = newUsersForProblem[userId].displayName.trim();
                if (!trimmedDisplayName) {
                    error = `User ${this.props.allUsers[userId].loginName} may not have an empty display name`;
                }
                if (uniqueNames[trimmedDisplayName]) {
                    error = `More than one user has display name "${trimmedDisplayName}"`;
                } else {
                    uniqueNames[trimmedDisplayName] = true;
                }
            });
        if (this.props.problemSteps[problemStepId].problemStep.stepId !== null && Object.keys(newUsersForProblem).length > unallocatedCount && facilitatorCount !== 1) {
            error = 'A team that advances through steps together must have exactly one facilitator if any roles are assigned.';
        }
        if (error) {
            this.props.dispatch(addToastMessageAction(error));
            return false;
        }
        if (Object.keys(roleMap).length > 0) {
            this.props.dispatch(updateUserRolesForProblem(problemStepId, roleMap));
        }
        Object.keys(usersToAssign.users).forEach((userId) => {
            if (!newUsersForProblem[userId]) {
                removedUsers.push(Number(userId));
            }
        });
        this.props.dispatch(updateUserDBForProblem(problemStepId, newUsersForProblem, removedUsers));
        return true;
    }

    canDispatchGroupLaunchSoloBardUsers() {
        const problemId = this.state.usersToAssign!.problemId;
        let soloUserProblems = this.getSoloBardUsersForProblem(problemId);
        return Object.keys(soloUserProblems).reduce((result, userId) => {
            if (this.props.problemSteps[soloUserProblems[userId].db.problemStepId].problemStep.state !== constants.STATE_ACTIVE) {
                return result + 1;
            }
            return result;
        }, 0) > 0;
    }

    dispatchGroupLaunchSoloBardUsers() {
        const problemId = this.state.usersToAssign!.problemId;
        let soloUserProblems = this.getSoloBardUsersForProblem(problemId);
        let actions: any[] = [];
        Object.keys(soloUserProblems).forEach((userId) => {
            if (this.props.problemSteps[soloUserProblems[userId].db.problemStepId].problemStep.state !== constants.STATE_ACTIVE) {
                actions.push(launchSoloBardProblemAction(soloUserProblems[userId].db.problemStepId));
            }
        });

        actions.forEach(this.props.dispatch);
    }

    canDispatchGroupCloseSoloBardUsers() {
        const problemId = this.state.usersToAssign!.problemId;
        let soloUserProblems = this.getSoloBardUsersForProblem(problemId);
        return Object.keys(soloUserProblems).reduce((result, userId) => {
            if (this.props.problemSteps[soloUserProblems[userId].db.problemStepId].problemStep.state === constants.STATE_ACTIVE) {
                return result + 1;
            }
            return result;
        }, 0) > 0;
    }

    dispatchGroupCloseSoloBardUsers() {
        const problemId = this.state.usersToAssign!.problemId;
        let soloUserProblems = this.getSoloBardUsersForProblem(problemId);
        let actions: any[] = [];
        Object.keys(soloUserProblems).forEach((userId) => {
            if (this.props.problemSteps[soloUserProblems[userId].db.problemStepId].problemStep.state === constants.STATE_ACTIVE) {
                actions.push(closeProblemAction(soloUserProblems[userId].db.problemStepId, moment().format()));
            }
        });

        actions.forEach(this.props.dispatch);
    }

    async dispatchSoloBardUsers() {
        const usersToAssign = this.state.usersToAssign!;
        const problemId = Number(usersToAssign.problemId);
        let soloUserProblems = this.getSoloBardUsersForProblem(problemId);
        let pendingSoloBardAnalysts = Object.keys(usersToAssign.assignedUsers)
            .filter((userId) => (usersToAssign.assignedUsers[userId] && !soloUserProblems[userId]))
            .length;
        this.updateNestedState('pendingSoloBardAnalysts', pendingSoloBardAnalysts, 'usersToAssign');
        let failedLoginNames: string[] = [];
        for (let userId of Object.keys(usersToAssign.assignedUsers)) {
            if (usersToAssign.assignedUsers[userId]) {
                if (!soloUserProblems[userId]) {
                    // User newly assigned to solo analyst role.  Add a problemStep in an appropriate state.
                    const problemStepId = await this.createNewProblemStep(this.state.problemSelected!);
                    if (problemStepId === undefined) {
                        failedLoginNames.push(this.props.allUsers[userId].loginName);
                    } else {
                        this.props.dispatch(updateUserDBForProblem(problemStepId, {[userId]: {...this.getUserDataFromState(userId), problemStepId}}, []));
                    }
                    this.updateNestedState('pendingSoloBardAnalysts', --pendingSoloBardAnalysts, 'usersToAssign');
                }
            } else {
                if (soloUserProblems[userId]) {
                    // Remove solo user and associated problemStep.
                    const problemStepId = soloUserProblems[userId].db.problemStepId;
                    this.props.dispatch(resetProblemStepStateAction(problemStepId));
                    this.props.dispatch(deleteProblemStepAction(problemStepId));
                }
            }
        }
        if (failedLoginNames.length > 0) {
            this.props.dispatch(addToastMessageAction('There were server errors creating the following solo analysts: ' + failedLoginNames.join(', ')));
            return false;
        }
        return true;
    }

    getTeamNumberForProblemStep(targetProblemStepId: number) {
        const problemId = this.props.problemSteps[targetProblemStepId].problemStep.problemId;
        const stepsForAllProblems = this.getStepsForAllProblems(this.props.problemSteps);
        return stepsForAllProblems[problemId].reduce((teamNumber, problemStepId, index) => {
            return teamNumber || (Number(problemStepId) === Number(targetProblemStepId) && index + 1);
        }, null);
    }

    renderAssignUsers() {
        const usersToAssign = this.state.usersToAssign!;
        const filter = parseBardUserFilterString(usersToAssign.unassignedFilter);
        return usersToAssign.pendingSoloBardAnalysts ? (
            <div className='adminConsole pendingUserProblems'>
                Saving to server... {usersToAssign.pendingSoloBardAnalysts} to go <LoadingBar id='savingUserAssignment'/>
            </div>
        ) : (
            <div className='adminConsole'>
                <div><b>Assign users to </b><span className='minorTitle'>{usersToAssign.assignTo}</span></div>
                <p>Drag users between the lines to assign them, or move the lines.</p>
                <div className='adminConsoleContent'>
                    <MultiAssignComponent
                        allIds={Object.keys(this.props.allUsers).filter((id) => (
                            usersToAssign.assignedUsers[id] || (!this.props.allUsers[id].admin && userMatchesFilter(this.props.allUsers[id], filter))
                        ))}
                        selectedIds={usersToAssign.assignedUsers}
                        renderItem={this.renderSingleUserForProblemStep}
                        onSelectionChanged={(id: number | string, selected: boolean) => {
                            this.updateNestedState('assignedUsers', {...usersToAssign.assignedUsers, [id]: selected}, 'usersToAssign');
                        }}
                    />
                </div>
                <div className='adminButtonsDiv'>
                    <FilterSuggestionField
                        key='userSuggestionField'
                        type='user'
                        className='filterInput'
                        placeholder='Filter unassigned users'
                        filter={usersToAssign.unassignedFilter}
                        onChange={(unassignedFilter) => {
                            this.setState({usersToAssign: {...usersToAssign, unassignedFilter}});
                        }}
                    />
                    <input type='button' value='Cancel' className='buttonStyle regularButtonStyle' onClick={() => {
                        this.setState({usersToAssign: undefined});
                    }}/>
                    <input type='button' value='Save' className='buttonStyle regularButtonStyle' onClick={async () => {
                        if ((usersToAssign.problemStepId && this.dispatchUsersForProblemStep())
                            || (!usersToAssign.problemStepId && await this.dispatchSoloBardUsers())) {
                            this.setState({usersToAssign: undefined});
                        }
                    }}/>
                    {!usersToAssign.problemStepId ? (<input type='button' value='Launch all' className='buttonStyle regularButtonStyle' onClick={() => {
                        this.dispatchGroupLaunchSoloBardUsers();
                    }} disabled={!this.canDispatchGroupLaunchSoloBardUsers()} />) : null}
                    {!usersToAssign.problemStepId ? (<input type='button' value='Close all' className='buttonStyle regularButtonStyle' onClick={() => {
                        this.dispatchGroupCloseSoloBardUsers();
                    }} disabled={!this.canDispatchGroupCloseSoloBardUsers()} />) : null}
                </div>
            </div>
        );
    }

    joinAnd(array) {
        if (array.length <= 1) {
            return array.join('');
        } else {
            return array.slice(0, -1).join(', ') + ' and ' + array.slice(-1);
        }
    }

    renderResetProblemSteps() {
        const teamMap = this.state.resetProblemStep!.reduce((map, problemStepId) => {
            const problemStep = this.props.problemSteps[problemStepId].problemStep;
            if (!map[problemStep.problemId]) {
                map[problemStep.problemId] = [];
            }
            map[problemStep.problemId].push(this.getTeamNumberForProblemStep(problemStepId));
            return map;
        }, {});
        const teams = Object.keys(teamMap).reduce<string[]>((teams, problemId) => (
            [...teams, this.props.problemDBs[problemId].title + ' team' + (teamMap[problemId].length > 1 ? 's ' : ' ') + this.joinAnd(teamMap[problemId])]
        ), []);
        const names = this.joinAnd(teams);
        return (
            <div className='adminConsole'>
                <h1>Reset {names} - Are you sure?</h1>
                <p>Pressing the Reset button below will discard all discussions and published data in the problem(s) for
                    the Analysts and the Facilitator in {names}, and reset them back to an unlaunched state.</p>
                <div className='adminButtonsDiv'>
                    <input type='button' value='Cancel' className='buttonStyle regularButtonStyle' onClick={() => {
                        this.setState({resetProblemStep: undefined});
                    }}/>
                    <input type='button' value='Reset' className='buttonStyle regularButtonStyle' onClick={() => {
                        this.state.resetProblemStep!.forEach((problemStepId) => {
                            this.props.dispatch(resetProblemStepStateAction(problemStepId));
                        });
                        this.setState({resetProblemStep: undefined});
                    }}/>
                </div>
            </div>
        );
    }

    renderDeleteProblemStep() {
        const deleteProblemStep = this.state.deleteProblemStep!;
        const problemId = deleteProblemStep.problemId;
        const teamNumber = this.getTeamNumberForProblemStep(deleteProblemStep.id);
        return (
            <div className='adminConsole'>
                <h1>Delete {this.props.problemDBs[problemId].title} Team {teamNumber} - Are you sure?</h1>
                <p>Pressing the Delete button below will discard all discussions and published data in the problem for
                    the Analysts and the Facilitator in team {teamNumber}, unassign all users from the team, and
                    remove the team from the system.</p>
                <div className='adminButtonsDiv'>
                    <input type='button' value='Cancel' className='buttonStyle regularButtonStyle' onClick={() => {
                        this.setState({deleteProblemStep: undefined});
                    }}/>
                    <input type='button' value='Delete' className='buttonStyle regularButtonStyle' onClick={() => {
                        const problemStepId = deleteProblemStep.id;
                        this.props.dispatch(resetProblemStepStateAction(problemStepId));
                        this.props.dispatch(deleteProblemStepAction(problemStepId));
                        this.setState({deleteProblemStep: undefined});
                    }}/>
                </div>
            </div>
        );
    }

    getSoloBardUsersForProblem(problemId) {
        const stepsForAllProblems = this.getStepsForAllProblems(this.props.problemSteps);
        const stepsForProblem = stepsForAllProblems[problemId] || [];
        return stepsForProblem.reduce((users, problemStepId) => {
            const userProblems = this.props.problemSteps[problemStepId].user;
            return Object.keys(userProblems).reduce((users, userId) => {
                if (userProblems[userId].db.role === constants.ROLE_SOLO_ANALYST) {
                    users[userId] = userProblems[userId];
                }
                return users;
            }, users);
        }, {});
    }

    isSoloBardProblemStep(problemStepId, problemId, renderSoloBardUsers) {
        const usersForProblemStep = this.props.problemSteps[problemStepId] && this.props.problemSteps[problemStepId].user;
        if (usersForProblemStep && Object.keys(usersForProblemStep).length === 1 &&
                usersForProblemStep[Object.keys(usersForProblemStep)[0]].db.role === constants.ROLE_SOLO_ANALYST) {
            renderSoloBardUsers[problemId] = true;
            return true;
        }
        return false;
    }

    getStepNameRange(publishedStepMasks) {
        // Return a range of published step names.
        const [minStepId, maxStepId] = publishedStepMasks.reduce(([minStepId, maxStepId], publishedStepMask) => {
            let stepId = constants.STEP_NOT_LAUNCHED;
            if (publishedStepMask) {
                let greatestBit;
                do {
                    greatestBit = publishedStepMask & -publishedStepMask;
                    publishedStepMask ^= greatestBit;
                } while (publishedStepMask);
                stepId = Math.log2(greatestBit);
            }
            minStepId = minStepId ? Math.min(minStepId, stepId) : stepId;
            maxStepId = maxStepId ? Math.max(maxStepId, stepId) : stepId;
            return [minStepId, maxStepId];
        }, [null, null]);
        return minStepId === null ? '' : (ReferenceData.getInstance().getStepDescriptionName(minStepId) + (minStepId === maxStepId ?
            '' : ' to ' + ReferenceData.getInstance().getStepDescriptionName(maxStepId)));
    }

    getCurrentStepName(problemStepId) {
        const problemStep = this.props.problemSteps[problemStepId].problemStep;
        const submitted = (problemStep.reportSubmittedAt || problemStep.systemReportSubmittedAt) ? ` (REPORT ${problemStep.systemReportSubmittedAt ? 'AUTO-' : ''}SUBMITTED)` : '';
        if (problemStep.state === constants.STATE_ARCHIVED) {
            return 'ARCHIVED';
        } else if (!problemStep.launched) {
            return 'NOT LAUNCHED';
        } else if (problemStep.stepId) {
            const referenceData = ReferenceData.getInstance();
            const roundId = getRoundId(problemStep.stepId, problemStep.roundSequenceNumber);
            const roundData = (roundId === null) ? null : referenceData.getRound(roundId);
            const roundName = roundData ? ` / ${roundData.name}` : '';
            return referenceData.getStepDescriptionName(problemStep.stepId) + roundName + submitted;
        } else if (problemStep.state === constants.STATE_CLOSED) {
            return 'CLOSED' + submitted;
        } else {
            // RTJ... report the range of published steps for the users.
            return this.getStepNameRange(Object.keys(this.props.problemSteps[problemStepId].user)
                .map((userId) => (this.props.problemSteps[problemStepId].user[userId].db.publishedStepMask))) + submitted;
        }
    }

    renderUserNamesForProblemStepAndRole(problemStepId, role) {
        const userProblems = this.props.problemSteps[problemStepId].user;
        return (
            <div className={classNames('roleList', {
                facilitator: role === constants.ROLE_FACILITATOR,
                analyst: role === constants.ROLE_ANALYST,
                observer: role === constants.ROLE_OBSERVER,
                unallocated: role === constants.ROLE_UNALLOCATED
            })}>
                {
                    Object.keys(userProblems).filter((userId) => (userProblems[userId].db.role === role)).map((userId) => (
                        `${userProblems[userId].db.displayName} (${this.props.allUsers[userId] ? this.props.allUsers[userId].loginName : 'UNKNOWN'})`
                    )).join(', ')
                }
            </div>
        );
    }

    renderTeamMembers(problemStepId) {
        const users = this.props.problemSteps[problemStepId].user;
        const numberUnallocated = Object.keys(users).filter((userId) => (users[userId].db.role === constants.ROLE_UNALLOCATED)).length;
        if (numberUnallocated > 0 && numberUnallocated === Object.keys(users).length) {
            return (
                <div className='adminTableNestedFlex'>
                    <StatusIconUser className='iconStyles unallocated'/>
                    {this.renderUserNamesForProblemStepAndRole(problemStepId, constants.ROLE_UNALLOCATED)}
                </div>
            );
        } else {
            return (
                <div className='adminTableNestedFlex'>
                    <StatusIconUser className='iconStyles facilitator'/>
                    {this.renderUserNamesForProblemStepAndRole(problemStepId, constants.ROLE_FACILITATOR)}
                    <StatusIconUser className='iconStyles analyst'/>
                    {this.renderUserNamesForProblemStepAndRole(problemStepId, constants.ROLE_ANALYST)}
                    <StatusIconUser className='iconStyles observer'/>
                    {this.renderUserNamesForProblemStepAndRole(problemStepId, constants.ROLE_OBSERVER)}
                    {numberUnallocated === 0 ? null : (<StatusIconUser className='iconStyles unallocated'/>)}
                    {numberUnallocated === 0 ? null : this.renderUserNamesForProblemStepAndRole(problemStepId, constants.ROLE_UNALLOCATED)}
                </div>
            );
        }
    }

    renderBadges(_problem: SingleProblemType) {
        return (
            <div className='badges'/>
        )
    }

    renderProblem(problemId: number) {
        const problemType = getProblemType(this.props.problemDBs[problemId]);
        return (
            <div className={classNames('adminTableRow', {selected: this.state.problemSelected === problemId})}>
                <label onClick={() => {
                    if (problemType !== constants.PROBLEM_TRAINING) {
                        this.setState({problemSelected: problemId, problemStepSelected: undefined, popUpMenu: undefined});
                    }
                }}>
                    <div className='adminTableTitle'>
                        <div className='adminTitle'>
                            <b>Problem Title:</b>
                            <span className='mediumTextFont'>{this.props.problemDBs[problemId].title}</span>
                            {this.renderBadges(this.props.problemDBs[problemId])}
                        </div>
                        <div className='adminTitle'>
                            <b>Start Date:</b>
                            <span className='mediumTextFont'>{this.props.problemDBs[problemId].expectedStart ? moment(this.props.problemDBs[problemId].expectedStart).format('LLLL') : 'NOT SET'}</span>
                            <b>Completion Date:</b>
                            <span className='mediumTextFont'>{this.props.problemDBs[problemId].expectedCompletion ? moment(this.props.problemDBs[problemId].expectedCompletion).format('LLLL') : 'NOT SET'}</span>
                        </div>
                    </div>
                    <div className='problemStatus noBackground'>
                        {
                            problemType === constants.PROBLEM_TRAINING ? (
                                <span>Training Problem</span>
                            ) : (
                                <span>BARD Problem</span>
                            )
                        }
                    </div>
                </label>
            </div>
        );
    }

    isModalVisible() {
        return (this.state.launchProblemStepIds !== undefined);
    }

    areSelectedProblemStepsInSameStep() {
        return this.state.problemStepSelected && null !== this.state.problemStepSelected.reduce((sameStepId: any, problemStepId: number) => {
            if (sameStepId !== null && sameStepId !== problemStepId) {
                const sameStep = this.props.problemSteps[sameStepId].problemStep;
                const problemStep = this.props.problemSteps[problemStepId].problemStep;
                if (sameStep.state !== problemStep.state || (problemStep.state === constants.STATE_ACTIVE
                        && (sameStep.launchMode !== problemStep.launchMode || sameStep.stepId !== problemStep.stepId || sameStep.roundSequenceNumber !== problemStep.roundSequenceNumber))) {
                    return null;
                }
            }
            return sameStepId;
        });
    }

    async createNewProblemStep(problemId: number): Promise<number | undefined> {
        const response = await fetch(CREATE_PROBLEM_STEP, {
            method: 'post',
            credentials: 'include',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({problemId})
        });
        if (response.ok) {
            const problemStepId = await response.json();
            this.props.dispatch(addProblemStepAction(problemStepId, {...initialSingleProblemStepType, problemId, state: constants.STATE_READY}));
            return problemStepId;
        } else {
            console.error(response);
            return undefined;
        }
    }

    onCancelModal() {
        this.setState({launchProblemStepIds: undefined});
    }

    renderModalContent() {
        const launchProblemStepIds = this.state.launchProblemStepIds;
        if (launchProblemStepIds) {
            return (
                <LaunchComponent
                    problemStepId={launchProblemStepIds[0]}
                    onLaunch={(launchAction) => {
                        launchProblemStepIds.slice(1).forEach((problemStepId) => {
                            this.props.dispatch(launchRoundAction(problemStepId, launchAction.launchTimestamp,
                                launchAction.launchStepId, launchAction.launchRoundSequenceNumber,
                                launchAction.launchRoundId, launchAction.activityDuration));
                        });
                    }}
                    onClose={(closeAction) => {
                        launchProblemStepIds.slice(1).forEach((problemStepId) => {
                            this.props.dispatch(closeProblemAction(problemStepId, closeAction.closeTimestamp));
                        });
                    }}
                    onExtend={(extendAction) => {
                        launchProblemStepIds.slice(1).forEach((problemStepId) => {
                            this.props.dispatch(extendCurrentRoundAction(problemStepId, extendAction.stepId,
                                extendAction.activityDuration));
                        });
                    }}
                />
            );
        } else {
            return null;
        }
    }

    renderModalActions() {
        return [
            <button key='cancel' className='buttonStyle linkButtonStyle discussionDeleteReasonCancelButton' onClick={this.onCancelModal}>CANCEL</button>
        ];
    }

    // problemMatchesFilter function requires a deeply nested problem structure, with problems containing teams, teams
    // containing userProblems and userProblems referencing their BardUser.
    buildFilterableProblem(problemId: number) {
        let problem = {...this.props.problemDBs[problemId]};
        const teams = Object.keys(this.props.problemSteps)
            .map((teamId) => (this.props.problemSteps[teamId]))
            .filter((team) => (team.problemStep.problemId === problemId));
        problem.teams = teams.map((team) => ({
            ...team.problemStep,
            users: Object.keys(team.user || {}).map((userForTeamId) => ({
                ...team.user[userForTeamId].db,
                User: this.props.allUsers[team.user[userForTeamId].db.userId]
            }))
        }));
        return problem
    }

    renderSelectProblem() {
        const firstSelectedProblemStep = this.state.problemStepSelected && this.props.problemSteps[this.state.problemStepSelected[0]].problemStep;
        const firstSelectedProblemStepUsers = this.state.problemStepSelected && this.props.problemSteps[this.state.problemStepSelected[0]].user;
        const selectedProblem = this.state.problemSelected ? this.props.problemDBs[this.state.problemSelected] : undefined;
        const filteredProblemIds = Object.keys(this.props.problemDBs || {})
            .map((problemId) => (Number(problemId)))
            .filter((problemId) => (
                problemMatchesFilter(this.buildFilterableProblem(problemId), parseProblemFilterString(this.state.problemFilter || ''))
            ))
            .sort((p1, p2) => (
                this.props.problemDBs[p1].isTraining !== this.props.problemDBs[p2].isTraining ?
                    (this.props.problemDBs[p1].isTraining ? 1 : -1) :
                    (this.props.problemDBs[p1].title !== this.props.problemDBs[p2].title ?
                        (this.props.problemDBs[p1].title < this.props.problemDBs[p2].title ? -1 : 1) : 0)));
        let renderSoloBardUsers = {};
        return (
            <div className='adminConsole'>
                <div className='majorTitle'>Problems</div>
                <p>Select a problem to edit or copy below, or click 'New Problem' to create a new one.</p>
                <div className='adminConsoleContent'>
                    {
                        filteredProblemIds
                            .map((problemId) => {
                                const stepsForAllProblems = this.getStepsForAllProblems(this.props.problemSteps);
                                return (
                                    <div className={classNames('adminTableMetaRow', {
                                        trainingProblem: this.props.problemDBs[problemId].isTraining
                                    })} key={problemId}>
                                        {
                                            this.renderProblem(problemId)
                                        }
                                        {
                                            !stepsForAllProblems[problemId] ? null : (
                                                [
                                                    ...this.getFilteredProblemStepIdsForProblem(problemId, renderSoloBardUsers)
                                                        .map((problemStepId, index) => (
                                                            this.renderNormalProblemStep(problemStepId, problemId, index)
                                                        )),
                                                    this.renderSoloBardProblemStep(problemId, renderSoloBardUsers),
                                                    this.state.awaitingProblemStepId === problemId ? (
                                                        <LoadingBar key={problemId} id='problemStepPending'/>
                                                    ) : null
                                                ]
                                            )
                                        }
                                    </div>
                                );
                            })
                    }
                </div>
                <div className='adminProblemSearchDiv'>
                    <FilterSuggestionField
                        key='problemSuggestionField'
                        type='problem'
                        className='filterInput'
                        placeholder='Filter problems'
                        filter={this.state.problemFilter}
                        onChange={(problemFilter) => {this.setState({problemFilter})}}
                    />
                </div>
                <div className='adminButtonsDiv'>
                    <input type='button' value='New Problem' className='buttonStyle regularButtonStyle'
                           onClick={() => {
                               this.setState({
                                   editProblem: {
                                       ...initialSingleProblemState,
                                       id: v4(),
                                       title: 'New Problem',
                                       created: new Date(),
                                       expectedStart: moment().add(10, 'minutes').toDate(),
                                       expectedCompletion: moment().add(7, 'days').toDate()
                                   },
                                   problemSelected: undefined
                               });
                           }}/>
                    <input type='button' value='Edit Problem'
                           className='buttonStyle regularButtonStyle'
                           disabled={!this.state.problemSelected}
                           onClick={() => {
                               const problem = selectedProblem!;
                               this.setState({
                                   editProblem: {
                                       ...initialSingleProblemState,
                                       ...selectedProblem,
                                       expectedStart: !problem.expectedStart || moment(problem.expectedStart).isBefore() ? undefined : problem.expectedStart,
                                       expectedCompletion: !problem.expectedCompletion || moment(problem.expectedCompletion).isBefore() ? undefined : problem.expectedCompletion
                                   },
                                   problemSelected: undefined
                               });
                           }}/>
                    <input type='button' value='Copy Problem'
                           className='buttonStyle regularButtonStyle'
                           disabled={!this.state.problemSelected}
                           onClick={() => {
                               if (window.confirm("Only copy a problem if you intend to change more than just the title." +
                                       "  If you want to have multiple teams working independently on the same problem," +
                                       " you can simply assign additional teams to the one problem.")) {
                                   this.setState({
                                       editProblem: {
                                           ...initialSingleProblemState,
                                           ...selectedProblem,
                                           id: v4()
                                       },
                                       problemSelected: undefined
                                   });
                               }
                           }}/>
                    <input type='button' value='Add Team'
                           className='buttonStyle regularButtonStyle'
                           disabled={!this.state.problemSelected}
                           onClick={async () => {
                               this.setState({awaitingProblemStepId: this.state.problemSelected});
                               const problemStepId = await this.createNewProblemStep(this.state.problemSelected!);
                               if (problemStepId === undefined) {
                                   this.props.dispatch(addToastMessageAction('There was a server error creating the team - please try again later.'));
                               }
                               this.setState({awaitingProblemStepId: undefined});
                           }}/>
                    <input type='button' value='Manage Solo Analysts'
                           className='buttonStyle regularButtonStyle'
                           disabled={!this.state.problemSelected}
                           onClick={() => {
                               const users = this.getSoloBardUsersForProblem(this.state.problemSelected);
                               this.setState({
                                   usersToAssign: {
                                       problemId: this.state.problemSelected,
                                       assignTo: `Solo Analysts for ${selectedProblem!.title}`,
                                       role: constants.ROLE_SOLO_ANALYST,
                                       users,
                                       assignedUsers: Object.keys(users || {}).reduce((all, id) => ({...all, [id]: true}), {}),
                                       unassignedFilter: ''
                                   }
                               });
                           }}
                    />
                    <input type='button' value='View Activity' className='buttonStyle regularButtonStyle'
                           disabled={!firstSelectedProblemStep || this.state.problemStepSelected!.length !== 1}
                           onClick={() => {
                               this.setState({viewActivity: true})
                           }}/>
                    <input type='button' value='Assign Users to Team'
                           className='buttonStyle regularButtonStyle'
                           disabled={!firstSelectedProblemStep || this.state.problemStepSelected!.length !== 1}
                           onClick={() => {
                               const problemStepId = this.state.problemStepSelected![0];
                               const teamNumber = this.getTeamNumberForProblemStep(problemStepId);
                               this.setState({
                                   usersToAssign: {
                                       problemStepId,
                                       assignTo: `Team ${teamNumber} for ${this.props.problemDBs[firstSelectedProblemStep!.problemId].title}`,
                                       users: firstSelectedProblemStepUsers!,
                                       assignedUsers: Object.keys(firstSelectedProblemStepUsers || {}).reduce((all, id) => ({...all, [id]: true}), {}),
                                       unassignedFilter: ''
                                   },
                                   problemSelected: undefined
                               });
                           }}/>
                    {
                        (firstSelectedProblemStep && firstSelectedProblemStep.state === constants.STATE_ACTIVE
                                && (firstSelectedProblemStep.stepId === null || getNextStepAndRound(firstSelectedProblemStep).nextStepId === null)) ? (
                            <input type='button' value='Close' className='buttonStyle regularButtonStyle'
                                   disabled={!this.areSelectedProblemStepsInSameStep()}
                                   onClick={() => {
                                       this.state.problemStepSelected!.forEach((problemStepId) => {
                                           this.props.dispatch(closeProblemAction(problemStepId, moment().format()));
                                       });
                                   }}/>
                        ) : (firstSelectedProblemStep && firstSelectedProblemStep.state === constants.STATE_CLOSED) ? (
                            <input type='button' value='Re-open' className='buttonStyle regularButtonStyle'
                                   disabled={!this.areSelectedProblemStepsInSameStep()}
                                   onClick={() => {
                                       this.state.problemStepSelected!.forEach((problemStepId) => {
                                           this.props.dispatch(reopenProblemAction(problemStepId));
                                       });
                                   }}/>
                        ) : (
                            <input type='button' value='Launch...' className='buttonStyle regularButtonStyle'
                                   disabled={!firstSelectedProblemStep
                                       || !this.areSelectedProblemStepsInSameStep()
                                       || (firstSelectedProblemStep.state !== constants.STATE_READY && firstSelectedProblemStep.stepId === null)}
                                   onClick={(evt) => {
                                       if (firstSelectedProblemStep!.state === constants.STATE_READY) {
                                           const problemStepSelected = this.state.problemStepSelected!;
                                           this.setState({
                                               popUpMenu: {
                                                   style: {bottom: window.innerHeight - evt.pageY + 10, left: evt.pageX - 10},
                                                   items: [
                                                       {label: 'Launch Facilitator Controlled', onClick: () => {
                                                               problemStepSelected.forEach((problemStepId) => {
                                                               this.props.dispatch(launchFacilitatorAdvancedProblemAction(problemStepId, 48))
                                                           });
                                                       }},
                                                       {label: 'Launch Real Time Jury', onClick: () => {
                                                           problemStepSelected.forEach((problemStepId) => {
                                                               this.props.dispatch(launchRealTimeJuryProblemAction(problemStepId))
                                                           });
                                                       }},
                                                       {label: 'Launch Strathclyde Mode', onClick: () => {
                                                           problemStepSelected.forEach((problemStepId) => {
                                                               this.props.dispatch(launchStrathclydeProblemAction(problemStepId))
                                                           });
                                                       }}
                                                   ]
                                               }
                                           });
                                       } else {
                                           this.setState({launchProblemStepIds: this.state.problemStepSelected});
                                       }
                                   }}/>
                        )
                    }
                    <span title='Closed problems can be archived, which hides them everwhere except in the admin panel.  Archived problems can be unarchived to closed.'>
                        <input type='button' value={(firstSelectedProblemStep && firstSelectedProblemStep.state === constants.STATE_ARCHIVED) ? 'Unarchive' : 'Archive'}
                               className='buttonStyle regularButtonStyle'
                               disabled={!firstSelectedProblemStep || !this.areSelectedProblemStepsInSameStep() ||
                                   (firstSelectedProblemStep.state !== constants.STATE_CLOSED && firstSelectedProblemStep.state !== constants.STATE_ARCHIVED)}
                               onClick={() => {
                                   this.state.problemStepSelected!.forEach((problemStepId) => {
                                       this.props.dispatch(setArchiveProblemAction(problemStepId,
                                           firstSelectedProblemStep!.state !== constants.STATE_ARCHIVED));
                                   });
                               }}/>
                    </span>
                    <input type='button' value='Reset Team' className='buttonStyle regularButtonStyle'
                           disabled={!firstSelectedProblemStep}
                           onClick={() => {
                               this.setState({resetProblemStep: this.state.problemStepSelected});
                           }}/>
                    <input type='button' value='Delete Team' className='buttonStyle regularButtonStyle'
                           disabled={!firstSelectedProblemStep || this.state.problemStepSelected!.length > 1}
                           onClick={() => {
                               this.setState({
                                   deleteProblemStep: firstSelectedProblemStep,
                                   problemStepSelected: undefined
                               });
                           }}/>
                    <input type='button' value='Downloads...' className='buttonStyle regularButtonStyle'
                           disabled={!firstSelectedProblemStep}
                           onClick={(evt) => {
                               const problemStepSelected = this.state.problemStepSelected!;
                               this.setState({
                                   popUpMenu: {
                                       style: {bottom: window.innerHeight - evt.pageY + 10, left: evt.pageX - 10},
                                       items: [
                                           {
                                               label: 'Download Training Problem',
                                               disabled: problemStepSelected.length > 1,
                                               onClick: () => {
                                                    const closeProblem = window.confirm('Download training problem in closed form?');
                                                    window.location.href = `/api/downloadTraining/${problemStepSelected}${closeProblem ? '?close=1' : ''}`;
                                               }
                                           },
                                           {
                                               label: DOWNLOAD_REPORT_BTN_LBL,
                                               disabled: (!firstSelectedProblemStep!.systemReportSubmittedAt && !firstSelectedProblemStep!.reportSubmittedAt) || problemStepSelected.length > 1,
                                                    onClick: () => {
                                                    window.location.href = buildRestPath(DOWNLOAD_REPORT_ENDPOINT, {problemStepId: problemStepSelected}) + '?getFullZip=1';
                                               }
                                           },
                                           ...this.props.bnWrapperState.engines.map((engine) => ({
                                               label: DOWNLOAD_BN_BTN_LBL + ' - ' + engine.name,
                                               disabled: !this.canDownloadGroupBN(problemStepSelected),
                                               onClick: () => {
                                                   problemStepSelected.forEach((problemStepId) => {
                                                       if (this.canDownloadGroupBN([problemStepId])) {
                                                           window.open(buildRestPath(DOWNLOAD_BN_ENDPOINT, {problemStepId, format: engine.name}));
                                                       }
                                                   });
                                               }
                                           })),
                                           {
                                                label: DOWNLOAD_TEAM_REPORTS_BTN_LBL,
                                                onClick: () => {
                                                    window.location.href = buildRestPath(DOWNLOAD_TEAM_REPORTS_ENDPOINT, {problemStepId: problemStepSelected});
                                                }
                                           },
                                       ]
                                   }
                               });
                           }}/>
               </div>
                {
                    !this.state.popUpMenu ? null : (
                        <div className='popUpMenu' style={this.state.popUpMenu.style}>
                            {
                                this.state.popUpMenu.items.map((menuItem) => (
                                    (!isPopUpMenuItem(menuItem)) ? menuItem : (
                                    <input key={menuItem.label} type='button' value={menuItem.label} className='buttonStyle regularButtonStyle'
                                           disabled={menuItem.disabled}
                                           onClick={() => {
                                               menuItem.onClick();
                                               this.setState({popUpMenu: undefined});
                                           }}
                                    />
                                )))
                            }
                            <input type='button' value='Cancel' className='buttonStyle regularButtonStyle'
                                   onClick={() => {this.setState({popUpMenu: undefined})}}
                            />
                        </div>
                    )
                }
                <DialogContainer
                    id="problemPanelModal"
                    visible={this.isModalVisible()}
                    aria-label='Admin Problem Panel'
                    onHide={this.onCancelModal}
                    actions={this.renderModalActions()}
                    stackedActions={false}
                >
                    {this.renderModalContent()}
                </DialogContainer>
            </div>
        );
    }

    getFilteredProblemStepIdsForProblem(problemId, renderSoloBardUsers) {
        const stepsForAllProblems = this.getStepsForAllProblems(this.props.problemSteps);
        return stepsForAllProblems[problemId]
            .filter((problemStepId) => (!this.isSoloBardProblemStep(problemStepId, problemId, renderSoloBardUsers)));
    }

    canDownloadGroupBN(problemStepIds) {
        return problemStepIds.reduce((result, problemStepId) => {
            const problemStep = this.props.problemSteps[problemStepId];
            return result || Object.keys(problemStep.user).reduce((result, userId) => {
                if (this.canGetAgenaRiskDataFromProblemStep(problemStep, Number(userId))) {
                    result+=1;
                }
                return result;
            }, 0) > 0
        }, false);
    }

    renderSoloBardProblemStep(problemId, renderSoloBardUsers) {
        if (!renderSoloBardUsers[problemId]) {
            return null;
        } else {
            const userProblems = this.getSoloBardUsersForProblem(problemId);
            return (
                <div className='adminTableRow subRow' key={'solo' + problemId}>
                    <label onClick={() => {
                        this.setState({problemSelected: problemId, problemStepSelected: undefined, popUpMenu: undefined});
                    }}>
                        <div className='adminTableTitle'>
                            Solo Analysts
                        </div>
                        <div className='adminTableNestedFlex'>
                            <StatusIconUser className='iconStyles soloAnalyst'/>
                            <div className='roleList soloAnalyst'>
                                {
                                    Object.keys(userProblems).map((userId) => (
                                        `${this.props.allUsers[userId] ? this.props.allUsers[userId].loginName : 'UNKNOWN'}`
                                    )).join(', ')
                                }
                            </div>
                        </div>
                        <div className='problemStatus noStepId'>
                            {this.getStepNameRange(Object.keys(userProblems).map((userId) => (userProblems[userId].db.publishedStepMask)))}
                        </div>
                    </label>
                </div>
            )
        }
    }

    multiSelectMouseDown(evt, problemStepId, problemId, selected) {
        let problemStepSelected;
        if (evt.ctrlKey) {
            if (selected) {
                problemStepSelected = (this.state.problemStepSelected && this.state.problemStepSelected.length > 1)
                    ? without(this.state.problemStepSelected, problemStepId) : undefined;
            } else {
                problemStepSelected = [...(this.state.problemStepSelected || []), problemStepId];
            }
        } else if (evt.shiftKey && this.state.problemStepSelected) {
            evt.preventDefault();
            // Select all problemSteps between last and current
            const lastProblemStepId = this.state.problemStepSelected[this.state.problemStepSelected.length - 1];
            let enabled = false;
            problemStepSelected = [...this.state.problemStepSelected];
            this.getFilteredProblemStepIdsForProblem(problemId, {}).forEach((otherProblemStepId) => {
                const match = (otherProblemStepId === lastProblemStepId || otherProblemStepId === problemStepId);
                if ((match || enabled) && problemStepSelected.indexOf(otherProblemStepId) < 0) {
                    problemStepSelected.push(otherProblemStepId);
                }
                if (match) {
                    enabled = !enabled;
                }
            });
        } else {
            problemStepSelected = [problemStepId];
        }
        this.setState({problemSelected: undefined, popUpMenu: undefined, problemStepSelected});
    }

    renderNormalProblemStep(problemStepId, problemId, index) {
        const selected = (this.state.problemStepSelected && this.state.problemStepSelected.indexOf(problemStepId) >= 0);
        const isTraining = this.props.problemDBs[problemId].isTraining;
        const problemStep = this.props.problemSteps[problemStepId].problemStep;
        return (
            <div key={problemStepId} className={classNames('adminTableRow', 'subRow', {selected})}>
                <label onMouseDown={(evt) => {
                    if (!isTraining) {
                        this.multiSelectMouseDown(evt, problemStepId, problemId, selected);
                    }
                }}>
                    <div className='adminTableTitle'>
                        {this.props.problemDBs[problemId].title} Team {index + 1}
                        <div>(problemStepId {problemStepId})</div>
                    </div>
                    {this.renderTeamMembers(problemStepId)}
                    <div className={classNames('problemStatus', {
                        notLaunched: !problemStep.launched,
                        noStepId: problemStep.launched && problemStep.stepId === null,
                        closed: problemStep.state === constants.STATE_CLOSED,
                        archived: problemStep.state === constants.STATE_ARCHIVED
                    })}>
                        {this.getCurrentStepName(problemStepId)}
                    </div>
                </label>
            </div>
        );
    }

    renderViewActivity() {
        let recentActivity;
        return (
            <div className='adminConsole'>
                <div className='adminConsoleContent'>
                    <RecentActivity problemStepId={+this.state.problemStepSelected![0]}
                                    onRef={(child) => {recentActivity = child}}
                    />
                </div>
                <div className='adminButtonsDiv'>
                    <input type='button' value='Refresh' className='buttonStyle regularButtonStyle'
                           onClick={() => (recentActivity && recentActivity.triggerFetchActivities(true))}
                    />
                    <input type='button' value='Done' className='buttonStyle regularButtonStyle'
                           onClick={() => {this.setState({viewActivity: undefined})}}
                    />
                </div>
            </div>
        );
    }

    render() {
        if (this.state.userIdToEdit) {
            return this.renderEditUser();
        } if (this.state.editProblem) {
            return this.renderEditProblem();
        } else if (this.state.usersToAssign) {
            return this.renderAssignUsers();
        } else if (this.state.resetProblemStep) {
            return this.renderResetProblemSteps();
        } else if (this.state.deleteProblemStep) {
            return this.renderDeleteProblemStep();
        } else if (this.state.viewActivity) {
            return this.renderViewActivity();
        } else {
            return this.renderSelectProblem();
        }
    }

    private canGetAgenaRiskDataFromProblemStep(problemStep: CombinedTeamReducerType, userId: any) {
        const userDB = problemStep.user[userId].db;
        return (userDB.publishedStepMask & (1 << constants.STEP_VARIABLES)) !== 0;
    }
}

function mapStoreToProps(store) {
    return {
        user: getUserFromStore(store),
        problemDBs: getAllProblemDBsFromStore(store),
        problemSteps: getAllTeamsFromStore(store),
        allUsers: getAllUsersFromStore(store),
        bnWrapperState: getBnWrapperStateFromStore(store)
    };
}

export default connect<AdminProblemsPanelStoreProps, DispatchProp>(mapStoreToProps)(AdminProblemsPanel);
