import React, {Component} from 'react';
import PropTypes from 'prop-types'
import {Snackbar} from 'react-md';
import classNames from 'classnames';

import '../scss/bardSnackbar.scss';

/**
 * If you just want to pop up a toast message, you might want to look at BardSnackbarContainer rather than using the
 * BardSnackbar component directly.
 */
class BardSnackbar extends Component {

    static SIZE_SMALL = 'small';
    static SIZE_LARGE = 'large';

    static propTypes = {
        id: PropTypes.string.isRequired,
        toasts: PropTypes.any.isRequired,
        autohide: PropTypes.bool,
        positionLeft: PropTypes.bool,
        positionLeftSecond: PropTypes.bool,
        positionCenter: PropTypes.bool,
        onDismiss: PropTypes.func.isRequired,
        lightTheme: PropTypes.bool,
        size: PropTypes.oneOf([BardSnackbar.SIZE_SMALL, BardSnackbar.SIZE_LARGE])
    };

    static defaultProps = {
        autohide: true,
        positionLeft: false,
        positionLeftSecond: false,
        positionCenter: false,
        lightTheme: false,
        size: BardSnackbar.SIZE_LARGE
    };

    render() {
        return (<Snackbar
            id={this.props.id}
            toasts={this.props.toasts}
            autohide={this.props.autohide}
            className={classNames('bard-snackbar', {
                left: this.props.positionLeft,
                'left-second': this.props.positionLeftSecond,
                'center': this.props.positionCenter,
                'lightTheme': this.props.lightTheme,
                'sizeSmall': this.props.size === BardSnackbar.SIZE_SMALL
            })}
            onDismiss={this.props.onDismiss}
        />);
    }
}

export default BardSnackbar;
