import React, {Component} from 'react';
import {createPortal} from 'react-dom';
import {connect} from 'react-redux';
import {isFinite} from 'lodash';
import {Button} from 'react-md';
import classNames from 'classnames';

import DispatchProp from '../../@types/dispatchProp';
import * as constants from '../../common/util/constants';
import {getFormattedTimeStamp} from '../util/timeUtils';
import SyncVariableContainer from '../container/SyncVariableContainer';
import BardSnackbar from './BardSnackbar';
import ConfirmationDialog from './ConfirmationDialog';
import DataStatusEnum from '../../common/DataStatusEnum';
import {getLoggedInUserIdFromStore} from '../reducers/loginReducer';
import {revertStatusChangeAction, statusChangeAction} from '../../common/reducers/allStatusesForUserAndTeamReducer';
import {
    initialSingleUserForProblemReducerType, SingleUserForProblemReducerType
} from '../../common/reducers/allUsersForTeamReducer';
import ReferenceData, {StepDescriptionMap} from '../../common/util/referenceData';
import {getSyncVariablesForPublish} from '../../common/util/buildBayesianModelDataUtils';
import {getCurrentRound} from '../../common/util/roundLogic';
import {FeatureFlagsReducerType, getFeatureFlagsFromStore} from '../../common/reducers/featureFlagsReducer';
import TipsComponent from './TipsComponent';
import {ProblemStepPojo} from '../../server/orm/dao/problemStepDAO';
import {StoreWithSharedState} from '../../common/reducers/sharedStateReducer';
import {getProblemStepFromStore} from '../../common/reducers/allTeamsReducerGetters';
import {getUserForProblemFromStore} from '../../common/reducers/allUsersForTeamReducerGetters';

import '../scss/myDraftComponent.scss';

const UN_PUBLISHED_TOAST_MSG = [{
    text: (<span><i className="material-icons">warning</i> There are unpublished changes</span>)
}];
const REVERT_TO_LAST_PUBLISHED_TXT = "REVERT TO LAST PUBLISHED";
const REVERT_TO_LAST_SAVED_TXT = "REVERT TO LAST SAVED";

const defaultProps = {
    readOnly: false,
    status: DataStatusEnum.MY_DRAFT
};

type MyDraftComponentDefaultProps = Readonly<typeof defaultProps>;

type MyDraftComponentOwnProps = {
    stepId: number;
    problemStepId: number;
    tabTense: number;
    buttonBarDiv: HTMLDivElement | null;
};

interface MyDraftComponentStoreProps {
    user: SingleUserForProblemReducerType;
    userId: number;
    userRole?: number;
    stepDescriptions: StepDescriptionMap;
    problemStep: ProblemStepPojo;
    publishSyncVariables: string[];
    featureFlags: FeatureFlagsReducerType;
}

type MyDraftComponentProps = MyDraftComponentOwnProps & MyDraftComponentDefaultProps & MyDraftComponentStoreProps & DispatchProp;

interface MyDraftComponentState {
    flushChanges: {[key: string]: () => void};
    lastPublishedToastMsg: {text: string}[];
    latestPublishedTimestamp?: number;
    isReverting: boolean;
}

class MyDraftComponent extends Component<MyDraftComponentProps, MyDraftComponentState> {

    static defaultProps = {
        readOnly: false,
        status: DataStatusEnum.MY_DRAFT
    };

    static getDerivedStateFromProps(props: MyDraftComponentProps, state: MyDraftComponentState) {
        const latestPublishedTimestamp = MyDraftComponent.getLatestTimestampForStatus(props, props.status + 1);
        if (latestPublishedTimestamp !== state.latestPublishedTimestamp) {
            const isSoloAnalyst = props.userRole === constants.ROLE_SOLO_ANALYST;
            return {
                latestPublishedTimestamp,
                lastPublishedToastMsg: [{text: `Last ${(isSoloAnalyst) ? 'Saved' : 'Published'} ${getFormattedTimeStamp(latestPublishedTimestamp)}`}]
            };
        } else {
            return null;
        }
    }

    constructor(props) {
        super(props);
        this.state = {
            flushChanges: {},
            isReverting: false,
            lastPublishedToastMsg: []
        };
        this.onSubmit = this.onSubmit.bind(this);
        this.addFlushChanges = this.addFlushChanges.bind(this);
        this.onRevert = this.onRevert.bind(this);
        this.revert = this.revert.bind(this);
        this.onRevertCancelled = this.onRevertCancelled.bind(this);
        this.canRevert = this.canRevert.bind(this);
        this.canSubmit = this.canSubmit.bind(this);
    }

    addFlushChanges(key, handler) {
        this.setState((oldState) => ({flushChanges: {...oldState.flushChanges, [key]: handler}}));
    }

    onSubmit() {
        // Give all components we're displaying a chance to flush their data before we submit (so we copy the latest version to Published/Group Draft).
        Object.keys(this.state.flushChanges).forEach((key) => {
            this.state.flushChanges[key]();
        });
        // Use setState callback to ensure we wait until all state changes have completed before publishing.
        this.setState({}, () => {
            this.props.dispatch(statusChangeAction(this.props.problemStepId, this.props.userId, this.props.status, this.props.stepId, this.props.publishSyncVariables));
        });
    }

    onRevert() {
        // Give all components we're displaying a chance to flush their data before we revert (so they don't apply pending changes after the revert).
        Object.keys(this.state.flushChanges).forEach((key) => {
            this.state.flushChanges[key]();
        });
        this.setState({isReverting: true});
    }

    revert() {
        this.setState({isReverting: false}, () => {
            this.props.dispatch(revertStatusChangeAction(this.props.problemStepId, this.props.userId, this.props.status, this.props.publishSyncVariables));
        });
    }

    onRevertCancelled() {
        this.setState({isReverting: false});
    }

    canSubmit(latestDraftTimestamp?: number, latestPublishedTimestamp?: number) {
        return (latestPublishedTimestamp === undefined
            || (latestDraftTimestamp !== undefined && latestDraftTimestamp > latestPublishedTimestamp));
    }

    canRevert(latestDraftTimestamp?: number, latestPublishedTimestamp?: number) {
        return (latestPublishedTimestamp !== undefined && latestDraftTimestamp !== undefined
            && latestDraftTimestamp > latestPublishedTimestamp);
    }

    getToolTip(element) {
        const isDelphiExchangeRound = (getCurrentRound(this.props.stepId, this.props.problemStep) === constants.ROUND_EXCHANGE);
        const isFacilitator = this.props.userRole === constants.ROLE_FACILITATOR;
        return (
            <span className="toolTip">
                {
                    element === 'publishButton' ? (
                        this.props.userRole === constants.ROLE_SOLO_ANALYST ? "Save your work" : (
                            this.props.stepId === constants.STEP_EXPLORE_PROBLEM || this.props.stepId === constants.STEP_BR_CANDIDATE_SOLUTION || this.props.stepId === constants.STEP_NBR_CANDIDATE_SOLUTION) && (this.props.problemStep.stepId !== null) ?
                            (isFacilitator ? (
                                    this.props.tabTense === constants.STEP_TENSE.PAST || isDelphiExchangeRound ?
                                        'Share Group Draft with Analysts (Group tab)'
                                        :
                                        "Share Group Draft with Analysts when Exchange Round commences(Group tab)"
                                ) : (
                                    !isDelphiExchangeRound ?
                                        "Share My Draft with Facilitator. Other Analysts will see when Exchange Round commences."
                                        :
                                        "Share My Draft with Facilitator and other Analysts (Published tab)"
                                )
                            ) : (
                                isFacilitator ?
                                    "Share Group Draft with Analysts (Group tab)"
                                    :
                                    "Share My Draft with Facilitator and other Analysts (Published tab)"
                            )
                    ) : (
                        element === 'revertButton' ? 'Discard unpublished changes' : ''
                    )
                }
            </span>
        );
    }

    renderWorkspaceButtons() {
        if (this.props.readOnly || !this.props.buttonBarDiv) {
            return null;
        } else {
            const latestDraftTimestamp = MyDraftComponent.getLatestTimestampForStatus(this.props, this.props.status);
            const latestPublishedTimestamp = MyDraftComponent.getLatestTimestampForStatus(this.props, this.props.status + 1);
            let canRevert = this.canRevert(latestDraftTimestamp, latestPublishedTimestamp);
            let canSubmit = this.canSubmit(latestDraftTimestamp, latestPublishedTimestamp);
            return createPortal((
                <>
                    <span className="toolTipContainer buttonToolTip">
                        <Button flat={true} className={classNames('mdActionButton', 'btnRevert', 'revert', {disabled: !canRevert})}
                                disabled={!canRevert} onClick={this.onRevert}>
                                {this.props.userRole === constants.ROLE_SOLO_ANALYST ? REVERT_TO_LAST_SAVED_TXT : REVERT_TO_LAST_PUBLISHED_TXT}
                        </Button>
                        {this.getToolTip('revertButton')}
                    </span>
                    <span className="toolTipContainer buttonToolTip">
                        <Button flat={true} className={classNames('mdActionButton', 'btnPublish', 'submit', {disabled: !canSubmit})}
                                disabled={!canSubmit}
                                onClick={this.onSubmit}>
                                {this.props.userRole === constants.ROLE_SOLO_ANALYST ? 'SAVE ': (this.props.status === DataStatusEnum.GROUP_DRAFT ? 'PUBLISH GROUP DRAFT' : 'PUBLISH MY DRAFT') }
                        </Button>
                        {this.getToolTip('publishButton')}
                    </span>
                    <ConfirmationDialog
                        visible={this.state.isReverting}
                        dialogTitle={this.props.userRole === constants.ROLE_SOLO_ANALYST ? REVERT_TO_LAST_SAVED_TXT: REVERT_TO_LAST_PUBLISHED_TXT}
                        onHideAction={this.revert} onCancelAction={this.onRevertCancelled}
                        dialogContent={(
                            <div>Discard {this.props.userRole === constants.ROLE_SOLO_ANALYST ? 'unsaved': 'unpublished'} changes?</div>
                        )}
                        width={350}
                    />
                </>
            ), this.props.buttonBarDiv);
        }
    }

    static getLatestTimestampForStatus(props: MyDraftComponentProps, status: DataStatusEnum): number | undefined {
        // Because some steps publish syncVariables from other steps, handle PUBLISHED as special, checking the publishedStepMask.
        if (status === DataStatusEnum.PUBLISHED && ((1 << props.stepId) & props.user.db.publishedStepMask) === 0) {
            return undefined;
        } else {
            return props.publishSyncVariables
                .map((syncVariable) => (props.user.status[status][syncVariable].timestamp))
                .map((timestampStr) => (Date.parse(timestampStr)))
                .filter((timestampVal) => (isFinite(timestampVal)))
                .reduce<number | undefined>((max, timestampVal) => (max === undefined ? timestampVal : Math.max(max, timestampVal)), undefined);
        }
    }

    renderToasts() {
        if (this.props.readOnly || this.props.status === DataStatusEnum.PUBLISHED || this.props.status === DataStatusEnum.GROUP) {
            return null;
        } else {
            const latestDraftTimestamp = MyDraftComponent.getLatestTimestampForStatus(this.props, this.props.status);
            const latestPublishedTimestamp = MyDraftComponent.getLatestTimestampForStatus(this.props, this.props.status + 1);
            const isSoloAnalyst = this.props.userRole === constants.ROLE_SOLO_ANALYST;
            return (
                <div>
                    <BardSnackbar
                        id="unpublished-timestamp-snackbar"
                        toasts={
                            (!latestDraftTimestamp || !latestPublishedTimestamp || latestDraftTimestamp <= latestPublishedTimestamp || isSoloAnalyst) ?
                                [] : UN_PUBLISHED_TOAST_MSG
                        }
                        autohide={false}
                        positionLeft={true}
                        onDismiss={() => {}}
                        lightTheme={true}
                        size={BardSnackbar.SIZE_SMALL}
                    />
                    <BardSnackbar
                        id="published-timestamp-snackbar"
                        toasts={this.state.lastPublishedToastMsg}
                        autohide={false}
                        positionLeftSecond={true}
                        onDismiss={() => {}}
                        lightTheme={true}
                        size={BardSnackbar.SIZE_SMALL}
                    />
                </div>
            );
        }
    }


    renderLeftColumn() {
        return (this.props.stepId === constants.STEP_PARAMETERS || this.props.stepId === constants.STEP_EXPLORE_MODEL || this.props.stepId === constants.STEP_STRUCTURE || this.props.stepId === constants.STEP_SINGLE_STRUCTURE) ? null : (
            <div className="workspaceLeftColumn">
                {<TipsComponent status={this.props.status} step={this.props.stepId} side={constants.TIP_SCREEN_LEFT} problemStep={this.props.problemStep} userProblem={this.props.user.db}/>}
            </div>
        );
    }

    renderRightColumn() {
        return (this.props.stepId === constants.STEP_PARAMETERS || this.props.stepId === constants.STEP_EXPLORE_MODEL) ? null : (
            <div className="workspaceRightColumn">
                {<TipsComponent status={this.props.status} step={this.props.stepId} side={constants.TIP_SCREEN_RIGHT} problemStep={this.props.problemStep} userProblem={this.props.user.db}/>}
            </div>
        );
    }

    render() {
        return (
            <div className='myWorkspaceStyle'>
                <div className={classNames('threeColumnLayout', {
                    'wideContent': this.props.stepId === constants.STEP_STRUCTURE
                })}>
                    {this.renderLeftColumn()}
                    <div className='workspaceContentColumn workspaceContentDiv'>
                        <SyncVariableContainer
                            problemStepId={this.props.problemStepId}
                            stepId={this.props.stepId}
                            syncVariables={this.props.stepDescriptions[this.props.stepId].syncVariables || []}
                            addFlushChanges={this.addFlushChanges}
                            readOnly={this.props.readOnly}
                            status={this.props.status}
                            tabTense={this.props.tabTense}
                            buttonBarDiv={this.props.buttonBarDiv}
                        />
                    </div>
                    {this.renderRightColumn()}
                </div>
                {this.renderWorkspaceButtons()}
                {this.renderToasts()}
            </div>
        );
    }
}

function mapStoreToProps(store: StoreWithSharedState, myProps: MyDraftComponentOwnProps & Partial<MyDraftComponentDefaultProps>): MyDraftComponentStoreProps {
    const problemStepId = myProps.problemStepId;
    const userId = getLoggedInUserIdFromStore(store);
    const user = userId && problemStepId ? getUserForProblemFromStore(store, problemStepId, userId) : initialSingleUserForProblemReducerType;
    const userRole = (userId && user) ? user.db.role : undefined;
    return {
        user,
        userId,
        userRole,
        stepDescriptions: ReferenceData.getInstance().getAllStepDescriptions(),
        problemStep: getProblemStepFromStore(store, problemStepId),
        publishSyncVariables: getSyncVariablesForPublish(store, problemStepId, userId, myProps.stepId) || [],
        featureFlags: getFeatureFlagsFromStore(store)
    };
}

export default connect(mapStoreToProps)(MyDraftComponent);