import {AnyAction, combineReducers, Reducer} from 'redux';
import {v4} from 'uuid';
import {indexOf} from 'lodash';

import {BayesNetParametersState, initialBayesNetParametersState} from './bayesNetParametersReducer';
import DataStatusEnum from '../DataStatusEnum';
import ensureFieldPath from '../ensureFieldPath';
import {objectMapReducer, orderedListReducer, timestampedReducer, ObjectMapState, TimestampedState} from '../util/genericReducers';
import * as constants from '../util/constants';
import { StoreWithSharedState } from './sharedStateReducer';

// ======== Constants

const SET_EXPLORE_MODEL_DATA = 'set_explore_model_bn_data';
const SET_EXPLORE_MODEL_BELIEFS = 'set_explore_model_beliefs';
const ADD_SCENARIO = 'add_scenario';
const UPDATE_SCENARIO = 'update_scenario';
const DELETE_SCENARIO = 'delete_scenario';
const SELECT_SCENARIO = 'select_scenario';
const ADD_SCENARIO_EVIDENCE_NODE = 'add_scenario_evidence_node';
const UPDATE_SCENARIO_EVIDENCE_NODE = 'update_scenario_evidence_node';
const CHANGE_SCENARIO_EVIDENCE_NODE = 'change_scenario_evidence_node';
const REORDER_SCENARIO_EVIDENCE_NODE = 'reorder_scenario_evidence_node';
const DELETE_SCENARIO_EVIDENCE_NODE = 'delete_scenario_evidence_node';
const ADD_MONITOR_VARIABLE = 'add_monitor_variable';
const CHANGE_MONITOR_VARIABLE = 'change_monitor_variable';
const REORDER_MONITOR_VARIABLE = 'reorder_monitor_variable';
const DELETE_MONITOR_VARIABLE = 'delete_monitor_variable';
const SET_MONITOR_VARIABLE_FOCUS_STATE = 'set_monitor_variable_focus_state';
const UPDATE_SCENARIO_EXPLANATION = 'update_scenario_explanation';
const UPDATE_SCENARIO_DETAILED_EXPLANATION = 'update_scenario_detailed_explanation';
const UPDATE_SCENARIO_EXPLANATION_ERROR = 'update_scenario_explanation_error';
const REFRESH_SCENARIO_DATA = 'refresh_scenario_data';

export const BASE_SCENARIO = 'base';

export interface BNClientWrapperScenarioData {
    scenarioId: string;
    evidence: ScenarioEvidenceState;
    monitorVariables: string[];
    monitorVariableFocusStates: string[];
}

export interface BNClientWrapperData {
    bayesNet: BayesNetParametersState;
    scenario: BNClientWrapperScenarioData;
}

export interface SingleEvidenceNodeState {
    selectedIndex: number | null;
    uncertainty: boolean;
    probability?: number;
}

export interface ScenarioEvidenceState {
    variable: ObjectMapState<SingleEvidenceNodeState>;
    order: string[];
}

// ======== Reducers

const singleEvidenceNodeReducer: Reducer<SingleEvidenceNodeState> = (state = {selectedIndex: null, uncertainty: false}, action) => {
    switch (action.type) {
        case UPDATE_SCENARIO_EVIDENCE_NODE:
            return {...state, ...action.data};
        default:
            return state;
    }
};

const scenarioEvidenceNodesReducer = objectMapReducer('variableId', singleEvidenceNodeReducer, {deleteActionType: DELETE_SCENARIO_EVIDENCE_NODE});

const changeableScenarioEvidenceNodesReducer = (state = {}, action) => {
    switch (action.type) {
        case CHANGE_SCENARIO_EVIDENCE_NODE:
            let result = {...state};
            delete(result[action.oldVariableId]);
            return scenarioEvidenceNodesReducer(result, action);
        default:
            return scenarioEvidenceNodesReducer(state, action);
    }
};

const scenarioEvidenceOrderReducer = orderedListReducer('variableId', ADD_SCENARIO_EVIDENCE_NODE, DELETE_SCENARIO_EVIDENCE_NODE, REORDER_SCENARIO_EVIDENCE_NODE);

const changeableScenarioEvidenceOrderReducer: Reducer<string[]> = (state = [], action) => {
    switch (action.type) {
        case CHANGE_SCENARIO_EVIDENCE_NODE:
            let result = [...state];
            let index = indexOf(state, action.oldVariableId);
            result[index] = action.variableId;
            return result;
        default:
            return scenarioEvidenceOrderReducer(state, action);
    }
};

const scenarioEvidenceReducer = combineReducers<ScenarioEvidenceState>({
    variable: changeableScenarioEvidenceNodesReducer,
    order: changeableScenarioEvidenceOrderReducer,
});

export interface SingleScenarioDataState {
    scenarioId: string;
    title?: string;
    prefix?: string;
    description?: string;
    evidence: ScenarioEvidenceState;
    ownerUserId?: number;
}

const singleScenarioDataReducer: Reducer<SingleScenarioDataState> = (state = {scenarioId: '', title: '', prefix: '', description: '', evidence: {order: [], variable: {}}, ownerUserId: undefined}, action) => {
    switch (action.type) {
        case ADD_SCENARIO:
            return {
                title: 'New scenario',
                prefix: action.prefix,
                description: '',
                evidence: scenarioEvidenceReducer(undefined, action),
                ownerUserId: action.ownerUserId
            };
        case UPDATE_SCENARIO:
            return {...state, ...action.data};
        case ADD_SCENARIO_EVIDENCE_NODE:
        case UPDATE_SCENARIO_EVIDENCE_NODE:
        case CHANGE_SCENARIO_EVIDENCE_NODE:
        case REORDER_SCENARIO_EVIDENCE_NODE:
        case DELETE_SCENARIO_EVIDENCE_NODE:
            return {...state, evidence: scenarioEvidenceReducer(state.evidence, action)};
        default:
            return state;
    }
};

export type SingleScenarioBeliefsType = {error: string} | ObjectMapState<number[]>;

const singleScenarioBeliefsReducer: Reducer<SingleScenarioBeliefsType | undefined> = (state = {}, action) => {
    switch (action.type) {
        case SET_EXPLORE_MODEL_BELIEFS:
            return action.beliefs;
        default:
            return state;
    }
};

export interface SingleScenarioExplanationState {
    short: string;
    long: string;
    error: string;
}

const singleScenarioExplanationReducer: Reducer<SingleScenarioExplanationState | undefined> = (state = {short: '', long: '', error: ''}, action) => {
    switch (action.type) {
        case UPDATE_SCENARIO_EXPLANATION:
            return {...state, short: action.explanation, error: ''};
        case UPDATE_SCENARIO_DETAILED_EXPLANATION:
             return {...state, long: action.detailedExplanation, error: ''};
        case UPDATE_SCENARIO_EXPLANATION_ERROR:
            return { ...state, error: action.error };
        case REFRESH_SCENARIO_DATA:
            return {...state, short: (state.short || '') + ' '};
        default:
            return state;
    }
};

const monitorVariablesOrderReducer = orderedListReducer('variableId', ADD_MONITOR_VARIABLE, DELETE_MONITOR_VARIABLE, REORDER_MONITOR_VARIABLE);

const monitorVariablesReducer: Reducer<string[]> = (state = [], action) => {
    switch (action.type) {
        case CHANGE_MONITOR_VARIABLE:
            let result = [...state];
            result[action.index] = action.newVariableId;
            return result;
        default:
            return monitorVariablesOrderReducer(state, action);
    }
};

const monitorVariableFocusStatesOrderReducer = orderedListReducer('stateId', ADD_MONITOR_VARIABLE, DELETE_MONITOR_VARIABLE, REORDER_MONITOR_VARIABLE);

const monitorVariableFocusStateReducer: Reducer<string[]> = (state = [], action) => {
    switch (action.type) {
        case SET_MONITOR_VARIABLE_FOCUS_STATE:
        case CHANGE_MONITOR_VARIABLE:
            let result = [...state];
            result[action.index] = action.stateId;
            return result;
        default:
            return monitorVariableFocusStatesOrderReducer(state, action);
    }
};

export interface SingleScenarioState extends TimestampedState {
    data: SingleScenarioDataState;
    beliefs?: SingleScenarioBeliefsType;
    explanation?: SingleScenarioExplanationState;
    monitorVariables: string[];
    monitorVariableFocusStates: string[];
}

const singleScenarioReducer = combineReducers<SingleScenarioState>({
    data: singleScenarioDataReducer,
    beliefs: singleScenarioBeliefsReducer,
    explanation: singleScenarioExplanationReducer,
    monitorVariables: monitorVariablesReducer,
    monitorVariableFocusStates: monitorVariableFocusStateReducer,
    timestamp: (state: string = '') => (state)
});

const allScenariosReducer = objectMapReducer('scenarioId', timestampedReducer(singleScenarioReducer), {deleteActionType: DELETE_SCENARIO});

const allScenariosSettableReducer: Reducer<ObjectMapState<SingleScenarioState>> = (state = {}, action) => {
    switch (action.type) {
        case SET_EXPLORE_MODEL_DATA:
            return action.exploreModelData.scenario;
        case ADD_SCENARIO:
            return allScenariosReducer(state, action);
        default:
            // Don't implicitly create scenarios (except for the base scenario) for actions other than
            // ADD_SCENARIO (can e.g. be sent from the BN client wrapper after a scenario has been deleted)
            return (action.scenarioId === BASE_SCENARIO || state[action.scenarioId])
                ? allScenariosReducer(state, action) : state;
    }
};

const currentScenarioIdReducer: Reducer<string> = (state = BASE_SCENARIO, action) => {
    switch (action.type) {
        case SELECT_SCENARIO:
        case ADD_SCENARIO:
            return action.scenarioId;
        case DELETE_SCENARIO:
            if (action.scenarioId === state) {
                return BASE_SCENARIO;
            } else {
                return state;
            }
        default:
            return state;
    }
};

const bayesNetReducer: Reducer<BayesNetParametersState> = (state = initialBayesNetParametersState, action) => {
    switch (action.type) {
        case SET_EXPLORE_MODEL_DATA:
            return action.exploreModelData.bayesNet;
        default:
            return state;
    }
};

export interface ExploreModelState extends TimestampedState {
    bayesNet: BayesNetParametersState;
    scenario: ObjectMapState<SingleScenarioState>;
    currentScenarioId: string;
}

const exploreModelReducer = combineReducers<ExploreModelState>({
    bayesNet: bayesNetReducer,
    scenario: allScenariosSettableReducer,
    currentScenarioId: currentScenarioIdReducer,
    timestamp: (state: string = '') => (state)
});

const exploreModelTimestampedReducer = timestampedReducer(exploreModelReducer, (oldState, newState, action) => {
    // Return boolean to indicate if the timestamp should be updated.
    if (action.ownerUserId) {
        return false;
    } else if (action.scenarioId) {
        return !action.fromServer && action.type !== SELECT_SCENARIO &&
            ((oldState && oldState.scenario[action.scenarioId] && !oldState.scenario[action.scenarioId].data.ownerUserId)
            || (newState.scenario[action.scenarioId] && !newState.scenario[action.scenarioId].data.ownerUserId));
    } else {
        return action.type !== SET_EXPLORE_MODEL_DATA;
    }
});

const exploreModelCopyableReducer: Reducer<ExploreModelState> = (state, action) => {
    switch (action.type) {
        case constants.COPY_PUBLISHED_ACTION:
        case constants.APPEND_PUBLISHED_ACTION:
            return action.fromUser.exploreModel ? {...action.fromUser.exploreModel} : state;
        default:
            return exploreModelTimestampedReducer(state, action);
    }
};

export default exploreModelCopyableReducer;

// ======== BNClientWrapperService functions

export function isBNClientWrapperAction(action: AnyAction): boolean {
    switch (action.type) {
        case SELECT_SCENARIO:
        case ADD_SCENARIO:
        case ADD_SCENARIO_EVIDENCE_NODE:
        case UPDATE_SCENARIO_EVIDENCE_NODE:
        case CHANGE_SCENARIO_EVIDENCE_NODE:
        case DELETE_SCENARIO_EVIDENCE_NODE:
        case REORDER_SCENARIO_EVIDENCE_NODE:
        case ADD_MONITOR_VARIABLE:
        case CHANGE_MONITOR_VARIABLE:
        case DELETE_MONITOR_VARIABLE:
        case REORDER_MONITOR_VARIABLE:
        case SET_MONITOR_VARIABLE_FOCUS_STATE:
        case REFRESH_SCENARIO_DATA:
            return true;
        default:
            return false;
    }
}

// ======== DBSync functions
// (used by DBSync and for unit testing)

export function getDBSyncActionTypes() {
    return [
        SET_EXPLORE_MODEL_DATA,
        SET_EXPLORE_MODEL_BELIEFS,
        ADD_SCENARIO,
        UPDATE_SCENARIO,
        DELETE_SCENARIO,
        SELECT_SCENARIO,
        ADD_SCENARIO_EVIDENCE_NODE,
        UPDATE_SCENARIO_EVIDENCE_NODE,
        CHANGE_SCENARIO_EVIDENCE_NODE,
        REORDER_SCENARIO_EVIDENCE_NODE,
        DELETE_SCENARIO_EVIDENCE_NODE,
        ADD_MONITOR_VARIABLE,
        CHANGE_MONITOR_VARIABLE,
        REORDER_MONITOR_VARIABLE,
        DELETE_MONITOR_VARIABLE,
        SET_MONITOR_VARIABLE_FOCUS_STATE,
        UPDATE_SCENARIO_EXPLANATION,
        UPDATE_SCENARIO_DETAILED_EXPLANATION
];
}

export function setExploreModelDataInStore(store: StoreWithSharedState, problemStepId, userId, status, object) {
    ensureFieldPath(store, 'sharedState', 'teams', problemStepId, 'user', userId, 'status', status);
    store.sharedState.teams[problemStepId].user[userId].status[status].exploreModel = object;
}

// ======== Action generator functions

export function setExploreModelDataAction(problemStepId: number, userId: number, status: DataStatusEnum, exploreModelData: ExploreModelState) {
    return {type: SET_EXPLORE_MODEL_DATA, problemStepId, userId, status, exploreModelData};
}

export function setExploreModelBeliefsAction(problemStepId: number, userId: number, status: DataStatusEnum, scenarioId: string, beliefs: SingleScenarioBeliefsType) {
    return {type: SET_EXPLORE_MODEL_BELIEFS, problemStepId, userId, status, scenarioId, beliefs};
}

export function addScenarioAction(problemStepId: number, userId: number, status: DataStatusEnum, prefix: string, ownerUserId?: number) {
    return {type: ADD_SCENARIO, problemStepId, userId, status, scenarioId: v4(), prefix, ownerUserId};
}

export function updateScenarioAction(problemStepId: number, userId: number, status: DataStatusEnum, scenarioId: string, data: Partial<SingleScenarioDataState>) {
    return {type: UPDATE_SCENARIO, problemStepId, userId, status, scenarioId, data};
}

export function deleteScenarioAction(problemStepId: number, userId: number, status: DataStatusEnum, scenarioId: string) {
    return {type: DELETE_SCENARIO, problemStepId, userId, status, scenarioId};
}

export function selectScenarioAction(problemStepId: number, userId: number, status: DataStatusEnum, scenarioId: string) {
    return {type: SELECT_SCENARIO, problemStepId, userId, status, scenarioId};
}

export function addScenarioEvidenceNodeAction(problemStepId: number, userId: number, status: DataStatusEnum, scenarioId: string, variableId: string) {
    return {type: ADD_SCENARIO_EVIDENCE_NODE, problemStepId, userId, status, scenarioId, variableId};
}

export function changeScenarioEvidenceNodeAction(problemStepId: number, userId: number, status: DataStatusEnum, scenarioId: string, variableId: string, oldVariableId: string) {
    return {type: CHANGE_SCENARIO_EVIDENCE_NODE, problemStepId, userId, status, scenarioId, variableId, oldVariableId};
}

export function updateScenarioEvidenceNodeAction(problemStepId: number, userId: number, status: DataStatusEnum, scenarioId: string, variableId: string, data: Partial<SingleEvidenceNodeState>) {
    return {type: UPDATE_SCENARIO_EVIDENCE_NODE, problemStepId, userId, status, scenarioId, variableId, data};
}

export function reorderScenarioEvidenceNodeAction(problemStepId: number, userId: number, status: DataStatusEnum, scenarioId: string, oldIndex: number, newIndex: number) {
    return {type: REORDER_SCENARIO_EVIDENCE_NODE, problemStepId, userId, status, scenarioId, oldIndex, newIndex};
}

export function deleteScenarioEvidenceNodeAction(problemStepId: number, userId: number, status: DataStatusEnum, scenarioId: string, variableId: string, index: number) {
    return {type: DELETE_SCENARIO_EVIDENCE_NODE, problemStepId, userId, status, scenarioId, variableId, index};
}

export function addMonitorVariableAction(problemStepId: number, userId: number, status: DataStatusEnum, scenarioId: string, variableId: string, stateId: string) {
    return {type: ADD_MONITOR_VARIABLE, problemStepId, userId, status, scenarioId, variableId, stateId};
}

export function changeMonitorVariableAction(problemStepId: number, userId: number, status: DataStatusEnum, scenarioId: string, index: number, newVariableId: string, stateId: string) {
    return {type: CHANGE_MONITOR_VARIABLE, problemStepId, userId, status, scenarioId, index, newVariableId, stateId};
}

export function reorderMonitorVariableAction(problemStepId: number, userId: number, status: DataStatusEnum, scenarioId: string, oldIndex: number, newIndex: number) {
    return {type: REORDER_MONITOR_VARIABLE, problemStepId, userId, status, scenarioId, oldIndex, newIndex};
}

export function deleteMonitorVariableAction(problemStepId: number, userId: number, status: DataStatusEnum, scenarioId: string, index: number) {
    return {type: DELETE_MONITOR_VARIABLE, problemStepId, userId, status, scenarioId, index};
}

export function setMonitorVariableFocusStateAction(problemStepId: number, userId: number, status: DataStatusEnum, scenarioId: string, index: number, stateId: string) {
    return {type: SET_MONITOR_VARIABLE_FOCUS_STATE, problemStepId, userId, status, scenarioId, index, stateId};
}

export function updateScenarioExplanationAction(problemStepId: number, userId: number, status: DataStatusEnum, scenarioId: string, explanation: string) {
    return {type: UPDATE_SCENARIO_EXPLANATION, problemStepId, userId, status, scenarioId, explanation};
}

export function updateScenarioDetailedExplanationAction(problemStepId: number, userId: number, status: DataStatusEnum, scenarioId: string, detailedExplanation: string) {
    return {type: UPDATE_SCENARIO_DETAILED_EXPLANATION, problemStepId, userId, status, scenarioId, detailedExplanation};
}

export function updateScenarioDetailedExplanationErrorAction(problemStepId: number, userId: number, status: DataStatusEnum, scenarioId: string, error: string) {
    return {type: UPDATE_SCENARIO_EXPLANATION_ERROR, problemStepId, userId, status, scenarioId, error};
}

export function refreshScenarioDataAction(problemStepId: number, userId: number, status: DataStatusEnum, scenarioId: string) {
    return {type: REFRESH_SCENARIO_DATA, problemStepId, userId, status, scenarioId};
}

// ======== Getter functions

export function getExploreModelDataFromStore(store: StoreWithSharedState, problemStepId, userId, status: DataStatusEnum): ExploreModelState {
    return getExploreModelDataFromProblemStep(store.sharedState.teams[problemStepId], userId, status);   
}

export function getExploreModelDataFromProblemStep(problemStep, userId, status: DataStatusEnum): ExploreModelState {
    return problemStep.user[userId].status[status].exploreModel;
}

export function getBNClientWrapperDataFromExploreModel(exploreModelData: ExploreModelState, newScenarioId?: string): BNClientWrapperData | undefined {
    // Sanity check exploreModelData
    if (!exploreModelData) {
        return undefined;
    }
    const bayesNet = exploreModelData.bayesNet;
    const scenarioId = newScenarioId || exploreModelData.currentScenarioId;
    const scenario = exploreModelData.scenario[scenarioId];
    if (!bayesNet || !scenarioId || !scenario) {
        return undefined;
    }
    const evidence = (scenarioId === BASE_SCENARIO) ? {order: [], variable: {}} : scenario.data.evidence;
    const monitorVariables = scenario.monitorVariables;
    const monitorVariableFocusStates = scenario.monitorVariableFocusStates;
    return {bayesNet, scenario: {scenarioId, evidence, monitorVariables, monitorVariableFocusStates}};
}
