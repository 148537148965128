// Utility methods to assist with creating updated copies of immutable objects such as Redux store state.

/**
 * Filter out deeply nested values in the provided object, returning either the original object if nothing changed, or
 * a shallow-copy object containing the original fields for those that haven't changed, and deep copies of those that
 * have.
 *
 * @param object The object whose values need to be filtered.
 * @param {(string | string[])[]} fieldPath An array of strings naming the "path" to the deeply nested state to be
 * filtered.  Field can be a single string, an array of strings to match several fields at the same level, or a wildcard
 * character ('*') to match all keys at that level.  If the object does not have values all the way to the end of the
 * fieldPath, the keep() method is invoked on the first value on the path which is not an object.
 * @param {(input: any, actualPath: string[]) => boolean} keep The function which will be invoked on the data at the end
 * of the fieldPath to indicate whether it should be kept (return true) of discarded (return false).  The callback is
 * invoked with the value of the data, and an array of strings giving the "actual path" of fields traversed from the top
 * level to this piece of data.
 * @param {any[]} actualPath Internal parameter used to build up the actual path which will be passed to the keep
 * function.
 * @return {any} The modified object whose values have been filtered out according to the keep function, or the original
 * object if no child fields have been excluded.
 */
export function deepFilter(object: any, fieldPath: (string | string[])[], keep: (input: any, actualPath: string[]) => boolean, actualPath: string[] = []): any {
    if (fieldPath.length === 0 || typeof(object) !== 'object') {
        const keepValue = keep(object, actualPath);
        return (keepValue) ? object : undefined;
    } else {
        const fieldList: string[] = (fieldPath[0] instanceof Array) ? (fieldPath[0] as string[]) : (fieldPath[0] === '*') ? Object.keys(object) : (fieldPath.slice(0, 1) as string[]);
        const remainingFieldPath = fieldPath.slice(1);
        return fieldList.reduce((result: any, field: string) => {
            const filtered = deepFilter(object[field], remainingFieldPath, keep, [...actualPath, field]);
            if (filtered !== object[field]) {
                result = result || {...object};
                if (filtered === undefined) {
                    delete(result![field]);
                } else {
                    result[field] = filtered;
                }
            }
            return result;
        }, undefined) || object;
    }
}

