import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Button, DialogContainer, FontIcon, NavigationDrawer} from 'react-md';
import ReactMarkdown from 'react-markdown';
import memoizeOne from 'memoize-one';
import {isEmpty} from 'lodash';

import {handleErrors} from '../util/ajaxUtils';
import HelpContentData, {HelpTopic, HelpTopicLink} from '../help/helpContentData';
import * as constants from '../../common/util/constants';
import {isProblemInMode} from '../../common/util/problemMode';
import {UserForTeamType} from '../../common/reducers/allUsersForTeamReducer';
import {SingleProblemStepType} from '../../common/reducers/allTeamsReducer';
import HelpTipsData from '../help/helpTipsData';
import {getBardTrainingUrlFromStore} from '../../common/reducers/bardTrainingUrlReducer';

import '../scss/react-md.scss';
import '../scss/material-icons.scss';
import '../scss/helpContent.scss';

interface HelpContentStoreProps {
    bardTrainingUrl: string;
}

interface HelpContentOwnProps {
    defaultStepId?: constants.STEP | null;
    helpTipId?: string;
    problemStep?: string;
    step: SingleProblemStepType;
    user: UserForTeamType;
}

type HelpContentProps = HelpContentStoreProps & HelpContentOwnProps;

interface HelpContentState {
    helpVisible: boolean;
    changelogText: string;
    currentItem?: HelpItem;
}

interface NavItem {
    primaryText: string;
    leftIcon: React.ReactElement;
    onClick: () => void;
}

interface HelpItem {
    key: string;
    title: string;
    stepId?: constants.STEP[];
    markdown?: string;
    links?: HelpTopicLink[];
    showChangelogText?: boolean;
    navItem: NavItem;
}

class HelpContent extends Component<HelpContentProps, HelpContentState> {

    private readonly helpContainerRef;

    constructor(props) {
        super(props);
        this.show = this.show.bind(this);
        this.hide = this.hide.bind(this);
        this.buildHelpItems = memoizeOne(this.buildHelpItems);
        this.helpContainerRef = React.createRef();
        this.state = {
            changelogText: '',
            helpVisible: false
        };
    }

    buildHelpItems(user: UserForTeamType, step: SingleProblemStepType, genericHelp: {[key: string]: HelpTopic}): {[key: string]: HelpItem} {
        return Object.keys(genericHelp)
            .map((key) => ({
                key,
                stepId: genericHelp[key].stepId,
                title: genericHelp[key].title,
                helpObjs: genericHelp[key].helpObj.filter((helpObj) => (
                    (isEmpty(helpObj.bardRoles) || (helpObj.bardRoles!.indexOf(user.role) >= 0))
                    && (isEmpty(helpObj.bardModes) || isProblemInMode(helpObj.bardModes!, step))
                ))
            }))
            .reduce((result, item) => {
                if (item.helpObjs.length > 0) {
                    const title = item.helpObjs.reduce((title, helpObj) => (helpObj.title || title), item.title);
                    result[item.key] = {
                        key: item.key,
                        title,
                        stepId: item.stepId,
                        markdown: item.helpObjs
                            .filter((helpObj) => (helpObj.markdown))
                            .map((helpObj) => (helpObj.markdown!.trim()))
                            .join('\n\n'),
                        links: item.helpObjs
                            .filter((helpObj) => (helpObj.links))
                            .reduce<HelpTopicLink[]>((links, helpObj) => ([...links, ...helpObj.links!]), []),
                        showChangelogText: item.helpObjs.reduce<boolean>((show, helpObj) => (show || helpObj.showChangelogText !== undefined), false),
                        navItem: {
                            primaryText: title,
                            leftIcon: <span>{title}</span>,
                            onClick: () => {this.setState({currentItem: result[item.key]})}
                        }
                    };
                }
                return result;
            }, {});
    }

    show() {
        this.setState({helpVisible: true});
    }

    hide() {
        this.setState({helpVisible: false});
    }

    renderHelpText(helpItem: HelpItem) {
        if (helpItem.showChangelogText) {
            console.log('should print changelog: ', this.state.changelogText);
        }
        return (
            <div>
                {
                    !helpItem.markdown ? null : (
                        <ReactMarkdown
                            className='markdownContent'
                            linkTarget='_blank'
                            source={helpItem.markdown}
                        />
                    )
                }
                {
                    !helpItem.showChangelogText ? null : (
                        <ReactMarkdown source={this.state.changelogText}/>
                    )
                }
            </div>
        );
    }

    loadChangelog() {
        return fetch('/changelog.md', {
            method: 'get',
            headers: new Headers({
                'Content-Type': 'text/markdown; charset=UTF-8'
            })
        })
            .then(handleErrors)
            .then((response) => {
                return response.text().then((data) => {
                    this.setState({changelogText: data});
                });
            }).catch(() => {
                this.setState({changelogText: '**Failed to load BARD change logs!**'});
            });
    }

    renderHelpContentLinks(helpItem: HelpItem) {
        return !helpItem.links ? null : helpItem.links.map((link, index) => (
            <div className='helpContentLink' key={index}>
                {
                    link.linkText || link.bardTrainingUrl ? (
                        <a href={link.bardTrainingUrl ? this.props.bardTrainingUrl : link.linkText} target='_blank' rel='noopener noreferrer'>{link.linkName}</a>
                    ) : (
                        link.linkName
                    )
                }
                <br/>
            </div>
        ));
    }

    componentDidMount() {
        return this.loadChangelog();
    }

    renderTip(helpTipId: string) {
        const tipContent = HelpTipsData.getInstance().getGenericTipContent(helpTipId);
        if (!tipContent) {
            return (<h2> No help content available </h2>);
        }
        const helpItems = this.buildHelpItems(this.props.user, this.props.step, {[helpTipId]: tipContent});

        return (
            <div className="helpTipsDiv">
                <div onClick={this.show}>
                    <div className="helpTipsPopup">
                        <FontIcon className="md-link md-22 helpTipsIcon">help</FontIcon>
                    </div>
                </div>
                <DialogContainer
                    id="helpTipsContent"
                    aria-label=""
                    visible={this.state.helpVisible}
                    width='800px'
                    height='400px'
                    focusOnMount={false}
                    onShow={this.show}
                    onHide={this.hide}
                >
                    {
                        Object.keys(helpItems).map((key) => (
                            <div key={key}>
                                <h2>{helpItems[key].title}</h2>
                                <section className="helpTipsSection">
                                    {this.renderHelpText(helpItems[key])}
                                    {this.renderHelpContentLinks(helpItems[key])}
                                </section>
                            </div>
                        ))
                    }

                </DialogContainer>
            </div>
        );
    }

    findTopicForStepId(helpItems: {[key: string]: HelpItem}, stepId: constants.STEP) {
        return Object.keys(helpItems).find((key) => (
            helpItems[key].stepId ? helpItems[key].stepId!.indexOf(stepId) >= 0 : false
        ));
    }

    renderFullHelp() {
        const helpItems = this.buildHelpItems(this.props.user, this.props.step, HelpContentData.getInstance().getGenericHelpContent());
        const topics = Object.keys(helpItems);
        const defaultTopic = (this.props.defaultStepId ? this.findTopicForStepId(helpItems, this.props.defaultStepId) : undefined) || topics[0];
        const currentItem = this.state.currentItem && helpItems[this.state.currentItem.key] ? this.state.currentItem : helpItems[defaultTopic];
        const navItems = topics.map((key) => (
            key === currentItem.key ? {...helpItems[key].navItem, active: true} : helpItems[key].navItem
        ));

        return (
            <div>
                {this.props.problemStep ? (
                        <div onClick={this.show}>
                            <div className="foyerProblemDetailStepInfoDiv">
                                <i className="material-icons foyerProblemDetailStepInfoQuestionIcon">help</i>
                                <span className='mediumTextFont'>Learn more about</span>&nbsp;
                                <span className='largeTextFont'>{this.props.problemStep}</span>&nbsp;
                                <span className='mediumTextFont'>{this.props.problemStep === 'BARD' ? null : 'step'}</span>
                            </div>
                        </div>
                    ) : (
                        <div className="headerHelpContent" onClick={this.show}>
                            <FontIcon className="md-link md-22">help</FontIcon>
                            <div className="headerHelpContentLabel">HELP</div>
                        </div>
                    )
                }

                <DialogContainer
                    id="helpContent"
                    aria-label=""
                    visible={this.state.helpVisible}
                    width='900px'
                    height='800px'
                    focusOnMount={false}
                    ref={this.helpContainerRef}
                    onHide={this.hide}
                >
                    <NavigationDrawer
                        renderNode={this.helpContainerRef.current}
                        navItems={navItems}
                        mobileDrawerType={NavigationDrawer.DrawerTypes.TEMPORARY_MINI}
                        tabletDrawerType={NavigationDrawer.DrawerTypes.PERSISTENT_MINI}
                        desktopDrawerType={NavigationDrawer.DrawerTypes.PERSISTENT_MINI}
                        toolbarTitle="BARD"
                        toolbarActions={<Button icon={true} onClick={this.hide}>close</Button>}
                        contentId="main-demo-content"
                        contentClassName="md-grid"
                        temporaryIcon={<FontIcon onClick={(evt) => {
                            evt.preventDefault();
                        }}/>}
                    >
                        <h2 className="md-cell md-cell--12">{currentItem.title}</h2>
                        <section className="md-text-container md-cell md-cell--12">
                            {this.renderHelpText(currentItem)}
                            {this.renderHelpContentLinks(currentItem)}
                        </section>
                    </NavigationDrawer>
                </DialogContainer>
            </div>
        );
    }

    render() {
        return this.props.helpTipId ? this.renderTip(this.props.helpTipId) : this.renderFullHelp();
    }
}

function mapStoreToState(store) {
    return {
        bardTrainingUrl: getBardTrainingUrlFromStore(store)
    };
}

export default connect(mapStoreToState)(HelpContent);