import React, {Component} from 'react';
import {connect} from 'react-redux';
import {DialogContainer} from 'react-md';
import classNames from 'classnames';

import { updateEmailTemplatesListAction, updateEmailTemplateAction, getAllEmailTemplatesFromStore } from '../reducers/allEmailTemplatesReducer';
import SingleInput from './SingleInput';

class AdminEmailTemplatePanel extends Component {

    static STANDARD_PARAMETERS = {
        email_footer: 'Standard email footer',
        is_pilot: 'flag for if the system is in integration test mode',
        lms_training_url: 'LMS training URL',
        is_tne_environment: 'flag for if the server is on createbetterreasoning.com'
    };

    constructor() {
        super();
        this.state = {
            previewSubject: undefined,
            previewBody: undefined
        };
    }

    componentDidMount() {
        fetch('/api/emails', {
            method: 'get',
            credentials: 'include'
        }).then((response) => {
            if (!response.ok) {
                return;
            }

            response.json()
            .then((emails) => {
                this.props.dispatch(updateEmailTemplatesListAction(emails));
            });
        });
    }

    render() {
        const section = this.state.editTemplate ? this.renderEditTemplate() : this.renderList();

        return (<div className="email-templates">{section}</div>)
    }

    onEditChange(field) {
        return (e) => {
            this.setState({ editTemplate: { ...this.state.editTemplate, [field]: e.target.value } });
        };
    }

    onSaveTemplate(e) {
        e.preventDefault();
        this.setState({...this.state, isProcessing: true});

        let template = {...this.state.editTemplate, parameters: {...this.state.editTemplate.parameters}};
        Object.keys(AdminEmailTemplatePanel.STANDARD_PARAMETERS).forEach((standardParam) => {
            delete(template.parameters[standardParam]);
        });

        fetch('/api/emails', {
            method: 'put',
            credentials: 'include',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify(template)
        }).then((response) => {
            if (response.ok) {
                response.json().then(() => {
                    this.props.dispatch(updateEmailTemplateAction(template));
                    this.setState({...this.state, editTemplate: undefined, isProcessing: undefined});
                });
            } else {
                this.setState({
                    ...this.state,
                    error: 'Server error',
                    isProcessing: undefined
                });
            }
        });
    }

    onTestEmailChanged(e) {
        this.setState({...this.state, testEmail: e.target.value});
    }

    onSendTestEmail() {
        if (!this.state.testEmail) {
            return;
        }

        this.setState({...this.state, isEmailSentError: undefined, isSendingEmail: true});

        fetch('/api/emails/test', {
            method: 'put',
            credentials: 'include',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({email: this.state.testEmail, template: this.state.editTemplate})
        }).then((response) => {
            if (response.ok) {
                response.json().then(() => {
                    this.setState({...this.state, isSendingEmail: undefined, isEmailSent: true});
                });
            } else {
                this.setState({
                    ...this.state,
                    error: 'Server error',
                    isSendingEmail: undefined,
                    isEmailSent: undefined,
                    isEmailSentError: true
                });
            }
        });
    }

    onPreview() {
        fetch('/api/emails/preview', {
            method: 'put',
            credentials: 'include',
            headers: {'content-type': 'application/json'},
            body: JSON.stringify({template: this.state.editTemplate})
        }).then((response) => {
            if (response.ok) {
                response.json()
                    .then(({subject, body}) => {
                        this.setState({previewSubject: subject, previewBody: body});
                    });
            }
        });
    }

    renderParameters(params) {
        return (
            <ul>
                {
                    Object.keys(params).map((k) => (
                        <li key={k} className={classNames('emailParameter', {
                            standard: AdminEmailTemplatePanel.STANDARD_PARAMETERS[k]
                        })}>
                            <span>{`{{${k}}}`}:</span>
                            <SingleInput inputType='text' content={params[k]} onChange={(value) => {
                                this.setState({
                                    editTemplate: {
                                        ...this.state.editTemplate,
                                        parameters: {
                                            ...this.state.editTemplate.parameters,
                                            [k]: value
                                        }
                                    }
                                });
                            }}/>
                        </li>
                    ))
                }
            </ul>
        );
    }

    renderEditTemplate() {
        const template = this.state.editTemplate;
        return (
            <form onSubmit={e => this.onSaveTemplate(e)}>
                <div className='adminConsole'>
                    <div className='majorTitle'>Edit: {template.name}</div>
                    <div className='adminConsoleContent'>
                        <div className="form-group">
                            <label htmlFor="code">Code</label>
                            <div className="form-input"><input type="text" id="code" value={template.code} disabled readOnly /></div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="name">Name</label>
                            <div className="form-input"><input type="text" id="name" required value={template.name} onChange={this.onEditChange('name')} /></div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="subject">Email Subject</label>
                            <div className="form-input"><input type="text" id="subject" required value={template.subject} onChange={this.onEditChange('subject')} /></div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="body">Email Body</label>
                            <div className="form-input"><textarea className="email-body" id="body" required value={template.body} onChange={this.onEditChange('body')}/></div>
                        </div>
                        <div className="form-group">
                            <label htmlFor="parameters">Parameters</label>
                            <div className="form-input">
                                {this.renderParameters(template.parameters)}
                                <button type='button' onClick={() => this.onPreview()} className='buttonStyle regularButtonStyle'>
                                    Preview
                                </button>
                            </div>
                        </div>

                        <div className="test-email">
                            <div>{this.state.isEmailSent ? 'Email sent successfully': (this.state.isEmailSentError ? 'Error - Email has not been sent.' : '')}&nbsp;</div>
                            <div className="form-group">
                                <label htmlFor="email">Test Email</label>
                                <div className="form-input"><input type="text" onChange={e => this.onTestEmailChanged(e)} id="email" placeholder="test@example.com" /></div>
                                <button onClick={() => this.onSendTestEmail()} type="button" disabled={!this.state.testEmail} className='buttonStyle regularButtonStyle'>
                                    {this.state.isSendingEmail ? 'Sending test email..' : 'Send a test email'}
                                </button>
                            </div>
                        </div>
                    </div>

                    <div className='adminButtonsDiv'>
                        <button type="button" className='buttonStyle regularButtonStyle' onClick={() => this.setState({editTemplate: undefined})}>Cancel</button>
                        <button type="submit" className='buttonStyle regularButtonStyle'>{this.state.isProcessing ? 'Saving...' : 'Save'}</button>
                    </div>
                </div>
                <DialogContainer
                    id='emailPreviewPanel'
                    visible={!!this.state.previewBody}
                    width='840px'
                    height='750px'
                    title="Email Preview"
                    onHide={() => {this.setState({previewBody: undefined})}}
                    autosizeContent={false}
                    stackedActions={false}
                >
                    <b>Subject:</b> {this.state.previewSubject}
                    <hr/>
                    <div className='emailBody'>{this.state.previewBody}</div>
                </DialogContainer>
            </form>
         );
    }

    editTemplate(templateId) {
        const editTemplate = {
            ...this.props.emails[templateId],
            parameters: {
                ...AdminEmailTemplatePanel.STANDARD_PARAMETERS,
                ...this.props.emails[templateId].parameters
            }
        };
        this.setState({editTemplate});
    }

    renderList() {
        return (
            <div className='adminConsole'>
                <div className='majorTitle'>Email Templates</div>
                <div className='adminConsoleContent'>
                    <table className="admin-table list-email-templates">
                        <colgroup>
                            <col width="1"/>
                            <col width="200px"/>
                            <col/>
                            <col/>
                            <col width="1"/>
                        </colgroup>
                        <thead>
                            <tr>
                                <th>ID</th>
                                <th>Code</th>
                                <th>Name</th>
                                <th>Subject</th>
                                <th className="center">Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                        {
                            this.props.emails && Object.keys(this.props.emails).map((templateId) => {
                                return (
                                    <tr key={templateId}>
                                        <td>{this.props.emails[templateId].id}</td>
                                        <td>{this.props.emails[templateId].code}</td>
                                        <td>{this.props.emails[templateId].name}</td>
                                        <td>{this.props.emails[templateId].subject}</td>
                                        <td className="center"><button onClick={() => {this.editTemplate(templateId)}}>Edit</button></td>
                                    </tr>
                                );
                            })
                        }
                        </tbody>
                    </table>
                </div>
            </div>
        );
    }
}

function mapStoreToProps(store) {
    return {
        emails: getAllEmailTemplatesFromStore(store)
    };
}

export default connect(mapStoreToProps)(AdminEmailTemplatePanel);
