import {
    addFieldData,
    BardFilterObject,
    BardFilterSuggestions,
    fieldMatchesFilter,
    parseFilterString,
    suggestFilterFields
} from './commonFilter';
import {BardUserFilter, BARD_USER_FILTER_TYPE, userMatchesFilter} from './bardUserFilter';
import {UserForTeamType} from '../reducers/allUsersForTeamReducer';

type TeamUserFilterKeys = Exclude<keyof UserForTeamType, 'id' | 'userId' | 'User' | 'problemStepId' | 'timeSpent'
    | 'groupManagementState' | 'groupManagementStateDate' | 'publishedStepMask' | 'draftMessage'>;

export type TeamUserFilter = {
    user?: BardFilterObject<BardUserFilter>
} & {
    [field in TeamUserFilterKeys]?: string | boolean | number | Array<string>;
};

export type TeamUserFilterObject = BardFilterObject<TeamUserFilter>;

export type TeamUserFilterSuggestions = BardFilterSuggestions<TeamUserFilter>;

export const TEAM_USER_FILTER_TYPE = 'teamUserFilter';

export const teamUserFilterFieldTypes: {[key in keyof Required<TeamUserFilter>]: string | string[]} = {
    role: ['none', 'admin', 'facilitator', 'analyst', 'observer', 'solo'],
    displayName: 'string',
    isNew: 'boolean',
    trainingUser: 'boolean',
    user: BARD_USER_FILTER_TYPE
};

addFieldData(TEAM_USER_FILTER_TYPE, teamUserFilterFieldTypes, 'displayName');

export function parseTeamUserFilterString(filter: string): TeamUserFilterObject {
    return parseFilterString<TeamUserFilter>(filter, TEAM_USER_FILTER_TYPE);
}

// Test if the last token in filter string could auto-complete to values in TeamUserFilterKeys.
export function suggestTeamUserFilterFields(filterString: string): TeamUserFilterSuggestions {
    const filter = parseFilterString(filterString, TEAM_USER_FILTER_TYPE);
    return suggestFilterFields(filter);
}

export function teamUserMatchesFilter(user: UserForTeamType, filter?: TeamUserFilterObject) {
    if (!filter || !filter.fields) {
        return true;
    }
    for (let field of filter.fields) {
        const filterValue = filter.where[field];
        if (teamUserFilterFieldTypes[field] === BARD_USER_FILTER_TYPE) {
            if (!user.User || !userMatchesFilter(user.User, filterValue)) {
                return false;
            }
        } else {
            const teamValue = user[field];
            if ((Array.isArray(filterValue) && filterValue.reduce((noneMatch, filterValue) => (
                noneMatch && !fieldMatchesFilter(teamValue, filterValue, TEAM_USER_FILTER_TYPE, field)
            ), true)) || (!Array.isArray(filterValue) && !fieldMatchesFilter(teamValue, filterValue, TEAM_USER_FILTER_TYPE, field))) {
                return false;
            }
        }
    }
    return true;
}
