import React, {Component} from 'react';
import * as PropTypes from 'prop-types';
import {connect} from 'react-redux';

import '../scss/suggestions.scss';

import * as constants from '../../common/util/constants';
import {getLoggedInUserIdFromStore} from '../reducers/loginReducer';
import {getSuggestionsFromStore, updateSuggestionsAction} from '../../common/reducers/suggestionsReducer';
import PlainTextField from '../presentation/PlainTextField';
import DataStatusEnum from '../../common/DataStatusEnum'
import {getTeamFromStore} from '../../common/reducers/allTeamsReducerGetters';
import {getAllUsersForProblemFromStore} from '../../common/reducers/allUsersForTeamReducerGetters';

class SuggestionsContainer extends Component {

    static propTypes = {
        readOnly: PropTypes.bool,
        status: PropTypes.number,
        userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        problemStepId: PropTypes.number.isRequired,
        problem: PropTypes.object
    };

    static defaultProps = {
        readOnly: false,
        status: DataStatusEnum.MY_DRAFT
    };

    render() {
        const TOOLTIP_SUGGESTIONS = 'How should the problem be approached?';
        return (
            <div className='suggestionsContainerDiv'>
                <div>
                    <div className='minorTitle instructionNumberDivStyle roleBackgroundColour'>
                        <span className="instructionNumberListStyle">
                            {(this.props.users[this.props.userId].db.role === constants.ROLE_FACILITATOR && this.props.team.problemStep.stepId !== null) ? 6 : 5}
                        </span>
                        <span className="toolTipContainer instructionDivStyle">
                            Suggestions
                            <span className='toolTip'>{TOOLTIP_SUGGESTIONS}</span>
                        </span>
                    </div>
                </div>
                <div className="suggestionsDiv">
               
                <PlainTextField className='suggestionsField'
                                        text={this.props.suggestions.suggestion.suggestionText || ''}
                                        onChange={(value) => {
                                            this.props.dispatch(updateSuggestionsAction(this.props.problemStepId, this.props.userId, this.props.status, value));
                                            }}
                                        rows={1}
                                        cols={40}
                                        readOnly={this.props.readOnly}
                                        placeholder={this.props.readOnly ? "" : "[ Are there any other tools or resources that you think would be useful in approaching this problem? ]"}
                        />
                </div>
            </div>
        )
    }
}

function mapStoreToProps(store, myProps) {
    const problemStepId = myProps.problemStepId;
    const userId = myProps.userId || getLoggedInUserIdFromStore(store);
    const status = (myProps.status === undefined) ? SuggestionsContainer.defaultProps.status : myProps.status;
    return {
        users: getAllUsersForProblemFromStore(store, problemStepId),
        userId,
        suggestions: getSuggestionsFromStore(store, problemStepId, userId, status),
        team: getTeamFromStore(store, problemStepId),
    };
}

export default connect(mapStoreToProps)(SuggestionsContainer);