import React, {Component} from 'react';
import {connect} from 'react-redux';
import {SelectionControl} from 'react-md';
import Select from 'react-select';

import {getFeatureFlagsFromStore, setFeatureFlagsAction} from '../../common/reducers/featureFlagsReducer';
import * as featureFlags from '../../common/util/featureFlags';
import PlainTextField from './PlainTextField';

class AdminFeatureFlagPanel extends Component {

    renderBooleanControl(flag) {
        switch (flag.value.type) {
            case featureFlags.BOOLEAN_DIRECT:
                return (
                    <SelectionControl
                        id={'control_' + flag.key}
                        label='toggle the feature'
                        type='switch'
                        name={flag.key}
                        checked={flag.value.data || false}
                        onChange={(data) => {
                            this.props.dispatch(setFeatureFlagsAction({[flag.key]: {value: {data}}}));
                        }}
                    />
                );
            case featureFlags.BOOLEAN_USERNAME:
                return (
                    <PlainTextField
                        rows={1}
                        text={flag.value.data}
                        changeOnBlur
                        autoResize
                        onChange={(data) => {
                            this.props.dispatch(setFeatureFlagsAction({[flag.key]: {value: {data}}}));
                        }}
                    />
                );
            default:
                return null;
        }
    }

    renderFlagControl(flag) {
        switch (flag.type) {
            case featureFlags.FlagType.boolean:
                const booleanTypes = featureFlags.FLAG_FUNCTIONS[featureFlags.FlagType.boolean];
                const options = Object.keys(booleanTypes)
                    .filter((type) => (flag.isClientOnly || !booleanTypes[type].isClientOnly))
                    .map((type) => ({label: booleanTypes[type].label, value: type}));
                return (
                    <div className='multipleControls'>
                        <Select
                            options={options}
                            value={options.filter(({value}) => (value === flag.value.type))}
                            onChange={(option) => {
                                this.props.dispatch(setFeatureFlagsAction({[flag.key]: {value: {type: option.value}}}));
                            }}
                            isClearable={false}
                        />
                        {
                            this.renderBooleanControl(flag)
                        }
                    </div>
                );
            case featureFlags.FlagType.select:
                const selectOptions = flag.options.map((option) => ({label: option.label || option, value: option.label || option}));
                return (
                    <Select
                        options={selectOptions}
                        value={selectOptions.find((option) => (option.value === flag.value))}
                        onChange={(option) => {
                            this.props.dispatch(setFeatureFlagsAction({[flag.key]: {value: option.value}}));
                        }}
                        isClearable={false}
                    />
                );
            default:
                return null;
        }
    }

    render() {
        return (
            <table className='featureFlagTable'>
                <tbody>
                <tr>
                    <th>Feature Flag Name</th>
                    <th>Type</th>
                    <th>Value</th>
                </tr>
                {
                    Object.keys(this.props.featureFlags).map((flagId) => {
                        const flag = this.props.featureFlags[flagId];
                        return (
                            <tr key={flagId}>
                                <th>
                                    {flag.name || flagId}
                                    {
                                        flag.adminConsoleComment ? (
                                            <div className='adminConsoleComment'>{flag.adminConsoleComment}</div>
                                        ) : null
                                    }
                                </th>
                                <td>{flag.type}</td>
                                <td>
                                    {this.renderFlagControl(flag)}
                                </td>
                            </tr>
                        );
                    })
                }
                </tbody>
            </table>
        );
    }
}

function mapStoreToProps(store) {
    return {
        featureFlags: getFeatureFlagsFromStore(store)
    };
}

export default connect(mapStoreToProps)(AdminFeatureFlagPanel);