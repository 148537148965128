import React, {Component, ReactNode} from 'react';
import {connect} from 'react-redux';

import {getIsLoggedInFromStore} from '../reducers/loginReducer';

interface VisibleWhenLoggedInOwnProps {
    className?: string;
    showWhenNotLoggedIn?: ReactNode;
    singleChild?: boolean;
    children?: ReactNode;
}

interface VisibleWhenLoggedInStoreProps {
    visible: boolean;
}

type VisibleWhenLoggedInProps = VisibleWhenLoggedInOwnProps & VisibleWhenLoggedInStoreProps;

class VisibleWhenLoggedIn extends Component<VisibleWhenLoggedInProps> {

    render() {
        if (this.props.visible) {
            return this.props.singleChild ? this.props.children : (
                <div className={this.props.className}>
                    {this.props.children}
                </div>
            );
        } else {
            return this.props.showWhenNotLoggedIn || null;
        }
    }
}

function mapStoreToProps(store) {
    return {
        visible: getIsLoggedInFromStore(store)
    };
}

export default connect(mapStoreToProps)(VisibleWhenLoggedIn);