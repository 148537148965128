import React, {Component} from 'react';
import {connect} from 'react-redux';
import onClickOutside from 'react-onclickoutside'
import {FontIcon} from 'react-md';
import {RouteComponentProps, withRouter} from 'react-router';

import Logout from './Logout';
import UserFeedback from '../presentation/UserFeedback';
import {getIsLoggedInFromStore, getUserFromStore} from '../reducers/loginReducer';
import * as constants from '../../common/util/constants';
import {getLoginPathFromStore, LoginPathReducerType} from '../../common/reducers/loginPathReducer';
import {launchTour} from '../tour/tour';
import {buildRestPath, PROBLEM_SAVE_LOAD_PATH, ProblemPathRouteParameters} from '../../common/clientServer/navigation';
import {StoreWithSharedState} from '../../common/reducers/sharedStateReducer';
import {SingleUserForProblemReducerType} from '../../common/reducers/allUsersForTeamReducer';
import {getUserForProblemFromStore} from '../../common/reducers/allUsersForTeamReducerGetters';

interface MainMenuStoreProps {
    isLoggedIn: boolean;
    loginPath: LoginPathReducerType;
    userForProblem?: SingleUserForProblemReducerType;
}

type MainMenuOwnProps = RouteComponentProps<ProblemPathRouteParameters>;

type MainMenuProps = MainMenuStoreProps & MainMenuOwnProps;

interface MainMenuState {
    visible: boolean;
}

class MainMenu extends Component<MainMenuProps, MainMenuState> {

    constructor(props) {
        super(props);

        this.state = {
            visible: false
        };

        this.handleClickOutside = this.handleClickOutside.bind(this);
        this.onClick = this.onClick.bind(this);
        this.closeMenu = this.closeMenu.bind(this);
        this.triggerTourLaunch = this.triggerTourLaunch.bind(this);
    }

    triggerTourLaunch() {
        this.setState({visible: false}, launchTour);
    }

    closeMenu() {
        this.setState({visible: false});
    }

    onClick() {
        this.setState({visible: !this.state.visible});
    }
    
    handleClickOutside() {
        this.setState({visible: false});        
    }

    render() {
        return(
            <div className="mainMenu">
                <FontIcon className="md-26 toolTipContainer hamburgerIcon" onClick={this.onClick}>
                    menu
                    <span className="toolTip">Feedback & Logout</span>
                </FontIcon>

                <div className={'mainMenuContents ' + (this.state.visible ? "visible" : "notVisible")}>

                    <span className="mainMenuItem menuCloseItem"><i className="material-icons closeIcon" onClick={this.closeMenu}>close</i></span>

                    {
                        !this.props.isLoggedIn ? null : (
                            <>
                                {
                                    !this.props.userForProblem || (this.props.userForProblem.db.role !== constants.ROLE_ANALYST
                                            && this.props.userForProblem.db.role !== constants.ROLE_FACILITATOR
                                            && this.props.userForProblem.db.role !== constants.ROLE_SOLO_ANALYST) ? null : (
                                        <span className='mainMenuItem' onClick={() => {
                                            this.props.history.push(buildRestPath(PROBLEM_SAVE_LOAD_PATH, {problemStepId: this.props.match.params.problemStepId}));
                                        }}>Save or Load Draft...</span>
                                    )
                                }
                                <UserFeedback className='mainMenuItem menuUserFeedbackItem' type={'userFeedback'} onClose={this.closeMenu}/>
                                <span className='mainMenuItem menuTourItem' onClick={this.triggerTourLaunch}>Page Tour</span>
                                <Logout className='mainMenuItem menuLogoutItem' onLogout={this.closeMenu}/>
                            </>
                        )
                    }
                
                    <hr/>
                
                    <span className="mainMenuItem menuReleaseIdItem">{constants.release_id}</span>
                </div>
            </div>
        )
    }
}

function mapStoreToProps(store: StoreWithSharedState, ownProps: MainMenuOwnProps): MainMenuStoreProps {
    const loggedInUser = getUserFromStore(store);
    return {
        isLoggedIn: getIsLoggedInFromStore(store),
        loginPath: getLoginPathFromStore(store),
        userForProblem: loggedInUser && ownProps.match.params.problemStepId
            ? getUserForProblemFromStore(store, ownProps.match.params.problemStepId, loggedInUser.id)
            : undefined
    };
}

export default withRouter(connect(mapStoreToProps)(onClickOutside(MainMenu)));