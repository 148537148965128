/**
 * Given an order array of IDs and a map from IDs to values, return an array of all the IDs which exist in map.  If
 * everything is working as designed, the return value should always equal the order parameter, but we're seeing
 * mysterious errors where the order array and the map get out of sync, which causes crashes.
 *
 * @param {string[]} order An array of IDs which should (ideally) equal all the IDs in map
 * @param {object} map An object mapping the IDs to values.
 * @return {string[]} An array of IDs in the provided map.
 */
export function safeOrder(order: string[], map: {[key: string]: any}): string[] {
    const mapKeys = Object.keys(map);
    // Check if order and mapKeys have the same length, and all keys in order occur in map
    let valid = order.reduce((valid, key) => (valid && !!map[key]), order.length === mapKeys.length);
    if (valid) {
        return order;
    } else {
        console.error('safeOrder: there was a mis-match between an order array and its values', order, mapKeys);
        // Mis-match between order and map... rebuild the array of keys, preserving the order as much as possible.
        return mapKeys.sort((key1, key2) => {
            const key1Index = order.indexOf(key1);
            const key2Index = order.indexOf(key2);
            return (key1Index >= 0 && key2Index >= 0) ? (key1Index - key2Index) :
                (key1Index < 0) ? 1 : -1;
        });
    }
}

interface OrderedObject {
    order: string[];
    [key: string]: {[id: string]: any} | any;
}

/**
 *
 * @param {object} object A object which contains an ordered array of keys and a field containing a map that is meant to
 * contain those keys.
 * @param {string} field The field name of the map which contains the keys.
 * @return {string[]} An array of IDs to the field in object.
 */
export function safeOrderObject<T extends OrderedObject>(object: T, field: keyof T): string[] {
    if (!object.order || !object[field]) {
        console.error(`safeOrderObject invoked on an object with missing field(s)${!object.order ? ' "order"' : ''}${!object[field] ? ` "${field}"` : ''}:`, object);
        return object.order;
    } else {
        return safeOrder(object.order, object[field]);
    }
}

export function safeBNParameters(bnParameters: any): any {
    return {
        ...bnParameters,
        nodes: {
            order: safeOrderObject(bnParameters.nodes, 'variable'),
            variable: bnParameters.nodes.variable
        }
    };
}