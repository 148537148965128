import {indexOf} from 'lodash';

/**
 * Detect if the given value matches the optional match, which might be undefined, a single value or an array
 *
 * @param value The (single) value to match
 * @param match The thing to match against, which might be undefined (always matches), a single value (test with ===) or
 * an array of values (test if value occurs in the array).
 * @return {boolean} True if values matches match, false otherwise.
 */
export function doesValueMatch(value, match) {
    return match === undefined || (Array.isArray(match) ? indexOf(match, value) >= 0 : match === value);
}