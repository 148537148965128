import React, {Component} from 'react';
import {connect} from 'react-redux';
import {Link, withRouter} from 'react-router-dom';
import { FontIcon } from 'react-md';

import {
    buildRestPath,
    PROBLEM_DESCRIPTION_PATH
} from '../../common/clientServer/navigation';
import { getProblemDBFromStore } from '../../common/reducers/allProblemsReducer';
import {getProblemStepFromStore} from "../../common/reducers/allTeamsReducerGetters";

class HeaderCurrentProblem extends Component {

    render() {
        return (
            <div className="headerCurrentProblem">
                <div className="headerLabelDiv">
                    <div className="currentProblem">
                        <div className="problemNameLabel">
                            {this.props.problem.title}
                        </div>
                        <div className="problemNameIcon toolTipContainer">
                            <Link target='_blank' to={buildRestPath(PROBLEM_DESCRIPTION_PATH, {problemStepId: this.props.problemStepId})}>
                                <FontIcon className='md-link openProblemInTabIcon'>open_in_new</FontIcon>
                            </Link>
                            <span className="toolTip toolTipHeaderIcons">Open problem in another browser tab</span>
                        </div>                       
                    </div>
                </div>
            </div>
        );
    }
}

function mapStoreToProps(store, myProps) {
    const problemStep = getProblemStepFromStore(store, myProps.problemStepId);

    return {
        problem: getProblemDBFromStore(store, problemStep.problemId)
    };
}

export default withRouter(connect(mapStoreToProps)(HeaderCurrentProblem));