import React, {Component} from 'react';
import AsyncSelect from 'react-select/async';
import {throttle} from 'lodash';

import {BardFilterSuggestions, tokeniseFilterString} from '../../common/filter/commonFilter';
import {BardUserFilter} from '../../common/filter/bardUserFilter';
import {ProblemFilter} from '../../common/filter/problemFilter';
import {OnChangeValue} from 'react-select';

interface UserFilterSuggestionFieldProps {
    filter?: string;
    onChange: (filterText: string) => void;
    className?: string;
    placeholder?: string;
    showOptionsBelow?: boolean;
    type: 'user' | 'problem'
}

interface UserFilterSuggestionFieldState {
    inputValue?: string;
    suggestions?: BardFilterSuggestions<BardUserFilter | ProblemFilter>;
}

export default class FilterSuggestionField extends Component<UserFilterSuggestionFieldProps, UserFilterSuggestionFieldState> {

    private readonly components = {
        DropdownIndicator: null,
    };

    constructor(props: UserFilterSuggestionFieldProps) {
        super(props);
        this.state = {};
        this.onInputChange = this.onInputChange.bind(this);
        this.onChange = this.onChange.bind(this);
        this.fetchSuggestions = throttle(this.fetchSuggestions.bind(this), 250);
    }

    onInputChange(inputValue: string, {action}) {
        if (action === 'input-change') {
            this.setState({inputValue});
        }
    }

    onChange(selection: OnChangeValue<{label: string, value: string}, false>) {
        const tokens = tokeniseFilterString(this.state.inputValue || '');
        const filterText = (tokens.length > 0 ? tokens.slice(0, tokens.length - 1).join(' ') + ' ' : '') + selection!.value;
        this.props.onChange(filterText);
        this.setState({inputValue: filterText});
    }

    async fetchSuggestions(filterText: string) {
        const requestUrl = this.props.type === 'user' ? '/api/userFilter?filter=' : '/api/problemFilter?filter=';
        const response = await fetch(requestUrl + encodeURI(filterText));
        if (response.ok) {
            const suggestions = await response.json();
            return [
                ...(suggestions.openParentheses ? [{value: '(', label: '('}] : []),
                ...suggestions.values.map((value) => ({value, label: value})),
                ...suggestions.fields.map((field) => ({value: field + '=', label: field + '='}))
            ];
        }
        return [];
    }

    render() {
        return (
            <AsyncSelect
                components={this.components}
                className={this.props.className}
                styles={{
                    menu: (styles) => (this.props.showOptionsBelow ? styles : {...styles, top: undefined, bottom: '100%'})
                }}
                placeholder={this.props.placeholder}
                inputValue={this.state.inputValue}
                onInputChange={this.onInputChange}
                value={null}
                onChange={this.onChange}
                onBlur={() => {
                    this.props.onChange(this.state.inputValue || '')
                }}
                loadOptions={this.fetchSuggestions}
                defaultOptions={true}
                closeMenuOnSelect={false}
            />
        );
    }
}