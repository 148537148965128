import React, {Component} from 'react';
import {connect} from 'react-redux';
import moment from 'moment';

import {getLoggedInUserIdFromStore} from '../reducers/loginReducer';
import {getBayesianModelData} from '../../common/util/buildBayesianModelDataUtils';
import {
    BASE_SCENARIO,
    ExploreModelState,
    refreshScenarioDataAction,
    selectScenarioAction,
    setExploreModelDataAction
} from '../../common/reducers/exploreModelReducer';
import {getProblemDBFromStore} from '../../common/reducers/allProblemsReducer';
import DataStatusEnum from '../../common/DataStatusEnum';
import ExploreModelScenariosPanel from './ExploreModelScenariosPanel';
import ExploreModelMonitorVariablesPanel from './ExploreModelMonitorVariablesPanel';
import BayesNetDisplayComponent from '../presentation/BNCoLaLayout/CoLaNetworkVisComponent';
import {getBaseBayesNetStyle, getBayesNetNodeShape} from '../util/bayesNetStyle';
import * as constants from '../../common/util/constants';
import NLGComponent from "../presentation/NLGComponent";
import HelpTips from '../presentation/HelpTips';
import {safeBNParameters, safeOrderObject} from '../../common/util/safeOrder';
import DownloadNetworkComponent from '../presentation/DownloadNetworkComponent';
import {StoreWithSharedState} from '../../common/reducers/sharedStateReducer';
import DispatchProp from '../../@types/dispatchProp';
import {getTeamFromStore} from '../../common/reducers/allTeamsReducerGetters';

import '../scss/exploreModel.scss';
import '../scss/react-md.scss';

const defaultProps = {
    readOnly: false,
    status: DataStatusEnum.MY_DRAFT
};

type ExploreModelContainerDefaultProps = Readonly<typeof defaultProps>;

interface ExploreModelContainerOwnProps {
    problemStepId: number;
    userId?: number;
    buttonBarDiv: HTMLDivElement | null;
}

interface ExploreModelContainerStoreProps {
    userId: number;
    loggedInUserId: number;
    isTrainingProblem: boolean;
    isClosedProblem: boolean;
    exploreModel: ExploreModelState;
    migrated: boolean;
}

type ExploreModelContainerProps = ExploreModelContainerOwnProps & ExploreModelContainerDefaultProps & DispatchProp & ExploreModelContainerStoreProps;

interface ExploreModelContainerState {
    refreshedScenarioId: string | null;
    currentScenario?: string;
}

class ExploreModelContainer extends Component<ExploreModelContainerProps, ExploreModelContainerState> {

    static defaultProps = defaultProps;

    constructor(props) {
        super(props);
        this.dispatch = this.dispatch.bind(this);
        this.setCurrentScenarioId = this.setCurrentScenarioId.bind(this);
        this.state = {
            refreshedScenarioId: null
        };
    }

    dispatch(action) {
        if (this.props.migrated && this.props.exploreModel.timestamp) {
            this.props.dispatch(setExploreModelDataAction(this.props.problemStepId, this.props.userId, this.props.status, this.props.exploreModel));
        }
        return this.props.dispatch(action);
    }

    setCurrentScenarioId(scenarioId) {
        if (this.props.readOnly) {
            // In read-only mode, don't update currentScenarioId in the store - override with local state.
            this.setState({currentScenario: scenarioId});
        } else {
            this.dispatch(selectScenarioAction(this.props.problemStepId, this.props.userId, this.props.status, scenarioId));
        }
    }

    componentDidMount() {
        this.refreshScenarioIfRequired();
    }

    componentDidUpdate() {
        this.refreshScenarioIfRequired();
    }

    refreshScenarioIfRequired() {
        // If we're read-only and the scenario data is missing or more than a day old, trigger a refresh.  This handles
        // badly exported training problem data.
        const currentScenario = this.getCurrentScenarioId();
        if (currentScenario !== this.state.refreshedScenarioId) {
            if (!this.props.exploreModel.scenario[currentScenario].timestamp
                || moment().diff(moment(this.props.exploreModel.scenario[currentScenario].timestamp), 'days') >= 1) {
                this.setState({refreshedScenarioId: currentScenario}, () => {
                    this.dispatch(refreshScenarioDataAction(this.props.problemStepId, this.props.userId, this.props.status, currentScenario));
                });
            }
        }
    }

    getCurrentScenarioId() {
        if (this.state.currentScenario && this.props.exploreModel.scenario[this.state.currentScenario]) {
            return this.state.currentScenario;
        } else if (this.props.exploreModel.currentScenarioId && this.props.exploreModel.scenario[this.props.exploreModel.currentScenarioId]) {
            return this.props.exploreModel.currentScenarioId;
        } else {
            return BASE_SCENARIO;
        }
    }

    renderGraphPanel() {
        let styles = getBaseBayesNetStyle(this.props.exploreModel.bayesNet.nodes);
        if (this.getCurrentScenarioId() !== BASE_SCENARIO) {
            const scenario = this.props.exploreModel.scenario[this.getCurrentScenarioId()];
            if (scenario) {
                safeOrderObject(scenario.data.evidence, 'variable').forEach((variableId) => {
                    styles[variableId] = {
                        ...styles[variableId],
                        strokeWidth: 5,
                        stroke: constants.colourLightActionBlue
                    };
                });
            }
        }
        return (
            <div className="graphPanel">
                <div style={{display:"inline-block",width:"100%"}}>
                    <div className="pageTip">
                        Create scenarios to run the network with different inputs.
                    </div>
                    <HelpTips helpId={constants.HELP_EXPLORE_NETWORK} problemStepId={this.props.problemStepId}/>
                </div>
                <DownloadNetworkComponent
                    problemStepId={this.props.problemStepId}
                    userId={this.props.userId}
                    status={this.props.status}
                    buttonBarDiv={this.props.buttonBarDiv}
                    buttonClassName='lightTheme'
                >
                    <BayesNetDisplayComponent
                        graphDescription={safeBNParameters(this.props.exploreModel.bayesNet)}
                        readOnly={true}
                        styles={styles}
                        customNodeDrawingSVGFunction={getBayesNetNodeShape}
                        focusNodes={this.props.exploreModel.bayesNet.nodes.order}
                    />
                </DownloadNetworkComponent>
                <div className='naturalLanguageExplanation'>
                    <NLGComponent
                        exploreModel={this.props.exploreModel}
                        currentScenarioId={this.getCurrentScenarioId()}
                    />
                </div>
            </div>
        );
    }

    render() {
        return (
            <div className="exploreModelComponent">
                <ExploreModelScenariosPanel
                    readOnly={this.props.readOnly}
                    problemStepId={this.props.problemStepId}
                    disablePersonalScenarios={this.props.isClosedProblem}
                    userId={this.props.userId}
                    loggedInUserId={this.props.loggedInUserId}
                    status={this.props.status}
                    dispatch={this.dispatch}
                    exploreModel={this.props.exploreModel}
                    currentScenarioId={this.getCurrentScenarioId()}
                    onScenarioChange={this.setCurrentScenarioId}
                />
                {this.renderGraphPanel()}
                <ExploreModelMonitorVariablesPanel
                    readOnly={this.props.readOnly}
                    problemStepId={this.props.problemStepId}
                    userId={this.props.userId}
                    status={this.props.status}
                    dispatch={this.dispatch}
                    exploreModel={this.props.exploreModel}
                    currentScenarioId={this.getCurrentScenarioId()}
                />
            </div>
        );
    }
}

function mapStoreToProps(store: StoreWithSharedState, myProps: ExploreModelContainerOwnProps & Partial<ExploreModelContainerDefaultProps>): ExploreModelContainerStoreProps {
    const problemStepId = myProps.problemStepId;
    const team = getTeamFromStore(store, problemStepId);
    const problem = getProblemDBFromStore(store, team.problemStep.problemId);
    const loggedInUserId = getLoggedInUserIdFromStore(store);
    const userId = myProps.userId || loggedInUserId;
    const status = myProps.status === undefined ? ExploreModelContainer.defaultProps.status : myProps.status;
    const {updated: exploreModel, migrated} = getBayesianModelData<ExploreModelState>(store, problemStepId, userId, status, constants.SYNC_EXPLORE_MODEL);
    return {
        userId,
        loggedInUserId,
        isTrainingProblem: problem ? problem.isTraining : false,
        isClosedProblem: team ? team.problemStep.state === constants.STATE_CLOSED : false,
        exploreModel,
        migrated
    };
}

export default connect(mapStoreToProps)(ExploreModelContainer);
