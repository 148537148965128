import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';

import DataStatusEnum from '../../common/DataStatusEnum';
import PlainTextField from '../presentation/PlainTextField';
import {getLoggedInUserIdFromStore} from '../reducers/loginReducer';
import {getKeyQuestionsFromStore, updateQuestionAction} from '../../common/reducers/keyQuestionsReducer';
import IconLabelButton from '../presentation/IconLabelButton';
import HelpTips from '../presentation/HelpTips';
import * as constants from '../../common/util/constants';
import {
    buildRestPath,
    PROBLEM_DESCRIPTION_PATH
} from '../../common/clientServer/navigation';
import { getProblemDBFromStore } from '../../common/reducers/allProblemsReducer';
import {getProblemStepFromStore} from "../../common/reducers/allTeamsReducerGetters";
import {getUserForProblemFromStore} from "../../common/reducers/allUsersForTeamReducerGetters";

import '../scss/keyQuestions.scss';

class KeyQuestionsContainer extends Component {

    static propTypes = {
        readOnly: PropTypes.bool,
        status: PropTypes.number,
        userId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
        problemStepId: PropTypes.number.isRequired
    };

    static defaultProps = {
        readOnly: false,
        status: DataStatusEnum.MY_DRAFT,
    };

    render() {
        const TOOLTIP_VIEW_PROBLEM =  'View the Problem statement in a new tab';
        const TOOLTIP_KEY_QUESTIONS = 'Questions from the Problem Statement';
        
        return (
            <div>
                <div className='minorTitle instructionNumberDivStyle roleBackgroundColour'>
                    <span className="instructionNumberListStyle">1</span> 
                    <span className="toolTipContainer instructionDivStyle">Read Problem</span>
                </div>
                 <HelpTips helpId={constants.HELP_EXPLORE_PROBLEM_READ} problemStepId={this.props.problemStepId}/>

                <div className="readProblem">
                    <IconLabelButton className='actionTheme' iconChildren='open_in_new' target='_blank' href={buildRestPath(PROBLEM_DESCRIPTION_PATH, {problemStepId: this.props.problemStepId})} label='VIEW PROBLEM' toolTip={TOOLTIP_VIEW_PROBLEM}/>
                </div>
                <div>
                    <div className='minorTitle instructionNumberDivStyle roleBackgroundColour'>
                        <span className="instructionNumberListStyle">2</span>  
                        <span className="instructionDivStyle toolTipContainer">Review Key Question(s)<span className='toolTip toolTipKeyQuestion'>{TOOLTIP_KEY_QUESTIONS}</span></span>
                    </div>
                    <HelpTips helpId={constants.HELP_EXPLORE_PROBLEM_KEY_QUESTIONS} problemStepId={this.props.problemStepId}/>

                    <div className="displayKeyQuestions">
                        <div className="keyHeading semiMajorTitle-px">
                            <div className="keyHeadingQuestion">
                                Key questions
                            </div>
                            <div className="keyHeadingComment">
                                {(!this.props.readOnly && (this.props.userRole === constants.ROLE_ANALYST || this.props.userRole === constants.ROLE_SOLO_ANALYST)) ? constants.appContextDict.exploreProblem.KEY_QUESTION_MY_COMMENT_LABEL : constants.appContextDict.exploreProblem.KEY_QUESTION_COMMENT_LABEL}
                            </div>
                        </div>
                    {
                        Object.keys(this.props.problem.question).map((questionId, index) => (
                            <div key={`${questionId}-${this.props.userId}`}>
                                <div className="keyQuestion" key={questionId}>
                                    <div className='keyQuestionDiv'>
                                        <span className='keyQuestionNumber'>{index + 1}.</span>
                                        <span className='keyQuestionText' dangerouslySetInnerHTML={{__html: this.props.problem.question[questionId]}}/>
                                    </div>
                                    <div className="keyQuestionUserNote">
                                        <PlainTextField
                                            text={(this.props.keyQuestions.questions[questionId] && this.props.keyQuestions.questions[questionId].text) || ''}
                                            onChange={(text) => {
                                                this.props.dispatch(updateQuestionAction(this.props.problemStepId, this.props.userId, this.props.status, questionId, text));
                                            }}
                                            readOnly={this.props.readOnly}
                                            autoResize
                                        />
                                    </div>
                                </div>
                            </div>
                        ))
                    }
                    </div>
                </div>
            </div>
        );
    }
}

function mapStoreToProps(store, myProps) {
    const problemStepId = myProps.problemStepId;
    const userId = myProps.userId || getLoggedInUserIdFromStore(store);
    const userFromStore = userId && problemStepId && getUserForProblemFromStore(store, problemStepId, userId);
    const userRole = (userId && userFromStore) ? userFromStore.db.role : null;
    const status = (myProps.status === undefined) ? KeyQuestionsContainer.defaultProps.status : myProps.status;
    const problemStep = getProblemStepFromStore(store, problemStepId);
    return {
        userId,
        userRole,
        problem: getProblemDBFromStore(store, problemStep.problemId),
        keyQuestions: getKeyQuestionsFromStore(store, problemStepId, userId, status)
    };
}

export default connect(mapStoreToProps)(KeyQuestionsContainer);