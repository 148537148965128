import {AnyAction, combineReducers, Reducer} from 'redux';

import {DraftMessageType} from '../../server/orm/dao/userProblemDAO';
import * as problemReducer from './allTeamsReducer';
import allStatusesForUserAndTeamReducer, {
    AllStatusesForUserAndTeamReducerType,
    singleStatusForUserAndTeamReducer
} from './allStatusesForUserAndTeamReducer';
import DataStatusEnum from '../DataStatusEnum';
import ensureFieldPath from '../ensureFieldPath';
import * as constants from '../util/constants';
import {
    GRP_MGMT_AUTO_DEMOTE_USER,
    GRP_MGMT_AUTO_REJECT_OPT_OUT_REQUEST,
    GRP_MGMT_DEMOTE_USER,
    GRP_MGMT_OPT_OUT_GRANT_REQUEST,
    GRP_MGMT_OPT_OUT_REQUEST,
    GRP_MGMT_OPT_OUT_WITHDRAW_REQUEST,
    GRP_MGMT_PROMOTE_USER
} from './groupManagementReducer';
import ReferenceData from '../util/referenceData';
import {ObjectMapState} from '../util/genericReducers';
import {StoreWithSharedState} from './sharedStateReducer';
import userNotesReducer, {UserNotesReducerType} from './userNotesReducer';
import {BardUser} from '../clientServer/bardUser';
import {getFacilitator, initialUserForTeamType} from './allUsersForTeamReducerGetters';

const SET_USERS_FOR_TEAM = 'set-users-for-team';
const SET_TEAM_USER_DRAFT_MESSAGE = 'set_team_user_draft_message';
const DELETE_TEAM_USER_DRAFT_MESSAGE = 'delete_team_user_draft_message';

// ======== Reducers

export interface UserForTeamType {
    userId: number;
    User?: BardUser;
    role: number;
    problemStepId: number;
    displayName: string;
    timeSpent: number;
    isNew: boolean;
    groupManagementState: number;
    groupManagementStateDate?: Date;
    publishedStepMask: number;
    trainingUser: boolean;
    draftMessage?: DraftMessageType;
}

const userForTeamDBReducer: Reducer<UserForTeamType> = (state = initialUserForTeamType, action) => {
    switch (action.type) {
        case constants.RESET_PROBLEM_STEP:
            return {
                ...state,
                isNew: true,
                publishedStepMask: 0,
                timeSpent: 0,
                draftMessage: null
            };
        case constants.PROBLEM_SELECTED:
            if (state.isNew) {
                return { ...state, isNew: false };
            }
            break;
        case constants.STATUS_CHANGE:
            let mask = (1 << action.stepId);
            const stepDescriptions = ReferenceData.getInstance().getAllStepDescriptions();
            if (!stepDescriptions[action.stepId].nextStep && stepDescriptions[action.stepId].parentStepId) {
                // Publishing the last substep also marks the parent step as published
                mask |= (1 << (stepDescriptions[action.stepId].parentStepId || 0));
            }
            if ((action.status === DataStatusEnum.MY_DRAFT || action.status === DataStatusEnum.GROUP_DRAFT) && ((state.publishedStepMask || 0) & mask) !== mask) {
                let publishedStepMask = (state.publishedStepMask || 0) | mask;
                return { ...state, publishedStepMask };
            }
            break;
        case constants.UPDATE_USER_ROLES_FOR_PROBLEM:
            return {
                ...state,
                role: action.roleMap[action.userId]
            };
        case GRP_MGMT_AUTO_REJECT_OPT_OUT_REQUEST:
        case GRP_MGMT_OPT_OUT_REQUEST:
        case GRP_MGMT_OPT_OUT_GRANT_REQUEST:
        case GRP_MGMT_DEMOTE_USER:
        case GRP_MGMT_AUTO_DEMOTE_USER:
        case GRP_MGMT_PROMOTE_USER:
            return {
                ...state,
                groupManagementState: action.lastAction,
                groupManagementStateDate: action.actionDate
            };            
        case GRP_MGMT_OPT_OUT_WITHDRAW_REQUEST:
            return {
                ...state,
                groupManagementState: constants.GRP_MGMT_NOP,
                groupManagementStateDate: action.actionDate
            };
        case SET_TEAM_USER_DRAFT_MESSAGE:
            return {...state, draftMessage: action.draftMessage};
        case DELETE_TEAM_USER_DRAFT_MESSAGE:
            return {...state, draftMessage: null};
        default:
            break;
    }
    return state;
};

export type SingleUserForProblemReducerType = {
    db: UserForTeamType,
    status: AllStatusesForUserAndTeamReducerType,
    notes: UserNotesReducerType
}

export const initialSingleUserForProblemReducerType: SingleUserForProblemReducerType = {
    db: initialUserForTeamType,
    status: {},
    notes: ''
};

export const singleUserForProblemReducer = combineReducers<SingleUserForProblemReducerType>({
    db: userForTeamDBReducer,
    status: allStatusesForUserAndTeamReducer,
    notes: userNotesReducer
});

export type AllUsersForTeamReducerType = ObjectMapState<SingleUserForProblemReducerType>;

const allUsersForTeamReducer: Reducer<AllUsersForTeamReducerType> = (state = {}, action : UserForTeamAction | AnyAction) => {
    switch (action.type) {
        case constants.UPDATE_USER_DB_FOR_PROBLEM:
            let result = {...state};
            Object.keys(action.newUsersForProblem).forEach((userId) => {
                let newState: any = singleUserForProblemReducer(state[userId], action);
                result[userId] = {
                    ...newState,
                    db: {
                        ...newState.db,
                        ...action.newUsersForProblem[userId]
                    }
                };
            });
            action.removedUsers.forEach((userId) => {
                delete(result[userId]);
            });
            return result;
        case constants.UPDATE_USER_ROLES_FOR_PROBLEM:
            let nextState = Object.keys(action.roleMap).reduce((newState, userId) => {
                newState[userId] = singleUserForProblemReducer(state[userId], {...action, userId});
                return newState;
            }, {...state});
            const oldFacilitator = getFacilitator(state);
            const newFacilitator = getFacilitator(nextState);
            if (oldFacilitator && oldFacilitator !== newFacilitator) {
                // If the facilitator role is changing, we need to preserve the existing group draft & group data.
                const groupDraft = nextState[oldFacilitator].status[DataStatusEnum.GROUP_DRAFT];
                const group = nextState[oldFacilitator].status[DataStatusEnum.GROUP];
                nextState = {
                    ...nextState,
                    // The old facilitator gets the Group Draft and Group as their My Draft and Published solutions.
                    // Also clear their Group Draft and Group data if it is being inherited by a new facilitator.
                    [oldFacilitator]: {
                        ...nextState[oldFacilitator],
                        status: {
                            ...nextState[oldFacilitator].status,
                            [DataStatusEnum.MY_DRAFT]: groupDraft,
                            [DataStatusEnum.PUBLISHED]: group,
                            ...(newFacilitator && {
                                [DataStatusEnum.GROUP_DRAFT]: singleStatusForUserAndTeamReducer(undefined, action),
                                [DataStatusEnum.GROUP]: singleStatusForUserAndTeamReducer(undefined, action)
                            })
                        }
                    },
                    // If there's a new facilitator, they inherit old facilitator's Group Draft and Group solutions.
                    ...(newFacilitator && {[newFacilitator]: {
                        ...nextState[newFacilitator],
                        status: {
                            ...nextState[newFacilitator].status,
                            [DataStatusEnum.GROUP_DRAFT]: groupDraft,
                            [DataStatusEnum.GROUP]: group
                        }
                    }})
                };
            }
            return nextState;

        case constants.RESET_PROBLEM_STEP:
            return Object.keys(state).reduce((reset, userId) => {
                reset[userId] = singleUserForProblemReducer(state[userId], action);
                return reset;
            }, {});
        case SET_USERS_FOR_TEAM:
            return {...action.usersForProblem};
        case DELETE_TEAM_USER_DRAFT_MESSAGE:
        case SET_TEAM_USER_DRAFT_MESSAGE:
            return Object.keys(state).reduce((userState, userId) => {
                if(Number(userId) === action.userId){
                    userState[userId] = singleUserForProblemReducer(state[userId], action);
                }

                return userState;
            }, {...state});
        default:
            if (action.userId) {
                const singleUserState = state[action.userId];
                const nextUserState = singleUserForProblemReducer(singleUserState, action);
                if (singleUserState !== nextUserState) {
                    return { ...state, [action.userId]: nextUserState };
                }
            }
            return state;
    }
};

export default allUsersForTeamReducer;

// ======== DBSync functions
// (used by DBSync and for unit testing)

export function getDBSyncActionTypes() {
    return [
        constants.PROBLEM_SELECTED, constants.STATUS_CHANGE, constants.UPDATE_USER_DB_FOR_PROBLEM, constants.UPDATE_USER_ROLES_FOR_PROBLEM,
        constants.RESET_PROBLEM_STEP, GRP_MGMT_OPT_OUT_REQUEST, GRP_MGMT_OPT_OUT_GRANT_REQUEST, GRP_MGMT_OPT_OUT_WITHDRAW_REQUEST,
        GRP_MGMT_DEMOTE_USER, GRP_MGMT_AUTO_REJECT_OPT_OUT_REQUEST, SET_TEAM_USER_DRAFT_MESSAGE, DELETE_TEAM_USER_DRAFT_MESSAGE
    ];
}

export function setUserProblemDBInStore(store: StoreWithSharedState, problemStepId: number, userId: number, userProblem: UserForTeamType) {
    ensureFieldPath(store, 'sharedState', 'teams', problemStepId, 'user', userId);
    store.sharedState.teams[problemStepId].user[userId] = {...store.sharedState.teams[problemStepId].user[userId], db: userProblem};
}

// ======== Action Generators

export interface UserForTeamAction extends problemReducer.ProblemStepAction {
    userId: number
}

export function problemOpenedAction(problemStepId : number, userId : number): UserForTeamAction {
    return { type: constants.PROBLEM_SELECTED, problemStepId, userId };
}

export function updateUserDBForProblem(problemStepId: number, newUsersForProblem: {[id: string]: UserForTeamType}, removedUsers: number[]) {
    return {type: constants.UPDATE_USER_DB_FOR_PROBLEM, problemStepId, newUsersForProblem, removedUsers};
}

export function updateUserRolesForProblem(problemStepId: number, roleMap: object) {
    return {type: constants.UPDATE_USER_ROLES_FOR_PROBLEM, problemStepId, roleMap};
}

export function setUserDataForProblemStepAction(problemStepId: number, usersForProblem) {
    return {type: SET_USERS_FOR_TEAM, usersForProblem, problemStepId};
}

// Draft chat message is part of user-problem content as we need only one draft per user per problem
export function saveUserProblemDraftMessageAction(problemStepId: number, userId: number, draftMessage: DraftMessageType) {
    return {type: SET_TEAM_USER_DRAFT_MESSAGE, problemStepId, userId, draftMessage};
}

//Delete draft chat message
export function deleteUserProblemDraftMessageAction(problemStepId: number, userId: number){
    return {type: DELETE_TEAM_USER_DRAFT_MESSAGE, problemStepId, userId};
}


