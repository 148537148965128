import * as constants from "../../common/util/constants";

const commonSelectStyles = {
    option: (styles, {isSelected, isFocused}) => ({
        ...styles,
        backgroundColor: isSelected ? constants.colourPaleBlueGrey
            : isFocused ? constants.colourLightBlueGrey : styles.backgroundColor,
        color: isSelected ? constants.colourTextGrey : styles.color
    }),
    menu: (styles) => ({...styles, zIndex: 22}),
    control: (styles) => ({...styles, height: '32px'}),
    placeholder: (styles) => ({...styles, height: '32px', lineHeight: '32px'}),
    input: (styles) => ({...styles, height: '32px', lineHeight: '32px'}),
    value: (styles) => ({...styles, height: '32px', lineHeight: '32px'})
};

export default commonSelectStyles;