import {KeyVariablesState, VariableType} from '../reducers/keyVariablesReducer';
import {
    mergeValidations,
    validate,
    VALIDATION_NO_UNTRIMMED_SPACE,
    VALIDATION_NOT_EMPTY,
    VALIDATION_UNIQUE,
    ValidationValue
} from './validation';
import {safeOrderObject} from './safeOrder';

function validateKeyVariableOrState(name: string, newValue: string, oldValue: string, others: {[id: string]: string}, errorPrefix: string) {
    return validate(name, newValue, oldValue, [
        VALIDATION_NOT_EMPTY,
        VALIDATION_NO_UNTRIMMED_SPACE,
        {
            type: VALIDATION_UNIQUE,
            caseInsensitive: true,
            others
        }
    ], errorPrefix);
}

export function validateKeyVariable(name: string, newValue: string, oldValue: string, others: {[id: string]: string}) {
    return validateKeyVariableOrState(name, newValue, oldValue, others, 'Variable names');
}

export function validateVariableStates(name: string, newValue: string, oldValue: string, others: {[id: string]: string}) {
    return validateKeyVariableOrState(name, newValue, oldValue, others, 'State names');
}

export function validateAllKeyVariables(keyVariables: KeyVariablesState): ValidationValue {
    const allValues = safeOrderObject(keyVariables, 'variable').reduce((all, variableId) => {
        all[variableId] = keyVariables.variable[variableId].data.name;
        return all;
    }, {});
    let validations = {};
    for (let variableId of Object.keys(allValues)) {
        validations = mergeValidations(validations,
            validateKeyVariable(variableId, allValues[variableId], allValues[variableId], allValues));
        if (!validations[variableId]) {
            const variable = keyVariables.variable[variableId];
            validations = mergeValidations(validations, validate(variableId, allValues[variableId], allValues[variableId],
                () => ({[variableId]:
                        (variable.data.variableType === VariableType.UTILITY) ? (variable.attributes.order.length > 0 ? `Utility variable ${allValues[variableId]} cannot have states.` : null)
                            : (!variable.data.type) ? `Variable ${allValues[variableId]} does not have a defined type.`
                                : (variable.attributes.order.length === 0) ? `Variable ${allValues[variableId]} must have states.`
                                    : null
                })));
        }
        const prefix = variableId + '_';
        const allStates = safeOrderObject(keyVariables.variable[variableId].attributes, 'state').reduce((all, stateId) => {
            all[prefix + stateId] = keyVariables.variable[variableId].attributes.state[stateId].stateName;
            return all;
        }, {});
        const errorPrefix = `State names of variable ${allValues[variableId]}`;
        for (let stateId of Object.keys(allStates)) {
            validations = mergeValidations(validations,
                validateKeyVariableOrState(stateId, allStates[stateId], allStates[stateId], allStates, errorPrefix));
        }
    }
    return validations;
}