import {AnyAction} from 'redux';
import {omit} from 'lodash';

import {LOGIN} from './loginReducer';
import {SET_LISTENER_ID} from '../../common/util/constants';
import {SharedStateReducerType} from '../../common/reducers/sharedStateReducer';
import {TopLevelReducerType} from './clientMainReducer';

const ACTION_PROCESSED_BY_SERVER = 'action_processed_by_server';

type ActionsType = AnyAction[];

function pendingActionActionsReducer(state: ActionsType = [], action: AnyAction, savedStates: {[actionId: string]: any}) {
    switch (action.type) {
        case SET_LISTENER_ID:
            // If our listener_id is changed, we can't confirm that our actions have been processed any more.  Reset.
            return [];
        case LOGIN:
            // Action should not be appended.
            return state;
        case ACTION_PROCESSED_BY_SERVER:
            // Discard leading actions which have no savedStates associated with them (they've been processed by the
            // server or they originated from the server)
            let actions = state;
            while (actions.length > 0 && !savedStates[actions[0].actionId]) {
                actions = (actions === state) ? [...state] : actions;
                actions.shift();
            }
            return actions;
        default:
            // Append the action if it originates from our client or we have pending actions
            if (!action.isClientOnly && action.actionId && (!action.fromServer || state.length > 0)) {
                return [...state, action];
            } else {
                return state;
            }
    }
}

type StatesType = {[actionId: string]: SharedStateReducerType};

function pendingActionStatesReducer(state: StatesType = {}, action: AnyAction, sharedState: any) {
    switch (action.type) {
        case SET_LISTENER_ID:
            // If our listener_id is changed, we can't confirm that our actions have been processed any more.  Reset.
            return {};
        case ACTION_PROCESSED_BY_SERVER:
            // Remove saved state for this action, if any.
            if (state[action.action.actionId]) {
                return omit(state, action.action.actionId);
            } else {
                return state;
            }
        default:
            // Only save the sharedState for actions which will be sent to the server by this client.
            if (!action.isClientOnly && action.actionId && !action.fromServer) {
                return {
                    ...state,
                    [action.actionId]: sharedState
                }
            } else {
                return state;
            }
    }
}

export interface PendingActionReducerType {
    actions: ActionsType;
    states: StatesType;
}

export const initialPendingActionReducerType: PendingActionReducerType = {actions: [], states: {}};

function pendingActionReducer(state = initialPendingActionReducerType, action: AnyAction, sharedState: any) {
    const states = pendingActionStatesReducer(state.states, action, sharedState);
    const actions = pendingActionActionsReducer(state.actions, action, states);
    if (states !== state.states || actions !== state.actions) {
        return {states, actions}
    } else {
        return state;
    }
}

// This reducer acts at the top level because it needs topLevelState.sharedState, which a reducer at the pendingActions
// level of the store wouldn't see.  It's called first, so it saves away the sharedState before the action is actually
// applied to the store.
export default function pendingActionTopLevelStateReducer(topLevelState: TopLevelReducerType | undefined, action: AnyAction): TopLevelReducerType {
    const {clientState, sharedState} = topLevelState || {clientState: {}} as TopLevelReducerType;
    const oldPendingActions = clientState.pendingActions as undefined | PendingActionReducerType;
    const pendingActions = pendingActionReducer(oldPendingActions, action, sharedState);
    if (!topLevelState || pendingActions !== oldPendingActions) {
        return {
            sharedState,
            clientState: {
                ...clientState,
                pendingActions
            }
        };
    } else {
        return topLevelState;
    }
}

export function actionProcessedByServerAction(action) {
    return {type: ACTION_PROCESSED_BY_SERVER, action, isClientOnly: true};
}

export function getPendingActionsFromStore(store): PendingActionReducerType {
    return store.clientState.pendingActions;
}