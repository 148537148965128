import React from 'react';
import { connect } from 'react-redux';
import { Route, Redirect } from 'react-router-dom'

import { getIsLoggedInFromStore } from '../reducers/loginReducer';
import { ROOT_PATH } from '../../common/clientServer/navigation';

/**
 * PrivateRoute is like a Route from react-router, but it additionally requires that the user is logged in to the
 * server (according to our Redux state).  If not, it forwards them to the login page, but after they log in they'll
 * be redirected back to the original path.
 *
 * Not a pure component, because it relies on things outside of the props and Redux state (specifically, the current
 * path the browser is accessing).
 *
 * @param NestedComponent The component to render on this route.
 * @param isLoggedIn This prop is populated from the Redux state by mapStoreToProps below.
 * @param rest The other props configured on the PrivateRoute, to be passed through to a vanilla Route.
 * @constructor
 */
const PrivateRoute = ({ component: NestedComponent, isLoggedIn, ...rest }) => {

    return <Route {...rest} render={(props) => {
        return isLoggedIn ? (
            <NestedComponent/>
        ) : (
            <Redirect to={{
                pathname: ROOT_PATH,
                state: {deeplink: props.location}
            }}/>
        )
    }}/>
};

function mapStoreToProps(state) {
    return {
        isLoggedIn: getIsLoggedInFromStore(state)
    };
}

export default connect(mapStoreToProps, null, null, { pure: false })(PrivateRoute);