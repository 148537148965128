import * as constants from '../../common/util/constants';

const bardLogoImg = require('../images/BARD_RGB_MED_LOGO_FOYER_clear.png');

// TODO File path needs to be updated for Monash experiements
const helpContentUrlPrefix = `https://bard-problem-resources.s3.amazonaws.com/help-content/CREATE`;

export interface HelpTopicLink {
    linkName: string;
    linkText?: string;
    bardTrainingUrl?: boolean;
}

export interface HelpObject {
    bardRoles?: number[];
    bardModes?: (string | number)[];
    title?: string;
    markdown?: string;
    showChangelogText?: boolean;
    links?: HelpTopicLink[];
}

export interface HelpTopic {
    id: string;
    stepId?: constants.STEP[];
    title: string;
    helpObj: HelpObject[];
}

const helpGeneric: { [key: string]: HelpTopic } = {

        [constants.HELP_INTRO]: {
            id: constants.HELP_INTRO,
            title: 'Overview',
            helpObj: [
                {
                    bardRoles: [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],
                    links: [{
                        linkName: 'Welcome to BARD Training [MP4]',
                        linkText: `${helpContentUrlPrefix}/intro_to_bard_training.mp4`
                    }, {
                        linkName: 'Roles within BARD [PDF]',
                        linkText: `${helpContentUrlPrefix}/bard_roles_within_bard.pdf`
                    }, {
                        linkName: 'How to use BARD: Analyst [PDF]',
                        linkText: `${helpContentUrlPrefix}/bard_how_to_use_bard_analyst.pdf`
                    }]
                }
            ]
        },
        [constants.HELP_FACILITATOR]: {
            id: constants.HELP_FACILITATOR,
            title: 'Facilitator help',
            helpObj: [
                {
                    bardRoles: [constants.ROLE_FACILITATOR],
                    bardModes: [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.LAUNCH_MODE_SOLO_BARD],
                    links: [{
                        linkName: 'How to Use BARD: Facilitator [PDF]',
                        linkText: `${helpContentUrlPrefix}/bard_how_to_use_bard_facilitator.pdf`
                    }, {
                        linkName: 'Detailed Facilitator Guidance [PDF]',
                        linkText: `${helpContentUrlPrefix}/bard_detailed_facilitator_guidance.pdf`
                    }]
                }
            ]
        },
        [constants.HELP_EXAMPLES]: {
            id: constants.HELP_EXAMPLES,
            title: 'Example Problems',
            helpObj: [
                {
                    bardModes: [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.LAUNCH_MODE_SOLO_BARD],
                    markdown: `
We have set up a number of Training Problems in BARD, which you can access via the BARD Foyer. ![](${bardLogoImg})

## Interactive Training Problems

You can open the problem in single user mode, attempt your own answers, and once you “Publish” at each step, you can see our solution along with additional training notes.

  * Drug Cheat Simple [[PDF]](${helpContentUrlPrefix}/drug_cheat_simple_2v_bn_example.pdf)
  * Drug Cheat Full [[PDF]](${helpContentUrlPrefix}/drug_cheat_full_bn_example.pdf) [[MP4]](${helpContentUrlPrefix}/drug_cheat_full_explanation.mp4)
  * 3 Nations Full [[PDF]](${helpContentUrlPrefix}/3_nations_full_bn_example.pdf)

The following interactive training problems are classic BNs from the research literature, annotated with training notes.
  
  * Pearl’s Burglary [[PDF]](${helpContentUrlPrefix}/classic_bn_pearl_alarm.pdf)
  * Metastatic Cancer [[PDF]](${helpContentUrlPrefix}/classic_bn_metastatic_cancer.pdf)
  * Chest Clinic / Asia [[PDF]](${helpContentUrlPrefix}/classic_bn_chest_clinic_asia.pdf)
  * Blue/Green Cab [[PDF]](${helpContentUrlPrefix}/classic_bn_blue_green_cab.pdf)
  
## View-only Training Problems

You can open the problem in view-only, single user mode, where you can see our solution along with additional training notes.
  
  * 3 Nations modules: Here we break down the parts of the 3 Nations problem into easy-to-follow modules.
  * Bomb Throwing (Example of a non-BN problem and solution) [[PDF]](${helpContentUrlPrefix}/bomb_throwing_non-bn_example.pdf)
  * Snakes on a plain (Example of a non-BN problem and solution) [[PDF]](${helpContentUrlPrefix}/snakes_on_plain_non-bn_example.pdf)
                    `
                }
            ]

        },
        [constants.HELP_EXPLORE_PROBLEM]: {
            id: constants.HELP_EXPLORE_PROBLEM,
            stepId: [constants.STEP_EXPLORE_PROBLEM],
            title: 'Step 1: EXPLORE PROBLEM',
            helpObj: [
                {
                    bardRoles: [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],
                    links: [{
                        linkName: 'Step 1: EXPLORE PROBLEM [PDF]',
                        linkText: `${helpContentUrlPrefix}/bard_step1_explore_problem.pdf`
                    }, {
                        linkName: 'Guidelines: BN vs Non-BN [PDF]',
                        linkText: `${helpContentUrlPrefix}/bard_guidelines_bn_vs_non-bn.pdf`
                    }]
                }
            ]
        },
        [constants.HELP_BUILD_NETWORK]: {
            id: constants.HELP_BUILD_NETWORK,
            stepId: [constants.STEP_BAYESIAN_SOLUTION],
            title: 'Steps 2-5: BUILD NETWORK',
            helpObj: [
                {
                    bardRoles: [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],
                    bardModes: [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.LAUNCH_MODE_SOLO_BARD],
                    links: [{
                        linkName: 'How to Build BNs [PDF]',
                        linkText: `${helpContentUrlPrefix}/bard_how_to_build_bns.pdf`
                    }, {
                        linkName: ' Building BNs Iteratively and Incrementatlly [PDF]',
                        linkText: `${helpContentUrlPrefix}/bard_building_bns.pdf`
                    }]
                }
            ]
        },
        [constants.HELP_BN_VARIABLES]: {
            id: constants.HELP_BN_VARIABLES,
            stepId: [constants.STEP_VARIABLES],
            title: 'Step 2: VARIABLES',
            helpObj: [
                {
                    bardRoles: [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],
                    links: [{
                        linkName: 'More On: VARIABLES [PDF]',
                        linkText: `${helpContentUrlPrefix}/bard_more_on_variables.pdf`
                    }]
                }
            ]
        },
        [constants.HELP_BN_STRUCTURE]: {
            id: constants.HELP_BN_STRUCTURE,
            stepId: [constants.STEP_STRUCTURE],
            title: 'Step 3: STRUCTURE',
            helpObj: [
                {
                    bardRoles: [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],
                    links: [{
                        linkName: 'More On: STRUCTURE [PDF]',
                        linkText: `${helpContentUrlPrefix}/bard_more_on_structure.pdf`
                    }, {
                        linkName: 'Guidelines: Network Layout [PDF]',
                        linkText: `${helpContentUrlPrefix}/bard_guidelines_network_layout.pdf`
                    }]
                }
            ]
        },
        [constants.HELP_BN_PARAMETERS]: {
            id: constants.HELP_BN_PARAMETERS,
            stepId: [constants.STEP_PARAMETERS],
            title: 'Step 4: PARAMETERS',
            helpObj: [
                {
                    bardRoles: [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],
                    bardModes: [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.LAUNCH_MODE_SOLO_BARD],
                    links: [{
                        linkName: 'More On: PARAMETERS [PDF]',
                        linkText: `${helpContentUrlPrefix}/bard_more_on_parameters.pdf`
                    }, {
                        linkName: 'Verbal Probabilities [PDF]',
                        linkText: `${helpContentUrlPrefix}/bard_verbal_probabilities.pdf`
                    }, {
                        linkName: 'More On: Probability [PDF]',
                        linkText: `${helpContentUrlPrefix}/bard_more_on_probability.pdf`
                    }]
                }
            ]

        },
        [constants.HELP_BN_EXPLORE_NETWORK]: {
            id: constants.HELP_BN_EXPLORE_NETWORK,
            stepId: [constants.STEP_EXPLORE_MODEL],
            title: 'Step 5: EXPLORE NETWORK',
            helpObj: [
                {
                    bardRoles: [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],
                    bardModes: [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.LAUNCH_MODE_SOLO_BARD],
                    links: [{
                        linkName: 'More On: Reasoning [PDF]',
                        linkText: `${helpContentUrlPrefix}/bard_more_on_reasoning.pdf`
                    },
                        {
                            linkName: 'More On: Explanation [PDF]',
                            linkText: `${helpContentUrlPrefix}/bard_more_on_explanation.pdf`
                        }]
                }
            ]
        },
        [constants.HELP_CANDIDATE_SOLUTION]: {
            id: constants.HELP_CANDIDATE_SOLUTION,
            stepId: [constants.STEP_BR_CANDIDATE_SOLUTION, constants.STEP_NBR_CANDIDATE_SOLUTION],
            title: 'Step 6: CANDIDATE SOLUTION',
            helpObj: [
                {
                    bardRoles: [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER],
                    bardModes: [constants.LAUNCH_MODE_FACILITATOR_ADVANCES],
                    links: [{
                        linkName: 'Step 6: CANDIDATE SOLUTION - BN [PDF]',
                        linkText: `${helpContentUrlPrefix}/bard_step6_candidate_solution-bn.pdf`
                    }, {
                        linkName: 'Step 6: CANDIDATE SOLUTION - NBN [PDF]',
                        linkText: `${helpContentUrlPrefix}/bard_step6_candidate_solution-non-bn.pdf`
                    }]
                }
            ]
        },
        [constants.HELP_CS_REPORT]: {
            id: constants.HELP_CS_REPORT,
            stepId: [constants.STEP_BR_REPORT, constants.STEP_NBR_REPORT],
            title: 'Step 7: REPORT',
            helpObj: [
                {
                    bardRoles: [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER],
                    bardModes: [constants.LAUNCH_MODE_FACILITATOR_ADVANCES],
                    links: [{
                        linkName: 'Step 7: REPORT [PDF]',
                        linkText: `${helpContentUrlPrefix}/bard_step7_report.pdf`
                    },
                        {
                            linkName: 'How to write a PERFECT report [PDF]',
                            linkText: `${helpContentUrlPrefix}/bard_perfect_ref.pdf`
                        }]
                }
            ]
        },
        [constants.HELP_REPORT]: {
            id: constants.HELP_REPORT,
            stepId: [constants.STEP_COMBINED_REPORT],
            title: 'Step 6: REPORT',
            helpObj: [
                {
                    bardRoles: [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],
                    bardModes: [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.LAUNCH_MODE_SOLO_BARD],
                    markdown: `
To access sample reports, click on the links suggesting 'Sample Report' and scroll to the section 'Ideal Solution Report' in the PDF.
                    `,
                    links: [
                        {
                            linkName: 'Step 6: REPORT-BN [PDF]',
                            linkText: `${helpContentUrlPrefix}/bard_step6_report-bn.pdf`
                        },
                        {
                            linkName: 'Step 6: REPOR-NBN [PDF]',
                            linkText: `${helpContentUrlPrefix}/bard_step6_report-nbn.pdf`
                        },
                        {
                            linkName: 'How to insert images into reports or discussion posts [PDF]',
                            linkText: `${helpContentUrlPrefix}/bard_inserting_images.pdf`
                        },
                        {
                            linkName: 'How to write a PERFECT report [PDF]',
                            linkText: `${helpContentUrlPrefix}/bard_perfect_ref.pdf`
                        },
                        {
                            linkName: 'Sample Report ->  Interactive: Drug Cheat Full [PDF]',
                            linkText: `${helpContentUrlPrefix}/drug_cheat_full_bn_example.pdf`
                        },
                        {
                            linkName: 'Sample Report ->  Interactive: 3 Nations Full [PDF]',
                            linkText: `${helpContentUrlPrefix}/3_nations_full_bn_example.pdf`
                        },
                        {
                            linkName: 'Sample Report ->  View only: Snakes on a plain [PDF]',
                            linkText: `${helpContentUrlPrefix}/snakes_on_plain_non-bn_example.pdf`
                        },
                        {
                            linkName: 'Sample Report ->  View only: Bomb Throwing [PDF]',
                            linkText: `${helpContentUrlPrefix}/bomb_throwing_non-bn_example.pdf`
                        }]
                }
            ]
        },
        [constants.HELP_WORKING_IN_TEAMS]: {
            id: constants.HELP_WORKING_IN_TEAMS,
            title: 'Working in teams',
            helpObj: [
                {
                    bardRoles: [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER],
                    bardModes: [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.LAUNCH_MODE_FACILITATOR_ADVANCES],
                    links: [{
                        linkName: 'Open-minded Thinking [PDF]',
                        linkText: `${helpContentUrlPrefix}/bard_open-minded_thinking.pdf`
                    }, {
                        linkName: 'Good Team Player [PDF]',
                        linkText: `${helpContentUrlPrefix}/bard_good_team_player.pdf`
                    }]
                }
            ]
        },
        [constants.HELP_GOOD_REASONING]: {
            id: constants.HELP_GOOD_REASONING,
            title: 'Good reasoning',
            helpObj: [
                {
                    bardRoles: [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],
                    links: [{
                        linkName: 'Good Judgement [PDF]',
                        linkText: `${helpContentUrlPrefix}/bard_good_judgement.pdf`
                    }]
                }
            ]
        },
        [constants.HELP_COMMUNICATION]: {
            id: constants.HELP_COMMUNICATION,
            title: 'Communication in BARD',
            helpObj: [
                {
                    bardRoles: [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],
                    bardModes: [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.LAUNCH_MODE_SOLO_BARD],
                    links: [
                        {
                            linkName: 'Communication in BARD [PDF]',
                            linkText: `${helpContentUrlPrefix}/bard_communication_in_bard.pdf`
                        },
                        {
                            linkName: 'How to insert images into reports or discussion posts [PDF]',
                            linkText: `${helpContentUrlPrefix}/bard_inserting_images.pdf`
                        }]
                }
            ]
        },
        [constants.HELP_TROUBLESHOOTING]: {
            id: constants.HELP_TROUBLESHOOTING,
            title: 'Troubleshooting',
            helpObj: [
                {
                    bardRoles: [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],
                    bardModes: [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.LAUNCH_MODE_SOLO_BARD],
                    links: [{linkName: 'Troubleshooting', linkText: `${helpContentUrlPrefix}/bard_troubleshooting.pdf`}]
                }
            ]
        },
        [constants.HELP_NEW]: {
            id: constants.HELP_NEW,
            title: 'What\'s New?',
            helpObj: [
                {
                    markdown: `
The BARD online tool supports problem analysis and decision making, both by individuals and collaboratively for groups.

The BARD team is always striving to improve the BARD tool to better suit the needs of our users, so we’ll be regularly updating the software with minor improvements. Keep an eye on this page to see what’s new.

Your feedback is valuable to us. You can use the FEEDBACK link under the menu at the top right corner of the screen to provide your feedback.
                    `,
                    showChangelogText: true
                }
            ]
        },
        [constants.HELP_TRAINING]: {
            id: constants.HELP_TRAINING,
            title: 'BARD Online Training',
            helpObj: [
                {
                    bardRoles: [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_SOLO_ANALYST],
                    bardModes: [constants.LAUNCH_MODE_REAL_TIME_JURY, constants.LAUNCH_MODE_SOLO_BARD],
                    markdown: `
Need a refresher? You can return to BARD training any time.
                    `,
                    links: [{linkName: 'Open BARD training', bardTrainingUrl: true}]
                }
            ]
        }
    };

export default class HelpContentData {
    static instance: HelpContentData;

    static getInstance(): HelpContentData {
        return HelpContentData.instance || (HelpContentData.instance = new HelpContentData());
    }

    public getGenericHelpContent(): {[key: string]: HelpTopic} {
        return helpGeneric;
    }

}
