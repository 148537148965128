import React, {Component} from 'react';
import bardLogoImg from '../images/BARD_RGB_MED_LOGO_FOYER_clear.png';
import '../scss/app.scss';
import '../scss/browserLogos.scss';

class BardGlobalErrorContainer extends Component {

    constructor(props) {
        super(props);
        this.state = {
            globalError: false
        }
    }

    componentDidCatch() {
        this.setState({globalError: true});
    }

    render() {
        return this.state.globalError ? (
            <div className='globalError'>
                <div className='globalErrorText'>
                    <h1>Sorry, something is not right...</h1>

                    <p>BARD encountered some issues that prevented the page from loading.</p>
                
                    <p>To proceed, here are a few options:</p>

                    <ul>
                        <li>Return to <a href='/foyer' rel='noopener noreferrer'> the BARD Foyer</a></li>
                        <li>Return to <a href={window.location.href} rel='noopener noreferrer'> your current problem</a></li>
                    </ul>
                </div>
                <div className="globalErrorBardLogoImg"><img src={bardLogoImg} alt="BARD LOGO"/></div>
            </div>
        ) : (
            this.props.children
        )
    }
}

export default BardGlobalErrorContainer;