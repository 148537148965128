import React, {Component} from 'react';
import * as PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import classNames from 'classnames';
import {Redirect, withRouter} from 'react-router-dom';
import {kebabCase} from 'lodash';
import memoizeOne from 'memoize-one';

import {getLoggedInUserIdFromStore} from '../reducers/loginReducer';
import ReferenceData from '../../common/util/referenceData';
import {problemOpenedAction} from '../../common/reducers/allUsersForTeamReducer';

import SubtabsContainer from './SubtabsContainer';
import * as constants from '../../common/util/constants';
import NotificationContainer from './NotificationContainer';
import {doesRoundMatch} from '../../common/util/roundLogic';
import {isStepDisabled, isFutureEditableStep} from '../util/stepLogic';
import {buildRestPath, PROBLEM_PATH, SELECT_PATH} from '../../common/clientServer/navigation';
import {doesValueMatch} from '../../common/util/matchLogic';
import {canReachStepId} from '../../common/util/buildBayesianModelDataUtils';
import { getProblemDBFromStore } from '../../common/reducers/allProblemsReducer';
import {getProblemStepFromStore} from "../../common/reducers/allTeamsReducerGetters";
import {getUserForProblemFromStore} from "../../common/reducers/allUsersForTeamReducerGetters";

import '../scss/react-md.scss';
import '../scss/app.scss';

class BardTemplate extends Component {

    static propTypes = {
        problemStepId: PropTypes.number.isRequired
    };

    TOP_LEVEL = 'topLevel';

    constructor(props) {
        super(props);
        this.buildFlattenedSteps = memoizeOne(this.buildFlattenedSteps.bind(this));
        this.state = {
            parentStepWidth: {},
            subtabNames: {}
        };
    }

    dispatchProblemOpenedAction(){
        this.props.dispatch(problemOpenedAction(this.props.problemStepId, this.props.userId));
    }

    
    findFirstSteps(orderedSteps) {
        const stepDescriptions = this.getAllStepDescriptions();
        Object.keys(stepDescriptions).forEach((stepId) => {
            if (stepDescriptions[stepId].startingStep) {
                if (!stepDescriptions[stepId].parentStepId) {
                    // Verify that we can reach the first routed step from this starting step
                    if ((this.props.problemStep.launched && canReachStepId(stepDescriptions, stepId, this.props.problemStep.route[0][0]))
                            || !orderedSteps[this.TOP_LEVEL]) {
                        orderedSteps[this.TOP_LEVEL] = [Number(stepId)];
                    }
                } else {
                    orderedSteps[stepDescriptions[stepId].parentStepId] = [Number(stepId)];
                }
            }
        });
    }

    flattenSteps(flattened, orderedSteps, key) {
        orderedSteps[key].forEach((stepId) => {
            stepId = Number(stepId);
            if (orderedSteps[stepId]) {
                // If a step has sub-steps, put the substeps into the flattened array instead
                this.flattenSteps(flattened, orderedSteps, stepId);
            } else {
                flattened.push(stepId);
            }
        });
        return flattened;
    }

    /**
     * Run through the linked list of next steps and flatten them into an array of "child" stepIds, i.e. ones with no children.
     *
     * @returns An array of stepIds, in order and all of which have no sub-steps.
     */
    buildFlattenedSteps(problemStep) {
        let orderedSteps = {};
        const stepDescriptions = this.getAllStepDescriptions();
        this.findFirstSteps(orderedSteps);
        Object.keys(orderedSteps).forEach((key) => {
            let stepId = orderedSteps[key].pop();
            while (stepId !== null) {
                orderedSteps[key].push(stepId);
                let nextStep = stepDescriptions[stepId].nextStep;
                
                if (Array.isArray(nextStep)) {
                    stepId = nextStep.reduce((result, stepId) => {
                        return (problemStep.route || []).reduce((result, route) => {
                            if (route[0] === stepId) {
                                return stepId;
                            } else {
                                return result;
                            }
                        }, null) || result;
                    }, nextStep[0]);
                } else {
                    stepId = nextStep;
                }
            }
        });
        return this.flattenSteps([], orderedSteps, this.TOP_LEVEL);
    }

    /**
     * Run up from the given stepId through parent IDs to the top-level step.
     * @param stepId The step to search upwards from.
     * @returns The top-level step ID which is an ancestor to stepId
     */
    getTopLevelStepId(stepId) {
        const stepDescriptions = this.getAllStepDescriptions();
        while (stepDescriptions[stepId].parentStepId) {
            stepId = stepDescriptions[stepId].parentStepId;
        }
        return stepId;
    }

    hasPublished(stepId) {
        return stepId < 0 || (((1 << stepId) & this.props.user.db.publishedStepMask) !== 0);
    }

    getAllStepDescriptions() {
        const stepDescriptions = ReferenceData.getInstance().getAllStepDescriptions();
        const realTimeJuryMode = (this.props.problemStep.launchMode === constants.LAUNCH_MODE_REAL_TIME_JURY);
        if (realTimeJuryMode) {
            // Override base stepDescriptions for Real Time Jury problems - all steps are open rounds.
            const openRoundChanges = ReferenceData.getInstance().getOpenRoundChanges();
            return Object.keys(stepDescriptions).reduce((result, stepId) => {
                result[stepId] = {...stepDescriptions[stepId], ...openRoundChanges};
                return result;
            }, {});
        } else {
            return stepDescriptions;
        }
    }

    calculateTabState() {
        const notLaunched = (this.props.problemStep.launched === null);
        let initialIndex = -1;
        let tense = (notLaunched) ? constants.STEP_TENSE.FUTURE : constants.STEP_TENSE.PAST;
        const realTimeJuryMode = (this.props.problemStep.launchMode === constants.LAUNCH_MODE_REAL_TIME_JURY);
        let previousTopLevel = -1;
        const tabSteps = this.buildFlattenedSteps(this.props.problemStep);
        const currentStepId = (!realTimeJuryMode || this.props.user.db.role !== constants.ROLE_ANALYST || this.props.problemStep.state === constants.STATE_CLOSED) ? this.props.problemStep.stepId : (
            // In Real Time Jury mode, the "current step" for an Analyst is the first step they haven't published.
            tabSteps.reduce((result, stepId, index) => {
                const topLevel = this.getTopLevelStepId(stepId);
                result = result || (index === tabSteps.length - 1 || (this.hasPublished(previousTopLevel) && !this.hasPublished(topLevel)) ? topLevel : null);
                previousTopLevel = topLevel;
                return result;
            }, null)
        );

        let tabTenses = tabSteps.map((stepId, index) => {
            const topLevel = this.getTopLevelStepId(stepId);
            if (currentStepId !== null && topLevel === Number(currentStepId) && tense !== constants.STEP_TENSE.FUTURE) {
                tense = constants.STEP_TENSE.PRESENT;
                initialIndex = index;
            } else if (tense === constants.STEP_TENSE.PRESENT) {
                tense = constants.STEP_TENSE.FUTURE;
            }
            const result = tense;
            if (tense === constants.STEP_TENSE.PRESENT && doesRoundMatch(constants.ROUND_OPEN + constants.ROUND_UNPUBLISHED, this.props.user, this.props.problemStep, stepId, this.getAllStepDescriptions(), tense)) {
                // In an open round, don't enable tabs for more than the next unpublished step
                tense = constants.STEP_TENSE.FUTURE;
            }
            return (tense === constants.STEP_TENSE.FUTURE && isFutureEditableStep(stepId, currentStepId)) ?
                constants.STEP_TENSE.FUTURE_EDITABLE : result;
        });
        let tabList = tabSteps.slice();
        const stepDescriptions = this.getAllStepDescriptions();
        const additionalConditionalSteps = ReferenceData.getInstance().getAdditionalConditionalSteps();
        additionalConditionalSteps.forEach((stepId) => {
            const step = stepDescriptions[stepId];
            if (doesValueMatch(this.props.user.db.role, step.visibleRole)) {
                tabList.push(stepId);
                tabTenses.push(constants.STEP_TENSE.PAST);
            }
        });
    
        if (initialIndex < 0) {
            initialIndex = 0;
        }

        return {tabList, tabTenses, initialIndex};
    }

    renderTabContent(stepId, tabName, tense) {
        const stepDescriptions = this.getAllStepDescriptions();
        const stepDescription = stepDescriptions[stepId];
        return <SubtabsContainer
            problemStepId={this.props.problemStepId}
            stepId={stepId}
            tabName={tabName}
            defaultName={this.state.subtabNames[tabName]}
            subTabs={stepDescription.subTabs}
            tabClassName={stepDescription.subtabClassName}
            user={this.props.user}
            problemStep={this.props.problemStep}
            problem={this.props.problem}
            readOnly={this.props.problemStep.state === constants.STATE_CLOSED || (tense === constants.STEP_TENSE.PAST && this.props.problemStep.stepId !== null)}
            tabTense={tense}
            notificationDisabled={this.isNotificationDisabled(stepId)}
            allowMinimalSubtabs={this.props.user.db.role === constants.ROLE_SOLO_ANALYST}
        />;
    }

    componentDidMount() {

        //Dispatching action to update isNew flag for the problem to indicate that it has been accessed.
        if(this.props.user.db.isNew){
            this.dispatchProblemOpenedAction();
        }
        
        // Sum up the widths of substep tabs
        let parentStepWidth = null;
        Object.keys(this.subStepRefs).forEach((parentStepId) => {
            let width = this.subStepRefs[parentStepId].reduce((width, ref) => {
                return width + ref.node.getBoundingClientRect().width;
            }, 0);
            if (width !== this.state.parentStepWidth[parentStepId]) {
                if (!parentStepWidth) {
                    parentStepWidth = {...this.state.parentStepWidth};
                }
                parentStepWidth[parentStepId] = width;
            }
        });
        if (parentStepWidth) {
            this.setState({parentStepWidth});
        }
    }

    componentDidUpdate() {
        // Remember subtabName
        if (this.props.match.params.subtabName !== undefined) {
            const tabName = this.props.match.params.tabName;
            const subtabName = this.props.match.params.subtabName;
            if (subtabName !== this.state.subtabNames[tabName]) {
                this.setState({subtabNames: {
                    ...this.state.subtabNames,
                    [tabName]: subtabName
                }});
            }
        }
    }

    getTabNameFromStepId(stepId) {
        const stepDescriptions = this.getAllStepDescriptions();
        return kebabCase(stepDescriptions[stepId].tabName || stepDescriptions[stepId].name);
    }

    isNotificationDisabled(stepId) {
        const stepDescription = this.getAllStepDescriptions()[stepId];
        return this.props.user.db.role !== constants.ROLE_FACILITATOR && stepDescription.notificationRounds &&
            !doesRoundMatch(stepDescription.notificationRounds, this.props.user, this.props.problemStep, stepId, this.getAllStepDescriptions());
    }

    render() {
        // Defensive step - ensure this user is actually assigned to this problem
        if (!this.props.user) {
            return <Redirect to={SELECT_PATH}/>;
        }
        let {tabList, tabTenses, initialIndex} = this.calculateTabState();
        const stepDescriptions = this.getAllStepDescriptions();
        this.subStepRefs = {};
        let selectedIndex = tabList
            .reduce((result, stepId, index) => (
                (!isStepDisabled(tabTenses[index]) && this.getTabNameFromStepId(stepId) === this.props.match.params.tabName) ? index : result
            ), initialIndex);
        const isFacilitator = (this.props.user.db.role === constants.ROLE_FACILITATOR);
        return (
            <div className={classNames('bardTemplate', {
                observer: this.props.user.db.role === constants.ROLE_OBSERVER,
                analyst: this.props.user.db.role === constants.ROLE_ANALYST,
                facilitator: isFacilitator,
                soloAnalyst: this.props.user.db.role === constants.ROLE_SOLO_ANALYST
            })}>
                <Tabs className="reactTabs topLevelTabs" selectedIndex={selectedIndex} selectedTabClassName='selected' onSelect={(index) => {
                    this.props.history.push(buildRestPath(PROBLEM_PATH, {problemStepId: this.props.problemStepId, tabName: this.getTabNameFromStepId(tabList[index])}));
                }}>
                    <TabList>
                        {
                            tabList.map((stepId, index) => {
                                let tense = (this.props.problemStep.state === constants.STATE_CLOSED) ? constants.STEP_TENSE.PAST : tabTenses[index];
                                const stepDescription = stepDescriptions[stepId];
                                const notificationTags = (isFacilitator && stepDescription.facilitatorTags) || stepDescription.tags;
                                return (
                                    <Tab key={'Tab' + stepId}
                                         disabled={isStepDisabled(tense)}
                                         className={classNames('react-tabs__tab', stepDescription.tabClassName, {
                                             completedStepTab: tense === constants.STEP_TENSE.PAST,
                                             currentStepTab: tense === constants.STEP_TENSE.PRESENT,
                                             futureStepTab: tense === constants.STEP_TENSE.FUTURE,
                                             futureEditableStepTab: tense === constants.STEP_TENSE.FUTURE_EDITABLE
                                         },'mainTab-'+this.getTabNameFromStepId(stepId),
                                        )}
                                         ref={(element) => {
                                             if (stepDescription.parentStepId) {
                                                 if (!this.subStepRefs[stepDescription.parentStepId]) {
                                                     this.subStepRefs[stepDescription.parentStepId] = [element];
                                                 } else {
                                                     this.subStepRefs[stepDescription.parentStepId].push(element);
                                                 }
                                             }
                                         }}
                                    >
                                        {
                                            (stepDescription.startingStep && stepDescription.parentStepId) ?
                                                <div className='parentStepTitle toolTipContainer' style={{width: this.state.parentStepWidth[stepDescription.parentStepId] || 100}}>
                                                    {stepDescriptions[stepDescription.parentStepId].tabName || stepDescriptions[stepDescription.parentStepId].name}
                                                    <span className="toolTip bntoolTip">{stepDescriptions[stepDescription.parentStepId].description}</span>
                                                </div> :
                                                null
                                        }
                                        <div className='tabDiv'>
                                            {stepDescription.tabName || stepDescription.name}
                                            {
                                                (!notificationTags || notificationTags.length === 0) ? null : (
                                                    <NotificationContainer
                                                        tags={notificationTags}
                                                        disabled={this.isNotificationDisabled(stepId)} showFlag stepId={stepId}/>
                                                )
                                            }
                                            <span className="toolTip toolTipTabs">{stepDescription.description}</span>
                                        </div>
                                    </Tab>
                                );
                            })
                        }

                    </TabList>
                    {
                        tabList.map((stepId, index) => (
                            <TabPanel key={'Tab' + stepId}>
                                {this.renderTabContent(stepId, this.getTabNameFromStepId(stepId), tabTenses[index])}
                            </TabPanel>
                        ))
                    }                    
                </Tabs>
            </div>
        );
    }

}

function mapStoreToProps(store, myProps) {
    const problemStepId = myProps.problemStepId;
    const userId = getLoggedInUserIdFromStore(store);
    const problemStep = getProblemStepFromStore(store, problemStepId);
    return {
        userId,
        problemStepId,
        problemStep,
        problem: getProblemDBFromStore(store, problemStep.problemId),
        user: getUserForProblemFromStore(store, problemStepId, userId)
    }
}

export default withRouter(connect(mapStoreToProps)(BardTemplate));