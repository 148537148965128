import {Action, AnyAction, Reducer} from 'redux';

import ensureFieldPath from '../ensureFieldPath';
import {UserFeedbackType} from '../clientServer/userFeedbackType';

export const DRAFT_FEEDBACK = 'draft';
export const SUBMITTED_FEEDBACK = 'submitted';

export const UPDATE_FEEDBACK = 'update_feedback';
export const SUBMIT_FEEDBACK = 'submit_feedback';

export const BARD_FEEDBACK_TYPES = [
    {label: 'Suggestion for improvement', value: 'enhancement'},
    {label: 'Defect report', value: 'defect'},
    {label: 'General comment on BARD', value: 'comment'}
];

export const BARD_HELP_TYPES = [
    {label: 'Help with BARD', value: 'bardhelp'},
    {label: 'Report a problem', value: 'bardproblem'},
    {label: 'General comment on BARD', value: 'bard'}
];

export const DEFAULT_FEEDBACK_TYPE = 'default';

export function getDBSyncActionTypes() {
    return [UPDATE_FEEDBACK, SUBMIT_FEEDBACK];
}

export interface UserFeedbackAction extends Action {
    userId: number,
    entryType: string,
    loginName: string,
    browserName: string,
    feedbackState: 'draft' | 'submitted',
    feedbackType: string,
    location: string,
    text: string
}

type EntryTypeUserFeedbackReducerType = {
    loginName: string;
    browserName: string;
    feedbackState: 'draft' | 'submitted';
    feedbackType: string;
    location: string;
    text: string;
};

export type UserFeedbackReducerType = {[key: string]: EntryTypeUserFeedbackReducerType};

const userFeedbackReducer: Reducer<UserFeedbackReducerType> = (state = {}, action: UserFeedbackAction | AnyAction) => {
    let userFeedback;
    switch (action.type) {
        case UPDATE_FEEDBACK:
            userFeedback = {...state[action.userId], [action.entryType]: {loginName: action.loginName, browserName: action.browserName, feedbackState: DRAFT_FEEDBACK, feedbackType: action.feedbackType, location: action.location, text: action.text}};
            return {
                ...state, [action.userId]: userFeedback
            };
        case SUBMIT_FEEDBACK:
            userFeedback = {...state[action.userId], [action.entryType]: {loginName: action.loginName, browserName: action.browserName, feedbackState: SUBMITTED_FEEDBACK, feedbackType: DEFAULT_FEEDBACK_TYPE, location: '', text: ''}};
            return {
                ...state, [action.userId]: userFeedback
            };
    default:
            return state;
    }
};

export default userFeedbackReducer;

export function updateUserFeedbackAction(userId, entryType, loginName, browserName, feedbackType, location, text): UserFeedbackAction {
    return {type: UPDATE_FEEDBACK, userId, entryType, loginName, browserName, feedbackState: DRAFT_FEEDBACK, feedbackType, location, text};
}

export function submitUserFeedbackAction(userId, entryType, loginName, browserName, feedbackType, location, text): UserFeedbackAction {
    return {type: SUBMIT_FEEDBACK, userId, entryType, loginName, browserName, feedbackState: SUBMITTED_FEEDBACK, feedbackType, location, text};
}

export function getUserFeedbackFromStore(store, userId, entryType) {
    return store.sharedState.feedback[userId] ? store.sharedState.feedback[userId][entryType] : null;
}

export function setUserFeedbackInStore(store, userId, entryType, feedbackPojo: UserFeedbackType) {
    ensureFieldPath(store, 'sharedState', 'feedback', userId, entryType);
    store.sharedState.feedback[userId][entryType] = feedbackPojo;
}
