import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';

import Timer from './Timer';
import {getRemainingTime} from '../util/timeUtils';
import * as constants from '../../common/util/constants';
import {getProblemStepFromStore} from "../../common/reducers/allTeamsReducerGetters";

import bardTimerGreenImg from '../images/STATUS_ICONS_GREEN_TIME.svg';

class HeaderCurrentTimer extends Component {

    static propTypes = {
        problemStepId: PropTypes.number.isRequired,
        page: PropTypes.string
    };

    static defaultProps = {
        page: constants.PAGE_HEADER
    };

    render() {
        const toolTip = <span className='toolTip toolTipHeaderIcons'>Time remaining for the problem</span>;
        const problemIsClosed = this.props.problemStep.state === constants.STATE_CLOSED;
        
        // calculate time remaining in current activity
        let {hours, end} = getRemainingTime(this.props.problemStep);
        
        return (
            <div className={'headerCurrentTimer '+ (this.props.page === constants.PAGE_FOYER ? 'foyerProblemStatusDivStyles' : '')}>
                <div className='imageDiv toolTipContainer'>
                    <img className="iconStyles" src={bardTimerGreenImg} alt="TIME REMAINING"/>
                    {toolTip}
                </div>
                <div className="headerLabelDiv">
                    <span className="headerTitle bolder toolTipContainer">REMAINING
                    {toolTip}
                    </span>
                    {(this.props.problemStep.launched === null || problemIsClosed) ? 
                        <div className='problemStep'>&ndash;</div>
                        :
                        (hours <= 0)
                            ? <div className='timeRemaining'>00:00</div>
                            : (hours > this.props.problemStep.activityDuration)
                                ? <div className='timeRemaining'>&ndash;</div>
                                : <Timer className='timeRemaining' deadline={end.toString()} suffix={true}/>
                    }
                </div>
            </div>
        );
    }
}

function mapStoreToProps(store, myProps) {
    return {
        problemStep: getProblemStepFromStore(store, myProps.problemStepId)
    };
}

export default connect(mapStoreToProps)(HeaderCurrentTimer);