import {Component} from 'react';
import Shepherd from 'tether-shepherd';
import {withRouter} from 'react-router-dom';
import {connect} from 'react-redux';

import {setupShepherdTour} from '../tour/tour';

class TourGuideComponent extends Component {

    constructor(props) {
        super(props);
        this.state = {
            lastLocation: '',
            lastStore: null
        };
        this.mediator = new Shepherd.Evented();
    }

    componentDidMount() {
        setupShepherdTour(this.mediator);
    }

    componentDidUpdate() {
        if (this.props.location.pathname !== this.state.lastLocation || this.props.store !== this.state.lastStore) {
            this.mediator.trigger('page-changed');
            this.setState({lastLocation: this.props.location.pathname, lastStore: this.props.store});
        }
    }

    render() {
        return null;
    }
}

function mapStoreToProps(store) {
    return {store};
}

export default withRouter(connect(mapStoreToProps)(TourGuideComponent));