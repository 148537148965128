import {AnyAction, Reducer} from 'redux';

import ensureFieldPath from '../ensureFieldPath';
import {objectMapReducer, ObjectMapState} from '../util/genericReducers';
import { StoreWithSharedState } from './sharedStateReducer';
import {SingleProblemStepType} from './allTeamsReducer';

// ======== Constants

export const UPDATE_PROBLEM = 'update_problem';

export interface ProblemEvidenceType {
    order: string[];
    content: {[evidenceKey: string]: string};
}

export interface SingleProblemType {
    id: number;
    title: string;
    summary: string;
    scenario: string;
    question: {[questionId: string]: string};
    evidence: ProblemEvidenceType;
    resource: {[resourceName: string]: string};
    created: Date;
    expectedStart?: Date;
    expectedCompletion?: Date;
    isTraining: boolean;
    teams?: SingleProblemStepType[];
}

export interface ProblemAction extends AnyAction {
    problemId: number
}

export const initialSingleProblemState: SingleProblemType = {
    id: 0,
    title: '',
    summary: '',
    scenario: '',
    question: {},
    evidence: {order: [], content: {}},
    resource: {},
    created: new Date(),
    isTraining: false
};

const singleProblemReducer: Reducer<SingleProblemType> = (state = initialSingleProblemState, action: ProblemAction | AnyAction) => {
    switch (action.type) {
        case UPDATE_PROBLEM:
            return {...state, ...action.problem};
        default:
            return state;
    }
};

const problemsObjectMapReducer = objectMapReducer('problemId', singleProblemReducer);

export type AllProblemsReducerType = ObjectMapState<SingleProblemType>;

const allProblemsReducer: Reducer<AllProblemsReducerType> = (state = {}, action: ProblemAction | AnyAction) => {
    if (action.problemId) {
        return problemsObjectMapReducer(state, action);
    } else {
        return state;
    }
};

export default allProblemsReducer;

// ======== DBSync functions
// (used by DBSync and for unit testing)

export function getDBSyncActionTypes() {
    return [UPDATE_PROBLEM];
}

export function setProblemDBInStore(store, problemId: number, problem: SingleProblemType) {
    ensureFieldPath(store, 'sharedState', 'problem', problemId);
    store.sharedState.problem[problemId] = problem;
}

// ======== Action generator functions

export function updateProblemAction(problemId: number, problem: Partial<SingleProblemType>): ProblemAction {
    return {type: UPDATE_PROBLEM, problemId, problem};
}

// ======== Getter functions

export function getProblemDBFromStore(store: StoreWithSharedState, problemId: number | string): SingleProblemType {
    return store.sharedState.problem[problemId];
}

export function getAllProblemDBsFromStore(store: StoreWithSharedState): AllProblemsReducerType {
    return store.sharedState.problem;
}
