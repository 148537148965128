export type BardFilterObject<T extends object> = {
    key: string;
    fields: string[];
    tokens: {[key in keyof T]?: string};
    where: Partial<T>;
    default?: boolean;
};

export interface BardFilterSuggestions<T> {
    fields: (keyof T)[];
    values: string[];
    openParentheses: boolean;
}

interface FieldData<T> {
    fieldTypes: {[key in keyof T]: string | string[]};
    defaultField: keyof T;
}

let fieldData: {[key: string]: FieldData<any>} = {};

export function addFieldData<T>(key: string, fieldTypes: {[key in keyof T]: string | string[]}, defaultField: keyof T) {
    fieldData[key] = {fieldTypes, defaultField};
}

export function tokeniseFilterString(filter: string): string[] {
    return filter.split(/[\s,]+|\b|\B(?=[=()])/);
}

export function getTokenValueByType(token: string, fieldType: string | string[]) {
    if (token) {
        if (Array.isArray(fieldType)) {
            return fieldType.reduce<string | undefined>((result, value) => (
                result || (value.toLocaleLowerCase().indexOf(token.toLocaleLowerCase()) >= 0 ? value : result)
            ), undefined);
        } else if (fieldType === 'number') {
            const value = Number.parseInt(token);
            return isNaN(value) ? undefined : value;
        } else if (fieldType === 'boolean') {
            return 'true'.indexOf(token.toLocaleLowerCase()) === 0 ? true :
                'false'.indexOf(token.toLocaleLowerCase()) === 0 ? false : undefined;
        }
    }
    return token;
}

export function isNestedFilterField<T extends object>(field: string | undefined, filter: BardFilterObject<T>): field is string {
    const {fieldTypes} = fieldData[filter.key];
    const fieldType = field && fieldTypes[field];
    return !!fieldType && typeof(fieldType) === 'string' && fieldData[fieldType] !== undefined;
}

function parseFilterStringInternal<T extends object>(tokens: string[], key: string, result: BardFilterObject<T>, index: number): number {
    const {fieldTypes, defaultField} = fieldData[key];
    let currentField, currentFieldType: string | string[] = '';
    let parenthesisDepth = 0;
    while (index < tokens.length) {
        const token = tokens[index++];
        if (token) {
            if (token === '(') {
                parenthesisDepth++;
            } else if (token === ')') {
                parenthesisDepth--;
                if (parenthesisDepth <= 0) {
                    return index;
                }
            } else if (index < tokens.length && tokens[index] === '=') {
                index++;
                if (fieldTypes.hasOwnProperty(token)) {
                    currentField = token;
                    currentFieldType = fieldTypes[currentField];
                    result.fields.push(token);
                    if (isNestedFilterField(currentField, result)) {
                        result.where[currentField] = {key: currentFieldType, fields: [], where: {}, tokens: {}};
                        result.tokens[currentField] = result.where[currentField];
                        // Nested filter
                        index = parseFilterStringInternal(tokens, currentFieldType as string, result.where[currentField], index);
                        currentField = undefined;
                    }
                }
            } else if (currentField) {
                const value = getTokenValueByType(token, currentFieldType);
                if (result.where[currentField] === undefined) {
                    result.where[currentField] = value;
                    result.tokens[currentField] = token;
                } else {
                    if (!Array.isArray(result.where[currentField])) {
                        result.where[currentField] = [result.where[currentField]];
                        result.tokens[currentField] = [result.tokens[currentField]];
                    }
                    result.where[currentField].push(value);
                    result.tokens[currentField].push(token);
                }
            } else {
                currentField = defaultField;
                currentFieldType = fieldTypes[currentField];
                result.fields.push(currentField);
                result.where[currentField] = token;
                result.tokens[currentField] = token;
                result.default = true;
            }
        }
    }
    return index;
}

export function parseFilterString<T extends object>(filter: string, key: string): BardFilterObject<T> {
    const tokens = tokeniseFilterString(filter);
    let result: BardFilterObject<T> = {key, fields: [], where: {}, tokens: {}};
    parseFilterStringInternal(tokens, key, result, 0);
    return result;
}

// Test if the last token in filter string could auto-complete to values in fieldTypes.
export function suggestFilterFields<T extends object>(filter: BardFilterObject<T>, nested = false): BardFilterSuggestions<T> {
    const {fieldTypes} = fieldData[filter.key];
    const lastField = filter.fields.length > 0 ? filter.fields[filter.fields.length - 1] : undefined;
    const lastFieldType = lastField ? fieldTypes[lastField] : undefined;
    const lastFieldToken = lastField ? filter.tokens[lastField] : undefined;
    const defaultToken = filter.default && !Array.isArray(lastFieldToken) ? lastFieldToken : undefined;
    const lastToken = defaultToken || lastFieldToken ?
        Array.isArray(lastFieldToken) ? lastFieldToken[lastFieldToken.length - 1] : lastFieldToken
        : undefined;
    if (isNestedFilterField(lastField, filter)) {
        return suggestFilterFields(lastFieldToken, true);
    }
    let fields = lastField && !lastFieldToken ? [] : Object.keys(fieldTypes);
    let values = (lastFieldType === 'boolean') ? ['true', 'false']
        : (Array.isArray(lastFieldType)) ? lastFieldType
        : [];
    if (lastToken) {
        fields = fields.filter((field) => (field.toLocaleLowerCase().indexOf(lastToken.toLocaleString()) >= 0));
        values = values.filter((value) => (value.toLocaleLowerCase().indexOf(lastToken.toLocaleString()) >= 0));
    }
    return {fields: fields as (keyof T)[], values, openParentheses: nested && !lastField}
}

export function fieldMatchesFilter(actualValue, filterValue, key: string, field: string) {
    const {fieldTypes} = fieldData[key];
    const fieldType = fieldTypes[field];
    if (Array.isArray(fieldType)) {
        let filterValueIndex = fieldType.indexOf(filterValue);
        // eslint-disable-next-line eqeqeq
        return filterValueIndex == actualValue;
    } else if (typeof (actualValue) === 'string' && typeof (filterValue) === 'string') {
        return actualValue.toLocaleLowerCase().indexOf(filterValue.toLocaleLowerCase()) >= 0;
    } else if (typeof(filterValue) === 'boolean') {
        return !!actualValue === filterValue;
    } else {
        // eslint-disable-next-line eqeqeq
        return actualValue == filterValue;
    }
}