import {merge} from 'lodash';

/**
 * Common validation functions
 */

const DEFAULT_ERROR_PREFIX = 'Value';

export const VALIDATION_NOT_EMPTY = 'not_empty';
export const VALIDATION_UNIQUE = 'unique';
export const VALIDATION_NO_UNTRIMMED_SPACE = 'no_untrimmed_space';

export interface ValidationValue {
    [key: string]: string | null | {recalculate: boolean};
}

type ValidationFunction = (name: string, value: any, oldValue: any, errorPrefix: string, options?: any) => ValidationValue;

const validationFunctions: {[key: string]: ValidationFunction} = {
    [VALIDATION_NOT_EMPTY]: (name, value, _oldValue, errorPrefix) =>
        ({[name]: value.length === 0 ? errorPrefix + ' may not be empty.' : null}),
    [VALIDATION_NO_UNTRIMMED_SPACE]: (name, value, _oldValue, errorPrefix) =>
        ({[name]: value.trim() !== value ? errorPrefix + ' may not start or end with spaces.' : null}),
    [VALIDATION_UNIQUE]: (name, value = '', oldValue = '', errorPrefix = DEFAULT_ERROR_PREFIX, options = {}) => {
        if (options.caseInsensitive) {
            value = value.toLowerCase();
            oldValue = oldValue.toLowerCase();
        }
        const error = errorPrefix + ' must be unique.';
        const otherDuplicates: string[] = [];
        const result = Object.keys(options.others).filter((id) => (id !== name)).reduce<ValidationValue>((all, id) => {
            let otherValue = options.others[id];
            if (options.caseInsensitive) {
                otherValue = otherValue.toLowerCase();
            }
            if (otherValue === value) {
                return {...all, [name]: error, [id]: error};
            } else if (otherValue === oldValue) {
                otherDuplicates.push(id);
            }
            return all;
        }, {[name]: null});
        if (otherDuplicates.length === 1) {
            // Only clear the invalid status of other things with the oldValue if there is one of them (otherwise,
            // they're still duplicates of one another).
            return {...result, [otherDuplicates[0]]: null};
        } else {
            return result;
        }
    }

};

type Validator = string | ValidationFunction | {
    type: string;
    [key: string]: any;
};

// This method assumes that a given field will always have the same list of validations.
export function validate(name: string, value, oldValue, validations: Validator | Validator[], errorPrefix = DEFAULT_ERROR_PREFIX) {
    if (!Array.isArray(validations)) {
        validations = [validations];
    }
    return validations.reduce<ValidationValue>((all, validator, index) => {
        let options, validationFunction;
        switch (typeof(validator)) {
            case 'object':
                options = validator;
                validationFunction = validationFunctions[validator.type];
                break;
            case 'string':
                validationFunction = validationFunctions[validator];
                break;
            default:
                validationFunction = validator;
        }
        const problem = validationFunction(name, value, oldValue, errorPrefix, options);
        return mergeValidationsWithKey(all, problem, index);
    }, {[name]: {recalculate: false}});
}

function mergeValidationsWithKey(oldValidations, newValidations, key) {
    return Object.keys(newValidations).reduce((all, fieldName) => {
        return {...all, [fieldName]: {...all[fieldName], [key]: newValidations[fieldName]}}
    }, oldValidations);
}

/**
 * This method returns a validation structure which forces recalculation of the provided validation names
 *
 * @param names The validationNames that should be recalculated
 */
export function revalidate(names: string[]): ValidationValue {
    return names.reduce((result, name) => {
        return {...result, [name]: {recalculate: true}};
    }, {});
}

/**
 * Call this method when one or more field have been deleted, so their validations can be discarded.
 *
 * @param deletedNames The validationNames that have been deleted.
 * @param otherNames (optional) Other validationNames which need to be recalculated (e.g. if they had a
 * VALIDATION_UNIQUE constraint with any of the deletedNames).  It is legal for a name to appear in both lists (in which
 * case it is marked as deleted, overriding marking it as requiring recalculation).
 * @return a new validation object which can be merged with existing validations.
 */
export function deleteValidationsForNames(deletedNames, otherNames: string[] = []) {
    return deletedNames.reduce((result, name) => {
        return {...result, [name]: null};
    }, revalidate(otherNames));
}

export function mergeValidations(oldValidations, newValidations) {
    // Deep merge the validation objects, but remove any names/keys which are falsey
    return Object.keys(newValidations).reduce((result, name) => {
        const forName = result[name];
        if (forName) {
            Object.keys(forName).forEach((key) => {
                if (!forName[key]) {
                    delete(forName[key]);
                }
            });
            if (Object.keys(forName).length === 0) {
                delete(result[name]);
            }
        } else {
            delete(result[name]);
        }
        return result;
    }, merge({}, oldValidations, newValidations));
}

export function shouldRecalculateValidation(validation, validationName) {
    return validation && validation[validationName] && validation[validationName].recalculate;
}

export function getProblemTextFromValidation(validationName: string, validation?: ValidationValue): string | null {
    const validationValue = validation && validation[validationName];
    return !validationValue ? null : Object.keys(validationValue).reduce((result, key) => {
        return result || (key === 'recalculate' ? result : validationValue[key]);
    }, null);
}

export function getAnyProblemTextFromValidation(validations: ValidationValue): string | null {
    const names = Object.keys(validations);
    return names.reduce<string | null>((problem, validationName) => (
        problem || getProblemTextFromValidation(validationName, validations)
    ), null);
}