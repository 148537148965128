import moment from 'moment';
import {Reducer} from 'redux';
import {isEqual} from 'lodash';

import DataStatusEnum from '../DataStatusEnum';
import ensureFieldPath from '../ensureFieldPath';
import {ObjectMapState, timestampedReducer, TimestampedState} from '../util/genericReducers';
import * as constants from '../util/constants';
import {StoreWithSharedState} from './sharedStateReducer';
import {initialKeyVariablesState, KeyVariablesState} from './keyVariablesReducer';

// ======== Constants

const SET_BN_GRAPH = 'set BN graph';

export interface Coordinate {
    x: number;
    y: number;
}

export interface Connection {
    source: string;
    target: string;
    label?: string;
}

export interface BayesNetGraphState extends TimestampedState {
    nodes: KeyVariablesState;
    connections: Connection[];
    coords: ObjectMapState<Coordinate>;
}

export const initialBayesNetGraphState: BayesNetGraphState = {nodes: initialKeyVariablesState, connections: [], coords: {}};

// ======== Reducers

const bayesNetGraphReducer: Reducer<BayesNetGraphState> = (state = initialBayesNetGraphState, action) => {
    switch (action.type) {
        case SET_BN_GRAPH:
            return {...action.graph};
        default:
            return state;
    }
};

const bayesNetGraphTimestampedReducer = timestampedReducer(bayesNetGraphReducer, (oldState, newState, action) => {
    // Don't update timestamp if the changes are simply migrated from changes to Variables.
    // Otherwise, the BN graph component is indiscriminant in dispatching no-op updates... do a deep comparison.
    return !action.migrated && !isEqual(oldState, newState);
});

const bayesNetGraphCopyableReducer: Reducer<BayesNetGraphState> = (state, action) => {
    switch (action.type) {
        case constants.COPY_PUBLISHED_ACTION:
        case constants.APPEND_PUBLISHED_ACTION:
            if (action.fromUser.bayesNetGraph) {
                return {...action.fromUser.bayesNetGraph};
            } else {
                return state;
            }
        default:
            return bayesNetGraphTimestampedReducer(state, action);
    }
};

export default bayesNetGraphCopyableReducer;

// ======== DBSync functions
// (used by DBSync and for unit testing)

export function getDBSyncActionTypes() {
    return [SET_BN_GRAPH];
}

export function setBayesNetGraphInStore(store: StoreWithSharedState, problemStepId: number, userId: number, status: DataStatusEnum, object) {
    ensureFieldPath(store, 'sharedState', 'teams', problemStepId, 'user', userId, 'status', [status]);
    store.sharedState.teams[problemStepId].user[userId].status[status].bayesNetGraph = object;
}

// ======== Action generator functions

export function setBayesNetGraphAction(problemStepId: number, userId: number, status: DataStatusEnum, graph: BayesNetGraphState, migrated: boolean) {
    return {type: SET_BN_GRAPH, problemStepId, userId, status, graph, timestamp: moment().format(), migrated};
}

// ======== Getter functions

export function getBayesNetGraphFromStore(store: StoreWithSharedState, problemStepId: number, userId: number, status: DataStatusEnum): BayesNetGraphState {
    return store.sharedState.teams[problemStepId].user[userId].status[status].bayesNetGraph;
}
