import moment from 'moment';

import { timestampedReducer, timestampedActionCreator, TimeStampedAction, TimestampedState } from '../util/genericReducers';
import ensureFieldPath from '../ensureFieldPath';
import { AnyAction, Reducer } from 'redux';

interface ScheduleShutdownActionBody {
    shutdownScheduled: boolean,
    scheduledShutdownTimeStamp: string
}

interface ShutdownActionBody {
    serviceAvailable: boolean
}

export interface ScheduleShutdownAction extends TimeStampedAction {
    body: ScheduleShutdownActionBody
}

export interface ShutdownAction extends TimeStampedAction {
    body: ShutdownActionBody
}

export interface RebootFromShutdownAction extends TimeStampedAction {}

export interface CancelScheduleShutdownAction extends TimeStampedAction {}

export interface RestartExistingShutdownScheduleAction extends TimeStampedAction {}

export interface ServerState extends TimestampedState {
    serviceAvailable: boolean,
    shutdownScheduled: boolean,
    scheduledShutdownTimeStamp: string
}

const initialState: ServerState = {
    serviceAvailable: true,
    shutdownScheduled: false,
    scheduledShutdownTimeStamp: "",
    timestamp: ""
};

// ======= Constants
export const SCHEDULE_SHUTDOWN = 'schedule_shutdown';
export const SHUTDOWN = 'shutdown';
export const REBOOT = 'reboot';
export const CANCEL_SCHEDULE_SHUTDOWN = 'cancel_schedule_shutdown';
export const RESTART_EXISTING_SHUTDOWN_SCHEDULE = 'restart_existing_shutdown_schedule';
// ======= Reducers

const serverStateReducer: Reducer<ServerState> = (state = initialState, action: AnyAction) => {
    switch(action.type) {
        case SCHEDULE_SHUTDOWN:
            return {...state, ...action.body, timestamp: action.timestamp};
        case SHUTDOWN:
            return {...state, ...action.body, timestamp: action.timestamp};
        case RESTART_EXISTING_SHUTDOWN_SCHEDULE:
            return {...state, timestamp: action.timestamp};
        case REBOOT:
        case CANCEL_SCHEDULE_SHUTDOWN:
            return {...initialState, timestamp: action.timestamp};
        default:
            return state;
    }
};

export default timestampedReducer(serverStateReducer);

// ======= DBSync functions
export function getDBSyncActionTypes() {
    return [SCHEDULE_SHUTDOWN, SHUTDOWN, REBOOT, CANCEL_SCHEDULE_SHUTDOWN, RESTART_EXISTING_SHUTDOWN_SCHEDULE];
}

export function setServerStateInStore(store: any, object?: ServerState) {
    ensureFieldPath(store, 'sharedState');
    store.sharedState.serverState = object;
}

// ======= Action Generators
export function scheduleShutdownAction(scheduledDuration: number) : ScheduleShutdownAction {
    return timestampedActionCreator({type: SCHEDULE_SHUTDOWN, body: {scheduledShutdownTimeStamp: moment().add(scheduledDuration, 'h').format(), shutdownScheduled: true}});
}

export function shutdownAction(): ShutdownAction {
    return timestampedActionCreator({type: SHUTDOWN, body: {serviceAvailable: false}});
}

export function rebootFromShutdownAction(): RebootFromShutdownAction {
    return timestampedActionCreator({type: REBOOT});
}

export function cancelScheduleShutdownAction(): CancelScheduleShutdownAction {
    return timestampedActionCreator({type: CANCEL_SCHEDULE_SHUTDOWN});
}

export function restartExistingShutdownSchedule(): RestartExistingShutdownScheduleAction {
    return timestampedActionCreator({type: RESTART_EXISTING_SHUTDOWN_SCHEDULE});
}

export function getServerStateFromStore(store) {
    return store.sharedState.serverState;
}
