import React, {Component} from 'react';
import PropTypes from 'prop-types';
import {connect} from 'react-redux';
import {MenuButton} from 'react-md';
import moment from 'moment';
import classNames from 'classnames';
import {lowerCase, toUpper} from 'lodash';

import {getProblemDBFromStore} from '../../common/reducers/allProblemsReducer';
import ReferenceData from '../../common/util/referenceData';
import {getUserFromStore} from '../reducers/loginReducer';
import * as constants from '../../common/util/constants';
import RoleContainer from '../container/RoleContainer';
import {requestOptOutAction, withdrawOptOutRequestAction} from '../../common/reducers/groupManagementReducer';
import ConfirmationDialog from './ConfirmationDialog';
import StatusIconUser from './StatusIconUser';
import {getFeatureFlagsFromStore} from '../../common/reducers/featureFlagsReducer';
import {FEATURE_USERS_OPT_OUT_TO_ROLE, getFlagValue} from '../../common/util/featureFlags';
import {getTeamFromStore} from '../../common/reducers/allTeamsReducerGetters';
import {getUserForProblemFromStore} from '../../common/reducers/allUsersForTeamReducerGetters';

const NO_ACTION = 0;
const REQUEST_OPT_OUT = 1;

class UserDetailsComponent extends Component {

    static propTypes = {
        problemStepId: PropTypes.number.isRequired,
        page: PropTypes.string,
        userRole: PropTypes.number
    };

    static defaultProps = {
        page: constants.PAGE_HEADER,
        userRole: null
    };

    constructor(props) {
        super(props);

        this.state = {
            action: NO_ACTION,
            needConfirmation: false,
            confirmationTitle: '',
            confirmationDialogContent: ''
        };

        this.actionCancelled = this.actionCancelled.bind(this);
        this.takeAction = this.takeAction.bind(this);
        this.actionFunc = this.actionFunc.bind(this);
    }

    actionCancelled() {
        this.setState({needConfirmation: false});
    }
    
    takeAction() {
        switch (this.state.action) {
            case REQUEST_OPT_OUT:
                this.setState({needConfirmation: false}, () => {
                    this.props.dispatch(requestOptOutAction(this.props.problemStepId, this.props.loggedInUser.id, this.props.userForProblem.db.role))
                });
               break;
            default:
        }
    }

    actionFunc(action) {

        let confirmationTitle = '';
        let confirmationDialogContent = '';
        const optOutOfProblem = getFlagValue(this.props.featureFlags[FEATURE_USERS_OPT_OUT_TO_ROLE]) === constants.ROLE_OBSERVER_NAME;
        const roleFor = optOutOfProblem ? '' : ' the Facilitator role for';
        const participateAs = optOutOfProblem ? 'follow the problem in the Observer role' : 'participate as an Analyst in this problem';
        const replacement = (this.props.team.problemStep.stepId !== null) ? ', and you will not be considered as a replacement for that role.' : '.';
        const promotion = (this.props.team.problemStep.stepId !== null) ? (<div><br/><b>You will not be considered as a replacement for any other role.</b></div>) : '';
        switch (action) {
            case REQUEST_OPT_OUT:
                confirmationTitle = 'Request opt out';
                switch (this.props.userForProblem.db.role) {
                    case constants.ROLE_FACILITATOR:
                        confirmationDialogContent = <span>You have requested to opt out of the Facilitator role for this problem.<br/><br/>You will still be able to participate in the problem in the Analyst role.<br/><br/><b>You will no longer act in the Facilitator role{replacement}</b><br/><br/><b>To finalise your opt-out, open the problem and go to the Group Management sub-tab under the ADMIN tab.</b></span>;
                        break;
                    case constants.ROLE_ANALYST:
                        confirmationDialogContent = <span>You have requested to opt out of{roleFor} this problem.<br/><br/>You will still be able to {participateAs}.<br/><br/><b>You will no longer act in the Analyst role{replacement}</b><br/><br/><b>Once actioned by the Facilitator, this action cannot be undone.</b></span>;
                        break;
                    case constants.ROLE_UNALLOCATED:
                        confirmationDialogContent = <span>You have requested to opt out of{roleFor} this problem.<br/><br/>You will still be able to {participateAs}.{promotion}<br/>This request will be actioned approximately 24 hours before the problem starts.</span>;
                        break;
                    default:
                        confirmationDialogContent = <span>You have requested to opt out of{roleFor} this problem.<br/><br/>You will still be able to {participateAs}.{promotion}<br/><b>Once actioned by the Facilitator, this action cannot be undone.</b></span>;
                        break;
                }
                break;
            default:
        }

        this.setState({needConfirmation: true, confirmationTitle, confirmationDialogContent, action});
    }

    render() {
        const toolTip = <span className="toolTip">You have the role of {this.props.userRoleName} for this problem</span>;
        const problemIsTraining = this.props.problem.isTraining;
        const problemIsClosed = this.props.team.problemStep.state === constants.STATE_CLOSED;

        let groupManagementState = constants.GRP_MGMT_NOP;
        let groupManagementStateDate = null;
        let optOutOptions = [];
        let optOutRoles = [];

        if (!problemIsTraining && !problemIsClosed && this.props.page === constants.PAGE_FOYER && false) {
            
            groupManagementState = this.props.userForProblem.db.groupManagementState;
            groupManagementStateDate = this.props.userForProblem.db.groupManagementStateDate;
            const ofRole = (this.props.userForProblem.db.role === constants.ROLE_FACILITATOR
                || (this.props.userForProblem.db.role === constants.ROLE_UNALLOCATED && getFlagValue(this.props.featureFlags[FEATURE_USERS_OPT_OUT_TO_ROLE]) === constants.ROLE_ANALYST_NAME)) ?
                ' of Facilitator role' : '';
            if (groupManagementState === constants.GRP_MGMT_NOP || groupManagementState === constants.GRP_MGMT_USER_PROMOTED) {
                optOutOptions = [{
                    primaryText: '', 
                    secondaryText: `Request to OPT OUT${ofRole} of this problem`,
                    onClick: () => { this.actionFunc(REQUEST_OPT_OUT) }
                }];
            } else if (groupManagementState === constants.GRP_MGMT_OPT_OUT_REQUESTED) {
                optOutOptions = [{
                    primaryText: `Opt out${ofRole} requested ${moment(groupManagementStateDate).format("DD MMM YYYY")}\n `,
                    secondaryText: `Withdraw request to opt out${ofRole}`,
                    onClick: () => {
                        this.props.dispatch(withdrawOptOutRequestAction(this.props.problemStepId, this.props.loggedInUser.id, this.props.userForProblem.db.role))
                    }
                }];
            }

            optOutRoles = getFlagValue(this.props.featureFlags[FEATURE_USERS_OPT_OUT_TO_ROLE]) === constants.ROLE_OBSERVER_NAME
                ? [constants.ROLE_FACILITATOR, constants.ROLE_ANALYST, constants.ROLE_OBSERVER, constants.ROLE_UNALLOCATED]
                : [constants.ROLE_FACILITATOR, constants.ROLE_UNALLOCATED];
        }

        return (
            <div className={'userDetailsComponent '+ (this.props.page === constants.PAGE_FOYER ? 'foyerProblemStatusDivStyles' : '')}>
                <div className='imageDiv toolTipContainer'>
                    <StatusIconUser className={classNames('iconStyles', this.props.userRoleName)}/>
                    {toolTip}
                </div>
                <div className="headerLabelDiv">                   
                    <div className="headerRoleWrap">
                        <div className="roleName">
                            {this.props.userForProblem.db.displayName}
                        </div>

                        <RoleContainer userRole={optOutRoles} userRoleForProblem={this.props.userForProblem.db.role} problemStepId={this.props.problemStepId}>
                            <div className="optOutWrap">
                                {
                                    (optOutOptions.length === 0) ? null : (
                                        <div className="optOutDiv toolTipContainer">
                                            {
                                                (groupManagementState === constants.GRP_MGMT_OPT_OUT_REQUEST_GRANTED ||
                                                    groupManagementState === constants.GRP_MGMT_USER_DEMOTED ||
                                                    groupManagementState === constants.GRP_MGMT_USER_AUTO_DEMOTED)
                                                ? (
                                                    <span><i>Opted out</i></span>
                                                ) : (
                                                        <MenuButton
                                                            id="userOptOutButton"
                                                            className='menuButton userOptOutButton'
                                                            icon
                                                            menuItems={optOutOptions}
                                                            anchor={{
                                                                x: MenuButton.HorizontalAnchors.INNER_RIGHT,
                                                                y: MenuButton.VerticalAnchors.BOTTOM
                                                            }}
                                                            position={this.props.team.problemStep.stepId === null ? MenuButton.Positions.TOP_LEFT : MenuButton.Positions.TOP_RIGHT}
                                                        >
                                                            more_horiz
                                                            <div className='notificationCounter' style={{marginRight:'0', visibility: groupManagementState === constants.GRP_MGMT_OPT_OUT_REQUESTED ? 'visible': 'hidden'}}/>
                                                        </MenuButton>
                                                )
                                            }
                                            <span className="toolTip">Opt out options</span>
                                        </div>
                                    )
                                }
                            </div>
                        </RoleContainer>
                    
                        <div className="roleDiv toolTipContainer">{toUpper(lowerCase(this.props.userRoleName))}
                            <span className="toolTip toolTipBeneath">
                                {
                                    this.props.userForProblem.db.role === constants.ROLE_UNALLOCATED ?
                                        ('You have not yet been allocated a role') :
                                        ('You have the role of ' + lowerCase(this.props.userRoleName))
                                } for this problem
                            </span>
                        </div>
                    </div>
                </div>
                        
                <ConfirmationDialog
                                visible={this.state.needConfirmation}
                                dialogTitle={this.state.confirmationTitle}
                                onHideAction={this.takeAction} onCancelAction={this.actionCancelled}
                                dialogContent={this.state.confirmationDialogContent}
                                width={500}
                                actionButtonContent="CONFIRM"
                            />

            </div>
        );
    }
}
function mapStoreToProps(store, myProps) {
    const problemStepId = myProps.problemStepId;
    const loggedInUser = getUserFromStore(store);
    const userForProblem = getUserForProblemFromStore(store, problemStepId, loggedInUser.id);
    const thisUserRole = myProps.userRole ? myProps.userRole : userForProblem.db.role;
    const roles = ReferenceData.getInstance().getAllUserRoles();
    const team = getTeamFromStore(store, problemStepId);
    return {
        loggedInUser,
        userForProblem,
        userRoleName: roles[thisUserRole].role,
        team,
        problem: getProblemDBFromStore(store, team.problemStep.problemId),
        featureFlags: getFeatureFlagsFromStore(store)             
    }
}

export default connect(mapStoreToProps)(UserDetailsComponent);
